import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import { ErrorContext } from "../contexts/error.context";
import * as ServiceAuthorizationListAPI from "../services/HeathRecordAPI";
import { MY_HEALTH_RECORD_VIEW_SERVICE_AUTHORIZATION } from "../define.widgets";
const qs = require("qs");

const useServiceAuthorizationListAPI = (
  page = 1,
  recordsPerPage,
  fetchData = true
) => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [serviceAuthorizationList, setServiceAuthorizationList] = useState([]);
  const { MemberStateName, memberRKSID } = useContext(AppContext);
  const [isServiceAuthorizationsloaded, setisServiceAuthorizationsloaded] =
    useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const setData = (data) => {
    setServiceAuthorizationList(
      data.ResultData.ServiceAuthorization.ServiceAuthorizationListMyMolina
    );
    setisServiceAuthorizationsloaded(true);

    if (
      data.ResultData.ServiceAuthorization.ServiceAuthorizationListMyMolina !==
      null
    )
      setTotalRecords(
        data.ResultData.ServiceAuthorization.ServiceAuthorizationListMyMolina[0]
          ?.TotalRecords
      );
  };

  useEffect(() => {
    if (fetchData) {
      setInnerSpinner(true, MY_HEALTH_RECORD_VIEW_SERVICE_AUTHORIZATION);
      let query = {
        Page: page,
        RecordsPerPage: recordsPerPage,
      };

      ServiceAuthorizationListAPI.getServiceAuthorizationList(
        {
          RKSMemberID: memberRKSID,
          stateCode: MemberStateName,
          Page: page,
          RecordsPerPage: recordsPerPage,
        },
        qs.stringify(query, { addQueryPrefix: true })
      )
        .then((response) => {
          if (response != undefined && response != null) {
            setData(response);
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MY_HEALTH_RECORD_VIEW_SERVICE_AUTHORIZATION);
        });
    }
  }, [memberRKSID, MemberStateName, page, recordsPerPage, fetchData]);

  return {
    serviceAuthorizationList,
    isServiceAuthorizationsloaded,
    totalRecords,
  };
};

export { useServiceAuthorizationListAPI };
