import React, { useContext } from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { ModalProvider } from "../../contexts/modal.context";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import { AppContext } from "../../contexts/app.context";

const MyDoctorLayout = (props) => {
  const { isTermedMember } = useContext(AppContext);
  return (
    <ModalProvider>
      <SSORedirectProvider>
        {!isTermedMember && (
          <div className="page-heading">
            <Text field={props.fields.heading} />
          </div>
        )}
        <Placeholder name="jss-mydoctor-layout" rendering={props.rendering} />
      </SSORedirectProvider>
    </ModalProvider>
  );
};

export default MyDoctorLayout;
