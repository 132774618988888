import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIDCardMedicaidTXDual = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/TX/Medicaid/TX_Duals_Options-MMP_2022_ID_Card_front_new.png)`,
          }}
          className="background-idcard"
          id="containerTX"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="table-font-size tx-member-idcard"
          >
            <tbody>
              <tr>
                <td>
                  <div className="clearfix">
                    <div className="float-start TXdualleft">
                      <ul className="list-unstyled TXmrgn">
                        <li className="mb-4">
                          <span className="plan-name-id-card">
                            <Text field={fields?.lblDualsHeading} />
                          </span>
                        </li>
                        <li>
                          <label>
                            <b>
                              <Text field={fields?.lblMember} />
                            </b>
                            &nbsp; {idCard?.Member_Name}
                          </label>
                        </li>
                        <li className="mb-5">
                          <label>
                            <b>
                              <Text field={fields?.lblID} />
                            </b>
                            &nbsp; {idCard?.OriginalMemberProfile?.Number}
                          </label>
                        </li>
                        <li>
                          <label>
                            <b>
                              <Text field={fields?.lblPCPNameDuals} />
                            </b>
                            &nbsp;{idCard?.Member_DOB}
                          </label>
                        </li>
                        <li className="mb-5">
                          <label>
                            <b>
                              <Text field={fields?.lblPCPPhoneDuals} />
                            </b>
                            &nbsp;{idCard?.PCPPhone_Current}
                          </label>
                        </li>
                        <li>
                          <label>
                            <b>
                              <Text field={fields?.lblMemCannotCharged} />
                            </b>
                          </label>
                        </li>
                        <li className="mb-4">
                          <label>
                            <Text field={fields?.lblCoPaysTX} />
                            &nbsp;
                            <Text field={fields?.lblDualsCopayZero} />
                          </label>
                        </li>
                        <li>
                          <label>
                            {
                              result?.PortalDeductiblesMappingDetails[0]
                                ?.ContractPlanId
                            }
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className="float-end TXdualright mrgn-top">
                      <div className="dualHeight"></div>
                      <div className="vborderTXDuals">
                        <ul className="list-unstyled TXmrgn">
                          <li className="mediheight">&nbsp;</li>
                          <li>
                            <Text field={fields?.lblBin} />
                            &nbsp;{" "}
                            {result?.PortalDeductiblesMappingDetails[0]?.RxBIN}
                          </li>
                          <li>
                            <Text field={fields?.lblPCN} />
                            &nbsp;{" "}
                            {result?.PortalDeductiblesMappingDetails[0]?.RxPCN}
                          </li>
                          <li>
                            <Text field={fields?.lblGroup} />
                            &nbsp;{" "}
                            {
                              result?.PortalDeductiblesMappingDetails[0]
                                ?.RxGroup
                            }
                          </li>
                          <li>
                            <Text field={fields?.lblRxIdTX} />
                            &nbsp; {idCard?.OriginalMemberProfile?.Number}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/OH/Duals/Member-ID-Card-Duals-back-OH.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
              className="table-font-size-back"
            >
              <tr>
                <td>
                  <label>
                    <Text field={fields?.lblBackEmergencyValues} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <Text field={fields?.lblBackEmergencySpanishValues} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblIDBackServices} />
                    </b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {"   "}
                    <Text field={fields?.lblMemServicesValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblBackBehaioralHealth} />
                    </b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"   "}
                    <Text field={fields?.lblBackBehaioralHealthValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblBack24HrNurseAdvise} />
                    </b>
                    &nbsp;
                    <Text field={fields?.lblBack24HrNurseAdviseValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblBackWebsite} />
                    </b>
                    <span className="id-card-back-website-name">
                      <Text field={fields?.lblBackWebsiteValue} />
                    </span>
                  </label>
                </td>
              </tr>

              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblPharmacyHelpDesk} />
                    </b>
                    &nbsp; <Text field={fields?.lblPharmacyHelpDeskValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblSendClaimsTo} />
                    </b>
                    <Text field={fields?.lblSendClaimsToValue} />
                    <br></br>
                    <span className="txt-pad">
                      <Text field={fields?.lblSendClaimsToValue1} />
                    </span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblClaimsInquiry} />
                    </b>
                    &nbsp; <Text field={fields?.lblClaimsInquiryValue} />
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardMedicaidTXDual;
