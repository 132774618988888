import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import * as ViewInpatientAndEDVisits from "../services/HeathRecordAPI";
import { ErrorContext } from "../contexts/error.context";
import { MY_HEALTH_RECORD_VIEW_INPATIENT_AND_ED_VISITS } from "../define.widgets";
const qs = require("qs");

// This API is used to fetch the inpatient visit list which display in Myhealthrecord page.
const useViewInpatientAndEDVisitsAPI = (
  page = 1,
  recordsPerPage,
  fetchData = true,
  searchTerm = null
) => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [inpatientVisitsList, setInpatientVisitsList] = useState([]);
  const { MemberStateName, memberRKSID } = useContext(AppContext);
  const [isImpatientloaded, setisImpatientloaded] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [facilityList, setFacilityList] = useState([]);

  const setData = (data) => {
    setFacilityList(data.DropDownItems);
    setInpatientVisitsList(data.InpatientEDVisits?.InpatientVisitsListMyMolina);
    setisImpatientloaded(true);
    if (data.InpatientEDVisits?.InpatientVisitsListMyMolina !== null)
      setTotalRecords(
        data.InpatientEDVisits?.InpatientVisitsListMyMolina[0]?.TotalRecords
      );
  };

  useEffect(() => {
    let filterParams = "";
    if (searchTerm) {
      filterParams = searchTerm["FilterParams"];
    }
    let query = {
      Page: page,
      RecordsPerPage: recordsPerPage,
      FilterParams: filterParams,
    };

    if (fetchData) {
      setInnerSpinner(true, MY_HEALTH_RECORD_VIEW_INPATIENT_AND_ED_VISITS);
      ViewInpatientAndEDVisits.getInpatientAndEDVisits(
        {
          RKSMemberId: memberRKSID,
          StateCode: MemberStateName,
          Page: page,
          RecordsPerPage: recordsPerPage,
          FilterParams: filterParams,
        },
        qs.stringify(query, { addQueryPrefix: true })
      )
        .then((response) => {
          if (response != undefined && response != null) {
            setData(response.ResultData);
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MY_HEALTH_RECORD_VIEW_INPATIENT_AND_ED_VISITS);
        });
    }
  }, [page, recordsPerPage, fetchData, searchTerm]);

  return { facilityList, inpatientVisitsList, isImpatientloaded, totalRecords };
};

export { useViewInpatientAndEDVisitsAPI };
