import Axios from "./axios/provider";

/**
 * GetAlerts!
 *
 * @param {object} data
 * @returns
 */
export const getAlertsInformation = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.GetAlerts, data);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * GetOerBanners!
 *
 * @param {object} data
 * @returns
 */
export const getOerBanners = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.GetOerBanners, data);
    return response;
  } catch (error) {
    throw error;
  }
};
