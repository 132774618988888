import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardMedicaidNV = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/NV/Medicaid/Member-ID-Card_NV.png)`,
          }}
          className="background-idcard"
          id="frontidcardimageNV"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="NVtable"
          >
            <tbody>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-sm-8 col-md-8 col-lg-8">
                      <div className="p2 medicaidNVleft">
                        <ul className="list-unstyled">
                          <li>
                            <b>
                              <Text field={fields.lblMember} />
                            </b>
                            &nbsp; {idCard?.Member_Name}
                          </li>
                          <li>
                            <div className="table-bold-seperatorNVdataleft">
                              <b>
                                <Text field={fields.lblmemberno} />
                              </b>
                              &nbsp; {idCard?.OriginalMemberProfile?.Number}
                            </div>
                          </li>
                          <li>
                            <b>
                              <Text field={fields.lblPlan} />
                            </b>
                            &nbsp; {idCard?.PlanName}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <div className="p2 medicaidNVright">
                        <ul className="list-unstyled">
                          <li>
                            <div className="table-bold-seperatorNVdataright">
                              <b>
                                <Text field={fields.lblBin} />
                              </b>
                              &nbsp; {result?.IdCardValues?.RxBIN}
                            </div>
                          </li>
                          <li>
                            <b>
                              <Text field={fields.lblPCN} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxPCN}
                          </li>
                          <li>
                            <b>
                              <Text field={fields.lblGroup} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxGRP}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/NV/Medicaid/NV_Back_View.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text backcard backcardNV">
            <div className="backcardmember">
              <div>
                <b>
                  <Text field={fields.lblIDBackMember} />
                </b>
                <Text field={fields.lblIDBackMemberData} />
              </div>
              <br />
              <div>
                <b>
                  <Text field={fields.lblIDBackRemitRural} />
                </b>
              </div>
              <br />
              <div>
                <b>
                  <Text field={fields.lblIDBackServices} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackServicesData} />
              </div>
              <div>
                <b>
                  <Text field={fields.lblIDBackNAL} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackNALData} />
              </div>
              <div>
                <b>
                  <Text field={fields.lblIDBackEnfermeras} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackEnfermerasData} />
              </div>

              <div>
                <b>
                  <Text field={fields.lblIDBackCVS} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackCVSData} />
              </div>
              <div>
                <b>
                  <Text field={fields.lblIDBackDental} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackDentalData} />
              </div>
              <div>
                <b>
                  <Text field={fields.lblIDBackComplaintContact} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackComplaintContactData} />
              </div>
            </div>
            <br />
            <div>
              <b>
                <Text field={fields.lblIDBackProvider} />
              </b>
            </div>
            <br />
            <div>
              <b>
                <Text field={fields.lblMedicalClaims} />
              </b>
              &nbsp;
              <Text field={fields.lblMedicalClaimsData} />
            </div>
            <br />
            <div>
              <Text field={fields.lblIDBackProviderData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicaidNV;
