import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as DoctorInfoAPI from "../services/DoctorInfoAPI";
import { AppContext } from "../contexts/app.context";
import { MY_DOCTOR } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";

const usePCPInfoAPI = () => {
  const { setError } = useContext(ErrorContext);
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { MemberStateName, memberRKSID } = useContext(AppContext);
  const [pcpinfo, setPCPInfo] = useState("");
  const [futurepcpinfo, setFuturePCPInfo] = useState("");

  const setData = (data) => {
    setPCPInfo(data.PCPInformation);
    setFuturePCPInfo(data.FuturePCPInformation);
  };

  useEffect(() => {
    // CHeck in user session
    if (memberRKSID != "") {
      setInnerSpinner(true, MY_DOCTOR);
      DoctorInfoAPI.getPCPInformation({
        RKSMemberID: memberRKSID,
        stateCode: MemberStateName,
      })
        .then((response) => {
          setData(response.ResultData.PCPInformation);
          setData(response.ResultData.FuturePCPInformation);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(() => setInnerSpinner(false, MY_DOCTOR));
    }
  }, [memberRKSID, MemberStateName]);

  return {
    pcpinfo,
    futurepcpinfo,
  };
};
export { usePCPInfoAPI };
