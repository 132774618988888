import React, { useContext, useState, useCallback, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Formik, Form, Field } from "formik";
import { AppContext } from "../../contexts/app.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { useViewContactPreferences } from "../../hooks/useViewContactPreferences";

function Communication(props) {
  let { homePhone, mailingAddress, prefEmailId } = useContext(AppContext);
  const initialValues = {
    homePhone: homePhone,
    mailingAddress: mailingAddress,
    prefEmailId: prefEmailId,
  };
  const { setActiveTab, tabOptions } = props;
  const { contactData, refreshMSGInfoAll, isViewContactLoaded } =
    useViewContactPreferences();
  let updatedHomePhone = UpdatedPhoneNumber(homePhone);
  function UpdatedPhoneNumber(phoneNumber) {
    const strPhone = phoneNumber?.replace(/[()]/g, "");
    const formattedPhone = strPhone?.replace(/\s/g, "-");
    return formattedPhone;
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        //onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="list-group-item langnavbox mb-4">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-md-6 col-sm-12">
                    <div className="row-details preferences-para">
                      <Text field={props?.fields?.updateDelivery} />
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title" style={{ fontSize: "16px" }}>
                      <label htmlFor="preferredPronoun">
                        <Text field={props?.fields?.lblPhone} />
                      </label>
                    </div>
                    <div className="row-details">
                      {!homePhone == "" && <div>{updatedHomePhone}</div>}
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title" style={{ fontSize: "16px" }}>
                      <label htmlFor="preferredPronoun">
                        <Text field={props?.fields?.lblText} />
                      </label>
                    </div>
                    <div className="row-details">
                      {!homePhone == "" && <div>{updatedHomePhone}</div>}
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-5 col-md-6 col-sm-12">
                    <div className="row-title" style={{ fontSize: "16px" }}>
                      <label htmlFor="preferredPronoun" className="mail-spacer">
                        <Text field={props?.fields?.lblMail} />
                      </label>
                      <button
                        type="button"
                        className={"btn btn-primary float-end btnUpdateMob"}
                        onClick={(e) =>
                          setActiveTab(e, 2, tabOptions[2]?.value)
                        }
                      >
                        <label htmlFor="updateButtonText">
                          <Text field={props?.fields?.updateButtonText} />
                        </label>
                      </button>
                    </div>
                    <div className="row-details pref_Email_Mob">
                      {prefEmailId}
                    </div>
                  </div>

                  {/* <div className="col-xxl-2 col-xl-2 col-md-12 col-sm-12">
                    <button
                      type="button"
                      className={"btn btn-primary float-end"}
                      onClick={(e) => setActiveTab(e, 2, tabOptions[2]?.value)}
                    >
                      <label htmlFor="updateButtonText">
                        <Text field={props?.fields?.updateButtonText} />
                      </label>
                    </button>
                  </div> */}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default Communication;
