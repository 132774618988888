import React from "react";
import MessagesIndex from "./MessagesIndex";
import { GLOBAL_VARIABLE } from "../../define.constants";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

const MyMessages = ({ fields }) => {
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");
  return <MessagesIndex fields={fields} />;
};

export default MyMessages;
