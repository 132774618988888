import Axios from "./axios/provider";
import { SpinnerContext } from "../contexts/spinner.context";

/**
 * Get Allergies List
 *
 * @param {object} data
 * @returns
 */
// This method is used to get the assessments and validations of a user
export const getAssessmentAndValidationList = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetAssessmentAndValidationList,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDynamicAssessmentQuestionsAndAnswers = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetDynamicAssessmentQuestionsAndAnswers,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDynamicAssessmentReportAPI = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.getDynamicAssessmentReportAPI,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const SubmitMemberAssessmentAnswersToCCA = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.SubmitMemberAssessmentAnswersToCCA,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProgressNoteViewReportFromCCA = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetProgressNoteViewReportFromCCA,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
// Api for download ssrs report pdf
export const getSSRSAssessment = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetSSRSAssessment,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
