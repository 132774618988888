import React, { useState, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Form, Field, FieldArray, ErrorMessage, Formik } from "formik";
import { highlightBorderOnError } from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import { InlineFormError } from "../../common/InlineFormError";
import { AppContext } from "./../../contexts/app.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  ICON_PATH,
  PHONE_DIGIT_ONLY_REGEX,
  IUD_FLAG_D,
  SUPPORT_USER,
  TERMED_MEMBER,
} from "../../define.constants";
const GenderIdentity = ({
  fields,
  errors,
  values,
  touched,
  initialTouched,
  setFieldValue,
  setFieldTouched,
  setValues,
  pageEditable,
  onButtonClick,
  contactPreferencesList,
  handleBlur,
  handleChange,
  getGenderIdentitySelected,
  handleGenderIdentityValue,
  contactData,
}) => {
  let genderIdentityList = [];
  let [genderIdentityFlag, setGenderIdentityFlag] = useState(true);
  let { isTermedMember } = useContext(AppContext);
  let genderId = 6; /*Added index value for comparing and displaying the texbox*/
  const handleGenderIdentitySelect = (e) => {
    if (e.value === "Additional gender category or other, please specify") {
      setGenderIdentityFlag(false);
    } else if (e.id === genderId) {
      setGenderIdentityFlag(false);
    } else {
      setGenderIdentityFlag(true);
    }
    getGenderIdentitySelected(e.value);
  };

  contactPreferencesList?.MH_GenderIdentity__pc?.map((data, index) => {
    genderIdentityList.push({
      value: data,
      id: index,
      label: data,
    });
  });
  const initialValues = {
    genderIdentityList: contactData?.IsContactPreferences?.GenderIdentity,
  };

  return (
    <>
      {initialValues.genderIdentityList !== undefined && (
        <Formik initialValues={initialValues}>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h5 className="tab-sub-title column-margin">
                  <Text field={fields?.genderIdentity ?? ""} />
                </h5>
                <div
                  className={
                    pageEditable
                      ? "disabled-div dropdown-list-width"
                      : "dropdown-list-width"
                  }
                >
                  <Field
                    as="select"
                    id="genderIdentityList"
                    name="genderIdentityList"
                    options={genderIdentityList}
                    value={
                      genderIdentityList
                        ? genderIdentityList.find(
                            (genderIdentityList) =>
                              genderIdentityList.value ===
                              values.genderIdentityList
                          )
                        : ""
                    }
                    onChange={(e) => handleGenderIdentitySelect(e)}
                    component={CustomSelect}
                    disabled={pageEditable}
                    isFormik={true}
                  />
                  <textarea
                    maxLength={20}
                    onChange={(e) => handleGenderIdentityValue(e)}
                    placeholder={getFieldValue(fields, "lblPreferredPronounPH")}
                    className={
                      genderIdentityFlag
                        ? "textarea-display"
                        : "form-control textarea-control"
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default GenderIdentity;
