import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewMedicaidIdCardIL = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardIL"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/IL/Medicaid/member-id-card-IL.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td className="table-il-plan">
                  <Text field={fields?.lblILMedicaidHeading} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td className="pb-2">
                  <label>
                    <b>
                      <Text field={fields?.lblMember} />
                    </b>
                  </label>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td className="pb-2">
                  <b>
                    <Text field={fields?.lblMemberIDIL} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td className="pb-2">
                  <b>
                    <Text field={fields?.lblDOB} />
                  </b>
                  &nbsp; {idCard?.Member_DOB}
                </td>
              </tr>
              <tr>
                <td className="pb-2">
                  <b>
                    <Text field={fields?.lblPCPIL} />
                  </b>
                  &nbsp; {idCard?.PCPName_Current}
                </td>
                <td>
                  <b>
                    <Text field={fields?.lblBin} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxBIN}
                </td>
              </tr>
              <tr>
                <td className="pb-2">
                  <b>
                    <Text field={fields?.lblPCPAdressIL} />
                  </b>
                  &nbsp; {idCard?.PCPAddress_Current}
                </td>
                <td>
                  <b>
                    <Text field={fields?.lblPCN} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxPCN}
                </td>
              </tr>
              <tr>
                <td className="pb-2">
                  <b>
                    <Text field={fields?.lblPCPPhoneIL} />
                  </b>
                  &nbsp; {idCard?.PCPPhone_Current}
                </td>
                <td>
                  <b>
                    <Text field={fields?.lblGroup} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxGRP}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields?.lblEffectiveDateIL} />
                  </b>
                  &nbsp; {idCard?.MemberEffectiveDate}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/IL/Medicaid/IL-Medicaid-MLTSS-id-card-Back-View.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            {/* <div>
              <Text field={fields.lblAZBackText} />
            </div> */}
            <div>&nbsp;</div>
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
              className="table-font-size-back"
            >
              <tbody>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblMemberBackIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblMemberBackValueIL} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblEmergencyIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblEmergencyValueIL} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblBehavirolIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblBehavirolValueIL} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblTransportationIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblTransportationValueIL} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblProviderIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblProviderValueIL} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblPriorAuthorizationIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblPriorAuthorizationValueIL} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblPharmacistsIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblPharmacistsValueIL} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblDentalAndVisionIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblDentalAndVisionValueIL} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblClaimIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblCliamValueIL} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblEDISubmissionIL} />
                    </b>
                    &nbsp; <Text field={fields?.lblEDISubmissionValueIL} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewMedicaidIdCardIL;
