import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";

const AllergiesRecords = (props) => {
  const { fields, data } = props;
  return (
    <>
      <div className="row print-heading mb-4 d-none">
        <Text field={props.fields.lblprintHeading} />
      </div>
      <ul className="list-group no-list-title" id="recordsToPrint">
        {data?.map((item, index) => (
          <li className="list-group-item " key={index}>
            <div className="row label-list">
              <DisplayItem
                nameField={fields.lblAllergicTo}
                outClass={"col-md-4 col-xl-4 details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblSymptoms}
                outClass={"col-md-4 col-xl-4 details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblDate}
                outClass={"col-md-4 col-xl-4 details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
            </div>
            <div className="row">
              <DisplayItem
                nameField={fields.lblAllergicTo}
                valueField={item.Substance}
                outClass={"col-md-12 col-xl-4 details d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblSymptoms}
                valueField={item.Presenting_Symptom}
                outClass={"col-md-12 col-xl-4 details d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblDate}
                valueField={item.Medical_Date}
                outClass={"col-md-12 col-xl-4 details d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default AllergiesRecords;
