import React, { useContext } from "react";
import ReactDOM from "react-dom";

function InnerSpinner() {
  return (
    <div className="inner-spinner">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default InnerSpinner;
