import React, { useState, useEffect, useContext, useRef } from "react";
import {
  ICON_PATH,
  PER_PAGE_MESSAGES,
  NUMBER_OF_RECORDS_PER_API_CALL,
  INBOX,
  ARCHIVE,
} from "../../define.constants";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useMessageAPI } from "../../hooks/useMessageAPI";
import { useMessageDetailAPI } from "../../hooks/useMessageDetailAPI";
import MessagesIndexItem from "./MessagesIndexItem";
import MessageDetails from "./MessageDetails";
import MessageTabs from "./MessageTabs";
import Search from "./Search";
import Sort from "./Sort";
import { Pagination } from "../../common/Pagination";
import NewMessageTab from "./NewMessageTab";
import PrintMessage from "./PrintMessage";
import ArchiveMessages from "./ArchiveMessages";
import { mobileAndTabletCheck, getFormattedDate } from "../../utils/helpers";
import { AppContext } from "./../../contexts/app.context";
import { useHistory } from "react-router-dom";
import { MESSAGES_LIST, MESSAGES_DETAILS } from "../../define.widgets";
import { SpinnerContext } from "../../contexts/spinner.context";

const MessagesIndex = ({ fields }) => {
  const { unreadInboxMsgCount, prefLang } = useContext(AppContext);
  const [messages, setMessages] = useState([]);
  const [slicedMessages, setSlicedMessages] = useState(null);
  const [openedMessage, SetOpenedMessage] = useState(null);
  const [userAction, setUserAction] = useState(INBOX);
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortBy, setSortBy] = useState("Date");
  const [dateSelected, setDateSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [isPageOpen, setIsPageOpen] = useState(false);
  const [isPrintingStart, setIsPrintingStart] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(INBOX);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const { refreshMemberInfoAll } = useContext(AppContext);

  const {
    inboxMessageList,
    totalRecords = inboxMessageList.length,
    attachments,
  } = useMessageAPI(
    NUMBER_OF_RECORDS_PER_API_CALL,
    userAction,
    page,
    sortBy,
    searchTerm,
    dateSelected,
    fetchData
  );

  const history = useHistory();
  let expandIcon = (
    <img
      src={`${ICON_PATH}/expand.svg`}
      width="24"
      height="24"
      alt="Maximize the Screen"
      title={getFieldValue(fields, "lblMaximize")}
    />
  );
  let shrinkIcon = (
    <img
      src={`${ICON_PATH}/shrink.svg`}
      width="24"
      height="24"
      alt="Minimize the Screen"
      title={getFieldValue(fields, "lblMinimize")}
    />
  );
  const refMsgDetails = useRef(null);

  useEffect(() => {
    setIsSmallScreen(mobileAndTabletCheck);
    if (totalRecords > 0) {
      setMessages((m) =>
        page > 1 ? [...m, ...inboxMessageList] : inboxMessageList
      );
      let queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get("mid")) {
        let om = inboxMessageList?.filter(
          (m) => m?.MessageID?.toString() === queryParams.get("mid").toString()
        );
        let sfid = queryParams.get("sfid");
        if (sfid === "null") {
          sfid = "";
        }
        if (om.length > 0) {
          SetOpenedMessage(om[0]);
          setIsMessageOpen(true);
          history.replace({});
          let list = localStorage.getItem("readMessageList");

          let readMessageList = Array.isArray(JSON.parse(list))
            ? JSON.parse(list)
            : [];
          readMessageList.push(om[0].MessageID);

          localStorage.setItem(
            "readMessageList",
            JSON.stringify(readMessageList)
          );
        }
      } else if (queryParams.get("SFMessageID")) {
        let om = inboxMessageList?.filter(
          (m) =>
            m?.SFMessageID?.toString() ===
            queryParams.get("SFMessageID").toString()
        );

        if (om.length > 0) {
          SetOpenedMessage(om[0]);
          setIsMessageOpen(true);
          history.replace({});
        }
      } else {
        SetOpenedMessage(inboxMessageList[0]);
      }
    }
  }, [inboxMessageList, totalRecords, page]);

  useEffect(() => {
    let slicedMessages = messages;
    const offset = (currentPage - 1) * PER_PAGE_MESSAGES;
    slicedMessages = messages?.slice(
      isSmallScreen ? offset : 0,
      offset + PER_PAGE_MESSAGES
    );
    setSlicedMessages(slicedMessages);
  }, [currentPage, messages, isSmallScreen]);

  const handleOnMessageSelect = (messageID) => {
    if (isSmallScreen) {
      setIsMessageOpen(true);
      refMsgDetails.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    SetOpenedMessage(
      messages?.filter(
        (m) => m?.MessageID?.toString() === messageID?.toString()
      )[0]
    );
  };

  const handleSort = (sortKey) => {
    resetPage();
    setUserAction(activeTab);
    setSortBy(sortKey);
  };

  const handleOnSearch = (searchParam, searchbydate = false) => {
    resetPage();
    if (!searchbydate) {
      setUserAction("search");
      setSearchTerm(searchParam);
    } else {
      setUserAction(userAction === ARCHIVE ? ARCHIVE : INBOX);
      setDateSelected(getFormattedDate(searchParam));
    }
  };

  const handleTabChange = (data) => {
    resetPage();
    setActiveTab(data);
    setUserAction(data);
    refreshMemberInfoAll();
  };
  const handleExpandClick = () => {
    if (isPageOpen == true) setIsPageOpen(false);
    else if (isPageOpen == false) setIsPageOpen(true);
  };

  const handleOnPrint = () => {
    setIsPrintingStart(true);
    setTimeout(() => window.print(), 100);
  };
  const onPageChangeHandler = (currentPage) => {
    setCurrentPage(currentPage);
    if (
      currentPage * PER_PAGE_MESSAGES >
      page * NUMBER_OF_RECORDS_PER_API_CALL
    ) {
      setFetchData(Date.now());
      setPage(page + 1);
    } else {
      setFetchData(false);
    }
  };

  const trackScrolling = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && totalRecords > currentPage * PER_PAGE_MESSAGES) {
      onPageChangeHandler(currentPage + 1);
    }
  };

  const handleOnArchived = () => {
    setFetchData(Date.now());
    handleTabChange(INBOX);
  };

  const resetPage = () => {
    setPage(1);
    setCurrentPage(1);
    setFetchData(Date.now());
    setSearchTerm(null);
    setSortBy("Date");
    setDateSelected(null);
  };

  return (
    <>
      <div className="container" ref={refMsgDetails}>
        <div className="row">
          <div className="col-xl-2 col-md-3">
            <div className="page-heading">
              <Text field={fields.heading} />
            </div>
          </div>
          {userAction !== ARCHIVE && (
            <Search
              inboxMessageList={messages}
              fields={fields}
              onMessageSelect={handleOnMessageSelect}
              onSearch={handleOnSearch}
            />
          )}
        </div>
      </div>
      <div className={`row sort-container`}>
        <div className="col-xl-2 col-md-4 col-sm-9">
          <MessageTabs
            labels={fields}
            messageCount={unreadInboxMsgCount}
            onTabSelect={handleTabChange}
            forDesktop={false}
          />
        </div>
        <Sort
          fields={fields}
          onSort={handleSort}
          onFilter={handleOnSearch}
          messageTabType={userAction}
        />
        {totalRecords >= 0 && (
          <div className="col-xl-7 col-md-12 text-right d-none d-xl-block p-b-xs">
            <NewMessageTab fields={fields} onPrint={handleOnPrint} />
            {userAction !== ARCHIVE && (
              <ArchiveMessages
                fields={fields}
                message={openedMessage}
                onArchived={handleOnArchived}
              />
            )}
            <PrintMessage fields={fields} onPrint={handleOnPrint} />
          </div>
        )}
      </div>
      <div className={`message-details-view ${isMessageOpen ? "open" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="back-btn"
                onClick={() => isSmallScreen && setIsMessageOpen(false)}
              >
                <img
                  src={`${ICON_PATH}/arrow-right.svg`}
                  alt={getFieldValue(fields, "lblInbox")}
                />
                <span>
                  <Text field={fields.lblInbox} />
                  {unreadInboxMsgCount != 0 && (
                    <span className="msg-counter">{unreadInboxMsgCount}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-right mobileMessage">
            <NewMessageTab fields={fields} onPrint={handleOnPrint} />
            {userAction !== ARCHIVE && totalRecords > 0 && (
              <ArchiveMessages
                fields={fields}
                forDesktop={false}
                message={openedMessage}
                onArchived={handleOnArchived}
              />
            )}
            {totalRecords > 0 && (
              <PrintMessage
                fields={fields}
                forDesktop={false}
                onPrint={handleOnPrint}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row inbox-container">
        <div className="col-xl-2 col-md-4 message-left-panel d-none d-md-block">
          <MessageTabs
            labels={fields}
            messageCount={unreadInboxMsgCount}
            onTabSelect={handleTabChange}
          />
        </div>

        <div
          className={`col-xl-3 col-md-8 ${
            !isSmallScreen ? `position-relative` : ``
          } ${isPageOpen ? `hide-div` : ``}`}
        >
          {innerSpinnerHTML?.[MESSAGES_LIST]}
          <div
            className="row message-inbox-panel"
            onScroll={(e) => trackScrolling(e)}
          >
            {slicedMessages?.length > 0 ? (
              <ul className="list-group">
                {slicedMessages.map((message, index) => {
                  return (
                    <MessagesIndexItem
                      onMessageSelect={handleOnMessageSelect}
                      key={`${index}`}
                      index={index}
                      message={message}
                      openedMessage={openedMessage}
                      messageTabType={userAction}
                      // attachments={attachments.filter(
                      //   (a) => a.MessageID === message.MessageID
                      // )}
                    />
                  );
                })}
              </ul>
            ) : (
              <p>
                <br />
                <Text field={fields.noMessage} />
              </p>
            )}
            {isSmallScreen && totalRecords > 0 && (
              <div className="col-12 pagination">
                <Pagination
                  totalRecords={totalRecords}
                  onPageChanged={onPageChangeHandler}
                  pageLimit={PER_PAGE_MESSAGES}
                />
              </div>
            )}
          </div>
        </div>
        {openedMessage?.MessageID && (
          <div
            className={`col-md-12 message-details  ${
              isPageOpen ? `col-xl-10` : `col-xl-7`
            }  ${isMessageOpen || isPrintingStart ? `open` : ``}`}
          >
            <div
              className={`expand-icon  ${isSmallScreen ? `hide-div` : ``}`}
              onClick={() => handleExpandClick()}
            >
              {isPageOpen ? shrinkIcon : expandIcon}
            </div>
            {innerSpinnerHTML?.[MESSAGES_DETAILS]}
            <MessageDetails
              fields={fields}
              message={openedMessage}
              prefLang={prefLang}
              userAction={userAction}
              // attachments={attachments.filter(
              //   (a) => a.MessageID === openedMessage.MessageID
              // )}
              // refMsgDetails={refMsgDetails}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MessagesIndex;
