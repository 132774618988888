import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardNonOHRisePartial = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/OH/Medicaid/Medicaid-OH-FRONT.png)`,
          }}
          className="background-idcard"
          id="OHMedicaid"
        >
          <div className="row mb50">
            <div className="col-sm-4 col-md-4 col-lg-4">&nbsp;</div>
            <div className="col-sm-8 col-md-8 col-lg-8 OHTitle">
              <div className="mb5">
                <b>
                  <Text field={fields.lblMemberServicePhone} />
                </b>
              </div>
              <div className="mb5">
                <b>
                  <Text field={fields.lbl24HourNurseAdvicePhone} />
                </b>
              </div>
              {/* <div>
                <b>
                  <Text field={fields.lblOhioRiseMemberServices} />
                </b>
              </div> */}
            </div>
          </div>

          <div className="row mb30">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="mb5">
                <b>
                  <Text field={fields.lblMember} />
                </b>
              </div>
              <div>{idCard?.Member_Name}</div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="mb5">
                <b>
                  <Text field={fields.lblMemberMmisId} />
                </b>
              </div>
              <div>{idCard?.OriginalMemberProfile?.Number}</div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="mb5">
                <b>
                  <Text field={fields.lblMolinaPlanId} />
                </b>
              </div>
              <div>{idCard?.OriginalMemberProfile?.Number}</div>
            </div>
          </div>

          <div className="row mb30">
            <div className="col-sm-5 col-md-4 col-lg-4">
              <b>
                <Text field={fields.lblInsurancerDate} />
              </b>
              {idCard?.IssuanceDate}
            </div>
            <div className="col-sm-7 col-md-8 col-lg-8">&nbsp;</div>
          </div>

          <div className="row">
            <div className="col-sm-5 col-md-4 col-lg-4">
              <div className="mb5 mt30">
                <b>
                  <Text field={fields.lblPrimaryCareProvider} />
                </b>
              </div>
              <div className="mb5">{idCard?.PCPName_Current}</div>
              <div className="OHPhone">
                <Text field={fields.lblPhone} /> &nbsp;
                {result?.IdCardValues?.Phone}
              </div>
            </div>
            <div className="col-sm-3 col-md-4 col-lg-4">&nbsp;</div>
            <div className="col-sm-4 col-md-4 col-lg-4 posrelative">
              <div className="OHBox">
                <div className="box">
                  <ul className="list-unstyled">
                    <li className="OHTitle">
                      <b>
                        <Text field={fields.lblPharmacyBenefit} />
                      </b>
                    </li>
                    <li className="heightOH">&nbsp;</li>
                    <li>
                      <Text field={fields.lblBin} />
                      {result?.IdCardValues?.RxBIN}
                    </li>
                    <li>
                      <Text field={fields.lblPCN} />
                      {result?.IdCardValues?.RxPCN}
                    </li>
                    <li>
                      <Text field={fields.lblPhone} />
                      {result?.IdCardValues?.Phone}
                    </li>
                    <li>
                      <Text field={fields.lblCspEnrolled} />
                    </li>
                    <li>
                      <Text field={fields.lblUseMemberIdForBilling} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/OH/Medicaid/OH-back-1.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text backcard oh-line-height">
            <div className="backcardmember">
              <div className="mb-2">
                <Text field={fields.lblMemberService24HoursePhone} />
              </div>
              <div className="mb-1">
                <b>
                  <Text field={fields.lblInformationForMembers} />
                </b>
              </div>
              <div className="mb-2">
                <Text field={fields.lblFindProviderAtMolinaProvider} />
              </div>
              <div className="row">
                <div className="col-sm-8 col-md-8 col-lg-8">
                  <div className="OHBackmrgn">
                    <b>
                      <Text field={fields.lblInformationForProviders} />
                    </b>
                  </div>
                  <div className="OHBackmrgn">
                    <Text field={fields.lblPleaseVerifyMemberEligibility} />
                  </div>
                  <div className="OHBackmrgn">
                    <Text field={fields.lblDesignatedPharmacy} />
                    <b>{idCard?.DesignatedPharmacy}</b>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4 posrelative">
                  <div className="CSPBlock2">
                    <div className="CSPText">
                      <b>
                        <Text field={fields.lblCsp} />
                      </b>
                    </div>
                    <div className="TextRX">
                      <Text field={fields.lblCSPRestricted} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardNonOHRisePartial;
