import React, { useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Form, Field, FieldArray, ErrorMessage, Formik } from "formik";
import { highlightBorderOnError } from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import { InlineFormError } from "../../common/InlineFormError";
import { AppContext } from "./../../contexts/app.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  ICON_PATH,
  PHONE_DIGIT_ONLY_REGEX,
  IUD_FLAG_D,
  SUPPORT_USER,
  TERMED_MEMBER,
} from "../../define.constants";
const PhonePreference = ({
  fields,
  errors,
  values,
  touched,
  initialTouched,
  setFieldValue,
  setFieldTouched,
  setValues,
  pageEditable,
  onButtonClick,
  communicationData,
  handleBlur,
  handleChange,
  isShowPhone,
}) => {
  let dropoptions = [];
  let { isTermedMember } = useContext(AppContext);
  communicationData?.LsMemberAPIInfo?.PhoneTypes?.map((data) => {
    dropoptions.push({
      value: data.TypeName,
      id: data.TypeName,
      label: data.LocalizedFields[0]?.Name,
    });
  });

  const handleChange1 = (selectedOption) => {
    {
    }
  };
  const AddRow = () => {
    const preferences = values?.PhonePreference?.preferences;
    preferences.push({
      phoneNumber: "",
      phoneType: "",
      preferredPhone: "",
      IUDFlag: null,
      VoiceConcent: false,
      TextConsent: false,
      isNewRow: true,
      isRowDeleted: false,
      OrderId: preferences?.filter((o) => o.IUDFlag !== IUD_FLAG_D).length + 1,
    });
    setValues({ ...values, preferences });
  };
  const ArrangeOrderofRow = (ind) => {
    const preferences = values?.PhonePreference?.preferences;
    let counter = 1;
    preferences.map((data, index) => {
      if (ind === index || data.IUDFlag === IUD_FLAG_D) {
        preferences[index].OrderId = null;
      } else {
        preferences[index].OrderId = counter;
        counter++;
      }
    });
    setValues({ ...values, preferences });
  };
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value?.replace(PHONE_DIGIT_ONLY_REGEX, "");
    const phoneNumberLength = phoneNumber?.length;
    if (phoneNumberLength < 4) return phoneNumber;
    else if (phoneNumberLength < 7) {
      return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3)}`;
    }
    return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(
      3,
      6
    )}-${phoneNumber?.slice(6, 10)}`;
  }

  function onFocusFormatPhoneNumber(value) {
    const phoneNumber = value?.replace(PHONE_DIGIT_ONLY_REGEX, "") + "";
    return phoneNumber;
  }

  const BindLabel = ({ children, htmlFor, cssClass, textDetails }) => {
    return (
      <>
        <label
          htmlFor={`PhonePreference.preferences.${htmlFor}`}
          className={`row-title ${cssClass} radio-btn`}
        >
          <Text field={textDetails ?? ""} />
          {children}
        </label>
      </>
    );
  };

  let count = 0;
  return (
    <>
      <Form>
        <div className="row">
          <div className="col-lg-12 col-md-8">
            <h5 className="tab-sub-title">
              <Text field={fields?.phoneNumberCardTitle ?? ""} />
              <WhoIsAuthorized
                authorize={{
                  [SUPPORT_USER]: true,
                  [TERMED_MEMBER]: false,
                }}
                handleClick={onButtonClick}
              >
                {({ handleClick, disabledClass }) => {
                  return (
                    <>
                      {pageEditable === true && isShowPhone ? (
                        <a
                          className={`edit ${disabledClass}`}
                          onClick={handleClick}
                        >
                          <Text
                            field={fields?.phoneNumberCardEditButton ?? ""}
                          />
                        </a>
                      ) : null}
                    </>
                  );
                }}
              </WhoIsAuthorized>
            </h5>
          </div>
        </div>
        <div className="col-sm-12">
          <p className="preferences-para">
            {!isShowPhone ? (
              <Text field={fields?.phoneCardWarnMessage ?? ""} />
            ) : (
              <Text field={fields?.phoneNumberCardDescription ?? ""} />
            )}
          </p>
        </div>
        <hr className="d-none d-sm-block d-xl-none" />
        <div>
          <FieldArray name="preferences">
            {(fieldArrayProp) => {
              return (
                <div>
                  {values?.PhonePreference?.preferences?.map(
                    (component, index) => (
                      component.IUDFlag !== IUD_FLAG_D ? count++ : "",
                      (
                        <div
                          id={`div_phoneNo_` + index}
                          key={index}
                          className={
                            component.IUDFlag === IUD_FLAG_D ? "d-none" : ""
                          }
                        >
                          <div className="row label-list">
                            <div className="col-xl-2 d-xl-block d-none">
                              <BindLabel
                                htmlFor={`${index}.phoneNumber`}
                                textDetails={fields?.phoneNumberHeading}
                              >
                                {component.OrderId}
                              </BindLabel>
                            </div>
                            <div className="col-xl-2 d-xl-block d-none">
                              <BindLabel
                                htmlFor={`${index}.phoneType`}
                                textDetails={fields?.phoneTypeHeading}
                              ></BindLabel>
                            </div>
                            <div className="col-xl-2 d-xl-block d-none radio-check-btn">
                              <BindLabel
                                htmlFor={`${index}.preferredPhone`}
                                textDetails={fields?.preferredPhoneHeading}
                              ></BindLabel>
                            </div>

                            <div className="col-xl-2 d-xl-block d-none radio-check-btn">
                              <BindLabel
                                htmlFor={`${index}.VoiceConcent`}
                                textDetails={fields?.voiceConsentHeading}
                              ></BindLabel>
                            </div>
                            <div className="col-xl-2 d-xl-block d-none radio-check-btn">
                              <BindLabel
                                htmlFor={`${index}.TextConsent`}
                                textDetails={fields?.textConsentHeading}
                              ></BindLabel>
                            </div>
                          </div>
                          <div className="row field1">
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                              <BindLabel
                                htmlFor={`${index}.phoneNumber`}
                                cssClass={`d-lg-block d-xl-none`}
                                textDetails={fields?.phoneNumberHeading}
                              >
                                {component.OrderId}
                              </BindLabel>

                              <div className="row-details">
                                <Field
                                  name={`PhonePreference.preferences.${index}.phoneNumber`}
                                  id={`PhonePreference.preferences.${index}.phoneNumber`}
                                  placeholder={getFieldValue(
                                    fields,
                                    "placeholderPhoneNumber",
                                    false
                                  )}
                                  type="text"
                                  disabled={pageEditable}
                                  onFocus={(event) => {
                                    setFieldValue(
                                      `PhonePreference.preferences.${index}.phoneNumber`,
                                      onFocusFormatPhoneNumber(
                                        event?.target?.value
                                      )
                                    );
                                  }}
                                  onChange={(event) => {
                                    handleChange(event);
                                    setFieldValue(
                                      `PhonePreference.preferences.${index}.phoneNumber`,
                                      onFocusFormatPhoneNumber(
                                        event?.target?.value
                                      )
                                    );
                                  }}
                                  maxLength={10}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                    setFieldValue(
                                      `PhonePreference.preferences.${index}.phoneNumber`,
                                      formatPhoneNumber(event?.target?.value)
                                    );
                                  }}
                                  className={
                                    pageEditable === true
                                      ? "form-control"
                                      : `form-control gray ${highlightBorderOnError(
                                          errors.PhonePreference
                                            ?.preferences !== undefined
                                            ? errors.PhonePreference
                                                ?.preferences[index]
                                                ?.phoneNumber
                                            : null,
                                          touched.PhonePreference
                                            ?.preferences != undefined
                                            ? touched.PhonePreference
                                                ?.preferences[index]
                                                ?.phoneNumber
                                            : null
                                        )}`
                                  }
                                />
                                <ErrorMessage
                                  name={`PhonePreference.preferences.${index}.phoneNumber`}
                                  component={InlineFormError}
                                />
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                              <BindLabel
                                htmlFor={`${index}.phoneType`}
                                cssClass={`d-lg-block d-xl-none`}
                                textDetails={fields?.phoneTypeHeading}
                              ></BindLabel>
                              <div className="row-details">
                                <Field
                                  as="select"
                                  name={`PhonePreference.preferences.${index}.phoneType`}
                                  id={`PhonePreference.preferences.${index}.phoneType`}
                                  placeholder={getFieldValue(
                                    fields,
                                    "placeholderPhoneType",
                                    false
                                  )}
                                  options={dropoptions}
                                  isDisabled={pageEditable}
                                  component={CustomSelect}
                                  onChange={(e) => {
                                    handleChange1(e);
                                    setFieldTouched(
                                      `PhonePreference.preferences.${index}.phoneType`,
                                      true
                                    );
                                  }}
                                  ariaLabel={getFieldValue(
                                    fields,
                                    "phoneTypeHeading",
                                    false
                                  )}
                                  customclass={
                                    (errors?.PhonePreference?.preferences !==
                                    undefined
                                      ? errors?.PhonePreference?.preferences[
                                          index
                                        ]?.phoneType
                                      : null) &&
                                    (component.isNewRow
                                      ? touched?.PhonePreference
                                          ?.preferences !== undefined
                                        ? touched?.PhonePreference?.preferences[
                                            index
                                          ]?.phoneType
                                          ? touched?.PhonePreference
                                              ?.preferences[index]?.phoneType
                                          : false
                                        : false
                                      : true)
                                      ? " molina-select__control_err"
                                      : ""
                                  }
                                />
                                <p className="text-danger">
                                  {(errors?.PhonePreference?.preferences !==
                                  undefined
                                    ? errors?.PhonePreference?.preferences[
                                        index
                                      ]?.phoneType
                                    : false) &&
                                  (component.isNewRow
                                    ? touched?.PhonePreference?.preferences !==
                                      undefined
                                      ? touched?.PhonePreference?.preferences[
                                          index
                                        ]?.phoneType
                                        ? touched?.PhonePreference?.preferences[
                                            index
                                          ]?.phoneType
                                        : false
                                      : false
                                    : true)
                                    ? errors?.PhonePreference.preferences[index]
                                        ?.phoneType
                                    : ""}
                                </p>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn">
                              <BindLabel
                                htmlFor={`${index}.preferredPhone`}
                                cssClass={`d-lg-block d-xl-none`}
                                textDetails={fields?.preferredPhoneHeading}
                              ></BindLabel>

                              <div className="row-details">
                                <div className="form-check">
                                  <Field
                                    name="PhonePreference.preferredPhone"
                                    id={`PhonePreference.preferences.${index}.preferredPhone`}
                                    type="radio"
                                    disabled={pageEditable}
                                    value={`${index}`}
                                    onChange={(event) => {
                                      setFieldValue(
                                        `PhonePreference.preferredPhone`,
                                        event?.target?.value
                                      );
                                      setFieldValue(
                                        `PhonePreference.preferences.${index}.preferredPhone`,
                                        event?.target?.value
                                      );
                                    }}
                                    className={"form-check-input"}
                                  />
                                </div>
                              </div>
                              {values?.PhonePreference?.preferences?.filter(
                                (o) => o.IUDFlag !== IUD_FLAG_D
                              )?.length === count && (
                                <ErrorMessage
                                  name={`PhonePreference.preferences.${0}.preferredPhone`}
                                  component={InlineFormError}
                                />
                              )}
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn">
                              <div
                                className="form-group"
                                role="group"
                                aria-labelledby={`PhonePreference.preferences.${index}.VoiceConcent`}
                              >
                                <BindLabel
                                  htmlFor={`${index}.VoiceConcent`}
                                  cssClass={`d-lg-block d-xl-none`}
                                  textDetails={fields?.voiceConsentHeading}
                                ></BindLabel>
                                <div className="row-details ">
                                  <div className="form-check">
                                    <Field
                                      id={`PhonePreference.preferences.${index}.VoiceConcent`}
                                      name={`PhonePreference.preferences.${index}.VoiceConcent`}
                                      type="checkbox"
                                      disabled={pageEditable}
                                      className="form-check-input"
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setFieldValue(
                                            `PhonePreference.preferences.${index}.VoiceConcent`,
                                            true
                                          );
                                        } else {
                                          setFieldValue(
                                            `PhonePreference.preferences.${index}.VoiceConcent`,
                                            false
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn">
                              <div
                                className="form-group"
                                role="group"
                                aria-labelledby={`PhonePreference.preferences.${index}.TextConsent`}
                              >
                                <BindLabel
                                  htmlFor={`${index}.TextConsent`}
                                  cssClass={`d-lg-block d-xl-none`}
                                  textDetails={fields?.textConsentHeading}
                                ></BindLabel>
                                <div className={"row-details "}>
                                  <div className="form-check">
                                    <Field
                                      id={`PhonePreference.preferences.${index}.TextConsent`}
                                      name={`PhonePreference.preferences.${index}.TextConsent`}
                                      type="checkbox"
                                      disabled={
                                        component.phoneType === "work"
                                          ? true
                                          : pageEditable
                                      }
                                      className="form-check-input"
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setFieldValue(
                                            `PhonePreference.preferences.${index}.TextConsent`,
                                            true
                                          );
                                        } else {
                                          setFieldValue(
                                            `PhonePreference.preferences.${index}.TextConsent`,
                                            false
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn phone">
                              {pageEditable === false && (
                                <>
                                  {values?.PhonePreference?.preferences
                                    ?.length > 0 &&
                                    (values?.PhonePreference?.preferredPhone ===
                                    index + "" ? (
                                      <>
                                        <img
                                          src={`${ICON_PATH}/remove_circle-light-24px.svg`}
                                          alt={getFieldValue(
                                            fields,
                                            "lblAltDeleteGrayIcon",
                                            false
                                          )}
                                          className={
                                            values?.PhonePreference
                                              ?.preferredPhone ===
                                            index + ""
                                              ? "d-none d-sm-none d-md-block disabled-link"
                                              : ""
                                          }
                                          onClick={() => {
                                            setFieldValue(
                                              `PhonePreference.preferences.${index}.IUDFlag`,
                                              IUD_FLAG_D
                                            );
                                          }}
                                        />
                                        <button
                                          type="button"
                                          className={
                                            values?.PhonePreference
                                              ?.preferredPhone ===
                                            index + ""
                                              ? "btn btn-delete d-none d-sm-block d-md-none disabled-link"
                                              : ""
                                          }
                                          onClick={() => {
                                            setFieldValue(
                                              `PhonePreference.preferences.${index}.IUDFlag`,
                                              IUD_FLAG_D
                                            );
                                          }}
                                        >
                                          <Text
                                            field={fields?.deleteButton ?? ""}
                                          />
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={`${ICON_PATH}/remove_circle-24px.svg`}
                                          alt={getFieldValue(
                                            fields,
                                            "lblAltDeleteBlackIcon",
                                            false
                                          )}
                                          className={
                                            values?.PhonePreference
                                              ?.preferredPhone ===
                                            index + ""
                                              ? "disabled-link"
                                              : "d-none d-sm-none d-md-block"
                                          }
                                          onClick={() => {
                                            ArrangeOrderofRow(index);
                                            setFieldValue(
                                              `PhonePreference.preferences.${index}.IUDFlag`,
                                              IUD_FLAG_D
                                            );
                                          }}
                                        />
                                        <button
                                          type="button"
                                          className="btn btn-delete d-none d-sm-block d-md-none"
                                          onClick={() => {
                                            ArrangeOrderofRow(index);
                                            setFieldValue(
                                              `PhonePreference.preferences.${index}.IUDFlag`,
                                              IUD_FLAG_D
                                            );
                                          }}
                                        >
                                          <Text
                                            field={fields?.deleteButton ?? ""}
                                          />
                                        </button>
                                      </>
                                    ))}
                                </>
                              )}
                            </div>
                          </div>
                          {values?.PhonePreference?.preferences?.length - 1 >
                            index && (
                            <hr className="d-none d-sm-block d-xl-none" />
                          )}
                        </div>
                      )
                    )
                  )}
                  {values?.PhonePreference?.preferences?.filter(
                    (o) => o.IUDFlag !== IUD_FLAG_D
                  ).length < 4 && (
                    <div className="row">
                      <div className="col-lg-6 col-md-8">
                        <button
                          type="button"
                          className={
                            pageEditable === true
                              ? "btn-add btn-disabled"
                              : "btn-add state-color-txt"
                          }
                          disabled={pageEditable}
                          onClick={AddRow}
                        >
                          <Text field={fields?.phoneCardAddButton ?? ""} />
                        </button>
                        <span className="add-content">
                          <Text
                            field={fields?.phoneCardAddButtonDescription ?? ""}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </FieldArray>
        </div>
      </Form>
    </>
  );
};
export default PhonePreference;
