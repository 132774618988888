import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import {
  dynamicControlConstants,
  assessmentConstants,
} from "../../utils/constants";
import {
  ICON_PATH,
  ENGLISH_LANG_CODE,
  PAGE_ID,
  SUCCESS_RESULT_CODE,
  SUPPORT_USER,
  TERMED_MEMBER,
} from "../../define.constants";
import { useLocation } from "react-router-dom";
import FormikControls from "./FormikControls";
import * as Yup from "yup";
import { AppContext } from "./../../contexts/app.context";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import InitializeValueAndValidations from "./ValidationFunction";
import * as AssessmentsAPI from "../../services/AssessmentsAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ModalContext } from "../../contexts/modal.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import { MY_WELLNESS_ASSESSMENT_FORM } from "../../define.widgets";
import { ErrorContext } from "../../contexts/error.context";

import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { __esModule } from "react-router-dom/cjs/react-router-dom.min";
import { parse } from "graphql";
const AssessmentForm = (props) => {
  const { setSpinner, setInnerSpinner, innerSpinnerHTML } =
    useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const { openModal, closeModal, isOpen } = useContext(ModalContext);
  const [isSaveDraftButtonClicked, setIsSaveDraftButtonClicked] =
    useState(false);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
  const [childAssessment, setChildAssessment] = useState(false);
  const [isAssessmentSubmitted, setIsAssessmentSubmitted] = useState(false);
  const [childIsAssessmentSubmitted, setChildIsAssessmentSubmitted] =
    useState(false);
  const [isFormReseted, setIsFormReseted] = useState(false);
  let { isSupportUser } = useContext(AppContext);
  const loc = useLocation();
  const pageURL = loc?.pathname;
  useEffect(() => {
    if (props.isResultsloaded && isSaveButtonClicked) {
      setIsSaveButtonClicked(false);
      successModalPopUp();
      if (isNextButtonClicked)
        document.getElementById("steps").scrollIntoView();
    }
    if (props.isResultsloaded || props.currentStepChange) {
      setIsFormReseted(false);
    }
  }, [props.isResultsloaded, isSaveButtonClicked, props.currentStepChange]);
  useEffect(() => {
    if (!isOpen && isSaveDraftButtonClicked) setIsSaveDraftButtonClicked(false);
    if (
      !isOpen &&
      props?.nextPageId === PAGE_ID &&
      isAssessmentSubmitted &&
      !childIsAssessmentSubmitted
    ) {
      location.href = pageURL;
    }
  }, [isOpen]);
  const FooterContent = () => {
    return (
      <div className="datasharing-modal mywellness-modal">
        <div className="datasharing-btns">
          {(props.nextPageId !== PAGE_ID ||
            isSaveDraftButtonClicked ||
            childIsAssessmentSubmitted) &&
            !isAssessmentSubmitted && (
              <button
                type="button"
                className="btn btn-primary"
                data-mdb-dismiss="modal"
                onClick={(e) => {
                  if (isNextButtonClicked) {
                    setIsNextButtonClicked(false);
                    props.onButtonClick();
                  }
                  setIsSaveDraftButtonClicked(false);
                  closeModal(e);
                }}
              >
                <Text field={props?.fields?.lblOkay} />
              </button>
            )}
        </div>
        <div className="text-center">
          {props.nextPageId === PAGE_ID &&
            isAssessmentSubmitted &&
            !childIsAssessmentSubmitted && (
              <a href="" className="a-link">
                <Text field={props?.fields?.myWellnessTitle} />
                <img
                  src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                  alt={props?.fields?.myWellnessTitle?.value}
                  onClick={(e) => {
                    location.href = pageURL;
                  }}
                ></img>
              </a>
            )}
        </div>
      </div>
    );
  };
  const FooterAlertContent = () => {
    return (
      <div className="datasharing-modal mywellness-modal">
        <div className="datasharing-btns">
          <button
            type="button"
            className="btn btn-primary"
            data-mdb-dismiss="modal"
            onClick={(e) => {
              if (isNextButtonClicked) {
                setIsNextButtonClicked(false);
              }
              setIsAssessmentSubmitted(false);
              setIsSaveDraftButtonClicked(false);
              closeModal(e);
            }}
          >
            <Text field={props?.fields?.lblOkay} />
          </button>
        </div>
      </div>
    );
  };
  const successModal = (
    <>
      <div className="datasharing-modal">
        <div className="update-header">
          <h5 className="head">
            <Text field={props?.fields?.lblAssessment} />
          </h5>
          <p className="datasharing-para">
            {(props.nextPageId !== PAGE_ID ||
              (props.nextPageId === PAGE_ID && !isAssessmentSubmitted) ||
              childIsAssessmentSubmitted) && (
              <Text field={props?.fields?.lblAssessmentContent} />
            )}
            {props.nextPageId === PAGE_ID &&
              isAssessmentSubmitted &&
              !childIsAssessmentSubmitted && (
                <Text field={props?.fields?.lblAssessmentSubmittedContent} />
              )}
          </p>
        </div>
      </div>
      <FooterContent />
    </>
  );
  const alertModal = (
    <>
      <div className="datasharing-modal">
        <div className="update-header">
          <h5 className="head">
            <Text field={props?.fields?.lblAlert} />
          </h5>
          <p className="datasharing-para">
            <Text field={props?.fields?.lblAlertContent} />
          </p>
        </div>
      </div>
      <FooterAlertContent />
    </>
  );
  const successModalPopUp = () => {
    openModal({
      content: successModal,
      version: 1,
    });
  };
  const alertModalPopUp = () => {
    openModal({
      content: alertModal,
      version: 1,
    });
  };
  try {
    let initialValues = {};
    let validationList = {};
    let answerDetails = props.assessmentAnswer;
    props.questionList?.map((question, i) => {
      if (question.AspControlType != dynamicControlConstants.hidden) {
        let qId = question?.QuestionId;
        let parentId = question?.ParentQuestionId;
        InitializeValueAndValidations(
          props.fields,
          question,
          initialValues,
          validationList,
          props.assessmentOptions,
          answerDetails,
          isFormReseted
        );
      }
    });
    const validationSchema = Yup.object().shape(validationList);
    const initialValuesData = initialValues;
    const onSubmit = (fields) => {
      let html = "";
      let answerList = props.assessmentAnswer;
      props.questionList?.map((question, ind) => {
        html += questionAnswerListForSubmit(question, fields);
        if (answerList?.length > 0) {
          answerList = questionAnswerListForSubmitArr(question, answerList);
        }
      });

      answerList?.map((ans, i) => {
        html += `<QUESTION><id>${ans.QuestionId}</id><value>${ans.Answer}</value></QUESTION>`;
      });
      let assessmentAction = assessmentConstants.save;
      if (
        (props?.nextPageId === PAGE_ID || props?.NextPageId === "") &&
        !isSaveDraftButtonClicked
      )
        assessmentAction = assessmentConstants.submit;

      let requestJson = {
        SecMemId: "",
        SecRoleId: "",
        DropdownSelectedMemId: "",
        CaseManagerUserId: "",
        Operation: assessmentAction,
        AssessmentId: props?.assessment?.id,
        VersionId: props?.assessment?.version,
        AssessmentRequest: "<QUESTIONS>" + html + "</QUESTIONS>",
      };
      let getAssessmentDetails = JSON.parse(
        localStorage.getItem("AssessmentData")
      );
      if (getAssessmentDetails == null) {
        let getChildAssessmnetId = checkAssessmentBranchCondition(
          props,
          props.assessmentBranchCondition,
          fields
        );
        if (getChildAssessmnetId != null) {
          let assessmentlistArr = [];
          assessmentlistArr = props.assessments?.filter(
            (o) => o.id === getChildAssessmnetId.OtherHraId
          );
          if (assessmentlistArr.length > 0) {
            let assessment = {};
            assessment.newAssessmentId = getChildAssessmnetId.OtherHraId;
            assessment.newVersion = assessmentlistArr[0].version;
            assessment.existAssessmentId = props.assessment.id;
            assessment.existsVersion = props.assessment.version;
            assessment.existsNextPageId = props.nextPageId;
            assessment.existsPageId = props.PageId;
            assessment.currentStep = props.currentStep;
            assessment.assessmentPages = 0;
            assessment.currentAssessmentPage = 0;
            localStorage.setItem("AssessmentData", JSON.stringify(assessment));
            props.assessment.id = getChildAssessmnetId.OtherHraId;
            props.assessment.version = assessmentlistArr[0].version;
          }
        }
      } else {
        if (props.assessment.id == getAssessmentDetails.newAssessmentId) {
          let assessmentPages = parseInt(getAssessmentDetails.assessmentPages);
          if (assessmentPages == getAssessmentDetails.currentAssessmentPage) {
            props.assessment.id = getAssessmentDetails.existAssessmentId;
            props.assessment.version = getAssessmentDetails.existsVersion;
            setChildAssessment(false);
          } else if (
            getAssessmentDetails.assessmentPages - 1 ==
            getAssessmentDetails.currentAssessmentPage
          ) {
            setChildAssessment(true);
          }
        }
      }

      setSpinner(true);
      AssessmentsAPI.SubmitMemberAssessmentAnswersToCCA(requestJson)
        .then((response) => {
          if (
            response?.ResultCode === SUCCESS_RESULT_CODE &&
            (response?.ResultData?.SaveStatus ||
              response?.ResultData?.SubmitStatus)
          ) {
            props.refreshAPIDataAll();
            setIsSaveButtonClicked(true);
            if (
              (props?.nextPageId === PAGE_ID || props?.NextPageId === "") &&
              !isSaveDraftButtonClicked
            ) {
              setChildIsAssessmentSubmitted(false);
              if (
                props.assessment.id == getAssessmentDetails?.existAssessmentId
              ) {
                let assessmentPages = parseInt(
                  getAssessmentDetails?.assessmentPages
                );
                if (
                  assessmentPages == getAssessmentDetails?.currentAssessmentPage
                ) {
                  setChildIsAssessmentSubmitted(true);
                }
              } else {
                setIsAssessmentSubmitted(true);
              }
            }
          } else {
            alertModalPopUp();
          }
        })
        .catch((err) => {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    };

    const checkAssessmentBranchCondition = (
      props,
      assessmentBranchCondition,
      fields
    ) => {
      let fieldsAnswer = [];
      let branchConditionValue = {};
      let assessmentBranchList = assessmentBranchCondition?.filter(
        (o) => o.PageID == "" + props.PageId && o.BranchCondition != null
      );
      if (assessmentBranchList?.length > 0) {
        for (const fieldsQuestionKey in fields) {
          let item = {};
          item.questionId = fieldsQuestionKey.slice(
            fieldsQuestionKey.indexOf("_") + 1
          );
          item.answer = fields[fieldsQuestionKey];
          item.conceptid = "";

          props.questionList.forEach((question) => {
            if (question.QuestionId == item.questionId) {
              item.conceptid = question.Conceptid;
            }
          });
          fieldsAnswer.push(item);
        }

        let branchCondition = assessmentBranchList[0].BranchCondition;
        const branchConditionAnswer = validateConditionOperator(
          fieldsAnswer,
          branchCondition.replace("SELECT 1 WHERE", "")
        );
        if (branchConditionAnswer) {
          let nextPageId = assessmentBranchList[0].NextPageID;
          if (nextPageId != null) {
            let assessmentPageOtherHrId = props.assessmentPages?.filter(
              (o) => o.PageId == nextPageId
            );
            if (assessmentPageOtherHrId?.length > 0) {
              branchConditionValue.OtherHraId =
                assessmentPageOtherHrId[0].OtherHraId;
              branchConditionValue.NextPageId =
                assessmentPageOtherHrId[0].NextPageId;
              return branchConditionValue;
            } else {
              return null;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    };

    const validateConditionOperator = (fieldsAnswer, branchCondition) => {
      const breakpointOperator = /\OR/;
      const splitted = branchCondition.split(breakpointOperator);
      const checkCondition = [];
      if (splitted?.length > 0) {
        splitted.forEach((items) => {
          const conditions = ["OR", "AND"];
          let containOperator = conditions.some((el) => items.includes(el));
          if (containOperator && items.includes("AND")) {
            const checkChildCondition = [];
            const childOperator = /\AND/;
            const splittedChildOperator = items.split(childOperator);
            splittedChildOperator.forEach((splitItem) => {
              let newItem = {};
              newItem.condition = splitItem;
              newItem.b_condition = false;
              let conceptid = getConceptIdValue(splitItem, "N'", "#'", 2);
              fieldsAnswer.forEach((fieldItem) => {
                if (fieldItem.conceptid == conceptid) {
                  newItem.b_condition = getOperationValue(
                    fieldItem.answer,
                    splitItem.replace(`N'${conceptid}#`, "")
                  );
                }
              });
              checkChildCondition.push(newItem);
            });
            let checkValue = checkChildCondition.every(
              (obj) => obj.b_condition === true
            );

            let newItem = {};
            newItem.condition = items;
            newItem.b_condition = checkValue;
            checkCondition.push(newItem);
          } else {
            let newItem = {};
            newItem.condition = items;
            newItem.b_condition = false;
            let conceptid = getConceptIdValue(items, "'", "#'", 1);
            fieldsAnswer.forEach((fieldItem) => {
              if (fieldItem.conceptid == conceptid) {
                newItem.b_condition = getOperationValue(
                  fieldItem.answer,
                  items.replace(`'${conceptid}#`, "")
                );
              }
            });
            checkCondition.push(newItem);
          }
        });
      }
      if (branchCondition.includes("OR")) {
        if (checkCondition.length > 0) {
          let checkValue = false;
          checkCondition.forEach((items) => {
            if (items.b_condition) {
              checkValue = true;
            }
          });
          return checkValue;
        } else {
          return false;
        }
      }
    };

    const getOperationValue = (answer, condition) => {
      if (condition.includes("IN")) {
        let getValue = getConceptIdValue(condition, "(", ")", 1);
        let getValueSplit = getValue.split(",");
        let conditionCheckValue = [];
        if (getValueSplit.length > 0) {
          getValueSplit.forEach((items) => {
            const itemReplaceQuotes = items.replace(/'/g, "");
            const itemReplaceSpace = itemReplaceQuotes.replace(/ /g, "");
            conditionCheckValue.push(itemReplaceSpace);
          });
        }
        if (conditionCheckValue.includes(answer)) {
          return true;
        } else {
          return false;
        }
      } else if (condition.includes("=")) {
        let getValue = getSubstring(condition, "= '", "'");
        if (getValue == answer) {
          return true;
        } else {
          return false;
        }
      }
    };

    const getSubstring = (str, char1, char2) => {
      const index = str.indexOf(char1);
      const result = str.substring(index + 3, index + 4);
      return result;
    };

    const getConceptIdValue = (str, char1, char2, idx) => {
      const index = str.indexOf(char1);
      const index1 = str.indexOf(char2);
      const result = str.substring(index + idx, index1);
      return result;
    };

    const questionAnswerListForSubmitArr = (question, answerList) => {
      let arr = [];
      arr = answerList?.filter((o) => o.QuestionId !== question.QuestionId);
      if (question.IsChild) {
        question?.SubListQuestion.map((subQuestion, i) => {
          arr = questionAnswerListForSubmitArr(subQuestion, arr);
        });
      }
      return arr;
    };
    const questionAnswerListForSubmit = (question, fields, isChild = false) => {
      let val = fields["question_" + question.QuestionId] + "";
      val = val === undefined || val === "undefined" || val === null ? "" : val;
      let html = "";
      if (question.AspControlType === dynamicControlConstants.checkbox) {
        val = val == "" ? `[]` : `[\"${val.replace(/[,]/g, `\",\"`)}\"]`;
      } else if (
        question.AspControlType === dynamicControlConstants.select ||
        question.AspControlType === dynamicControlConstants.radio
      ) {
        val = val == "" ? `[]` : `[\"${val}\"]`;
      } else if (question.AspControlType === dynamicControlConstants.hidden) {
        val = question.QuestionText;
      }
      html = `<QUESTION><id>${question.QuestionId}</id><value>${val}</value></QUESTION>`;
      if (question.IsChild) {
        question?.SubListQuestion.map((subQuestion, i) => {
          let subQHtml = questionAnswerListForSubmit(subQuestion, fields, true);
          html += subQHtml;
        });
      }
      return html;
    };
    const resetFormData = (question, arrInitialValues) => {
      let AspControlType = question?.AspControlType;
      let qId = question?.QuestionId;
      if (!question.IsReadOnly) {
        switch (AspControlType) {
          case dynamicControlConstants.checkbox:
            arrInitialValues[`question_${qId}`] = [];
            break;
          case dynamicControlConstants.scoreid:
          case dynamicControlConstants.hidden:
            break;
          default:
            arrInitialValues[`question_${qId}`] = "";
            break;
        }
      }
      if (question.IsChild) {
        question?.SubListQuestion.map((subQuestion, i) => {
          arrInitialValues = resetFormData(subQuestion, arrInitialValues);
        });
      }
      return arrInitialValues;
    };
    const handleReset = (resetForm) => {
      try {
        let values = initialValues;
        props.questionList?.map((question, ind) => {
          values = resetFormData(question, values);
        });
        initialValues = values;
        setIsFormReseted(true);
        resetForm({ values: initialValues });
      } catch (e) {
        console.log(e);
      }
    };
    return (
      <Formik
        enableReinitialize
        initialValues={initialValuesData}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          values,
          checked,
          touched,
          setValues,
          setFieldValue,
          resetForm,
          handleSubmit,
        }) => (
          <Form>
            <div className="dynamic-data">
              <div className="row">
                <div className="col-lg-12">
                  {props.prefLang?.toLowerCase() ===
                  ENGLISH_LANG_CODE.toLowerCase() ? (
                    <>
                      {props.PageName && (
                        <h5 className="card-title">{props.PageName}</h5>
                      )}
                    </>
                  ) : (
                    <>
                      {props.SpanishPageName && (
                        <h5 className="card-title">{props.SpanishPageName}</h5>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div>
                {props.assessmentContent
                  ?.filter((obj) => obj.dependantQuestionId === PAGE_ID)
                  .map((content, ind) => {
                    return (
                      <div key={ind} className="row space-between">
                        <div className="col-md-12">
                          {props.prefLang?.toLowerCase() ===
                          ENGLISH_LANG_CODE.toLowerCase() ? (
                            <div
                              // className="spacing-left"
                              dangerouslySetInnerHTML={{
                                __html: content.contentText,
                              }}
                            ></div>
                          ) : (
                            <div
                              // className="spacing-left"
                              dangerouslySetInnerHTML={{
                                __html: content.spanishContentText,
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                {props.questionList?.map((question, i) => {
                  return (
                    <div key={i} className="row space-between">
                      <FormikControls
                        question={question}
                        index={i}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        assessmentOptions={props.assessmentOptions}
                        assessmentContent={props.assessmentContent}
                        disabledPage={props.disabledPage}
                      />
                    </div>
                  );
                })}
                <div className="dynamic-btn">
                  {props.questionList?.length > 0 &&
                    props.disabledPage !== true && (
                      <WhoIsAuthorized
                        authorize={{
                          [SUPPORT_USER]: false,
                          [TERMED_MEMBER]: false,
                        }}
                        handleClick={(e) => {
                          setIsSaveDraftButtonClicked(true);
                          handleSubmit();
                        }}
                      >
                        {({ handleClick, disabledClass }) => {
                          return (
                            <button
                              type="button"
                              className="btn draft-btn"
                              onClick={handleClick}
                            >
                              <Text field={props?.fields?.saveAsDraftButton} />
                            </button>
                          );
                        }}
                      </WhoIsAuthorized>
                    )}
                  {props.nextPageId === PAGE_ID &&
                    props.disabledPage !== true &&
                    localStorage.getItem("AssessmentData") == null && (
                      <WhoIsAuthorized
                        authorize={{
                          [SUPPORT_USER]: false,
                          [TERMED_MEMBER]: false,
                        }}
                        handleClick={handleSubmit}
                      >
                        {({ handleClick, disabledClass }) => {
                          return (
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              onClick={handleClick}
                            >
                              <Text field={props?.fields?.submitButton} />
                            </button>
                          );
                        }}
                      </WhoIsAuthorized>
                    )}
                  {props.disabledPage === true && (
                    <button
                      type="button"
                      className="btn btn-primary mr-1"
                      onClick={() => {
                        document.getElementById("steps").scrollIntoView();
                        if (props.nextPageId !== PAGE_ID) props.onButtonClick();
                        else props.backToMyWellness();
                      }}
                    >
                      {props.nextPageId !== PAGE_ID && (
                        <Text field={props?.fields?.nextButton} />
                      )}
                      {props.nextPageId === PAGE_ID && (
                        <>{<Text field={props?.fields?.lblClose} />}</>
                      )}
                    </button>
                  )}
                  {(props.nextPageId !== PAGE_ID || childAssessment) &&
                    !props.disabledPage && (
                      <>
                        <WhoIsAuthorized
                          authorize={{
                            [SUPPORT_USER]: true,
                            [TERMED_MEMBER]: false,
                          }}
                          handleClick={(e) => {
                            if (isSupportUser === false) {
                              setIsNextButtonClicked(true);
                              handleSubmit();
                            } else {
                              document.getElementById("steps").scrollIntoView();
                              if (props.nextPageId !== PAGE_ID) {
                                props.onButtonClick();
                              } else {
                                props.backToMyWellness();
                              }
                            }
                          }}
                        >
                          {({ handleClick, disabledClass }) => {
                            return (
                              <button
                                className="btn next-btn"
                                type="button"
                                onClick={(e) => handleClick(e)}
                              >
                                <Text field={props?.fields?.nextButton} />
                              </button>
                            );
                          }}
                        </WhoIsAuthorized>
                      </>
                    )}
                  <div>
                    {props.questionList?.length > 0 &&
                      props.disabledPage !== true && (
                        <button
                          className="btn clear-btn"
                          type="button"
                          onClick={() => handleReset(resetForm)}
                        >
                          <Text field={props?.fields?.clearFormButton} />
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};
export default withModalProvider(AssessmentForm);
