import React from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";

import { ICON_PATH } from "../../define.constants";

const CommonError = (props) => (
  <div className="Registration">
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <section className="login-section d-flex justify-content-center">
          <div className="card lg-card">
            <div className="card-body">
              <h5 className="card-title text-center">
                <Text field={props.fields.heading} />
              </h5>
              <div className="card-subtitle text-center">
                <Text field={props.fields.Subheading} />
              </div>
              <div className="row create-account">
                <div className="col-md-6 col-12">
                  <div className="media">
                    <img
                      src={`${ICON_PATH}/arrow-right-small.svg`}
                      className="d-flex align-self-start left-arrow-custom"
                      alt={getFieldValue(props.fields, "Redirectlink")?.text}
                    />
                    <div className="media-body">
                      <a
                        href={getFieldValue(props.fields, "Redirectlink")?.href}
                      >
                        <span>
                          {getFieldValue(props.fields, "Redirectlink")?.text}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
);

export default CommonError;
