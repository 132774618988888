import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { ErrorContext } from "../contexts/error.context";
import { MARKETPLACE } from "../define.constants";
import { getOneildigitalid } from "../services/OneilIdCardAPI";
const useOneilIdCardAPI = (memberId, MemberStateName, year, memberLOB) => {
  const { setError } = useContext(ErrorContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [result, setResult] = useState([]);
  const [statusCode, setStatusCode] = useState();
  const isValidInput = (memberId, yearVal, lobVal) => {
    if (lobVal?.toLowerCase() === MARKETPLACE && memberId && yearVal < 2025) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (isValidInput(memberId, year, memberLOB)) {
      setSpinner(true);
      setResult([]);
      setStatusCode(undefined);
      getOneildigitalid(
        MemberStateName,
        memberLOB.toLowerCase(),
        [memberId],
        "Digital",
        year.toString()
      )
        .then((response) => {
          setResult(response);
        })
        .catch(function (err) {
          if (err.statusCode !== 404) {
            setError({ err });
          }
          setStatusCode(err.statusCode);
        })
        .finally(function () {
          setSpinner(false);
        });
    }
  }, [setSpinner, memberId, MemberStateName, year, memberLOB]);
  return {
    result,
    statusCode,
  };
};
export { useOneilIdCardAPI };
