import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import DisplayTitleItem from "../MyHealthRecord-Layout/Common/DisplayTitleItem";

const ReferralsRecords = (props) => {
  const { fields, data } = props;
  return (
    <>
      <div className="row print-heading mb-4 d-none">
        <Text field={fields.lblprintHeading} />
      </div>
      <ul className="list-group d-md-block d-none" id="recordsToPrint">
        {data?.map((item, index) => (
          <li className="list-group-item " key={index}>
            <div className="row label-list">
              <DisplayTitleItem
                nameField={fields.lblReferralID}
                outClass={"col-xl-5 d-xl-block d-none col-list-title"}
              />
              <DisplayTitleItem
                nameField={fields.lblReferredToSpecialty}
                outClass={"col-xl-7 d-xl-block d-none col-list-title"}
              />
            </div>
            <div className="row">
              <DisplayItem
                nameField={fields.lblReferralID}
                valueField={fields.lblReferralIDPrefix?.value + item.ReferralID}
                outClass={
                  "col-xl-5 col-md-12 col-list-title d-md-flex d-xl-block"
                }
                titleClass={"d-lg-block d-xl-none"}
              />
              <DisplayItem
                nameField={fields.lblReferredToSpecialty}
                valueField={item.Specialty}
                outClass={
                  "col-xl-7 col-md-12 col-list-title d-md-flex d-xl-block"
                }
                titleClass={"d-lg-block d-xl-none"}
              />
            </div>
            <div className="row label-list">
              <DisplayItem
                nameField={fields.lblReferredFrom}
                outClass={"col-md-6 col-xl-2 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblEffectiveDate}
                outClass={"col-md-6 col-xl-2 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblStatus}
                outClass={"col-md-6 col-xl-1 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblExpirationDate}
                outClass={"col-md-6 col-xl-2 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblNumberOfVisits}
                outClass={"col-md-6 col-xl-2 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblNumberOfVisitsAvailable}
                outClass={"col-md-6 col-xl-3 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
            </div>
            <div className="row">
              <DisplayItem
                nameField={fields.lblReferredFrom}
                valueField={item.ReferredFromProviderName}
                outClass={"col-md-12 col-xl-2 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblEffectiveDate}
                valueField={item.EffectiveDate}
                outClass={"col-md-12 col-xl-2 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblStatus}
                valueField={item.Status}
                outClass={"col-md-12 col-xl-1 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblExpirationDate}
                valueField={item.ExpirationDate}
                outClass={"col-md-12 col-xl-2 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblNumberOfVisits}
                valueField={item.NumberOfVisits + ""}
                outClass={"col-md-12 col-xl-2 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblNumberOfVisitsAvailable}
                valueField={item.NumberOfVisitsAvailable + ""}
                outClass={"col-md-12 col-xl-3 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ReferralsRecords;
