import React, { useState, useEffect, useContext } from "react";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import { getFormattedDate } from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import { BoxContainer } from "../../common/BoxContainer";
import { Box } from "../../common/Box";
import { useGetDDLYearsAPI } from "../../hooks/useGetDDLYearsAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { COVERAGE_ELIGIBILITY } from "../../define.widgets";
import { YearDropdownContext } from "../../contexts/yearDropdown.context";

const Eligibility = ({ fields, onYearSelect }) => {
  const [recordList, setRecordList] = useState([]);
  const [record, setRecord] = useState(null);
  const { listRecords, isIdCardToggleRemovalFlag } = useGetDDLYearsAPI();
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const { yearFromMyCoverageDropdown, setYearFromMyCoverageDropdown } =
    useContext(YearDropdownContext);
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    let plans =
      listRecords.length > 0
        ? listRecords.filter((l) => l.Year === currentYear)
        : listRecords;
    setRecordList(plans);
    setRecord(plans[0]);
    listRecords?.length > 0
      ? setYearFromMyCoverageDropdown(plans[0])
      : setYearFromMyCoverageDropdown({});
  }, [listRecords]);

  useEffect(() => {
    if (record) {
      onYearSelect(record);
    }
  }, [record]);

  const handleYearSelect = (option) => {
    let record = recordList.filter((r) => Number(r.Year) === option.value)[0];
    setRecord(record);
    setYearFromMyCoverageDropdown(record);
  };

  let redirectionLink = getFieldValue(fields, "lblRedirectionLink");

  const handleRedirectionToDocuments = (e) => {
    e.preventDefault();
    location =
      listRecords?.length > 0
        ? `${redirectionLink.href},${record.Year}`
        : redirectionLink.href;
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
          <div className="page-heading">
            <Text field={fields.heading} />
          </div>
        </div>
        {/* {listRecords?.length > 1 && !isIdCardToggleRemovalFlag && (
          <div className="col-xxl-9 col-lg-8 col-md-6 col-sm-12 mycoverage-filters">
            <div className="plan-year-block">
              <div>
                <Text field={fields.lblPlanYear} />
              </div>
              <div>
                <CustomSelect
                  field={{
                    name: "coveragePlanYears",
                    value: record?.Year,
                  }}
                  className="form-select"
                  aria-label={getFieldValue(fields, "lblPlanYear")}
                  options={recordList.map((item) => ({
                    value: item.Year,
                    label: item.Year,
                  }))}
                  isFormik={false}
                  onChange={handleYearSelect}
                />
              </div>
            </div>
          </div>
        )} */}
      </div>
      <div className="row eligibility-block">
        <div className="col-md-12 col-sm-12">
          <section className="d-flex justify-content-center">
            <BoxContainer>
              <Box
                title={fields.lblEligibility}
                subTitle={fields.lblRedirectionLink}
                onClickHandler={handleRedirectionToDocuments}
              >
                {innerSpinnerHTML?.[COVERAGE_ELIGIBILITY]}
                <>
                  {record && (
                    <div className="row eligibility-details">
                      {[
                        { label: "lblPlanName", item: record.PlanDescription },
                        {
                          label: "lblEffDate",
                          item: getFormattedDate(record.EffDate, "mm/dd/yyyy"),
                        },
                        {
                          label: "lblTermDate",
                          item: getFormattedDate(record.TermDate, "mm/dd/yyyy"),
                        },
                      ].map(({ label, item }) => (
                        <div
                          className="col-xl-4 col-md-6 col-sm-12"
                          key={label}
                        >
                          <div className="row-title">
                            <Text field={fields[label]} />
                          </div>
                          <div className="row-details">{item}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              </Box>
            </BoxContainer>
          </section>
        </div>
      </div>
    </>
  );
};

export default Eligibility;
