import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useGetAssessmentAndValidationListAPI } from "../../hooks/useGetAssessmentAndValidationListAPI";
import {
  DUALS,
  ICON_PATH,
  MARKETPLACE,
  MEDICARE,
  SUPPORT_USER,
  TERMED_MEMBER,
  GLOBAL_VARIABLE,
  NM,
  WA,
  NM_MEDICAID_ProgramID,
  MEDICAID,
  ASSESSMENT_ENGLISH_LANG_CODE,
  ASSESSMENT_SPANISH_LANG_CODE,
  NM_BH_CRSIS,
  NM_Health_Risk,
  NM_Transition_of_Care,
  NM_Health_Needs,
} from "../../define.constants";
import AssessmentValidation from "./AssessmentValidation";
import { assessmentStatus } from "../../utils/constants";
import { AppContext } from "./../../contexts/app.context";
import {
  isDualStateApplicableForEHRA,
  isMedicareStateApplicableForEHRA,
  memberAgeCalculation,
} from "../../utils/helpers";
import { mobileAndTabletCheck } from "../../utils/helpers";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { getFormattedDate } from "../../utils/helpers";
import { SpinnerContext } from "../../contexts/spinner.context";
import {
  getStateLobConfigurationItems,
  isStateApplicableForTHRAssessment,
  isStateApplicableMyAssessmentMyHealthPerks,
} from "../../utils/helpers";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

import {
  ASSESSMENT_STATUS_COMPLETED,
  ASSESSMENT_FLAG_INTERNAL,
  ASSESSMENT_FLAG_EXTERNAL,
  ASSESSMENTS_ID,
  ASSESSMENT_ID_680,
  ASSESSMENT_ID_732,
  ASSESSMENT_ID_738,
  ASSESSMENT_ID_740,
  ASSESSMENT_ID_1437,
  SSRS_REPORT_NAMES,
  ASSESSMENT_PDF_NAMES,
  H2060_B_ASSESSMENT,
  Export_PDF,
  SSRS_PDF,
  TLTSS,
} from "../../define.constants";
import { MY_WELLNESS_GET_ASSESSMENTS } from "../../define.widgets";
import * as AssessmentsAPI from "../../services/AssessmentsAPI";
import { ErrorContext } from "../../contexts/error.context";
import * as HealthRecordAPI from "../../services/HeathRecordAPI";
const MyWellnessAssessments = (props) => {
  const { assessments, isApiResponded } =
    useGetAssessmentAndValidationListAPI();
  const { redirectToSSO } = useContext(SSORedirectContext);
  let { dateOfBirth, MemberStateName, memberLOB, prefLang } =
    useContext(AppContext);
  let { isTermedMember, programId } = useContext(AppContext);
  let CareCoordinator =
    MemberStateName?.toLowerCase() === NM?.toLowerCase() &&
    programId === NM_MEDICAID_ProgramID &&
    memberLOB.toLowerCase() === MEDICAID;
  const { innerSpinnerHTML, setSpinner } = useContext(SpinnerContext);

  /*useEffect(() => {
    setMobileDisplayCheck(mobileAndTabletCheck);
  }, [mobileDisplayCheck]); */
  const memberFinalAge = memberAgeCalculation(dateOfBirth);
  const [mobileDisplayCheck, setMobileDisplayCheck] = useState(false);
  const [checkCompletedStatus, setCheckCompletedStatus] = useState(true);
  const [disabledPage, setDisabledPage] = useState(false);
  const { setError } = useContext(ErrorContext);
  const isMarketPlace = memberLOB?.toLowerCase() === MARKETPLACE;
  const isMedicare = memberLOB?.toLowerCase() === MEDICARE;
  const isDuals = memberLOB?.toLowerCase() === DUALS;
  const isMedicaid = memberLOB?.toLowerCase() === MEDICAID;
  useEffect(() => {
    localStorage.removeItem("AssessmentData");
    assessments?.map((data) => {
      if (data.status.toLowerCase() === ASSESSMENT_STATUS_COMPLETED) {
        setCheckCompletedStatus(false);
      }
    });
  }, [assessments]);
  useEffect(() => {
    setMobileDisplayCheck(mobileAndTabletCheck);
  }, [mobileDisplayCheck]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [showAssessment, setShowAssessment] = useState(true);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  try {
    useEffect(() => {
      if (assessments?.length > 0) {
        if (CareCoordinator) {
          setFilteredRecords(
            assessments
              .filter((obj) => obj?.IsVisible === true)
              .sort(
                (a, b) =>
                  new Date(b.CompletionDate) - new Date(a.CompletionDate)
              )
          );
        } else {
          setFilteredRecords(
            assessments.filter((obj) => obj?.IsVisible === true)
          );
        }
      }
    }, [assessments]);
    const viewProgressNoteReport = (id) => {
      let request = {
        SubmitId: id,
      };
      setSpinner(true);
      AssessmentsAPI.getProgressNoteViewReportFromCCA(request)
        .then((response) => {
          if (response?.ResultData?.Result_PDF) {
            let byteChars = window.atob(response.ResultData.Result_PDF);
            let byteNum = new Array(byteChars.length);
            for (let i = 0; i < byteChars.length; i++) {
              byteNum[i] = byteChars.charCodeAt(i);
            }
            let byteAr = new Uint8Array(byteNum);
            let file = new Blob([byteAr], {
              type: "application/pdf;base64",
            });
            let filePath = URL.createObjectURL(file);
            window.open(filePath);
          } else {
            setError({ err: { statusCode: response.ResultCode } });
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    };
    const callToAssessmentValidation = (item) => {
      if (item.History && item.History.toUpperCase() === "PROGRESS NOTES") {
        if (item.IsAttachmentExists && item.FileExt.toLowerCase() === ".pdf")
          viewProgressNoteReport(item.id);
      } else if (
        CareCoordinator &&
        item.status.toLowerCase() === ASSESSMENT_STATUS_COMPLETED
      ) {
        DynamicAssessmentReportAPI(item);
      } else if (SSRS_REPORT_NAMES.includes(item?.name)) {
        //call ssrs report downloadingapi the api
        getSSRreportPdf(item);
      } else if (ASSESSMENT_PDF_NAMES.includes(item?.name)) {
        //call CCA API to download pdf
        planButtonClickLtss(item);
      } else {
        if (
          item.status.toLowerCase() === ASSESSMENT_STATUS_COMPLETED &&
          item.Assessmentflag === ASSESSMENT_FLAG_INTERNAL
        ) {
          setDisabledPage(true);
        } else setDisabledPage(false);
        setShowAssessment(false);
        setSelectedAssessment(item);
      }
    };

    const DynamicAssessmentReportAPI = (item) => {
      let request = {
        assessmentId: item.id,
        versionId: item.version,
      };
      setSpinner(true);
      AssessmentsAPI.getDynamicAssessmentReportAPI(request)
        .then((response) => {
          if (response?.ResultData?.viewReport) {
            const htmlContent = atob(response?.ResultData?.viewReport);

            // Open the HTML content in a new tab
            const newWindow = window.open();
            newWindow.document.write(htmlContent);
            newWindow.document.close(); // Close document writing stream
            return resp;
          }
        })
        .finally(() => {
          setSpinner(false);
        })
        .catch(() => {});
    };
    const getSSRreportPdf = (item) => {
      let request = {
        Reportname: item.name,
        AssessmentSubmitId: item.SubmitId,
        SelectedLanguage: prefLang,
      };

      AssessmentsAPI.getSSRSAssessment(request)
        .then((response) => {
          if (response?.ResultData?.Output) {
            let byteChars = window.atob(response.ResultData?.Output);
            let byteNum = new Array(byteChars.length);
            for (let i = 0; i < byteChars.length; i++) {
              byteNum[i] = byteChars.charCodeAt(i);
            }
            let byteAr = new Uint8Array(byteNum);
            let file = new Blob([byteAr], {
              type: "application/pdf;base64",
            });
            let filePath = URL.createObjectURL(file);
            window.open(filePath);
          } else {
            setError({ err: { statusCode: response.ResultCode } });
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    };
    /** Back To List  */
    const backToMyWellness = () => {
      setShowAssessment(true);
    };

    const BindLabelValue = ({ label, value }) => {
      return (
        <>
          <div className="list-desc-title">
            <Text field={label} />
          </div>
          <div className="list-desc-details">{value}</div>
        </>
      );
    };

    const BindLink = ({
      className,
      method,
      altText,
      assesmentItem,
      assessmentFlag,
      assessmentId,
      href,
      statusIcon,
    }) => {
      const assessmentIdArr = [
        ASSESSMENT_ID_680,
        ASSESSMENT_ID_732,
        ASSESSMENT_ID_738,
        ASSESSMENT_ID_740,
        ASSESSMENT_ID_1437,
        NM_BH_CRSIS,
        NM_Health_Risk,
        NM_Transition_of_Care,
        NM_Health_Needs,
      ];
      if (assessmentFlag === ASSESSMENT_FLAG_INTERNAL) {
        if (statusIcon === assessmentStatus.completed)
          return (
            <a
              href="#"
              className={className}
              aria-label={altText}
              onClick={(e) => {
                e.preventDefault();
                method ? method() : null;
              }}
            >
              {((statusIcon === assessmentStatus.completed &&
                CareCoordinator &&
                assessmentIdArr.includes(assessmentId)) ||
                (assesmentItem?.History &&
                  assesmentItem?.History.toUpperCase() ===
                    "PROGRESS NOTES")) && (
                <img src={`${ICON_PATH}/Group_2641.svg`} alt={altText} />
              )}
              {statusIcon === assessmentStatus.completed &&
                !(
                  assessmentIdArr.includes(assessmentId) ||
                  (assesmentItem?.History &&
                    assesmentItem?.History.toUpperCase() === "PROGRESS NOTES")
                ) &&
                !CareCoordinator &&
                mobileDisplayCheck === false && (
                  <img src={`${ICON_PATH}/visibility-24px.svg`} alt={altText} />
                )}
              {statusIcon === assessmentStatus.completed &&
                mobileDisplayCheck === true && (
                  <img
                    src={`${ICON_PATH}/visibility-24px_mobile.svg`}
                    alt={altText}
                  />
                )}
            </a>
          );
        else
          return (
            <WhoIsAuthorized
              type="link"
              href="#"
              authorize={{
                [SUPPORT_USER]: true,
                [TERMED_MEMBER]: false,
              }}
              handleClick={(e) => {
                e.preventDefault();
                method ? method() : null;
              }}
            >
              {({ handleClick, disabledClass }) => {
                return (
                  <a
                    href="#"
                    className={`${className} ${disabledClass}`.trim()}
                    aria-label={altText}
                    onClick={handleClick}
                  >
                    {statusIcon === assessmentStatus.inProgress &&
                      mobileDisplayCheck === false && (
                        <img
                          src={`${ICON_PATH}/Group 3070.svg`}
                          alt={altText}
                        />
                      )}
                    {(statusIcon === assessmentStatus.notTaken ||
                      statusIcon === assessmentStatus.neverTaken) &&
                      mobileDisplayCheck === false && (
                        <img
                          src={`${ICON_PATH}/Group 3070.svg`}
                          alt={altText}
                        />
                      )}
                    {statusIcon === assessmentStatus.inProgress &&
                      mobileDisplayCheck === true && (
                        <img src={`${ICON_PATH}/mode-24px.svg`} alt={altText} />
                      )}
                    {(statusIcon === assessmentStatus.notTaken ||
                      statusIcon === assessmentStatus.neverTaken) &&
                      mobileDisplayCheck === true && (
                        <img
                          src={`${ICON_PATH}/Group 3070.svg`}
                          alt={altText}
                        />
                      )}
                  </a>
                );
              }}
            </WhoIsAuthorized>
          );
      } else if (assessmentFlag === ASSESSMENT_FLAG_EXTERNAL) {
        return (
          <a
            href={href}
            className={className}
            aria-label={altText}
            onClick={(e) =>
              redirectToSSO({
                e,
                url: constants.apigeeURLs.GetPDHISSORedirect,
              })
            }
          >
            {statusIcon === assessmentStatus.notTaken &&
              mobileDisplayCheck === false && (
                <img
                  src={`${ICON_PATH}/open_in_new_black-24px.svg`}
                  alt={altText}
                />
              )}

            {statusIcon === assessmentStatus.notTaken &&
              mobileDisplayCheck === true && (
                <img
                  src={`${ICON_PATH}/open_in_new-24px_mobile.svg`}
                  alt={altText}
                />
              )}
          </a>
        );
      }
    };
    const planButtonClickLtss = (value) => {
      setSpinner(true);
      let doctype = "";
      let assessmnetLang = ASSESSMENT_ENGLISH_LANG_CODE;
      if (H2060_B_ASSESSMENT == value.name) {
        doctype = Export_PDF;
        assessmnetLang = prefLang;
      } else if (TLTSS == value.name) {
        doctype = SSRS_PDF;
        assessmnetLang = ASSESSMENT_ENGLISH_LANG_CODE;
      } else {
        doctype = SSRS_PDF;
      }

      HealthRecordAPI.DownloadServicePlan({
        AssessmentId: value.SubmitId,
        SessionId: "",
        DocumentType: doctype,
        AssessmentLanguage: assessmnetLang,
      })
        .then((response) => {
          if (response?.ResultData?.AssessmentBinaryData) {
            let byteChars = window.atob(
              response.ResultData.AssessmentBinaryData
            );
            let byteNum = new Array(byteChars.length);
            for (let i = 0; i < byteChars.length; i++) {
              byteNum[i] = byteChars.charCodeAt(i);
            }
            let byteAr = new Uint8Array(byteNum);
            let file = new Blob([byteAr], {
              type: "application/pdf;base64",
            });
            let filePath = URL.createObjectURL(file);
            window.open(filePath);
          } else {
            setError({ err: { statusCode: response.ResultCode } });
          }
          setData(response);
        })
        .catch((err) => {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    };
    const assessmentDetailList = getStateLobConfigurationItems(
      props?.fields,
      MemberStateName,
      memberLOB,
      ""
    );

    const NoAssessmentFoundDisclaimer = () => {
      if (
        (isMarketPlace &&
          !isStateApplicableForTHRAssessment(MemberStateName)) ||
        !isMarketPlace
      ) {
        return (
          <div className="no-assessment-records">
            <Text
              field={
                CareCoordinator
                  ? assessmentDetailList[0]?.fields?.noDataNMFromApiMessage
                  : assessmentDetailList[0]?.fields?.noDataFromApiMessage
              }
            />
          </div>
        );
      } else {
        return <></>;
      }
    };
    const showActionIcon = (item) => {
      if (
        MemberStateName?.toLowerCase() === WA?.toLowerCase() &&
        isMedicaid === true
      ) {
        return true;
      } else if (
        item.IsICTParticipant == 1 &&
        item.status.toLowerCase() !== assessmentStatus.completed
      )
        return false;
      else return true;
    };

    const showDisclaimerForMedicareAndDuals = () => {
      if (
        (isMedicare && isMedicareStateApplicableForEHRA(MemberStateName)) ||
        (isDuals && isDualStateApplicableForEHRA(MemberStateName))
      ) {
        return false;
      } else if (isMedicare || isDuals) {
        return true;
      } else {
        return false;
      }
    };

    if (isTermedMember) {
      //No Assessments to show for termed members
      return (
        <div className="wellness-page">
          <div className="row">
            <div className="col-md-12 no-assessment-records1">
              <Text
                field={props?.fields?.noCompletedAssessmentDisclaimerTitle}
              />
            </div>
          </div>
        </div>
      );
    } else if (showDisclaimerForMedicareAndDuals()) {
      //Showing disclaimer message for Medicare & Duals members
      return (
        <div className="wellness-page">
          <div className="row">
            <div className="col-md-12 no-assessment-records1">
              <Text field={props?.fields?.disclaimerForMedicare} />
            </div>
          </div>
        </div>
      );
    } else {
      //Showing Assessments for Marketplace & Medicaid members
      return (
        <>
          {isMarketPlace && memberFinalAge <= 18 ? (
            <div className="wellness-page">
              <div className="row">
                <div className="col-md-12 no-assessment-records1">
                  <Text field={props?.fields?.disclaimerForMinor} />
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                {isMarketPlace &&
                isStateApplicableMyAssessmentMyHealthPerks(MemberStateName) ? (
                  <>
                    <>
                      <div className="row no-tab-title">
                        <div className="col-md-12">
                          <ul className="list-group ">
                            <li className="list-group-item">
                              <div className="row field1">
                                <div className="list-desc-details">
                                  {assessmentDetailList[0]?.fields
                                    ?.wellnessAssessmentNoticeDescription
                                    ?.value ??
                                    assessmentDetailList[1]?.fields
                                      ?.wellnessAssessmentNoticeDescription
                                      ?.value}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>

                    {checkCompletedStatus &&
                      isStateApplicableForTHRAssessment(MemberStateName) &&
                      !isTermedMember && (
                        <>
                          <hr className="bottom-divider " />
                          <div className="row d-sm-none d-md-block">
                            <div className="col-lg-12 bottom-para">
                              <div>
                                <Text
                                  field={
                                    assessmentDetailList[0]?.fields
                                      ?.noDataFromApiMessage
                                  }
                                />
                              </div>
                              <div>
                                <Text
                                  field={
                                    assessmentDetailList[0]?.fields
                                      ?.noCompletedAssessmentDisclaimerDescription ??
                                    ""
                                  }
                                />
                                <a
                                  className="contact-us text-zoom state-color-txt"
                                  href={
                                    props?.fields?.contactUsLink?.value?.href
                                  }
                                >
                                  {props?.fields?.contactUsLink?.value?.text}
                                  <img
                                    src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                                    alt={
                                      props?.fields?.contactUsLink?.value?.text
                                    }
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="row d-none d-sm-block d-md-none">
                            <div className="col-lg-12 bottom-para">
                              <p>
                                <span className="d-block">
                                  <Text
                                    field={
                                      assessmentDetailList[0]?.fields
                                        ?.noDataFromApiMessage
                                    }
                                  />
                                </span>
                                <span className="d-block">
                                  <Text
                                    field={
                                      assessmentDetailList[0]?.fields
                                        ?.noCompletedAssessmentDisclaimerDescription
                                    }
                                  />
                                </span>
                              </p>
                              <a
                                className="contact-us"
                                href={props?.fields?.contactUsLink?.value?.href}
                              >
                                {props?.fields?.contactUsLink?.value?.text}
                                <img
                                  src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                                  alt={
                                    props?.fields?.contactUsLink?.value?.text
                                  }
                                />
                              </a>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    {showAssessment === true ? (
                      <div className="row no-tab-title">
                        <div className="col-md-12">
                          <ul className="list-group ">
                            {filteredRecords?.slice(0).length === 0 &&
                              !isApiResponded && (
                                <li className="list-group-item">
                                  {
                                    innerSpinnerHTML?.[
                                      MY_WELLNESS_GET_ASSESSMENTS
                                    ]
                                  }
                                  <div className="row field1">
                                    <div className="col-sm-12"></div>
                                  </div>
                                </li>
                              )}
                            {filteredRecords
                              ?.slice(0)
                              ?.filter(
                                (obj) =>
                                  !isMarketPlace || obj?.id !== ASSESSMENTS_ID
                              )?.length !== 0 &&
                            filteredRecords !== undefined ? (
                              <>
                                {filteredRecords
                                  ?.slice(0)
                                  ?.filter(
                                    (obj) =>
                                      !isMarketPlace ||
                                      obj?.id !== ASSESSMENTS_ID
                                  )
                                  .map((item, index) => (
                                    <li className="list-group-item" key={index}>
                                      <div className="row field1">
                                        <div className="col-lg-5 col-md-6 col-sm-12">
                                          <BindLabelValue
                                            label={
                                              props?.fields?.assessmentCategory
                                            }
                                            value={item.category}
                                          />
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                          <BindLabelValue
                                            label={
                                              props?.fields?.assessmentName
                                            }
                                            value={item.name}
                                          />
                                        </div>
                                        {showActionIcon(item) && (
                                          <div className="col-lg-2 col-md-1 col-sm-12">
                                            <div className="text-right">
                                              <BindLink
                                                className="list-icon-pen"
                                                method={() =>
                                                  callToAssessmentValidation(
                                                    item
                                                  )
                                                }
                                                altText={item.status}
                                                assessmentFlag={
                                                  item.Assessmentflag
                                                }
                                                assesmentItem={item}
                                                assessmentId={item.id}
                                                statusIcon={item.status.toLowerCase()}
                                                href="/home"
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  ))}
                              </>
                            ) : (
                              <div>
                                <NoAssessmentFoundDisclaimer />
                              </div>
                            )}
                          </ul>
                        </div>
                        {isMarketPlace &&
                          checkCompletedStatus &&
                          isStateApplicableForTHRAssessment(MemberStateName) &&
                          !isTermedMember && (
                            <>
                              <hr className="bottom-divider " />
                              <div className="row d-sm-none d-md-block">
                                <div className="col-lg-12 bottom-para">
                                  <div>
                                    <Text
                                      field={
                                        assessmentDetailList[0]?.fields
                                          ?.noDataFromApiMessage
                                      }
                                    />
                                  </div>
                                  <div>
                                    <Text
                                      field={
                                        assessmentDetailList[0]?.fields
                                          ?.noCompletedAssessmentDisclaimerDescription ??
                                        ""
                                      }
                                    />
                                    <a
                                      className="contact-us text-zoom state-color-txt"
                                      href={
                                        props?.fields?.contactUsLink?.value
                                          ?.href
                                      }
                                    >
                                      {
                                        props?.fields?.contactUsLink?.value
                                          ?.text
                                      }
                                      <img
                                        src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                                        alt={
                                          props?.fields?.contactUsLink?.value
                                            ?.text
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="row d-none d-sm-block d-md-none">
                                <div className="col-lg-12 bottom-para">
                                  <p>
                                    <span className="d-block">
                                      <Text
                                        field={
                                          assessmentDetailList[0]?.fields
                                            ?.noDataFromApiMessage
                                        }
                                      />
                                    </span>
                                    <span className="d-block">
                                      <Text
                                        field={
                                          assessmentDetailList[0]?.fields
                                            ?.noCompletedAssessmentDisclaimerDescription
                                        }
                                      />
                                    </span>
                                  </p>
                                  <a
                                    className="contact-us"
                                    href={
                                      props?.fields?.contactUsLink?.value?.href
                                    }
                                  >
                                    {props?.fields?.contactUsLink?.value?.text}
                                    <img
                                      src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                                      alt={
                                        props?.fields?.contactUsLink?.value
                                          ?.text
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                            </>
                          )}
                        {isMarketPlace &&
                          isStateApplicableForTHRAssessment(MemberStateName) &&
                          isTermedMember && (
                            <>
                              <div className="col-md-12 no-assessment-records">
                                <Text
                                  field={
                                    props?.fields
                                      ?.noCompletedAssessmentDisclaimerTitle
                                  }
                                />
                              </div>
                            </>
                          )}
                      </div>
                    ) : (
                      <>
                        <AssessmentValidation
                          fields={props?.fields}
                          assessment={selectedAssessment}
                          backToMyWellness={backToMyWellness}
                          disabledPage={disabledPage}
                          assessmentIDSpecificList={
                            props?.fields?.assessmentIDSpecificList
                          }
                          assessments={assessments}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      );
    }
  } catch (e) {
    console.log(e);
  }
};
export default MyWellnessAssessments;
