import React, { useContext } from "react";
import { ICON_PATH, SUPPORT_USER, TERMED_MEMBER } from "../../define.constants";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { SpinnerContext } from "../../contexts/spinner.context";
import { archiveMemMessages } from "../../services/MessagesAPI";
import { ErrorContext } from "../../contexts/error.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

const ArchiveMessages = ({
  fields,
  message,
  forDesktop = true,
  onArchived,
}) => {
  const { setError } = useContext(ErrorContext);
  const { setSpinner } = useContext(SpinnerContext);
  return (
    <WhoIsAuthorized
      authorize={{
        [SUPPORT_USER]: false,
        [TERMED_MEMBER]: true,
      }}
      handleClick={() => {
        let selectedMessages = [];
        if (typeof window !== "undefined") {
          selectedMessages = Array.from(
            document?.querySelectorAll(
              "input[type=checkbox][name=message_chk]:checked"
            )
          ).map((c) => c.value);
          document?.getElementsByName("message_chk").forEach((chk) => {
            chk.checked = false;
          });
        }

        selectedMessages = [...new Set(selectedMessages)].join(",");
        if (selectedMessages.length == 0) {
          selectedMessages = message.SFMessageID
            ? message.SFMessageID
            : message.MessageID;
        }
        if (selectedMessages) {
          setSpinner(true);
          archiveMemMessages({
            SFConversationIDList: selectedMessages,
            SFMessageIDList: selectedMessages,
            //PortalMessageIDList: selectedMessages,
            //HiddenIDList: selectedMessages,
          })
            .then((response) => {
              onArchived();
            })
            .catch(function (err) {
              setError({ err });
            })
            .finally(function () {
              setSpinner(false);
            });
        }
      }}
    >
      {/* const archiveBtn = (
    <img
      src={`${ICON_PATH}/archive-24px-dark.svg`}
      alt={getFieldValue(fields, "lblArchive")}
    />
    ); */}
      {({ handleClick, disabledClass }) => {
        const archiveBtn = (
          <img
            src={`${ICON_PATH}/archive-24px-dark.svg`}
            alt={getFieldValue(fields, "lblArchive")}
          />
        );
        return forDesktop ? (
          <div
            className="archive"
            onClick={handleClick}
            title={getFieldValue(fields, "lblArchivemsg")}
          >
            {archiveBtn}
            <span>
              <Text field={fields.lblArchive} />
            </span>
          </div>
        ) : (
          <span className="archive" onClick={handleClick}>
            {archiveBtn}
          </span>
        );
      }}
    </WhoIsAuthorized>
  );
};

export default ArchiveMessages;
