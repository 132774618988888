import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { CustomSelect } from "../../../common/Select";
import { Field } from "formik";

const DisplayProvider = (fields) => {
  const { providers, handleDropdownChange, dropdownOptions, props } = fields;
  return (
    <div className="col-xl-3 col-md-6 col-12 healthrcd-drop">
      <label className="label mb-2">
        <Text
          field={
            props.fields?.lblProvider
              ? props.fields.lblProvider
              : props.fields.lblFacility
          }
        />
      </label>
      {/* {providers && providers !== null && ( */}
      <Field
        as="select"
        id="selectedOption"
        name="selectedOption"
        component={CustomSelect}
        options={dropdownOptions}
        onChange={handleDropdownChange}
      />
      {/* )} */}
    </div>
  );
};

export default DisplayProvider;
