import React from "react";
import Select, { components } from "react-select";
import { highlightBorderOnError } from "./../../utils/helpers";
import { GLOBAL_VARIABLE } from "../../define.constants";
const CustomSelect = (props) => {
  const {
    options,
    field,
    form,
    onChange: handleChange,
    isFormik,
    isSearchable,
    isDisabled,
    customclass,
    ariaLabel,
  } = props;
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <>{GLOBAL_VARIABLE.noOptionMessageSelect}</>
      </components.NoOptionsMessage>
    );
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="fa fa-caret-down"></i>
      </components.DropdownIndicator>
    );
  };
  const highlightBorderError =
    !isFormik ??
    highlightBorderOnError(form.errors.reason, form.touched.reason);

  const selectPlaceholder = GLOBAL_VARIABLE.lblSelectPlaceholder;
  return (
    <Select
      className={`molina-select-wrapper ${highlightBorderError} ${
        customclass ?? customclass
      }`}
      isSearchable={isSearchable}
      classNamePrefix="molina-select"
      options={options}
      isDisabled={isDisabled}
      name={field.name}
      value={
        options ? options.find((option) => option.value === field.value) : ""
      }
      onChange={(option) => {
        handleChange(option);
        isFormik ? form.setFieldValue(field.name, option.id) : option.id;
      }}
      components={{ DropdownIndicator, NoOptionsMessage }}
      styles={{
        dropdownIndicator: (provided, state) => ({
          ...provided,
          transform: state.selectProps.menuIsOpen && "rotate(180deg)",
        }),
      }}
      placeholder={selectPlaceholder}
      aria-label={ariaLabel ? ariaLabel : field.name}
    />
  );
};
CustomSelect.defaultProps = {
  isFormik: true,
  isDisabled: false,
  isSearchable: true,
};
export default CustomSelect;
