import Axios from "./axios/provider";

/**
 * Get Service Authorization List
 *
 * @param {object} data
 * @returns
 */
export const getELetterList = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ELetterList + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * GetClaimEOBDocument
 * @param {object} data
 * @returns
 */
export const getELetterDocument = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetMemberLetterDocument,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
