import { useState, useEffect, useContext } from "react";
import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import { IMAGE_PATH, ICON_PATH } from "../../define.constants";
import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "./../../contexts/app.context";
import {
  MEDICAID,
  TX_MEDICAID_STAR,
  TX_MEDICAID_STAR_PLUS,
  TX_MEDICAID_CHIP,
  TX_MEDICAID_CHIP_RSA,
  TX_MEDICAID_CHIP_PERINATE,
  TX_MEDICAID_CHIP_PERINATE_RSA,
} from "../../define.constants";

const ViewTXRewards = ({ props }) => {
  const list = props;
  let { memberLOB, programId, MemberStateName } = useContext(AppContext);
  const getWebsite = (list, programId) => {
    let websiteLink = null;
    if (memberLOB.toLowerCase() === MEDICAID) {
      const isTXStar = programId === TX_MEDICAID_STAR;
      const isTXStarPlus = programId === TX_MEDICAID_STAR_PLUS;
      const isTXChipOrRSA =
        programId === TX_MEDICAID_CHIP || programId === TX_MEDICAID_CHIP_RSA;
      const isTXPerinateOrRSA =
        programId === TX_MEDICAID_CHIP_PERINATE ||
        programId === TX_MEDICAID_CHIP_PERINATE_RSA;

      if (isTXStar === true) {
        websiteLink = list[0]?.fields?.valueAddedServiceStar.value.href;
      } else if (isTXStarPlus === true) {
        websiteLink = list[0]?.fields?.valueAddedServiceStarPlus.value.href;
      } else if (isTXChipOrRSA === true) {
        websiteLink = list[0]?.fields?.valueAddedServiceChip.value.href;
      } else if (isTXPerinateOrRSA === true) {
        websiteLink = list[0]?.fields?.valueAddedServicePerinate.value.href;
      }
    }
    return websiteLink;
  };
  return (
    <>
      <div className="row trackmyspending vb">
        <BoxContainer>
          <Box title={list[0]?.fields?.rewardsHeading}>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 col-sm-12 mb-4">
                  <div className="molina-progress-bar-list memberUsefulTools">
                    <table
                      width="100%"
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                    >
                      <tr>
                        <td
                          style={{
                            paddingLeft: "3%",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          {/* <picture>
                           <img
                              src={`${IMAGE_PATH}/CareSeatProgram.png`}
                              //className="img-fluid"
                              style={{ paddingLeft: "15%", width: "75%" }}
                            />
                          </picture>{" "} */}
                          <b>
                            <Text
                              field={list[0]?.fields?.valueAddedServiceText}
                            />
                          </b>
                        </td>
                        <td
                          style={{
                            paddingLeft: "20px",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text
                            field={list[0]?.fields?.valueAddedServicePrefix}
                          />
                          <a href={getWebsite(list, programId)} target="blank">
                            <Text field={list[0]?.fields?.valueAddedService} />
                          </a>
                          <Text
                            field={list[0]?.fields?.valueAddedServiceSuffix}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <picture>
                            <img
                              src={`${IMAGE_PATH}/MolinaHelpFinder_rewards.png`}
                              //className="img-fluid"
                              style={{ paddingLeft: "15%", width: "75%" }}
                            />
                          </picture>{" "}
                        </td>
                        <td
                          style={{
                            paddingLeft: "20px",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text field={list[0]?.fields?.molinaHelpFinderTX} />
                          <a
                            href="https://molinahelpfinder.com/"
                            target="blank"
                          >
                            <Text
                              field={list[0]?.fields?.molinaHelpFinderTXText}
                            />
                          </a>
                          <Text
                            field={list[0]?.fields?.MolinaHelpFinderSuffix}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <picture>
                            <img
                              src={`${IMAGE_PATH}/psychhub-icon.png`}
                              className="img-fluid"
                            />
                          </picture>{" "}
                        </td>
                        <td
                          style={{
                            paddingLeft: "20px",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text field={list[0]?.fields?.physhHub} />
                          <a
                            href="https://psychhub.com/resources/videos/"
                            target="blank"
                          >
                            <Text field={list[0]?.fields?.learrningMore} />
                            <img
                              className="arr-icon"
                              src={`${ICON_PATH}/arrow-right-small.svg`}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr style={{ fontSize: "15px" }}>
                        <td className="h-100">
                          <ul className="list-inline memberSocialIcon my-auto">
                            <li className="list-inline-item">
                              <a
                                href="https://www.facebook.com/MolinaHealthTX"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture1.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="https://www.youtube.com/user/MolinaHealthcare"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture5.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="https://twitter.com/molinahealth"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture2.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="https://www.instagram.com/molinahealth/"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture3.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                rel="noreferrer"
                                href={
                                  list[0]?.fields?.linkedInLink?.value?.href
                                }
                                target="_blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture4.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                          </ul>
                        </td>
                        <td
                          style={{
                            paddingLeft: "20px",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text field={list[0]?.fields?.SocialMedia} />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 mb-4">
                  <div className="molina-progress-bar-list">
                    <div>
                      <picture className="img-mobile-app">
                        <img
                          src={`${IMAGE_PATH}/Mobile-app-image.png`}
                          className="img-fluid spotlight img-mobile-app"
                        />
                        <a href="https://apps.apple.com/us/app/my-molina/id1087995311">
                          <img
                            src={`${IMAGE_PATH}/Appstore.png`}
                            className="img-fluid spotlight img-appstore"
                          />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.molina.mobile.myhealthinhand&hl=en_US&gl=US&pli=1">
                          <img
                            src={`${IMAGE_PATH}/Google-PlayStore.png`}
                            className="img-fluid spotlight img-playstore"
                          />
                        </a>
                      </picture>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </BoxContainer>
      </div>{" "}
    </>
  );
};

export default ViewTXRewards;
