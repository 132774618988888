export const STEP_USER_ID = "1";
export const STEP_PWD = "2";
export const STEP_VERIFY_CODE = "3";
export const STEP_RESET_PWD = "4";
export const STEP_CONFIRMATION = "5";
export const STEP_PASS_CONFIRMATION = "6";
export const USERID = "USERID";
export const PWD = "PASSWORD";
export const SUBMIT = "Submit";
export const INITIAL_NUMBER = "0";
export const FUNCTIONALITY_USERID = "ForgotUserID";
export const FUNCTIONALITY_PWD = "ForgotPassword";
export const SUCCESS_VERIFICATION = "VALID SECURITY CODE";
export const ENGLISH_LANG = "EN";
export const EMAIL_STR = "X";
