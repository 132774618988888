import React from "react";
import {
  Text,
  RichText,
  Image,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { BoxContainer } from "../../common/BoxContainer";
import { Box } from "../../common/Box";
import { renderApproach } from "./Helper";
import { GLOBAL_VARIABLE } from "../../define.constants";

const ContactGotQuestions = ({ fields, approachList }) => {
  let gridTitle = "grid-title";
  let gridData = "grid-data";
  let gridBold = "grid-bold";
  let gridData1 = "grid-data1";
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  return (
    <div className="row">
      <BoxContainer sectionClass={"got-question-section"}>
        <Box title={fields?.gotQuestionsTitle ?? ""}>
          <div className="card-para">
            <Text field={fields?.gotQuestionsDescription ?? ""} />
          </div>
          <div className="helpline-block">
            <div className={`${renderApproach(approachList)}`}>
              {approachList?.map((approach, index) => (
                <div className="grid-item m-0" key={index}>
                  <div className="media">
                    <Image
                      className="d-flex align-self-start mr-13 state-color"
                      key={index}
                      field={approach.fields?.chooseIcon?.value}
                    />
                    <div className="media-body">
                      <BindData
                        label={approach?.fields?.heading}
                        className={gridTitle}
                      />
                      <BindData
                        label={approach?.fields?.phoneNumber1}
                        className={gridData}
                      />
                      <BindData
                        label={approach?.fields?.phoneNumber2}
                        className={gridData}
                      />
                      <BindData
                        label={approach?.fields?.phoneNumber3}
                        className={gridData}
                      />
                      <BindData
                        label={approach?.fields?.phoneNumber4}
                        className={gridData}
                      />
                      <BindRichTextData
                        label={approach?.fields?.postBoxAddress}
                        className={gridData}
                      />
                      <BindData
                        label={approach?.fields?.tty1}
                        className={gridData1}
                      />
                      <BindData
                        label={approach?.fields?.tty2}
                        className={gridData1}
                      />
                      <BindData
                        label={approach?.fields?.tty3}
                        className={gridData1}
                      />
                      <BindRichTextData
                        label={approach?.fields?.timings1}
                        className={gridBold}
                      />
                      <BindData
                        label={approach?.fields?.timings2}
                        className={gridBold}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </BoxContainer>
    </div>
  );
};
const BindData = ({ label, className }) => {
  if (label?.value && label !== "") {
    return (
      <>
        <div className={className}>
          <Text field={label} />
        </div>
      </>
    );
  } else {
    return null;
  }
};
const BindRichTextData = ({ label, className }) => {
  if (label?.value && label !== "") {
    return (
      <>
        <div className={className}>
          <RichText field={label} />
        </div>
      </>
    );
  } else {
    return null;
  }
};
export default ContactGotQuestions;
