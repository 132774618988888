import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { formatName } from "../../utils/helpers";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";

const InpatientAdmissionsERVisitsRecords = (props) => {
  const { fields, data, next } = props;

  return (
    <>
      <div className="row print-heading mb-4 d-none">
        <Text field={fields.lblprintHeading} />
      </div>
      <div className="clearfix"></div>
      <ul className="list-group d-none d-md-block">
        {data?.map((item, index) => (
          <li className="list-group-item" key={index}>
            <div className="row label-list">
              <DisplayItem
                nameField={fields.lblType}
                outClass={"col-md-6 col-xl-3 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblFacility1}
                outClass={"col-md-6 col-xl-3 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblDiagnosis}
                outClass={"col-md-6 col-xl-2 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblAdmit1}
                outClass={"col-md-6 col-xl-2 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblDischarge}
                outClass={"col-md-6 col-xl-2 d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
            </div>
            <div className="row">
              <DisplayItem
                nameField={fields.lblType}
                valueField={item.ServiceType}
                outClass={"col-md-12 col-xl-3 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-xl-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblFacility1}
                valueField={formatName(item.FacilityName)}
                outClass={"col-md-12 col-xl-3 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblDiagnosis}
                valueField={formatName(item.PrimaryDiag)}
                outClass={"col-md-12 col-xl-2 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblAdmit1}
                valueField={item.AdmitDate}
                outClass={"col-md-12 col-xl-2 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblDischarge}
                valueField={item.DischargeDate}
                outClass={"col-md-12 col-xl-2 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default InpatientAdmissionsERVisitsRecords;
