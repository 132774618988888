/*
This component returns each item in a box.
*/

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const DisplayItem = (props) => {
  const {
    nameField,
    valueField,
    outClass,
    titleClass,
    detailsClass,
    isdatafield,
  } = props;
  return (
    <div className={`${outClass}`}>
      {nameField && (
        <div className={`${titleClass ?? titleClass}`}>
          <Text field={nameField} />
        </div>
      )}
      {!isdatafield && (
        <div className={`${titleClass ?? titleClass}`}>
          <span> {valueField}</span>
        </div>
      )}
      {isdatafield && (
        <div className={`${detailsClass ?? detailsClass}`}>
          <span>{valueField}</span>
        </div>
      )}
    </div>
  );
};

export default DisplayItem;
