import React, { useState, useContext, useRef, useEffect } from "react";
import { SpinnerContext } from "../../contexts/spinner.context";
import * as HealthRecordAPI from "../../services/HeathRecordAPI";
import { ICON_PATH } from "../../define.constants";
import { ErrorContext } from "../../contexts/error.context";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const GetEOBDocument = (props) => {
  const { claimId, lblEOB, index, lblEOBDoc, divFlag } = props;
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [hrefValue, setHrefValue] = useState("#");
  const [EOBDocumnet, setEOBDocument] = useState(false);

  useEffect(() => {
    if (hrefValue !== "" && hrefValue !== "#")
      document.getElementById(`eobdownload${index}`).click();
  }, [hrefValue]);

  const eobClick = (e, index) => {
    let href = "#";

    if (claimId.trim() != "" && hrefValue === "#") {
      e.preventDefault();
      setSpinner(true);
      HealthRecordAPI.getClaimEOBDocument({
        ClaimID: claimId.trim(),
      })
        .then((data) => {
          if (data.ResultData.ClaimEOBDocument.DocumentContent !== null) {
            href =
              "data:application/octet-stream;base64," +
              encodeURIComponent(
                data.ResultData.ClaimEOBDocument.DocumentContent
              );
            setHrefValue(href);
            setEOBDocument(false);
          } else {
            setEOBDocument(true);
          }
        })
        .catch((err) => {
          setError({ err });
        })
        .finally(() => {
          setSpinner(false);
        });
    }
  };

  return (
    <>
      {index != null ? (
        divFlag ? (
          <div className="col-xl-2 col-md-12 d-xl-block d-none position-relative">
            <div className="row position-absolute">
              {EOBDocumnet ? (
                <div className="row">
                  <div className="col-list-title d-xl-block d-none eob-doc">
                    <Text field={lblEOBDoc} />
                  </div>
                  <div className="row">
                    <div className="col-8 custom-tooltip-spending claim-items">
                      <Text field={lblEOB} />
                    </div>

                    <div className="col-2">
                      <div className="col-md-1 claim-items">
                        <a
                          id={`eob${index}`}
                          key={index}
                          href={hrefValue}
                          className="text-zoom"
                          onClick={(e) => eobClick(e, index)}
                          {...(hrefValue !== ""
                            ? { download: "ClaimsHistory.pdf" }
                            : {})}
                        >
                          <img
                            src={`${ICON_PATH}/Group_2641.svg`}
                            alt={lblEOB.value}
                          />
                        </a>

                        <a
                          id={`eobdownload${index}`}
                          href={hrefValue}
                          className="text-zoom"
                          hidden
                          onClick={() => eobClick(index)}
                          {...(hrefValue !== ""
                            ? { download: "ClaimsHistory.pdf" }
                            : {})}
                        >
                          <img
                            src={`${ICON_PATH}/Group_2641.svg`}
                            alt={lblEOB.value}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-8 custom-tooltip-spending claim-items EOB-top-justify">
                    <Text field={lblEOB} />
                  </div>

                  <div className="col-2">
                    <div className="col-md-1 claim-items Eob-icon-top-justify">
                      <a
                        id={`eob${index}`}
                        key={index}
                        href={hrefValue}
                        className="text-zoom"
                        onClick={(e) => eobClick(e, index)}
                        {...(hrefValue !== ""
                          ? { download: "ClaimsHistory.pdf" }
                          : {})}
                      >
                        <img
                          src={`${ICON_PATH}/Group_2641.svg`}
                          alt={lblEOB.value}
                        />
                      </a>

                      <a
                        id={`eobdownload${index}`}
                        href={hrefValue}
                        className="text-zoom"
                        hidden
                        onClick={() => eobClick(index)}
                        {...(hrefValue !== ""
                          ? { download: "ClaimsHistory.pdf" }
                          : {})}
                      >
                        <img
                          src={`${ICON_PATH}/Group_2641.svg`}
                          alt={lblEOB.value}
                        />
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            {EOBDocumnet ? (
              <div className="col-xl-2 col-md-12 details d-md-flex d-xl-block">
                <div className="list-desc-title d-lg-block d-xl-none"></div>
                <div className="d-xl-block eob-doc-abs">
                  <Text field={lblEOBDoc} />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-xl-1 col-md-12 details d-md-flex d-xl-block">
              <div className="list-desc-title mt-md-2">
                <Text field={lblEOB} />
              </div>
              <div className="list-desc-details claim-items mt-lg-3">
                <div className="row position-relative ch-eob-icon">
                  <div className="col-md-1 claim-items Eob-icon-top-justify">
                    <a
                      id={`eob${index}`}
                      key={index}
                      href={hrefValue}
                      className="text-zoom"
                      onClick={(e) => eobClick(e, index)}
                      {...(hrefValue !== ""
                        ? { download: "ClaimsHistory.pdf" }
                        : {})}
                    >
                      <img
                        src={`${ICON_PATH}/Group_2641.svg`}
                        alt={lblEOB.value}
                      />
                    </a>

                    <a
                      id={`eobdownload${index}`}
                      href={hrefValue}
                      className="text-zoom"
                      hidden
                      onClick={() => eobClick(index)}
                      {...(hrefValue !== ""
                        ? { download: "ClaimsHistory.pdf" }
                        : {})}
                    >
                      <img
                        src={`${ICON_PATH}/Group_2641.svg`}
                        alt={lblEOB.value}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <>
          {EOBDocumnet ? (
            <div className="row">
              <div className="col-list-title d-xl-block eob-doc-sm">
                <Text field={lblEOBDoc} />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-1 claim-items Eob-icon-top-justify">
            <a
              id={`eob${index}`}
              key={index}
              href={hrefValue}
              className="text-zoom"
              onClick={(e) => eobClick(e, index)}
              {...(hrefValue !== "" ? { download: "ClaimsHistory.pdf" } : {})}
            >
              <img src={`${ICON_PATH}/Group_2641.svg`} alt={lblEOB.value} />
            </a>

            <a
              id={`eobdownload${index}`}
              href={hrefValue}
              className="text-zoom"
              hidden
              onClick={() => eobClick(index)}
              {...(hrefValue !== "" ? { download: "ClaimsHistory.pdf" } : {})}
            >
              <img src={`${ICON_PATH}/Group_2641.svg`} alt={lblEOB.value} />
            </a>
          </div>
        </>
      )}
    </>
  );
};
export default GetEOBDocument;
