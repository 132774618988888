import React from "react";

const DisplayItemCH = (props) => {
  const { nameField, valueField, outClass, titleClass, detailsClass } = props;
  return (
    <div className={`${outClass}`}>
      <div className={`${titleClass}`}>{nameField}</div>
      <div className={`${detailsClass}`}>
        <span>{valueField}</span>
      </div>
    </div>
  );
};

export default DisplayItemCH;
