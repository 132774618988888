import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardMedicaidNYLTC = ({
  MemberStateName,
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardNYLTCFront"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/NY/Medicaid/NY_MLTC_ID_Card_front.png)`,
          }}
          className="background-idcard ny-chp"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberNYMLTCID"
          >
            <tbody>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan="2" nowrap="nowrap">
                  <b>
                    <Text field={fields.lblName} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIdNy} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
                <td>
                  <b>
                    <Text field={fields.lblDOB} />
                  </b>
                  &nbsp; {idCard?.Member_DOB}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <b>
                    <Text field={fields.lblEffectiveDate} />
                  </b>
                  &nbsp; {idCard?.MemberEffectiveDate}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIssue} />
                  </b>
                  &nbsp; {result?.IdCardValues?.Issue}
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblSwhOfMltcBenefitsOnly} />
                  &nbsp;
                  <b>
                    <Text field={fields.lblMltcBenefitsOnly} />
                  </b>
                </td>
              </tr>

              <tr>
                <td>
                  {result?.IdCardValues["Contract#"]}
                  {/* &nbsp; {idCard?.MemberEffectiveDate} */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardNYLTC"
      >
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/NY/Medicaid/NY_MLTC_ID_Card_back.png)`,
            padding: "0.5rem",
          }}
          className="member-id-card-back ny-chp-back"
        >
          <div className="member-id-card-back-text">
            <div className="backcardmember">
              <div className="mb-2">
                <Text field={fields?.lblLtcEmergencyNearest} />
              </div>
              <div className="mb-2">
                <label className="width-25-ltc mb-2">
                  <b>
                    <Text field={fields.lblMembers} />
                  </b>
                </label>
                <span className="pb-2">
                  <Text field={fields.lblMemberDetails} />
                </span>
              </div>
              <div className="mb-2">
                <label className="width-25-ltc mb-2">
                  <b>
                    <Text field={fields.lblProviders} />
                  </b>
                </label>
                <span className="pb-2 inline-flex">
                  <Text field={fields.lblEligibilityDentalLtc} />
                </span>
              </div>
              <div className="mb-2">
                <label className="width-25-ltc mb-2">
                  <b>
                    <Text field={fields.lblWebsites} />
                  </b>
                </label>
                <span className="pb-2">
                  <Text field={fields.lblWebsiteDetails} />
                </span>
              </div>
              <div className="mb-2">
                <label className="width-25-ltc mb-2">
                  <b>
                    <Text field={fields.lblSubmitClaimKY} />
                  </b>
                </label>
                <span className="pb-2 inline-flex">
                  <Text field={fields.lblSubmitAddressLtc} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicaidNYLTC;
