import React, { useState, useContext } from "react";
import DisplayItem from "../MyResources-Layout/Common/DisplayItem";
import { ICON_PATH, MARKETPLACE } from "../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import * as MyResourcesAPI from "../../services/MyResourcesAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { getFormattedDate } from "../../utils/helpers";
import { ModalContext } from "./../../contexts/modal.context";
import { ErrorContext } from "../../contexts/error.context";

const ELetterRecords = (props) => {
  const { fields, data } = props;
  const { setError } = useContext(ErrorContext);
  const { memberLOB, MemberStateName, memberRKSID } = useContext(AppContext);
  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();
  const divSpan = isMarketplaceMember ? 3 : 5;
  const [hrefValue, setHrefValue] = useState("#");
  const { openModal, closeModal } = useContext(ModalContext);

  const eletterClick = (e, item) => {
    let apiResponse = null;
    refreshCacheAndReload();
    MyResourcesAPI.getELetterDocument({
      rksMemberId: memberRKSID, //"NYM17182000064",
      Statecode: MemberStateName,
      FileName: item.FileName,
      FilePath: item.FilePath,
      Source: item.Source,
      Vendor: item.VendorGroupName,
      DocID: item.DocumentID,
    })
      .then((apiResponse) => {
        if (apiResponse.ResultCode == "2001") {
          if (apiResponse.ResultData.Content !== null) {
            const link = document.createElement("a");
            link.href =
              "data:application/octet-stream;base64," +
              apiResponse.ResultData.Data;
            link.download = item.FileName;
            link.click();
          } else {
            filemsgModal();
          }
        } else {
          filemsgModal();
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {});
  };
  const filemsgModalpop = () => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>
            <Text field={fields?.lblMessage ?? ""} />
          </p>
        </div>
        <div className="comm-btns">
          <button
            type="button"
            className="btn continue-button"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            <Text field={fields?.lblClose ?? ""} />
          </button>
        </div>
      </div>
    </>
  );
  const filemsgModal = () => {
    openModal({
      content: filemsgModalpop(),
      version: 1,
    });
  };
  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
  };

  return (
    <>
      {/* <div className="row print-heading mb-4 d-none">
        <Text field={fields.lblprintHeading} />
      </div>
      <br /> <br /> */}
      {/* <div className="float-end w-30 pagination custom-tooltip-spending text-right"> */}
      <span
        className="float-end w-30 custom-tooltip-spending text-right pb-3"
        style={{ marginBottom: "1px" }}
      >
        <span className="manage-content">
          <Text field={props.fields.lblprintfield1} /> &nbsp;
        </span>
        <a className="list-icon-download" style={{ marginBottom: "100px" }}>
          <img src={`${ICON_PATH}/Group_2641.svg`} className="tooltip-img" />
        </a>
        <span className="manage-content">
          &nbsp;
          <Text field={props.fields.lblprintfield2} />
        </span>
      </span>
      {/* </div> */}
      <div className="clearfix"></div>
      <div>
        <ul className="list-group" id="recordsToPrint">
          {data?.map((item, index) => (
            <li className="list-group-item" key={index}>
              <div className="row label-list">
                <DisplayItem
                  // nameField={item.LetterCategory}
                  valueField={item.LetterName}
                  outClass={`col-xl-5 col-md-6 d-xl-block`}
                  titleClass={"list-desc-title"}
                  isdatafield={false}
                />
                <DisplayItem
                  nameField={fields.lblDate}
                  outClass={`col-xl-5 col-md-6 d-xl-block d-none`}
                  titleClass={"list-desc-title"}
                  isdatafield={false}
                />
                <div className="col-xxl-2 col-md-2">
                  <div className="text-right">
                    <div>
                      <a
                        key={index}
                        id={`a_letterDownload${index}`}
                        //href={hrefValue}
                        className="list-icon-download"
                        onClick={(e) => eletterClick(e, item)}
                        {...(hrefValue !== ""
                          ? { download: item.FileName }
                          : {})}
                      >
                        <img
                          src={`${ICON_PATH}/Group_2641.svg`}
                          alt={""}
                          id={`downloadIcon2`}
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <DisplayItem
                  //nameField={item.LetterName}
                  valueField={item.LetterCategory}
                  outClass={`col-xl-${divSpan} col-md-12 d-md-flex d-xl-block`}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                  isdatafield={true}
                />
                <DisplayItem
                  nameField={fields.lblDate}
                  valueField={getFormattedDate(item.Date, "mm/dd/yyyy")}
                  outClass={`col-xl-${divSpan} col-md-12 d-md-flex d-xl-block`}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                  isdatafield={true}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ELetterRecords;
