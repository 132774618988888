import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as AssessmentsAPI from "../services/AssessmentsAPI";
import { MY_WELLNESS_TAKE_ASSESSMENT } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";
const useGetDynamicAssessmentQuestionsAndAnswersAPI = ({
  assessmentId,
  versionId,
}) => {
  const { setError } = useContext(ErrorContext);
  const { setInnerSpinner } = useContext(SpinnerContext);
  const [assessmentDetails, setAssessmentDetails] = useState(null);
  const [responderName, setResponderName] = useState("");
  const [relationshipToMember, setRelationshipToMember] = useState("");
  const [memberHealthcareID, setMemberHealthcareID] = useState("");
  const [statusMessage, setStatusMessage] = useState(null);
  const [refreshAPI, setRefreshAPI] = useState(false);
  const [isResultsloaded, setIsResultsloaded] = useState(false);

  const setData = (response) => {
    setAssessmentDetails(response?.ResultData);
    setResponderName(response?.ResultData?.RespondersName);
    setRelationshipToMember(response?.ResultData?.RelationshipToMember);
    setMemberHealthcareID(response?.ResultData?.MemberHealthcareId);
    setStatusMessage(response?.Message);
    setIsResultsloaded(true);
  };

  useEffect(() => {
    if (refreshAPI) {
      callApi();
    } else if (assessmentDetails === null) {
      callApi();
    }
  }, [refreshAPI]);

  const callApi = () => {
    if (assessmentId !== "" && versionId !== "") {
      setInnerSpinner(true, MY_WELLNESS_TAKE_ASSESSMENT);
      AssessmentsAPI.getDynamicAssessmentQuestionsAndAnswers({
        SecMemId: "",
        SecRoleId: "",
        DropdownSelectedMemId: "",
        AssessmentId: assessmentId,
        VersionId: versionId,
      })
        .then((response) => {
          if (response?.ResultData != undefined) {
            setData(response);
            if (refreshAPI) {
              setRefreshAPI(false);
            }
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MY_WELLNESS_TAKE_ASSESSMENT);
        });
    }
  };
  const refreshAPIDataAll = () => {
    setIsResultsloaded(false);
    setRefreshAPI(true);
  };
  return {
    assessmentDetails,
    statusMessage,
    isResultsloaded,
    responderName,
    relationshipToMember,
    memberHealthcareID,
    refreshAPIDataAll,
  };
};

export { useGetDynamicAssessmentQuestionsAndAnswersAPI };
