import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import { ErrorContext } from "../contexts/error.context";
import * as MyResourcesAPI from "../services/MyResourcesAPI";
import { MY_RESOURCES_NOTICE_AND_LETTERS } from "../define.widgets";

const qs = require("qs");

const useElecNotifAPI = (
  page = 1,
  recordsPerPage,
  fetchData = true,
  searchTerm = null
) => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  //const [serviceAuthorizationList, setServiceAuthorizationList] = useState([]);
  const [memberLetterList, setMemberLetterList] = useState([]);
  const { MemberStateName, memberRKSID, memberLOB, prefLang } =
    useContext(AppContext);
  const [isMemberLetterListloaded, setisMemberLetterListloaded] =
    useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [letterList, setLetterList] = useState([]);

  const setData = (data) => {
    setLetterList(data.ResultData.LetterNameList);
    setMemberLetterList(data.ResultData.lettersList);
    setisMemberLetterListloaded(true);

    if (data.ResultData.lettersList !== null)
      setTotalRecords(data.ResultData?.TotalRecords);
  };

  useEffect(() => {
    let startDate = "",
      endDate = "",
      letterName = "";

    if (searchTerm != null) {
      startDate = searchTerm["startDate"];
      endDate = searchTerm["endDate"];
      letterName = searchTerm["letterName"];
    }

    let query = {
      Page: page,
      RecordsPerPage: recordsPerPage,
      StartDate: startDate,
      EndDate: endDate,
      LetterName: letterName.toLowerCase() === "all" ? "" : letterName,
    };

    if (fetchData) {
      setInnerSpinner(true, MY_RESOURCES_NOTICE_AND_LETTERS);
      console.log(prefLang);
      let lettertype = "";
      if (MemberStateName == "NY") {
        lettertype = "Determination";
      }
      MyResourcesAPI.getELetterList(
        {
          MemberID: memberRKSID,
          LOB: memberLOB,
          State: MemberStateName,
          LetterType: lettertype,
          Page: page,
          RecordsPerPage: recordsPerPage,
          StartDate: startDate,
          EndDate: endDate,
          LetterName:
            letterName.toLowerCase() === "all" ||
            letterName.toLowerCase() === "todos"
              ? ""
              : letterName,
          Language: prefLang,
          // MemberID: memberRKSID,
          // LOB: memberLOB,
          // State: MemberStateName,
          // LetterType: "Determination",
          // Page: page,
          // RecordsPerPage: recordsPerPage,
          // Langauage: "EN",
          // StartDate: "",
          // EndDate: "",
          // LetterName: "",
        },
        qs.stringify(query, { addQueryPrefix: true })
      )
        .then((response) => {
          if (response != undefined && response != null) {
            setData(response);
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MY_RESOURCES_NOTICE_AND_LETTERS);
        });
    }
  }, [page, recordsPerPage, fetchData, searchTerm]);

  return {
    letterList,
    memberLetterList,
    isMemberLetterListloaded,
    totalRecords,
  };
};

export { useElecNotifAPI };
