import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as DDLYearsAPI from "../services/DDLYearsAPI";
import { AppContext } from "../contexts/app.context";
import { COVERAGE_ELIGIBILITY } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";
import { MARKETPLACE } from "../define.constants";

const useGetDDLYearsAPI = () => {
  const { setError } = useContext(ErrorContext);
  const { setInnerSpinner } = useContext(SpinnerContext);
  const [isIdCardToggleRemovalFlag, setIsIdCardToggleRemovalFlag] =
    useState(false);
  const [listRecords, setListRecords] = useState([]);
  const [planData, setPlanData] = useState([]);
  const { memberRKSID, memberLOB } = useContext(AppContext);
  let isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;

  const setData = (data) => {
    if (!isMarketplaceMember) {
      setPlanData(data.ResultData);
    }
    setIsIdCardToggleRemovalFlag(
      data.ResultData.ListYears.IsIdCardToggleRemovalFlag
    );
    setListRecords(data.ResultData.ListYears.ListEnrollmentRecords);
  };

  useEffect(() => {
    if (memberRKSID != "") {
      setInnerSpinner(true, COVERAGE_ELIGIBILITY);
      DDLYearsAPI.getDDLYears({
        RKSMemberId: memberRKSID,
      })
        .then((response) => {
          setData(response);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, COVERAGE_ELIGIBILITY);
        });
    }
  }, [memberRKSID]);

  return {
    listRecords,
    isIdCardToggleRemovalFlag,
    planData,
  };
};

export { useGetDDLYearsAPI };
