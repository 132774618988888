import React, { useState, useContext } from "react";
import { File, Link, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import {
  ICON_PATH,
  PWD_VALIDATION_REGEX2,
  SUPPORT_USER,
  TERMED_MEMBER,
  SWITCHED_TO_DEPENDENT,
} from "../../define.constants";
import { object } from "prop-types";
import { redirectTo } from "../../utils/helpers";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

export var createFileIcon = (
  formDoc,
  index,
  language,
  className,
  multiLanguage,
  tabName,
  cls_vname
) => {
  return formDoc &&
    formDoc.value !== null &&
    formDoc.value !== undefined &&
    formDoc.value !== "" ? (
    <File
      key={index}
      id="a_download1"
      field={formDoc}
      // className="dropdown-item"
      className={className}
      target="_blank"
      cls_vname={cls_vname}
    >
      {multiLanguage ? (
        <span>{language.value}</span>
      ) : (
        <img
          src={`${ICON_PATH}/Group_2641.svg`}
          alt={tabName?.value}
          id={`downloadIcon1${index}`}
        />
      )}
    </File>
  ) : multiLanguage ? (
    <span key={index} className={className}>
      {language.value}
    </span>
  ) : (
    <div>
      <a
        key={`a_download1${index}`}
        id="a_download1"
        href="#"
        className="list-icon-download"
        cls_vname={cls_vname}
      >
        <img
          src={`${ICON_PATH}/Group_2641.svg`}
          alt={tabName?.value}
          id={`downloadIcon2${index}`}
        />
      </a>
    </div>
  );
};

export var createLinkIcon = ({
  link,
  index,
  language,
  className,
  multiLanguage,
  imageName,
  tabName,
  distItem,
  MemberStateName,
  memberLOB,
  name,
  planId,
  selectedYear,
  isYearSpecific,
  byPassAccessControl = false,
  customPopupContent = "",
  cls_vname,
}) => {
  let helpfulLink = "";
  let actionUnavailableForHelpfulLink = false;
  if (link?.value?.href !== "" || link?.value?.href !== "http://#") {
    helpfulLink = link?.value?.href;
  } else {
    helpfulLink = "#";
  }
  if (
    (helpfulLink === "#" || helpfulLink === "" || helpfulLink === undefined) &&
    language < new Date().getFullYear()
  ) {
    actionUnavailableForHelpfulLink = true;
  }

  return name ? (
    getPlanDocIcon(
      distItem,
      MemberStateName,
      memberLOB,
      name,
      planId,
      selectedYear,
      isYearSpecific,
      index,
      cls_vname
    )
  ) : link &&
    ((link.value !== null && link.value !== undefined && link.value !== "") ||
      (link !== "" &&
        (link.value === null ||
          link.value === undefined ||
          link.value === ""))) ? (
    //     link.value === "")) ||
    // (link !== "" &&
    //   (link?.value?.href == "" || link?.value?.href == "http://#"))) ? (
    // <Link aria-label={link?.value?.href} field={link} className={className}>

    <WhoIsAuthorized
      type="link"
      href={helpfulLink}
      authorize={{
        [SUPPORT_USER]: true,
        [TERMED_MEMBER]: true,
      }}
      byPassAccessControl={byPassAccessControl}
      actionUnavailable={actionUnavailableForHelpfulLink}
      customMessage={customPopupContent}
    >
      {({ handleClick, href }) => {
        return (
          <a
            id={`a_downloadIcon${index}`}
            href={href}
            rel="noopener noreferrer"
            className={className}
            onClick={handleClick}
            cls_vname={cls_vname}
            {...(link.value !== null &&
            link.value !== undefined &&
            link.value !== ""
              ? link?.value?.href != "" &&
                link?.value?.href != "#" &&
                link?.value?.target !== null &&
                link?.value?.target !== undefined &&
                link?.value?.target !== ""
                ? { target: "_blank" }
                : {}
              : {})}
          >
            {multiLanguage ? (
              <span>
                {" "}
                {language.value != undefined ? language.value : language}
              </span>
            ) : (
              <img
                src={`${ICON_PATH}/${imageName}`}
                alt={tabName?.value}
                id={`downloadIcon1${index}`}
              />
            )}
          </a>
        );
      }}
    </WhoIsAuthorized>
  ) : // </Link>
  multiLanguage ? (
    <span key={index} className={className}>
      {language.value != undefined ? language.value : language}
    </span>
  ) : (
    <div>
      <a
        key={`a_download1${index}`}
        id="a_download1"
        href="#"
        className={className}
        cls_vname={cls_vname}
      >
        <img
          src={`${ICON_PATH}/${imageName}`}
          alt={tabName?.value}
          id={`downloadIcon2${index}`}
        />
      </a>
    </div>
  );
};

const getPlanUrl = (
  keys,
  distItem,
  param,
  param1,
  isYearSpecific,
  selectedYear,
  name
) => {
  let planUrl = "#";
  if (keys !== null && keys !== undefined) {
    keys.forEach((item) => {
      if (
        (isYearSpecific &&
          (item
            .toLocaleLowerCase()
            .includes((param + selectedYear).toLocaleLowerCase()) ||
            (item
              .toLocaleLowerCase()
              .includes((param1 + "~" + selectedYear).toLocaleLowerCase()) &&
              name === "Handbook"))) ||
        (!isYearSpecific &&
          (item.toLocaleLowerCase().includes(param.toLocaleLowerCase()) ||
            (item.toLocaleLowerCase().includes(param1.toLocaleLowerCase()) &&
              name === "Handbook")) &&
          item.includes("Common"))
      ) {
        planUrl = distItem?.phrases[item];
        return planUrl;
      }
      return planUrl;
    });
    return planUrl;
  }
};

const getPlanDocIcon = (
  distItem,
  MemberStateName,
  memberLOB,
  name,
  planId,
  selectedYear,
  isYearSpecific,
  index,
  cls_vname
) => {
  var keys = distItem !== null ? Object.keys(distItem?.phrases) : null;
  let planurl = "#";
  let param1 = MemberStateName + `~` + memberLOB + `~` + name;
  let param =
    MemberStateName + `~` + memberLOB + `~` + name + `~` + planId + `~`;

  planurl = getPlanUrl(
    keys,
    distItem,
    param,
    param1,
    isYearSpecific,
    selectedYear,
    name
  );

  if (selectedYear == distItem?.phrases.comingSoonYear && planurl === "#") {
    let comingSoonKey = MemberStateName + "~comingSoonYearUrl";
    planurl = distItem?.phrases[comingSoonKey];
  }
  let actionUnavailableForDocs = false;
  if (
    selectedYear !== distItem?.phrases.comingSoonYear &&
    selectedYear < new Date().getFullYear() &&
    planurl === "#"
  ) {
    actionUnavailableForDocs = true;
  }

  return (
    <>
      <WhoIsAuthorized
        type="link"
        href={planurl}
        actionUnavailable={actionUnavailableForDocs}
      >
        {({ handleClick, href }) => {
          return (
            <a
              id={`a_downloadPlanIcon${index}`}
              href={planurl}
              {...(planurl !== "#" ? { target: "_blank" } : {})}
              onClick={handleClick}
              rel="noopener noreferrer"
              className="list-icon-download"
              cls_vname={cls_vname}
            >
              <img src={`${ICON_PATH}/Group_2641.svg`} />
            </a>
          );
        }}
      </WhoIsAuthorized>
    </>
  );
};
