import React, { useContext, useEffect, useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Formik, Form, Field } from "formik";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import { AppContext } from "../../contexts/app.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ACCOUNT_SETTINGS_MY_PROFILE } from "../../define.widgets";
import { useViewContactPreferences } from "../../hooks/useViewContactPreferences";
import { CustomSelect } from "../../common/Select";
import {
  MARKETPLACE,
  SUCCESS_RESPONSE,
  SUCCESS_RESULT_CODE,
  TERMED_MEMBER,
  SUPPORT_USER,
} from "../../define.constants";
import * as AccountSettingsProfileAPI from "../../services/AccountSettingsProfileAPI";
import { ErrorContext } from "../../contexts/error.context";
import { ModalContext } from "./../../contexts/modal.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
function MemberNameChange(props) {
  let {
    profileList,
    isDependentLoggedIn,
    memberLOB,
    refreshGetAccountProfileAPI,
    isTermedMember,
    memberRKSID,
  } = useContext(AppContext);
  const [isEditable, setIsEditable] = useState(false);
  const { openModal, closeModal } = useContext(ModalContext);
  const { innerSpinnerHTML, setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const accountStateMyProfile = props?.fields?.MyProfileList[1] ?? [];
  const accountDetailMyProfile = props?.fields?.MyProfileList[0] ?? [];
  const initialValues = { txtCity: profileList.City };
  const { contactData, refreshMSGInfoAll, isViewContactLoaded } =
    useViewContactPreferences();
  const onSubmit = () => {
    saveNameChangeRequest();
  };
  const [hiddenDisclaimer, setHiddenDisclaimer] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [suffix, setSuffix] = useState("");
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleMiddleNameChange = (e) => {
    setMiddleName(e.target.value);
  };
  const handleSuffixChange = (e) => {
    setSuffix(e.target.value);
  };
  const saveNameChangeRequest = () => {
    if (
      firstName == null ||
      firstName?.trim()?.length < 1 ||
      firstName?.trim()?.length > 60
    )
      setFirstName(profileList.FirstName);
    if (
      lastName == null ||
      lastName?.trim()?.length < 1 ||
      lastName?.trim()?.length > 60
    )
      setLastName(profileList.LastName);
    if (
      middleName == null ||
      middleName?.trim()?.length < 1 ||
      middleName?.trim()?.length > 60
    )
      setMiddleName(profileList.middleName);
    if (
      suffix == null ||
      suffix?.trim()?.length < 5 ||
      suffix?.trim()?.length > 9
    )
      setSuffix(profileList.suffix);
    setSpinner(true);
    AccountSettingsProfileAPI.saveNameChangeRequest({
      RKSMemberID: memberRKSID,
      StateName: profileList?.State,
      MemberNumber: profileList?.MemberId,
      FirstName: profileList?.FirstName,
      LastName: profileList?.LastName,
      DateOfBirth: profileList?.DateOfBirth,
      RequestedFirstName: firstName?.trim(),
      RequestedMiddleName: middleName?.trim(),
      RequestedLastName: lastName?.trim(),
      RequestedSuffix: suffix?.trim(),
    })
      .then((data) => {
        if (data) {
          if (data.ResultCode === SUCCESS_RESULT_CODE) {
            refreshGetAccountProfileAPI();
            setIsEditable(false);
            showModal(true);
          } else {
            showModal(false);
          }
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const updateNameChangeModal = (success) => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>
            {success ? (
              <Text
                field={accountDetailMyProfile?.fields?.mncSuccessMessage ?? ""}
              />
            ) : (
              <Text
                field={accountDetailMyProfile?.fields?.mncFailureMessage ?? ""}
              />
            )}
          </p>
        </div>
        <div className="comm-btns">
          <button
            type="button"
            className="btn continue-button"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            <Text field={accountDetailMyProfile?.fields?.lblClose ?? ""} />
          </button>
        </div>
      </div>
    </>
  );
  const showModal = (success) => {
    openModal({
      content: updateNameChangeModal(success),
      version: 1,
    });
  };

  useEffect(() => {
    if (isDependentLoggedIn === true) {
      setHiddenDisclaimer(true);
    }
  }, [isDependentLoggedIn]);

  const isMarketplaceMember = memberLOB.toLowerCase() === MARKETPLACE;

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize="true">
        {() => {
          return (
            <Form>
              <li className="list-group-item">
                {innerSpinnerHTML?.[ACCOUNT_SETTINGS_MY_PROFILE]}
                <div className="row">
                  <div className="col-xl-8 col-md-6">
                    <h5 className="tab-sub-title">
                      <Text
                        field={
                          accountDetailMyProfile?.fields?.nameChangeTitle ?? ""
                        }
                      />
                    </h5>
                  </div>
                  {!isMarketplaceMember && !isEditable && (
                    <div
                      className="col-sm-12 col-xs-12 d-md-none d-lg-none d-xl-none"
                      style={{ height: 40 }}
                    ></div>
                  )}
                  <div className="col-xl-4 col-md-6">
                    {!isMarketplaceMember && !isEditable && (
                      <button
                        className="btn btn-primary update-button"
                        onClick={() => setIsEditable(true)}
                        type="button"
                        disabled={isTermedMember}
                      >
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.updateButtonText
                          }
                        />
                      </button>
                    )}
                    {!isMarketplaceMember && isEditable && (
                      <div
                        className="personal-details-btns"
                        style={{ float: "right" }}
                      >
                        <WhoIsAuthorized
                          authorize={{
                            [SUPPORT_USER]: false,
                            [TERMED_MEMBER]: false,
                          }}
                          handleClick={onSubmit}
                        >
                          {({ handleClick, disabledClass }) => {
                            return (
                              <button
                                className="btn btn-primary m-3 w-md-35 w-sm-100"
                                onClick={handleClick}
                                disabled={
                                  disabledClass ||
                                  (firstName?.length < 10 &&
                                    lastName?.length < 5)
                                }
                              >
                                <Text
                                  field={
                                    accountDetailMyProfile?.fields
                                      ?.saveButtonText
                                  }
                                />
                              </button>
                            );
                          }}
                        </WhoIsAuthorized>
                        <button
                          className="btn btn-default m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100 "
                          type="button"
                          onClick={() => setIsEditable(false)}
                        >
                          <Text
                            field={accountDetailMyProfile?.fields?.lblCancel}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {!isMarketplaceMember && isEditable && (
                  <div
                    className="row field1 common-input"
                    style={{ paddingBottom: 40 }}
                  >
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12">
                      <div className="row-title">
                        <label htmlFor="memberFirstName">
                          <Text
                            field={accountDetailMyProfile?.fields?.firstName}
                          />
                        </label>
                      </div>
                      <div
                        className="row-details"
                        style={{ position: "relative" }}
                      >
                        <Field
                          name="txtfirstname"
                          id="txtfirstname"
                          type="text"
                          value={firstName}
                          maxLength={60}
                          className="form-control"
                          onChange={(e) => handleFirstNameChange(e)}
                          style={{ border: "1px solid #ccc" }}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12">
                      <div className="row-title">
                        <label htmlFor="middleName">
                          <Text
                            field={accountDetailMyProfile?.fields?.middleName}
                          />
                        </label>
                      </div>
                      <div
                        className="row-details"
                        style={{ position: "relative" }}
                      >
                        <Field
                          name="txtmiddlename"
                          id="txtmiddlename"
                          type="text"
                          value={middleName}
                          maxLength={60}
                          className="form-control"
                          onChange={(e) => handleMiddleNameChange(e)}
                          style={{ border: "1px solid #ccc" }}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12">
                      <div className="row-title">
                        <label htmlFor="lastName">
                          <Text
                            field={accountDetailMyProfile?.fields?.lastName}
                          />
                        </label>
                      </div>
                      <div
                        className="row-details"
                        style={{ position: "relative" }}
                      >
                        <Field
                          name="txtlastname"
                          id="txtlastname"
                          type="text"
                          value={lastName}
                          maxLength={60}
                          className="form-control"
                          onChange={(e) => handleLastNameChange(e)}
                          style={{ border: "1px solid #ccc" }}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12">
                      <div className="row-title">
                        <label htmlFor="suffix">
                          <Text
                            field={accountDetailMyProfile?.fields?.suffix}
                          />
                        </label>
                      </div>
                      <div
                        className="row-details"
                        style={{ position: "relative" }}
                      >
                        <Field
                          name="txtsuffix"
                          id="txtsuffix"
                          type="text"
                          value={suffix}
                          maxLength={5}
                          className="form-control"
                          onChange={(e) => handleSuffixChange(e)}
                          style={{ border: "1px solid #ccc", width: 100 }}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div
                      className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12"
                      style={{ fontSize: 14, marginTop: 30 }}
                    >
                      <Text
                        field={
                          accountDetailMyProfile?.fields
                            ?.nameChangeDisclaimer ?? ""
                        }
                      />
                    </div>
                  </div>
                )}
              </li>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default MemberNameChange;
