import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import { CustomSelect } from "../../common/Select";
import {
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {
  SUCCESS_RESPONSE,
  SUCCESS_RESULT_CODE,
  TERMED_MEMBER,
  SUPPORT_USER,
  MEDICAID,
  NM,
  NM_MEDICAID_ProgramID,
  TX,
  TX_MD_PLUS_PROGRAMID,
  SPANISH_LANG_CODE,
} from "../../define.constants";
import * as Yup from "yup";
import { Form, Formik, Field, ErrorMessage, FastField } from "formik";
import { AppContext } from "../../contexts/app.context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { InlineFormError } from "../../common/InlineFormError";
import { ModalContext } from "../../contexts/modal.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { SubmitCareCoordinationRequestToCCA } from "../../services/MessagesAPI";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { colors } from "@material-ui/core";
import { highlightBorderOnError } from "../../utils/helpers";
import { ErrorContext } from "../../contexts/error.context";
import CareCoordinationTeam from "../MyHealthRecord-CTContactInfoAccess/CareCoordinationTeam";
import ServiceDescription from "../MyHealthRecord-Layout/Common/ServiceDescription";

const RequestCareCoordination = (props1) => {
  const { requestingEntities, fields } = props1;
  const requestingEntityList = [];
  const [requestingEntityState, setRequestingEntityState] = useState([]);
  const {
    programId,
    MemberStateName,
    memberName,
    prefPhone,
    prefEmailId,
    memberId,
    dateOfBirth,
    mailingAddress,
    memberLOB,
    prefLang,
  } = useContext(AppContext);
  const { openModal, closeModal } = useContext(ModalContext);
  let [othersEntryToInclude, setOthersEntry] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState({ reasons: [] });
  const [othersSelectedToEnableTextbox, setOthersSelected] = useState(false);
  const [enableSubmitButton, setSubmitBtnEnable] = useState(false);
  let [authorizeSelected, setAuthorizeChecboxValue] = useState(false);
  let [cancelClick, setCancelClick] = useState(false);
  let [reqEntityDDValueState, SetReqEntityDDValue] = useState([]);
  let reuestEntityDropDownValue = "";
  let reqEntityError = "";
  let isCancelClicked = false;
  const requiredMark = <span style={{ color: "red" }}>*</span>;
  const { setError } = useContext(ErrorContext);

  //TX Star Plus Program condition check
  const isTXStarPlus =
    MemberStateName == TX && programId == TX_MD_PLUS_PROGRAMID;

  const handleRequestingEntityValue = (e) => {
    SetReqEntityDDValue(e.value);
  };
  useEffect(() => {
    SetReqEntityDDValue(reqEntityDDValueState);
  }, [reqEntityDDValueState]);

  const RequestingEntity = () => {
    let careCoordinationDataFromMainPage = requestingEntities;
    if (
      (requestingEntityList === null && requestingEntityList === undefined) ||
      requestingEntityList.length === 0
    ) {
      careCoordinationDataFromMainPage?.map((data) => {
        requestingEntityList.push({
          value: data,
          id: data,
          label: data,
        });
        setRequestingEntityState([...requestingEntityList]);
      });
    }
  };

  useEffect(() => {
    RequestingEntity();
  }, []); //re-render only once

  useEffect(() => {
    setSelectedReasons(selectedReasons);
  }, [selectedReasons]);

  useEffect(() => {
    setAuthorizeChecboxValue(authorizeSelected);
  }, [authorizeSelected]);

  //on checkbox selection, assign the selected value in an array
  const handleChange = (e) => {
    let { value, checked } = e.target;
    let { reasons } = selectedReasons;
    if (e.target.name === "authorize") {
      setAuthorizeChecboxValue(checked ? true : false);
    } else {
      if (checked) {
        setSelectedReasons({ reasons: [...reasons, e.target.value] });
      } else {
        setSelectedReasons({
          reasons: reasons.filter((e) => e !== value),
        });
      }
    }
  };

  const BackToCareTeamComponent = () => {
    isCancelClicked = true;
    setCancelClick(isCancelClicked);
    props1.cancelCallBack(isCancelClicked);
    //we have props from RCC page only hence this listener is in
    //CareCoordinationTeam.js page and from there one more listener
    //will take to Main index page
  };

  useEffect(() => {
    setCancelClick(isCancelClicked);
  }, [isCancelClicked]);

  let othersSelected = false;
  if (selectedReasons != null && selectedReasons != undefined) {
    if (selectedReasons.reasons != undefined) {
      selectedReasons.reasons?.map((item, index) => {
        if (isTXStarPlus) {
          othersSelected = item === "Other" ? true : false;
        } else {
          othersSelected = item === "Others" ? true : false;
        }
      });
    }
  }

  useEffect(() => {
    setOthersSelected(othersSelected);
  }, [othersSelected]);

  const { setSpinner } = useContext(SpinnerContext);
  const formValues = {
    requestingEntity: "",
    reasonforRequestingCareCoordination: "",
  };
  const RequestSchema = Yup.object().shape({
    reqEntityError: Yup.string().required(
      getFieldValue(fields, "requestingEntityRequired")
    ),
  });

  useEffect(() => {
    setSubmitBtnEnable(enableSubmitButton);
  }, [enableSubmitButton]);

  const apiResponseTemplate = (heading, message) => (
    <div className="update-header">
      <p className="text-center">{heading}</p>
      <div className="text-center">
        <p>{message}</p>
      </div>
    </div>
  );

  const handleSubmit = (e, props) => {
    e.preventDefault();
    MakeRequest(props);
  };

  const MakeRequest = async (props) => {
    // remove the others entry if others is not selected
    if (isTXStarPlus && !othersSelected) {
      props["values"].othersEntry = "";
    }

    const request = {
      RequestingEntity: props["values"]?.requestingEntityList,
      ReasonforRequestingCareCoordination:
        props != null && props != undefined
          ? props["values"]?.othersEntry != undefined
            ? props["values"]?.othersEntry?.length === 0
              ? selectedReasons?.reasons?.toString()
              : selectedReasons?.reasons?.toString() +
                "," +
                props["values"]?.othersEntry
            : selectedReasons?.reasons?.toString()
          : selectedReasons?.reasons?.toString(), //converting array to comma separated string
      MemberEmail: prefEmailId,
    };

    let response;
    setSpinner(true);
    try {
      response = await SubmitCareCoordinationRequestToCCA(request);
    } catch (err) {
      setError({ err });
    } finally {
      setSpinner(false);
      const successMsgTitle = fields?.successApiTitle?.value;
      if (response?.ResultData) {
        // Preparing Success Message
        const successMessage =
          isTXStarPlus &&
          prefLang?.toLowerCase() === SPANISH_LANG_CODE.toLowerCase()
            ? fields?.successApiMessage?.value
            : response?.ResultData.replaceAll('"', "");

        const content = apiResponseTemplate(successMsgTitle, successMessage);

        openModal({
          content,
          version: 1,
        });
      }
    }
  };

  let CareCoordinator =
    MemberStateName?.toLowerCase() === NM?.toLowerCase() &&
    programId === NM_MEDICAID_ProgramID &&
    memberLOB.toLowerCase() === MEDICAID;

  return (
    <>
      <div>
        <div className="row p-2">
          <Text field={fields.headingRCC} />
        </div>
        <br></br>
        <Formik
          initialValues={formValues}
          //onSubmit={handleSubmit}
          validationSchema={RequestSchema}
          enableReinitialize={false}
        >
          {(props) => {
            return (
              <Form>
                <div className="blockSec">
                  <div className="row gx-4 gy-2">
                    <div className="col-md-4">
                      <label htmlFor="memberId" className="form-label textbold">
                        <Text field={fields.lblMemberId} />
                      </label>
                      <Field
                        name="memberId"
                        id="memberId"
                        type="text"
                        className={`form-control`}
                        disabled="true"
                        value={memberId}
                      />
                    </div>
                    <div className="col-md-4 mb10">
                      <label htmlFor="email" className="form-label textbold">
                        <Text field={fields.lblEmail} />
                      </label>
                      <Field
                        name="email"
                        id="email"
                        type="text"
                        className={`form-control`}
                        disabled="false"
                        value={prefEmailId}
                      />
                    </div>
                    <div className="col-md-4 mb10">
                      <label
                        htmlFor="phoneNumber"
                        className="form-label textbold"
                      >
                        <Text field={fields.lblPhoneNumber} />
                      </label>
                      <Field
                        name="phoneNumber"
                        id="phoneNumber"
                        type="text"
                        className={`form-control`}
                        disabled="true"
                        value={prefPhone}
                      />
                    </div>
                    <div className="col-md-4 mb10">
                      <label
                        htmlFor="memberName"
                        className="form-label textbold"
                      >
                        <Text field={fields.lblMemberName} />
                      </label>
                      <Field
                        name="memberName"
                        id="memberName"
                        type="text"
                        className={`form-control`}
                        disabled="true"
                        value={memberName}
                      />
                    </div>

                    <div className="col-md-4 mb10">
                      <label htmlFor="dob" className="form-label textbold">
                        <Text field={fields.lblDOB} />
                      </label>
                      <Field
                        name="dob"
                        id="dob"
                        type="text"
                        className={`form-control`}
                        disabled="true"
                        value={dateOfBirth}
                      />
                    </div>
                    <div className="col-md-4 mb10">
                      <label
                        htmlFor="requestingEntity"
                        className="form-label textbold"
                      >
                        <Text field={fields.lblRequestingEntity} />
                        {requiredMark}
                      </label>
                      <Field
                        name="requestingEntityList"
                        id="requestingEntityList"
                        as="select"
                        className={`form-control ${highlightBorderOnError(
                          props.errors.requestingEntityList,
                          props.touched.requestingEntity
                        )}`}
                        options={requestingEntityState}
                        onChange={(e) => handleRequestingEntityValue(e)}
                        isFormik={true}
                        isDisabled={false}
                        component={CustomSelect}
                        // customclass={
                        //   reqEntityDDValueState != undefined ||
                        //   reqEntityDDValueState.length === 0
                        //     ? "molina-select__control_err"
                        //     : ""
                        // }
                      ></Field>
                      <p className="text-danger" />
                      {reqEntityError}
                      <ErrorMessage
                        name="requestingEntityList"
                        component={InlineFormError}
                      />
                    </div>
                    <div className="col-md-12  formcheckmrgn">
                      <label
                        htmlFor="reasonRquesting"
                        className="form-label textbold"
                      >
                        <Text field={fields.lblReasonRequesting} />
                        {requiredMark}
                      </label>
                      {/* <DisplayItem
                        nameField={fields.lblReasonRequesting}
                        outClass={"test"}
                        titleClass={"form-label w-100 textbold"}
                        detailsClass={"form-label w-100 textbold"}
                      /> */}

                      {!isTXStarPlus && (
                        <>
                          {CareCoordinator || (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                value="Housing"
                                className="form-check-input"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator || (
                                  <Text field={fields.lblHousing} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator || (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                value="Health Management"
                                className="form-check-input"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator || (
                                  <Text field={fields.lblHealthManagement} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator || (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                value="Transportation"
                                className="form-check-input"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator || (
                                  <Text field={fields.lblTransportation} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator || (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                className="form-check-input"
                                value="Food"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator || (
                                  <Text field={fields.lblFood} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator || (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                className="form-check-input mr-2"
                                value="Benefits"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator || (
                                  <Text field={fields.lblBenefits} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator && (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                value="Children at Risk"
                                className="form-check-input"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator && (
                                  <Text field={fields.lblChildrenatRisk} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator && (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                value="Complex Medical/BH Needs"
                                className="form-check-input"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator && (
                                  <Text
                                    field={fields.lblComplexMedicalBHNeeds}
                                  />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator && (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                value="Health Management"
                                className="form-check-input"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator && (
                                  <Text field={fields.lblHealthManagement} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator && (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                className="form-check-input"
                                value="Medication"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                <Text field={fields.lblMedication} />
                              </label>
                            </div>
                          )}
                          {CareCoordinator && (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                value="Pregnancy"
                                className="form-check-input"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator && (
                                  <Text field={fields.lblPregnancy} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator && (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                value="SDOH"
                                className="form-check-input"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator && (
                                  <Text field={fields.lblSDOH} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator || (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                className="form-check-input mr-2"
                                value="Help Finding a Provider"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator || (
                                  <Text field={fields.lblHelpFindingProv} />
                                )}
                              </label>
                            </div>
                          )}
                          {CareCoordinator || (
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                name="reasonRequestItems"
                                className="form-check-input mr-2"
                                value="OH Rise / CANS Assessment"
                                onChange={handleChange}
                              />
                              <label className="form-check-label">
                                {CareCoordinator || (
                                  <Text
                                    field={fields.lblOHRiseCansAssessment}
                                  />
                                )}
                              </label>
                            </div>
                          )}
                          <div className="form-check form-check-inline inputOther">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              className="form-check-input"
                              value="Others"
                              onChange={handleChange}
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblOthers} />
                            </label>
                            <Field
                              name="othersEntry"
                              id="othersEntry"
                              type="text"
                              className={`other`}
                              disabled={!othersSelectedToEnableTextbox}
                            />
                          </div>
                        </>
                      )}
                      {isTXStarPlus && (
                        <>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              value="Children at Risk"
                              className="form-check-input"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !==
                                  "Children at Risk"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblChildrenAtRisk} />
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              value="Complex medical needs"
                              className="form-check-input"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !==
                                  "Complex medical needs"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblComplexMedicalNeeds} />
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              value="Behavioral Health Needs"
                              className="form-check-input"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !==
                                  "Behavioral Health Needs"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblBehavioralHealthNeeds} />
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              className="form-check-input"
                              value="Disease Management"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !==
                                  "Disease Management"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblDiseaseManagement} />
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              className="form-check-input"
                              value="Medication"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !== "Medication"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblMedication} />
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              className="form-check-input"
                              value="Maternal Mental Health"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !==
                                  "Maternal Mental Health"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblMaternalMentalHealth} />
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              className="form-check-input"
                              value="Maternal Case Management"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !==
                                  "Maternal Case Management"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblMaternalCaseManagement} />
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              className="form-check-input mr-2"
                              value="Housing"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !== "Housing"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblHousing} />
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              className="form-check-input mr-2"
                              value="Food"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !== "Food"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblFood} />
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              className="form-check-input mr-2"
                              value="Transportation"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !== "Transportation"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblTransportation} />
                            </label>
                          </div>
                          <div
                            className="form-check form-check-inline inputOther"
                            style={{ display: "block" }}
                          >
                            <input
                              type="checkbox"
                              name="reasonRequestItems"
                              className="form-check-input"
                              value="Other"
                              onChange={handleChange}
                              disabled={
                                selectedReasons?.reasons?.length == 1 &&
                                selectedReasons?.reasons[0] !== "Other"
                              }
                            />
                            <label className="form-check-label">
                              <Text field={fields.lblOther} />
                            </label>
                            <Field
                              name="othersEntry"
                              id="othersEntry"
                              type="text"
                              className={`other`}
                              disabled={!othersSelectedToEnableTextbox}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-md-4 mb10">
                        <label
                          htmlFor="mailingAddress"
                          className="form-label textbold"
                        >
                          <Text field={fields.lblMailingAddress} />
                        </label>
                        <Field
                          name="mailingAddress"
                          id="mailingAddress"
                          type="text"
                          className={`form-control`}
                          disabled="true"
                          value={mailingAddress}
                        />
                      </div>
                      {!isTXStarPlus && (
                        <div className="col-md-6 form-check mt20">
                          <input
                            type="checkbox"
                            name="authorize"
                            onChange={handleChange}
                            className={`form-check-input mr-2
                    ${
                      props.errors.authorize && props.touched.authorize
                        ? "is-invalid"
                        : null
                    }
                    `}
                          />
                          <p className="row-details">
                            <Text field={fields.lbldelegate} />
                          </p>
                        </div>
                      )}
                      <div className="col-md-6 form-check mt20">
                        <input
                          type="checkbox"
                          name="authorize"
                          onChange={handleChange}
                          className={`form-check-input mr-2
                         ${
                           props.errors.authorize && props.touched.authorize
                             ? "is-invalid"
                             : null
                         }
                          `}
                        />
                        <p className="row-details">
                          <Text field={fields.lblAuthorize} />
                        </p>
                      </div>
                      {isTXStarPlus && (
                        <>
                          <ServiceDescription
                            disclaimer={fields.lblAccountDisclaimer.value}
                            contactUs={fields.linkAccountSettings}
                          />
                        </>
                      )}

                      <div className="col-md-12 mt-2">
                        <WhoIsAuthorized
                          authorize={{
                            [SUPPORT_USER]: false,
                            [TERMED_MEMBER]: false,
                          }}
                          handleClick={(e) => handleSubmit(e, props)}
                        >
                          {({ handleClick, disabledClass }) => {
                            return (
                              <button
                                type="submit"
                                onClick={handleClick}
                                className="btn btn-primary m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
                                disabled={
                                  reqEntityDDValueState.length === 0 ||
                                  selectedReasons.reasons.length == 0 ||
                                  authorizeSelected === false
                                    ? true
                                    : false
                                }
                              >
                                <Text field={fields.btnSubmit} />
                              </button>
                            );
                          }}
                        </WhoIsAuthorized>
                        <button
                          type="button"
                          className="btn btn-primary m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
                          onClick={() => BackToCareTeamComponent()}
                        >
                          <Text field={fields.btnCancel} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {/* )} */}
    </>
  );
};

export default RequestCareCoordination;
