import React, { useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { ICON_PATH, SUPPORT_USER, TERMED_MEMBER } from "../../define.constants";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

function LinkInfo(props) {
  const { fields } = props;
  const { redirectToSSO } = useContext(SSORedirectContext);
  const { isTermedMember } = useContext(AppContext);

  return (
    <div className="col-xxl-12 col-sm-12 molina-card-link ">
      <WhoIsAuthorized
        type="link"
        href={props.href}
        authorize={{
          [SUPPORT_USER]: true,
          [TERMED_MEMBER]: false,
        }}
        handleClick={(e) =>
          redirectToSSO({
            e,
            url: constants.apigeeURLs.SapphireChangePCPSSO,
          })
        }
      >
        {({ handleClick, disabledClass, href }) => {
          return (
            <span
              className={`cursor-pointer custom-tooltip ${
                !props.isHref ? disabledClass : ""
              }`.trim()}
            >
              <a
                {...(props.isHref ? { href } : {})}
                onClick={(e) => (!props.isHref && handleClick(e)) || {}}
                className={`card-link ${
                  (props?.sectionClass, !props.isHref ? disabledClass : "")
                }`.trim()}
              >
                {props.text}
                {(!isTermedMember || props.isHref) && (
                  <img
                    className="link-icon2"
                    src={`${ICON_PATH}/arrow-right.svg`}
                    alt={getFieldValue(fields, "rightArrowAlt")}
                  />
                )}
              </a>
              <span className="custom-tooltip-text">
                <Text field={props.cardInfo} />
              </span>
            </span>
          );
        }}
      </WhoIsAuthorized>
    </div>
  );
}

export default LinkInfo;
