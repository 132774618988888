import React, { useState, useEffect, useContext } from "react";
import {
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { useGetAssessmentAndValidationListAPI } from "../../hooks/useGetAssessmentAndValidationListAPI";
import { ICON_PATH, MEDICAID, MEDICARE } from "../../define.constants";
import { assessmentStatus } from "../../utils/constants";
import { mobileAndTabletCheck } from "../../utils/helpers";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import {
  ASSESSMENT_FLAG_EXTERNAL,
  ASSESSMENTS_ID,
  SUPPORT_USER,
  TERMED_MEMBER,
  MARKETPLACE,
  SC,
  IA,
  IL,
  IL_MLTSS_PROGRAM,
  WA,
  WA_MD_BHSO,
  WA_MD_BHSO_PLANID,
  GLOBAL_VARIABLE,
  NM,
  NM_MEDICAID_ProgramID,
} from "../../define.constants";
import { MY_WELLNESS_GET_ASSESSMENTS } from "../../define.widgets";
import { AppContext } from "./../../contexts/app.context";
import {
  memberAgeCalculation,
  getStateLobConfigurationItems,
  isStateApplicableMyAssessmentMyHealthPerks,
  isStateApplicableMemberRewardsMyHealthPerks,
} from "../../utils/helpers";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import MemberRewards from "./MemberRewards";

const MyWellnessMyHealthPerks = (props) => {
  const { assessments, isApiResponded } =
    useGetAssessmentAndValidationListAPI();
  let { isTermedMember, MemberStateName, memberLOB, planId, programId } =
    useContext(AppContext);
  let { dateOfBirth } = useContext(AppContext);
  const memberFinalAge = memberAgeCalculation(dateOfBirth);
  const { redirectToSSO } = useContext(SSORedirectContext);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const [mobileDisplayCheck, setMobileDisplayCheck] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState([]);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  useEffect(() => {
    setFilteredRecords(assessments);
  }, [assessments]);

  useEffect(() => {
    setMobileDisplayCheck(mobileAndTabletCheck);
  }, [mobileDisplayCheck]);

  const BindLabelValue = ({ label, value }) => {
    return (
      <>
        <div className="list-desc-title">
          <Text field={label} />
        </div>
        <div className="list-desc-details">{value}</div>
      </>
    );
  };

  const BindLabelHealthPerksValue = ({ label, value }) => {
    return (
      <>
        <div className="list-desc-title">{label}</div>
        <div className="list-desc-details pt-2">{value}</div>
      </>
    );
  };

  const BindForm = ({ className, altText, href }) => {
    return (
      <WhoIsAuthorized
        type="link"
        href="_blank"
        authorize={{
          [SUPPORT_USER]: false,
          [TERMED_MEMBER]: false,
        }}
      >
        {({ handleClick, disabledClass }) => {
          return (
            <a
              href={href}
              {...(href !== "#" ? { target: "_blank" } : {})}
              className={`${className} ${disabledClass}`.trim()}
              aria-label={altText}
              onClick={handleClick}
            >
              {mobileDisplayCheck === false && (
                <img src={`${ICON_PATH}/Group_2641.svg`} alt={altText} />
              )}

              {mobileDisplayCheck === true && (
                <img src={`${ICON_PATH}/Group_2641.svg`} alt={altText} />
              )}
            </a>
          );
        }}
      </WhoIsAuthorized>
    );
  };

  const BindLink = ({ className, altText, href, statusIcon }) => {
    return (
      <WhoIsAuthorized
        type="link"
        href="#"
        authorize={{
          [SUPPORT_USER]: false,
          [TERMED_MEMBER]: false,
        }}
        handleClick={(e) => {
          redirectToSSO({
            e,
            url: constants.apigeeURLs.GetPDHISSORedirect,
          });
        }}
      >
        {({ handleClick, disabledClass }) => {
          return (
            <a
              href={href}
              className={`${className} ${disabledClass}`.trim()}
              aria-label={altText}
              onClick={handleClick}
            >
              {/* {statusIcon === assessmentStatus.completed &&
          mobileDisplayCheck === false && (
            <img src={`${ICON_PATH}/visibility-24px.svg`} alt={altText} />
          )}
        {statusIcon === assessmentStatus.inProgress &&
          mobileDisplayCheck === false && (
            <img src={`${ICON_PATH}/Group 3070.svg`} alt={altText} />
          )} */}
              {statusIcon === assessmentStatus.notTaken &&
                mobileDisplayCheck === false && (
                  <img
                    src={`${ICON_PATH}/open_in_new_black-24px.svg`}
                    alt={altText}
                  />
                )}
              {/* {statusIcon === assessmentStatus.completed &&
          mobileDisplayCheck === true && (
            <img
              src={`${ICON_PATH}/visibility-24px_mobile.svg`}
              alt={altText}
            />
          )}
        {statusIcon === assessmentStatus.inProgress &&
          mobileDisplayCheck === true && (
            <img src={`${ICON_PATH}/mode-24px.svg`} alt={altText} />
          )} */}
              {statusIcon === assessmentStatus.notTaken &&
                mobileDisplayCheck === true && (
                  <img
                    src={`${ICON_PATH}/open_in_new-24px_mobile.svg`}
                    alt={altText}
                  />
                )}
            </a>
          );
        }}
      </WhoIsAuthorized>
    );
  };

  const myHealthPerksDetailList = getStateLobConfigurationItems(
    props?.fields,
    MemberStateName,
    memberLOB,
    ""
  );

  const isILMLTSS =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === IL &&
    programId === IL_MLTSS_PROGRAM;
  const isMarketPlace = memberLOB.toLowerCase() === MARKETPLACE;
  const isMedicaid = memberLOB.toLowerCase() === MEDICAID;
  const isMedicare = memberLOB.toLowerCase() === MEDICARE;
  const scMedicaid =
    memberLOB.toLowerCase() === MEDICAID && MemberStateName === SC;
  const iaMedicaid =
    memberLOB.toLowerCase() === MEDICAID && MemberStateName === IA;
  const nmMedicaid =
    memberLOB.toLowerCase() === MEDICAID &&
    MemberStateName === NM &&
    programId === NM_MEDICAID_ProgramID;

  const isWABHSO =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === WA &&
    programId === WA_MD_BHSO &&
    planId === WA_MD_BHSO_PLANID;

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="tab-content" id="myTabContent">
            <div id="assessments" role="tabpanel" aria-labelledby="tab-1">
              {isMedicaid &&
              !isILMLTSS &&
              !isWABHSO &&
              isStateApplicableMemberRewardsMyHealthPerks(MemberStateName) ? (
                <div className="row no-tab-title accordion-md">
                  <div className="col-md-12">
                    <MemberRewards
                      fields={myHealthPerksDetailList[0]?.fields}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {isMarketPlace &&
                  isStateApplicableMyAssessmentMyHealthPerks(
                    MemberStateName
                  ) ? (
                    <>
                      <div className="row no-tab-title">
                        <div className="col-md-12">
                          <ul className="list-group ">
                            <li className="list-group-item">
                              <div className="row field1">
                                <div className="col-lg-10 col-md-11 col-sm-12">
                                  <BindLabelHealthPerksValue
                                    label={
                                      myHealthPerksDetailList[0]?.fields
                                        ?.healthPerksWellnessAssessmentNoticeTitle
                                        ?.value
                                    }
                                    value={
                                      myHealthPerksDetailList[0]?.fields
                                        ?.healthPerksWellnessAssessmentNoticeDescription
                                        ?.value
                                    }
                                  />
                                </div>
                                <div className="col-lg-2 col-md-1 col-sm-12">
                                  <div className="text-right">
                                    <BindLink
                                      className="list-icon-pen"
                                      altText="Not Taken"
                                      statusIcon="not taken"
                                      href="/home"
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {!isTermedMember && isMarketPlace && (
                        <>
                          <hr className="bottom-divider" />
                          <div className="row d-sm-none d-md-block">
                            <div className="col-lg-12 bottom-para">
                              <div>
                                <Text
                                  field={props?.fields?.disclaimerForPDHI}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row d-none d-sm-block d-md-none">
                            <div className="col-lg-12 bottom-para">
                              <p>
                                <Text
                                  field={props?.fields?.disclaimerForPDHI}
                                />
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="row no-tab-title">
                      <div className="col-md-12">
                        <ul className="list-group">
                          {filteredRecords?.length === 0 && !isApiResponded && (
                            <li className="list-group-item">
                              {innerSpinnerHTML?.[MY_WELLNESS_GET_ASSESSMENTS]}
                              <div className="row field1">
                                <div className="col-sm-12"></div>
                              </div>
                            </li>
                          )}

                          {filteredRecords
                            ?.filter(
                              (obj) =>
                                isMarketPlace && obj?.id === ASSESSMENTS_ID
                            )
                            .map((item, index) => (
                              <li className="list-group-item" key={index}>
                                <div className="row field1">
                                  <div className="col-lg-5 col-md-6 col-sm-12">
                                    <BindLabelValue
                                      label={
                                        props?.fields?.assessmentCategoryForPDHI
                                      }
                                      value={item.category}
                                    />
                                  </div>
                                  <div className="col-lg-5 col-md-5 col-sm-12">
                                    <BindLabelValue
                                      label={
                                        props?.fields?.assessmentNameForPDHI
                                      }
                                      value={item.name}
                                    />
                                  </div>
                                  <div className="col-lg-2 col-md-1 col-sm-12">
                                    <div className="text-right">
                                      <BindLink
                                        className="list-icon-pen"
                                        altText={item.status}
                                        statusIcon={item.status.toLowerCase()}
                                        href="/home"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>

                      {isMedicaid &&
                        (scMedicaid ? (
                          <>
                            <div
                              style={{
                                font: "normal normal 18px/21px campton-book",
                              }}
                            >
                              <div>
                                {" "}
                                <b>
                                  <Text field={props?.fields?.rewadsHeading} />{" "}
                                </b>
                              </div>
                              <div>&nbsp;</div>
                              <div>
                                <Text field={props?.fields?.helathConnection} />
                              </div>
                              <div>&nbsp;</div>
                              <div>
                                <Text field={props?.fields?.membership} />
                              </div>
                              <div>&nbsp;</div>
                              <div>
                                <Text field={props?.fields?.scMembers} />
                              </div>
                              <div>&nbsp;</div>
                              <div>&nbsp;</div>
                              <table border="1" className="scMedicaidRewads">
                                <tr>
                                  <th style={{ textAlign: "center" }}>
                                    {" "}
                                    <Text
                                      field={props?.fields?.labelsHeading}
                                    />
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    {" "}
                                    <Text
                                      field={props?.fields?.desciptionHeading}
                                    />
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    {" "}
                                    <Text
                                      field={props?.fields?.redirectionLink}
                                    />
                                  </th>
                                </tr>
                                <tr>
                                  <td>
                                    <Text
                                      field={props?.fields?.preganatMembers}
                                    />
                                  </td>
                                  <td>
                                    <Text field={props?.fields?.breastPump} />
                                    <br />
                                    <Text field={props?.fields?.breastPump1} />
                                    <br />
                                    <Text field={props?.fields?.beastPump2} />
                                    <br />
                                    <Text
                                      field={props?.fields?.breastPumpSuffix}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <a
                                      href="https://aeroflowbreastpumps.com/"
                                      target="blank"
                                    >
                                      <Text field={props?.fields?.Here} />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Text
                                      field={props?.fields?.benefitsFoMembers}
                                    />
                                  </td>
                                  <td>
                                    <Text field={props?.fields?.faceBookPage} />
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <a
                                      href="https://www.facebook.com/MolinaHealthSC"
                                      target="blank"
                                    >
                                      <Text
                                        field={props?.fields?.faceBookWebsite}
                                      />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Text
                                      field={props?.fields?.carseatProgam}
                                    />
                                  </td>
                                  <td>
                                    <Text
                                      field={props?.fields?.carseatDescription}
                                    />
                                  </td>
                                  <td>
                                    <RichText
                                      field={props?.fields?.carSeatRediection}
                                    />
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-12 no-assessment-records">
                              <Text
                                field={
                                  props?.fields?.disclaimerForHealthPerksNonMP
                                }
                              />
                            </div>
                          </>
                        )) &&
                        (iaMedicaid ? (
                          <>
                            <div>
                              <b style={{ color: "#009ea0" }}>
                                {
                                  myHealthPerksDetailList[0]?.fields
                                    ?.healthPerksWellnessAssessmentNoticeDescription
                                    ?.value
                                }
                              </b>
                              <div>&nbsp;</div>
                              <div className="col-md-12">
                                <b style={{ fontSize: "16px" }}>
                                  {
                                    myHealthPerksDetailList[0]?.fields
                                      ?.healthPerksWellnessAssessmentDescriptionIOWA
                                      ?.value
                                  }
                                </b>
                                <div>&nbsp;</div>
                                <div>&nbsp;</div>
                                <ul className="list-group ">
                                  <li className="list-group-item">
                                    <div className="row field1">
                                      <div className="col-lg-10 col-md-11 col-sm-12">
                                        <BindLabelHealthPerksValue
                                          label={
                                            myHealthPerksDetailList[0]?.fields
                                              ?.healthPerksWellnessAssessmentNoticeTitle
                                              ?.value
                                          }
                                        />
                                      </div>
                                      <div className="col-lg-2 col-md-1 col-sm-12">
                                        <div className="text-right">
                                          <BindForm
                                            className="list-icon-pen"
                                            href={
                                              myHealthPerksDetailList[0]?.fields
                                                ?.formLink?.value?.href
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {!isTermedMember && isMarketPlace && (
                              <>
                                <hr className="bottom-divider" />
                                <div className="row d-sm-none d-md-block">
                                  <div className="col-lg-12 bottom-para">
                                    <div>
                                      <Text
                                        field={props?.fields?.disclaimerForPDHI}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row d-none d-sm-block d-md-none">
                                  <div className="col-lg-12 bottom-para">
                                    <p>
                                      <Text
                                        field={props?.fields?.disclaimerForPDHI}
                                      />
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="col-md-12 no-assessment-records">
                              <Text
                                field={
                                  props?.fields?.disclaimerForHealthPerksNonMP
                                }
                              />
                            </div>
                          </>
                        )) &&
                        (nmMedicaid ? (
                          <>
                            <div className="accordion-item cards-tab3">
                              <div className="accordion-header" id="faqBlock">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-mdb-toggle="collapse"
                                  data-mdb-target={"#collapse0"}
                                  aria-expanded="false"
                                  aria-controls={"collapse0"}
                                >
                                  <Text
                                    field={
                                      props?.fields?.disclaimerForNMHealthPerks
                                    }
                                  />
                                </button>
                              </div>
                              <div
                                id={"collapse0"}
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-mdb-parent="#faqBlock"
                              >
                                <div className="accordion-body">
                                  <RichText
                                    field={props?.fields?.disclaimerForNMLink}
                                  />
                                  {/* <a href="https://dev0-cm.molinahealthcare.com/~/media/Molina/PublicWebsite/PDF/members/NM_2024/en-us/Turquoise_Rewards.docx">
                                    <Text
                                      field={props?.fields?.disclaimerForNMLink}
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item cards-tab4">
                              <div className="accordion-header" id="faqBlock1">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-mdb-toggle="collapse"
                                  data-mdb-target={"#collapse1"}
                                  aria-expanded="false"
                                  aria-controls={"collapse1"}
                                >
                                  <Text
                                    field={props?.fields?.disclaimerForNMHealth}
                                  />
                                </button>
                              </div>
                              <div
                                id={"collapse1"}
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-mdb-parent="#faqBlock1"
                              >
                                <div className="accordion-body">
                                  <Text
                                    field={
                                      props?.fields?.disclaimerForNMComingSoon
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-12 no-assessment-records">
                              <Text
                                field={
                                  props?.fields?.disclaimerForHealthPerksNonMP
                                }
                              />
                            </div>
                          </>
                        ))}
                      {isMedicare && (
                        <>
                          <div className="col-md-12 no-assessment-records">
                            <Text
                              field={
                                props?.fields?.disclaimerForHealthPerksMedicare
                              }
                            />
                          </div>
                        </>
                      )}

                      {isTermedMember && isMarketPlace && (
                        <>
                          <div className="col-md-12 no-assessment-records">
                            <Text
                              field={props?.fields?.disclaimerForPDHITermedUser}
                            />
                          </div>
                        </>
                      )}
                      {!isTermedMember && isMarketPlace && (
                        <>
                          <hr className="bottom-divider" />
                          <div className="row d-sm-none d-md-block">
                            <div className="col-lg-12 bottom-para">
                              <div>
                                <Text
                                  field={props?.fields?.disclaimerForPDHI}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row d-none d-sm-block d-md-none">
                            <div className="col-lg-12 bottom-para">
                              <p>
                                <Text
                                  field={props?.fields?.disclaimerForPDHI}
                                />
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyWellnessMyHealthPerks;
