import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIDCardMedicaidTX = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/TX/Medicaid/TX-star-plus.png)`,
          }}
          className="background-idcard"
          id="containerTX"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="table-font-size tx-member-idcard"
          >
            <tbody>
              <tr>
                <td>
                  <div className="clearfix">
                    <div className="float-start widthone">
                      <ul className="list-unstyled TXmrgn">
                        <li>
                          <b>
                            <Text field={fields?.lblMember} />
                          </b>
                          &nbsp; {idCard?.Member_Name}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblID} />
                          </b>
                          &nbsp; {idCard?.OriginalMemberProfile?.Number}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCDOB} />
                          </b>
                          {idCard?.Member_DOB}
                        </li>
                        <li>
                          <Text field={fields?.lblSCPCPName} /> <br></br>
                          {idCard?.PCPName_Current}
                        </li>
                        <li>
                          <Text field={fields?.lblSCPCPPhone} /> <br></br>
                          {idCard?.PCPPhone_Current}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblPCPEffectiveDate} />
                          </b>
                          &nbsp; {idCard?.PCPEffectiveDate}
                        </li>
                      </ul>
                      <div className="TXblock">
                        <ul className="list-unstyled TXmrgn">
                          <li>
                            <Text field={fields?.lblMMIS} />
                            &nbsp; {idCard?.OriginalMemberProfile?.Number}
                          </li>
                          <li>
                            <Text field={fields?.lblIssueDate} />
                            &nbsp; {formatToday()}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="float-end widthtwo">
                      <div className="TXmrgntoptwo">
                        <ul className="list-unstyled TXmrgn">
                          <li>
                            <b>
                              <Text field={fields?.lblBin} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxBIN}
                          </li>
                          <li>
                            <b>
                              <Text field={fields?.lblGroup} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxGRP}
                          </li>
                          <li>
                            <b>
                              <Text field={fields?.lblPCN} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxPCN}
                          </li>
                          <li>
                            <Text field={fields?.lblCVSCaremark} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/TX/Medicaid/TX-Molina-STAR-Back.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
              className="table-font-size-back"
            >
              <tr>
                <td className="starplusbackid">
                  <label>
                    <u>
                      <b>
                        <Text field={fields?.lblSCBackMembers} />
                      </b>
                    </u>
                    &nbsp; <Text field={fields?.lblSCBackMemberValue} />
                  </label>
                  <label>
                    <u>
                      <Text field={fields?.lblBackEmergency} />
                    </u>
                    &nbsp;
                    <Text field={fields?.lblBackEmergencyValues} />
                  </label>
                  <label>
                    <u>
                      <Text field={fields?.lblBackServiceCoordination} />
                    </u>
                    &nbsp;{" "}
                    <Text field={fields?.lblBackServiceCoordinationValue} />
                  </label>
                  <label>
                    <u>
                      <Text field={fields?.lblBackPriorAuth} />
                    </u>
                    &nbsp; <Text field={fields?.lblBackPriorAuthValue} />
                  </label>
                  <label>
                    <u>
                      <Text field={fields?.lblBackBehavioral} />
                    </u>
                    &nbsp; <Text field={fields?.lblBackBehavioralValues} />
                  </label>
                  <label>
                    <u>
                      <b>
                        <Text field={fields?.lblSCBackMembersSpanish} />
                      </b>
                    </u>
                    &nbsp; <Text field={fields?.lblSCBackMemberSpanishValue} />
                  </label>
                  <label>
                    <u>
                      <Text field={fields?.lblBackEmergencySpanish} />
                    </u>
                    &nbsp;
                    <Text field={fields?.lblBackEmergencySpanishValues} />
                  </label>
                  <label>
                    <u>
                      <Text field={fields?.lblBackServiceCoordinationSpanish} />
                    </u>
                    &nbsp;{" "}
                    <Text field={fields?.lblBackServiceCoordinationValue} />
                  </label>
                  <label>
                    <u>
                      <Text field={fields?.lblBackPriorAuthSpanish} />
                    </u>
                    &nbsp;
                    <Text field={fields?.lblBackPriorAuthSpanishValue} />
                  </label>
                  <label>
                    <u>
                      <Text field={fields?.lblBackBehavioralSpanish} />
                    </u>
                    &nbsp;{" "}
                    <Text field={fields?.lblBackBehavioralSpanishValues} />
                  </label>
                  <label>
                    <u>
                      <b>
                        <Text field={fields?.lblBackPRACTITIONERS} />
                      </b>
                    </u>
                    <RichText field={fields?.lblBackPRACTITIONERSValues} />
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardMedicaidTX;
