import React, { useContext } from "react";
import {
  IMAGE_PATH,
  LA_PLAN_ID,
  MEDICAID,
  CA,
  CA_MEDICAID_PLAN_ID_Non_MSP,
} from "../../../../../define.constants";
import { AppContext } from "../../../../../contexts/app.context";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";

const ViewIDCardMedicaidCA = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const { MemberStateName, memberLOB, planId } = useContext(AppContext);
  //CA Adv Networking changes - start
  const isCAMedicaidNonMSP =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === CA &&
    CA_MEDICAID_PLAN_ID_Non_MSP.includes(planId);
  //CA Adv Networking changes - end
  console.log("BineRX", result?.RxBin);
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/CA/Medicaid/${
              LA_PLAN_ID.includes(planId) ? "Medicaid-id-ca-LA" : "member-id-ca"
            }.png)`,
          }}
          className="background-idcard"
          id="containerCA"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td>
                  <div className="clearfix mt-1">
                    <div className="float-start CAmedileft CAipad">
                      <ul className="list-unstyled CAlistMrgn">
                        <li>
                          <b>
                            <Text field={fields?.lblMember} />
                          </b>
                          <span className="font-id-value">
                            {idCard?.Member_Name}
                          </span>
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblID} />
                          </b>
                          <span className="font-id-value">
                            {idCard?.OriginalMemberProfile?.Number}
                          </span>
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblDOB} />
                          </b>
                          <span className="font-id-value">
                            {idCard?.Member_DOB}
                          </span>
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblProviderCA} />
                          </b>
                          <span className="font-id-value">
                            {idCard?.PCPName_Current}
                          </span>
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblPCPPhoneCA} />
                          </b>
                          <span className="font-id-value">
                            {idCard?.PCPPhone_Current}
                          </span>
                        </li>
                        <li
                          className={
                            isCAMedicaidNonMSP && idCard?.SecondaryHospital
                              ? ""
                              : "mb-4"
                          }
                        >
                          <b>
                            <Text field={fields?.lblProviderGroupCA} />
                          </b>
                          <span className="font-id-value">
                            {idCard?.ProviderGroupName}
                          </span>
                        </li>
                        {/*Only user have CA Medicaid Non MSP Plans PCP data*/}
                        {isCAMedicaidNonMSP && idCard?.SecondaryHospital && (
                          <>
                            <li className="mb-4">
                              <b>
                                <Text field={fields?.lblHospital} />
                              </b>
                              <span className="font-id-value">
                                {idCard?.SecondaryHospital}
                              </span>
                            </li>
                          </>
                        )}
                        <li>
                          <RichText field={fields?.lblMemberServicesCA} />
                        </li>
                        <li>
                          <RichText field={fields?.lblEligibilityCA} />
                        </li>
                        <li>
                          <RichText field={fields?.lblNurseHelpLineCA} />
                        </li>
                        <li>
                          <RichText field={fields?.lblNurseHelpLineSpanishCA} />
                        </li>
                        <li>
                          <RichText field={fields?.lblRXQuestionsCA} />
                        </li>
                        <li>
                          <RichText field={fields?.lblHospitalAdmissionCA} />
                        </li>
                      </ul>
                    </div>
                    <div className="float-end CAmediright">
                      <div
                        className={`${
                          LA_PLAN_ID.includes(planId)
                            ? "CAmrgnLARtop"
                            : "CAmrgnRtop"
                        }`}
                      >
                        <ul className="list-unstyled CAlistR1">
                          <li>
                            <b>
                              <Text field={fields?.lblBin} />
                            </b>
                            <span className="font-id-value">
                              {result?.IdCardValues?.RxBIN}
                            </span>
                          </li>
                          <li>
                            <b>
                              <Text field={fields?.lblPCN} />
                            </b>
                            <span className="font-id-value">
                              {result?.IdCardValues?.RxPCN}
                            </span>
                          </li>
                          <li>
                            <b>
                              <Text field={fields?.lblGroup} />
                            </b>
                            <span className="font-id-value">
                              {result?.IdCardValues?.RxGRP}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP${
              LA_PLAN_ID.includes(planId)
                ? "/CA/Medicaid/Medicaid-id-ca-LA-back.png)"
                : "/TX/Medicaid/TX-Molina-STAR-Back.png)"
            }`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text font-LA">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
            >
              <tr>
                <td>
                  <div className="row-space member-id-card-semibold">
                    <Text field={fields?.lblIDBackHeading} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row-space">
                    <b>
                      <Text field={fields?.lblProviderCA} />
                    </b>
                    <span className="font-id-value">
                      <Text field={fields?.lblProviderValueCA} />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row-space">
                    <b>
                      <Text field={fields?.lblBackMemberCA} />
                    </b>
                    <span className="font-id-value">
                      <Text field={fields?.lblBackMemberValueCA} />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row-space">
                    <b>
                      <Text field={fields?.lblMemberSpanishBackCA} />
                    </b>
                    <span className="font-id-value">
                      <Text field={fields?.lblMemberSpanishBackValueCA} />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="member-id-card-semibold">
                    <Text field={fields?.lblRentalClaimsCA} />
                    <div className="inline-flex">
                      <Text field={fields?.lblRentalClaimsValueCA} />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardMedicaidCA;
