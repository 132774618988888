import React from "react";
import PropTypes from "prop-types";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH } from "../../define.constants";

const Box = ({
  cardClassDefault = "card",
  title,
  children,
  subTitle,
  count,
  cardSize,
  onClickHandler = {},
}) => {
  const cardClass = `${cardClassDefault}  ${
    cardSize === "small" ? "molina-card" : "lg-card"
  }`;
  return (
    <div className={cardClass}>
      <div className="card-body">
        <h5 className={`card-title${count ? " messages-title" : ""}`}>
          <Text field={title} />
          {count && <div className="messages-count">{count}</div>}
          {subTitle && (
            <a
              className={"track-more-details text-zoom-sm"}
              href={subTitle?.value.href}
              onClick={(e) => onClickHandler(e)}
            >
              {subTitle?.value.text}
              <img
                className="link-icon2"
                src={`${ICON_PATH}/arrow-right-small.svg`}
                alt={subTitle?.value.text}
              />
            </a>
          )}
        </h5>
        {children}
      </div>
    </div>
  );
};

Box.propTypes = {
  title: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  colClass: PropTypes.string,
  subTitle: PropTypes.string,
};

export default Box;
