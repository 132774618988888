import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

function DependentView(props) {
  return (
    <div className="row mb-5 mt-5">
      <div className="col-md-12 col-sm-12 pt-2">
        <h6 className="card-bill-msg">
          <Text field={props.fields.dependantText} />
        </h6>
      </div>
    </div>
  );
}

export default DependentView;
