import React, { useEffect, useState } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

const Search = ({ fields, inboxMessageList, onMessageSelect, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResultItem, setSelectedResultItem] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value, false);
  };

  useEffect(() => {
    setSearchResults([]);
    if (searchTerm.length > 2) {
      const results = inboxMessageList.filter(
        (message) =>
          message?.Subject?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          message?.Body?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    }
  }, [searchTerm, inboxMessageList]);

  const handleSearchItemClick = (message) => {
    setSearchTerm("");
    setSelectedResultItem(message.Subject);
    onMessageSelect(message.MessageID, message.InteractionId);
  };

  return (
    <div className="col-xl-10 col-md-9 message-filters">
      <div className="autocomplete-control">
        <input
          type="text"
          id="search-my-messages"
          className="search"
          value={searchTerm}
          onChange={handleChange}
          placeholder={selectedResultItem}
          aria-label={getFieldValue(fields, "lblSearch")}
        />
        <ul className="autocomplete-list">
          {searchResults.map((message, index) => (
            <li key={index} onClick={() => handleSearchItemClick(message)}>
              {message.Subject}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Search;
