import React from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH } from "../../define.constants";

const PrintMessage = ({ fields, forDesktop = true, onPrint }) => {
  let printIcon = (
    <img
      src={`${ICON_PATH}/print-24px.svg`}
      alt={getFieldValue(fields, "lblPrint")}
    />
  );
  return (
    <>
      {forDesktop ? (
        <div
          className="print"
          onClick={onPrint}
          title={getFieldValue(fields, "lblPrintmsg")}
        >
          {printIcon}
          <span>
            <Text field={fields.lblPrint} />
          </span>
        </div>
      ) : (
        <span className="print" onClick={onPrint}>
          {printIcon}
        </span>
      )}
    </>
  );
};

export default PrintMessage;
