import React, { useEffect, useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage, replace } from "formik";
import { highlightBorderOnError } from "../../utils/helpers";
import * as MessageAPI from "../../services/MessagesAPI";
import { InlineFormError } from "../../common/InlineFormError";
import * as Yup from "yup";
import FileUploadErrorDialog from "../CreateNewMessage/FileUploadErrorDialog";
import CancelConfirmationDialog from "../CreateNewMessage/CancelConfirmationDialog";
import { AppContext } from "./../../contexts/app.context";
import { ModalContext } from "./../../contexts/modal.context";
import {
  Text,
  RichText,
  Link,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {
  ICON_PATH,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  ARCHIVE,
  HEALTH_PLAN_RENEWALS,
  DATE_MM_DD_YYYY,
  VACCINATION_CAMPAIGN,
  KY,
  NY,
  MEDICAID,
} from "../../define.constants";

const ChildVaccinationReplyMessage = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const [pageRefresh, setPageRefresh] = useState(1);
  const { MemberStateName, memberLOB, isNYAffinityMember } =
    useContext(AppContext);
  const isNYSWHFIDEMedicaid =
    MemberStateName === NY &&
    memberLOB?.toLowerCase() === MEDICAID &&
    programId === NY_MD_SWHFIDE_PROGRAMID;
  const isNYSWHMedicare =
    MemberStateName === NY &&
    memberLOB?.toLowerCase() === MEDICARE &&
    programId === NY_MC_SWH_PROGRAMID;
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID?.toLowerCase();

  const [selectedDetails, setSelectedDetails] = useState([]);
  const [fileUploadFlag, setFileUploadFlag] = useState();
  let { isTermedMember } = useContext(AppContext);
  const { isSupportUser } = useContext(AppContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const [successMess, setSuccessMess] = useState(false);
  const { fields, data, next } = props;
  const divSpan = 2;
  const [replyMessTxt, setReplyMessTxt] = useState(false);
  const onSendButtonClick = () => {
    //window.location.reload();
    setPageRefresh(pageRefresh + 1); //temp comment
  };
  let strval = [];
  let listsOfBase64Strings = [];

  const handleDialog = (message, isLoading) => {
    setCancelConfirmationDialog({
      message,
      isLoading,
    });
  };
  const handleOnCancel = () => {
    handleDialog(<Text field={fields.Cancelpopup} />, true);
  };
  const confirmToCancel = (choose) => {
    if (choose) {
      //Reset the file uploading pop up
      document.getElementById("create-message-form").reset();
      setSelectedDetails([]);
      handleDialog("", false);
      setReplyMessTxt(false);
    } else {
      handleDialog("", false);
    }
  };

  const [dialog, setCancelConfirmationDialog] = useState({
    message: "",
    isLoading: false,
  });
  const [fileUploadErrorDialog, setFileUploadErrorDialog] = useState({
    message: "",
    isLoading: false,
  });
  const changeHandler = async (event) => {
    let intval = 0;
    if (selectedDetails.length > 0) {
      intval = 3 - selectedDetails.length;
    } else if (event.target.files.length >= 3) {
      intval = 3;
    } else {
      intval = event.target.files.length;
    }

    for (let i = 0; i < intval; i++) {
      const fileTypeError = hasFileTypeError(
        event.target.files[i],
        supportedFileTypes
      );
      const fileSizeError = hasFileSizeError(event.target.files[i], sizeLimit);
      /*const fileUploadFlag =!(
        hasFileTypeError(event.target.files[i], supportedFileTypes) ||
        hasFileSizeError(event.target.files[i], sizeLimit))*/

      const fileUploadFlag = hasFileTypeError(
        event.target.files[i],
        supportedFileTypes
      );

      // setFileUploadFlag(fileTypeError);

      if (fileTypeError) {
        handleFileUploadErrorDialog(
          <Text field={fields.VaccinationFileUploadErrorpopup} />,
          true
        );
      } else if (fileSizeError) {
        handleFileUploadErrorDialog(
          <Text field={fields.FileErrorSizePopup} />,
          true
        );
      } else {
        setSelectedFile(event.target.files[i]);

        if (selectedDetails.length > 0 && i == 0) {
          strval.push(selectedDetails[0]);
          if (selectedDetails.length > 1) {
            strval.push(selectedDetails[1]);
          }
        }
        if (event.target.files[i]) {
          strval.push(event.target.files[i]);
        }
        if (strval.length > 0) {
          setSelectedDetails(strval);
        }
      }
    }
  };

  const confirmToClose = (choose) => {
    handleFileUploadErrorDialog("", false);
  };
  const handleFileUploadErrorDialog = (message, isLoading) => {
    setFileUploadErrorDialog({
      message,
      isLoading,
    });
  };
  const supportedFileTypes: string[] = ["image/png", "image/jpg", "image/jpeg"];

  const sizeLimit: numer = 5 * 1048576;

  const hasFileTypeError = (file: File, supportedFileTypes): boolean =>
    file && !supportedFileTypes.includes(file.type);

  const hasFileSizeError = (file: File, sizeLimit): boolean =>
    file && file.size > sizeLimit;

  const deleteAttachments = (val) => {
    setSelectedDetails(selectedDetails.filter((str) => str.name != val));
  };
  const qs = require("qs");
  const downloadAtt = (val, name) => {
    let query1 = {
      AttachmentID: val,
    };

    MessageAPI.getAttachment(
      query1,
      qs.stringify(query1, { addQueryPrefix: true })
    )
      .then((response) => {
        if (response.ResultData != undefined) {
          var base64body = response.ResultData.DownloadAttachments.body;

          const fileTypes = {
            pdf: "application/pdf",
            png: "application/png",
            jpg: "image/jpg",
            jpeg: "image/jpeg",
            doc: "application/doc",
            docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            xls: "application/excel",
            xla: "application/x-msexcel",
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          };
          //checkingfilestypes
          var fileExtension = name.split(".").at(-1);
          var fileType = fileTypes[fileExtension.toLowerCase()];

          var header = `data:${fileType};base64,`;
          var a = document.createElement("a");
          a.href = header + base64body;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(function () {});
  };

  const resetFormUpload = () => {
    setSelectedDetails([]);
  };

  const getBase64String1 = (selectedFile) => {
    var fileName = selectedFile.name;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
        //resolve(reader.result);
      };
      reader.onerror = (error) => {};
    });
  };

  const onLoad = (fileString) => {
    listsOfBase64Strings.push(fileString);
  };

  const validateVaccinationSchema = Yup.object().shape({
    ChildMolinaMemberID: Yup.string().trim().required("*"),
    ChildFirstName: Yup.string().trim().required("*"),
    ChildLastName: Yup.string().trim().required("*"),
    ChildDateOfBirth: Yup.string().trim().required("*"),
    DoctorName: Yup.string().trim().required("*"),
  });
  const today = new Date().toISOString().split("T")[0];
  let uploadIcon = (
    <img
      src={`${ICON_PATH}/upload.svg`}
      width="18px"
      height="18px"
      alt={getFieldValue(fields, "lblPrint")}
    />
  );

  return (
    <>
      <Formik
        initialValues={{
          description: "",
          ChildMolinaMemberID: "",
          ChildFirstName: "",
          ChildLastName: "",
          ChildDateOfBirth: "",
          DoctorName: "",
          ConversationID: "",
          Attachments: "",
        }}
        validationSchema={validateVaccinationSchema}
        onSubmit={async (values) => {
          // setSpinner(true);//temp comment

          for (let i = 0; i < selectedDetails.length; i++) {
            const base64String = await getBase64String1(selectedDetails[i]);
            listsOfBase64Strings.push(base64String);
          }
          var res1 = null;
          {
            res1 = await MessageAPI.SubmitMemberRewards({
              RewardFirstName: values?.lblChildFirstName,
              RewardLastName: values?.lblChildLastName,
              RewardHealthPlanID: values?.lblChildMolinaMemberID,
              DOB: values?.lblChildDateOfBirth,
              FirstName: values?.lblDoctorName,
              MailingAddress2: values?.lblDoctorAddress,
              PhoneNumber: values?.lblDoctorPhoneNumber,
              Campaign: VACCINATION_CAMPAIGN,
            });
          }
          const updateAddressModal = (success) => (
            <>
              <div className="account-modal">
                <div className="update-header">
                  <p className="popup-teal-text">
                    {success ? (
                      <Text field={fields.lblSuccessMessage} />
                    ) : (
                      <Text field={fields.lblSuccessMessage} />
                    )}
                  </p>
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-done"
                    data-mdb-dismiss="modal"
                    onClick={closeModal}
                  >
                    <Text field={fields.lbldone} />
                  </button>
                </div>
              </div>
            </>
          );

          const showModal = (success) => {
            openModal({
              content: updateAddressModal(success),
              version: 1,
            });
          };

          let objResultData = JSON.parse(res1.ResultData);
          var responseConversationId = objResultData[0]?.SFId;
          for (let i = 0; i < listsOfBase64Strings.length; i++) {
            var res2 = await MessageAPI.setAttachment({
              ConversationId: responseConversationId,
              Body: listsOfBase64Strings[i],
              Name: selectedDetails[i].name,
            });
            var resfinal = res2;
          }
          var flag1 = res1?.Message;
          var flag2 = res2?.Message;

          if (listsOfBase64Strings.length == 0 && res1?.Message === "Success") {
            showModal(true);
            setSuccessMess(true);
            setReplyMessTxt(false);
            setPageRefresh(pageRefresh + 1);
            window.location.reload();
          } else if (
            listsOfBase64Strings.length > 0 &&
            res1?.Message === "Success" &&
            res2?.Message === "Success"
          ) {
            showModal(true);
            setSuccessMess(true);
            setReplyMessTxt(false);
            setPageRefresh(pageRefresh + 1);
            window.location.reload();
          } else {
            setSuccessMess(false);
            setSpinner(true);
            setReplyMessTxt(false);
          }
        }}
      >
        {({ touched, errors, values, setFieldValue }) => (
          <Form id="create-message-form">
            <div className="row-details row-title">
              <div>
                <p>
                  <img
                    src={`${ICON_PATH}/${
                      MemberStateName === KY
                        ? "logo-passport.svg"
                        : MemberStateName === NY && isNYAffinityMember === true
                        ? "Affinity_Molina_Healthcare_logo.png"
                        : isNYSWHMedicare || isNYSWHFIDEMedicaid
                        ? "Molina_Healthcare_SWH_logo.png"
                        : "Molina_Healthcare_Logo_320.svg"
                    }`}
                  />
                </p>
              </div>
              <div className="msg-details-head">
                <Text field={fields?.lblMemberInformation} />
              </div>
              <div className="row-details row-title">
                <p>
                  <Text field={fields.lblChildMolinaMemberID} />
                  <ErrorMessage
                    name="ChildMolinaMemberID"
                    component={InlineFormError}
                  />
                </p>
                <p>
                  <Field
                    name="ChildMolinaMemberID"
                    id="ChildMolinaMemberID"
                    maxLength={22}
                    rows={1}
                    className={`form-control ${highlightBorderOnError(
                      errors.ChildMolinaMemberID,
                      touched.ChildMolinaMemberID
                    )}`}
                  />
                </p>
              </div>
              <div className="row-details row-title">
                <Text field={fields?.lblChildName} />

                <div className="row">
                  <div className="col-sm-12 col-md-6 row-details row-title">
                    <Field
                      name="ChildFirstName"
                      id="ChildFirstName"
                      placeholder={getFieldValue(fields, "lblChildFirstName")}
                      className={`form-control ${highlightBorderOnError(
                        errors.ChildFirstName,
                        touched.ChildFirstName
                      )}`}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 row-title">
                    <Field
                      name="ChildLastName"
                      id="ChildLastName"
                      placeholder={getFieldValue(fields, "lblChildLastName")}
                      className={`form-control ${highlightBorderOnError(
                        errors.ChildLastName,
                        touched.ChildLastName
                      )}`}
                    />
                  </div>
                </div>
              </div>
              <div className="row-details row-title">
                <p>
                  <Text field={fields?.lblChildDateOfBirth} />
                </p>
                <p>
                  <Field
                    name="ChildDateOfBirth"
                    id="ChildDateOfBirth"
                    type="date"
                    max={today}
                    maxLength={22}
                    rows={1}
                    placeholder={DATE_MM_DD_YYYY}
                    className={`form-control ${highlightBorderOnError(
                      errors.ChildDateOfBirth,
                      touched.ChildDateOfBirth
                    )}`}
                  />
                </p>
              </div>
              <div className="msg-details-head">
                <Text field={fields?.lblVaccinationInfo} />
              </div>
              <div className="row-details row-title">
                <p>
                  <Text field={fields?.lblDoctorName} />
                </p>
                <p>
                  <Field
                    name="DoctorName"
                    id="DoctorName"
                    maxLength={22}
                    rows={1}
                    className={`form-control ${highlightBorderOnError(
                      errors.DoctorName,
                      touched.DoctorName
                    )}`}
                  />
                </p>
              </div>
              <div className="row-details row-title">
                <p>
                  <Text field={fields?.lblDoctorAddress} />
                </p>
                <p>
                  <Field
                    name="lblDoctorAddress"
                    id="lblDoctorAddress"
                    maxLength={22}
                    rows={1}
                    className={`form-control`}
                  />
                </p>
              </div>
              <div className="row-details row-title">
                <p>
                  <Text field={fields?.lblDoctorPhoneNumber} />
                </p>
                <p>
                  <Field
                    name="lblDoctorPhoneNumber"
                    id="lblDoctorPhoneNumber"
                    maxLength={22}
                    rows={1}
                    className={`form-control`}
                  />
                </p>
              </div>
              <div className="msg-tbl row">
                <div className="row-title">
                  <p>
                    <Text field={fields?.lblUploadMessage} />
                  </p>
                  <Text field={fields?.lblAttachments} />
                </div>

                <div>
                  <label
                    className="custom-file-upload"
                    title={getFieldValue(fields, "lblchoosefilestt")}
                  >
                    <input
                      type="file"
                      name="file"
                      multiple
                      disabled={selectedDetails.length == 3 ? true : false}
                      className="btn btn-upload"
                      onChange={changeHandler}
                    />
                    <i className="fa fa-cloud-upload" />
                    {uploadIcon} <Text field={fields?.lblChoosefiles} />
                  </label>
                </div>

                <div className="label-attachments">
                  <Text field={fields?.InfoAttachment} />
                </div>
                <div className="row filedetails">
                  {selectedDetails?.map((file, index) => {
                    return (
                      <React.Fragment key={file.name}>
                        <div className="label-file-upload">
                          <table
                            style={{
                              border: "1px solid lightblue",
                              margintop: "3px",
                            }}
                          >
                            <tr>
                              <td> {file.name}</td>
                              <td style={{ marginleft: "178px" }}>
                                <img
                                  src={`${ICON_PATH}/cross1.jpg`}
                                  width="10px"
                                  height="13px"
                                  margin-left="178px"
                                  margin-top="4px"
                                  margin-bottom="3px"
                                  color="red"
                                  className="img-close"
                                  alt={getFieldValue(fields, "lblPrint")}
                                  onClick={() => deleteAttachments(file.name)}
                                />
                              </td>
                            </tr>
                          </table>{" "}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div>
                  {Object.keys(errors).length > 0 ? (
                    <div className="text-center">
                      <p className="text-danger">
                        <Text field={fields.lblRequiredFieldsMessage} />
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="msg-tbl row">
                <div>
                  <button
                    className="btn btn-submit"
                    type="submit"
                    onClick={onSendButtonClick}
                    title={getFieldValue(fields, "lblSendtt")}
                  >
                    <Text field={fields.btnSubmit} />
                  </button>
                  <button
                    type="button"
                    title={getFieldValue(fields, "lblCanceltt")}
                    onClick={handleOnCancel}
                    className="btn btn-primary btn-margin btn-cancel"
                  >
                    <Text field={fields?.btnCancel} />
                  </button>
                  {dialog.isLoading && (
                    <CancelConfirmationDialog
                      onDialog={confirmToCancel}
                      message={dialog.message}
                      fields={fields}
                    ></CancelConfirmationDialog>
                  )}
                  {fileUploadErrorDialog.isLoading && (
                    <FileUploadErrorDialog
                      message={fileUploadErrorDialog.message}
                      onDialog={confirmToClose}
                    ></FileUploadErrorDialog>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChildVaccinationReplyMessage;
