import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardStateUTMedicaid = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          id="utMemberidCard"
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/IL/Medicaid/member-id-card-IL.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="table-font-size"
          >
            <tbody id="fontUT">
              <tr>
                <td height="40"></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp;
                  {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td height="10"></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIdentification} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td height="10"></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPlan} />
                  </b>
                  &nbsp; {idCard?.PlanName}
                </td>
              </tr>
              <tr>
                <td height="25"></td>
              </tr>
              <tr>
                <td>
                  <div className="utMedicardRight">
                    <ul className="list-unstyled">
                      <li>
                        <Text field={fields.lblRXBIN} />
                        &nbsp; {result?.IdCardValues?.RxBIN}
                      </li>
                      <li>
                        <Text field={fields.lblRxPCN} />
                        &nbsp; {result?.IdCardValues?.RxPCN}
                      </li>
                      <li>
                        <Text field={fields.lblRXGRP} />
                        &nbsp; {result?.IdCardValues?.RxGrp}
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblCopaysMedicalHeading} />
                  </b>
                </td>
              </tr>
              <tr>
                <td height="15"></td>
              </tr>
              <tr>
                <td>
                  {idCard?.UTDrVisitvalue && (
                    <>
                      <label>
                        <Text field={fields.lblDrVisit} />
                      </label>
                      <label className="m-l-xs">{idCard?.UTDrVisitvalue}</label>
                      &nbsp;
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td height="5"></td>
              </tr>
              <tr>
                <td>
                  {idCard?.UTRXvalue && (
                    <>
                      <label>
                        <Text field={fields.lblRx} />
                      </label>
                      <label className="m-l-xs">{idCard?.UTRXvalue}</label>
                      &nbsp;
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td height="5"></td>
              </tr>
              <tr>
                <td>
                  {idCard?.UTUrgentCare && (
                    <>
                      <label>
                        <Text field={fields.lblUrgentCare} />
                      </label>
                      <label className="m-l-xs">{idCard?.UTUrgentCare}</label>
                      &nbsp;
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/NV/Medicaid/NV_Back_View.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
              className="table-font-size-back"
            >
              <tbody>
                <tr>
                  <td>
                    <div className="IDcardBackText">
                      <ul className="list-unstyled">
                        <li>
                          <b>
                            <Text field={fields.lblEmergencyServices} />
                          </b>
                        </li>
                        <li>
                          <Text field={fields.lblEmergencyServicesText} />
                        </li>
                        <li>
                          <b>
                            <Text field={fields.lblContactHeading} />
                          </b>
                        </li>
                        <li>
                          <Text field={fields.lblContactContent1} />
                        </li>
                        <li>
                          <Text field={fields.lblContactContent2} />
                        </li>
                        <li>
                          <Text field={fields.lblContactContent3} />
                        </li>
                        <li>
                          <b>
                            <Text field={fields.lblPharmacists} />
                          </b>
                          <Text field={fields.lblPharmacistsContact} />
                        </li>
                        <li>
                          <b>
                            <Text field={fields.lblProviderHospital} />
                          </b>
                        </li>
                        <li>
                          <Text field={fields.lblProviderHospitalContent} />
                        </li>
                        <li></li>
                        <li>
                          <Text field={fields.lblIDCardDisclaimer} />
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardStateUTMedicaid;
