import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { SpinnerContext } from "../../contexts/spinner.context";

function Spinner() {
  const { isLoading } = useContext(SpinnerContext);
  if (isLoading) {
    return ReactDOM.createPortal(
      <div className="spinner text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>,
      document.querySelector("#spinner-root")
    );
  } else {
    return null;
  }
}

export default Spinner;
