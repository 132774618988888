import React, { useContext, useState, useEffect } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "./../../contexts/app.context";
import {
  PRIMARY_SUBSCRIBER_ROLEID,
  DEPENDANT_ACCESS_GRANTED_ROLEID,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import GrantAccess from "./GrantAccess-Dependant";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ACCOUNT_SETTINGS_ACCESS_FOR_OTHERS } from "../../define.widgets";

const AccountSettingsAccessManagement = (props) => {
  const accessManagementDetails = props?.fields ?? "";
  let { Dependents } = useContext(AppContext);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const [dependentList, setDependentList] = useState([]);
  const [primarySubscriber, setPrimarySubscriber] = useState([]);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  useEffect(() => {
    setPrimarySubscriber([]);
    setDependentList([]);
    Dependents?.map((item) => {
      if (item.RoleId === PRIMARY_SUBSCRIBER_ROLEID) {
        setPrimarySubscriber((primarySubscriber) => [
          ...primarySubscriber,
          item,
        ]);
      } else {
        setDependentList((dependentList) => [...dependentList, item]);
      }
    });
  }, [Dependents]);

  return (
    <>
      <div className="row no-tab-title">
        <div className="col-md-12">
          <ul className="list-group">
            <li className="list-group-item">
              {innerSpinnerHTML?.[ACCOUNT_SETTINGS_ACCESS_FOR_OTHERS]}
              <div className="row">
                <div className="col-xxl-8 col-md-8">
                  <h5 className="tab-sub-title">
                    <Text field={accessManagementDetails.memberHeading} />
                  </h5>
                </div>
                <GrantAccess {...props} dependentList={dependentList} />
              </div>
              <div className="row  common-input">
                {primarySubscriber?.map((item, index) => (
                  <>
                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 ">
                      <div className="gap-active ">
                        <div className="row-title">
                          {item.FirstName}
                          <span className="name-space">{item.LastName}</span>
                        </div>
                        <div className="row-details">
                          {getFieldValue(
                            accessManagementDetails,
                            "idText",
                            false
                          )}
                          <span>{item.MemberNumber}</span>
                          {getFieldValue(
                            accessManagementDetails,
                            "userSubscriber",
                            false
                          )}
                          <div />
                        </div>
                      </div>
                      <hr className="d-none d-sm-block d-xl-none" />
                    </div>
                  </>
                ))}
                {dependentList?.map((item, index) => (
                  <>
                    <div
                      className={`col-xl-4 col-lg-12 col-md-12 col-sm-12 ${
                        index !== dependentList?.length - 1
                          ? ""
                          : "button-space"
                      }`.trim()}
                      key={index}
                    >
                      <div className="gap-active ">
                        <div className="row-title">
                          {item.FirstName}
                          <span className="name-space">{item.LastName}</span>
                        </div>
                        <div className="row-details">
                          {getFieldValue(
                            accessManagementDetails,
                            "idText",
                            false
                          )}
                          <span>{item.MemberNumber}</span>
                          {getFieldValue(
                            accessManagementDetails,
                            "userDependent",
                            false
                          )}
                        </div>
                        <div className="access-granted">
                          {item.RoleId === DEPENDANT_ACCESS_GRANTED_ROLEID &&
                            item.RoleId !== PRIMARY_SUBSCRIBER_ROLEID && (
                              <span>
                                <Text
                                  field={
                                    accessManagementDetails.accessGrantedText
                                  }
                                />
                              </span>
                            )}
                        </div>
                      </div>
                      {index !== dependentList?.length - 1 ? (
                        <hr className="d-none d-sm-block d-xl-none" />
                      ) : null}
                    </div>
                  </>
                ))}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AccountSettingsAccessManagement;
/*testing*/
