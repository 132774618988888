import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as DataSharingAppsAPI from "../services/DataSharingAppsAPI";
import { AppContext } from "../contexts/app.context";
import { ACCSETTINGS_MANAGE_DATA_SHARING } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";

const useThirdPartyAppsAPI = () => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const { memberRKSID } = useContext(AppContext);
  const [thirdPartyApps, setThirdPartyApps] = useState([]);
  const [refreshSession, setRefreshSession] = useState(false);
  const [isthirdPartyAppsLoaded, setIsthirdPartyAppsLoaded] = useState(false);
  const setData = (response) => {
    setThirdPartyApps(response?.ResultData);
    setIsthirdPartyAppsLoaded(true);
  };
  useEffect(() => {
    if (refreshSession) {
      callApi();
    } else if (!isthirdPartyAppsLoaded) {
      callApi();
    }
  }, [refreshSession]);
  const callApi = () => {
    setInnerSpinner(true, ACCSETTINGS_MANAGE_DATA_SHARING);
    DataSharingAppsAPI.ThirdPartyAppsList({})
      .then((response) => {
        setData(response);
        if (refreshSession === true) {
          setRefreshSession(false);
        }
      })
      .catch(function (err) {
        setError({ err });
        setRefreshSession(false);
      })
      .finally(() => setInnerSpinner(false, ACCSETTINGS_MANAGE_DATA_SHARING));
  };
  const refreshMessageInfoAll = () => {
    setRefreshSession(true);
  };
  return {
    thirdPartyApps,
    refreshMessageInfoAll,
    isthirdPartyAppsLoaded,
  };
};
export { useThirdPartyAppsAPI };
