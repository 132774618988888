import React, { useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import {
  ICON_PATH,
  H1700_1_INDIVIDUAL_SERVICE_PLAN,
  H1700_2_INDIVIDUAL_SERVICE_PLAN_ADDENDUM,
  H1700_3_INDIVIDUAL_SERVICE_PLAN_SIGNATUREPAGE,
  SPL_CarePlan_Name,
  Export_PDF,
  ENGLISH_LANG_CODE,
  SERVICE_PLAN,
  SSRS_PDF,
  EHRA,
  LTSS,
  ASSESSMENT_ENGLISH_LANG_CODE,
  ASSESSMENT_SPANISH_LANG_CODE,
} from "../../define.constants";
import { useGetServicePlanAPI } from "../../hooks/useGetServicePlanAPI";
import * as HealthRecordAPI from "../../services/HeathRecordAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ErrorContext } from "../../contexts/error.context";
import { AppContext } from "../../contexts/app.context";

const MyHealthRecordServicePlan = (props) => {
  const { fields } = props;
  const { servicePlan, isLoaded } = useGetServicePlanAPI();
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const { prefLang } = useContext(AppContext);
  const ltsserviceplan1Flag = servicePlan?.H17001Plan
    ? servicePlan.H17001Plan
    : false;
  const ltsaddendumFlag = servicePlan?.H17002Plan
    ? servicePlan.H17002Plan
    : false;
  const ltssignatureFlag = servicePlan?.H17003Plan
    ? servicePlan.H17003Plan
    : false;

  const ltsserviceplanFlag = servicePlan?.TxServicePlan
    ? servicePlan.TxServicePlan
    : false;
  // const ltsserviceplanFlag = true;
  // const ltsaddendumFlag = true;
  // const ltssignatureFlag = true;

  const buttonaction = (assvalue) => {
    var clickvalue = "";
    servicePlan?.HistoryOfAssesmentList?.map(function (value) {
      if (assvalue == H1700_1_INDIVIDUAL_SERVICE_PLAN) {
        if (value.Name == assvalue) {
          planButtonClickLtss(value);
        }
      } else if (assvalue == H1700_3_INDIVIDUAL_SERVICE_PLAN_SIGNATUREPAGE) {
        if (value.Name == assvalue) {
          planButtonClickLtss(value);
        }
      } else if (assvalue == H1700_2_INDIVIDUAL_SERVICE_PLAN_ADDENDUM) {
        if (value.Name == assvalue) {
          planButtonClick(value);
        }
      } else if (assvalue == SERVICE_PLAN) {
        if (EHRA == value.Name || LTSS == value.Name)
          planButtonClickLtss(value);
      }
      // switch (assvalue) {
      //   case H1700_1_INDIVIDUAL_SERVICE_PLAN:
      //     if (value.Name == assvalue) {
      //       planButtonClickLtss(value);
      //       return;
      //     }
      //   case H1700_3_INDIVIDUAL_SERVICE_PLAN_SIGNATUREPAGE:
      //     if (value.Name == assvalue) {
      //       planButtonClickLtss(value);
      //       return;
      //     }
      //   case H1700_2_INDIVIDUAL_SERVICE_PLAN_ADDENDUM:
      //     if (value.Name == assvalue) {
      //       planButtonClick(value);
      //       return;
      //     }
      //   case SERVICE_PLAN:
      //     if (EHRA === value.Name) {
      //       planButtonClickLtss(value);
      //       return;
      //     } else if (LTSS === value.Name) {
      //       planButtonClickLtss(value);
      //       return;
      //     }

      //   default:
      //     return;
      // }
    });
  };

  const planButtonClick = (obj) => {
    setSpinner(true);
    HealthRecordAPI.getCarePlan({
      CareplanName: obj.Name,
      AssessmentId: obj.AssessmentSubmitId,
      //SessionId: "",
      DocumentType: Export_PDF,
      Language: prefLang,
    })
      .then((response) => {
        if (response?.ResultData?.Output) {
          let byteChars = window.atob(response.ResultData.Output);
          let byteNum = new Array(byteChars.length);
          for (let i = 0; i < byteChars.length; i++) {
            byteNum[i] = byteChars.charCodeAt(i);
          }
          let byteAr = new Uint8Array(byteNum);
          let file = new Blob([byteAr], {
            type: "application/pdf;base64",
          });
          let filePath = URL.createObjectURL(file);
          window.open(filePath);
        } else {
          setError({ err: { statusCode: response.ResultCode } });
        }
        setData(response);
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(function () {
        setSpinner(false);
      });
  };

  const planButtonClickLtss = (value) => {
    setSpinner(true);
    var doctype = "";
    var assessmnetLang = ASSESSMENT_ENGLISH_LANG_CODE;
    if (H1700_1_INDIVIDUAL_SERVICE_PLAN == value.Name) {
      doctype = Export_PDF;
      assessmnetLang = prefLang;
    } else if (H1700_3_INDIVIDUAL_SERVICE_PLAN_SIGNATUREPAGE == value.Name) {
      doctype = Export_PDF;
      assessmnetLang = ASSESSMENT_ENGLISH_LANG_CODE;
    } else if (EHRA == value.Name || LTSS == value.Name) {
      doctype = SSRS_PDF;
      assessmnetLang = ASSESSMENT_ENGLISH_LANG_CODE;
    }
    HealthRecordAPI.DownloadServicePlan({
      AssessmentId: value.AssessmentSubmitId,
      SessionId: "",
      DocumentType: doctype,
      AssessmentLanguage: assessmnetLang,
    })
      .then((response) => {
        if (response?.ResultData?.AssessmentBinaryData) {
          let byteChars = window.atob(response.ResultData.AssessmentBinaryData);
          let byteNum = new Array(byteChars.length);
          for (let i = 0; i < byteChars.length; i++) {
            byteNum[i] = byteChars.charCodeAt(i);
          }
          let byteAr = new Uint8Array(byteNum);
          let file = new Blob([byteAr], {
            type: "application/pdf;base64",
          });
          let filePath = URL.createObjectURL(file);
          window.open(filePath);
        } else {
          setError({ err: { statusCode: response.ResultCode } });
        }
        setData(response);
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(function () {
        setSpinner(false);
      });
  };

  return (
    <li className="list-group-item">
      <div className="carePlanBody">
        <div className="grid-container">
          <div className="column-container">
            <div className="column-header">
              <Text field={props.fields.servicePlanName} />
            </div>
            <div className="column-content" style={{ fontSize: "initial" }}>
              {ltsserviceplan1Flag && (
                <div>
                  <Text field={props.fields.individualserviceplan} />
                </div>
              )}
              {ltsaddendumFlag && (
                <div>
                  <Text field={props.fields.individualserviceplanaddendum} />
                </div>
              )}
              {ltssignatureFlag && (
                <div>
                  <Text field={props.fields.individualserviceplansignature} />
                </div>
              )}
              {ltsserviceplanFlag && (
                <div>
                  <Text field={props.fields.txserviceplan} />
                </div>
              )}
            </div>
          </div>
          <div className="column-container">
            <div className="column-header">
              <Text field={props.fields.servicePlanType} />
            </div>
            <div className="column-content" style={{ fontSize: "initial" }}>
              {ltsserviceplan1Flag && (
                <div>
                  <Text field={props.fields.waiverserviceplan} />
                </div>
              )}
              {ltsaddendumFlag && (
                <div>
                  <Text field={props.fields.waiverserviceplan} />
                </div>
              )}
              {ltssignatureFlag && (
                <div>
                  <Text field={props.fields.waiverserviceplan} />
                </div>
              )}
              {ltsserviceplanFlag && (
                <div>
                  <Text field={props.fields.txltss} />
                </div>
              )}
            </div>
          </div>
          <div className="column-container">
            <div className="column-header">
              <Text field={props.fields.txserviceplantab} />
            </div>
            <div
              className="column-carePlan-content"
              style={{ fontSize: "initial" }}
            >
              {ltsserviceplan1Flag && (
                <div>
                  <button
                    id="plan1"
                    className="carePlanbtn"
                    style={{ color: "white" }}
                    onClick={() =>
                      buttonaction(H1700_1_INDIVIDUAL_SERVICE_PLAN)
                    }
                    src={`${ICON_PATH}/Group_2641.svg`}
                    //download="ClaimsHistory.pdf"
                    // alt={item.name}
                    // onClick={() => downloadPDF(item.name)}
                  >
                    <Text field={props.fields.btnview} />
                  </button>
                </div>
              )}
              {ltsaddendumFlag && (
                <div>
                  <button
                    id="plan2"
                    className="carePlanbtn"
                    style={{ color: "white" }}
                    onClick={
                      () =>
                        buttonaction(H1700_2_INDIVIDUAL_SERVICE_PLAN_ADDENDUM)
                      //console.log("clicked 2")
                    }
                    src={`${ICON_PATH}/Group_2641.svg`}
                    // alt={item.name}
                    // onClick={() => downloadPDF(item.name)}
                  >
                    <Text field={props.fields.btnview} />
                  </button>
                </div>
              )}
              {ltssignatureFlag && (
                <div>
                  <button
                    id="plan3"
                    className="carePlanbtn"
                    style={{ color: "white" }}
                    onClick={
                      () =>
                        buttonaction(
                          H1700_3_INDIVIDUAL_SERVICE_PLAN_SIGNATUREPAGE
                        )
                      //console.log("clicked 2")
                    }
                    src={`${ICON_PATH}/Group_2641.svg`}
                    // alt={item.name}
                    // onClick={() => downloadPDF(item.name)}
                  >
                    <Text field={props.fields.btnview} />
                  </button>
                </div>
              )}
              {ltsserviceplanFlag && (
                <div>
                  <button
                    id="plan4"
                    className="carePlanbtn"
                    style={{ color: "white" }}
                    onClick={() => buttonaction(SERVICE_PLAN)}
                    src={`${ICON_PATH}/Group_2641.svg`}
                    // alt={item.name}
                    // onClick={() => downloadPDF(item.name)}
                  >
                    <Text field={props.fields.btnview} />
                  </button>
                </div>
              )}

              {/* {renderData.buttonArray} */}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
export default MyHealthRecordServicePlan;
