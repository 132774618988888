import React, { useState, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Form, Field, FieldArray, ErrorMessage, Formik } from "formik";
import { highlightBorderOnError } from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import { InlineFormError } from "../../common/InlineFormError";
import { AppContext } from "./../../contexts/app.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  ICON_PATH,
  PHONE_DIGIT_ONLY_REGEX,
  IUD_FLAG_D,
  SUPPORT_USER,
  TERMED_MEMBER,
} from "../../define.constants";
const SexualOrientation = ({
  fields,
  errors,
  values,
  touched,
  initialTouched,
  setFieldValue,
  setFieldTouched,
  setValues,
  pageEditable,
  onButtonClick,
  contactPreferencesList,
  handleBlur,
  handleChange,
  getSexualOrientationSelected,
  handleSexualOrientationValue,
  contactData,
}) => {
  let sexualOrientationList = [];
  let [sexualOrientationFlag, setSexualOrientationFlag] = useState(true);
  let { isTermedMember } = useContext(AppContext);
  let sexualOrientationId = 4; /*Added index value for comparing and displaying the texbox*/
  const handleSexualOrientationSelect = (e) => {
    if (e.value === "Something else, please describe") {
      setSexualOrientationFlag(false);
    } else if (e.id === sexualOrientationId) {
      setSexualOrientationFlag(false);
    } else {
      setSexualOrientationFlag(true);
    }
    getSexualOrientationSelected(e.value);
  };

  contactPreferencesList?.MH_SexualOrientation__pc?.map((data, index) => {
    sexualOrientationList.push({
      value: data,
      id: index,
      label: data,
    });
  });
  const initialValues = {
    sexualOrientationList: contactData?.IsContactPreferences?.SexualOrientation,
  };
  return (
    <>
      {initialValues.sexualOrientationList !== undefined && (
        <Formik initialValues={initialValues}>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h5 className="tab-sub-title column-margin">
                  <Text field={fields?.sexualOrientation ?? ""} />
                </h5>
                <div
                  className={
                    pageEditable
                      ? "disabled-div dropdown-list-width"
                      : "dropdown-list-width"
                  }
                >
                  <Field
                    as="select"
                    id="sexualOrientationList"
                    name="sexualOrientationList"
                    options={sexualOrientationList}
                    value={
                      sexualOrientationList
                        ? sexualOrientationList.find(
                            (sexualOrientationList) =>
                              sexualOrientationList.value ===
                              values.sexualOrientationList
                          )
                        : ""
                    }
                    onChange={(e) => handleSexualOrientationSelect(e)}
                    component={CustomSelect}
                    isFormik={true}
                  />
                  <textarea
                    maxLength={20}
                    placeholder={getFieldValue(
                      fields,
                      "lblSexualOrientationPH"
                    )}
                    className={
                      sexualOrientationFlag
                        ? "textarea-display"
                        : "form-control textarea-control"
                    }
                    onChange={(e) => handleSexualOrientationValue(e)}
                  ></textarea>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default SexualOrientation;
