import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";

const ContactDetails = (props) => (
  <div>
    <div className="page-heading">
      <Text field={props.fields?.title ?? ""} />
    </div>
  </div>
);

export default ContactDetails;
