import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const CommonConfig = (props) => {
  return (
    <div className="d-none">
      <p>Common-Config Component</p>
    </div>
  );
};

export default CommonConfig;
