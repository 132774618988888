import React, { useContext } from "react";
import {
  Text,
  Link,
  RichText,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { EFFECTIVE } from "../Home-Layout/Constants";
import { getFormattedDate } from "../../utils/helpers";
import { AppContext } from "./../../contexts/app.context";
import { SUBSCRIBER } from "./../../define.constants";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";

function StatusModal(props) {
  let date = null;
  const { userType } = useContext(AppContext);
  const { redirectToSSO } = useContext(SSORedirectContext);
  props.status?.toLocaleLowerCase().includes(EFFECTIVE) && (date = props.date);
  return (
    <>
      {props.heading?.value && (
        <div className="account-font">
          <Text field={props.heading} />
        </div>
      )}
      {props.content?.value && date !== null ? (
        <span className="datafontalign">
          <Text field={props.content} />{" "}
          <b>{getFormattedDate(date, "mm/dd/yyyy")}</b>
        </span>
      ) : (
        <span className="datafontalign">
          <RichText field={props.content} />
        </span>
      )}

      {userType === SUBSCRIBER && getFieldValue(props, "payMyBill") && (
        <div className="text-center ">
          <button
            type="button"
            className="btn btn-primary mt-4"
            aria-label={props.payMyBill}
            onClick={(e) =>
              redirectToSSO({
                e,
                url: constants.apigeeURLs.GetHPSSSORedirect,
                data: {
                  ReqPage: "Home",
                },
              })
            }
          >
            <Text
              className="btn btn-primary ripple-surface"
              field={props.payMyBill}
            />
          </button>
        </div>
      )}
    </>
  );
}

export default StatusModal;
