import React, { useContext } from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../../../contexts/app.context";
import { MI_MEDICAID_PLAN_HMP } from "../../../../../define.constants";

const ViewIdCardDualSc = ({ fields, result, details, idCard, drugInfo }) => {
  console.log(fields, "fields");
  const { planId } = useContext(AppContext);
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/SC/Dual/SC_Dual_Front_View.png)`,
          }}
          className="background-idcard background-id-row background-id-mi p-3"
        >
          <div className="row margn-top-100" id="viewIdCardDualMI">
            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 white-bg">
              <div className="p-2">
                <ul className="list-unstyled">
                  <li>&nbsp;</li>
                  <li className="pb-1">
                    <span className="fw-bold">
                      <Text field={fields.lblMember} />
                    </span>
                    &nbsp;{idCard?.Member_Name}
                  </li>
                  <li className="pb-1">
                    <span className="fw-bold">
                      <Text field={fields.lblID} />
                    </span>
                    &nbsp;{idCard?.Member_Number}
                  </li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  <li className="pb-1">
                    <span>
                      <b>
                        <Text field={fields.lblPCPNameDuals} />
                      </b>
                    </span>
                    &nbsp;{idCard?.PCPName_Current}
                  </li>
                  <li className="pb-1">
                    <span>
                      <b>
                        <Text field={fields.lblPCPPhoneDuals} />
                      </b>
                    </span>
                    &nbsp;{idCard?.PCPPhone_Current}
                  </li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  <li className="pb-1">
                    <span className="fw-bold">
                      <Text field={fields.lblMemCannotCharged} />
                    </span>
                  </li>
                  <li className="pb-2">
                    <span>
                      <Text field={fields.lblCopaysMedicalHeading} />
                    </span>
                    &nbsp;
                    <Text field={fields.lblDualsCopayZero} />
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    {result?.PortalDeductiblesMappingDetails[0]?.ContractPlanId}
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xs-4 col-sm-4 col-md-4 col-lg-4 white-bg"
              style={{ paddingLeft: "0" }}
            >
              <div className="p-2">
                <ul
                  className="list-unstyled mrgn-top margin-mi-bottom"
                  style={{ paddingLeft: "0" }}
                >
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  <li>
                    <span className="fw-bold pb-1">
                      <Text field={fields.lblBin} />
                    </span>
                    &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxBIN}
                  </li>
                  <li>
                    <span className="fw-bold pb-1">
                      <Text field={fields.lblPCN} />
                    </span>
                    &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxPCN}
                  </li>
                  <li>
                    <span className="fw-bold pb-1">
                      <Text field={fields.lblGroup} />
                    </span>
                    &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxGroup}
                  </li>
                  <li>
                    <span className="fw-bold pb-1">
                      <Text field={fields.lblRxid} />
                    </span>
                    &nbsp; {idCard?.Member_Number}
                  </li>
                </ul>
              </div>
            </div>
            <div className="text-center">
              <Text field={fields.lblNoteforHealthcare} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/MI/Dual/MI_DUALS_Back.png)`,
          }}
          className="member-id-card-back background-id-mi"
        >
          <div className="member-id-card-back-text backcard backcardNV id-card-dual-mi">
            <div className="backcardmember">
              <div className="b-2">
                <Text field={fields.lblBackViewCarryThisCard} />
              </div>
              <div className="mb-2 mt-2">
                <label>
                  <b>
                    <Text field={fields.lblIDBackServices} />
                  </b>
                </label>

                <Text field={fields.lblMemberServicesContact} />
              </div>
              <div>
                <label>
                  <b>
                    <Text field={fields.lblBackBehaioralHealth} />
                  </b>
                </label>
                <span className="inline-flex">
                  <Text field={fields.lblIDBackNALData} />
                </span>
              </div>
              <div>
                <label>
                  <b>
                    <Text field={fields.lblPharmacyHelpDesk} />
                  </b>
                </label>
                <span className="inline-flex">
                  <Text field={fields.lblPharmacyHelpDeskValue} />
                </span>
              </div>
              <div>
                <label>
                  <b>
                    <Text field={fields.lblIDBackNAL} />
                  </b>
                </label>
                <span className="inline-flex">
                  <Text field={fields.lblIDBackNALData} />
                </span>
              </div>
              <div className="mb-2">
                <label>
                  <b>
                    <Text field={fields.lblBackWebsite} />
                  </b>
                </label>

                <span className="inline-flex">
                  <Text field={fields.lblBackWebsiteValue} />
                </span>
              </div>
              <div className="mb-2">
                <label>
                  <b>
                    <Text field={fields.lblSubmitClaim} />
                  </b>
                </label>
                <span className="inline-flex">
                  <Text field={fields.lblSubmitClaimsDetailsBack} />
                </span>
              </div>
              <div className="mb-2">
                <label>
                  <b>
                    <Text field={fields.lblClaimInquiry} />
                  </b>
                </label>
                <span className="inline-flex">
                  <Text field={fields.lblClaimInquiryDetails} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardDualSc;
