import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { ModalProvider } from "../../contexts/modal.context";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import { AppContext } from "../../contexts/app.context";

const RxSSLayout = (props) => {
  return (
    <ModalProvider>
      <SSORedirectProvider>
        {
          <div className="page-heading">
            <Text field={props.fields.heading} />
          </div>
        }
        <Placeholder name="jss-rxss-layout" rendering={props.rendering} />
      </SSORedirectProvider>
    </ModalProvider>
  );
};

export default RxSSLayout;
