import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH } from "../../../define.constants";
import { withModalProvider } from "../../../hoc/withModalProvider";
import { ModalContext } from "../../../contexts/modal.context";
import Reasons from "./Reasons";

const ReqIdModal = (props) => {
  const { fields } = props;
  const { openModal } = useContext(ModalContext);

  return (
    <>
      <button
        type="button"
        className="btn btn-default request-id-modal-btn"
        onClick={() =>
          openModal({
            header: (
              <div className="request-new-id-header">
                <Text field={fields.PopupRequestNewIDCard} />
              </div>
            ),
            content: <Reasons {...props} />,
            version: 1,
          })
        }
      >
        <img
          className="state-color"
          src={`${ICON_PATH}/contact.svg`}
          aria-hidden="true"
          alt={fields.btnRequestNewIDCard?.value}
        ></img>
        <span className="space-btw">
          <Text field={fields.btnRequestNewIDCard} />
        </span>
      </button>
    </>
  );
};

export default withModalProvider(ReqIdModal);
