import React, { useContext, useState } from "react";
import { ModalContext } from "../../../contexts/modal.context";
import { ICON_PATH } from "../../../define.constants";
import SearchForm from "./SearchForm";
import ViewEstimate from "./ViewEstimate";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
//import { mobileAndTabletCheck } from "../../../utils/helpers";

const SearchResults = ({ fields, results, request }) => {
  /*let postsPerPage = mobileAndTabletCheck()
    ? getFieldValue(fields, "lblRecordsPerPage")
    : results?.length;

  const [next, setNext] = useState(postsPerPage);
  const [page, setPage] = useState(1);

  let totalRecords = results?.length ?? 0;
  let totalPages = Math.ceil(totalRecords / postsPerPage); */

  const { openModal } = useContext(ModalContext);
  const backToSearch = () => {
    openModal({
      content: <SearchForm fields={fields} initialValues={request} />,
      version: 2,
    });
  };

  /*const handleShowMoreRecords = () => {
    setNext(
      (prevVal) =>
        prevVal +
        (totalRecords - next < postsPerPage
          ? totalRecords - next
          : postsPerPage)
    );
    setPage(page + 1);
  }; */

  const viewEstimateHandler = (item) => {
    openModal({
      content: (
        <ViewEstimate
          fields={fields}
          request={request}
          results={results}
          code={item.ProcedureCode ?? item.Id}
          description={item.ProcedureDescription ?? item.CodeDescription}
        />
      ),
      version: 4,
    });
  };
  return (
    <div className="mycoverage-modal">
      <div className="update-header">
        <div className="back-link" onClick={backToSearch}>
          <img
            className="back-link-arrow"
            src={`${ICON_PATH}/arrow-left-24px.svg`}
            alt={getFieldValue(fields, "lblBack")}
          />
          <div className="back-link-title">
            <Text field={fields.lblBack} />
          </div>
        </div>
        <div className="table-heading">
          <Text field={fields.lblResult} />
          <span>
            &nbsp;&quot;
            {request.ProcedureDescription && request.ProcedureDescription !== ""
              ? request.ProcedureDescription
              : request.ProcedureCode}
            &quot;
          </span>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {[fields.lblCode, fields.lblDesc, null].map((field, index) => (
                  <th key={index} scope="col">
                    {field && <Text field={field} />}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {results?.length ? (
                results /*slice(0, next).*/
                  .map((item) => (
                    <tr key={item.$id}>
                      <td>{item.ProcedureCode ?? item.Id}</td>
                      <td className="word-break">
                        {item.ProcedureDescription ?? item.Description}
                        <div className="d-md-none">
                          <a
                            href="#"
                            onClick={() => viewEstimateHandler(item)}
                            className="card-link state-color-txt"
                          >
                            <Text field={fields.lblEstimate} />
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          href="#"
                          onClick={() => viewEstimateHandler(item)}
                          className="card-link state-color-txt"
                        >
                          <Text field={fields.lblEstimate} />
                        </a>
                      </td>
                    </tr>
                  ))
              ) : (
                <td colSpan="3">
                  <Text field={fields.lblNoResult} />
                </td>
              )}
            </tbody>
          </table>
          {/*totalPages !== page && (
            <div className="show-more-btn">
              <a className="card-link" onClick={handleShowMoreRecords}>
                <Text field={fields.lblShowMore} />
              </a>
            </div>
          )*/}
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
