import React, { useContext } from "react";
import { AppContext } from "../../../contexts/app.context";
import { ModalContext } from "../../../contexts/modal.context";
import SearchForm from "./SearchForm";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { MARKETPLACE } from "../../../define.constants";
import { getConfigByKey } from "../../../utils/helpers";
import { UNAUTHORIZED } from "../../../define.constants";
import { SitecoreContextReactContext } from "@sitecore-jss/sitecore-jss-react";

const CostEstimatorButton = ({ fields, list }) => {
  const { openModal } = useContext(ModalContext);
  const {
    context: {
      route: { placeholders },
    },
  } = useContext(SitecoreContextReactContext);
  const { isTermedMember, memberLOB, coverageEffDt, planTerminationDt } =
    useContext(AppContext);
  const { isSupportUser } = useContext(AppContext);
  const covDate = new Date(coverageEffDt);
  const termDate = new Date(planTerminationDt);
  const curDate = new Date();
  const isFutureEffective =
    covDate > curDate && termDate > curDate ? true : false;
  const eceUrl = list?.length > 0 && list?.[0]?.fields?.eceLink?.value.href;
  const isMarketplaceLOB =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase() ? true : false;
  function handleClick(e) {
    if (isMarketplaceLOB) {
      if (isSupportUser) {
        openModal({
          header: <></>,
          content: (
            <>{getConfigByKey(UNAUTHORIZED, placeholders?.["jss-config"])}</>
          ),
          footer: <></>,
          version: 1,
        });
      } else {
        if (!isTermedMember && !isFutureEffective) window.open(eceUrl);
        else if (isFutureEffective)
          openModal({
            content: <Text field={fields.lblAvailFirstDay} />,
            version: 1,
          });
      }
    } else {
      openModal({
        content: <SearchForm fields={fields} />,
        version: 2,
      });
    }
  }
  return (
    <div className="cost-estimator-btn">
      <button
        type="button"
        className={
          isTermedMember && isMarketplaceLOB
            ? " btn btn-secondary disabled"
            : "btn btn-secondary"
        }
        onClick={handleClick}
      >
        <Text field={list?.[0]?.fields?.lblEstmtrButton} />
      </button>
    </div>
  );
};

export default CostEstimatorButton;
