import React, { useContext } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import NonMarketplaceLanguageInfoLink from "./NonMarketplaceLanguageInfoLink";
import LanguageInfoLink from "./LanguageInfoLink";
import {
  MEDICARE,
  MEDICAID,
  DUALS,
  NY,
  CA,
  ID,
  MA,
  MULTI_LANG_LINK,
  NON_DISCRIMINATION_LINK,
} from "../../define.constants";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
const NonMarketplaceLanguageNonDis = ({
  distItem,
  fields,
  languageInfoList,
}) => {
  const { MemberStateName, memberLOB, planId, programId, isNYAffinityMember } =
    useContext(AppContext);
  const keys = distItem !== null ? Object.keys(distItem?.phrases) : null;

  let selectedYear = new Date().getFullYear();
  let param = "";
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == NY) {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        getFieldValue(languageInfoList?.fields, "docName") +
        `~` +
        programId +
        `~` +
        isNYAffinityMember +
        `~` +
        selectedYear;
    }
  }
  let planurl = "#";

  if (keys !== null && keys !== undefined) {
    keys.forEach((item) => {
      if (item == param) {
        planurl = distItem?.phrases[item];

        return planurl;
      }
    });
  }

  return (
    <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
      <NonMarketplaceLanguageInfoLink
        text={
          getFieldValue(languageInfoList?.fields, "nonDiscriminationLink")?.text
        }
        planurl={planurl}
        link={languageInfoList?.fields?.nonDiscriminationLink}
        image={languageInfoList?.fields?.nonDiscriminationImage}
      />
    </p>
  );
};
export default NonMarketplaceLanguageNonDis;
/*NonMPChecking*/
