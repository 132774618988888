import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as IdCardAPI from "../services/IdCardAPI";
import { ErrorContext } from "../contexts/error.context";
import { MARKETPLACE } from "../define.constants";
const qs = require("qs");

const useMemAPIIdCardDetailsAPI = (member, year, memberLOB) => {
  const { setError } = useContext(ErrorContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [result, setResult] = useState("");
  const [details, setDetails] = useState("");
  const [idCard, setIdCard] = useState("");
  const [drugInfo, setDrugInfo] = useState("");

  const setData = (data) => {
    setResult(data?.ResultData);
    setDetails(data?.ResultData?.MemAPIIdCardDetails);
    setIdCard(data?.ResultData?.MemberIdCard);
    setDrugInfo(data?.ResultData?.MemberIdCard?.Druginfo);
  };

  const isValidInput = (memVal, yearVal, lobVal) => {
    if (lobVal && lobVal?.toLowerCase() !== MARKETPLACE && memVal) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isValidInput(member, year, memberLOB)) {
      setSpinner(true);
      let request = {
        DepRksMemId: member.RksMemberId,
        DepMemStateCode: member.StateCode,
        Year: year,
      };
      IdCardAPI.getMemAPIIdCardDetails(
        request,
        qs.stringify(request, { addQueryPrefix: true })
      )
        .then((response) => {
          setData(response);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    }
  }, [setSpinner, member, year, memberLOB]);

  return {
    result,
    details,
    idCard,
    drugInfo,
  };
};

export { useMemAPIIdCardDetailsAPI };
