import React, { useState } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH } from "../../define.constants";
import { INBOX } from "../../define.constants";
import { ARCHIVE } from "../../define.constants";

const MessageTabs = ({
  labels,
  messageCount,
  onTabSelect,
  forDesktop = true,
}) => {
  const [activeTab, setActiveTab] = useState(INBOX);

  const handleClick = (tab) => {
    setActiveTab(tab);
    onTabSelect(tab);
  };

  const LabelAndCounter = ({ label, messageCounter }) => (
    <>
      <Text field={labels[label]} />
      {messageCounter > 0 && (
        <span className="msg-counter">{messageCounter}</span>
      )}
    </>
  );

  return (
    <div className={`message-menu-list ${forDesktop ? "row" : ""}`}>
      {[
        {
          tab: INBOX,
          label: "lblInbox",
          icon: "email-24px.svg",
          messageCounter: messageCount,
        },
        {
          tab: ARCHIVE,
          label: "lblArchived",
          icon: "archive-24px-dark.svg",
        },
      ].map(({ tab, label, icon, messageCounter }) => (
        <div
          key={tab}
          className={`${forDesktop ? "col-md-12" : "message-menu"} ${
            tab === ARCHIVE ? "tab-disabled" : ""
          } ${activeTab === tab ? "active" : ""}`}
          onClick={() => handleClick(tab)}
        >
          {forDesktop ? (
            <div
              className={`message-menu ${
                tab === ARCHIVE ? "tab-disabled" : ""
              }`}
            >
              <img
                src={`${ICON_PATH}/${icon}`}
                alt={getFieldValue(labels, label)}
              />
              <LabelAndCounter label={label} messageCounter={messageCounter} />
            </div>
          ) : (
            <LabelAndCounter label={label} messageCounter={messageCounter} />
          )}
        </div>
      ))}
    </div>
  );
};

export default MessageTabs;
