import React from "react";
import { MultiStepProvider } from "../contexts/Steps.context";

function withStepContext(WrappedComponent) {
  return class HOC extends React.Component {
    render() {
      return (
        <MultiStepProvider>
          <WrappedComponent {...this.props} />
        </MultiStepProvider>
      );
    }
  };
}

export { withStepContext };
