import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";

const LabResultsRecords = (props) => {
  const { fields, data } = props;

  return (
    <>
      <div className="row print-heading mb-4 d-none">
        <Text field={fields.lblprintHeading} />
      </div>
      <ul className="list-group d-none d-md-block" id="recordsToPrint">
        {data?.map((item, index) => (
          <li className="list-group-item " key={index}>
            <div className="row label-list">
              <DisplayItem
                nameField={fields.lblDateOfVaccination}
                outClass={"col-xl-6 col-md-6 details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblNameOfVaccination}
                outClass={"col-xl-6 col-md-6 details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
            </div>
            <div className="row">
              <DisplayItem
                nameField={fields.lblDateOfVaccination}
                valueField={item.ServiceDate}
                outClass={"col-xl-6 col-md-12 details d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblNameOfVaccination}
                valueField={item.Description}
                outClass={"col-xl-6 col-md-12 details d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default LabResultsRecords;
