import React, { useContext } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import LinkInfo from "./LinkInfo";
import {
  IMAGE_PATH,
  MARKETPLACE,
  NY_MD_CHP_PROGRAMID,
  MEDICAID,
  NY,
  NY_MD_NMC_PROGRAMID,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import { InnerSpinner } from "../../common/Spinner";
import { StateLOBImagePath } from "./Helper";

const HomeMyIDCard = (props) => {
  const {
    MemberStateName,
    memberLOB,
    memberRKSID,
    isTermedMember,
    programId,
    planId,
    DSNPdetails,
  } = useContext(AppContext);
  const { fields } = props;
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE?.toLowerCase();

  // Flag for NY-Chip to hide Home-IDCard
  const isNYChipMedicaid =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === NY &&
    programId === NY_MD_CHP_PROGRAMID;

  // Flag to hide IDCard & link for DSNP- Medicaid member
  const isDSNPMedicaidMember =
    DSNPdetails?.length > 0 && memberLOB?.trim()?.toLowerCase() === MEDICAID;

  //To pull image path
  const relativeImagePath =
    MemberStateName && memberLOB && planId && !isDSNPMedicaidMember
      ? StateLOBImagePath(MemberStateName, memberLOB, programId)
      : null;

  const isHomePageIdCardDisplay =
    !isMarketplaceMember && isNYChipMedicaid === false;

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  return isHomePageIdCardDisplay ? (
    <BoxContainer
      classForWidth={"col-xl-4 col-lg-6 col-sm-12"}
      sectionClass={""}
    >
      <SSORedirectProvider>
        <Box title={fields.headingMyIDCard} cardSize={"small"}>
          {!memberRKSID && <InnerSpinner />}
          {relativeImagePath && !isTermedMember && (
            <>
              <div
                className="idcardimage"
                style={{
                  backgroundImage: `url(${IMAGE_PATH}${relativeImagePath})`,
                  height: "86%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              ></div>
              <div className="row card-link-cta-block-2col align-items-end">
                <LinkInfo
                  href={getFieldValue(fields, "idCardLink")?.href}
                  text={getFieldValue(fields, "idCardLink")?.text}
                  cardInfo={fields.idCardLinkInfo}
                  sectionClass={"mobile-bottom"}
                  isHref={true}
                />
              </div>
            </>
          )}
        </Box>
      </SSORedirectProvider>
    </BoxContainer>
  ) : null;
};

export default withModalProvider(HomeMyIDCard);
