import React, { useContext, useState, createContext } from "react";
import { useGetDictionaryItems } from "../hooks/useDictionaryItems";
import { useGetDDLYearsAPI } from "../hooks/useGetDDLYearsAPI";

const MyResourcesContext = createContext({
  distItem: {},
  listRecords: [],
});

function MyResourcesProvider({ children }) {
  const { distItem, SetDistItem } = useGetDictionaryItems();
  const { listRecords, setListRecords } = useGetDDLYearsAPI();
  const context = { distItem, SetDistItem, listRecords, setListRecords };

  return (
    <div>
      <MyResourcesContext.Provider value={context}>
        {children}
      </MyResourcesContext.Provider>
    </div>
  );
}

export { MyResourcesContext, MyResourcesProvider };
