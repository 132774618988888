import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const Title = ({ fields }) => {
  return (
    <div className="cost-estimator-title">
      <Text field={fields.lblPCE} />
    </div>
  );
};

export default Title;
