import React, { useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH } from "../../define.constants";

const ReadMore = ({ children, props }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="plan-content">
      {isReadMore ? null : text}
      <div onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? (
          <div className="show-btn">
            <span>
              <Text field={props.lblViewDetails} />
              <img
                src={`${ICON_PATH}/arrow-right.svg`}
                alt={props.lblViewDetails.value}
              />
            </span>{" "}
          </div>
        ) : (
          <div className="close-btn">
            <span>
              <Text field={props.lblClose} />{" "}
              <img
                src={`${ICON_PATH}/icon-navigation-chevron_up.svg`}
                alt={props.lblClose.value}
              />
            </span>
          </div>
        )}
      </div>
    </p>
  );
};
export default ReadMore;
