import React, { useContext } from "react";
import { Image, Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  SUPPORT_USER,
  TERMED_MEMBER,
  SWITCHED_TO_DEPENDENT,
} from "../../define.constants";
import {
  isAccessibleToSupportUser,
  isAccessibleToTermedMember,
  isAccessibleToSwitchedDependent,
} from "../../utils/helpers";

const RenderSSOLink = ({ fields }) => {
  const { redirectToSSO } = useContext(SSORedirectContext);
  const ssoEndPoint = getFieldValue(fields, "ssoEndPoint");

  return (
    <WhoIsAuthorized
      type="link"
      href="#"
      authorize={{
        [SUPPORT_USER]: isAccessibleToSupportUser(ssoEndPoint),
        [TERMED_MEMBER]: isAccessibleToTermedMember(ssoEndPoint),
        [SWITCHED_TO_DEPENDENT]: isAccessibleToSwitchedDependent(ssoEndPoint),
      }}
      handleClick={(e) =>
        redirectToSSO({
          e,
          url: constants.apigeeURLs[ssoEndPoint],
        })
      }
    >
      {({ handleClick, disabledClass, href }) => {
        return (
          <div className={`carousel-item-block ${disabledClass}`.trim()}>
            <a href={href} onClick={handleClick}>
              <div className="card">
                <Image className="carousel-icon" field={fields.image} />
                <div>
                  <div>
                    <Text aria-label={fields.title} field={fields.title} />
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      }}
    </WhoIsAuthorized>
  );
};

export default RenderSSOLink;
