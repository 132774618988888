import React, { useContext, useState } from "react";
import {
  Text,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { AppContext } from "../../contexts/app.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { SUPPORT_USER, TERMED_MEMBER, KY } from "../../define.constants";

const RxSSInfo = (props) => {
  const { redirectToSSO } = useContext(SSORedirectContext);
  const { profileList } = useContext(AppContext);

  const { fields } = props;

  const rxSSRedirect = (e) => {
    redirectToSSO({
      e,
      url: constants.apigeeURLs.GetRXSSSavingsSSORedirect,
    });
  };

  return (
    <div className="rxss-home pt-5">
      <div className="row">
        <div className="col-12 d-none d-sm-none d-md-block"></div>
      </div>
      <div className="rxss-content">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <section className="got-question-section d-flex justify-content-center">
              <div className="card lg-card rxssinfo-card">
                <div className="card-body rxss-card">
                  <div className="row custom-container">
                    <div className="col-md-12 col-lg-12 rxss-static">
                      <Text
                        field={
                          profileList?.State === KY
                            ? fields.rxssStaticContentKY
                            : fields.rxssStaticContent
                        }
                      />
                    </div>
                  </div>
                  <div className="pt-4">
                    <WhoIsAuthorized
                      authorize={{
                        [SUPPORT_USER]: false,
                        [TERMED_MEMBER]: true,
                      }}
                      handleClick={rxSSRedirect}
                    >
                      {({ handleClick }) => {
                        return (
                          <button
                            className={`btn btn-primary`}
                            onClick={handleClick}
                            disabled={profileList?.State === KY ? true : false}
                          >
                            <Text
                              className="btn btn-primary ripple-surface"
                              field={fields.rxSSNavigation}
                            />
                          </button>
                        );
                      }}
                    </WhoIsAuthorized>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RxSSInfo;
