import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import * as MedicationsAPI from "../services/HeathRecordAPI";
import { ErrorContext } from "../contexts/error.context";
import { MY_HEALTH_RECORD_VIEW_MEDICATIONS } from "../define.widgets";
const qs = require("qs");

const useMedicationsAPI = (searchTerm = null) => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [medicationsList, setMedicationsList] = useState([]);
  const { MemberStateName, memberRKSID } = useContext(AppContext);
  const [medicationsMessage, setMedicationsMessage] = useState("");
  const [medicationsDisclaimer, setMedicationsDisclaimer] = useState("");
  const [ismedloaded, setismedloaded] = useState(false);

  const setData = (data) => {
    setMedicationsList(data.ResultData?.Medications?.DistinctMedications);
    setMedicationsMessage(data.ResultData?.Medications?.MedicationsMessage);
    setMedicationsDisclaimer(
      data.ResultData?.Medications?.MedicationsDisclaimer
    );
    setismedloaded(true);
  };

  useEffect(() => {
    let startDate = "",
      endDate = "";
    if (searchTerm != null) {
      startDate = searchTerm["startDate"];
      endDate = searchTerm["endDate"];
    }
    let query = {
      MedicationDateFrom: startDate,
      MedicationDateTo: endDate,
    };

    setInnerSpinner(true, MY_HEALTH_RECORD_VIEW_MEDICATIONS);
    MedicationsAPI.ViewMedications(
      {
        RKSMemberId: memberRKSID,
        StateCode: MemberStateName,
        MedicationDateFrom: startDate,
        MedicationDateTo: endDate,
      },
      qs.stringify(query, { addQueryPrefix: true })
    )
      .then((response) => {
        if (response != undefined && response != null) {
          setData(response);
        }
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(function () {
        setInnerSpinner(false, MY_HEALTH_RECORD_VIEW_MEDICATIONS);
      });
  }, [searchTerm]);

  return {
    medicationsList,
    medicationsMessage,
    medicationsDisclaimer,
    ismedloaded,
  };
};

export { useMedicationsAPI };
