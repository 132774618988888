import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CODE_RESULT_SUCCESS,
  ICON_PATH,
  INVALID_PING_PASSWORD_CODE,
  PINGUSERID_NOT_FOUND_RESULT_CODE,
  PWD_LOWERCASE_REGEX,
  PWD_MINIMUM_LENGTH,
  PWD_NUMBER_REGEX,
  PWD_UPPERCASE_REGEX,
  REPEAT_THREE_REGEX,
} from "../../define.constants";
import { updatePassword } from "../../services/AccountSettingsMfaAPI";
import { ErrorContext } from "../../contexts/error.context";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const UpdatePassword = (props) => {
  const eye = `${ICON_PATH}/visibility-24px.svg`;
  const eyeSlash = `${ICON_PATH}/visibility_off_black_24dp.svg`;

  const fields = props.fields;
  const { setError } = useContext(ErrorContext);
  const [passVisable, setPassVisable] = useState(false);
  const [confirmVisable, setConfirmVisable] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [popoverPlacement, setPopoverPlacement] = useState();
  const [pwdLength, setPwdLength] = useState(false);
  const [pwdUppercaseRule, setPwdUppercaseRule] = useState(false);
  const [pwdLowercaseRule, setPwdLowercaseRule] = useState(false);
  const [pwdNumberRule, setPwdNumberRule] = useState(false);
  const [pwdRepeatThreeRule, setPwdRepeatThreeRule] = useState(false);
  const [pwdNoSpaceRule, setPwdNoSpaceRule] = useState(false);
  const [pwdValidated, setPwdValidated] = useState(false);
  const [passwordRejected, setPasswordRejected] = useState(false);
  const [screen, setScreen] = useState(1);
  const [showError, setShowError] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [codeSentCount, setCodeSentCount] = useState(0);

  // adjust popover placement based on screen width
  useEffect(() => {
    if (window.outerWidth > 768) {
      setPopoverPlacement("right");
    } else {
      setPopoverPlacement("bottom");
    }
  }, [window.outerWidth]);

  // the fallowing two const are for the eye icon
  const passVisibilityIcon = (
    <img
      src={passVisable ? eye : eyeSlash}
      alt={getFieldValue(fields, "newPassword")}
      className="pwd-icon"
      onClick={() => setPassVisable(!passVisable)}
      width="24px"
    />
  );

  const confirmVisibilityIcon = (
    <img
      src={confirmVisable ? eye : eyeSlash}
      alt={getFieldValue(fields, "confirmPassword")}
      className="pwd-icon"
      onClick={() => setConfirmVisable(!confirmVisable)}
      width="24px"
    />
  );

  // check password validity and set individual rule met
  const validatepwd = (value) => {
    let invalid = false;
    if (value.length < PWD_MINIMUM_LENGTH) {
      setPwdLength(false);
      invalid = true;
    } else {
      setPwdLength(true);
    }
    if (PWD_UPPERCASE_REGEX.test(value)) {
      setPwdUppercaseRule(true);
    } else {
      setPwdUppercaseRule(false);
      invalid = true;
    }
    if (PWD_LOWERCASE_REGEX.test(value)) {
      setPwdLowercaseRule(true);
    } else {
      setPwdLowercaseRule(false);
      invalid = true;
    }
    if (PWD_NUMBER_REGEX.test(value)) {
      setPwdNumberRule(true);
    } else {
      setPwdNumberRule(false);
      invalid = true;
    }
    if (value.includes(" ")) {
      setPwdNoSpaceRule(false);
      invalid = true;
    } else {
      setPwdNoSpaceRule(true);
    }
    if (REPEAT_THREE_REGEX.test(value)) {
      setPwdRepeatThreeRule(false);
      invalid = true;
    } else {
      setPwdRepeatThreeRule(true);
    }
    setPwdValidated(!invalid);

    return invalid;
  };

  // individual popover list item for interactive popover
  const popoverListItemSecond = (text, ruleMet) => {
    if (ruleMet) {
      return (
        <li>
          <div className="row">
            <i className="col-1">
              <img src={`${ICON_PATH}/iconGreen.jpg`} />
            </i>

            <div className="col-11 col-md-10 col-sm-10">
              <b>{text}</b>
            </div>
          </div>
        </li>
      );
    } else {
      return (
        <li style={{ color: "red" }}>
          <div className="row">
            <i className="col-1">
              <img src={`${ICON_PATH}/iconRed.jpg`} />
            </i>

            <div className="col-11 col-md-10 col-sm-10">
              <b>{text}</b>
            </div>
          </div>
        </li>
      );
    }
  };

  // interacive popover (the one with checkmarks or empty circles)
  const pwdPopover = (
    <div>
      <div className="popoverHeader">
        <b>
          <Text field={fields.minimumRequirements} />
        </b>
      </div>
      <div>
        <ul className="list-unstyled">
          {popoverListItemSecond(getFieldValue(fields, "pwdLength"), pwdLength)}
          {popoverListItemSecond(
            getFieldValue(fields, "pwdUppercaseRule"),
            pwdUppercaseRule
          )}
          {popoverListItemSecond(
            getFieldValue(fields, "pwdLowercaseRule"),
            pwdLowercaseRule
          )}
          {popoverListItemSecond(
            getFieldValue(fields, "pwdNumberRule"),
            pwdNumberRule
          )}
          {popoverListItemSecond(
            getFieldValue(fields, "pwdNoSpaceRule"),
            pwdNoSpaceRule
          )}
          {popoverListItemSecond(
            getFieldValue(fields, "pwdRepeatThreeRule"),
            pwdRepeatThreeRule
          )}
        </ul>
      </div>
    </div>
  );

  // individual list items for static popover
  const popoverListItem = (text) => {
    return (
      <li>
        <div className="row">
          <div className="col-11 col-md-10 col-sm-10">
            <b>{text}</b>
          </div>
        </div>
      </li>
    );
  };

  // the smaller static popover
  const theSmallBoxOfPasswordRules = (
    <div>
      <div>
        <b>
          <Text field={fields.passwordMustNotInclude} />
        </b>
      </div>
      <ul className="list-unstyled password-list">
        {popoverListItem(getFieldValue(fields, "pwdPartOfUserName"))}
        {popoverListItem(getFieldValue(fields, "lastThreePasswordResets"))}
        {popoverListItem(getFieldValue(fields, "closelyMatchCurrentPassword"))}
        {popoverListItem(getFieldValue(fields, "personalInformationOr"))}
        {popoverListItem(getFieldValue(fields, "pwdBeEasilyGuessed"))}
      </ul>
    </div>
  );

  // static popover (The longer list that displays when hoverin over the info icon)
  const theBigBoxOfPasswordRules = (
    <div>
      <div className="password-tooltip">
        <Text field={fields.howToCreatePassword} />
      </div>
      <div>
        <b>
          <Text field={fields.minimumRequirements} />
        </b>
      </div>
      <div>
        <ul className="list-unstyled password-list">
          {popoverListItem(getFieldValue(fields, "pwdLength"))}
          {popoverListItem(getFieldValue(fields, "pwdUppercaseRule"))}
          {popoverListItem(getFieldValue(fields, "pwdLowercaseRule"))}
          {popoverListItem(getFieldValue(fields, "pwdNumberRule"))}
          {popoverListItem(getFieldValue(fields, "pwdNoSpaceRule"))}
          {popoverListItem(getFieldValue(fields, "pwdRepeatThreeRule"))}
        </ul>
      </div>
      {theSmallBoxOfPasswordRules}
    </div>
  );

  // Call update password API
  const updatePasswordHandler = async (values) => {
    try {
      const response = await updatePassword({ newPassword: values.newPass });
      if (response.ResultCode === CODE_RESULT_SUCCESS) {
        setScreen(2);
      } else if (response.ResultCode === PINGUSERID_NOT_FOUND_RESULT_CODE) {
      } else if (response.ResultCode === INVALID_PING_PASSWORD_CODE) {
        setPasswordRejected(true);
        setPwdValidated(false);
      } else {
        setError({ err: response.ResultData?.ResponseStatusCode?.Message });
      }
    } catch (err) {
      setError({ err });
    }
  };

  const enterCodeError = (
    <div className="missing-code">
      <Text field={fields.pleaseEnterSecurityCode} />
    </div>
  );

  const submitCode = () => {
    setScreen(1);
  };

  const screen1 = (
    <div>
      <div className="text-center changeEmailHeader MFAHeader">
        <Text field={fields.verifyCode} />
      </div>
      <div className="mfa-description MFA-info">
        <Text field={fields.emailTo} />
        {props.email}.
      </div>
      <div className="MFALabel editEmailLabel">
        <Text field={fields.oneTimeCode} />
      </div>
      <Formik
        initialValues={{ code: "" }}
        onSubmit={submitCode}
        validationSchema={Yup.object().shape({
          code: Yup.string().required().length(6),
        })}
      >
        {({ values, errors, touched }) => (
          <Form>
            <div>
              <Field
                name="code"
                type="text"
                maxLength="6"
                className={`form-control ${
                  errors.code && touched.code && "is-invalid"
                }`}
              />
              {errors.code && touched.code && enterCodeError}
            </div>
            {showError}
            <div className="text-center codeButtons">
              <button
                type="button"
                className="btn blue-text"
                disabled={codeSentCount >= 2}
                onClick={() => {
                  // onNext({ newEmail });
                  setAttemptCount(0);
                  setCodeSentCount((count) => count + 1);
                  setShowError(
                    <div className="error-message margin-top-one-rem">
                      <Text field={fields.codeResent} />
                    </div>
                  );
                }}
              >
                <Text field={fields.resendCodeBtn} />
              </button>
            </div>
            <div className="codeButtons text-center">
              <button
                type="button"
                className="btn blue-text"
                onClick={props.close}
              >
                <Text field={fields.cancelBtn} />
              </button>
              <button
                type="submit"
                className="btn btn-molina"
                maxLength="6"
                disabled={errors.code || attemptCount > 2}
              >
                <Text field={fields.submitBtn} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  const screen2 = (
    <div>
      <div className="MFAHeader text-center update-password-margin">
        <Text field={fields.updatePasswordHeader} />
      </div>
      <Formik
        initialValues={{ newPass: "", confirmPass: "" }}
        onSubmit={updatePasswordHandler}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          newPass: Yup.string().required(" "),
          confirmPass: Yup.string()
            .required(" ")
            .oneOf(
              [Yup.ref("newPass"), null],
              getFieldValue(fields, "doesNotMatchMsg")
            ),
        })}
      >
        {({ values, errors, touched, setFieldTouched }) => (
          <Form>
            <div className="update-password-margin">
              <div className="MFALabel editEmailLabel grayscale-override">
                <img
                  src={`${ICON_PATH}/check-green.svg`}
                  className="check-green"
                  style={{
                    display: pwdValidated ? "inline" : "none",
                  }}
                  alt={getFieldValue(fields, "pwd")}
                />
                <Text field={fields.newPassword} />
                <div className="font-weight-500 custom-tooltip-spending pl-3">
                  <div className="custom-tooltip-spending-text tooltipBox">
                    {theBigBoxOfPasswordRules}
                  </div>
                  <img
                    style={{ marginBottom: "2px" }}
                    src={`${ICON_PATH}/info-24px.svg`}
                    alt={getFieldValue(fields, "infoIcon")}
                  />
                </div>
              </div>
              <OverlayTrigger
                placement={popoverPlacement}
                trigger="focus"
                show={showPopover}
                overlay={
                  <Popover className="pwdPopover popoverMob">
                    <Popover.Body>{pwdPopover}</Popover.Body>
                  </Popover>
                }
              >
                <div className="update-password-wrapper">
                  <Field
                    name="newPass"
                    type={passVisable ? "text" : "password"}
                    className={`form-control ${
                      (errors.newPass && touched.newPass) ||
                      (passwordRejected && "is-invalid")
                    }`}
                    validate={validatepwd}
                    onKeyDown={() => setPasswordRejected(false)}
                    onFocus={() => setShowPopover(true)}
                    onBlur={() => {
                      setShowPopover(false);
                      setFieldTouched("newPass", true);
                    }}
                  />
                  <i>{passVisibilityIcon}</i>
                </div>
              </OverlayTrigger>
              {passwordRejected && (
                <div className="error-message half-rem-top-margin">
                  <Text field={fields.noncompliantPasswordError} />
                  <div className="font-weight-500 custom-tooltip-spending pl-3">
                    <div className="custom-tooltip-spending-text tooltipBox">
                      {theSmallBoxOfPasswordRules}
                    </div>
                    <img
                      style={{ marginBottom: "2px" }}
                      src={`${ICON_PATH}/info-24px.svg`}
                      alt={getFieldValue(fields, "infoIcon")}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="update-password-margin">
              <div className="MFALabel editEmailLabel grayscale-override">
                <img
                  src={`${ICON_PATH}/check-green.svg`}
                  className="check-green"
                  style={{
                    display:
                      pwdValidated && values.confirmPass == values.newPass
                        ? "inline"
                        : "none",
                  }}
                  alt={getFieldValue(fields, "pwd")}
                />
                <Text field={fields.confirmPassword} />
              </div>
              <div className="update-password-wrapper">
                <Field
                  name="confirmPass"
                  type={confirmVisable ? "text" : "password"}
                  className={`form-control ${
                    ((values.confirmPass != values.newPass &&
                      touched.confirmPass) ||
                      passwordRejected) &&
                    "is-invalid"
                  }`}
                />
                <i>{confirmVisibilityIcon}</i>
              </div>
              {values.newPass !== values.confirmPass && touched.confirmPass && (
                <div className="error-message">
                  <Text field={fields.doesNotMatchMsg} />
                </div>
              )}
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-molina">
                <Text field={fields.updatePasswordBtn} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  const screen3 = (
    <div>
      <div className="text-center MFAHeader">
        <Text field={fields.allSet} />
      </div>
      <div className="mfa-description MFA-info">
        <Text field={fields.passwordUpdated} />
      </div>
      <div className="mfa-description MFA-info">
        <Text field={fields.obviousInstructions} />
      </div>
    </div>
  );

  return screen === 0 ? screen1 : screen === 1 ? screen2 : screen3;
};

export default UpdatePassword;
