import React, { useContext } from "react";
import DisplayItemCH from "./DisplayItemCH";
import DisplayTitleItemCH from "./DisplayTitleItemCH";
import GetEOBDocument from "./GetEOBDocument";
import Status from "./Status";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { MARKETPLACE, ID } from "../../define.constants";
import { AppContext } from "./../../contexts/app.context";
import { showEOBClickInstruction } from "./Helper";

const MarketPlaceRecords = (props) => {
  const { data, fields } = props;
  let { memberLOB, MemberStateName, programId } = useContext(AppContext);
  let isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();
  const memberState_UC = MemberStateName?.toUpperCase();
  const memberLOB_UC = memberLOB?.toUpperCase();

  // Conditions For showing EOB
  const showEOB = () => {
    return showEOBClickInstruction(memberState_UC, memberLOB_UC, programId);
  };

  return (
    <>
      <ul className="list-group d-none d-md-block" id="recordsToPrint">
        {data?.map((item, index) => (
          <li className="list-group-item" key={index}>
            <div className="row ">
              <DisplayTitleItemCH
                item={item.Provider}
                colClass={"col-list-title d-xl-block d-none "}
              />
            </div>
            <div className="row d-xl-none">
              <div className="col-6">
                <DisplayTitleItemCH
                  item={item.Provider}
                  colClass={"col-list-title d-md-flex d-xl-block"}
                />
              </div>
              <div className="col-6 cl-history-status-padding">
                <Status
                  outerClass={" d-flex align-items-center"}
                  status={item.Status?.trim()}
                  fields={fields}
                />
              </div>
            </div>

            <div className="row label-list">
              <Status
                outerClass={
                  "cl-history-status d-xl-block d-none position-relative"
                }
                status={item.Status?.trim()}
                fields={fields}
              />
              <DisplayItemCH
                nameField={fields.lblClaimId.value}
                outClass={"col-sm details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItemCH
                nameField={fields.lblClaim.value}
                outClass={"col-sm details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItemCH
                nameField={fields.lblService.value}
                outClass={"col-sm details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />

              <DisplayItemCH
                nameField={fields.lblPaidOn.value}
                outClass={"col-sm details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              <DisplayItemCH
                nameField={fields.lblDescription.value}
                outClass={"col-sm details d-xl-block d-none"}
                titleClass={"list-desc-title"}
              />
              {item?.HasEOB && (isMarketplaceMember || showEOB) && (
                <>
                  <GetEOBDocument
                    claimId={item.ClaimID}
                    lblEOB={props.fields.lblEOB}
                    index={index}
                    lblEOBDoc={props.fields.lblEOBDoc}
                    divFlag={true}
                  />
                </>
              )}
            </div>
            <div className="row">
              <div className="col-xl-2 col-md-4 col-6"></div>

              <DisplayItemCH
                nameField={fields.lblClaimId.value}
                valueField={item.ClaimID}
                outClass={"col-sm details d-md-flex d-xl-block wid"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details "}
              />
              <DisplayItemCH
                nameField={fields.lblClaim.value}
                valueField={item.ClaimDatestr}
                outClass={"col-sm details d-md-flex d-xl-block wid"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details "}
              />
              <DisplayItemCH
                nameField={fields.lblService.value}
                valueField={item.ServiceDate}
                outClass={"col-sm details d-md-flex d-xl-block wid"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details "}
              />

              <DisplayItemCH
                nameField={fields.lblPaidOn.value}
                valueField={item.PaidONstr}
                outClass={"col-sm details d-md-flex d-xl-block wid"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details "}
              />
              <DisplayItemCH
                nameField={fields.lblDescription.value}
                valueField={item.PrimaryDiagDesc}
                outClass={"col-sm-2 details d-md-flex d-xl-block wid"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={`list-desc-details ${showEOB ? "widthEOB" : ""}`}
              />
              {item?.HasEOB && (
                <>
                  <div className="col-lg"></div>
                </>
              )}
            </div>
            <div className="row d-xl-none mt-2">
              {item?.HasEOB && (isMarketplaceMember || showEOB) && (
                <>
                  {/* <div className=""> */}
                  <GetEOBDocument
                    claimId={item.ClaimID}
                    lblEOB={props.fields.lblEOB}
                    index={index}
                    lblEOBDoc={props.fields.lblEOBDoc}
                    divFlag={false}
                  />
                  {/* </div> */}
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MarketPlaceRecords;
