import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { ErrorContext } from "../../contexts/error.context";
import { ModalContext } from "../../contexts/modal.context";
import { withModalProvider } from "../../hoc/withModalProvider";

function Error() {
  const { message } = useContext(ErrorContext);
  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    if (message) {
      openModal({
        content: <>{message}</>,
        version: 4,
      });
    }
  }, [message]);

  return null;
}

export default withModalProvider(Error);
