import Axios from "./axios/provider";
import { v4 as uuidv4 } from "uuid";
export const getOneildigitalid = async (
  state,
  lob,
  memberId,
  type,
  planYear
) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.Oneildigitalid, {
      state,
      lob,
      memberId,
      type,
      planYear,
      TransactionId: uuidv4(),
    });
    return response;
  } catch (error) {
    throw error;
  }
};
