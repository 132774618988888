import React, { useContext } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import HomeContext from "./../../contexts/home.context";
import { AppContext } from "../../contexts/app.context";
/**
 * The main layout of the home.
 */
const HomeLayout = (props) => {
  const { isSwitchedToDependent, isDependentLoggedIn } = useContext(AppContext);
  return (
    <HomeContext.Provider
      value={{ isSwitchedToDependent, isDependentLoggedIn, isAutoPay: false }}
    >
      <div className="home-page">
        <Placeholder name="jss-home-layout" rendering={props.rendering} />
      </div>
    </HomeContext.Provider>
  );
};

export default HomeLayout;
