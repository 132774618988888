import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Result from "./Result";
import * as IdCardAPI from "../../../services/IdCardAPI";
import { SpinnerContext } from "../../../contexts/spinner.context";
import { AppContext } from "../../../contexts/app.context";
import { getIDCardType } from "../../../utils/helpers.js";
import { ErrorContext } from "../../../contexts/error.context";
import { ModalContext } from "../../../contexts/modal.context";
import { SUPPORT_USER, TERMED_MEMBER } from "../../../define.constants";
import { WhoIsAuthorized } from "../../../common/WhoIsAuthorized";

const Confirmation = (props) => {
  const { openModal } = useContext(ModalContext);
  const { memberRKSID, memberLOB, MemberStateName } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const { setSpinner } = useContext(SpinnerContext);
  const { fields, selectedDependents, selectedReason, yearSel } = props;

  const handleIDCardRequest = () => {
    setSpinner(true);
    IdCardAPI.submitRequestIDCard({
      RequestIdCardType: getIDCardType(memberLOB, MemberStateName), //Required Parameter - Fetch it from common helper
      MemIDList:
        selectedDependents?.length > 0
          ? selectedDependents.map((sd) => sd.RksMemberId).join(";")
          : memberRKSID,
      ReasonId: selectedReason ? selectedReason.ReasonId : null, //Possible values 1,2,3,4,5
      Reason: selectedReason ? selectedReason.ChangeReason : "",
      Year: yearSel,
    })
      .then((response) => {
        setSpinner(false);
        openModal({
          header: (
            <div className="request-new-id-header">
              <Text field={fields.PopupRequestNewIDCard} />
            </div>
          ),
          content: <Result {...props} />,
          version: 1,
        });
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(function () {
        setSpinner(false);
      });
  };
  return (
    <>
      <p>
        <Text field={fields.PopupRequestNewIDCardP1} />
      </p>
      <p className="new-id-print1">
        <Text field={fields.PopupRequestNewIDCardP2} />
      </p>
      <WhoIsAuthorized
        authorize={{
          [SUPPORT_USER]: false,
          [TERMED_MEMBER]: false,
        }}
        handleClick={handleIDCardRequest}
      >
        {({ handleClick, disabledClass }) => {
          return (
            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-primary ${disabledClass}`.trim()}
                onClick={handleClick}
              >
                <Text field={fields.btnRequestNewIDCard} />
              </button>
            </div>
          );
        }}
      </WhoIsAuthorized>
    </>
  );
};

export default Confirmation;
