import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const CommonConfigItem = (props) => (
  <div>
    <p>Common-ConfigItem Component</p>
    <Text field={props.fields.key} />
    <Text field={props.fields.value} />
  </div>
);

export default CommonConfigItem;
