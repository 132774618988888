import React, { useCallback, useState } from "react";

const useCalendar = (props) => {
  const [currentDate, setCurrentDate] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return {
    currentDate,
    setCurrentDate,
    selectedDate,
    setSelectedDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };
};

export { useCalendar };
