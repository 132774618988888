import { PHONE_DIGIT_ONLY_REGEX } from "../../define.constants";

function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value?.replace(PHONE_DIGIT_ONLY_REGEX, "");
  const phoneNumberLength = phoneNumber?.length;
  if (phoneNumberLength < 4) return phoneNumber;
  else if (phoneNumberLength < 7) {
    return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3)}`;
  }
  return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(
    3,
    6
  )}-${phoneNumber?.slice(6, 10)}`;
}

export const onFocusFormatPhoneNumber = (value) => {
  const phoneNumber = value?.replace(PHONE_DIGIT_ONLY_REGEX, "") + "";
  return phoneNumber;
};

const onNumberInputChangeHandler = (
  event,
  inputName,
  formikProps,
  depInput
) => {
  const formatedNumber = onFocusFormatPhoneNumber(event.target.value);
  formikProps.setFieldValue(inputName, formatedNumber);
  depInput && formikProps.setFieldValue(depInput, formatedNumber);
};

export const changeHandler = (event, name, formikProps) => {
  onNumberInputChangeHandler(event, name, formikProps);
};
