import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
const NavLink = (props) => {
  var currentLocation = useLocation()?.pathname;
  var active = currentLocation === props?.href;
  let localProps = { ...props };
  localProps.className = localProps?.className + (active ? " active" : "");
  return <a {...localProps}>{props?.children}</a>;
};
NavLink.contextTypes = {
  router: PropTypes?.object,
};
export default NavLink;
