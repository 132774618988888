import React, { useContext, useState } from "react";
import HomeContext from "./../../contexts/home.context";
import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import Summary from "./Summary";
import SubscriberView from "./SubscriberView";
import DependentView from "./DependentView";
import { AppContext } from "../../contexts/app.context";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import {
  DEPENDENT,
  MARKETPLACE,
  MEDICAID,
  NY,
  NY_MD_CHP_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import MyBillModal from "./MyBillModal";
import { withModalProvider } from "../../hoc/withModalProvider";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

const HomeMyBill = (props) => {
  const { isSwitchedToDependent, isDependentLoggedIn, isAutoPay } =
    useContext(HomeContext);
  const {
    userType,
    MemberStateName,
    memberLOB,
    programId,
    isNYAffinityMember,
    isTermedMember,
  } = useContext(AppContext);
  const { fields } = props;
  const showSubscriberView = userType !== DEPENDENT ? true : false;
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE?.toLowerCase();

  const isNYChip =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === NY &&
    !isMarketplaceMember &&
    programId === NY_MD_CHP_PROGRAMID;
  const isMyBillSection = isMarketplaceMember || isNYChip;

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  const modalContent = (
    <>
      <SSORedirectProvider>
        <MyBillModal {...fields} />
      </SSORedirectProvider>
    </>
  );
  return isMyBillSection ? (
    <>
      <BoxContainer
        classForWidth={"col-xl-4 col-lg-6 col-sm-12"}
        sectionClass={""}
      >
        <SSORedirectProvider>
          <Box title={fields.heading} cardSize={"small"}>
            {/* <Summary fields={fields} /> */}

            {showSubscriberView ? (
              <SubscriberView
                fields={fields}
                isAutoPay={isAutoPay}
                MemberStateName={MemberStateName}
                modalContent={modalContent}
                memberLOB={memberLOB}
                programId={programId}
                isNYAffinityMember={isNYAffinityMember}
                isTermedMember={isTermedMember}
              />
            ) : (
              <DependentView fields={fields} />
            )}
          </Box>
        </SSORedirectProvider>
      </BoxContainer>
    </>
  ) : null;
};

export default withModalProvider(HomeMyBill);
