import React, { useContext } from "react";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import { ProgressBar } from "../../common/ProgressBar";
import { useGetAccumulatorAPI } from "../../hooks/useGetAccumulatorAPI";
import ProcedureCostEstimator from "./ProcedureCostEstimator";
import { getFormattedDate } from "../../utils/helpers";
import { SpinnerContext } from "../../contexts/spinner.context";
import { COVERAGE_TRACK_MY_SPENDING } from "../../define.widgets";

const TrackMySpending = ({ fields, plan, list }) => {
  const { accum, date } = useGetAccumulatorAPI(plan?.Year);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  let showFamilySpending =
    accum?.DeductibleFamilyPharmacyLimit > 0 ||
    accum?.FamilyLimit > 0 ||
    accum?.DeductibleFamilyMedicalLimit > 0;
  const accumValue =
    accum?.DeductibleIndividualMedicalLimit > 0 ||
    accum?.DeductibleIndividualPharmacyLimit > 0 ||
    accum?.IndividualLimit > 0;
  return (
    <div className="row trackmyspending">
      <div className="col-md-12 col-sm-12">
        <section className=" d-flex justify-content-center">
          <div className="card  lg-card">
            <div className="card-body">
              {innerSpinnerHTML?.[COVERAGE_TRACK_MY_SPENDING]}
              <div className="row">
                <div
                  className={`col-xxl-${
                    showFamilySpending ? `8` : `6`
                  } col-xl-${
                    showFamilySpending ? `12` : `6`
                  } col-lg-12 col-sm-12`}
                >
                  <div className="row">
                    <div
                      className={`col-xl-${
                        showFamilySpending ? `6` : `12`
                      } col-lg-12`}
                    >
                      <h5 className="card-title">
                        <Text field={fields.headingOne} />
                      </h5>
                      <div className="molina-progress-bar-list">
                        {accumValue ? (
                          <>
                            {accum?.DeductibleIndividualMedicalLimit > 0 && (
                              <ProgressBar
                                title={fields.lblMedical}
                                description={
                                  list[0]?.fields?.lblMedicalInfoStateSpecific
                                    .value
                                }
                                total={accum.DeductibleIndividualMedicalLimit}
                                actual={
                                  accum.DeductibleIndividualMedicalAccumValue
                                }
                                id="Home_TrackMySpending_Medical"
                              />
                            )}
                            {accum?.DeductibleIndividualPharmacyLimit > 0 && (
                              <ProgressBar
                                title={fields.lblPharmacy}
                                description={
                                  list[0]?.fields?.lblPharmacyInfoStateSpecific
                                    .value
                                }
                                total={accum.DeductibleIndividualPharmacyLimit}
                                actual={
                                  accum.DeductibleIndividualPharmacyAccumValue
                                }
                                id="Home_TrackMySpending_Pharmacy"
                              />
                            )}
                            {accum?.IndividualLimit > 0 && (
                              <ProgressBar
                                title={fields.lblAnnual}
                                description={
                                  list[0]?.fields?.lblAnnualInfoStateSpecific
                                    .value
                                }
                                total={accum.IndividualLimit}
                                actual={accum.IndividualAccumValue}
                                id="Home_TrackMySpending_Annual"
                              />
                            )}
                          </>
                        ) : (
                          <>
                            <ProgressBar
                              title={fields.lblMedical}
                              description={
                                list[0]?.fields?.lblMedicalInfoStateSpecific
                                  .value
                              }
                              total={0}
                              actual={0}
                              id="Home_TrackMySpending_Medical0"
                            />
                            <ProgressBar
                              title={fields.lblPharmacy}
                              description={
                                list[0]?.fields?.lblPharmacyInfoStateSpecific
                                  .value
                              }
                              total={0}
                              actual={0}
                              id="Home_TrackMySpending_Pharmacy0"
                            />
                            <ProgressBar
                              title={fields.lblAnnual}
                              description={
                                list[0]?.fields?.lblAnnualInfoStateSpecific
                                  .value
                              }
                              total={0}
                              actual={0}
                              id="Home_TrackMySpending_Annual0"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {showFamilySpending && (
                      <div className="col-xl-6 col-lg-12">
                        <h5 className="card-title family-spending">
                          <Text field={fields.headingTwo} />
                        </h5>
                        <div className="molina-progress-bar-list">
                          {accum?.DeductibleFamilyMedicalLimit > 0 && (
                            <ProgressBar
                              title={fields.lblMedical}
                              description={
                                list[0]?.fields?.lblMedicalInfoStateSpecific
                                  .value
                              }
                              total={accum.DeductibleFamilyMedicalLimit}
                              actual={accum.DeductibleFamilyMedicalAccumValue}
                              id="Home_FamilySpending_Medical"
                            />
                          )}
                          {accum?.DeductibleFamilyPharmacyLimit > 0 && (
                            <ProgressBar
                              title={fields.lblPharmacy}
                              description={
                                list[0]?.fields?.lblPharmacyInfoStateSpecific
                                  .value
                              }
                              total={accum.DeductibleFamilyPharmacyLimit}
                              actual={accum.DeductibleFamilyPharmacyAccumValue}
                              id="Home_FamilySpending_Pharmacy"
                            />
                          )}
                          {accum?.FamilyLimit > 0 && (
                            <ProgressBar
                              title={fields.lblAnnual}
                              description={
                                list[0]?.fields?.lblAnnualInfoStateSpecific
                                  .value
                              }
                              total={accum.FamilyLimit}
                              actual={accum.FamilyAccumValue}
                              id="Home_FamilySpending_Annual"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="track-msg-tip">
                        <Text field={fields.description1} />{" "}
                        {getFormattedDate(date, "mm/dd/yyyy")}
                        {". "}
                        <Text field={fields.description2} />
                      </div>
                    </div>
                  </div>
                </div>
                <ProcedureCostEstimator
                  fields={fields}
                  columns={showFamilySpending ? 4 : 6}
                  showFamilySpending={showFamilySpending}
                  list={list}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TrackMySpending;
