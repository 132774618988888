import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Placeholder,
  VisitorIdentification,
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import IdleTimer from "react-idle-timer";

// Import the context providers
import { AppProvider, AppContext } from "./contexts/app.context";
import { SpinnerProvider } from "./contexts/spinner.context";
import { ErrorProvider } from "./contexts/error.context";
import { withModalProvider } from "./hoc/withModalProvider";
import { ModalContext } from "./contexts/modal.context";
import {
  SESSION_COOKIE_TIMEOUT,
  IDLE_ERROR,
  IDLE_TIMEOUT,
  IDLE_POPUP_BUTTON,
  SESSION_EXPIRE,
  TIMEOUT_VALUE_PORTAL,
  TIMEOUT_VALUE_POPUP,
} from "./define.constants";
import { getConfigByKey, onSecuredPage, redirectTo } from "./utils/helpers";
import { SitecoreContextReactContext } from "@sitecore-jss/sitecore-jss-react";
import { handleLogout } from "./utils/helpers";
import { ThemeProvider } from "./contexts/theme.context";

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route }) => {
  //let isValidUser = localStorage.getItem("isValidUser");
  const [sessionTimeoutMessage, setSessionTimeoutMessage] = useState("");
  const { openModal, closeModal } = useContext(ModalContext);
  const idleTimerRef = useRef(null);
  const idleTimerRefPopup = useRef(null);
  const sessionExpireRef = useRef(null);
  const {
    context: {
      route: { placeholders },
    },
  } = useContext(SitecoreContextReactContext);
  const { isSupporUser } = useContext(AppContext);
  // let timeoutValuePortal =
  //   (SESSION_COOKIE_TIMEOUT -
  //     getConfigByKey(IDLE_TIMEOUT, placeholders?.["jss-config"])) *
  //   1000;
  // let timeoutValuePopup = POPUP_TIMEOUT * 1000;
  const timeoutValuePortal = parseInt(
    process.env.TIMEOUT_VALUE_PORTAL != undefined
      ? process.env.TIMEOUT_VALUE_PORTAL
      : TIMEOUT_VALUE_PORTAL
  );

  const timeoutValuePopup = parseInt(
    process.env.TIMEOUT_VALUE_POPUP != undefined
      ? process.env.TIMEOUT_VALUE_POPUP
      : TIMEOUT_VALUE_POPUP
  );
  let finalLayout = (
    <AppProvider>
      <ThemeProvider>
        <Placeholder name="jss-config" rendering={route} />
        <Placeholder name="jss-header-alerts" rendering={route} />
        <Placeholder name="jss-header" rendering={route} />
        <div
          className={`${
            route.displayName === "MyMessages" ||
            route.displayName === "CreateNewMessage"
              ? "container-fluid p-0"
              : "container"
          }`}
        >
          <Placeholder name="jss-main" rendering={route} />
        </div>
        <Placeholder name="jss-language-info" rendering={route} />
        <Placeholder name="jss-footer" rendering={route} />
      </ThemeProvider>
    </AppProvider>
  );
  //if (isValidUser) location = "/";

  const handleExtendSession = (e) => {
    closeModal();
    idleTimerRef.current.reset();
    idleTimerRefPopup.current.reset();
  };
  const onIdlePortal = () => {
    setSessionTimeoutMessage(
      getConfigByKey(IDLE_ERROR, placeholders?.["jss-config"])
    );
  };
  const onIdlePopup = () => {
    localStorage.removeItem("chatEnable");
    Genesys("command", "Messenger.close");
    Genesys("command", "MessagingService.clearConversation");
    handleLogout(isSupporUser);
  };

  useEffect(() => {
    Genesys("subscribe", "Messenger.opened", function () {
      if (
        sessionStorage.getItem("browserClosed") === null ||
        localStorage.getItem("chatEnable") === null
      ) {
        Genesys("command", "Messenger.close");
        sessionStorage.setItem("chatClosed", "true");
      }

      let browserClosed = sessionStorage.getItem("browserClosed");
      let chatEnable = localStorage.getItem("chatEnable");
      let chatClosed = sessionStorage.getItem("chatClosed");
      if (
        (browserClosed === null || chatEnable === null) &&
        (chatClosed === "true" || chatEnable === "false")
      ) {
        sessionStorage.setItem("chatClosed", "false");
        localStorage.removeItem("chatEnable");
        setTimeout(() => {
          Genesys("command", "MessagingService.clearConversation");
        }, 5000);
      }
    });

    let browserClosed = sessionStorage.getItem("browserClosed");
    let chatEnable = localStorage.getItem("chatEnable");
    let chatClosed = sessionStorage.getItem("chatClosed");
    if (
      (browserClosed === null || chatEnable === null) &&
      (chatClosed === "true" || chatEnable === "false")
    ) {
      localStorage.removeItem("chatEnable");
      setTimeout(() => {
        Genesys("command", "MessagingService.clearConversation");
      }, 5000);
    }

    if (onSecuredPage() && sessionTimeoutMessage) {
      const content = (
        <IdleTimer
          ref={idleTimerRefPopup}
          timeout={timeoutValuePopup}
          onIdle={onIdlePopup}
        >
          <div className="update-header">
            <p className="text-center">{sessionTimeoutMessage}</p>
            <div className="text-center">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  handleExtendSession(e);
                }}
              >
                {getConfigByKey(
                  IDLE_POPUP_BUTTON,
                  placeholders?.["jss-config"]
                )}
              </button>
            </div>
          </div>
        </IdleTimer>
      );
      openModal({
        content,
        version: 3,
      });
      setSessionTimeoutMessage("");
    }
  }, [sessionTimeoutMessage]);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields &&
            route.fields.pageTitle &&
            route.fields.pageTitle.value) ||
            "Page"}
        </title>
      </Helmet>

      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.
  
        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      {/* <VisitorIdentification /> */}

      {/* root placeholder for the app, which we add components to using route data */}
      <IdleTimer
        ref={idleTimerRef}
        timeout={timeoutValuePortal}
        onIdle={onIdlePortal}
      >
        <ErrorProvider>
          <SpinnerProvider>{finalLayout}</SpinnerProvider>
        </ErrorProvider>
      </IdleTimer>
    </React.Fragment>
  );
};

export default withModalProvider(Layout);
