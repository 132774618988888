import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const DisplayTitleItemCH = (props) => {
  const { fieldName, item, colClass } = props;
  return (
    <div className={`${colClass}`}>
      {fieldName && <Text field={fieldName} />}
      {item}
    </div>
  );
};

export default DisplayTitleItemCH;
