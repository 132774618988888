import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "./../../contexts/app.context";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SpinnerContext } from "../../contexts/spinner.context";
import { useViewContactPreferences } from "../../hooks/useViewContactPreferences";
import { useViewCommunicationPreferences } from "../../hooks/useViewCommunicationPreferences";
import { ACCOUNT_SETTINGS_MY_PROFILE } from "../../define.widgets";

function Communication(props) {
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  let { prefPhone, homePhone, cellPhone, profileList } = useContext(AppContext);
  const { setActiveTab, tabOptions } = props;
  const accountDetailMyProfile = props?.fields?.MyProfileList[0] ?? null;
  const initialValues = {
    homePhone: homePhone,
  };
  const { communicationData } = useViewCommunicationPreferences();
  const { contactData, refreshMSGInfoAll, isViewContactLoaded } =
    useViewContactPreferences();
  let preferredPhone = "";
  let preferredEmail = "";

  communicationData?.LsMemberAPIInfo?.phoneNumbers?.map((data) => {
    if (data.IsDefault == true) {
      preferredPhone = data.phoneNumber;
    }
  });

  communicationData?.LsMemberAPIInfo?.Emails?.map((data) => {
    if (data.IsDefault == true) {
      preferredEmail = data.EmailAddress;
    }
  });
  preferredPhone == "" ? homePhone ?? "" : preferredPhone;
  let updatedHomePhone = UpdatedPhoneNumber(preferredPhone);
  let { isTermedMember } = useContext(AppContext);
  function UpdatedPhoneNumber(phoneNumber) {
    const strPhone = phoneNumber?.replace(/[()]/g, "");
    const formattedPhone = strPhone?.replace(/\s/g, "-");
    return formattedPhone;
  }
  const onSubmit = (values, submitProps) => {
    submitProps.setSubmitting(false);
  };
  const validationSchema = Yup.object({
    prefEmailId: Yup.string().required("Required"),
    prefPhone: Yup.string().required("Required"),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <li className="list-group-item">
                {innerSpinnerHTML?.[ACCOUNT_SETTINGS_MY_PROFILE]}
                <div className="row">
                  <div className="col-xl-9 col-md-8">
                    <h5 className="tab-sub-title">
                      <Text
                        field={
                          accountDetailMyProfile?.fields?.communicationTitle ??
                          ""
                        }
                      />
                    </h5>
                  </div>
                  <div className="col-xl-3 col-md-4">
                    <button
                      type="button"
                      className={
                        isTermedMember
                          ? "btn btn-primary update-button disabled"
                          : "btn btn-primary update-button"
                      }
                      onClick={(e) => setActiveTab(e, 2, tabOptions[2]?.value)}
                    >
                      <label htmlFor="updateButtonText">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.updateButtonText
                          }
                        />
                      </label>
                    </button>
                  </div>
                </div>
                <div className="row field1 common-input">
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="genderIdentity">
                        <Text
                          field={accountDetailMyProfile?.fields?.genderIdentity}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="genderIdentity"
                        name="genderIdentity"
                        value={
                          contactData?.IsContactPreferences?.GenderIdentity
                        }
                      >
                        {contactData?.IsContactPreferences?.GenderIdentity}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="preferredPronoun">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.preferredPronoun
                          }
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="preferredPronoun"
                        name="preferredPronoun"
                        value={contactData?.IsContactPreferences?.Pronoun}
                      >
                        {contactData?.IsContactPreferences?.Pronoun}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="sexualOrientation">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.sexualOrientation
                          }
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="sexualOrientation"
                        name="sexualOrientation"
                        value={
                          contactData?.IsContactPreferences?.SexualOrientation
                        }
                      >
                        {contactData?.IsContactPreferences?.SexualOrientation}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="ethnicity">
                        <Text
                          field={accountDetailMyProfile?.fields?.ethnicity}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="ethnicity"
                        name="ethnicity"
                        value={contactData?.IsContactPreferences?.RaceEthnicity}
                      >
                        {contactData?.IsContactPreferences?.RaceEthnicity}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="preferredLanguage">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.preferredLanguage
                          }
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="preferredLanguage"
                        name="preferredLanguage"
                        value={
                          contactData?.IsContactPreferences?.PrimaryLanguage
                        }
                      >
                        {contactData?.IsContactPreferences?.PrimaryLanguage}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-none d-sm-none d-xl-none d-lg-block d-md-block">
                    <div className="row-title">
                      <label htmlFor="preferredPhone">
                        <Text
                          field={accountDetailMyProfile?.fields?.preferredPhone}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      {!homePhone == "" && <div>{updatedHomePhone}</div>}
                    </div>
                  </div>
                </div>
                {/* <div className="row common-input">
                  <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="row-title">
                      <Text
                        field={accountDetailMyProfile?.fields?.preferredPhone}
                      />
                    </div>
                    <div className="row-details">
                      {!homePhone == "" && <div>{updatedHomePhone}</div>}
                    </div>
                  </div>
                  <div className="col-xxl-4 col-lg-8 col-md-8 col-sm-12 button-space">
                    <div className="row-title">
                      <Text
                        field={
                          accountDetailMyProfile?.fields?.preferredEmail ?? ""
                        }
                      />
                    </div>
                    <div className="row-details">
                      <div>{profileList.Email}</div>
                    </div>
                  </div>
                </div> */}
                <div className="row field1 common-input">
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12 d-lg-none d-md-none d-xl-block">
                    <div className="row-title">
                      <label htmlFor="preferredPhoneNumber">
                        <Text
                          field={accountDetailMyProfile?.fields?.preferredPhone}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      {!homePhone == "" && <div>{updatedHomePhone}</div>}
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-5 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="preferredEmail">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.preferredEmail ?? ""
                          }
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div>
                        {preferredEmail == ""
                          ? profileList.Email
                          : preferredEmail}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-1 col-md-6 col-sm-12">
                    <Text />
                  </div>
                  <div className="col-xxl-2 col-xl-1 col-md-6 col-sm-12">
                    <Text />
                  </div>
                  <div className="col-xxl-2 col-xl-1 col-md-6 col-sm-12 button-space">
                    <Text />
                  </div>
                </div>
              </li>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default Communication;
