import React, { useState, useContext, useEffect } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { getStateConfiguration } from "../../utils/helpers";
import LanguageInfoLink from "./LanguageInfoLink";
import { AppContext } from "../../contexts/app.context";
import NonMarketplaceLanguage from "./NonMarketplaceLanguage";
import NonMarketplaceLanguageNonDis from "./NonMarketPlaceLanguageNonDis";
import * as CommonAPI from "../../services/Common";
import {
  MARKETPLACE,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  SPANISH_LANG_CODE,
  MEDICAID,
  NY,
  GLOBAL_VARIABLE,
} from "../../define.constants";
const HomepageLanguageInformation = (props) => {
  let { memberLOB, MemberStateName, prefLang } = useContext(AppContext);
  const languageInfoList = getStateConfiguration(
    props?.fields,
    MemberStateName,
    memberLOB
  );

  const { fields, selectedYear, index } = props;

  const [distItem, setDistItem] = useState(null);

  const ismedicaid =
    MEDICAID.toLowerCase() == memberLOB.toLowerCase() && MemberStateName == NY;

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  useEffect(() => {
    if (prefLang !== "" && prefLang !== null) {
      const langcode =
        SPANISH_LANG_TEXT.toLowerCase() === prefLang?.toLowerCase() ||
        SPANISH_LANG_CODE.toLocaleLowerCase() === prefLang?.toLocaleLowerCase()
          ? SPANISH_LANG_CODE
          : ENGLISH_LANG_CODE;
      CommonAPI.getDictionaryItems(langcode)
        .then((response) => {
          setDistItem(response);
        })
        .catch(function (error) {
          console.log(
            "Error occured while fetching data from dictionary",
            error
          );
        });
    }
  }, [prefLang]);
  return (
    <div className="lang-info-container container">
      <div className="lang-info-heading">
        {getFieldValue(languageInfoList?.fields, "heading")}
      </div>
      <div className="lang-info-text">
        {getFieldValue(languageInfoList?.fields, "description")}
      </div>
      <div className="row lang-link-wrapper">
        {ismedicaid ? (
          <NonMarketplaceLanguageNonDis
            selectedYear={selectedYear}
            index={index}
            distItem={distItem}
            fields={fields}
            languageInfoList={languageInfoList}
          />
        ) : (
          <p className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <LanguageInfoLink
              text={
                getFieldValue(languageInfoList?.fields, "nonDiscriminationLink")
                  ?.text
              }
              link={languageInfoList?.fields?.nonDiscriminationLink}
              image={languageInfoList?.fields?.nonDiscriminationImage}
            />
          </p>
        )}
        {memberLOB.toLocaleLowerCase() == MARKETPLACE.toLocaleLowerCase() ? (
          <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
            <LanguageInfoLink
              text={
                getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
              }
              link={languageInfoList?.fields?.multiLangLink}
              image={languageInfoList?.fields?.multiLangImage}
            />
          </p>
        ) : (
          <NonMarketplaceLanguage
            selectedYear={selectedYear}
            index={index}
            distItem={distItem}
            fields={fields}
            languageInfoList={languageInfoList}
          />
        )}
      </div>
    </div>
  );
};

export default HomepageLanguageInformation;
