import React, { useState } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH } from "../../define.constants";
import { Calendar } from "../../common/Calendar";
import { useCalendar } from "../../hooks/useCalendar";
import { INBOX, ARCHIVE } from "../../define.constants";

const Sort = ({ fields, onSort, onFilter, messageTabType }) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const { selectedDate, setSelectedDate } = useCalendar();
  const [sortBy, setSortBy] = useState("Date");
  const [anchorEl, setAnchorEl] = useState();

  const handleSort = (e) => {
    e.preventDefault();
    setSortBy(e.currentTarget.dataset.sortkey);
    onSort(e.currentTarget.dataset.sortkey);
    if (e.currentTarget.dataset.sortkey === "Date") {
      setAnchorEl(e.currentTarget);
      setDatePickerOpen(!datePickerOpen);
      e.stopPropagation();
    }
  };

  const handleSelectAllMsgs = (e) => {
    if (typeof window !== "undefined") {
      document?.getElementsByName("message_chk").forEach((chk) => {
        chk.checked = e.currentTarget.checked;
      });
    }
  };

  const onDateSelect = (selectedDate) => {
    setSelectedDate(selectedDate);
    setDatePickerOpen(!datePickerOpen);
    onFilter(selectedDate, true);
  };

  return (
    <div className="col-xl-3 col-md-8 col-sm-3">
      <a
        className="sort-by dropdown-toggle"
        href="#"
        id="sort-by-msg"
        data-mdb-toggle="dropdown"
      >
        <img
          className="state-color"
          src={`${ICON_PATH}/sort-by.svg`}
          alt={getFieldValue(fields, "lblSort")}
        />
        <span>
          <Text field={fields.lblSort} />
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-right dropdown-default">
        {[
          { item: "Date", label: fields.lblDate },
          { item: "Unread", label: fields.lblUnread },
          { item: "Subject", label: fields.lblSubject },
        ].map(({ item, label }) => {
          return (
            <a
              key={item}
              className={`dropdown-item ${sortBy === item && "active"}`}
              href="#"
              onClick={(e) => handleSort(e)}
              data-sortkey={item}
            >
              <img
                className="dropdown-icon"
                src={`${ICON_PATH}/tick-teal.svg`}
                alt={getFieldValue(fields, label)}
              />
              <span>
                <Text field={label} />
              </span>
            </a>
          );
        })}
        {datePickerOpen && (
          <Calendar
            isOpen={datePickerOpen}
            onClose={() => setDatePickerOpen(!datePickerOpen)}
            showInput={false}
            handleChange={onDateSelect}
            value={selectedDate}
            anchor={anchorEl}
          />
        )}
      </div>
      {messageTabType !== ARCHIVE && (
        <span className="select-all">
          <span>
            <Text field={fields.lblSelect} />
          </span>
          <span className="checkbox-img">
            <input
              id="SelectAll"
              type="checkbox"
              name="SelectAll"
              value="1"
              onClick={handleSelectAllMsgs}
              aria-label={getFieldValue(fields, "lblSelect")}
            />
            <label htmlFor="SelectAll"></label>
          </span>
        </span>
      )}
    </div>
  );
};

export default Sort;
