import React, { useContext } from "react";
import { Text, Link, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { SUPPORT_USER, TERMED_MEMBER } from "../../define.constants";
import {
  isAccessibleToSupportUser,
  isAccessibleToTermedMember,
} from "../../utils/helpers";

const Description = ({
  isReadMore,
  alert,
  maxChar,
  fields,
  handleReadMore,
  handleDismiss,
  handleView,
}) => {
  const { redirectToSSO } = useContext(SSORedirectContext);
  const {
    readMore,
    readLess,
    dismiss,
    btnchangepcp,
    btnviewidcard,
    btndownloadapp,
    btnview,
  } = fields;
  const getDescription = getFieldValue(alert, "description", false)?.href
    ? getFieldValue(alert, "description", false).text
    : getFieldValue(alert, "description", false);
  const getTitle = getFieldValue(alert, "title", false);
  const getLink = getFieldValue(alert, "link", false)?.href
    ? getFieldValue(alert, "link", false)?.text
    : "";
  const totalChars =
    getDescription?.length + getTitle?.length + getLink?.length;
  const ssoEndPoint = getFieldValue(alert, "ssoEndPoint", false);
  const CDPAlertAction = ({ alertid, link }) => {
    let btnText = "";
    let redirectUrl = "";
    switch (alertid) {
      case "cdp_UpdatePCP":
        btnText = btnchangepcp;
        break;
      case "cdp_DigitalIDCard":
        btnText = btnviewidcard;
        break;
      case "cdp_DownloadApp":
        btnText = btndownloadapp;
        break;
      default:
        btnText = btnview;
    }
    return (
      <button
        type="button"
        className="btn btn-secondary ripple-surface cdp-btn"
        onClick={() =>
          handleView({
            id: alert.id,
            link: link,
          })
        }
      >
        <Text field={btnText} />
      </button>
    );
  };

  return (
    <span className="renew-discription state-color-txt">
      {totalChars > maxChar && !isReadMore ? (
        <>
          {getDescription
            .substr(0, maxChar - getTitle?.length - getLink?.length)
            .trim()}
          <a
            className="card-link-read-more text-decoration-underline"
            onClick={() =>
              handleReadMore({
                id: alert.id,
                showMore: !isReadMore,
              })
            }
          >
            <Text field={readMore} />
          </a>
        </>
      ) : (
        <>
          {ssoEndPoint ? (
            <WhoIsAuthorized
              type="link"
              href="#"
              authorize={{
                [SUPPORT_USER]: isAccessibleToSupportUser(ssoEndPoint),
                [TERMED_MEMBER]: isAccessibleToTermedMember(ssoEndPoint),
              }}
              handleClick={(e) =>
                redirectToSSO({
                  e,
                  url: constants.apigeeURLs[ssoEndPoint],
                })
              }
            >
              {({ handleClick, disabledClass, href }) => {
                return (
                  <a
                    href={href}
                    className={`alert-text-zoom state-color-txt text-decoration-underline ${disabledClass}`.trim()}
                    onClick={handleClick}
                  >
                    <Text field={alert.description} />
                  </a>
                );
              }}
            </WhoIsAuthorized>
          ) : getFieldValue(alert, "description", false)?.href ? (
            <Link
              className="alert-text-zoom state-color-txt text-decoration-underline"
              field={alert.description}
            />
          ) : (
            <Text field={alert.description} />
          )}
          <span className="alert-continued-title">
            <Text field={alert.continuedTitle} />
          </span>
          {isReadMore && !alert.id.includes("cdp_") && (
            <a
              className="card-link-read-more"
              onClick={() =>
                handleReadMore({
                  id: alert.id,
                  showMore: !isReadMore,
                })
              }
            >
              <Text field={readLess} />
            </a>
          )}
          {alert.id == "cdp_UpdatePCP" && (
            <button
              type="button"
              className="btn btn-secondary ripple-surface cdp-btn"
              onClick={() =>
                handleDismiss({
                  id: alert.id,
                })
              }
            >
              <Text field={dismiss} />
            </button>
          )}
          {alert.id.includes("cdp_") && (
            <CDPAlertAction alertid={alert.id} link={alert.link} />
          )}
        </>
      )}
    </span>
  );
};

export default Description;
