import React from "react";
import { renderStatusCss, renderStatus } from "./Helper";

const Status = (props) => {
  const { status, fields, outerClass } = props;

  return (
    <div className={`col-xl-2 col-md-4 col-6 history-details ${outerClass}`}>
      <div className={`history-status ${renderStatusCss(status)}`}>
        {renderStatus(status, fields)}
      </div>
    </div>
  );
};

export default Status;
