import React from "react";
import { ICON_PATH } from "../../define.constants";

const CarePlanMobile = (props) => {
  const { carePlan, altText } = props;
  const downloadPDF = (name) => {
    const fileName = `${name}.pdf`;
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/octet-stream;base64," + encodeURIComponent(carePlan)
    );
    element.setAttribute("download", fileName);
    element.setAttribute("target", "_blank");
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div className="d-block d-md-none">
      <img
        src={`${ICON_PATH}/Group_2641.svg`}
        alt={altText.value}
        onClick={() => downloadPDF(altText.value)}
      />
    </div>
  );
};
export default CarePlanMobile;
