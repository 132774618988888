import React, { useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import * as Yup from "yup";
import StepProgressBar from "./StepProgressBar";
import { MultiStepContext } from "../../contexts/Steps.context";
import { InlineFormError } from "../../common/InlineFormError";
import { ICON_PATH, LoginPageUrl } from "../../define.constants";
import { handleLogin, highlightBorderOnError } from "../../utils/helpers";

const ActivateAccount = (props) => {
  const { setStep, step2Data, setStep2Data } = useContext(MultiStepContext);
  const { fields } = props;
  return (
    <>
      <div className="Registration">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <section className="d-flex justify-content-center">
              <div className="card lg-card">
                <div className="card-body">
                  <StepProgressBar fields={fields} />
                  {/* <!-- step4 --> */}
                  <div className="member-info" style={{ textAlign: "center" }}>
                    <p style={{ textAlign: "center" }}>
                      <Text field={fields.regCompleted} />
                    </p>
                    <div className="security-code">
                      <Text field={fields.regCompletedMsg} />
                    </div>
                    <div className="registration-btn">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleLogin}
                      >
                        <Text field={fields.backToLoginButton} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivateAccount;
