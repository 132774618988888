import React, { useContext, useState, useCallback, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Formik, Form, Field } from "formik";
import { AppContext } from "../../contexts/app.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ACCOUNT_SETTINGS_MY_PROFILE } from "../../define.widgets";
import { useLocation } from "react-router-dom";
import * as UpdateLanguageAPI from "../../services/UpdateLanguageAPI";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  ENGLISH_LANG_CODE,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  ENGLISH_LANG_TEXT,
} from "../../define.constants";
import { ErrorContext } from "../../contexts/error.context";
import { SUPPORT_USER, TERMED_MEMBER } from "../../define.constants";

function Language(props) {
  let { prefLang } = useContext(AppContext);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  let loc = useLocation();
  const langURL = `${loc?.pathname
    .replace(`/${SPANISH_LANG_CODE}`, "")
    .replace(`/${ENGLISH_LANG_CODE}`, "")}`;

  let transLang = prefLang?.toLowerCase();
  let formLang = "";
  if (
    transLang === SPANISH_LANG_TEXT.toLowerCase() ||
    transLang === SPANISH_LANG_CODE.toLowerCase()
  ) {
    formLang = SPANISH_LANG_TEXT;
  } else {
    formLang = ENGLISH_LANG_TEXT;
  }

  const accountDetailMyProfile = props?.fields?.MyProfileList[0] ?? null;
  let selectedLanguage = "";

  const setUserSelectedLanguage = useCallback(
    (selectedLanguage) => {
      setSpinner(true);
      Genesys("command", "Messenger.close");
      UpdateLanguageAPI.getUpdateLanguage({
        MemLanguage: selectedLanguage,
      })
        .then((response) => {
          setSpinner(false);
          if (response?.Message === "Success") {
            location.href = `/${selectedLanguage?.toLowerCase()}${langURL}`;
          }
        })
        .catch((err) => {
          setSpinner(false);
          setError({ err });
        });
    },
    [setSpinner, langURL]
  );
  const initialValues = {
    picked: formLang,
  };
  return (
    <div>
      <Formik
        enableReinitialize="true"
        initialValues={initialValues}
        onSubmit={(values) => {
          selectedLanguage =
            values?.picked === SPANISH_LANG_TEXT
              ? SPANISH_LANG_CODE
              : ENGLISH_LANG_CODE;
          setTimeout(() => {
            setUserSelectedLanguage(selectedLanguage);
          }, 500);
        }}
      >
        {({ handleSubmit }) => (
          <Form>
            <li className="list-group-item ">
              {innerSpinnerHTML?.[ACCOUNT_SETTINGS_MY_PROFILE]}
              <div className="row">
                <div className="col-lg-8 col-md-6">
                  <h5 className="tab-sub-title">
                    <Text
                      field={
                        accountDetailMyProfile?.fields?.languageTitle ?? ""
                      }
                    />
                  </h5>
                </div>
                <div className="col-lg-4 col-md-6">
                  <WhoIsAuthorized
                    authorize={{
                      [SUPPORT_USER]: false,
                      [TERMED_MEMBER]: true,
                    }}
                    handleClick={handleSubmit}
                  >
                    {({ handleClick, disabledClass }) => {
                      return (
                        <button
                          type="button"
                          className={`btn btn-primary save-button ${disabledClass}`.trim()}
                          onClick={handleClick}
                        >
                          <Text
                            field={
                              accountDetailMyProfile?.fields?.saveButtonText ??
                              ""
                            }
                          />
                        </button>
                      );
                    }}
                  </WhoIsAuthorized>
                </div>
              </div>
              <div role="group" className="row" id="LangNavSection">
                <div className="col-md-4 col-sm-12">
                  <div className="form-check myprofile-radio">
                    <Field
                      type="radio"
                      name="picked"
                      value={ENGLISH_LANG_TEXT}
                      id="englishChecked"
                      className="form-check-input"
                    />
                    <label className="label-radio" htmlFor="english">
                      <Text
                        field={
                          accountDetailMyProfile?.fields
                            ?.englishLanguageLabel ?? ""
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className=" col-md-4 col-sm-12">
                  <div className="form-check myprofile-radio">
                    <Field
                      type="radio"
                      name="picked"
                      value={SPANISH_LANG_TEXT}
                      id="spanishunchecked"
                      className="form-check-input"
                    />
                    <label
                      className="label-radio button-space"
                      htmlFor="spanish"
                    >
                      <Text
                        field={
                          accountDetailMyProfile?.fields
                            ?.spanishLanguageLabel ?? ""
                        }
                      />
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Language;
