import React, { useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import {
  ICON_PATH,
  SUPPORT_USER,
  TERMED_MEMBER,
  MARKETPLACE,
  MEDICAID,
  IL,
  CA,
  CA_PROGID_LIST_HIDE_MYDOC,
  IL_MLTSS_PROGRAM,
  GLOBAL_VARIABLE,
  NE,
  NM,
  NM_MEDICAID_ProgramID,
  TX,
} from "../../define.constants";
import { AppContext } from "../../contexts/app.context";
import { disableSSO, getFormattedDate } from "../../utils/helpers";
import { InnerSpinner } from "../../common/Spinner";
import {
  SSORedirectContext,
  SSORedirectProvider,
} from "../../contexts/SSORedirect.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

const HomeMyDetails = (props) => {
  const {
    planName,
    memberId,
    doctorName,
    planTerminationDt,
    memberRKSID,
    primaryCaseManager,
    memberLOB,
    enrollmentEffDate,
    RedeterminationDate,
    isTermedMember,
    programId,
    MemberStateName,
    ServiceCoordinatorPhoneNumber,
  } = useContext(AppContext);
  const termDate = planTerminationDt && new Date(planTerminationDt);
  const effDate = enrollmentEffDate && new Date(enrollmentEffDate);
  const redeterminationDate =
    RedeterminationDate && new Date(RedeterminationDate);
  const { fields } = props;
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");
  const isTXMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID.toLowerCase() &&
    MemberStateName === TX;

  //Showing Redetermination only for NonMp - Medicaid
  const showRedeterminationDate =
    RedeterminationDate && memberLOB?.toLowerCase() === MEDICAID?.toLowerCase()
      ? true
      : false;

  //Primary case manager flag to show or hide CaseManager
  const showCaseManager =
    !isMarketplaceMember &&
    primaryCaseManager != null &&
    primaryCaseManager?.length > 0
      ? true
      : false;

  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID?.toLowerCase();

  /* Medthod returning flag to Hide MyDoctor for 
  IL - MLTSS Medicaid
  CA - EAE Medicaid
  CA - MMP Opt Out & Dual Opt Out converted to Medicaid ProgIDs
  */
  const showMyDoctor = () => {
    let showMyDoctorFlag = true;
    // Medicaid Common check
    const isMedicaidCommonCheck = isMedicaidMember && programId;
    const trimmedProgramID = programId?.trim();

    // State & ProgramID wise check to hide MyDoctor
    if (isMedicaidCommonCheck) {
      switch (MemberStateName.trim()) {
        case CA:
          showMyDoctorFlag = CA_PROGID_LIST_HIDE_MYDOC.includes(
            trimmedProgramID
          )
            ? false
            : true;
          break;
        case IL:
          showMyDoctorFlag =
            trimmedProgramID === IL_MLTSS_PROGRAM ? false : true;
          break;
        default:
          showMyDoctorFlag = true;
      }
    }

    return showMyDoctorFlag;
  };
  let RenewalDate =
    MemberStateName?.toLowerCase() === NE?.toLowerCase()
      ? fields.lblRenewalDate
      : fields.lblRedeterminationDate;
  let CareCoordinator =
    MemberStateName?.toLowerCase() === NM?.toLowerCase() &&
    programId === NM_MEDICAID_ProgramID &&
    memberLOB.toLowerCase() === MEDICAID
      ? fields.CareCoordinator
      : fields.caseManager;
  return (
    <BoxContainer classForWidth={"col-xl-4 col-lg-6 col-sm-12"}>
      <SSORedirectProvider>
        <Box title={fields.heading} cardSize={"small"}>
          {!memberRKSID && <InnerSpinner />}
          <div className="row">
            {!isMarketplaceMember ? (
              <UserInfo
                label={fields.startDate}
                value={getFormattedDate(effDate, "mm/dd/yyyy")}
                sectionClass={"coverage-width"}
              />
            ) : (
              <UserInfo
                label={fields.endDate}
                value={getFormattedDate(termDate, "mm/dd/yyyy")}
                sectionClass={"coverage-width"}
              />
            )}
            <UserInfo label={fields.planName} value={planName} />
          </div>

          <div className="row">
            <UserInfo label={fields.memberId} value={memberId} />
            <UserInfo
              label={fields.myDoctor}
              value={doctorName}
              sectionClass={"card-details-width"}
              mainClass={"2"}
            />
          </div>
          {(showRedeterminationDate || showCaseManager) && (
            <div className="row">
              {showRedeterminationDate && (
                <UserInfo
                  label={RenewalDate}
                  value={getFormattedDate(redeterminationDate, "mm/dd/yyyy")}
                  sectionClass={"card-details-width"}
                  mainClass={"2"}
                />
              )}
              {showCaseManager && (
                <UserInfo
                  label={
                    isTXMedicaidMember
                      ? fields.ServiceCoordinatorName
                      : CareCoordinator
                  }
                  value={primaryCaseManager}
                  sectionClass={"card-details-width mobwidth"}
                  mainClass={"2"}
                />
              )}
              {isTXMedicaidMember && showCaseManager && (
                <UserInfo
                  label={fields.ServiceCoordinatorPhone}
                  value={ServiceCoordinatorPhoneNumber}
                />
              )}
            </div>
          )}

          <div className="row card-link-cta-block-2col align-items-end">
            {isMarketplaceMember ? (
              <LinkInfo
                href={getFieldValue(fields, "getIdCard")?.href}
                text={getFieldValue(fields, "getIdCard")?.text}
                cardInfo={fields.getIdCardInfo}
                sectionClass={"mobile-space"}
                isHref={true}
              />
            ) : (
              ""
            )}
            {showMyDoctor() && (
              <LinkInfo
                href={getFieldValue(fields, "changeDoctor")?.href}
                text={getFieldValue(fields, "changeDoctor")?.text}
                cardInfo={fields.changeDoctorInfo}
                sectionClass={"mobile-bottom"}
                isHref={true}
              />
            )}
          </div>
        </Box>
      </SSORedirectProvider>
    </BoxContainer>
  );
};

const UserInfo = (props) => {
  const mainClassName = props?.mainClass
    ? `col-md-6 col-sm-12 card-details-block${props?.mainClass}`
    : "col-md-6 col-sm-12 card-details-block";
  const sectionClassName = props?.sectionClass
    ? `card-desc-label ${props?.sectionClass}`
    : "card-desc-label";
  return (
    <div className={mainClassName}>
      <div className={sectionClassName}>
        <Text field={props.label} />
      </div>
      {props.txmedicaid ? (
        <div className="card-desc-value">
          <Text field={props.value} />
        </div>
      ) : (
        <div className="card-desc-value">{props.value}</div>
      )}
    </div>
  );
};

const LinkInfo = (props) => {
  const { fields } = props;
  const { redirectToSSO } = useContext(SSORedirectContext);
  const { isTermedMember } = useContext(AppContext);
  return (
    <div className="col-xxl-12 col-sm-12 molina-card-link ">
      <WhoIsAuthorized
        type="link"
        href={props.href}
        authorize={{
          [SUPPORT_USER]: true,
          [TERMED_MEMBER]: false,
        }}
        handleClick={(e) =>
          redirectToSSO({
            e,
            url: constants.apigeeURLs.SapphireChangePCPSSO,
          })
        }
      >
        {({ handleClick, disabledClass, href }) => {
          return (
            <span
              className={`cursor-pointer custom-tooltip ${
                !props.isHref ? disabledClass : ""
              }`.trim()}
            >
              <a
                {...(props.isHref ? { href } : {})}
                onClick={(e) => (!props.isHref && handleClick(e)) || {}}
                className={`card-link ${
                  (props?.sectionClass, !props.isHref ? disabledClass : "")
                }`.trim()}
              >
                {props.text}
                {(!isTermedMember || props.isHref) && (
                  <img
                    className="link-icon2"
                    src={`${ICON_PATH}/arrow-right.svg`}
                    alt={getFieldValue(fields, "rightArrowAlt")}
                  />
                )}
              </a>
              <span className="custom-tooltip-text">
                <Text field={props.cardInfo} />
              </span>
            </span>
          );
        }}
      </WhoIsAuthorized>
    </div>
  );
};

export default HomeMyDetails;
