import React from "react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import DisplayTitleItem from "../MyHealthRecord-Layout/Common/DisplayTitleItem";
import ReadMore from "../Common-Health-Records/ReadMore";

const ReferralsRecordsMobile = (props) => {
  const { fields, data } = props;
  return (
    <ul className="list-group d-sm-block d-md-none" id="recordsToPrint">
      {data?.map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="row">
            <DisplayTitleItem
              nameField={fields.lblReferralID}
              valueField={fields.lblReferralIDPrefix?.value + item.ReferralID}
              outClass={"col-list-title"}
            />

            <DisplayItem
              nameField={fields.lblReferredToSpecialty}
              valueField={item.Specialty}
              outClass={"col-md-3 details"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />

            <div id={"readMoreDetails" + index} className="">
              <ReadMore props={fields}>
                <DisplayItem
                  nameField={fields.lblReferredFrom}
                  valueField={item.ReferredFromProviderName}
                  outClass={"col-md-3 details"}
                  titleClass={"list-desc-title"}
                  detailsClass={"list-desc-details"}
                />
                <DisplayItem
                  nameField={fields.lblEffectiveDate}
                  valueField={item.EffectiveDate}
                  outClass={"col-md-3 details"}
                  titleClass={"list-desc-title"}
                  detailsClass={"list-desc-details"}
                />

                <DisplayItem
                  nameField={fields.lblStatus}
                  valueField={item.Status}
                  outClass={"col-md-3 details"}
                  titleClass={"list-desc-title"}
                  detailsClass={"list-desc-details"}
                />
                <DisplayItem
                  nameField={fields.lblExpirationDate}
                  valueField={item.ExpirationDate}
                  outClass={"col-md-3 details"}
                  titleClass={"list-desc-title"}
                  detailsClass={"list-desc-details"}
                />
                <DisplayItem
                  nameField={fields.lblNumberOfVisits}
                  valueField={item.NumberOfVisits}
                  outClass={"col-md-3 details"}
                  titleClass={"list-desc-title"}
                  detailsClass={"list-desc-details"}
                />

                <DisplayItem
                  nameField={fields.lblNumberOfVisitsAvailable}
                  valueField={item.NumberOfVisitsAvailable}
                  outClass={"col-md-3 details"}
                  titleClass={"list-desc-title"}
                  detailsClass={"list-desc-details"}
                />
                <div className="close-btn" id={"closeBtnDetails" + index}></div>
              </ReadMore>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ReferralsRecordsMobile;
