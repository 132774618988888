import React, { useEffect, useRef, useContext, useCallback } from "react";
import MySubscriptionCommunication from "./SubscriptionCommunication";
import SubscriptionContent from "./SubscriptionContent";
import SubscriptionNotification from "./SubscriptionNotification";
import Communication from "./Subscription";
import { withModalProvider } from "../../hoc/withModalProvider";
import { ModalContext } from "../../contexts/modal.context";
import { ErrorContext } from "../../contexts/error.context";

const AccountSettingsSubsriptions = (props) => {
  const { fields, setActiveTab, tabOptions } = props;

  const { setError } = useContext(ErrorContext);
  const { openModal, closeModal } = useContext(ModalContext);

  const EnableControls = (setValues, values) => {
    if (isEditable) {
      setIsEditable(false);
      setValues({ ...values, communicationPreferencesList });
    } else {
      setIsEditable(true);
    }
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="formsDocs"
            role="tabpanel"
            aria-labelledby="tab-1"
          >
            <div className="row no-tab-title">
              <div className="col-md-12">
                <ul className="list-group">
                  <div id="LangNavSection">
                    <Communication
                      fields={fields}
                      setActiveTab={setActiveTab}
                      tabOptions={tabOptions}
                    />
                  </div>
                  <div id="SubscriptionContent">
                    <SubscriptionContent
                      fields={fields}
                      setActiveTab={setActiveTab}
                      tabOptions={tabOptions}
                    />
                  </div>
                  <div id="SubscriptionNotification">
                    <SubscriptionNotification
                      fields={fields}
                      setActiveTab={setActiveTab}
                      tabOptions={tabOptions}
                    />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withModalProvider(AccountSettingsSubsriptions);
//export default AccountSettingsSubsriptions;
