import React, { useState, useContext } from "react";
import { DATE_MM_DD_YYYY, ICON_PATH } from "../../define.constants";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import TakeAssessment from "./TakeAssessment";
import { getFormattedDate } from "../../utils/helpers";
const AssessmentValidation = (props) => {
  let {
    memberName,
    dateOfBirth,
    homePhone,
    cellPhone,
    other,
    MemberStateName,
  } = useContext(AppContext);
  const [responderName, setResponderName] = useState("");
  const [relationshipToMember, setRelationshipToMember] = useState("");
  const [memberHealthcareID, setMemberHealthcareID] = useState("");
  const { rendering, backToMyWellness } = props;
  const assessmentValidationProps = props.fields;

  let assessmentDate = getFormattedDate(new Date(), DATE_MM_DD_YYYY);
  try {
    if (props?.assessment?.lastUpdated) {
      assessmentDate = getFormattedDate(
        props?.assessment?.lastUpdated,
        DATE_MM_DD_YYYY
      );
    }
  } catch (error) {
    console.log("error", error);
  }

  const UpdateResponderData = (respName, relToMem, memHcID) => {
    setResponderName(respName);
    setRelationshipToMember(relToMem);
    setMemberHealthcareID(memHcID);
  };

  const BindLabelValue = ({ label, value }) => {
    return (
      <>
        <div className="list-desc-title">
          <Text field={label} />
        </div>
        <div className="list-desc-details">{value}</div>
      </>
    );
  };
  return (
    <div className="row no-tab-title wellness-back">
      <div className="col-md-12">
        <div className="home-link">
          <div className="media">
            <img
              className="d-flex align-self-start left-arrow-custom"
              src={`${ICON_PATH}/icon-navigation-chevron_leftblack_24px.svg`}
              alt={getFieldValue(
                assessmentValidationProps,
                "leftarrowIcon",
                false
              )}
            />
            <div className="media-body">
              <span className="cursor-pointer" onClick={backToMyWellness}>
                <Text field={props?.fields?.backToList} />
              </span>
            </div>
          </div>
        </div>
        <ul className="list-group">
          <li className="list-group-item">
            <div className="row">
              <div className="col-lg-10 col-md-8">
                <h5 className="tab-sub-title">
                  <Text
                    field={
                      MemberStateName === "NE"
                        ? props?.fields?.NEhealthRiskAssessmentTitle
                        : props?.fields?.healthRiskAssessmentTitle
                    }
                  />
                </h5>
              </div>
              <div className="col-lg-2 col-md-4 text-right d-none">
                <div>
                  <img
                    src={`${ICON_PATH}/PlaceholderVideoGraphic.svg`}
                    aria-hidden="true"
                    alt={getFieldValue(
                      assessmentValidationProps,
                      "printIconAlt",
                      false
                    )}
                  ></img>{" "}
                  <Text field={props?.fields?.print} />
                </div>
              </div>
            </div>
            <div className="row field1">
              <div className="col-lg-2 col-md-6 col-sm-12">
                <BindLabelValue
                  label={props?.fields?.memberName}
                  value={memberName}
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <BindLabelValue
                  label={props?.fields?.responderName}
                  value={responderName}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <BindLabelValue
                  label={props?.fields?.relationshipToMember}
                  value={relationshipToMember}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <BindLabelValue
                  label={props?.fields?.memberHealthcareID}
                  value={memberHealthcareID}
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <BindLabelValue
                  label={props?.fields?.dateOfBirth}
                  value={dateOfBirth}
                />
              </div>
              <div className="col-md-6 d-none d-sm-none d-lg-none d-md-block">
                <BindLabelValue
                  label={props?.fields?.state}
                  value={MemberStateName}
                />
              </div>
            </div>
            <div className="row field2">
              <div className="col-lg-2 col-md-6 col-sm-12 d-md-none d-lg-block">
                <BindLabelValue
                  label={props?.fields?.state}
                  value={MemberStateName}
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 d-md-none d-lg-block">
                <BindLabelValue
                  label={props?.fields?.homePhone}
                  value={homePhone}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <BindLabelValue
                  label={props?.fields?.cellPhone}
                  value={cellPhone}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <BindLabelValue label={props?.fields?.other} value={other} />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 button-space">
                <BindLabelValue
                  label={props?.fields?.date}
                  value={assessmentDate}
                />
              </div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="row">
              <div className="col-lg-12">
                <h5 className="tab-sub-title">
                  <Text field={props?.fields?.takeAssessmentTitle} />
                </h5>
              </div>
              <div className="col-lg-12">
                <TakeAssessment
                  fields={props?.fields}
                  assessment={props.assessment}
                  updateResponderData={UpdateResponderData}
                  disabledPage={props.disabledPage}
                  backToMyWellness={props.backToMyWellness}
                  assessmentIDSpecificList={props.assessmentIDSpecificList}
                  assessments={props.assessments}
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default AssessmentValidation;
