import React, { createContext } from "react";
import { useModal } from "../hooks/useModal";
import { Modal } from "./../common/Modal";

const ModalContext = createContext({
  isOpen: false,
  header: "",
  content: "",
  footer: "",
  openModal: () => {},
  closeModal: () => {},
  version: 0,
});

function ModalProvider({ children }) {
  const { isOpen, header, content, footer, openModal, closeModal, version } =
    useModal();
  const context = {
    isOpen,
    header,
    content,
    footer,
    openModal,
    closeModal,
    version,
  };

  return (
    <ModalContext.Provider value={context}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
}

export { ModalContext, ModalProvider };
