import React, { useContext, useEffect, useState } from "react";

import { withModalProvider } from "../../hoc/withModalProvider";
import { auditPingMFA } from "../../services/AccountSettingsMfaAPI";
import {
  ADD_MFA_PING_WIDGET,
  CHANNEL_ID_FIDO,
  CHANNEL_ID_TOTP,
  FAILURE_RESPONSE,
  FIDO2_LABEL,
  SUCCESS_RESPONSE,
  TOTP_LABEL,
} from "../../define.constants";
import * as commonAPI from "../../services/Common";

const AddMFAWidget = (props) => {
  const userId = props.userId;
  const lang = props.lang;
  console.log("userId: ", userId);
  console.log("lang: ", lang);

  commonAPI
    .getWidgetEnv()
    .then((responseData) => {
      var props = {
        config: {
          method: "runFlow",
          apiRoot: responseData.apiRoot,
          accessToken: responseData.accessToken,
          companyId: responseData.companyId,
          policyId: responseData.policyId.AddMFA,
          parameters: {
            username: userId,
            lang: lang,
            userAuthenticated: true,
          },
        },
        useModal: false,
        successCallback,
        errorCallback,
        onCloseModal,
      };
      /*** Invoke the Widget ****/
      davinci.skRenderScreen(document.querySelector(".skWidgetModal"), props);
    })
    .catch((error) => console.log("error", error));
  //}
  //}, [props.userId]);

  const auditMFA = (data) => {
    let deviceType;
    switch (data.additionalProperties.p1DeviceType) {
      case CHANNEL_ID_TOTP:
        deviceType = TOTP_LABEL;
        break;
      case CHANNEL_ID_FIDO:
        deviceType = FIDO2_LABEL;
        break;
      default:
        deviceType = data.additionalProperties.p1DeviceType;
        break;
    }
    auditPingMFA({
      DeviceID: data.additionalProperties.p1DeviceId,
      DeviceType: deviceType,
      PingAPIStatus: data.success
        ? SUCCESS_RESPONSE.toUpperCase()
        : FAILURE_RESPONSE.toUpperCase(),
      Functionality: ADD_MFA_PING_WIDGET,
    });
  };

  function successCallback(response) {
    console.log("successCallback");
    console.log(response);
    // Update MFA List
    props.onAddSuccess();
    // Audit MFA
    auditMFA(response);
  }

  function errorCallback(error) {
    console.log("errorCallback");
    console.log(error);
    // Audit MFA
    auditMFA(error);
  }

  function onCloseModal() {
    console.log("onCloseModal");
  }
  //Widget End

  return (
    <>
      <div id="widget" className="skWidgetModal">
        Loading...
      </div>
    </>
  );
};

export default withModalProvider(AddMFAWidget);
