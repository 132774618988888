import React from "react";
import { dynamicControlConstants } from "../../utils/constants";
import * as Yup from "yup";
import { getFormattedDate } from "../../utils/helpers";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { VISIBLE_OPTION } from "../../define.constants";
export default function InitializeValueAndValidations(
  fields,
  question,
  initialValues,
  validationList,
  assessmentOptions,
  answerDetails,
  isFormReseted,
  isChild = false
) {
  let qId = question?.QuestionId;
  let parentId = question?.ParentQuestionId;
  let AspControlType = question?.AspControlType;
  let IsMandatory = question?.IsMandatory;
  let answerList = [];
  if (question?.IsVisibleOption !== VISIBLE_OPTION) {
    answerList = answerDetails;
    let answer = question?.DefaultValue == null ? "" : question?.DefaultValue;
    if (answerList?.length > 0) {
      let ans = answerList.filter((o) => o.QuestionId == qId);
      if (ans.length > 0)
        answer =
          ans[0]?.Answer === null || ans[0]?.Answer === "null"
            ? ""
            : ans[0]?.Answer;
    }
    answer = answer == undefined ? "" : answer;
    switch (AspControlType) {
      case dynamicControlConstants.date:
        let dtAnswer = answer.replace(/[/]/g, "-");
        if (isFormReseted && !question.IsReadOnly) dtAnswer = "";
        initialValues[`question_${qId}`] = dtAnswer;
        if (isChild == true) {
          validationList[`question_${qId}`] = Yup.date().when(
            `question_${parentId}`,
            {
              is: (parent) => checkCond(question, qId),
              then: Yup.date().required(
                getFieldValue(fields, "validationDateRequired", false)
              ),
              otherwise: Yup.date(),
            }
          );
        } else {
          if (IsMandatory)
            validationList[`question_${qId}`] = Yup.date().required(
              getFieldValue(fields, "validationDateRequired", false)
            );
          else validationList[`question_${qId}`] = Yup.date();
        }
        break;
      case dynamicControlConstants.radio:
      case dynamicControlConstants.select:
        if (
          assessmentOptions.filter((obj) => obj.QuestionId === qId).length > 0
        ) {
          if (isFormReseted && !question.IsReadOnly) answer = "[]";
          initialValues[`question_${qId}`] =
            answer === "[]" || answer === ""
              ? ""
              : JSON.parse(answer)?.length > 0
              ? JSON.parse(answer)[0]
              : answer;
          if (isChild == true) {
            validationList[`question_${qId}`] = Yup.string().when(
              `question_${parentId}`,
              {
                is: (parent) => checkCond(question, qId),
                then: Yup.string().required(
                  getFieldValue(fields, "validationSelectRequired", false)
                ),
                otherwise: Yup.string(),
              }
            );
          } else {
            if (IsMandatory)
              validationList[`question_${qId}`] = Yup.string().required(
                getFieldValue(fields, "validationSelectRequired", false)
              );
            else validationList[`question_${qId}`] = Yup.string();
          }
        }
        break;
      case dynamicControlConstants.checkbox:
        if (
          assessmentOptions.filter((obj) => obj.QuestionId === qId).length > 0
        ) {
          if (isFormReseted && !question.IsReadOnly) answer = "";
          initialValues[`question_${qId}`] =
            answer == "" ? [] : JSON.parse(answer);
          if (isChild == true) {
            validationList[`question_${qId}`] =
              AspControlType == dynamicControlConstants.checkbox
                ? Yup.array()
                : Yup.string().when(`question_${parentId}`, {
                    is: (parent) => checkCond(question, qId),
                    then: Yup.string().required(
                      getFieldValue(fields, "validationCheckboxRequired", false)
                    ),
                    otherwise: Yup.string(),
                  });
          } else {
            if (IsMandatory)
              validationList[`question_${qId}`] = Yup.array()
                .min(1)
                .required(
                  getFieldValue(fields, "validationCheckboxAtleastOne", false)
                );
            else validationList[`question_${qId}`] = Yup.array();
          }
        }
        break;
      case dynamicControlConstants.scoreid:
        break;
      default:
        if (isFormReseted && !question.IsReadOnly) answer = "";
        initialValues[`question_${qId}`] = answer;
        if (isChild == true) {
          validationList[`question_${qId}`] = Yup.string().when(
            `question_${parentId}`,
            {
              is: (parent) => checkCond(question, qId),
              then: Yup.string().required(
                getFieldValue(fields, "validationChildRequired", false)
              ),
              otherwise: Yup.string(),
            }
          );
        } else {
          if (IsMandatory)
            validationList[`question_${qId}`] = Yup.string().required(
              getFieldValue(fields, "validationChildRequired", false)
            );
          else validationList[`question_${qId}`] = Yup.string();
        }
        break;
    }
  }
  if (question.IsChild) {
    ChildQuetionsList(
      fields,
      question,
      initialValues,
      validationList,
      assessmentOptions,
      answerDetails,
      isFormReseted
    );
  }
}
function checkCond(question, qId) {
  let flag = true;
  switch (question.AspControlType) {
    case dynamicControlConstants.date:
      if (
        document
          .querySelector(
            `[aria-label="question_` + qId + `"] .MuiInputBase-input`
          )
          ?.hasAttribute("disabled")
      )
        flag = false;
      break;
    case dynamicControlConstants.select:
      if (
        document
          .querySelector(`[aria-label="question_` + qId + `"]`)
          ?.hasAttribute("disabled")
      )
        flag = false;
      break;
    default:
      if (
        document
          .querySelector(`[name="question_` + qId + `"]`)
          ?.hasAttribute("disabled")
      )
        flag = false;
      break;
  }
  if (flag && !question.IsMandatory) flag = false;
  return flag;
}
function ChildQuetionsList(
  fields,
  question,
  initialValues,
  validationList,
  assessmentOptions,
  answerDetails,
  isFormReseted
) {
  try {
    question.SubListQuestion.map((subQuestion, i) => {
      let isChild = true;
      InitializeValueAndValidations(
        fields,
        subQuestion,
        initialValues,
        validationList,
        assessmentOptions,
        answerDetails,
        isFormReseted,
        isChild
      );
    });
  } catch (e) {
    console.log(e);
  }
}
