import React from "react";
import { getFormattedDate } from "../../utils/helpers";
import { ICON_PATH } from "../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const handlePrint = () => {
  window.print();
};

export var healthReminderRecords = (alertType, description) => {
  return (
    <div className="row">
      <div className="col-md-12 details">
        <span className="list-desc-title">
          {alertType}
          {":"}
        </span>
        <span className="list-desc-details">{description}</span>
      </div>
    </div>
  );
};

export var printPage = (props) => {
  return (
    <div className="col-12 text-right">
      <a href="#" onClick={handlePrint}>
        <img
          src={`${ICON_PATH}/PlaceholderVideoGraphic.svg`}
          alt={props.lnkPrint.value.text}
          aria-hidden="true"
        ></img>
        {props.lnkPrint.value.text}
      </a>
    </div>
  );
};

export var printReportDate = (lblPrintGenerated) => {
  return (
    <div className="row">
      <div className="col-md-12 report-date pt-4 d-none">
        <span className="report-generate">
          <Text field={lblPrintGenerated} />
        </span>
        <span>
          {new Date().toLocaleTimeString()}{" "}
          {getFormattedDate(new Date(), "Day, Mon dd, yyyy")}
        </span>
      </div>
    </div>
  );
};
