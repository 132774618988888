import React from "react";
import { DATE_MM_DD_YYYY, IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { getFormattedDate } from "../../../../../utils/helpers";

const ViewIdCardHawki = ({ fields, result, details, idCard, drugInfo }) => {
  let today = new Date();
  let priorDate = new Date(new Date().setDate(today.getDate() + 30));
  let MemberName = idCard?.Member_Name?.split(",").reverse().join(" ");
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardNY"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/IA/Medicaid/Member-ID-Iowa-Hawki.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="2"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp;{" "}
                  <b
                    style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}
                  >
                    {MemberName}
                  </b>
                </td>
                <td>
                  <b>
                    <Text field={fields.lblBin} />
                  </b>
                  &nbsp;{" "}
                  <b
                    style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}
                  >
                    {result?.IdCardValues.RxBIN}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIdentification} />
                  </b>
                  &nbsp;{" "}
                  <b
                    style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}
                  >
                    {idCard?.Member_Number}
                  </b>
                </td>
                <td>
                  <b>
                    <Text field={fields.lblPCN} />
                  </b>
                  &nbsp;{" "}
                  <b
                    style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}
                  >
                    {result?.IdCardValues.RxPCN}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblDateOfBirth} />
                  </b>
                  &nbsp;{" "}
                  <b
                    style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}
                  >
                    {idCard?.Member_DOB}
                  </b>
                </td>
                <td>
                  <b>
                    <Text field={fields.lblGroup} />
                  </b>
                  &nbsp;{" "}
                  <b
                    style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}
                  >
                    {result?.IdCardValues.RxGRP}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblEffectiveDate} />
                  </b>
                  &nbsp;{" "}
                  <b
                    style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}
                  >
                    {idCard?.MemberEffectiveDate}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <table
            width="100%"
            border="0"
            cellPadding="2"
            cellSpacing="3"
            id="memberCurrentID"
            style={{ fontFamily: "campton-book" }}
          >
            <tbody>
              <tr>
                <Text field={fields.lblDeductibles} />
              </tr>
              <tr>
                <td height="5" colSpan="1"></td>
              </tr>
              <tr>
                <Text field={fields.lblMedicalDeductible} />
              </tr>
              <br></br>
              <tr>
                <Text field={fields.lblIDBackMemberData} />
              </tr>
              <tr>
                <td height="5" colSpan="1"></td>
              </tr>
              <tr>
                <Text field={fields.lblIDBackMiembroData} />
              </tr>
              <tr>
                <td height="6" colSpan="1"></td>
              </tr>
              <div>
                {/* <label>
                  <b
                    style={{
                      padding: "2px",
                      fontWeight: "bolder",
                      fontSize: "13px",
                    }}
                  >
                    <Text field={fields.lblHMO} />{" "}
                  </b>
                </label> */}
                <label>
                  <Text field={fields.lblHealthCare} />
                </label>
              </div>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/IA/Medicaid/Member-ID-Iowa-back.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="row">
            <div className="col-xxl-12 col-xl-12">
              <div
                className="row card-content-id-back"
                style={{ paddingTop: "20px", fontSize: "large" }}
              >
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <div className="form-group card-f-style">
                    <label>
                      <b style={{ color: "#009ea0" }}>
                        <Text field={fields.lblMemberNumbers} />
                      </b>
                    </label>
                  </div>
                  <div className="form-group card-f-style p-t-xxs line-height-zero">
                    <label>
                      <b>
                        <Text field={fields.lblMemberServicesKY} />
                      </b>
                      <b
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "lighter",
                        }}
                      >
                        <Text field={fields.lblMemberServicesValueNY} />
                      </b>
                    </label>
                  </div>
                  <tr>
                    <td height="7" colSpan="2"></td>
                  </tr>
                  <div className="form-group card-f-style">
                    <label>
                      <b>
                        <Text field={fields.lblTTY} />
                      </b>
                    </label>
                    <label>
                      <b
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "lighter",
                        }}
                      >
                        <Text field={fields.lblTTYData} />
                      </b>
                    </label>
                  </div>
                  <tr>
                    <td height="7" colSpan="2"></td>
                  </tr>
                  <div className="form-group card-f-style">
                    <label>
                      <b>
                        <Text field={fields.lblIDBackNAL} />
                      </b>
                    </label>
                    <label>
                      <b
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "lighter",
                        }}
                      >
                        <Text field={fields.lblIDBackNALData} />
                      </b>
                    </label>
                  </div>
                  <tr>
                    <td height="7" colSpan="2"></td>
                  </tr>
                  <div className="form-group card-f-style">
                    <label>
                      <b>
                        <Text field={fields.lblBilling} />
                      </b>
                    </label>
                    <label>
                      <b
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "lighter",
                        }}
                      >
                        <Text field={fields.lblBillingData} />
                      </b>
                    </label>
                  </div>
                  <tr>
                    <td height="7" colSpan="2"></td>
                  </tr>
                  <div className="form-group card-f-style p-t-xxs line-height-zero">
                    <label>
                      <b>
                        <Text field={fields.lblCostShares} />
                      </b>
                    </label>
                  </div>
                  <tr>
                    <td height="7" colSpan="2"></td>
                  </tr>
                  <div className="form-group card-f-style">
                    <label>
                      <b>
                        <Text field={fields.lblMedicalClaims} />
                      </b>
                    </label>
                    <label>
                      <b
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "lighter",
                        }}
                      >
                        <Text field={fields.lblNoticeData} />
                      </b>
                    </label>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <div className="form-group card-f-style">
                    <label>
                      <b style={{ color: "#009ea0" }}>
                        <Text field={fields.lblProviderNumbers} />
                      </b>
                    </label>
                  </div>
                  <div className="form-group card-f-style p-t-xxs line-height-zero">
                    <label>
                      <b>
                        <Text field={fields.lblIDBackCVS} />
                      </b>
                    </label>
                  </div>
                  <div className="form-group card-f-style line-height-zero">
                    <label>
                      <b
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "lighter",
                        }}
                      >
                        <Text field={fields.lblIDBackCVSData} />
                      </b>
                    </label>
                  </div>
                  <tr>
                    <td height="7" colSpan="2"></td>
                  </tr>
                  <div className="form-group card-f-style">
                    <label>
                      <b>
                        <Text field={fields.lblIDBackNotification} />
                      </b>
                      <b
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "lighter",
                        }}
                      >
                        <Text field={fields.lblIDBackNotificationData} />
                      </b>
                    </label>
                  </div>
                  <tr>
                    <td height="7" colSpan="2"></td>
                  </tr>
                  <div className="form-group card-f-style">
                    <label>
                      <b>
                        <Text field={fields.lblInpatientAdmissions} />
                      </b>
                      <b
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "lighter",
                        }}
                      >
                        <Text field={fields.lblIDBackProviderData} />
                      </b>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                paddingTop: "52px",
                paddingLeft: "112px",
                fontFamily: "campton-book",
                fontSize: "10px",
              }}
            >
              <Text field={fields.lblIDBackHeading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardHawki;
