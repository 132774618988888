/*
This component returns each item in a box.
*/

import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const DisplayItem = (props) => {
  const { nameField, valueField, outClass, titleClass, detailsClass } = props;
  return (
    <div className={`${outClass}`}>
      {nameField && (
        <label className={`${titleClass ?? titleClass}`}>
          <Text field={nameField} />
        </label>
      )}
      {valueField && (
        <div className={`${detailsClass ?? detailsClass}`}>
          <span>{valueField}</span>
        </div>
      )}
    </div>
  );
};

export default DisplayItem;
