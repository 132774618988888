import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardMedicaidWA = ({
  MemberStateName,
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div className="row ">
        <div
          id="id-card-container"
          className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 container-fluid"
        >
          <p className="text-align-center">
            <Text field={fields.lblFront} />
          </p>
          <div
            className="bg-front"
            style={{
              backgroundImage: `url(${IMAGE_PATH}/id/NonMP/WA/Medicaid/WA_Medicaid_Common_New.png)`,
            }}
          >
            <section className="id-body">
              <div className="row card-adj card-padding-front">
                <div className="col-xxl-12 col-xl-12">
                  <div className="row">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblWACaidMember} />
                          </b>
                        </label>
                        <label>{idCard?.Member_Name}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row rowHeight">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblIdentificationWACaid} />
                          </b>
                        </label>
                        <label>{idCard?.OriginalMemberProfile?.Number}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row rowHeight">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblDOBWACaid} />
                          </b>
                        </label>
                        <label>{idCard?.Member_DOB}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row rowHeight">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblClientIDWACaid} />
                          </b>
                        </label>
                        <label>{result?.ClientID_WA?.trim()}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row contentFromTop">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 border-right">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblProgramWACaid} />
                          </b>
                        </label>
                        <span className="myMolinaIDCardText">
                          {result?.POD_DESC_WA?.trim()}
                        </span>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <div className="form-group card-f-style line-height-zero">
                        <label>
                          <b>
                            <Text field={fields?.lblRxBinWACaid} />
                          </b>
                        </label>
                        <label>{result?.IdCardValues?.RxBIN}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row rowHeight">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 border-right">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblPCPNameWACaid} />
                          </b>
                        </label>
                        <label>{idCard?.PCPName_Current}</label>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <div className="form-group card-f-style line-height-zero">
                        <label>
                          <b>
                            <Text field={fields?.lblPCNWACaid} />
                          </b>
                        </label>
                        <label>{result?.IdCardValues?.RxPCN}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row rowHeight">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 border-right">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblPCPPhoneWACaid} />
                          </b>
                        </label>
                        <label>{idCard?.PCPPhone_Current}</label>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <div className="form-group card-f-style line-height-zero">
                        <label>
                          <b>
                            <Text field={fields?.lblGroupWACaid} />
                          </b>
                        </label>
                        <label>{result?.IdCardValues?.RxGroup}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row rowHeight">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblPCPLocation} />
                          </b>
                        </label>
                        <span className="myMolinaIDCardText">
                          {idCard?.PCPAddress_Current}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row rowHeight">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblPCPEffWACaid} />
                          </b>
                        </label>
                        <label>{idCard?.PCPEffectiveDate}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row rowHeight">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblAuthWACaid} />
                          </b>
                        </label>
                        <label>
                          <Text field={fields?.lblAuthWACaidValue} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row contentFromTop">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <div className="form-group card-f-style">
                        <label>
                          <Text field={fields?.lblMembServicesWACaid} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <div className="form-group card-f-style">
                        <label>
                          <Text field={fields?.lblContactWACaid} />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          id="id-card-container"
          className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 container-fluid"
        >
          <p className="text-align-center">
            <Text field={fields.lblBack} />
          </p>
          <div
            className="bg-front"
            style={{
              backgroundImage: `url(${IMAGE_PATH}/id/NonMP/WA/Medicaid/WA_Medicaid_Back.png)`,
            }}
          >
            <section className="id-body">
              <div className="row card-adj card-padding-back">
                <div className="col-xxl-12 col-xl-12">
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group card-f-style">
                        <label>
                          <u>
                            <b>
                              <Text field={fields?.lblEmergencyServWACaid} />
                            </b>
                          </u>
                        </label>
                        <span className="myMolinaIDCardText">
                          <Text field={fields?.lblEmergencyServValueWACaid} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblRMHCLineWACaid} />
                          </b>
                        </label>
                        <span className="myMolinaIDCardText">
                          <Text field={fields?.lblRMHCLineValueWACaid} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblNALWACaid} />
                          </b>
                        </label>
                        <span className="myMolinaIDCardText">
                          <Text field={fields?.lblNALValueWACaid} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group card-f-style">
                        <label>
                          <Text field={fields?.lblNALEngWACaid} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group card-f-style">
                        <label>
                          <Text field={fields?.lblNALSpanishWACaid} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row contentFromTop">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group card-f-style">
                        <label>
                          <u>
                            <b>
                              <Text field={fields?.lblProvidersWACaid} />
                            </b>
                          </u>
                        </label>
                        <span className="myMolinaIDCardText">
                          <Text field={fields?.lblProvidersValueWACaid} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblHosAdmissionWACaid} />
                          </b>
                        </label>
                        <span className="myMolinaIDCardText">
                          <Text field={fields?.lblHosAdmissionValWACaid} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblRemitWACaid} />
                          </b>
                        </label>
                        <span className="myMolinaIDCardText">
                          <Text field={fields?.lblRemitValueWACaid} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row contentFromTopFiftyPx">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group card-f-style">
                        <label>
                          <b>
                            <Text field={fields?.lblTTYWACaid} />
                          </b>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicaidWA;
