import React, { useState, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Form, Field, Formik } from "formik";
import { CustomSelect } from "../../common/Select";
import { AppContext } from "../../contexts/app.context";
import Multiselect from "multiselect-react-dropdown";
const RaceOREthnicity = ({
  fields,
  errors,
  values,
  touched,
  initialTouched,
  setFieldValue,
  setFieldTouched,
  setValues,
  pageEditable,
  onButtonClick,
  contactPreferencesList,
  handleBlur,
  handleChange,
  getRaceOrEthinicitySelected,
  handleRaceOrEthicityValue,
  contactData,
}) => {
  let raceOrEthinicityList = [];
  let [raceOrEthnicityFlag, setRaceOrEthnicityFlag] = useState(true);
  let { isTermedMember } = useContext(AppContext);
  const handleRaceOrEthinicitynSelect = (e) => {
    if (e.value === "Other:") {
      setRaceOrEthnicityFlag(false);
    } else {
      setRaceOrEthnicityFlag(true);
    }
    getRaceOrEthinicitySelected(e.value);
  };

  contactPreferencesList?.MH_RaceEthnicity__pc?.map((data) => {
    raceOrEthinicityList.push({
      value: data,
      id: data,
      label: data,
    });
  });
  const initialValues = {
    raceOrEthinicityList: contactData?.IsContactPreferences?.RaceEthnicity,
  };

  // Multi-Select Race/Ethnicity -- START

  // Preparing for GET - To show already selected list fetched from API at initial load
  const initialSelectedIDs = initialValues?.raceOrEthinicityList
    ?.split(",")
    ?.map((item) => item.trim());
  const initialSelectedList = raceOrEthinicityList?.filter(({ id }) =>
    initialSelectedIDs?.includes(id)
  );

  // Preparing for POST - Send updated selected list to API
  const prepareSelectedValues = (selectedList) => {
    // Preparing comma separated values to send to API
    let selectedRaceEthnicity = selectedList
      ? selectedList.map((item) => item.value).join(",")
      : "";

    getRaceOrEthinicitySelected(selectedRaceEthnicity);
  };

  // On selection of item from dropdown
  const onSelect = (selectedList, selectedItem) => {
    if (
      selectedItem.value === "Other:" ||
      selectedItem.value === "Otro (indique cuál)"
    ) {
      setRaceOrEthnicityFlag(false);
    }
    prepareSelectedValues(selectedList);
  };

  // On removal of item from selectedList in dropwown
  const onRemove = (selectedList, removedItem) => {
    if (
      removedItem.value === "Other:" ||
      removedItem.value === "Otro (indique cuál)"
    ) {
      setRaceOrEthnicityFlag(true);
    }
    prepareSelectedValues(selectedList);
  };

  // Multi-Select Race/Ethnicity -- END

  return (
    <>
      {initialValues.raceOrEthinicityList !== undefined && (
        <div className="row">
          <div className="col-lg-12 col-md-8">
            <h5 className="tab-sub-title column-margin">
              <Text field={fields?.RaceOrEthnicity ?? ""} />
            </h5>
            <div
              className={
                pageEditable
                  ? "disabled-div dropdown-list-width"
                  : "dropdown-list-width"
              }
            >
              <div id="RaceMultiSelect">
                <Multiselect
                  displayValue="value"
                  id="raceOrEthinicityList"
                  className="multiSelectEnhanced Mselect"
                  name="raceOrEthinicityList"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={onRemove}
                  onSearch={function noRefCheck() {}}
                  onSelect={onSelect}
                  options={raceOrEthinicityList}
                  showCheckbox={true}
                  disable={pageEditable}
                  placeholder={fields?.multiSelectPlaceholder?.value}
                  selectedValues={initialSelectedList}
                  hideSelectedList={true}
                  style={{
                    chips: {
                      background: "#009ea0",
                    },
                    multiselectContainer: {
                      fontSize: "16px",
                      width: "100%",
                    },
                    searchBox: {
                      fontSize: "16px",
                    },
                    input: {
                      display: "inline-block",
                    },
                  }}
                />
              </div>
              <textarea
                maxLength={20}
                placeholder={getFieldValue(fields, "lblPreferredPronounPH")}
                className={
                  raceOrEthnicityFlag
                    ? "textarea-display"
                    : "form-control textarea-control"
                }
                onChange={(e) => handleRaceOrEthicityValue(e)}
              ></textarea>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default RaceOREthnicity;
