import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardLtcFL = ({ fields, result, details, idCard, drugInfo }) => {
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardNY"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/FL/Medicaid/FL_LTC_id_card_front.png)`,
          }}
          className="background-idcard"
          id="containerltc"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td colSpan="2">
                  <ul className="list-unstyled ltcmrgn">
                    <li>
                      <b>
                        <Text field={fields.lblMember} />
                      </b>
                      &nbsp; {idCard?.Member_Name}
                    </li>
                    <li>
                      <b>
                        <Text field={fields.lblmemberno} />
                      </b>
                      &nbsp; {idCard?.Member_Number}
                    </li>
                    <li>
                      <b>
                        <Text field={fields.lblEffectiveDate} />
                      </b>
                      &nbsp; {idCard?.MemberEffectiveDate}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="flheight">
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Text field={fields.lblNoteForMemberServiceLtc} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicaid/WI_back_view.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text backcard">
            <div className="backcardmember">
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblEmergencyServices} />
                </u>
                <Text field={fields.lblNearestEmergencyNote} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblCaseManagementOrMember} />
                </u>
                <Text field={fields.lblCaseManagementOrMemberContact} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblClaims} />
                </u>
                &nbsp;
                <Text field={fields.lblClaimsBillingInformation} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblSCBackEDIClaims} />
                </u>
                &nbsp;
                <Text field={fields.lblEmdeonPayer} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblTransportation} />
                </u>
                &nbsp;
                <Text field={fields.lblToScheduleTrip} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblIDBackComplaintContact} />
                </u>
                &nbsp;
                <Text field={fields.lblIDBackComplaintContactData} />
              </div>
            </div>
            <div className="text-center">
              <Text field={fields.lblFlAddress} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardLtcFL;
