import React, { useContext, useState, useCallback } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ModalContext } from "../../contexts/modal.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import * as GrantAccessAPI from "../../services/GrantAccessAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { AppContext } from "../../contexts/app.context";
import { Formik, Form, Field } from "formik";
import {
  DEPENDANT_ACCESS_GRANTED_ROLEID,
  ACCESS_REVOKE_CODE,
  SUCCESS_RESULT_CODE,
} from "../../define.constants";
import { ErrorContext } from "../../contexts/error.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { SUPPORT_USER, TERMED_MEMBER } from "../../define.constants";
const AccessUpdate = (props) => {
  const fields = props?.fields;
  const dependantDetailList = props.dependentList;
  const { setError } = useContext(ErrorContext);
  const { openModal, closeModal } = useContext(ModalContext);
  let { refreshProfiles, isTermedMember } = useContext(AppContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [refreshProfilesCalled, setRefreshProfilesCalled] = useState(false);
  let selectedDependant = "";
  const setUserSelectedDependant = useCallback(
    (selectedDependant) => {
      setSpinner(true);
      GrantAccessAPI.getGrantAccess({
        DependentMemId: selectedDependant,
      })
        .then((response) => {
          setSpinner(false);
          if (
            response?.ResultCode === SUCCESS_RESULT_CODE ||
            response?.ResultCode === ACCESS_REVOKE_CODE
          ) {
            refreshProfiles();
            setRefreshProfilesCalled(true);
          }
        })
        .catch((err) => {
          setError({ err });
          setSpinner(false);
        });
    },
    [refreshProfiles, props]
  );

  let userRoleId = "0";
  dependantDetailList?.map((item) => {
    if (item.RoleId === DEPENDANT_ACCESS_GRANTED_ROLEID) {
      userRoleId = item.RksMemberId;
    }
  });
  const initialValues = {
    picked: userRoleId,
  };
  const accessContent = (
    <div className="access-modal">
      <div className="update-header">
        <h5 className="heading-main">
          <Text field={fields.popupHeading} />
        </h5>
        <p className="para-one">
          <Text field={fields.popupDescription} />
        </p>
        <div className="row field1 common-input">
          <Formik
            enableReinitialize="true"
            initialValues={initialValues}
            onSubmit={(values) => {
              selectedDependant = values?.picked;
              setUserSelectedDependant(selectedDependant);
              closeModal();
            }}
          >
            {({ handleSubmit }) => (
              <Form>
                <div role="group" className="row">
                  {dependantDetailList.map((item, index) => (
                    <div
                      className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12"
                      key={index}
                    >
                      <div className="form-check">
                        <Field
                          type="radio"
                          name="picked"
                          value={item.RksMemberId}
                          className="form-check-input radio-btns-access"
                          id="flexCheckChecked"
                        />
                        <label className="label-radio " htmlFor="english">
                          <h5 className="dependent-header">
                            <div className="row-title">
                              {item.FirstName}
                              <span className="name-space">
                                {item.LastName}
                              </span>
                            </div>
                          </h5>
                          <p className="para-dependent">
                            <div className="row-details">
                              <Text field={fields.idText} />
                              <span>{item.MemberNumber}</span>
                              <Text field={fields.userDependent} />
                            </div>
                          </p>
                        </label>
                      </div>
                    </div>
                  ))}
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="form-check">
                      <Field
                        type="radio"
                        name="picked"
                        value="0"
                        className="form-check-input radio-btns-access"
                        id="flexCheckChecked"
                      />
                      <label className="label-radio " htmlFor="english">
                        <h5 className="dependent-header">
                          <div className="row-title">
                            <Text field={fields.accessRevokeText} />
                          </div>
                        </h5>
                      </label>
                    </div>
                  </div>
                  <div className="access-btns">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-mdb-dismiss="modal"
                      onClick={closeModal}
                    >
                      <Text field={fields.popupCancelButton} />
                    </button>
                    <WhoIsAuthorized
                      authorize={{
                        [SUPPORT_USER]: false,
                        [TERMED_MEMBER]: false,
                      }}
                      handleClick={handleSubmit}
                    >
                      {({ handleClick, disabledClass }) => {
                        return (
                          <button
                            type="button"
                            onClick={handleClick}
                            className="btn btn-primary"
                          >
                            <Text field={fields.popupContinueButton} />
                          </button>
                        );
                      }}
                    </WhoIsAuthorized>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
  const generalUpdate = () => {
    openModal({
      content: accessContent,
      version: 2,
    });
  };

  return (
    <>
      <div className="col-xxl-4 col-md-4">
        <button
          type="button"
          className={
            isTermedMember
              ? " btn btn-primary update-button disabled"
              : "btn btn-primary update-button"
          }
          onClick={() => {
            openModal({
              content: accessContent,
              version: 2,
            });
          }}
        >
          <Text field={fields.grantBtnTitle} />
        </button>
      </div>
    </>
  );
};

export default withModalProvider(AccessUpdate);
