/*
This componentreturns the Service Description Disclaimer at the end of the result. 
*/

import React from "react";
import { ICON_PATH } from "../../../define.constants";

const ServiceDescription = (props) => {
  const { bannerMsgKY, disclaimer, contactUs, resultsDisclaimer } = props;
  return (
    <div className="service-disc">
      {bannerMsgKY && <p className="disc-bld">{bannerMsgKY.value}</p>}
      {disclaimer}
      <a className="hr-contact" href={contactUs.value.href}>
        {contactUs.value.text}
        <img
          className="contact-img"
          src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
          alt={contactUs.value.text}
        />
      </a>
      {resultsDisclaimer && <p className="disc-bld">{resultsDisclaimer}</p>}
    </div>
  );
};

export default ServiceDescription;
