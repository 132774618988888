import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import * as ClaimsHistoryAPI from "../services/HeathRecordAPI";
import { ErrorContext } from "../contexts/error.context";
import { MY_HEALTH_RECORD_MEM_API_SERVICE_HISTORY } from "../define.widgets";
const qs = require("qs");

const useClaimsHistoryAPI = (
  page = 1,
  recordsPerPage,
  fetchData = true,
  searchTerm = null
) => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [claimsHistoryList, setClaimsHistoryList] = useState([]);
  const { MemberStateName, memberRKSID } = useContext(AppContext);
  const [isClaimLoaded, setIsClaimLoaded] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [providerList, setProviderList] = useState([]);

  const setData = (data) => {
    setProviderList(data?.ResultData?.ProviderList);
    setClaimsHistoryList(data?.ResultData?.HealthRecordDetails);
    setIsClaimLoaded(true);
    if (data?.ResultData?.HealthRecordDetails !== null)
      setTotalRecords(data?.ResultData?.HealthRecordDetails[0]?.TotalRecords);
  };

  useEffect(() => {
    let startDate = "",
      endDate = "",
      provName = "";

    if (searchTerm != null) {
      startDate = searchTerm["startDate"];
      endDate = searchTerm["endDate"];
      provName = searchTerm["provName"];
    }

    let query = {
      Page: page,
      RecordsPerPage: recordsPerPage,
      StartDate: startDate,
      EndDate: endDate,
      ProvName:
        provName.toLowerCase() === "all" || provName.toLowerCase() === "todos"
          ? ""
          : provName,
    };

    if (fetchData) {
      setInnerSpinner(true, MY_HEALTH_RECORD_MEM_API_SERVICE_HISTORY);
      ClaimsHistoryAPI.getClaimsHistoryList(
        {
          RKSMemberId: memberRKSID,
          StateCode: MemberStateName,
          Page: page,
          RecordsPerPage: recordsPerPage,
          StartDate: startDate,
          EndDate: endDate,
          ProvName:
            provName.toLowerCase() === "all" ||
            provName.toLowerCase() === "todos"
              ? ""
              : provName,
        },
        qs.stringify(query, { addQueryPrefix: true })
      )
        .then((response) => {
          if (response != undefined && response != null) {
            setData(response);
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MY_HEALTH_RECORD_MEM_API_SERVICE_HISTORY);
        });
    }
  }, [page, recordsPerPage, fetchData, searchTerm]);

  return {
    providerList,
    claimsHistoryList,
    isClaimLoaded,
    totalRecords,
  };
};

export { useClaimsHistoryAPI };
