import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";

const MedicationDetailsRecords = (props) => {
  const {
    fields,
    medicationName,
    prescribingDoctor,
    prescriberNPI,
    dateFilled,
    dateWritten,
    quantity,
    daysSupply,
    refillsAuthorized,
    refillsLeft,
    pharmacyNPI,
  } = props;
  return (
    <ul className="list-group d-none d-md-block medication-details">
      <li className="list-group-item">
        <div className="row">
          <div className="col-12 tab-sub-title">
            <b>
              <Text field={fields.lblMedicationsDetails} />
            </b>
          </div>
        </div>
        <div className="row  common-input label-list">
          <DisplayItem
            nameField={fields.lblMedicationName}
            outClass={"col-xl-3 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
          <DisplayItem
            nameField={fields.lblPrescribingDoctor}
            outClass={"col-xl-3 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
          <DisplayItem
            nameField={fields.lblPrescriberNPI}
            outClass={"col-xl-2 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
          <DisplayItem
            nameField={fields.lblDateFilled}
            outClass={"col-xl-2 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
          <DisplayItem
            nameField={fields.lblDateWritten}
            outClass={"col-xl-2 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
        </div>
        <div className="row field1 common-input">
          <DisplayItem
            nameField={fields.lblMedicationName}
            valueField={medicationName}
            outClass={"col-xl-3 col-md-12 d-md-flex d-xl-block"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
          <DisplayItem
            nameField={fields.lblPrescribingDoctor}
            valueField={prescribingDoctor}
            outClass={"col-xl-3 col-md-12 d-md-flex d-xl-block"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
          <DisplayItem
            nameField={fields.lblPrescriberNPI}
            valueField={prescriberNPI}
            outClass={"col-xl-2 col-md-12 d-md-flex d-xl-block"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
          <DisplayItem
            nameField={fields.lblDateFilled}
            valueField={dateFilled}
            outClass={"col-xl-2 col-md-12 d-md-flex d-xl-block"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
          <DisplayItem
            nameField={fields.lblDateWritten}
            valueField={dateWritten}
            outClass={"col-xl-2 col-md-12 d-md-flex d-xl-block"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
        </div>
        <div className="row field2 common-input label-list">
          <DisplayItem
            nameField={fields.lblQuantity}
            outClass={"col-xl-3 col-md-6 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
          <DisplayItem
            nameField={fields.lblDaysSupply}
            outClass={"col-xl-3 col-md-6 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
          <DisplayItem
            nameField={fields.lblRefillsAuth}
            outClass={"col-xl-2 col-md-6 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
          <DisplayItem
            nameField={fields.lblRefillsLeft}
            outClass={"col-xl-2 col-md-6 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
          <DisplayItem
            nameField={fields.lblPharmacyNPI}
            outClass={"col-xl-2 col-md-6 d-xl-block d-none"}
            titleClass={"list-desc-title row-title"}
          />
        </div>
        <div className="row field2 common-input">
          <DisplayItem
            nameField={fields.lblQuantity}
            valueField={quantity}
            outClass={"col-md-12 col-xl-3 d-xl-block d-md-flex"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
          <DisplayItem
            nameField={fields.lblDaysSupply}
            valueField={daysSupply}
            outClass={"col-xl-3 col-md-12 d-xl-block d-md-flex"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
          <DisplayItem
            nameField={fields.lblRefillsAuth}
            valueField={refillsAuthorized}
            outClass={"col-xl-2 col-md-12 d-xl-block d-md-flex"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
          <DisplayItem
            nameField={fields.lblRefillsLeft}
            valueField={refillsLeft}
            outClass={"col-xl-2 col-md-12 d-xl-block d-md-flex"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
          <DisplayItem
            nameField={fields.lblPharmacyNPI}
            valueField={pharmacyNPI}
            outClass={"col-xl-2 col-md-12 d-xl-block d-md-flex"}
            titleClass={"list-desc-title row-title d-lg-block d-xl-none"}
            detailsClass={"list-desc-details row-details"}
          />
        </div>
      </li>
    </ul>
  );
};

export default MedicationDetailsRecords;
