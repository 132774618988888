import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../contexts/app.context";
import {
  Text,
  RichText,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { CustomSelect } from "../../common/Select";
import {
  NYInclusion,
  createFileIconNMP,
  createLinkIconNMP,
  createLinkIconNMP_CY2024,
} from "./NonMarketplaceHelper";
import { SpinnerContext } from "../../contexts/spinner.context";
import { COVERAGE_ELIGIBILITY } from "../../define.widgets";
import {
  MEDICAID,
  HELP_FULLINK_ID,
  MEDICARE,
  FL,
  DUALS,
  IL,
  ICON_PATH,
  WA,
  NM,
  NM_MCAID,
  MEDICARE_CY2024_STATES,
  DUALS_CY2024_STATES,
  Label_Implementation_Year,
  Label_Current_Year,
} from "../../define.constants";
const NonMarketplaceFormDocs = ({
  formsFields,
  formsList,
  langRef,
  distItem,
  docsFields,
  docsList,
  listRecords,
  cy2024ImplementationYear,
}) => {
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  let { MemberStateName, memberLOB, programId, planId, isNYAffinityMember } =
    useContext(AppContext);
  const [recordList] = useState([]);
  const helpfulLinkIds = HELP_FULLINK_ID;
  const [record] = useState(null);
  const [isYearSpecific] = useState(false);
  const { isTermedMember } = useContext(AppContext);
  const isMedicaid = memberLOB?.toLowerCase() === MEDICAID;
  const isMedicare = memberLOB?.toLowerCase() === MEDICARE;
  const isDuals = memberLOB?.toLowerCase() === DUALS;
  let currentyear = new Date().getFullYear();
  const showMultipleLinks = () => {
    let showMultipleLinksFlag = false;
    if (
      ((isMedicare &&
        MEDICARE_CY2024_STATES?.includes(
          MemberStateName?.trim()?.toLowerCase()
        )) ||
        (isDuals &&
          DUALS_CY2024_STATES?.includes(
            MemberStateName?.trim()?.toLowerCase()
          ))) &&
      currentyear < cy2024ImplementationYear
    ) {
      showMultipleLinksFlag = true;
    }
    return showMultipleLinksFlag;
  };
  const isWAMedicaid =
    memberLOB?.toLowerCase() === MEDICAID &&
    WA === MemberStateName.toUpperCase();
  const MedicaidTermedMember =
    memberLOB?.toLowerCase() === MEDICAID && isTermedMember;
  //Medicare and Dual Termed Condition
  const MedicareTermedMember =
    memberLOB?.toLowerCase() === MEDICARE && isTermedMember;
  const DualsTermedMember =
    memberLOB?.toLowerCase() === DUALS && isTermedMember;
  const docsListArray = docsList.length === undefined || docsList.length <= 0;
  const formListArray = formsList.length === undefined || formsList.length <= 0;
  console.log("formListArray", formListArray);

  // Get actual Forms count displayed in UI
  const getActualFormListCount = () => {
    let formListCount = formsList.length;
    formsList?.map((item, index) => {
      let linkIds = [];
      helpfulLinkIds.forEach((link) => {
        if (link.id === getFieldValue(item.fields, "id")) {
          linkIds = link;
        }
      });
      if (linkIds && linkIds.programIds && linkIds.programIds.length > 0) {
        if (linkIds.programIds.includes(programId)) {
          if (
            linkIds.planIds &&
            linkIds.planIds.length > 0 &&
            !linkIds.planIds.includes(planId)
          ) {
            formListCount = formListCount - 1;
            return;
          }
        } else {
          formListCount = formListCount - 1;
          return;
        }
      }
    });

    return formListCount;
  };

  // Flag to show "No Information Available" in Forms section
  const showNoInfoAvailable = () => {
    let noInfoFlag = false;
    if (isMedicaid && IL === MemberStateName?.toUpperCase()) {
      const actualFormCount = getActualFormListCount();
      noInfoFlag =
        Array.isArray(formsList) &&
        (actualFormCount === undefined || actualFormCount <= 0);
    }

    return noInfoFlag;
  };
  const NM_Medicaid_Message =
    MemberStateName == NM && isMedicaid && programId == NM_MCAID;

  const Medicare_Dual_TermedMessage =
    docsListArray && (MedicareTermedMember || DualsTermedMember);
  // Form Section Label Sort by Alphabetically
  {
    if (formsList != null && formsList.length > 0) {
      formsList.sort((a, b) =>
        a["fields"]["formTitle"]["value"].localeCompare(
          b["fields"]["formTitle"]["value"]
        )
      );
    }
  }
  // Document Section Label Sort by Alphabetically

  {
    if (docsList != null && docsList.length > 0) {
      docsList.sort((a, b) =>
        a["fields"]["documentTitle"]["value"].localeCompare(
          b["fields"]["documentTitle"]["value"]
        )
      );
    }
  }
  // let currentpath = useLocation();
  // useEffect(() => {
  //   console.log("inside", currentpath);
  //   setTimeout(() => {
  //     if (
  //       currentpath?.hash === "#Form-Docs" &&
  //       document.getElementById("Formulary")
  //     ) {
  //       document
  //         .getElementById("Formulary")
  //         .scrollIntoView({ behaviour: "smooth", block: "center" });
  //       currentpath.hash = "";
  //     }
  //   }, 2000);
  // }, [currentpath]);
  return (
    <div
      className="tab-pane fade show active my-resources-page"
      id="formsDocs"
      role="tabpanel"
      aria-label="tab-1"
    >
      {/* Forms Section start */}
      {distItem && !NM_Medicaid_Message && (
        <>
          <div className="row">
            <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
              <h5 className="tab-title title2">{formsFields?.tabName.value}</h5>
            </div>
            {listRecords?.length > 1 && (
              <div className="col-xxl-9 col-lg-8 col-md-6 col-sm-12 document-plan-year coverage-page">
                <div className="plan-year-block">
                  <div>{docsFields?.planYear.value}</div>
                  <div>
                    <CustomSelect
                      field={{
                        name: "documentsPlanYears",
                        value: yearFromMyCoverage
                          ? yearFromMyCoverage
                          : record?.Year,
                      }}
                      className="form-select"
                      aria-label={getFieldValue(formsFields, "planYear")}
                      options={recordList.map((item) => ({
                        value: item.Year,
                        label: item.Year,
                      }))}
                      isFormik={false}
                      onChange={handleYearSelect}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row ">
            <div className="col-md-12">
              <ul className="list-group loder-position">
                {innerSpinnerHTML?.[COVERAGE_ELIGIBILITY]}
                {Array.isArray(formsList) && formsList.length > 0 && (
                  <>
                    {formsList?.map((item, index) => {
                      let linkIds = [];
                      helpfulLinkIds.forEach((link) => {
                        if (link.id === getFieldValue(item.fields, "id")) {
                          linkIds = link;
                        }
                      });
                      if (
                        linkIds &&
                        linkIds.programIds &&
                        linkIds.programIds.length > 0
                      ) {
                        if (linkIds.programIds.includes(programId)) {
                          if (
                            linkIds.planIds &&
                            linkIds.planIds.length > 0 &&
                            !linkIds.planIds.includes(planId)
                          ) {
                            return;
                          }
                        } else {
                          return;
                        }
                      }
                      if (index === formsList.length - 1) {
                        if (
                          // Checks whether the form is applicaple to this NY Medicaid member
                          NYInclusion(
                            item.fields.id,
                            programId,
                            MemberStateName,
                            memberLOB,
                            isNYAffinityMember
                          ) &&
                          (item.fields.excludePlanIds?.value === "" ||
                            (item.fields.excludePlanIds?.value !== "" &&
                              item.fields.excludePlanIds?.value
                                .toString()
                                .split(",")
                                .indexOf(record?.PlanID) < 0))
                        ) {
                          return (
                            <li
                              className="list-group-item last-card-tab1 cards-md"
                              key={index}
                            >
                              <div className="row">
                                <div className="col-xxl-2 col-md-3 list-title card-titles">
                                  <Text field={item.fields.formTitle} />
                                </div>
                                <div className="col-xxl-8 col-md-7 list-desc">
                                  {item.fields.isSubmitOnline.value === true ? (
                                    <div>
                                      <RichText
                                        field={
                                          item.fields.submitOnlineDescription
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {getFieldValue(
                                        item.fields,
                                        "formDescription"
                                      ) ? (
                                        <div>
                                          <RichText
                                            field={item.fields.formDescription}
                                          />
                                        </div>
                                      ) : null}
                                    </>
                                  )}

                                  {getFieldValue(
                                    item.fields,
                                    "formFaxContact"
                                  ) ||
                                  getFieldValue(
                                    item.fields,
                                    "formAddressContact"
                                  ) ||
                                  getFieldValue(
                                    item.fields,
                                    "formEmailContact"
                                  ) ? (
                                    <>
                                      <hr className="dropdown-divider d-sm-block d-md-none" />
                                    </>
                                  ) : null}

                                  {getFieldValue(
                                    item.fields,
                                    "formFaxContact"
                                  ) ||
                                  getFieldValue(
                                    item.fields,
                                    "formAddressContact"
                                  ) ||
                                  getFieldValue(
                                    item.fields,
                                    "formEmailContact"
                                  ) ? (
                                    <>
                                      <div className="row">
                                        <div className="col-xxl-4 col-lg-6 col-md-6">
                                          {getFieldValue(
                                            item.fields,
                                            "formAddressContact"
                                          ) ? (
                                            <>
                                              <div className="list-desc-title address-title">
                                                <Text
                                                  field={
                                                    item.fields
                                                      .formAddressHeading
                                                  }
                                                />
                                              </div>
                                              <div className="list-desc-details address-details">
                                                <RichText
                                                  field={
                                                    item.fields
                                                      .formAddressContact
                                                  }
                                                />
                                              </div>{" "}
                                            </>
                                          ) : null}
                                        </div>
                                        <div className="col-xxl-3 col-lg-6 col-md-6">
                                          {getFieldValue(
                                            item.fields,
                                            "formFaxContact"
                                          ) ||
                                          getFieldValue(
                                            item.fields,
                                            "formEmailContact"
                                          ) ? (
                                            <>
                                              <div className="list-desc-title">
                                                <Text
                                                  field={
                                                    item.fields.formFaxHeading
                                                  }
                                                />
                                              </div>
                                              <div className="list-desc-details">
                                                <Text
                                                  field={
                                                    item.fields.formFaxContact
                                                  }
                                                />
                                              </div>
                                              <div className="list-desc-title">
                                                <Text
                                                  field={
                                                    item.fields.formEmailHeading
                                                  }
                                                />
                                              </div>
                                              <div className="list-desc-details">
                                                <Text
                                                  field={
                                                    item.fields.formEmailContact
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </div>

                                <div className="col-xxl-2 col-md-2">
                                  {showMultipleLinks() ? (
                                    <>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="text-center">
                                            <div className="row-label-style">
                                              {/* <Text field={label2023} /> */}
                                              {Label_Current_Year}
                                            </div>
                                            {item.fields
                                              .isFormLinkFromDictionary
                                              ?.value === false
                                              ? createLinkIconNMP({
                                                  link: item.fields.formLink,
                                                  fromFile:
                                                    item.fields?.fromFile
                                                      ?.value,
                                                  index,
                                                  language:
                                                    item.fields.language,
                                                  className:
                                                    "list-icon-download",
                                                  multiLanguage: false,
                                                  imageName: "Group_2641.svg",
                                                  tabName: formsFields?.tabName,
                                                  byPassAccessControl: true,
                                                  isNYAffinityMember,
                                                  customPopupContent:
                                                    getFieldValue(
                                                      item.fields,
                                                      "customPopupContent"
                                                    ),
                                                  cls_vname:
                                                    MemberStateName +
                                                    "_" +
                                                    memberLOB +
                                                    "_" +
                                                    item?.displayName.replace(
                                                      /\s+/g,
                                                      "_"
                                                    ),
                                                })
                                              : createLinkIconNMP({
                                                  link: item.fields.formLink,
                                                  fromFile:
                                                    item.fields?.fromFile
                                                      ?.value,
                                                  index,
                                                  language: "",
                                                  className:
                                                    "list-icon-download",
                                                  multiLanguage: false,
                                                  imageName: "Group_2641.svg",
                                                  tabName: formsFields?.tabName,
                                                  distItem,
                                                  MemberStateName,
                                                  memberLOB,
                                                  name: item.fields.docName
                                                    ?.value,
                                                  planId,
                                                  programId,
                                                  selectedYear: record?.Year,
                                                  isYearSpecific,
                                                  byPassAccessControl: true,
                                                  isNYAffinityMember,
                                                  customPopupContent:
                                                    getFieldValue(
                                                      item.fields,
                                                      "customPopupContent"
                                                    ),
                                                  cls_vname:
                                                    MemberStateName +
                                                    "_" +
                                                    memberLOB +
                                                    "_" +
                                                    item?.displayName.replace(
                                                      /\s+/g,
                                                      "_"
                                                    ),
                                                })}
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="text-center">
                                            <div className="row-label-style">
                                              {/* <Text field={label2024} /> */}
                                              {Label_Implementation_Year}
                                            </div>
                                            {item.fields
                                              .isFormLinkFromDictionary
                                              ?.value === false
                                              ? createLinkIconNMP({
                                                  link: item.fields.formLink,
                                                  fromFile:
                                                    item.fields?.fromFile
                                                      ?.value,
                                                  index,
                                                  language:
                                                    item.fields.language,
                                                  className:
                                                    "list-icon-download",
                                                  multiLanguage: false,
                                                  imageName: "Group_2641.svg",
                                                  tabName: formsFields?.tabName,
                                                  byPassAccessControl: true,
                                                  isNYAffinityMember,
                                                  customPopupContent:
                                                    getFieldValue(
                                                      item.fields,
                                                      "customPopupContent"
                                                    ),
                                                  cls_vname:
                                                    MemberStateName +
                                                    "_" +
                                                    memberLOB +
                                                    "_" +
                                                    item?.displayName.replace(
                                                      /\s+/g,
                                                      "_"
                                                    ),
                                                })
                                              : createLinkIconNMP_CY2024({
                                                  link: item.fields.formLink,
                                                  fromFile:
                                                    item.fields?.fromFile
                                                      ?.value,
                                                  index,
                                                  language: "",
                                                  className:
                                                    "list-icon-download",
                                                  multiLanguage: false,
                                                  imageName: "Group_2641.svg",
                                                  tabName: formsFields?.tabName,
                                                  distItem,
                                                  MemberStateName,
                                                  memberLOB,
                                                  name: item.fields.docName
                                                    ?.value,
                                                  planId,
                                                  programId,
                                                  selectedYear: record?.Year,
                                                  isYearSpecific,
                                                  byPassAccessControl: true,
                                                  isNYAffinityMember,
                                                  customPopupContent:
                                                    getFieldValue(
                                                      item.fields,
                                                      "customPopupContent"
                                                    ),
                                                  cls_vname:
                                                    MemberStateName +
                                                    "_" +
                                                    memberLOB +
                                                    "_" +
                                                    item?.displayName.replace(
                                                      /\s+/g,
                                                      "_"
                                                    ),
                                                })}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="text-right">
                                        {item.fields.isFormLinkFromDictionary
                                          ?.value === false
                                          ? createLinkIconNMP({
                                              link: item.fields.formLink,
                                              fromFile:
                                                item.fields?.fromFile?.value,
                                              index,
                                              language: item.fields.language,
                                              className: "list-icon-download",
                                              multiLanguage: false,
                                              imageName: "Group_2641.svg",
                                              tabName: formsFields?.tabName,
                                              byPassAccessControl: true,
                                              isNYAffinityMember,
                                              customPopupContent: getFieldValue(
                                                item.fields,
                                                "customPopupContent"
                                              ),
                                              cls_vname:
                                                MemberStateName +
                                                "_" +
                                                memberLOB +
                                                "_" +
                                                item?.displayName.replace(
                                                  /\s+/g,
                                                  "_"
                                                ),
                                            })
                                          : createLinkIconNMP({
                                              link: item.fields.formLink,
                                              fromFile:
                                                item.fields?.fromFile?.value,
                                              index,
                                              language: "",
                                              className: "list-icon-download",
                                              multiLanguage: false,
                                              imageName: "Group_2641.svg",
                                              tabName: formsFields?.tabName,
                                              distItem,
                                              MemberStateName,
                                              memberLOB,
                                              name: item.fields.docName?.value,
                                              planId,
                                              programId,
                                              selectedYear: record?.Year,
                                              isYearSpecific,
                                              byPassAccessControl: true,
                                              isNYAffinityMember,
                                              cy2024ImplementationYear,
                                              customPopupContent: getFieldValue(
                                                item.fields,
                                                "customPopupContent"
                                              ),
                                              cls_vname:
                                                MemberStateName +
                                                "_" +
                                                memberLOB +
                                                "_" +
                                                item?.displayName.replace(
                                                  /\s+/g,
                                                  "_"
                                                ),
                                            })}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        }
                      } else {
                        if (
                          // Checks whether the form is applicaple to this NY Medicaid member
                          NYInclusion(
                            item.fields.id,
                            programId,
                            MemberStateName,
                            memberLOB,
                            isNYAffinityMember
                          ) &&
                          (item.fields.excludePlanIds?.value === "" ||
                            (item.fields.excludePlanIds?.value !== "" &&
                              item.fields.excludePlanIds?.value
                                .toString()
                                .split(",")
                                .indexOf(record?.PlanID) < 0))
                        ) {
                          return (
                            <li
                              className="list-group-item cards cards-md"
                              key={index}
                            >
                              <div className="row">
                                <div className="col-xxl-2 col-md-3 list-title card-titles">
                                  <Text field={item.fields.formTitle} />
                                </div>
                                <div className="col-xxl-8 col-md-7 list-desc">
                                  {item.fields.isSubmitOnline.value === true ? (
                                    <div>
                                      <RichText
                                        field={
                                          item.fields.submitOnlineDescription
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {getFieldValue(
                                        item.fields,
                                        "formDescription"
                                      ) ? (
                                        <div>
                                          <RichText
                                            field={item.fields.formDescription}
                                          />
                                        </div>
                                      ) : null}
                                    </>
                                  )}

                                  {getFieldValue(
                                    item.fields,
                                    "formFaxContact"
                                  ) ||
                                  getFieldValue(
                                    item.fields,
                                    "formAddressContact"
                                  ) ? (
                                    <>
                                      <hr className="dropdown-divider d-sm-block d-md-none" />
                                    </>
                                  ) : null}

                                  {getFieldValue(
                                    item.fields,
                                    "formFaxContact"
                                  ) ||
                                  getFieldValue(
                                    item.fields,
                                    "formAddressContact"
                                  ) ? (
                                    <>
                                      <div className="row">
                                        <div className="col-xxl-4 col-lg-6 col-md-6">
                                          {getFieldValue(
                                            item.fields,
                                            "formAddressContact"
                                          ) ? (
                                            <>
                                              <div className="list-desc-title address-title">
                                                <Text
                                                  field={
                                                    item.fields
                                                      .formAddressHeading
                                                  }
                                                />
                                              </div>
                                              <div className="list-desc-details address-details">
                                                <RichText
                                                  field={
                                                    item.fields
                                                      .formAddressContact
                                                  }
                                                />
                                              </div>{" "}
                                            </>
                                          ) : null}
                                        </div>
                                        <div className="col-xxl-3 col-lg-6 col-md-6">
                                          {getFieldValue(
                                            item.fields,
                                            "formFaxContact"
                                          ) ? (
                                            <>
                                              <div className="list-desc-title">
                                                <Text
                                                  field={
                                                    item.fields.formFaxHeading
                                                  }
                                                />
                                              </div>
                                              <div className="list-desc-details">
                                                <Text
                                                  field={
                                                    item.fields.formFaxContact
                                                  }
                                                />
                                              </div>
                                              <div className="list-desc-title">
                                                <Text
                                                  field={
                                                    item.fields
                                                      ?.formEmailHeading
                                                  }
                                                />
                                              </div>
                                              <div className="list-desc-details">
                                                <Text
                                                  field={
                                                    item.fields
                                                      ?.formEmailContact
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </div>

                                <div className="col-xxl-2 col-md-2 list-multi-link-drp">
                                  {showMultipleLinks() ? (
                                    <>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="text-center">
                                            <div className="row-label-style">
                                              {/* <Text field={label2023} /> */}
                                              {Label_Current_Year}
                                            </div>
                                            {item.fields
                                              .isFormLinkFromDictionary
                                              ?.value === false
                                              ? createLinkIconNMP({
                                                  link: item.fields.formLink,
                                                  fromFile:
                                                    item.fields?.fromFile
                                                      ?.value,
                                                  index,
                                                  language:
                                                    item.fields.language,
                                                  className:
                                                    "list-icon-download",
                                                  multiLanguage: false,
                                                  imageName: "Group_2641.svg",
                                                  tabName: formsFields?.tabName,
                                                  byPassAccessControl: true,
                                                  isNYAffinityMember,
                                                  customPopupContent:
                                                    getFieldValue(
                                                      item.fields,
                                                      "customPopupContent"
                                                    ),
                                                  cls_vname:
                                                    MemberStateName +
                                                    "_" +
                                                    memberLOB +
                                                    "_" +
                                                    item?.displayName.replace(
                                                      /\s+/g,
                                                      "_"
                                                    ),
                                                })
                                              : createLinkIconNMP({
                                                  link: item.fields.formLink,
                                                  fromFile:
                                                    item.fields?.fromFile
                                                      ?.value,
                                                  index,
                                                  language: "",
                                                  className:
                                                    "list-icon-download",
                                                  multiLanguage: false,
                                                  imageName: "Group_2641.svg",
                                                  tabName: formsFields?.tabName,
                                                  distItem,
                                                  MemberStateName,
                                                  memberLOB,
                                                  name: item.fields.docName
                                                    ?.value,
                                                  planId,
                                                  programId,
                                                  selectedYear: record?.Year,
                                                  isYearSpecific,
                                                  byPassAccessControl: true,
                                                  isNYAffinityMember,
                                                  customPopupContent:
                                                    getFieldValue(
                                                      item.fields,
                                                      "customPopupContent"
                                                    ),
                                                  cls_vname:
                                                    MemberStateName +
                                                    "_" +
                                                    memberLOB +
                                                    "_" +
                                                    item?.displayName.replace(
                                                      /\s+/g,
                                                      "_"
                                                    ),
                                                })}
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="text-center">
                                            <div className="row-label-style">
                                              {/* <Text field={label2024} /> */}
                                              {Label_Implementation_Year}
                                            </div>
                                            {item.fields
                                              .isFormLinkFromDictionary
                                              ?.value === false
                                              ? createLinkIconNMP({
                                                  link: item.fields.formLink,
                                                  fromFile:
                                                    item.fields?.fromFile
                                                      ?.value,
                                                  index,
                                                  language:
                                                    item.fields.language,
                                                  className:
                                                    "list-icon-download",
                                                  multiLanguage: false,
                                                  imageName: "Group_2641.svg",
                                                  tabName: formsFields?.tabName,
                                                  byPassAccessControl: true,
                                                  isNYAffinityMember,
                                                  customPopupContent:
                                                    getFieldValue(
                                                      item.fields,
                                                      "customPopupContent"
                                                    ),
                                                  cls_vname:
                                                    MemberStateName +
                                                    "_" +
                                                    memberLOB +
                                                    "_" +
                                                    item?.displayName.replace(
                                                      /\s+/g,
                                                      "_"
                                                    ),
                                                })
                                              : createLinkIconNMP_CY2024({
                                                  link: item.fields.formLink,
                                                  fromFile:
                                                    item.fields?.fromFile
                                                      ?.value,
                                                  index,
                                                  language: "",
                                                  className:
                                                    "list-icon-download",
                                                  multiLanguage: false,
                                                  imageName: "Group_2641.svg",
                                                  tabName: formsFields?.tabName,
                                                  distItem,
                                                  MemberStateName,
                                                  memberLOB,
                                                  name: item.fields.docName
                                                    ?.value,
                                                  planId,
                                                  programId,
                                                  selectedYear: record?.Year,
                                                  isYearSpecific,
                                                  byPassAccessControl: true,
                                                  isNYAffinityMember,
                                                  customPopupContent:
                                                    getFieldValue(
                                                      item.fields,
                                                      "customPopupContent"
                                                    ),
                                                  cls_vname:
                                                    MemberStateName +
                                                    "_" +
                                                    memberLOB +
                                                    "_" +
                                                    item?.displayName.replace(
                                                      /\s+/g,
                                                      "_"
                                                    ),
                                                })}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="text-right">
                                        {item.fields.formLinkLangList.length >
                                        3 ? (
                                          <>
                                            <div>
                                              <a
                                                className="list-icon-download"
                                                href="#"
                                                id={index}
                                                data-mdb-toggle="dropdown"
                                                cls_vname={
                                                  MemberStateName +
                                                  "_" +
                                                  memberLOB +
                                                  "_" +
                                                  item?.displayName.replace(
                                                    /\s+/g,
                                                    "_"
                                                  )
                                                }
                                              >
                                                <img
                                                  src={`${ICON_PATH}/Group_2641.svg`}
                                                  alt={
                                                    formsFields?.tabName.value
                                                  }
                                                  id={`downloadIcon${index}`}
                                                />
                                              </a>

                                              <div className="dropdown-menu dropdown-menu-right dropdown-default">
                                                {item.fields.formLinkLangList?.map(
                                                  (e, index) => {
                                                    return e.fields.isDoc
                                                      ?.value === true
                                                      ? createFileIconNMP(
                                                          e.fields.formDoc,
                                                          index,
                                                          e.fields.language,
                                                          "dropdown-item",
                                                          true,
                                                          null,
                                                          MemberStateName +
                                                            "_" +
                                                            memberLOB +
                                                            "_" +
                                                            item?.displayName.replace(
                                                              /\s+/g,
                                                              "_"
                                                            )
                                                        )
                                                      : createLinkIconNMP({
                                                          link: e.fields
                                                            .formLink,
                                                          index,
                                                          language:
                                                            e.fields.language,
                                                          className:
                                                            "dropdown-item",
                                                          multiLanguage: true,
                                                          imageName:
                                                            "Group_2641.svg",
                                                          byPassAccessControl: true,
                                                          cls_vname:
                                                            MemberStateName +
                                                            "_" +
                                                            memberLOB +
                                                            "_" +
                                                            item?.displayName.replace(
                                                              /\s+/g,
                                                              "_"
                                                            ),
                                                        });
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        ) : item.fields.isFormLinkFromDictionary
                                            ?.value === false ? (
                                          createLinkIconNMP({
                                            link: item.fields.formLink,
                                            fromFile:
                                              item.fields?.fromFile?.value,
                                            index,
                                            language: item.fields.language,
                                            className: "list-icon-download",
                                            multiLanguage: false,
                                            imageName: "Group_2641.svg",
                                            tabName: formsFields?.tabName,
                                            byPassAccessControl: true,
                                            isNYAffinityMember,
                                            customPopupContent: getFieldValue(
                                              item.fields,
                                              "customPopupContent"
                                            ),
                                            cls_vname:
                                              MemberStateName +
                                              "_" +
                                              memberLOB +
                                              "_" +
                                              item?.displayName.replace(
                                                /\s+/g,
                                                "_"
                                              ),
                                          })
                                        ) : (
                                          createLinkIconNMP({
                                            link: item.fields.formLink,
                                            fromFile:
                                              item.fields?.fromFile?.value,
                                            index,
                                            language: "",
                                            className: "list-icon-download",
                                            multiLanguage: false,
                                            imageName: "Group_2641.svg",
                                            tabName: formsFields?.tabName,
                                            distItem,
                                            MemberStateName,
                                            memberLOB,
                                            name: item.fields.docName?.value,
                                            planId,
                                            programId,
                                            selectedYear: record?.Year,
                                            isYearSpecific,
                                            byPassAccessControl: true,
                                            isNYAffinityMember,
                                            cy2024ImplementationYear,
                                            customPopupContent: getFieldValue(
                                              item.fields,
                                              "customPopupContent"
                                            ),
                                            cls_vname:
                                              MemberStateName +
                                              "_" +
                                              memberLOB +
                                              "_" +
                                              item?.displayName.replace(
                                                /\s+/g,
                                                "_"
                                              ),
                                          })
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        }
                      }
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>
        </>
      )}
      {showNoInfoAvailable() && (
        <>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Text field={docsFields.TermedMemberMessage} />
            </div>
          </div>
        </>
      )}
      {formListArray && (
        <>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Text field={docsFields.TermedMemberMessage} />
            </div>
          </div>
        </>
      )}
      {NM_Medicaid_Message && (
        <>
          <div className="row">
            <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
              <h5 className="tab-title title2">{formsFields?.tabName.value}</h5>
            </div>
            <div className="col-md-12 col-sm-12">
              <Text field={docsFields.TermedMemberMessage} />
            </div>
          </div>
        </>
      )}

      {/* Forms Section end */}
      <div ref={langRef}>
        <hr className="dropdown-divider hr-divider" />
      </div>

      {/* Documents Section start */}

      {distItem && !MedicaidTermedMember && (
        <>
          <div className="row">
            <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
              <h5 className="tab-title title2">{docsFields?.tabName.value}</h5>
            </div>
            {listRecords?.length > 1 && (
              <div className="col-xxl-9 col-lg-8 col-md-6 col-sm-12 document-plan-year coverage-page">
                <div className="plan-year-block">
                  <div>{docsFields?.planYear.value}</div>
                  <div>
                    <CustomSelect
                      field={{
                        name: "documentsPlanYears",
                        value: yearFromMyCoverage
                          ? yearFromMyCoverage
                          : record?.Year,
                      }}
                      className="form-select"
                      aria-label={getFieldValue(formsFields, "planYear")}
                      options={recordList.map((item) => ({
                        value: item.Year,
                        label: item.Year,
                      }))}
                      isFormik={false}
                      onChange={handleYearSelect}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row ">
            <div className="col-md-12">
              <ul className="list-group loder-position">
                {innerSpinnerHTML?.[COVERAGE_ELIGIBILITY]}
                {Array.isArray(docsList) && docsList.length && (
                  <>
                    {docsList?.map((item, index) => {
                      let linkIds = [];
                      helpfulLinkIds.forEach((link) => {
                        if (link.id === getFieldValue(item.fields, "id")) {
                          linkIds = link;
                        }
                      });
                      if (
                        linkIds &&
                        linkIds.programIds &&
                        linkIds.programIds.length > 0
                      ) {
                        if (linkIds.programIds.includes(programId)) {
                          if (
                            linkIds.planIds &&
                            linkIds.planIds.length > 0 &&
                            !linkIds.planIds.includes(planId)
                          ) {
                            return;
                          }
                        } else {
                          return;
                        }
                      }
                      if (index === docsList.length - 1) {
                        if (
                          // Checks whether the document is applicaple to this NY Medicaid member
                          NYInclusion(
                            item.fields.id,
                            programId,
                            MemberStateName,
                            memberLOB,
                            isNYAffinityMember
                          ) &&
                          (item.fields.excludePlanIds?.value === "" ||
                            (item.fields.excludePlanIds?.value !== "" &&
                              item.fields.excludePlanIds?.value
                                .toString()
                                .split(",")
                                .indexOf(record?.PlanID) < 0))
                        ) {
                          return (
                            <li
                              className="list-group-item last-card-tab1 cards-md"
                              key={index}
                            >
                              <div className="row">
                                <div className="col-xxl-2 col-md-3 list-title card-titles">
                                  <Text field={item.fields.documentTitle} />
                                </div>
                                <div className="col-xxl-8 col-md-7 list-desc2">
                                  <div>
                                    <Text
                                      field={item.fields.documentDescription}
                                    />
                                  </div>
                                </div>
                                <div className="col-xxl-2 col-md-2">
                                  {showMultipleLinks() ? (
                                    <>
                                      <div className="row">
                                        <div className="col-6">
                                          {!item.fields.noLink?.value && ( // member grevience has no download icon
                                            <div className="text-center">
                                              <div className="row-label-style">
                                                {/* <Text field={label2023} /> */}
                                                {Label_Current_Year}
                                              </div>
                                              {item.fields.isDoc?.value ===
                                                true &&
                                              record?.Year ==
                                                distItem?.phrases.comingSoonYear
                                                ? createFileIconNMP(
                                                    item.fields.Doc,
                                                    index,
                                                    "",
                                                    "list-icon-download",
                                                    false,
                                                    docsFFields?.tabName,
                                                    MemberStateName +
                                                      "_" +
                                                      memberLOB +
                                                      "_" +
                                                      item?.displayName.replace(
                                                        /\s+/g,
                                                        "_"
                                                      )
                                                  )
                                                : createLinkIconNMP({
                                                    link: item.fields
                                                      .documentLink,
                                                    fromFile:
                                                      item.fields?.fromFile
                                                        ?.value,
                                                    index,
                                                    language: "",
                                                    className:
                                                      "list-icon-download",
                                                    multiLanguage: false,
                                                    imageName: "Group_2641.svg",
                                                    tabName:
                                                      docsFields?.tabName,
                                                    distItem,
                                                    MemberStateName,
                                                    memberLOB,
                                                    name: item.fields.docName
                                                      ?.value,
                                                    planId,
                                                    programId,
                                                    selectedYear: record?.Year,
                                                    isYearSpecific,
                                                    byPassAccessControl: true,
                                                    isNYAffinityMember,
                                                    customPopupContent:
                                                      getFieldValue(
                                                        item.fields,
                                                        "customPopupContent"
                                                      ),
                                                    cls_vname:
                                                      MemberStateName +
                                                      "_" +
                                                      memberLOB +
                                                      "_" +
                                                      item?.displayName.replace(
                                                        /\s+/g,
                                                        "_"
                                                      ),
                                                  })}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-6">
                                          {!item.fields.noLink?.value && ( // member grevience has no download icon
                                            <div className="text-center">
                                              <div className="row-label-style">
                                                {/* <Text field={label2024} /> */}
                                                {Label_Implementation_Year}
                                              </div>
                                              {item.fields.isDoc?.value ===
                                                true &&
                                              record?.Year ==
                                                distItem?.phrases.comingSoonYear
                                                ? createFileIconNMP(
                                                    item.fields.Doc,
                                                    index,
                                                    "",
                                                    "list-icon-download",
                                                    false,
                                                    docsFields?.tabName,
                                                    MemberStateName +
                                                      "_" +
                                                      memberLOB +
                                                      "_" +
                                                      item?.displayName.replace(
                                                        /\s+/g,
                                                        "_"
                                                      )
                                                  )
                                                : createLinkIconNMP_CY2024({
                                                    link: item.fields
                                                      .documentLink,
                                                    fromFile:
                                                      item.fields?.fromFile
                                                        ?.value,
                                                    index,
                                                    language: "",
                                                    className:
                                                      "list-icon-download",
                                                    multiLanguage: false,
                                                    imageName: "Group_2641.svg",
                                                    tabName:
                                                      docsFields?.tabName,
                                                    distItem,
                                                    MemberStateName,
                                                    memberLOB,
                                                    name: item.fields.docName
                                                      ?.value,
                                                    planId,
                                                    programId,
                                                    selectedYear: record?.Year,
                                                    isYearSpecific,
                                                    byPassAccessControl: true,
                                                    isNYAffinityMember,
                                                    customPopupContent:
                                                      getFieldValue(
                                                        item.fields,
                                                        "customPopupContent"
                                                      ),
                                                    cls_vname:
                                                      MemberStateName +
                                                      "_" +
                                                      memberLOB +
                                                      "_" +
                                                      item?.displayName.replace(
                                                        /\s+/g,
                                                        "_"
                                                      ),
                                                  })}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {!item.fields.noLink?.value && ( // member grevience has no download icon
                                        <div className="text-right">
                                          {item.fields.isDoc?.value === true &&
                                          record?.Year ==
                                            distItem?.phrases.comingSoonYear
                                            ? createFileIconNMP(
                                                item.fields.Doc,
                                                index,
                                                "",
                                                "list-icon-download",
                                                false,
                                                docsFields?.tabName,
                                                MemberStateName +
                                                  "_" +
                                                  memberLOB +
                                                  "_" +
                                                  item?.displayName.replace(
                                                    /\s+/g,
                                                    "_"
                                                  )
                                              )
                                            : createLinkIconNMP({
                                                link: item.fields.documentLink,
                                                fromFile:
                                                  item.fields?.fromFile?.value,
                                                index,
                                                language: "",
                                                className: "list-icon-download",
                                                multiLanguage: false,
                                                imageName: "Group_2641.svg",
                                                tabName: docsFields?.tabName,
                                                distItem,
                                                MemberStateName,
                                                memberLOB,
                                                name: item.fields.docName
                                                  ?.value,
                                                planId,
                                                programId,
                                                selectedYear: record?.Year,
                                                isYearSpecific,
                                                byPassAccessControl: true,
                                                isNYAffinityMember,
                                                cy2024ImplementationYear,
                                                customPopupContent:
                                                  getFieldValue(
                                                    item.fields,
                                                    "customPopupContent"
                                                  ),
                                                cls_vname:
                                                  MemberStateName +
                                                  "_" +
                                                  memberLOB +
                                                  "_" +
                                                  item?.displayName.replace(
                                                    /\s+/g,
                                                    "_"
                                                  ),
                                              })}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        }
                      } else {
                        if (
                          // Checks whether the document is applicaple to this NY Medicaid member
                          NYInclusion(
                            item.fields.id,
                            programId,
                            MemberStateName,
                            memberLOB,
                            isNYAffinityMember
                          ) &&
                          (item.fields.excludePlanIds?.value === "" ||
                            (item.fields.excludePlanIds?.value !== "" &&
                              item.fields.excludePlanIds?.value
                                .toString()
                                .split(",")
                                .indexOf(record?.PlanID) < 0))
                        ) {
                          return (
                            <li
                              className="list-group-item cards cards-md"
                              key={index}
                              // id={item.fields.docName.value
                              //   .split(" ")
                              //   .join("_")}
                            >
                              <div className="row">
                                <div className="col-xxl-2 col-md-3 list-title card-titles">
                                  <Text field={item.fields.documentTitle} />
                                </div>
                                <div className="col-xxl-8 col-md-7 list-desc2">
                                  <div>
                                    <Text
                                      field={item.fields.documentDescription}
                                    />
                                  </div>
                                </div>
                                <div className="col-xxl-2 col-md-2">
                                  {showMultipleLinks() ? (
                                    <>
                                      <div className="row">
                                        <div className="col-6">
                                          {!item.fields.noLink?.value && ( // member grevience has no download icon
                                            <div className="text-center">
                                              <div className="row-label-style">
                                                {/* <Text field={label2023} /> */}
                                                {Label_Current_Year}
                                              </div>
                                              {item.fields.isDoc?.value ===
                                                true &&
                                              record?.Year ==
                                                distItem?.phrases.comingSoonYear
                                                ? createFileIconNMP(
                                                    item.fields.Doc,
                                                    index,
                                                    "",
                                                    "list-icon-download",
                                                    false,
                                                    docsFields?.tabName,
                                                    MemberStateName +
                                                      "_" +
                                                      memberLOB +
                                                      "_" +
                                                      item?.displayName.replace(
                                                        /\s+/g,
                                                        "_"
                                                      )
                                                  )
                                                : createLinkIconNMP({
                                                    link: item.fields
                                                      .documentLink,
                                                    fromFile:
                                                      item.fields?.fromFile
                                                        ?.value,
                                                    index,
                                                    language: "",
                                                    className:
                                                      "list-icon-download",
                                                    multiLanguage: false,
                                                    imageName: "Group_2641.svg",
                                                    tabName:
                                                      docsFields?.tabName,
                                                    distItem,
                                                    MemberStateName,
                                                    memberLOB,
                                                    name: item.fields.docName
                                                      ?.value,
                                                    planId,
                                                    programId,
                                                    selectedYear: record?.Year,
                                                    isYearSpecific,
                                                    byPassAccessControl: true,
                                                    isNYAffinityMember,
                                                    customPopupContent:
                                                      getFieldValue(
                                                        item.fields,
                                                        "customPopupContent"
                                                      ),
                                                    cls_vname:
                                                      MemberStateName +
                                                      "_" +
                                                      memberLOB +
                                                      "_" +
                                                      item?.displayName.replace(
                                                        /\s+/g,
                                                        "_"
                                                      ),
                                                  })}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-6">
                                          {!item.fields.noLink?.value && ( // member grevience has no download icon
                                            <div className="text-center">
                                              <div className="row-label-style">
                                                {/* <Text field={label2024} /> */}
                                                {Label_Implementation_Year}
                                              </div>
                                              {item.fields.isDoc?.value ===
                                                true &&
                                              record?.Year ==
                                                distItem?.phrases.comingSoonYear
                                                ? createFileIconNMP(
                                                    item.fields.Doc,
                                                    index,
                                                    "",
                                                    "list-icon-download",
                                                    false,
                                                    docsFields?.tabName,
                                                    MemberStateName +
                                                      "_" +
                                                      memberLOB +
                                                      "_" +
                                                      item?.displayName.replace(
                                                        /\s+/g,
                                                        "_"
                                                      )
                                                  )
                                                : createLinkIconNMP_CY2024({
                                                    link: item.fields
                                                      .documentLink,
                                                    fromFile:
                                                      item.fields?.fromFile
                                                        ?.value,
                                                    index,
                                                    language: "",
                                                    className:
                                                      "list-icon-download",
                                                    multiLanguage: false,
                                                    imageName: "Group_2641.svg",
                                                    tabName:
                                                      docsFields?.tabName,
                                                    distItem,
                                                    MemberStateName,
                                                    memberLOB,
                                                    name: item.fields.docName
                                                      ?.value,
                                                    planId,
                                                    programId,
                                                    selectedYear: record?.Year,
                                                    isYearSpecific,
                                                    byPassAccessControl: true,
                                                    isNYAffinityMember,
                                                    customPopupContent:
                                                      getFieldValue(
                                                        item.fields,
                                                        "customPopupContent"
                                                      ),
                                                    cls_vname:
                                                      MemberStateName +
                                                      "_" +
                                                      memberLOB +
                                                      "_" +
                                                      item?.displayName.replace(
                                                        /\s+/g,
                                                        "_"
                                                      ),
                                                  })}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {!item.fields.noLink?.value && ( // member grevience has no download icon
                                        <div className="text-right">
                                          {item.fields.isDoc?.value === true &&
                                          record?.Year ==
                                            distItem?.phrases.comingSoonYear
                                            ? createFileIconNMP(
                                                item.fields.Doc,
                                                index,
                                                "",
                                                "list-icon-download",
                                                false,
                                                docsFields?.tabName,
                                                MemberStateName +
                                                  "_" +
                                                  memberLOB +
                                                  "_" +
                                                  item?.displayName.replace(
                                                    /\s+/g,
                                                    "_"
                                                  )
                                              )
                                            : createLinkIconNMP({
                                                link: item.fields.documentLink,
                                                fromFile:
                                                  item.fields?.fromFile?.value,
                                                index,
                                                language: "",
                                                className: "list-icon-download",
                                                multiLanguage: false,
                                                imageName: "Group_2641.svg",
                                                tabName: docsFields?.tabName,
                                                distItem,
                                                MemberStateName,
                                                memberLOB,
                                                name: item.fields.docName
                                                  ?.value,
                                                planId,
                                                programId,
                                                selectedYear: record?.Year,
                                                isYearSpecific,
                                                byPassAccessControl: true,
                                                isNYAffinityMember,
                                                cy2024ImplementationYear,
                                                customPopupContent:
                                                  getFieldValue(
                                                    item.fields,
                                                    "customPopupContent"
                                                  ),
                                                cls_vname:
                                                  MemberStateName +
                                                  "_" +
                                                  memberLOB +
                                                  "_" +
                                                  item?.displayName.replace(
                                                    /\s+/g,
                                                    "_"
                                                  ),
                                              })}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        }
                      }
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>
        </>
      )}
      {isTermedMember && isMedicaid && (
        <>
          <div className="row">
            <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
              <h5 className="tab-title title2">{docsFields?.tabName.value}</h5>
            </div>
            <div className="col-md-12 col-sm-12">
              <Text field={docsFields.TermedMemberMessage} />
            </div>
          </div>
        </>
      )}
      {Medicare_Dual_TermedMessage && (
        <>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Text field={docsFields.TermedMemberMessage} />
            </div>
          </div>
        </>
      )}
      {/* Documents Section end */}
    </div>
  );
};

export default NonMarketplaceFormDocs;
