import React, { useContext } from "react";
import ReadMore from "../Common-Health-Records/ReadMore";
import Status from "./Status";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import DisplayTitleItem from "../MyHealthRecord-Layout/Common/DisplayTitleItem";
import { MARKETPLACE } from "../../define.constants";
import { AppContext } from "../../contexts/app.context";

const ServiceAuthorizationMobileRecords = (props) => {
  const { fields, data, next } = props;
  const { memberLOB } = useContext(AppContext);
  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();

  return (
    <ul className="list-group d-sm-block d-md-none" id="recordsToPrint">
      {data?.map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="row">
            <DisplayTitleItem
              nameField={fields.lblServiceAuthorization}
              valueField={
                fields.lblServiceAuthorizationSymbol.value +
                item.AuthorizationID
              }
              outClass={"col-list-title"}
            />
            <>
              <Status
                outerClass={"col-xl-3 col-md-6"}
                status={item.Status?.trim()}
                fields={fields}
              />
              <DisplayItem
                nameField={fields.lblSubmittedDate}
                valueField={item.DateOfRequest}
                outClass={"col-xl-3 col-md-6"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />
            </>
            <DisplayItem
              nameField={fields.lblServiceDescription}
              valueField={item.ProcedureDescription}
              outClass={"col-xl-3 col-md-6"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
          </div>
          <div id={"readMoreDetails" + index} className="">
            <ReadMore props={fields}>
              <DisplayItem
                nameField={fields.lblReferredFrom}
                valueField={item.ReferredFromProviderFullName}
                outClass={"col-xl-3 col-md-6"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblReferredTo}
                valueField={item.ReferredToProviderFullName}
                outClass={"col-xl-3 col-md-6"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblReferredToSpecialty}
                valueField={
                  item.ReferredToSpecialty?.length !== 0
                    ? item.ReferredToSpecialty[0].Specialty
                    : null
                }
                outClass={"col-xl-3 col-md-6"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />

              <div className="close-btn" id={"closeBtnDetails" + index}></div>
            </ReadMore>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ServiceAuthorizationMobileRecords;
