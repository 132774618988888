import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useServiceAuthorizationListAPI } from "../../hooks/useServiceAuthorizationListAPI";
import ServiceAuthorizationsRecords from "./ServiceAuthorizationsRecords";
import ServiceAuthorizationMobileRecords from "./ServiceAuthorizationMobileRecords";
import {
  printReportDate,
  paginationPrint,
  pagination,
} from "../Common-Health-Records/Helper";
import ServiceDescription from "../MyHealthRecord-Layout/Common/ServiceDescription";
import {
  AZMCC_PROGRAMID,
  NUMBER_OF_RECORDS_PER_API_CALL,
  NY,
  WA,
  MI,
  AZ,
  WAFCPRGMID,
  MARKETPLACE,
  NYMCC_PROGRAMID,
  DUAL,
  GLOBAL_VARIABLE,
  NON_MP_SA_DISCLAIMER_STATES_1,
  NON_MP_SA_DISCLAIMER_STATES_2,
  NM,
  NM_MEDICAID_ProgramID,
  MEDICAID,
} from "../../define.constants";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_HEALTH_RECORD_VIEW_SERVICE_AUTHORIZATION } from "../../define.widgets";
import { FooterDescription } from "../MyHealthRecord-Layout/Common/FooterDescription";
import { AppContext } from "../../contexts/app.context";

const MyHealthRecordServiceAuthorizations = (props) => {
  const recordsPerPage = parseInt(props.fields.lblPostPerPage.value);
  const { innerSpinnerHTML } = useContext(SpinnerContext);

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  const descriptionprop = props.fields;

  /**Pagination useState Variables */
  const [next, setNext] = useState(recordsPerPage);
  const [page, setPage] = useState(1);
  const [fetchData, setFetchData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  /**Filtering useState Variables */
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [slicedRecords, setSlicedRecords] = useState(null);

  const {
    serviceAuthorizationList,
    isServiceAuthorizationsloaded,
    totalRecords,
  } = useServiceAuthorizationListAPI(
    page,
    NUMBER_OF_RECORDS_PER_API_CALL,
    fetchData
  );
  const {
    programId,
    MemberStateName,
    memberLOB,
    IsFIMCMember,
    IsNYAffinityMember,
    isDualEligible,
  } = useContext(AppContext);
  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();
  //NM  impementation
  const isMedicaid = memberLOB.toLowerCase() === MEDICAID.toLowerCase();
  useEffect(() => {
    if (totalRecords > 0) {
      setFilteredRecords((r) =>
        page > 1
          ? [...r, ...serviceAuthorizationList]
          : serviceAuthorizationList
      );
    }
  }, [serviceAuthorizationList, totalRecords, page]);

  useEffect(() => {
    let slicedRecords = filteredRecords;
    const offset = (currentPage - 1) * recordsPerPage;
    slicedRecords = filteredRecords?.slice(0, offset + recordsPerPage);
    if (totalRecords - offset < recordsPerPage) {
      slicedRecords = filteredRecords?.slice(
        0,
        offset + (totalRecords - offset)
      );
    } else {
      slicedRecords = filteredRecords?.slice(0, offset + recordsPerPage);
    }

    setSlicedRecords(slicedRecords);
  }, [filteredRecords, currentPage]);

  let totalPages = Math.ceil(totalRecords / recordsPerPage);

  const disclaimerdata = () => {
    let disclaimer = null;

    // Special Disclaimer for these states
    const showSpclLongDisclaimer = NON_MP_SA_DISCLAIMER_STATES_1?.includes(
      MemberStateName?.trim()?.toLowerCase()
    );
    const showSpclShortDisclaimer = NON_MP_SA_DISCLAIMER_STATES_2?.includes(
      MemberStateName?.trim()?.toLowerCase()
    );

    if (
      isServiceAuthorizationsloaded &&
      (slicedRecords === null || slicedRecords?.length === 0)
    ) {
      if (MemberStateName == NY.toUpperCase() && programId == NYMCC_PROGRAMID) {
        disclaimer = descriptionprop.lblDescriptionNonMPNY.value;
      } else if (
        MemberStateName != null &&
        MemberStateName.toUpperCase() == NY.toUpperCase() &&
        IsNYAffinityMember != null &&
        IsNYAffinityMember.toUpperCase() == OPTIN_FlAG_Y.toLowerCase()
      ) {
        disclaimer = descriptionprop.lblDescriptionNonMPAY.value;
      } else {
        disclaimer = descriptionprop.lblDescriptionNonMPMI.value;
      }
    } else {
      if (isMarketplaceMember) {
        disclaimer = descriptionprop.lblDescription.value;
      } else if (
        (programId == WAFCPRGMID && MemberStateName == WA.toUpperCase()) ||
        (IsFIMCMember != "" && MemberStateName == WA)
      ) {
        //FIMC 2016 Disclaimer
        disclaimer = descriptionprop.lblDescriptionNonMPMI.value;
      } else if (
        MemberStateName == NY.toUpperCase() &&
        programId == NYMCC_PROGRAMID
      ) {
        disclaimer = descriptionprop.lblDescriptionNonMPNY.value;
      } else if (
        programId == AZMCC_PROGRAMID &&
        MemberStateName.toUpperCase() == AZ.toUpperCase()
      ) {
        disclaimer = descriptionprop.lblDescriptionNonMPAZ.value;
      } else if (
        MemberStateName != null &&
        MemberStateName.toUpperCase() == NY.toUpperCase() &&
        IsNYAffinityMember != null &&
        IsNYAffinityMember.toUpperCase() == OPTIN_FlAG_Y.toLowerCase()
      ) {
        disclaimer = descriptionprop.lblDescriptionNonMPAY.value;
      } else if (
        programId == NM_MEDICAID_ProgramID &&
        isMedicaid &&
        MemberStateName.toUpperCase() == NM.toUpperCase()
      ) {
        disclaimer = descriptionprop.lblDescriptionNMNonMP.value;
      } else {
        disclaimer = descriptionprop.lblDescriptionNonMP.value;
      }
    }

    // Wave 3 States Disclaimer
    if (showSpclLongDisclaimer) {
      disclaimer = descriptionprop.lblDescriptionNonMPMI.value;
    } else if (showSpclShortDisclaimer) {
      disclaimer = descriptionprop.lblDescriptionNonMP.value;
    }

    return disclaimer;
  };

  return (
    <>
      <div
        className="tab-pane fade show active referrals-tab"
        id="serviceAuth"
        role="tabpanel"
        aria-label="tab-1"
      >
        <div className="row no-tab-title">
          <div className="col-md-12 no-date-filter">
            {isServiceAuthorizationsloaded ? (
              slicedRecords === null || slicedRecords?.length === 0 ? (
                <div className="no-records">
                  <Text
                    field={props.fields.lblZeroServiceAuthorizationMessage}
                  />
                </div>
              ) : (
                <>
                  {paginationPrint(totalRecords, next, props.fields)}
                  <ServiceAuthorizationsRecords
                    fields={props.fields}
                    data={slicedRecords}
                  />
                  <ServiceAuthorizationMobileRecords
                    fields={props.fields}
                    data={slicedRecords}
                  />
                  {totalPages !== currentPage && (
                    <a
                      className="show-more hr-contact"
                      onClick={(e) =>
                        pagination(
                          totalRecords,
                          next,
                          page,
                          recordsPerPage,
                          setNext,
                          setPage,
                          currentPage,
                          setCurrentPage,
                          NUMBER_OF_RECORDS_PER_API_CALL,
                          setFetchData
                        )
                      }
                    >
                      {props.fields.lblShowMore.value}
                    </a>
                  )}
                </>
              )
            ) : (
              filteredRecords?.slice(0).length === 0 && (
                <li className="list-group-item">
                  {
                    innerSpinnerHTML?.[
                      MY_HEALTH_RECORD_VIEW_SERVICE_AUTHORIZATION
                    ]
                  }
                  <div className="row field1">
                    <div className="col-sm-12"></div>
                  </div>
                </li>
              )
            )}
          </div>
        </div>
        {printReportDate(props.fields.lblPrintGenerated)}
        <ServiceDescription
          disclaimer={disclaimerdata()}
          contactUs={props.fields.lnkContactUs}
        />
      </div>
    </>
  );
};

export default MyHealthRecordServiceAuthorizations;
