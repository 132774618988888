import React, { useContext } from "react";
import { AppContext } from "../../contexts/app.context";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

const Program = (props) => {
  const { fields, settabshow } = props;
  const { programId } = useContext(AppContext);
  const programIdName = [];

  var result = getFieldValue(fields, "MyDoctor", false)?.title;
  if (result && result !== null) {
    var programObject = result.split("~");
    if (programObject?.length > 1) {
      var programnames = programObject[1].includes(";")
        ? [programObject[1].split(";")]
        : [programObject[1]];
      programnames?.map((item) => {
        if (item === programId) {
          if (!programIdName.includes(item)) {
            programIdName.push(item);
          }
        }
      });
      if (programIdName.length > 0) {
        settabshow(false);
      }
    }
  }
  return <></>;
};
export default Program;
