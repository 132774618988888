import React, { useCallback, useState } from "react";

const useYearDropdown = (props) => {
  const [yearFromMyCoverageDropdown, setYearSelected] = useState({});
  const setYearFromMyCoverageDropdown = useCallback((yearSelected) => {
    setYearSelected(yearSelected);
  }, []);
  return { yearFromMyCoverageDropdown, setYearFromMyCoverageDropdown };
};

export { useYearDropdown };
