import React, { useContext, useEffect, useState } from "react";
import AccountSettingsPersonalConfirmPopUp from "./../AccountSettings-Personal-ConfirmPopUp";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Formik, Form } from "formik";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import { AppContext } from "../../contexts/app.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ACCOUNT_SETTINGS_MY_PROFILE } from "../../define.widgets";
import { GLOBAL_VARIABLE } from "../../define.constants";

function PersonalInformation(props) {
  let { profileList, isDependentLoggedIn } = useContext(AppContext);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const accountStateMyProfile = props?.fields?.MyProfileList[1] ?? [];
  const accountDetailMyProfile = props?.fields?.MyProfileList[0] ?? [];
  const initialValues = {};
  const validationMessage =
    accountDetailMyProfile?.fields?.validationMessage?.value;

  const onSubmit = (submitProps) => {
    submitProps.setSubmitting(false);
  };
  const [hiddenDisclaimer, setHiddenDisclaimer] = useState(false);

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  useEffect(() => {
    if (isDependentLoggedIn === true) {
      setHiddenDisclaimer(true);
    }
  }, [isDependentLoggedIn]);
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => {
          return (
            <Form>
              <li className="list-group-item">
                {innerSpinnerHTML?.[ACCOUNT_SETTINGS_MY_PROFILE]}
                <div className="row">
                  <div className="col-xl-9 col-md-8">
                    <h5 className="tab-sub-title">
                      <label htmlFor="personalInformationTitle">
                        <Text
                          field={
                            accountDetailMyProfile?.fields
                              ?.personalInformationTitle
                          }
                        />
                      </label>
                    </h5>
                  </div>
                  <div className="col-xl-3 col-md-4">
                    <SSORedirectProvider>
                      <AccountSettingsPersonalConfirmPopUp
                        {...accountStateMyProfile}
                      />
                    </SSORedirectProvider>
                  </div>
                </div>
                <div className="row field1 common-input">
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="memberLastName">
                        <Text
                          field={accountDetailMyProfile?.fields?.lastName}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="memberLastName"
                        name="memberLastName"
                        value={profileList.LastName}
                      >
                        {profileList.LastName}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="memberFirstName">
                        <Text
                          field={accountDetailMyProfile?.fields?.firstName}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="memberFirstName"
                        name="memberFirstName"
                        value={profileList.FirstName}
                      >
                        {profileList.FirstName}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="dateOfBirth">
                        <Text
                          field={accountDetailMyProfile?.fields?.dateOfBirth}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={profileList.DateOfBirth}
                      >
                        {profileList.DateOfBirth}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="gender">
                        <Text field={accountDetailMyProfile?.fields?.gender} />
                      </label>
                    </div>
                    <div className="row-details">
                      <div id="gender" name="gender" value={profileList.Gender}>
                        {profileList.Gender}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="memberId">
                        <Text
                          field={accountDetailMyProfile?.fields?.memberIDNumber}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="memberId"
                        name="memberId"
                        value={profileList.MemberId}
                      >
                        {profileList.MemberId}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-none d-sm-none d-xl-none d-lg-block">
                    <div className="row-title">
                      <label htmlFor="mailingAddress1">
                        <Text
                          field={accountDetailMyProfile?.fields?.mailingAddress}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="mailingAddress1"
                        name="mailingAddress"
                        value={profileList.MailingAddress}
                      >
                        {profileList.MailingAddress}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row field2 common-input">
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12 d-lg-none d-xl-block">
                    <div className="row-title">
                      <label htmlFor="mailingAddress">
                        <Text
                          field={accountDetailMyProfile?.fields?.mailingAddress}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="mailingAddress"
                        name="mailingAddress"
                        value={profileList.MailingAddress}
                      >
                        {profileList.MailingAddress}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="mailingAddress2">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.mailingAddress2
                          }
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="mailingAddress2"
                        name="mailingAddress2"
                        value={profileList.ApptSuiteOther}
                      >
                        {profileList.ApptSuiteOther}
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="city">
                        <Text field={accountDetailMyProfile?.fields?.city} />
                      </label>
                    </div>
                    <div className="row-details">
                      <div id="city" name="city" value={profileList.City}>
                        {profileList.City}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="state">
                        <Text field={accountDetailMyProfile?.fields?.state} />
                      </label>
                    </div>
                    <div className="row-details">
                      <div id="state" name="state" value={profileList.State}>
                        {profileList.State}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12 button-space">
                    <div className="row-title">
                      <label htmlFor="zip">
                        <Text field={accountDetailMyProfile?.fields?.zip} />
                      </label>
                    </div>
                    <div className="row-details">
                      <div id="zip" name="zip" value={profileList.Zip}>
                        {profileList.Zip}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    {hiddenDisclaimer && (
                      <div className="btn-disclaimer-text">
                        {getFieldValue(
                          accountDetailMyProfile,
                          "btnDisclaimer",
                          false
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </li>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default PersonalInformation;
