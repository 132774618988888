import React, { useState, useCallback, useContext } from "react";
import { ErrorContext } from "../contexts/error.context";
import { SpinnerContext } from "../contexts/spinner.context";
import * as commonAPI from "../services/Common";

const useSSORedirect = () => {
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [html, setHtml] = useState("");

  const redirectToSSO = useCallback(
    ({ e, url, data = {} }) => {
      if (e) e.preventDefault();
      setSpinner(true);
      commonAPI
        .SSORedirect(url, {
          ...data,
        })
        .then((response) => {
          setHtml(response?.ResultData?.Html);
        })
        .catch((err) => {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    },
    [setError, setSpinner]
  );

  return {
    html,
    redirectToSSO,
    setHtml,
  };
};

export { useSSORedirect };
