import React, { useEffect, useState, useContext } from "react";
import * as MyCoveragesAPI from "../../../services/MyCoveragesAPI";
import { ICON_PATH } from "../../../define.constants";
import { ModalContext } from "../../../contexts/modal.context";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { SpinnerContext } from "../../../contexts/spinner.context";
import { Currency_Symbol } from "../../../define.constants.js";
import { ErrorContext } from "../../../contexts/error.context";
const qs = require("qs");

const ViewEstimate = ({ fields, request, results, code, description }) => {
  const { setError } = useContext(ErrorContext);
  const [estimate, setEstimate] = useState(null);
  const { openModal } = useContext(ModalContext);
  const { setSpinner } = useContext(SpinnerContext);
  const symbol = getFieldValue(fields, "lblSymbol");
  const backToResultsHandler = () => {
    openModal({
      content: (
        <SearchResults results={results} request={request} fields={fields} />
      ),
      version: 4,
    });
  };
  const backToSearch = (e) => {
    e.preventDefault();
    openModal({
      content: <SearchForm fields={fields} />,
      version: 2,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    setSpinner(true);
    let requestParam = {
      ProcedureCode: code,
      ProcedureDescription: "",
      ZipCode: request.ZipCode,
    };
    MyCoveragesAPI.ProcedureCostEstimate(
      requestParam,
      qs.stringify(requestParam, { addQueryPrefix: true })
    )
      .then((response) => {
        if (response && response.ResultData) {
          setEstimate(response?.ResultData?.ProcedureCostEstimateResponse);
        } else {
          setError({ err });
        }
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(function () {
        setSpinner(false);
      });
  }, [request, code, setSpinner]);
  return (
    <div className="mycoverage-modal">
      <div className="update-header">
        <div className="back-link" onClick={backToResultsHandler}>
          <img
            className="back-link-arrow"
            src={`${ICON_PATH}/arrow-left-24px.svg`}
            alt={getFieldValue(fields, "lblBack")}
          />
          <div className="back-link-title">
            <Text field={fields.lblBack} />
          </div>
        </div>
        <div>
          <div className="estimated-cost-title">
            <Text field={fields.lblEstimateCost} />
          </div>
          <div className="estimated-cost-subeading">{description}</div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="parent-zip">
                <div className="for-ZIP-text">
                  <Text field={fields.lblZip} />
                  <span className="estimated-cost-zipcode">
                    {request.ZipCode}
                  </span>
                </div>
                <div className="estimated-cost-notzipcode">
                  <Text field={fields.lblNotZipCode} />
                  <div>
                    <a
                      className="estimated-cost-zipcodeupdate state-color"
                      href="#"
                      onClick={(e) => backToSearch(e)}
                    >
                      <Text field={fields.lblUpdate} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 offset-1">
              <div className="estimated-cost-description">
                {estimate?.ProcedureDescription ?? description}
              </div>
            </div>
          </div>
          <div className="row">
            {[
              {
                label: fields.lblInNetwork,
                itemPosition: "left",
                min: estimate?.InNetWorkMin,
                max: estimate?.InNetWorkMax,
              },
              {
                label: fields.lblOutOfNetwork,
                itemPosition: "right",
                min: estimate?.OutNetWorkMin,
                max: estimate?.OutNetWorkMax,
              },
            ].map(({ label, itemPosition, min, max }, index) => (
              <div className="col-lg-6 col-md-12 col-sm-12" key={index}>
                <div className={`estimated-cost-card-${itemPosition}`}>
                  <span className="estimated-cost-backgroundimage-header">
                    <Text field={label} />
                  </span>
                  <br />
                  <span className="estimated-cost-backgroundimage-subheading">
                    {Currency_Symbol}
                    {min ?? "0.00"} - {Currency_Symbol}
                    {max ?? "0.00"}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="estimated-cost-disclaimer">
                <Text field={fields.lblEstDeductible} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-2 print-parent">
              <button
                className="btn btn-primary ripple-surface"
                onClick={handlePrint}
              >
                <Text field={fields.lblPrint} />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-2 print-parent">
              <div className="estimated-cost-start-over">
                <a
                  href="#"
                  className="card-link state-color-txt"
                  alt={getFieldValue(fields, "lblStartOver")}
                  onClick={(e) => backToSearch(e)}
                >
                  <Text field={fields.lblStartOver} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEstimate;
