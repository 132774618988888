import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "./../../contexts/app.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { ErrorContext } from "../../contexts/error.context";
import { MyResourcesContext } from "../../contexts/myResources.context";
import {
  Text,
  Link,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import {
  ICON_PATH,
  SAPPHIRE_SSO,
  CAREMARK_SSO,
  SUPPORT_USER,
  TERMED_MEMBER,
  SWITCHED_TO_DEPENDENT,
  HELP_FULLINK_ID,
  MARKETPLACE,
  IL,
  CA,
  MEDICAID,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import {
  getStateConfiguration,
  getHelpfulLink,
  isAccessibleToSupportUser,
  isAccessibleToTermedMember,
  isAccessibleToSwitchedDependent,
} from "./../../utils/helpers";
const helpfulLinkIds = HELP_FULLINK_ID;
const displayAllCards = (classname, item, index, isTermed, redirectToSSO) => {
  const getApigeeSSOurl = (SSOurl) => {
    switch (SSOurl) {
      case SAPPHIRE_SSO:
        return "SapphireChangePCPSSO";
      case CAREMARK_SSO:
        return "GetCVSCaremarkSSORedirect";
      default:
        return null;
    }
  };

  const getSSOLink = (e, item) => {
    redirectToSSO({
      e,
      url: getApigeeSSOurl(item.SSOurl?.value),
    });
  };
  console.log("SSOurl", item.fields.SSOurl?.value);

  return (
    <div key={index}>
      <div className={`accordion-item ${classname}`}>
        {item.fields.hyperlinkSelected.value === true ? (
          <>
            {item.fields.IsSSoEndPoint?.value === true ? (
              <div className="row">
                <div className="col-xxl-3 col-md-6 list-title card-titles pt-2 pb-2">
                  <Text field={item.fields.accordionHeading} />
                </div>
                <div className="col-xxl-9 col-md-11 list-desc2">
                  <div className="card-desc content-md-tab2">
                    <RichText field={item.fields.accordionDescription} />
                  </div>
                </div>
                {item.fields.SSOurl?.value ? (
                  <WhoIsAuthorized
                    type="link"
                    href="#"
                    authorize={{
                      [SUPPORT_USER]: isAccessibleToSupportUser(
                        getApigeeSSOurl(item.fields.SSOurl?.value)
                      ),
                      [TERMED_MEMBER]: isAccessibleToTermedMember(
                        getApigeeSSOurl(item.fields.SSOurl?.value)
                      ),
                      [SWITCHED_TO_DEPENDENT]: isAccessibleToSwitchedDependent(
                        getApigeeSSOurl(item.fields.SSOurl?.value)
                      ),
                    }}
                    handleClick={(e, item) =>
                      redirectToSSO({
                        e,
                        url: constants.apigeeURLs[
                          getApigeeSSOurl(item.SSOurl?.value)
                        ],
                      })
                    }
                  >
                    {({ handleClick, disabledClass, href }) => {
                      return (
                        <div
                          className={`list-multi-link-drp ${disabledClass}`.trim()}
                        >
                          <a
                            className="list-icon-newtab"
                            onClick={(e) => handleClick(e, item.fields)}
                          >
                            <img
                              src={`${ICON_PATH}/open_in_new-24px.svg`}
                              className="redirect-icon"
                              alt={getFieldValue(
                                item.fields,
                                "accordionHeading"
                              )}
                              id={index}
                            />
                          </a>
                        </div>
                      );
                    }}
                  </WhoIsAuthorized>
                ) : null}
              </div>
            ) : (
              <div className="row">
                <div className="col-xxl-11 col-md-11 accordion-link">
                  <Text field={item.fields.accordionHeading} />
                </div>
                <Link
                  field={item.fields.faqHyperlink}
                  className="list-icon-newtab redirect-icon-tab3"
                  target="_blank"
                >
                  <img
                    className="state-color"
                    src={`${ICON_PATH}/open_in_new-24px.svg`}
                    alt={getFieldValue(item.fields, "accordionHeading")}
                  />
                </Link>
              </div>
            )}
          </>
        ) : (
          <div>
            <div className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target={"#collapse" + index}
                aria-expanded="false"
                aria-controls={"collapse" + index}
              >
                <Text field={item.fields.accordionHeading} />
              </button>
            </div>
            <div
              id={"collapse" + index}
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#faqBlock"
            >
              {item.fields.accordionDescription != null ? (
                <div className="accordion-body">
                  <div
                    className={`accordion-body-desc ${
                      isTermed ? "Termeddisabled a" : ""
                    }`.trim()}
                  >
                    <RichText field={item.fields.accordionDescription} />
                  </div>
                  <AccordionContentList
                    contentListItems={item.fields.contentList}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MyResourcesFaqs = ({ fields }) => {
  let { memberLOB, MemberStateName, isTermedMember, programId } =
    useContext(AppContext);
  const [recordList, setRecordList] = useState(null);
  const { redirectToSSO } = useContext(SSORedirectContext);
  const { setError } = useContext(ErrorContext);
  const { distItem, listRecords } = useContext(MyResourcesContext);
  useEffect(() => {
    setRecordList(listRecords);
  }, [listRecords]);

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  const isTermedNonMP =
    isTermedMember && memberLOB.toLowerCase() != MARKETPLACE ? true : false;
  const list = getStateConfiguration(fields, MemberStateName, memberLOB);

  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID?.toLowerCase();
  const isProgIDRestrictedFAQ =
    isMedicaidMember &&
    (MemberStateName?.toUpperCase() === CA ||
      MemberStateName?.toUpperCase() === IL);

  return (
    <div className="row no-tab-title my-resources-page faqs-md">
      <div className="col-md-12">
        <div className="accordion" id="faqBlock">
          {Array.isArray(list) && list.length && (
            <>
              {list?.map((item, index) => {
                let linkIds = [];
                helpfulLinkIds.forEach((link) => {
                  if (link.id === getFieldValue(item.fields, "id")) {
                    linkIds = link;
                  }
                });
                if (
                  linkIds &&
                  linkIds.programIds &&
                  linkIds.programIds.length > 0
                ) {
                  // CA & IL Medicaid
                  // Constant file mentioned FAQ Items with ID & ProgramIDs will be hidden
                  if (isProgIDRestrictedFAQ) {
                    if (linkIds.programIds.includes(programId)) {
                      return;
                    }
                  }
                }
                if (index === list.length - 1) {
                  return displayAllCards(
                    "last-card-tab3",
                    item,
                    index,
                    isTermedNonMP,
                    redirectToSSO
                  );
                } else {
                  return displayAllCards(
                    "cards-tab3",
                    item,
                    index,
                    isTermedNonMP,
                    redirectToSSO
                  );
                }
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const AccordionContentList = ({ contentListItems }) => {
  return (
    <div>
      {contentListItems?.map((content, index) => (
        <div key={index}>
          <div className="accordion-body-title">
            <Text field={content.fields.heading} />
          </div>
          <div className="accordion-body-desc">
            <Text field={content.fields.description} />
          </div>
        </div>
      ))}
    </div>
  );
};
export default MyResourcesFaqs;
