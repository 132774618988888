import React, { useContext, useState, useEffect } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AccountSettingsPlanConfirmPopup from "../AccountSettings-Plan-ConfirmPopup";
import { AppContext } from "../../contexts/app.context";
import { mobileAndTabletCheck } from "../../utils/helpers";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ACCOUNT_SETTINGS_MY_PROFILE } from "../../define.widgets";

function PlanInformation(props) {
  let { profileList, isDependentLoggedIn } = useContext(AppContext);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const accountStateMyProfilePlan = props?.fields?.MyProfileList[2] ?? [];
  const accountDetailMyProfile = props?.fields?.MyProfileList[0] ?? [];
  const validationMessage =
    accountDetailMyProfile?.fields?.validationMessage?.value;
  const initialValues = {};
  const onSubmit = (submitProps) => {
    submitProps.setSubmitting(false);
  };
  const validationSchema = Yup.object({
    planName: Yup.string().required({ validationMessage }),
    coverageEffDt: Yup.string().required({ validationMessage }),
  });
  const [mobileDisplayCheck, setMobileDisplayCheck] = useState(false);
  const [hiddenDisclaimer, setHiddenDisclaimer] = useState(false);

  useEffect(() => {
    setMobileDisplayCheck(mobileAndTabletCheck);
    if (isDependentLoggedIn === true) {
      setHiddenDisclaimer(true);
    }
  }, [mobileDisplayCheck, isDependentLoggedIn]);

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    if (mobileDisplayCheck === true) {
      return (
        <p className="plan-content">
          {isReadMore
            ? text?.slice(
                0,
                accountDetailMyProfile?.fields?.planViewDescLimitValue?.value
              )
            : text}
          <div
            onClick={() => {
              setIsReadMore(!isReadMore);
            }}
            className="read-or-hide"
          >
            {isReadMore ? (
              <Text field={accountDetailMyProfile?.fields?.planViewMore} />
            ) : (
              <Text field={accountDetailMyProfile?.fields?.planShowLess} />
            )}
          </div>
        </p>
      );
    } else return <p className="plan-content">{children}</p>;
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <li className="list-group-item">
                {innerSpinnerHTML?.[ACCOUNT_SETTINGS_MY_PROFILE]}
                <div className="row">
                  <div className="col-xl-9 col-md-8">
                    <h5 className="tab-sub-title">
                      <Text field={accountDetailMyProfile?.fields?.planTitle} />
                    </h5>
                  </div>
                  <div className="col-xl-3 col-md-4">
                    <SSORedirectProvider>
                      <AccountSettingsPlanConfirmPopup
                        {...accountStateMyProfilePlan}
                      />
                    </SSORedirectProvider>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <ReadMore>
                      {getFieldValue(
                        accountDetailMyProfile,
                        "planDescription",
                        false
                      )}
                    </ReadMore>
                  </div>
                </div>
                <div className="row common-input">
                  <div className="col-xxl-3 col-xl-4 col-md-6 col-sm-12">
                    <div className="row-title">
                      <Text
                        field={
                          accountDetailMyProfile?.fields?.coverageEffectiveDate
                        }
                      />
                    </div>
                    <div className="row-details">
                      <div
                        id="coverageEffDt"
                        name="coverageEffDt"
                        value={profileList.EnrollmentEffDate}
                      >
                        {profileList.EnrollmentEffDate}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-lg-3 col-md-6 col-sm-12 button-space">
                    <div className="row-title">
                      <Text field={accountDetailMyProfile?.fields?.planName} />
                    </div>
                    <div className="row-details">
                      <div
                        id="planName"
                        name="planName"
                        value={profileList.EnrollmentPlan}
                      >
                        {profileList.EnrollmentPlan}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    {hiddenDisclaimer && (
                      <div className="btn-disclaimer-text">
                        {getFieldValue(
                          accountDetailMyProfile,
                          "btnDisclaimer",
                          false
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </li>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default PlanInformation;
