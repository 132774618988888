import React, { useContext, useEffect, useState, useCallback } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import {
  SUBSCRIBER,
  SELF,
  DEPENDANT_ACCESS_GRANTED_ROLEID,
  DEPENDANT_NO_ACCESS_ROLEID,
  PRIMARY_SUBSCRIBER_ROLEID,
} from "../define.constants";
import * as MemberInfoAllApi from "../services/MemberAPI";
import { onSecuredPage, getUserType } from "../utils/helpers";
import * as MemberAPI from "../services/MemberAPI";
import { HEADER_USER_SWITCH } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";
const qs = require("qs");

const useGetProfilesAPI = ({
  refresh,
  refreshMemberInfoAndReload,
  memberRKSID,
  memberName,
  _memberNumber,
  loggedInStateCode,
}) => {
  const { setSpinner, setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [refreshSession, setRefreshSession] = useState(false);
  const [isDependentLoggedIn, setIsDependentLoggedIn] = useState(false);
  const [Dependents, setDependents] = useState([]);
  const [isSwitchedToDependent, setIsSwitchedToDependent] = useState(false);
  const [isSwitchedView, setIsSwitchedView] = useState(false);
  const [switchedUserType, setSwitchedUserType] = useState(SELF);
  const [selectedMemberName, setSelectedMemberName] = useState("");
  const [selectedMemberNumber, setSelectedMemberNumber] = useState(0);
  const [selfMemberId, setSelfMemberId] = useState(memberRKSID);
  const [selfStateCode, setSelfStateCode] = useState(loggedInStateCode);
  const [showUserSwitch, setShowUserSwitch] = useState(false);
  const [switchedUserDependentCat, setSwitchedUserDependentCat] = useState("");
  const [loggedInUserDependentCat, setLoggedInUserDependentCat] = useState("");
  const [selfRoleId, setSelfRoleId] = useState("");

  useEffect(() => {
    if (Dependents?.length) {
      const [selfUser] = Dependents?.filter(
        (user) => user.RelationshipToLoggedInUser.toLowerCase() === SELF
      );
      setSelfMemberId(selfUser?.RksMemberId);
      setSelfStateCode(selfUser?.StateCode);
      setLoggedInUserDependentCat(selfUser?.DependentCategory);
      setSelfRoleId(selfUser?.RoleId);
      setIsDependentLoggedIn(
        selfUser?.RoleId === DEPENDANT_ACCESS_GRANTED_ROLEID ||
          selfUser?.RoleId === DEPENDANT_NO_ACCESS_ROLEID
          ? true
          : false
      );
      setShowUserSwitch(
        selfUser?.RoleId === PRIMARY_SUBSCRIBER_ROLEID ? true : false
      );
      if (memberRKSID && memberRKSID !== selfUser?.RksMemberId) {
        const [switchedUser] = Dependents?.filter(
          (user) => user.RksMemberId === memberRKSID
        );
        const [bool, type] = getSwitchedUser({
          relationshipToLoggedInUser: switchedUser?.RelationshipToLoggedInUser,
          roleId: switchedUser?.RoleId,
        });
        setSwitchedUserDependentCat(switchedUser?.DependentCategory);
        setIsSwitchedView(bool);
        setSwitchedUserType(type);
        setIsSwitchedToDependent(bool && type !== SUBSCRIBER ? true : false);
      }
    }
  }, [Dependents, memberRKSID]);

  const changeDependentView = ({
    memberId,
    memberNumber,
    index,
    isCurrentLogin,
    relationshipToLoggedInUser,
    stateCode,
    roleId,
    isSupportUser,
  }) => {
    setSelectedMemberNumber(memberNumber);
    setSelectedMemberName(getSelectedMemberName(index));
    generateNewToken({
      DesiredProfileMemId: memberId,
      DesiredProfileStateCode: stateCode,
      LoggedInMemId: selfMemberId,
      LoggedInMemStateCode: selfStateCode,
      IsSupportUser: isSupportUser,
      MemberNumber: null,
    });
  };

  const changeDSNPView = ({
    memberId,
    memberNumber,
    stateCode,
    isSupportUser,
  }) => {
    setSelectedMemberNumber(memberNumber);
    generateNewToken({
      DesiredProfileMemId: memberId,
      DesiredProfileStateCode: stateCode,
      LoggedInMemId: selfMemberId,
      LoggedInMemStateCode: selfStateCode,
      IsSupportUser: isSupportUser,
      MemberNumber: memberNumber,
    });
  };

  const getSwitchedUser = ({ relationshipToLoggedInUser, roleId }) => {
    const relationship = relationshipToLoggedInUser?.toLowerCase();
    switch (relationship) {
      case SELF:
        return [false, SELF];
      default:
        return [true, getUserType(roleId)];
    }
  };

  const generateNewToken = ({
    DesiredProfileMemId,
    DesiredProfileStateCode,
    LoggedInMemId,
    LoggedInMemStateCode,
    IsSupportUser,
    MemberNumber,
  }) => {
    setSpinner(true);
    MemberAPI.getuserLogin(
      {
        DesiredProfileMemId,
        DesiredProfileStateCode,
        LoggedInMemId,
        LoggedInMemStateCode,
        IsSupportUser,
        MemberNumber,
      },
      qs.stringify({ isUserSwitched: 1 }, { addQueryPrefix: true })
    )
      .then((data) => {
        if (data) {
          refreshMemberInfoAndReload();
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const getSelectedMemberName = (index) => {
    return `${Dependents[index].FirstName} ${Dependents[index].LastName}`;
  };

  useEffect(() => {
    setSelectedMemberNumber(_memberNumber);
    setSelectedMemberName(memberName);
  }, [_memberNumber, memberName]);

  useEffect(() => {
    if (onSecuredPage()) {
      setInnerSpinner(true, HEADER_USER_SWITCH);
      MemberInfoAllApi.getProfiles()
        .then((response) => {
          setRefreshSession(false);
          setDependents(response?.ResultData.Profiles);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setRefreshSession(false);
          setInnerSpinner(false, HEADER_USER_SWITCH);
        });
    }
  }, [refreshSession]);

  const refreshProfiles = () => {
    setRefreshSession(true);
  };

  return {
    isSwitchedView,
    switchedUserType,
    isDependentLoggedIn,
    Dependents,
    changeDependentView,
    changeDSNPView,
    isSwitchedToDependent,
    selectedMemberName,
    selectedMemberNumber,
    showUserSwitch,
    switchedUserDependentCat,
    loggedInUserDependentCat,
    selfRoleId,
    refreshProfiles,
  };
};

export { useGetProfilesAPI };
