import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as AssessmentsAPI from "../services/AssessmentsAPI";
import { AppContext } from "../contexts/app.context";
import { MY_WELLNESS_GET_ASSESSMENTS } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";

const useGetAssessmentAndValidationListAPI = () => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const { MemberStateName, memberLOB } = useContext(AppContext);
  const [assessments, setAssessments] = useState([]);
  const [isApiResponded, setIsApiResponded] = useState(false);
  const setData = (response) => {
    setAssessments(response);
  };
  useEffect(() => {
    setInnerSpinner(true, MY_WELLNESS_GET_ASSESSMENTS);
    AssessmentsAPI.getAssessmentAndValidationList({
      IncludeAllStatus: "true",
      SecMemId: "",
      SecRoleId: "",
      DropdownSelectedMemId: "",
    })
      .then((response) => {
        setIsApiResponded(true);
        setData(response?.ResultData?.AssessmentViewList);
      })
      .catch(function (err) {
        setIsApiResponded(true);
        setError({ err });
      })
      .finally(() => {
        setInnerSpinner(false, MY_WELLNESS_GET_ASSESSMENTS);
      });
    //}
    //}, [setSpinner, memberRKSID, MemberStateName]);
  }, []);

  return {
    assessments,
    isApiResponded,
  };
};

export { useGetAssessmentAndValidationListAPI };
