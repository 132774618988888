import React from "react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import { ICON_PATH } from "../../define.constants";

const MedicationsRecords = (props) => {
  const { fields, data, NavigateToMedicHistory } = props;

  return (
    <ul className="list-group no-list-title" id="recordsToPrint">
      {data?.map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="row label-list">
            <DisplayItem
              nameField={fields.lblDateFilled1}
              outClass={"col-xl-3 col-md-6 details d-xl-block d-none"}
              titleClass={"list-desc-title"}
            />
            <DisplayItem
              nameField={fields.lblMedicationName1}
              outClass={"col-xl-5 col-md-6 details d-xl-block d-none"}
              titleClass={"list-desc-title"}
            />

            <DisplayItem
              nameField={fields.lblRefillsLeft1}
              outClass={"col-xl-2 col-md-6 details d-xl-block d-none"}
              titleClass={"list-desc-title"}
            />
            <div className="col-xl-2 col-md-12 view-details medication-view-details d-xl-block d-none">
              <a
                href="#"
                onClick={(e) =>
                  NavigateToMedicHistory(
                    e,
                    item.MedicationName,
                    item.PrescribingDoctor,
                    item.PrescriberNPI,
                    item.DatePrecriptionFilled,
                    item.DatePrecriptionWritten,
                    item.Dose,
                    item.DaysSupply,
                    item.RefillsAuthorized,
                    item.NumberofRefills,
                    item.PharmacyNPI
                  )
                }
              >
                {fields.lblViewDetails.value}
                <img
                  src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                  alt={fields.lblViewDetails.value}
                />
              </a>
            </div>
          </div>
          <div className="row">
            <DisplayItem
              nameField={fields.lblDateFilled1}
              valueField={item.DatePrecriptionFilled}
              outClass={"col-xl-3 col-md-12 details d-md-flex d-xl-block"}
              titleClass={"list-desc-title d-lg-block d-xl-none"}
              detailsClass={"list-desc-details"}
            />
            <DisplayItem
              nameField={fields.lblMedicationName1}
              valueField={item.MedicationName}
              outClass={"col-xl-5 col-md-12 details d-md-flex d-xl-block"}
              titleClass={"list-desc-title d-lg-block d-xl-none"}
              detailsClass={"list-desc-details "}
            />

            <DisplayItem
              nameField={fields.lblRefillsLeft1}
              valueField={item.NumberofRefills}
              outClass={"col-xl-2 col-md-12 details d-md-flex d-xl-block"}
              titleClass={"list-desc-title d-lg-block d-xl-none"}
              detailsClass={"list-desc-details "}
            />

            <div className="col-xl-2 col-md-12 view-details medication-view-details d-lg-block d-xl-none">
              <a
                href="#"
                onClick={(e) =>
                  NavigateToMedicHistory(
                    e,
                    item.MedicationName,
                    item.PrescribingDoctor,
                    item.PrescriberNPI,
                    item.DatePrecriptionFilled,
                    item.DatePrecriptionWritten,
                    item.Dose,
                    item.DaysSupply,
                    item.RefillsAuthorized,
                    item.NumberofRefills,
                    item.PharmacyNPI
                  )
                }
              >
                {fields.lblViewDetails.value}
                <img
                  src={`${ICON_PATH}/icon-navigation-chevron_left_24px.svg`}
                  alt={fields.lblViewDetails.value}
                />
              </a>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default MedicationsRecords;
