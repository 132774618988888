/* eslint-disable react/display-name */
import React from "react";
import Slider from "react-slick";

function Arrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={style} onClick={onClick} />;
}

export const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  rows: 1,
  nextArrow: <Arrow />,
  prevArrow: <Arrow />,
  adaptiveHeight: true,
  appendDots: (dots) => <ul>{dots}</ul>,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
        slidesPerRow: 1,
        dots: true,
        infinite: true,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
      },
    },
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
        slidesPerRow: 1,
        dots: true,
        infinite: true,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
      },
    },
    {
      breakpoint: 1919,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
        slidesPerRow: 1,
        dots: true,
        infinite: true,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
      },
    },
  ],
};
