import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIDCardKYMedicaid = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/KY/Medicaid/Molina_KY_Passport_Medicaid_Member_ID_card_Front.png)`,
          }}
          className="background-idcard frontviewimageKY"
        >
          <div className="kyaddressblock">
            {/* <table id="KYAddressTable" className="w-100">
              <tr>
                <td className="text-right kycolumnone">
                  <b>
                    <Text field={fields.lblIDBackDental} />
                  </b>
                </td>
                <td>
                  <div>
                    <Text field={fields.lblIDBackDentalData} />
                  </div>
                  <div>
                    <Text field={fields.lblIDBackComplaintContact} />
                  </div>
                  <div>
                    <Text field={fields.lblIDBackComplaintContactData} />
                  </div>
                </td>
              </tr>
            </table> */}
            {/* <div className="col-md-5 col-sm-7 offset-sm-5 offset-md-7">
              <b>
                <Text field={fields.lblIDBackDental} />
              </b>{" "}
              &nbsp;&nbsp;
              <Text field={fields.lblIDBackDentalData} />
            </div>
            <div className="col-md-5 col-sm-7 offset-sm-5 offset-md-7">
              <div className="KYaddress">
                <Text field={fields.lblIDBackComplaintContact} />
              </div>
              <div className="KYaddress">
                <Text field={fields.lblIDBackComplaintContactData} />
              </div>
            </div>
            <div className="clearfix pb-2"></div> */}
          </div>

          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="KYtable"
          >
            <tbody>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-md-7">
                      <b>
                        <Text field={fields.lblMemberMedicareAZ} />
                      </b>
                      &nbsp; {idCard?.Member_Name}
                    </div>
                    <div className="col-md-7">
                      <b>
                        <Text field={fields?.lblDOB} />
                      </b>
                      &nbsp; {result?.MemberIdCard?.Member_DOB}
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-md-7">
                      <b>
                        {" "}
                        <Text field={fields.lblMemberIDMedicareAZ} />
                      </b>
                      &nbsp; {idCard?.OriginalMemberProfile?.Number}
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="kyrxrow">
                    <div className="kyrxcolumn">
                      <div className="pb-1">
                        <b>
                          <Text field={fields.lblBin} />
                        </b>
                        &nbsp; {result?.IdCardValues?.RxBIN}
                      </div>
                      <div className="pb-1">
                        <b>
                          <Text field={fields.lblPCN} />
                        </b>
                        &nbsp; {result?.IdCardValues?.RxPCN}
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="float-left kyrxcolleft">
                      <b>
                        <Text field={fields.lblIssueDateAZ} />
                      </b>
                    </div>
                    <div className="float-left kyrxcolright">
                      <b>
                        <Text field={fields.lblGroup} />
                      </b>
                      &nbsp; {result?.IdCardValues?.RxGRP}
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="col-md-7 col-sm-7">
                    <b>
                      <Text field={fields.lblPrimaryCare} />
                    </b>
                    &nbsp; {idCard?.PCPName_Current}
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-md-7">
                    <b>
                      <Text field={fields.lblMedicalHeading} />
                    </b>
                    &nbsp; {idCard?.PCPPhone_Current}
                  </div>
                  <div className="clearfix pb-4"></div>
                </td>
              </tr>
              <tr>
                <td className="table-costsummary">
                  <RichText field={fields.lblCostShares} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/KY/Medicaid/Molina_KY_Passport_Medicaid_Member_ID_card_Back.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <div>
              <b>
                <Text field={fields?.lblMemberServicesKY} />{" "}
              </b>
              &nbsp; <Text field={fields?.lblIDBackMiembro} />
            </div>
            <br />
            <div>
              <Text field={fields?.lblIDBackMiembroData} />
            </div>
            <br />
            <div>
              <b>
                {} <Text field={fields?.lbl24hoursNurseAdviceKY} />
              </b>
              &nbsp; <Text field={fields?.lblProviderKY} />
            </div>
            <br />
            <div>
              <b>
                {} <Text field={fields?.lbl24hoursNurseAdviceSpanishKY} />
              </b>
              &nbsp; <Text field={fields?.lblProviderValueKY} />
            </div>
            <br />
            <div>
              <b>
                {} <Text field={fields?.lblPharmacyKY} />
              </b>
              <br />
              <Text field={fields?.lblPharmacyKYValue} />
              <br /> <Text field={fields?.lblSubmitClaimKY} />
            </div>
            <br />

            <div>
              <b>
                {} <Text field={fields?.lblIDBackProvider} />
              </b>
            </div>

            <div>
              {" "}
              <Text field={fields?.lblIDBackProviderData} />
              <br />
              <Text field={fields?.lblMedicalClaimsData} />
            </div>

            <br />
            <div>
              <Text field={fields?.lblNotice} />
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardKYMedicaid;
