import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardMedicaidVA = ({
  MemberStateName,
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardVA"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/VA/VA_CardinalCare_Rebranded_Front.jpg)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap" className="plan-care-name">
                  <b>
                    <Text field={fields.lblMemberName} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
                <td nowrap="nowrap" className="plan-care-name">
                  <b>
                    <Text field={fields.lblPharmacy} />
                  </b>
                </td>
              </tr>

              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPreferredLanguage} />
                  </b>
                  &nbsp; <Text field={fields.lblEnglish} />
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblBin} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxBIN}
                  {/* &nbsp; {idCard?.lblRxBinValue} */}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMedicaidID} />
                  </b>
                  &nbsp; {idCard?.MedicaidId}
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblPCN} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxPCN}
                  {/* &nbsp; {idCard?.MemberEffectiveDate} */}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblSubscriberIdNum} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblGroup} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxGRP}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblEffectiveDate} />
                  </b>
                  &nbsp; {idCard?.MemberEffectiveDate}
                </td>

                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/VA/VA_FAMISCardinalCare_Rebranded_Back.jpg)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text"></div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicaidVA;
