import React, { useContext } from "react";
import {
  IMAGE_PATH,
  GLOBAL_VARIABLE,
  MI_MEDICAID_PLAN_HMP,
  ICON_PATH,
} from "../../../../../define.constants";
import { AppContext } from "../../../../../contexts/app.context";
import {
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardMedicaidMI = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");
  const { planId } = useContext(AppContext);
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/MI/Medicaid/MI-Medicaid-front.png)`,
          }}
          className="background-idcard background-id-row background-id-mi p-3"
        >
          <div className="row margn-top-100">
            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 white-bg">
              <div className="p-2">
                <ul className="list-unstyled">
                  <li>
                    <span className="fw-bold">
                      <Text field={fields.lblMember} />
                    </span>
                    &nbsp;{idCard?.Member_Name}
                  </li>
                  <li>
                    <span className="fw-bold">
                      <Text field={fields.lblID} />
                    </span>
                    &nbsp;{idCard?.Member_Number}
                  </li>
                  <li>
                    <span className="fw-bold">
                      <Text field={fields.lblProgram} />
                    </span>
                    &nbsp;{idCard?.PlanName}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 white-bg">
              <div className="p-2">
                <ul className="list-unstyled mrgn-top margin-mi-bottom">
                  <li>
                    <span className="fw-bold">
                      <Text field={fields.lblBin} />
                    </span>
                    &nbsp; {result?.IdCardValues?.RxBIN}
                  </li>
                  <li>
                    <span className="fw-bold">
                      <Text field={fields.lblPCN} />
                    </span>
                    &nbsp; {result?.IdCardValues?.RxPCN}
                  </li>
                  <li>
                    <span className="fw-bold">
                      <Text field={fields.lblGroup} />
                    </span>
                    &nbsp; {result?.IdCardValues?.RxGRP}
                  </li>
                </ul>
              </div>
            </div>
            <div className="text-center" style={{ padding: "50px" }}>
              <Text field={fields.lblNoteforHealthcare} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/MI/Medicaid/MI-Medicaid-back.png)`,
          }}
          className="member-id-card-back background-id-mi"
        >
          <div className="member-id-card-back-text backcard backcardNV">
            <div className="backcardmember">
              <div className="b-2">
                <div className="text-center">
                  <h6>
                    Call <b>(888) 898-7969:</b>
                  </h6>
                </div>
              </div>
              <div className="b-2">
                <img
                  alt={fields.lblBenefits}
                  className="idcard-back-img"
                  src={`${ICON_PATH}/Benefits.svg`}
                />
                <b>
                  <Text field={fields.lblBenefits} />
                </b>
                &nbsp;
                <Text field={fields.lblBenefitsToLearnMore} />
              </div>
              {/* Existing logic */}
              {/* <div className="mb-2">
                <b>
                  {planId === MI_MEDICAID_PLAN_HMP ? (
                    <Text field={fields.lblDental} />
                  ) : (
                    <Text field={fields.lblVisionToLearnDetal} />
                  )}
                </b>
                &nbsp;
                <Text field={fields.lblDentalToLearnMore} />
              </div> */}
              {/* New Logic for RITM4517327 - Start */}
              <div className="mb-2">
                <img
                  alt={fields.lblTransportation}
                  className="idcard-back-img"
                  src={`${ICON_PATH}/Transportation.svg`}
                />
                <b>
                  <Text field={fields.lblTransportation} />
                </b>
                &nbsp;
                <Text field={fields.lblTransportationToLearnMore} />
              </div>
              <div className="mb-2">
                <img
                  alt={fields.lblDental}
                  className="idcard-back-img"
                  src={`${ICON_PATH}/Dental.svg`}
                />
                <b>
                  <Text field={fields.lblDental} />
                </b>
                &nbsp;
                {planId === MI_MEDICAID_PLAN_HMP ? (
                  <Text field={fields.lblDentalToLearnMore} />
                ) : (
                  <Text field={fields.lblVisionToLearnDetal} />
                )}
              </div>
              {/* New Logic for RITM4517327 - End*/}
              <div className="mb-2">
                <img
                  alt={fields.lblVision}
                  className="idcard-back-img-vision"
                  src={`${ICON_PATH}/Vision.svg`}
                />
                <b>
                  <Text field={fields.lblVision} />
                </b>
                &nbsp;
                <Text field={fields.lblVisionToLearMore} />
              </div>
              <hr
                style={{
                  width: "100%",
                  border: "0",
                  borderTop: "2px solid #000",
                  margin: "10px 0",
                }}
              />
              <div className="mb-2">
                <b>
                  <Text field={fields.lblMIBack24HrsNurseAdvice} />
                </b>
                &nbsp;
                <Text field={fields.lblMIBack24HrsNurseAdviceDetails} />
              </div>
              <div>
                <b>
                  <Text field={fields.lblPractitionersProviders} />
                </b>
              </div>
              <div className="mb-2">
                <Text field={fields.lblPractitionersProvidersDetails} />
              </div>
              <div className="mb-2">
                <b>
                  <Text field={fields.lblPharmacists} />
                </b>
                &nbsp;
                <Text field={fields.lblPharmacistsCallDetails} />
              </div>
              <div className="mb-2">
                <b>
                  <Text field={fields.lblClaimsSubmission} />
                </b>
                &nbsp;
                <Text field={fields.lblClaimsSubmissionToLearnMore} />
              </div>
              <div className="mb-2">
                <b>
                  <Text field={fields.lblEDI} />
                </b>
                &nbsp;
                <Text field={fields.lblEdiPayerId} />
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicaidMI;
