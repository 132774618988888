import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useContext } from "react";
import { AppContext } from "../../../../../contexts/app.context";

const ViewIdCardMedicareAZ = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  let { planName } = useContext(AppContext);
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/Medicare/Molina_IDCard_CompleteCare2023.png)`,
          }}
          className="background-idcard background-id-row"
        >
          <table
            className="medicare-table"
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td nowrap="nowrap">
                  <span style={{ color: "#00a4b3" }}>{planName}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblMemberMedicareAZ} />
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblMemberIDMedicareAZ} />
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td height="40"></td>
              </tr>
              <tr>
                <td>
                  <div className="medicareAZ">
                    <ul className="list-unstyled">
                      <li>
                        <Text field={fields.lblBin} />
                        &nbsp;{" "}
                        {result?.PortalDeductiblesMappingDetails[0]?.RxBIN}
                      </li>
                      <li>
                        <Text field={fields.lblPCN} />
                        &nbsp;{" "}
                        {result?.PortalDeductiblesMappingDetails[0]?.RxPCN}
                      </li>
                      <li>
                        <Text field={fields.lblGroup} />
                        &nbsp;{" "}
                        {result?.PortalDeductiblesMappingDetails[0]?.RxGroup}
                      </li>
                      <li>
                        <Text field={fields.lblRXIDAZ} />
                        &nbsp; {idCard?.OriginalMemberProfile?.Number}
                      </li>
                      <li className="azheight">&nbsp;</li>
                      <li className="azportal-title">
                        {
                          result?.PortalDeductiblesMappingDetails[0]
                            ?.ContractPlanId
                        }
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ height: "12px" }}></td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblIssueDateAZ} />
                  &nbsp;{formatToday()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicare/Medicare_ID_Card_Molina_BLANK_220914_r1_FNL2.png)`,
          }}
          className="member-id-card-back"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="member-id-card-back-az"
          >
            <tbody>
              <tr>
                <td style={{ height: "30px" }}></td>
              </tr>
              <tr>
                <td>
                  <Text field={fields?.lblMemberServicesKY} />
                  &nbsp;{" "}
                  <Text field={fields?.lblMemberServicesMedcareValueAZ} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <Text field={fields?.lbl24hoursNurseAdviceMedicareAZ} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <Text
                    field={fields?.lbl24hoursNurseAdviceSpanishMedicareAZ}
                  />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <Text field={fields?.lblProviderKY} />
                  &nbsp; <Text field={fields?.lblProviderValueKY} />
                </td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <Text field={fields?.lblProviderValueSuffixKY} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <Text field={fields?.lblSubmitClaimKY} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <Text field={fields?.lblMedicalKY} />
                  &nbsp;{" "}
                  {
                    result?.PortalDeductiblesMappingDetails[0]
                      ?.ClaimAddressLine1
                  }{" "}
                  {result?.PortalDeductiblesMappingDetails[0]?.ClaimAddressCity}{" "}
                  {
                    result?.PortalDeductiblesMappingDetails[0]
                      ?.ClaimAddressState
                  }{" "}
                  {result?.PortalDeductiblesMappingDetails[0]?.ClaimAddressZip}
                </td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "110px" }}>
                  <Text field={fields?.lblProviderValueSuffixKY} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <Text field={fields?.lblPharmacyKY} />
                  &nbsp;
                  <Text field={fields?.lblPharmacyKYValue} />
                </td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "69px" }}>
                  <Text field={fields?.lblProviderValueSuffixKY} />
                </td>
              </tr>
              <tr>
                <td style={{ height: "60px" }}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicareAZ;
