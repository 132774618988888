import React, { useContext } from "react";
import {
  Placeholder,
  getChildPlaceholder,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { ModalProvider } from "../../contexts/modal.context";
import { AppContext } from "./../../contexts/app.context";
import { getStateLobConfigurationItems } from "./../../utils/helpers";
import {
  GOT_QUESTIONS,
  SEND_A_MESSAGE,
  GLOBAL_VARIABLE,
} from "../../define.constants";
const ContactLayout = (props) => {
  let {
    memberLOB,
    MemberStateName,
    programId,
    homePhone,
    prefEmailId,
    cellPhone,
    prefPhone,
    profileList,
  } = useContext(AppContext);
  const renderingData = getChildPlaceholder(
    props.rendering,
    "jss-contact-layout"
  ).filter((component) => component.componentName === GOT_QUESTIONS);

  const approachList = getStateLobConfigurationItems(
    renderingData[0]?.fields,
    MemberStateName,
    memberLOB,
    programId
  );

  const sendAMsgRenderingData = getChildPlaceholder(
    props.rendering,
    "jss-contact-layout"
  ).filter((component) => component.componentName === SEND_A_MESSAGE);

  const sendAMsgList = getStateLobConfigurationItems(
    sendAMsgRenderingData[0]?.fields,
    MemberStateName,
    memberLOB,
    programId
  );

  return (
    <ModalProvider>
      <section className="contact-us-page">
        {!homePhone == "" && (
          <Placeholder
            name="jss-contact-layout"
            rendering={props.rendering}
            approachList={approachList}
            sendAMsgList={sendAMsgList}
            phone={homePhone}
            email={prefEmailId}
          />
        )}
        {homePhone == "" && (
          <Placeholder
            name="jss-contact-layout"
            rendering={props.rendering}
            approachList={approachList}
          />
        )}
      </section>
    </ModalProvider>
  );
};

export default ContactLayout;
