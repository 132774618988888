import Axios from "./axios/provider";
import { SpinnerContext } from "../contexts/spinner.context";

/**
 * Get Allergies List
 *
 * @param {object} data
 * @returns
 */
// This method is used to get the communication preferences
export const getAccountSettingsProfile = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.AccountSettingsProfile,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// export const UpdateCommunicationPreferences = async (data) => {
//   try {
//     const response = await Axios.post(
//       constants.apigeeURLs.UpdateCommunicationPreferences,
//       data
//     );
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };

export const getMemberCitiesForState = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetMemberCitiesForState,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAddress = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.UpdateAddress, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addressAutoComplete = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.AddressAutoComplete,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getZipCodeChange = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetZipCodeChange,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getZipCodeFromAutoSuggestionList = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetZipCodeFromAutoSuggestionList,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const saveNameChangeRequest = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.SaveNameChangeRequest,
      data
    );
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
};
