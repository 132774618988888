import { useEffect, useState, useContext } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import * as HealthRecordAPI from "../services/HeathRecordAPI";
import { ErrorContext } from "../contexts/error.context";
import { MY_HEALTH_RECORD_GET_SERVICE_PLAN } from "../define.widgets";
import { CarePlan_Name } from "../define.constants";

const useGetServicePlanAPI = () => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const {
    memberRKSID,
    memberLOB,
    StateCode,
    MemberNo,
    Language,
    isSupportUser,
    supportUserId,
    programId,
    userId,
  } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const [servicePlan, setservicePlan] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const setData = (response) => {
    setservicePlan(response.ResultData);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (memberRKSID != "") {
      setInnerSpinner(true, MY_HEALTH_RECORD_GET_SERVICE_PLAN);

      HealthRecordAPI.getServicePlan({
        // RKSMemberID: "MBR120617370554",
        // MemberLob: "Medicaid",
        // StateCode: "TX",
        // MemberNo: "506647033",
        // Language: "EN",
        // SupportUserId: "u",
        // UserId: "2",
        // ProgramId: "QMXHPQ0882",
        includeAllStatus: true,
      })
        .then((response) => {
          if (response != undefined && response != null) {
            setData(response);
          }
        })
        .catch(function (err) {
          console.log(err);
          setError({ err });
        })
        .finally(function () {
          //setInnerSpinner(false, MY_HEALTH_RECORD_GET_SERVICE_PLAN);
        });
    }
  }, [memberRKSID]);
  return { servicePlan, isLoaded };
};
export { useGetServicePlanAPI };
