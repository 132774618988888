import React from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import {
  Currency_Symbol,
  ID,
  TX,
  MEDICAID,
  TX_MD_PLUS_PROGRAMID,
} from "../../define.constants";

export const currencyValue = (value) => {
  return `${Currency_Symbol}` + `${value}`;
};

export const renderStatusCss = (status) => {
  if (status != null && status != undefined && status != "") {
    const statusString = status?.toLowerCase();
    switch (statusString) {
      case "pend":
        return "orange-bg";
      case "paid":
        return "green-bg";
      case "denied":
        return "red-bg";
      default:
        return "orange-bg";
    }
  }
};

export const renderStatus = (status, fields) => {
  if (status != null && status != undefined && status != "") {
    const statusString = status?.toLowerCase();
    switch (statusString) {
      case "pend":
        return getFieldValue(fields, "lblPend", false);
      case "paid":
        return getFieldValue(fields, "lblPaid", false);
      case "denied":
        return getFieldValue(fields, "lblDenied", false);
      default:
        return statusString;
    }
  }
};

const paginationLabel = (
  totalRecords,
  next,
  props,
  isMarketplaceMember,
  showEOBNotification,
  isMedicaidMember
) => {
  return (
    <>
      <div className="col-md-12 col-lg-3">
        {totalRecords < props.lblPostPerPage.value ? (
          <label>
            {totalRecords} <Text field={props.lblResults} />
          </label>
        ) : (
          <label>
            1-{next} <Text field={props.lblOf} /> {totalRecords}{" "}
            <Text field={props.lblResults} />
          </label>
        )}
      </div>
      {(isMarketplaceMember || showEOBNotification) && (
        <div className="col-md-12 col-lg-9 eobDisclaimer">
          <Text field={props.lblEOBDisclaimer} />
        </div>
      )}
    </>
  );
};

export const paginationPrint = (
  totalRecords,
  next,
  props,
  isMarketplaceMember,
  showEOBNotification,
  isMedicaidMember
) => {
  return (
    <div className="row pagination">
      {paginationLabel(
        totalRecords,
        next,
        props,
        isMarketplaceMember,
        showEOBNotification
      )}
    </div>
  );
};

export const getshowEOB = (MemberStateName) => {
  /* As of now MyMolina Redesign JULY/2023 
  setting it to false as per requirements.*/
  const showEOB = false;
  return showEOB;
};

export const showEOBClickInstruction = (memberState, memberLOB, programId) => {
  let showEOBnotification = false;
  const MEDICAID_LOB = MEDICAID?.toUpperCase();

  // Showing notification for TX MCAID STAR PLUS - TX Enhancement 2024
  switch (memberState) {
    case TX:
      switch (memberLOB) {
        case MEDICAID_LOB:
          switch (programId) {
            case TX_MD_PLUS_PROGRAMID:
              showEOBnotification = true;
              break;
          }
          break;
      }
      break;
  }

  return showEOBnotification;
};
