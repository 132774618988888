import React, { useContext } from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../../../contexts/app.context";
import { MS_MEDICAID_CHIP } from "../../../../../define.constants";

const ViewIdCardMedicaidMS = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const { programId } = useContext(AppContext);

  const backgroundImageUrl =
    programId === MS_MEDICAID_CHIP
      ? `${IMAGE_PATH}/id/NonMP/MS/Medicaid/ID_Card_MS_Medicaid_CHIP.png`
      : `${IMAGE_PATH}/id/NonMP/MS/Medicaid/ID_Card_MS_Medicaid.png`;

  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardMS"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="table-font-size"
          >
            <tbody>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td style={{ height: "6px" }}></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblmemberno} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
              </tr>
              <tr>
                <td style={{ height: "6px" }}></td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>
                    {" "}
                    <Text field={fields.lblPlan} />
                  </b>
                  &nbsp; {idCard?.PlanName}
                </td>
              </tr>

              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style={{ height: "10px" }}></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPCPHeading} />
                  </b>
                </td>
                <td>
                  <b>
                    &nbsp;
                    <b>
                      <Text field={fields.lblBin} />
                    </b>
                  </b>
                  &nbsp; {result?.IdCardValues?.RxBIN}
                </td>
              </tr>
              <tr>
                <td style={{ height: "6px" }}></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPcp} />
                  </b>
                  &nbsp; {idCard?.PCPName_Current}
                </td>
                <td>
                  <b>
                    &nbsp;
                    <b>
                      <Text field={fields.lblPCN} />
                    </b>
                  </b>
                  &nbsp; {result?.IdCardValues?.RxPCN}
                </td>
              </tr>
              <tr>
                <td style={{ height: "6px" }}></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPCPPhone} />
                  </b>
                  &nbsp; {idCard?.PCPPhone_Current}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              {programId === MS_MEDICAID_CHIP && (
                <tr>
                  <td colSpan="2" className="pb-1 pt-1">
                    <Text field={fields.lblEffectiveDateOfCoverage} />
                    &nbsp; {idCard?.MemberEffectiveDate}
                  </td>
                </tr>
              )}
              {programId === MS_MEDICAID_CHIP && (
                <tr>
                  <td colSpan="2" className="pb-1">
                    <Text field={fields.lblCopay} />
                    &nbsp; {result?.IdCardValues?.Copay}
                  </td>
                </tr>
              )}
              {programId === MS_MEDICAID_CHIP && (
                <tr>
                  <td colSpan="2">
                    <Text field={fields.lbloutOfPocketMaximum} />
                    &nbsp; {result?.IdCardValues?.OutOfPocketMax}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardBackMs"
      >
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicaid/WI_back_view.png)`,
          }}
          className="member-id-card-back table-font-size"
        >
          <div className="member-id-card-back-text backcard backcardNV">
            <div className="backcardmember">
              <div>
                <b>
                  <Text field={fields.lblIDBackMiembro} />{" "}
                </b>
                <Text field={fields.lblIDBackMiembroData} />
              </div>
              <br />
              <div>
                <b>
                  <Text field={fields.lblIDBackRemitRural} />{" "}
                </b>
              </div>
              <div>
                <b>
                  <Text field={fields.lblIDBackServices} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackServicesData} />
              </div>
              <div>
                <b>
                  <Text field={fields.lblIDBackPharmacyServices} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackPharmacyServicesData} />
              </div>
              <div>
                <b>
                  <Text field={fields.lblIDBackNAL} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackNALData} />
              </div>
              <div>
                <b>
                  <Text field={fields.lblIDBackEnfermeras} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackEnfermerasData} />
              </div>

              <div>
                <b>
                  {" "}
                  <Text field={fields.lblIDBackCVS} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackCVSData} />
              </div>
              <div>
                <b>
                  <Text field={fields.lblIDBackComplaintContact} />
                </b>
                &nbsp;
                <Text field={fields.lblIDBackComplaintContactData} />
              </div>
            </div>
            <br />
            <div>
              <b>
                <Text field={fields.lblIDBackProvider} />{" "}
              </b>
            </div>
            <br />
            <div>
              <Text field={fields.lblMedicalClaims} />
              &nbsp;
              <b>
                <Text field={fields.lblMedicalClaimsData} />
              </b>
            </div>
            <br />
            <div>
              <Text field={fields.lblIDBackProviderData} />
            </div>
            <div>
              <Text field={fields.lblIDBackPharmacyServices} />
              &nbsp;
              <Text field={fields.lblIDBackPharmacyServicesData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicaidMS;
