import React, { useState, useEffect } from "react";
import {
  getFieldValue,
  Text,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import {
  SPANISH_LANG_PATH,
  ICON_PATH,
  ENGLISH_LANG_PATH,
  LoginPageUrl,
  REGISTARTION_URL,
} from "../../define.constants";
import { useLocation } from "react-router-dom";

const CommonRegisterHeader = (props) => {
  const location = useLocation();
  let locationEngLang = `${ENGLISH_LANG_PATH}${REGISTARTION_URL}`;
  let locationSpanishLang = `${SPANISH_LANG_PATH}${REGISTARTION_URL}`;

  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-light molina-header1">
      <div className="container-fluid">
        <a
          className="navbar-brand molina-loginheader-logo navbar-center"
          href={LoginPageUrl}
        >
          <img
            src={`${ICON_PATH}/Molina_Healthcare_Logo_320.svg`}
            alt={props.fields.MyMolina?.value}
          />
          {/* <span>
            <Text field={props.fields.MyMolina} />
          </span> */}
        </a>

        {/* <!-- Collapsible content --> */}
        <ul className="navbar-nav login-navbar-right navbar-right hidden-md">
          <li className="nav-item dropdown molina-header-lbl3 d-md-block">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbardrop"
              data-mdb-toggle="dropdown"
            >
              <img
                className="header-icon"
                src={`${ICON_PATH}/language-selection.svg`}
                alt={props.fields.EngLanguageAbbreviation?.value}
              />
              <small className="font-weight-bold text-dark selected-lang">
                {location.pathname.includes(locationSpanishLang) ? (
                  <Text field={props.fields.SpanishLanguageAbbreviation} />
                ) : (
                  <Text field={props.fields.EngLanguageAbbreviation} />
                )}
              </small>
            </a>

            <div className="dropdown-menu dropdown-menu-right dropdown-default">
              <a
                className={`dropdown-item ${
                  location.pathname.includes(locationSpanishLang)
                    ? ""
                    : "active"
                }`}
                href={locationEngLang}
              >
                <img
                  className="header-dropdown-icon"
                  src={`${ICON_PATH}/tick-teal.svg`}
                  alt={props.fields.LanguageOne?.value}
                />
                <span>
                  <Text field={props.fields.LanguageOne} />
                </span>
              </a>
              <a
                className={`dropdown-item ${
                  location.pathname.includes(locationSpanishLang)
                    ? "active"
                    : ""
                }`}
                href={locationSpanishLang}
              >
                <img
                  className="header-dropdown-icon"
                  src={`${ICON_PATH}/tick-teal.svg`}
                  alt={props.fields.LanguageTwo?.value}
                />
                <span>
                  <Text field={props.fields.LanguageTwo} />
                </span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default CommonRegisterHeader;
