import React from "react";
import { IMAGE_PATH } from "../../define.constants";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { COST_ZERO } from "../../define.constants";

const ViewIdCard = ({ fields, result, details, idCard, drugInfo }) => {
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/2021/2021-MP-ID-Card-${idCard?.OriginalMemberProfile?.State}.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <thead>
              {["TX", "UT", "OH"].includes(idCard?.StateName) && (
                <tr>
                  <th
                    className={`misc-headings-first-card-${idCard?.StateName} float-right`}
                  >
                    <Text field={fields.lblMisc} />
                  </th>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblSubscriberName} />
                  </b>
                  &nbsp; {details?.Member_Name}
                </td>
                <td className="table-bold-seperator">
                  <b>
                    <Text field={fields.lblID} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblSubscriberId} />
                  </b>
                  &nbsp; {details?.Member_Number}
                </td>
                <td className="table-bold-seperator">
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp;
                  {`${idCard?.OriginalMemberProfile?.LastName}, ${idCard?.OriginalMemberProfile?.FirstName}`}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblDOB} />
                  </b>
                  &nbsp; {details?.Member_DOB}
                </td>
                <td className="table-bold-seperator">
                  <b>
                    <Text field={fields.lblPlan} />
                  </b>
                  &nbsp; {idCard?.PlanName}
                </td>
              </tr>
              <tr>
                <td>
                  <b></b>&nbsp;
                </td>
                <td className="table-bold-seperator">
                  <b>
                    <Text field={fields.lblEffectiveDate} />
                  </b>
                  &nbsp; {details?.MemberEffectiveDate}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td className="table-bold-seperator">&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <u className="table-second-heading">
                      <Text field={fields.lblMedicalHeading} />
                    </u>
                  </b>
                </td>
                <td className="table-bold-seperator">
                  <b>
                    <u className="table-second-heading">
                      <Text field={fields.lblPrescriptionHeading} />
                    </u>
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPrimaryCare} />
                  </b>
                  &nbsp; {drugInfo?.PRIMARYCARECOST ?? COST_ZERO}
                </td>
                <td className="table-bold-seperator">
                  <b>
                    <Text field={fields.lblTierOne} />
                  </b>
                  &nbsp; {drugInfo?.GENERICDRUG ?? COST_ZERO}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblSpecialistVisits} />
                  </b>
                  &nbsp; {drugInfo?.SPECIALITYCARE ?? COST_ZERO}
                </td>
                <td className="table-bold-seperator">
                  <b>
                    <Text field={fields.lblTierTwo} />
                  </b>
                  &nbsp; {drugInfo?.BRANDDRUG ?? COST_ZERO}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblUrgentCare} />
                  </b>
                  &nbsp; {drugInfo?.URGENTCARE ?? COST_ZERO}
                </td>
                <td className="table-bold-seperator">
                  <b>
                    <Text field={fields.lblTierThree} />
                  </b>
                  &nbsp; {drugInfo?.NONPREFERREDDRUG ?? COST_ZERO}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblER} />
                  </b>
                  &nbsp; {drugInfo?.EMERGENCYCOST ?? COST_ZERO}
                </td>
                <td className="table-bold-seperator">
                  <b>
                    <Text field={fields.lblTierFour} />
                  </b>
                  &nbsp; {drugInfo?.SPECIALITYDRUG ?? COST_ZERO}
                </td>
              </tr>
              <tr>
                <td height="5" colSpan="2"></td>
              </tr>
              <tr>
                <td className="table-costsummary" colSpan="2">
                  <RichText field={fields.lblCostShares} />
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="table-costsummary2">
                  <Text field={fields.lblHealthCare} />
                  &nbsp;&nbsp;&nbsp;
                  {result?.RxBin && (
                    <>
                      <b>
                        <Text field={fields.lblBin} />
                      </b>
                      &nbsp; {result.RxBin} &nbsp;
                    </>
                  )}
                  {result?.RxPCN && (
                    <>
                      <b>
                        <Text field={fields.lblPCN} />
                      </b>
                      &nbsp; {result.RxPCN} &nbsp;
                    </>
                  )}
                  {idCard?.RxGroup && (
                    <>
                      <b>
                        <Text field={fields.lblGroup} />
                      </b>
                      &nbsp; {idCard.RxGroup}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/2021/2020-2021-MP-Card-Back.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <div className="member-id-card-back-heading">
              <b>
                <Text field={fields.lblIDBackHeading} />
              </b>
            </div>
            <div className="member-id-card-back-seperator">
              <b>
                <Text field={fields.lblIDBackMember} />{" "}
              </b>
              <Text field={fields.lblIDBackMemberData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackMiembro} />{" "}
              </b>
              <Text field={fields.lblIDBackMiembroData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackRemit} />{" "}
              </b>
              <Text field={fields.lblIDBackRemitData} />
            </div>
            <div className="rural-heading">
              <b>
                <Text field={fields.lblIDBackRemitRural} />{" "}
              </b>
              <Text field={fields.lblIDBackRemitRuralData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackServices} />
              </b>
              <Text field={fields.lblIDBackServicesData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackNAL} />
              </b>
              <Text field={fields.lblIDBackNALData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackEnfermeras} />
              </b>
              <Text field={fields.lblIDBackEnfermerasData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackCVS} />
              </b>
              <Text field={fields.lblIDBackCVSData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackDental} />
              </b>
              <Text field={fields.lblIDBackDentalData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackProvider} />
              </b>
              &nbsp;
              <Text field={fields.lblIDBackProviderData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackNotification} />
              </b>
              <Text field={fields.lblIDBackNotificationData} />
            </div>
            <div>
              <b>
                <Text field={fields.lblIDBackComplaintContact} />
              </b>
            </div>
            <div className="cust-padding">
              <b>
                <Text field={fields.lblIDBackComplaintContactData} />
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCard;
