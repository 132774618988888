import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Status from "./Status";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import DisplayTitleItem from "../MyHealthRecord-Layout/Common/DisplayTitleItem";
import { MARKETPLACE } from "../../define.constants";
import { AppContext } from "../../contexts/app.context";

const ServiceAuthorizationsRecords = (props) => {
  const { fields, data, next } = props;
  const { memberLOB } = useContext(AppContext);
  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();
  const divSpan = 2;

  return (
    <>
      <div className="row print-heading mb-4 d-none">
        <Text field={fields.lblprintHeading} />
      </div>
      <ul className="list-group d-none d-md-block" id="recordsToPrint">
        {data?.map((item, index) => (
          <li className="list-group-item" key={index}>
            <div className="clearfix"></div>
            <div className="row">
              <DisplayTitleItem
                nameField={fields.lblServiceAuthorization}
                valueField={
                  fields.lblServiceAuthorizationSymbol.value +
                  item.AuthorizationID
                }
                outClass={"col-list-title authorization-id d-xl-block d-none "}
              />
            </div>
            <div className="row d-xl-none">
              <DisplayItem
                nameField={fields.lblServiceAuthorization}
                valueField={
                  fields.lblServiceAuthorizationSymbol.value +
                  item.AuthorizationID
                }
                outClass={"col-xl-2 col-md-12 d-md-flex d-xl-block"}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
            </div>
            <div className="row label-list">
              <>
                <Status
                  outerClass={"cl-history-status d-xl-block position-relative"}
                  status={item.Status?.trim()}
                  fields={fields}
                />
                <DisplayItem
                  nameField={fields.lblSubmittedDate}
                  outClass={"col-xl-2 col-md-6 d-xl-block d-none"}
                  titleClass={"list-desc-title"}
                />
              </>
              <DisplayItem
                nameField={fields.lblServiceDescription}
                outClass={`col-xl-${divSpan} col-md-6 d-xl-block d-none`}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblReferredFrom}
                outClass={`col-xl-${divSpan} col-md-6 d-xl-block d-none`}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblReferredTo}
                outClass={`col-xl-${divSpan} col-md-6 d-xl-block d-none`}
                titleClass={"list-desc-title"}
              />
              <DisplayItem
                nameField={fields.lblReferredToSpecialty}
                outClass={`col-xl-${divSpan} col-md-6 d-xl-block d-none`}
                titleClass={"list-desc-title"}
              />
            </div>
            <div className="row">
              <>
                <DisplayItem
                  outClass={"col-xl-2 col-md-12 d-md-flex d-xl-block"}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                />
                <DisplayItem
                  nameField={fields.lblSubmittedDate}
                  valueField={item.DateOfRequest}
                  outClass={"col-xl-2 col-md-12 d-md-flex d-xl-block"}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                />
              </>
              <DisplayItem
                nameField={fields.lblServiceDescription}
                valueField={item.ProcedureDescription}
                outClass={`col-xl-${divSpan} col-md-12 d-md-flex d-xl-block`}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblReferredFrom}
                valueField={item.ReferredFromProviderFullName}
                outClass={`col-xl-${divSpan} col-md-12 d-md-flex d-xl-block`}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblReferredTo}
                valueField={item.ReferredToProviderFullName}
                outClass={`col-xl-${divSpan} col-md-12 d-md-flex d-xl-block`}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblReferredToSpecialty}
                valueField={
                  item.ReferredToSpecialty?.length !== 0
                    ? item.ReferredToSpecialty[0].Specialty
                    : null
                }
                outClass={`col-xl-${divSpan} col-md-12 d-md-flex d-xl-block`}
                titleClass={"list-desc-title d-lg-block d-xl-none"}
                detailsClass={"list-desc-details"}
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ServiceAuthorizationsRecords;
