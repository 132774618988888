import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import VerifyCode from "./VerifyCode";
import React, { useState } from "react";
import ForgotUserIdAndPwd from "./ForgotUserIdAndPwd";
import ResetPwrd from "./ResetPwrd";
import Confirmation from "./Confirmation";
import {
  STEP_USER_ID,
  STEP_PWD,
  STEP_VERIFY_CODE,
  STEP_RESET_PWD,
  STEP_CONFIRMATION,
  STEP_PASS_CONFIRMATION,
  INITIAL_NUMBER,
} from "./constants";

const RegistrationAccountHelp = (props) => {
  const [selectedOption, setselectedOption] = useState(INITIAL_NUMBER);
  const [step, setStep] = useState(INITIAL_NUMBER);
  const [optional, setOptional] = useState("");

  const handleClick = (e) => {
    setselectedOption(e.target.value);
  };

  const NavigateToSteps = (props, optional = "") => {
    setStep(props);
    setOptional(optional);
    props === INITIAL_NUMBER ? setselectedOption(props) : "";
  };

  return (
    <>
      {step === INITIAL_NUMBER && (
        <>
          <h5 className="card-title text-center">
            <Text field={props.fields.heading} />
          </h5>
          <div className="row radio-button">
            <div className="col-md-6 col-5">
              <input
                name="radio"
                value={STEP_USER_ID}
                id="radio1"
                type="radio"
                onClick={(e) => {
                  handleClick(e);
                }}
              />
              <label htmlFor="radio1">
                <Text field={props.fields.lblForgotUserId} />
              </label>
            </div>
            <div className="col-md-5 offset-md-1 col-7">
              <input
                name="radio"
                value={STEP_PWD}
                id="radio2"
                type="radio"
                onClick={(e) => {
                  handleClick(e);
                }}
              />
              <label htmlFor="radio2">
                <Text field={props.fields.lblForgotPwd} />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="recovery-btn col-md-12">
              <button
                type="button"
                className="btn text-info ripple-surface1"
                onClick={() => {
                  location = "/";
                }}
              >
                <Text field={props.fields.btnCancel} />
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  selectedOption === STEP_USER_ID || selectedOption === STEP_PWD
                    ? NavigateToSteps(selectedOption)
                    : alert(getFieldValue(props.fields, "lblSelectRadio"));
                }}
              >
                <Text field={props.fields.btnNext} />
              </button>
            </div>
          </div>
        </>
      )}
      {(step === STEP_USER_ID || step === STEP_PWD) && (
        <ForgotUserIdAndPwd
          fields={props.fields}
          selectedOption={selectedOption}
          NavigateToSteps={NavigateToSteps}
        />
      )}
      {step === STEP_VERIFY_CODE && (
        <VerifyCode
          fields={props.fields}
          selectedOption={selectedOption}
          NavigateToSteps={NavigateToSteps}
          email={optional}
        />
      )}
      {step === STEP_RESET_PWD && (
        <ResetPwrd
          fields={props.fields}
          NavigateToSteps={NavigateToSteps}
          email={optional}
        />
      )}
      {step === STEP_PASS_CONFIRMATION && (
        <Confirmation
          fields={props.fields}
          selectedOption={selectedOption}
          UserId={optional}
        />
      )}
      {step === STEP_CONFIRMATION && (
        <Confirmation
          fields={props.fields}
          selectedOption={selectedOption}
          UserId={optional}
        />
      )}
    </>
  );
};

export default RegistrationAccountHelp;
