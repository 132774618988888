import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Field, Form, Formik } from "formik";
import { ErrorContext } from "../../contexts/error.context";
import { requestDeleteMember } from "../../services/AccountSettingsMfaAPI";
import {
  SUCCESS_RESULT_CODE,
  SUPPORT_USER,
  TERMED_MEMBER,
} from "../../define.constants";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

const RequestDeleteAccount = (props) => {
  const fields = props.fields;
  const stateName = props.MemberStateName;
  const { setError } = useContext(ErrorContext);

  const [screen, setScreen] = useState(1);

  const requestDelete = (values) => {
    requestDeleteMember({})
      .then((response) => {
        if (response.ResultCode === SUCCESS_RESULT_CODE) {
          setScreen(2);
        } else {
          setError({ err: response?.ResultData?.Status });
        }
      })
      .catch((err) => {
        setError({ err });
      });
  };

  const screen2 = (
    <div>
      <div className="text-center MFA-info MFA-success-message">
        <Text field={fields.requestDeleteSuccess} />
      </div>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-molina"
          onClick={props.closeModal}
        >
          <Text field={fields.doneBtn} />
        </button>
      </div>
    </div>
  );

  const screen1 = (
    <div>
      <div>
        <Formik initialValues={{}}>
          {({ values, errors, touched, setFieldTouched }) => (
            <Form>
              <div className="text-center MFA-info MFA-success-message">
                <Text field={fields.requestDeleteDisclaimer} />
              </div>
              <div className="text-center">
                <button
                  onClick={props.closeModal}
                  className="btn MFA-left-Button"
                >
                  <Text field={fields.requestDeleteNo} />
                </button>
                <WhoIsAuthorized
                  authorize={{
                    [SUPPORT_USER]: false,
                    [TERMED_MEMBER]: true,
                  }}
                  type="link"
                  handleClick={requestDelete}
                >
                  {({ handleClick, disabledClass }) => {
                    return (
                      <button
                        type="button"
                        className="btn btn-molina MFA-right-Button"
                        onClick={handleClick}
                      >
                        <Text field={fields.requestDeleteYes} />
                      </button>
                    );
                  }}
                </WhoIsAuthorized>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );

  return <>{screen === 1 ? screen1 : screen2}</>;
};

export default RequestDeleteAccount;
