import React, { useState, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import {
  STEP_USER_ID,
  STEP_RESET_PWD,
  STEP_CONFIRMATION,
  STEP_PWD,
  FUNCTIONALITY_USERID,
  FUNCTIONALITY_PWD,
  INITIAL_NUMBER,
  SUCCESS_VERIFICATION,
  EMAIL_STR,
} from "./constants";
import * as RegistrationAPI from "../../services/RegistrationAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ErrorContext } from "../../contexts/error.context";
import { SUCCESS_RESPONSE, SUCCESS_RESULT_CODE } from "../../define.constants";

const VerifyCode = (props) => {
  const { fields, selectedOption, NavigateToSteps, email } = props;
  const [securityCode, setSecurityCode] = useState("");
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [showVerify, setShowVerify] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  var emailNameLength = email.substring(0, email.lastIndexOf("@")).length;
  var emailDomain = email.substring(email.lastIndexOf("@"));

  const submitSecurityCode = () => {
    if (
      selectedOption === STEP_USER_ID &&
      securityCode !== null &&
      securityCode !== ""
    ) {
      setSpinner(true);
      RegistrationAPI.getForgotUserIDPasswordPost({
        EmailId: email,
        InCorrectCount: INITIAL_NUMBER,
        SecurityCode: securityCode,
        Functionality: FUNCTIONALITY_USERID,
      })
        .then((data) => {
          if (data.ResultCode === SUCCESS_RESULT_CODE) {
            NavigateToSteps(STEP_CONFIRMATION, data.ResultData.UserId);
          } else {
            setShowVerify(false);
          }
        })
        .catch((err) => {
          //setError({ customMessage: getFieldValue(fields, "secCodeError1") });
          // console.log(getFieldValue(fields, "secCodeError1"), error);
          setError({ err });
        })

        .finally(() => {
          setSpinner(false);
        });
    } else if (
      selectedOption === STEP_PWD &&
      securityCode !== null &&
      securityCode !== ""
    ) {
      setSpinner(true);
      RegistrationAPI.getValidateSecurityCode({
        EmailId: email,
        SecurityCode: securityCode,
        Functionality: FUNCTIONALITY_PWD,
      })
        .then((data) => {
          if (data.ResultData === SUCCESS_VERIFICATION) {
            NavigateToSteps(STEP_RESET_PWD, email);
          } else {
            setShowVerify(false);
          }
        })
        .catch((error) => {
          console.log(getFieldValue(fields, "secCodeError1"), error);
        })

        .finally(() => {
          setSpinner(false);
        });
    } else {
      setErrorMsg(getFieldValue(fields, "secCodeError2"));
    }
  };

  const resendSecurityCode = () => {
    if (selectedOption === STEP_USER_ID) {
      setSpinner(true);
      RegistrationAPI.getSendOrResendSecurityCode({
        EmailId: email,
        Functionality: FUNCTIONALITY_USERID,
      })
        .then((data) => {
          if (
            data.Message !== SUCCESS_RESPONSE &&
            data.ResultCode !== SUCCESS_RESULT_CODE
          ) {
            alert(getFieldValue(fields, "alertErrorMsg"));
          } else {
            setErrorMsg(getFieldValue(fields, "lblResentCode"));
          }
        })
        .catch((error) => {
          console.log(getFieldValue(fields, "resendCodeError"), error);
        })
        .finally(() => {
          setSpinner(false);
        });
    } else if (selectedOption === STEP_PWD) {
      setSpinner(true);
      RegistrationAPI.getSendOrResendSecurityCode({
        EmailId: email,
        Functionality: FUNCTIONALITY_PWD,
      })
        .then((data) => {
          if (
            data.Message !== SUCCESS_RESPONSE &&
            data.ResultCode !== SUCCESS_RESULT_CODE
          ) {
            alert(getFieldValue(fields, "alertErrorMsg"));
          } else {
            setErrorMsg(getFieldValue(fields, "lblResentCode"));
          }
        })
        .catch((error) => {
          console.log(getFieldValue(fields, "resendCodeError"), error);
        })
        .finally(() => {
          setSpinner(false);
        });
    }
  };

  return (
    <>
      <h5 className="card-title text-center">
        <Text field={fields.lblVerifyCode} />
      </h5>
      {showVerify ? (
        <>
          {selectedOption === STEP_USER_ID ? (
            <div className="security-code sec-code">
              <Text field={fields.lblVerifyPwd} />
              <span>{email}.</span>
              <Text field={fields.lblVerifyMsg} />
            </div>
          ) : (
            <div className="security-code sec-code">
              <Text field={fields.lblVerifyPwd} />
              {emailNameLength > 3 && (
                <>
                  <span>
                    {email.substring(0, 3)}
                    {EMAIL_STR.repeat(emailNameLength - 3)}
                    {emailDomain}.
                  </span>
                </>
              )}
              {emailNameLength <= 3 && <span>{email}.</span>}
              <Text field={fields.lblVerifyMsg} />
            </div>
          )}
          <div className="row-title">
            <Text field={fields.lblSecurityCode} />
          </div>
          <input
            type="text"
            className="form-control"
            value={securityCode}
            name="text"
            aria-label="Password"
            onChange={(e) => setSecurityCode(e.target.value)}
          />
          <div className="step3-error-msg"> {errorMsg}</div>
          <div className="recovery-btn security col-md-12">
            <button
              type="button"
              className="btn text-info ripple-surface1"
              onClick={resendSecurityCode}
            >
              <Text field={fields.btnResendCode} />
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={submitSecurityCode}
            >
              <Text field={fields.btnSubmit} />
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="security-code sec-code">
            <Text field={fields.VeficationFailed} />
          </div>
          <div className="recovery-btn col-md-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                location = "/";
              }}
            >
              <Text field={fields.btnClose} />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default VerifyCode;
