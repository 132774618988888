import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import ReadMore from "../Common-Health-Records/ReadMore";

const MedicationDetailsMobileRecords = (props) => {
  const {
    fields,
    medicationName,
    prescribingDoctor,
    prescriberNPI,
    dateFilled,
    dateWritten,
    quantity,
    daysSupply,
    refillsAuthorized,
    refillsLeft,
    pharmacyNPI,
  } = props;
  return (
    <ul className="list-group d-block d-md-none medication-details">
      <li className="list-group-item">
        <div className="row">
          <div className="col-12 tab-sub-title">
            <b>
              <Text field={fields.lblMedicationsDetails} />
            </b>
          </div>
        </div>
        <div className="row field1 common-input">
          <DisplayItem
            nameField={fields.lblMedicationName}
            valueField={medicationName}
            outClass={"col-lg-3 col-md-6"}
            titleClass={"row-title"}
            detailsClass={"row-details"}
          />
          <DisplayItem
            nameField={fields.lblPrescribingDoctor}
            valueField={prescribingDoctor}
            outClass={"col-lg-3 col-md-6"}
            titleClass={"row-title"}
            detailsClass={"row-details"}
          />
          <DisplayItem
            nameField={fields.lblPrescriberNPI}
            valueField={prescriberNPI}
            outClass={"col-lg-2 col-md-6"}
            titleClass={"row-title"}
            detailsClass={"row-details"}
          />
          <DisplayItem
            nameField={fields.lblDateFilled}
            valueField={dateFilled}
            outClass={"col-lg-2 col-md-6"}
            titleClass={"row-title"}
            detailsClass={"row-details"}
          />
        </div>
        <div id={"readMoreDetails"} className="">
          <ReadMore props={fields}>
            <DisplayItem
              nameField={fields.lblDateWritten}
              valueField={dateWritten}
              outClass={"col-lg-2 col-md-6"}
              titleClass={"row-title"}
              detailsClass={"row-details"}
            />
            <DisplayItem
              nameField={fields.lblQuantity}
              valueField={quantity}
              outClass={"col-lg-3 col-md-6"}
              titleClass={"row-title"}
              detailsClass={"row-details"}
            />
            <DisplayItem
              nameField={fields.lblDaysSupply}
              valueField={daysSupply}
              outClass={"col-lg-3 col-md-6"}
              titleClass={"row-title"}
              detailsClass={"row-details"}
            />
            <DisplayItem
              nameField={fields.lblRefillsAuth}
              valueField={refillsAuthorized}
              outClass={"col-lg-2 col-md-6"}
              titleClass={"row-title"}
              detailsClass={"row-details"}
            />
            <DisplayItem
              nameField={fields.lblRefillsLeft}
              valueField={refillsLeft}
              outClass={"col-lg-2 col-md-6"}
              titleClass={"row-title"}
              detailsClass={"row-details"}
            />
            <DisplayItem
              nameField={fields.lblPharmacyNPI}
              valueField={pharmacyNPI}
              outClass={"col-lg-2 col-md-6"}
              titleClass={"row-title"}
              detailsClass={"row-details"}
            />

            <div className="close-btn" id={"closeBtnDetails"}></div>
          </ReadMore>
        </div>
      </li>
    </ul>
  );
};

export default MedicationDetailsMobileRecords;
