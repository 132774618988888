import React, { useContext, useState, useEffect } from "react";
import { CustomSelect } from "../../../common/Select";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../contexts/app.context";

const DependentList = ({
  elem = "checkbox",
  fields,
  onDependentSelected,
  dependents,
  defaultMemID = null,
}) => {
  const { memberRKSID } = useContext(AppContext);
  const [selectedDependents, setSelectedDependents] = useState([]);
  const [choice, setChoice] = useState(null);
  useEffect(() => {
    if (
      (selectedDependents.isArray && selectedDependents.length > 0) ||
      (!selectedDependents.isArray && selectedDependents !== null)
    ) {
      onDependentSelected(selectedDependents);
    }
  }, [onDependentSelected, selectedDependents]);

  const handleDependentSelect = (e, type) => {
    let selected;
    if (type === "all") {
      selected = e.target.checked ? dependents : [];
    } else {
      let currentSelection = dependents.filter(
        (d) =>
          d.RksMemberId.toString() ===
          (type === "check" ? e.target.value : e.value).toString()
      )[0];
      setChoice(currentSelection);
      selected =
        type === "check"
          ? e.target.checked
            ? [...selectedDependents, currentSelection]
            : selectedDependents.filter(function (selectedDependent) {
                return (
                  selectedDependent.RksMemberId.toString() !==
                  currentSelection.RksMemberId.toString()
                );
              })
          : currentSelection;
    }
    setSelectedDependents(
      Array.isArray(selected) ? [...new Set(selected)] : selected
    );
  };

  if (dependents?.length > 0) {
    if (elem === "dropdown") {
      return (
        <CustomSelect
          id="drp_tab"
          className="form-select"
          aria-label="drp_tab"
          field={{
            name: "dependents",
            value:
              choice?.RksMemberId ||
              (defaultMemID ? defaultMemID.RksMemberId : memberRKSID),
          }}
          options={dependents?.map((item) => ({
            value: item?.RksMemberId,
            label: item?.LastName + ", " + item?.FirstName,
          }))}
          isFormik={false}
          onChange={(e) => handleDependentSelect(e, "select")}
        />
      );
    } else {
      return (
        <>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked={selectedDependents.length === dependents.length}
              onChange={(e) => handleDependentSelect(e, "all")}
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              <Text field={fields.lblPopupAll} />
            </label>
          </div>
          {dependents?.map((dependent) => (
            <div key={dependent.$id} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={dependent.RksMemberId}
                id={dependent.$id}
                checked={selectedDependents?.some(
                  (d) =>
                    d?.RksMemberId.toString() ===
                    dependent?.RksMemberId.toString()
                )}
                onChange={(e) => handleDependentSelect(e, "check")}
              />
              <label className="form-check-label" htmlFor={dependent.$id}>
                {`${dependent.LastName}, ${dependent.FirstName}`}
              </label>
            </div>
          ))}
        </>
      );
    }
  }
  return null;
};

export default DependentList;
