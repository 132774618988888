import React, { useContext, useState } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  changeEmailInitiate,
  changeEmailValidate,
} from "../../services/AccountSettingsMfaAPI";
import { ErrorContext } from "../../contexts/error.context";
import { getAllUserDevices } from "../../services/AccountSettingsMfaAPI";
import {
  CODE_RESULT_SUCCESS,
  EXISTING_EMAIL,
  INVALID_OPT,
  SUCCESS_RESULT_CODE,
} from "../../define.constants";

const EditEmail = (props) => {
  const fields = props.fields;
  const { setError } = useContext(ErrorContext);
  const [newEmail, setNewEmail] = useState();
  const [step, setStep] = useState(0);
  const [deviceID, setDeviceID] = useState();
  const [showEmailError, setShowEmailError] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [codeSentCount, setCodeSentCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const requiredMark = <span style={{ color: "red" }}>*</span>;

  const onNext = (values) => {
    setShowError(false);
    changeEmailInitiate({
      username: props.userId,
      newEmail: values.newEmail,
    })
      .then((response) => {
        if (response.ResultCode === CODE_RESULT_SUCCESS) {
          setNewEmail(values.newEmail);
          setDeviceID(response?.ResultData?.changeEmailResponse?.DeviceID);
          setStep(1);
        } else if (
          response.ResultData?.changeEmailResponse?.Status == EXISTING_EMAIL
        ) {
          setShowEmailError(true);
        } else {
          setError({ err: response?.ResultData?.changeEmailResponse?.Status });
        }
      })
      .catch((err) => {
        setError({ err });
      });
  };

  const submit = async (values, { resetForm }) => {
    try {
      const response = await changeEmailValidate({
        username: props.userId,
        newEmail: newEmail,
        deviceAuthId: deviceID,
        OTP: values.code,
      });
      if (
        response.ResultCode === CODE_RESULT_SUCCESS &&
        response.ResultData?.changeEmailValidateResponse?.Status != INVALID_OPT
      ) {
        const deviceResponse = await getAllUserDevices({});
        if (deviceResponse.ResultCode === SUCCESS_RESULT_CODE) {
          const isDefault = props.MFAOptions[0].isDefault;
          const data =
            deviceResponse.ResultData.ActiveMFADevices.ActiveDeviceList;
          const newOptions = props.getOptions(data);

          if (isDefault) {
            newOptions[0].isDefault = true;
            props.setDefault(0, newOptions);
          } else {
            props.setMFAOptions(newOptions);
          }
          setStep(2);
        } else {
          setError({ err: deviceResponse.Message });
        }
      } else {
        if (response.ResultCode !== CODE_RESULT_SUCCESS) {
          setError({ err: response.Message });
        } else {
          resetForm();
          if (attemptCount < 2) {
            setShowError(
              <div className="error-message margin-top-one-rem">
                <Text field={fields.emailPhoneOtp} />
              </div>
            );
          } else if (attemptCount >= 2 && codeSentCount < 2) {
            setShowError(
              <div className="error-message margin-top-one-rem">
                <Text field={fields.emailPhoneOtpThirdAttempt} />
              </div>
            );
          } else {
            setShowError(
              <div className="error-message margin-top-one-rem">
                <Text field={fields.emailOtpAfterThirdAttempt} />
              </div>
            );
          }
          setAttemptCount((count) => count + 1);
        }
      }
    } catch (err) {
      setError({ err });
    }
  };

  const enterCodeError = (
    <div className="missing-code">
      <Text field={fields.pleaseEnterSecurityCode} />
    </div>
  );

  const step1 = (
    <div>
      <div className="changeEmailHeader MFAHeader text-center">
        <Text field={fields.changeEmail} />
      </div>
      <Formik
        initialValues={{ newEmail: "", confirmEmail: "" }}
        onSubmit={onNext}
        validationSchema={Yup.object().shape({
          newEmail: Yup.string().required().email(),
          confirmEmail: Yup.string()
            .required()
            .oneOf([Yup.ref("newEmail"), null]),
        })}
      >
        {({ values, errors, touched }) => (
          <Form>
            <div className="">
              <div className="margin-bottom-two-rem">
                <span className="MFALabel">
                  <Text field={fields.oldEmail} />
                </span>
                <span className="MFAItem">{props.email}</span>
                {showEmailError && (
                  <div className="error-message margin-top-one-rem">
                    <Text field={fields.emailExistError} />
                  </div>
                )}
              </div>
              <div className="editEmailMFA">
                <div className="editEmailLabel MFALabel">
                  <Text field={fields.newEmail} />
                  {requiredMark}
                </div>
                <Field
                  name="newEmail"
                  type="text"
                  onKeyDown={() => setShowEmailError(false)}
                  className={`form-control ${
                    errors.newEmail && touched.newEmail && "is-invalid"
                  }`}
                />
                {errors.newEmail && touched.newEmail && (
                  <div className="error-message">
                    <Text field={fields.enterValidEmail} />
                  </div>
                )}
              </div>
              <div className="editEmailMFA">
                <div className="editEmailLabel MFALabel">
                  <Text field={fields.confirmNewEmail} />
                  {requiredMark}
                </div>
                <Field
                  name="confirmEmail"
                  type="text"
                  className={`form-control ${
                    values.newEmail !== values.confirmEmail &&
                    touched.confirmEmail &&
                    "is-invalid"
                  }`}
                />
                {values.newEmail !== values.confirmEmail &&
                  touched.confirmEmail && (
                    <div className="error-message">
                      <Text field={fields.emailMatchError} />
                    </div>
                  )}
              </div>
            </div>
            <div className="codeButtons text-center">
              <button
                type="button"
                className="btn blue-text"
                onClick={props.close}
              >
                <Text field={fields.cancelBtn} />
              </button>
              <button
                type="submit"
                className="btn btn-molina"
                disabled={errors.confirmEmail && errors.confirmEmail}
              >
                <Text field={fields.continueBtn} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  const step2 = (
    <div>
      <div className="text-center changeEmailHeader MFAHeader">
        <Text field={fields.verifyCode} />
      </div>
      <div className="mfa-description MFA-info">
        <Text field={fields.emailTo} />
        {newEmail}.
      </div>
      <div className="mfa-description MFA-info">
        <Text field={fields.spamFolder} />
      </div>
      <div className="MFALabel editEmailLabel">
        <Text field={fields.oneTimeCode} />
      </div>
      <Formik
        initialValues={{ code: "" }}
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          code: Yup.string().required().length(6),
        })}
      >
        {({ values, errors, touched }) => (
          <Form>
            <div>
              <Field
                name="code"
                type="text"
                maxLength="6"
                className={`form-control ${
                  errors.code && touched.code && "is-invalid"
                }`}
              />
              {errors.code && touched.code && enterCodeError}
            </div>
            {showError}
            <div className="text-center codeButtons">
              <button
                type="button"
                className="btn blue-text"
                disabled={codeSentCount >= 2}
                onClick={() => {
                  onNext({ newEmail });
                  setAttemptCount(0);
                  setCodeSentCount((count) => count + 1);
                  setShowError(
                    <div className="error-message margin-top-one-rem">
                      <Text field={fields.codeResent} />
                    </div>
                  );
                }}
              >
                <Text field={fields.resendCodeBtn} />
              </button>
            </div>
            <div className="codeButtons text-center">
              <button
                type="button"
                className="btn blue-text"
                onClick={props.close}
              >
                <Text field={fields.cancelBtn} />
              </button>
              <button
                type="submit"
                className="btn btn-molina"
                maxLength="6"
                disabled={errors.code || attemptCount > 2}
              >
                <Text field={fields.submitBtn} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  const step3 = (
    <div>
      <div className="text-center changeEmailHeader MFAHeader">
        <Text field={fields.success} />
      </div>
      <div className="mfa-description MFA-info">
        <Text field={fields.emailSuccess} />
      </div>
      <div className="text-center">
        <button type="button" className="btn btn-molina" onClick={props.close}>
          <Text field={fields.doneBtn} />
        </button>
      </div>
    </div>
  );

  return <>{step === 0 ? step1 : step === 1 ? step2 : step3}</>;
};

export default EditEmail;
