import React, { useEffect, useRef, useContext } from "react";
import Communication from "./Communication";
import Language from "./Language";
import PersonalInformation from "../AccountSettings-MyProfileWrapper/PersonalInformation";
import PlanInformation from "./PlanInformation";
import { useLocation } from "react-router-dom";
import { MEDICARE, MARKETPLACE } from "../../define.constants";
import { AppContext } from "../../contexts/app.context";
import MemberNameChange from "./MemberNameChange";
const AccountSettingsMyProfileWrapper = (props) => {
  const { fields, setActiveTab, tabOptions } = props;
  let currentpath = useLocation();
  useEffect(() => {
    if (currentpath?.hash === "#LangNavSection") {
      document.getElementById("LangNavSection").scrollIntoView();
    }
  }, [currentpath]);
  //Member Name Change Validate OH users
  let { profileList, memberLOB, planId } = useContext(AppContext);
  const planIDs = [
    "QMXBP0780",
    "QMXBP6757",
    "QMXBP7932",
    "QMXBP0780",
    "QMXBP8013",
    "QMXBP8399",
    "QMXBP8400",
    "QMXBP8407",
  ];
  const isMarketPlaceOrMedicare =
    memberLOB.toLowerCase() === MARKETPLACE ||
    memberLOB.toLowerCase() === MEDICARE;

  return (
    <div className="row">
      <div className="col-12">
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="formsDocs"
            role="tabpanel"
            aria-labelledby="tab-1"
          >
            <div className="row no-tab-title">
              <div className="col-md-12">
                <ul className="list-group">
                  <PersonalInformation fields={fields} />
                  <Communication
                    fields={fields}
                    setActiveTab={setActiveTab}
                    tabOptions={tabOptions}
                  />
                  <div id="LangNavSection">
                    {isMarketPlaceOrMedicare && (
                      <PlanInformation fields={fields} />
                    )}
                    <Language fields={fields} />
                  </div>
                  {/* Member Name Change section start */}
                  <div id="NameChangeSection">
                    {profileList?.State === "OH" &&
                      planIDs.includes(planId) && (
                        <MemberNameChange fields={fields} />
                      )}
                  </div>
                  {/* Member Name Change section end */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsMyProfileWrapper;
