import Axios from "./axios/provider";

/**
 * Get PCP information!
 *
 * @param {object} data
 * @returns
 */
export const getPCPInformation = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.PCPInformation,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
