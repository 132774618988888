import Axios from "./axios/provider";
import { SpinnerContext } from "../contexts/spinner.context";

/**
 * Get Allergies List
 *
 * @param {object} data
 * @returns
 */
export const getCommunicationPreferences = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ViewCPPlanNotification,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const UpdateCPPlanNotification = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.UpdateCPPlanNotification,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
