import React from "react";
import { formatDependentDOB } from "../../utils/helpers";
import { onFocusFormatPhoneNumber } from "./phoneNumberValidation";

const nullOrValue = (value) => (value ? value : null);

export const formaData = (formik, profileList, reportDate, QNXTMemberID) => {
  console.log({ formik, profileList });
  return {
    MemberOrPrId: null, //"MA-MEM00001487829",
    HealthPlanEmailAddress: formik.healthPlanEmailAddress,
    HealthPlanContactExtn: nullOrValue(formik.healthPlanExtn),
    HealthPlanContactNumber: formik.healthPlanPhoneNum
      ? onFocusFormatPhoneNumber(formik.healthPlanPhoneNum)
      : "",
    HealthPlanContactLastName: formik.healthPlanContactLastName,
    HealthPlanContactFirstName: formik.healthPlanContactFirstName,
    HealthPlanMemberID: formik.memberHealthPlan,
    HealthPlanName: formik.healthPlanName,
    EmailAddress: profileList.Email,
    AlternatePhoneNumber: formik.altPhoneNum
      ? onFocusFormatPhoneNumber(formik.altPhoneNum)
      : null,
    PhoneNumber: formik.phoneNumber
      ? onFocusFormatPhoneNumber(formik.phoneNumber)
      : null,
    Zip: profileList.Zip,
    State: profileList.State,
    City: profileList.City,
    Address: profileList.MailingAddress,
    DateOfBirth: profileList.DateOfBirth,
    Gender: profileList.Gender,
    MemberNumber: profileList.MemberId,
    LastName: profileList.LastName,
    FirstName: profileList.FirstName,

    VoluntaryAuthorization: true,
    RightToContact: true,
    Authorization: true,
    NoCancellationAfterAuthorization: true,
    Source: "Portal",
    RequestType: null,
    RequestorType: formik.request === "member" ? "Self" : "Onbehalfof",
    RelationshipType: formik.request === "member" ? "Self" : "Dependent",
    RequestDate: reportDate(),
    DependentFirstName: nullOrValue(formik.dependentFirstName),
    DependentLastName: nullOrValue(formik.dependentLastName),
    DependentMemberNumber: nullOrValue(formik.dependentMemberId),
    QNXTMemberID: nullOrValue(QNXTMemberID),
    DependentQNXTMemberID: formik.DependentQNXTMemberID,
    DependentGender: nullOrValue(formik.dependentGender),
    // DependentDateOfBirth: formik.dependentDateOfBirth
    //   ? getFormattedDate(formik.dependentDateOfBirth).replace(/[^ -~]/g, "")
    //   : null,
    DependentDateOfBirth: formik.dependentDateOfBirth
      ? formatDependentDOB(formik.dependentDateOfBirth)
      : null,
    DependentAddress: nullOrValue(formik.dependentAddress),
    DependentCity: nullOrValue(formik.dependentCity),
    DependentState: nullOrValue(formik.dependentState),
    DependentZip: nullOrValue(formik.dependentZip),
    SalesForceResponse: null,
    DependentPhoneNumber: formik.dependentPhoneNumber
      ? onFocusFormatPhoneNumber(formik.dependentPhoneNumber)
      : null,
    DependentEmailAddress: nullOrValue(formik.dependentEmail),
    RelationshipWithDependent: nullOrValue(formik.relationshipWithDependent),
    DependentAlternatePhoneNumber: formik.dependentAltPhoneNum
      ? onFocusFormatPhoneNumber(formik.dependentAltPhoneNum)
      : null,

    USER_ID: profileList.userId,
    SUSER_ID: profileList.supportUserId,
    RoleId: profileList.roleId,
    ProgramID: profileList.programId,
    LOB: profileList.memberLOB,
  };
};

export const apiResponseTemplate = (heading, message) => (
  <div className="update-header">
    <p className="text-center">{heading}</p>
    <div className="text-center">
      <p>{message}</p>
    </div>
  </div>
);
