import React from "react";
import { SSORedirectProvider } from "../contexts/SSORedirect.context";

function withSSOProvider(WrappedComponent) {
  return class HOC extends React.Component {
    render() {
      return (
        <SSORedirectProvider>
          <WrappedComponent {...this.props} />
        </SSORedirectProvider>
      );
    }
  };
}

export { withSSOProvider };
