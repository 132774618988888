import {
  GRID_1COL_CONTAINER,
  GRID_2COL_CONTAINER,
  GRID_3COL_CONTAINER,
  GRID_4COL_CONTAINER,
} from "../../define.constants";

export const renderApproach = (approachList) => {
  switch (approachList.length) {
    case 1:
      return GRID_1COL_CONTAINER;
    case 2:
      return GRID_2COL_CONTAINER;
    case 3:
      return GRID_3COL_CONTAINER;
    case 4:
      return GRID_4COL_CONTAINER;
    default:
      return "";
  }
};
