import React, { useEffect, useRef } from "react";
import Communication from "./Communication";
import Language from "./Language";
import PersonalInformation from "./PersonalInformation";
import PlanInformation from "./PlanInformation";
import { useLocation } from "react-router-dom";

const AccountSettingsPayer = (props) => {
  const { fields, setActiveTab, tabOptions } = props;
  let currentpath = useLocation();
  useEffect(() => {
    if (currentpath?.hash === "#LangNavSection") {
      document.getElementById("LangNavSection").scrollIntoView();
    }
  }, [currentpath]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="formsDocs"
            role="tabpanel"
            aria-labelledby="tab-1"
          >
            <div className="row no-tab-title">
              <div className="col-md-12">
                <ul className="list-group">
                  <PersonalInformation fields={fields} />
                  <PlanInformation fields={fields} />
                  <Communication
                    fields={fields}
                    setActiveTab={setActiveTab}
                    tabOptions={tabOptions}
                  />
                  <div id="LangNavSection">
                    <Language fields={fields} />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsPayer;
