import React, { useCallback, useState, useContext } from "react";
import { SitecoreContextReactContext } from "@sitecore-jss/sitecore-jss-react";
import { getConfigByKey } from "../utils/helpers";
import { GENERIC_ERROR } from "../define.constants";

const useError = () => {
  const {
    context: {
      route: { placeholders },
    },
  } = useContext(SitecoreContextReactContext);
  const [message, setMessage] = useState("");

  /**
   * Common method to show error in modal if API fails!
   *
   * @param {boolean} showDefault: to show default generic message
   * @param {string} customMessage: to show custom module specific message
   * @param {object} err: response from API.
   */
  const setError = useCallback(
    ({ showDefault = true, customMessage = "", err = {} }) => {
      console.log("useError", err);
      if (customMessage) {
        setMessage(customMessage);
      } else if (shouldDisplayErrorInPopup(err?.statusCode)) {
        if (showDefault) {
          setMessage(
            getConfigByKey(GENERIC_ERROR, placeholders?.["jss-config"])
          );
        } else if (typeof err === "object" && err?.data) {
          //TODO
          setMessage(err?.data?.Message);
        } else {
          setMessage(
            getConfigByKey(GENERIC_ERROR, placeholders?.["jss-config"])
          );
        }
      }
    },
    [placeholders]
  );

  /**
   * This method will reset the error message!
   */
  const resetError = useCallback(() => {
    if (message) {
      setMessage("");
    }
  }, [message]);

  /**
   * Will check 5xx, 4xx series and ignore 401 (Unauthorized) and 403 (Forbidden)!
   * Redirection to login page is already handled for 401 and 403.
   *
   * @param {Number} statusCode
   * @returns
   */
  const shouldDisplayErrorInPopup = (statusCode) => {
    if (500 <= statusCode && statusCode <= 511) {
      return true;
    } else if (
      statusCode !== 401 &&
      statusCode !== 403 &&
      400 <= statusCode &&
      statusCode <= 451
    ) {
      return true;
    }
    return false;
  };

  return { message, setError, resetError };
};

export { useError };
