import React from "react";
import { YearDropdownProvider } from "../contexts/yearDropdown.context";

function withYearDropdownProvider(WrappedComponent) {
  return class HOC extends React.Component {
    render() {
      return (
        <YearDropdownProvider>
          <WrappedComponent {...this.props} />
        </YearDropdownProvider>
      );
    }
  };
}

export { withYearDropdownProvider };
