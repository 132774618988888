import React, { useContext, useEffect, useState } from "react";
import {
  Placeholder,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import {
  PRIMARY_SUBSCRIBER_ROLEID,
  PS_FLAG_STATUS_ACTIVE,
  ACCESS_MGNT_TAB,
  MANAGE_COMUNICATION_TAB,
  SUBSCRIBER,
  MARKETPLACE,
  GLOBAL_VARIABLE,
  NY,
  MEDICAID,
  MFA_TAB,
  NY_MD_HARP_PROGRAMID,
  NYMCC_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  NY_MD_SWHFIDE_PROGRAMID,
  MEDICARE,
} from "../../define.constants";
import { useViewCommunicationPreferences } from "../../hooks/useViewCommunicationPreferences";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
const AccountSettingsLayout = (props) => {
  const { rendering, fields } = props;
  let {
    isDependentLoggedIn,
    isSwitchedView,
    IsDSNPMember,
    DSNPdetails,
    DependentCategory,
    loggedInUserDependentCat,
    switchedUserType,
    isSwitchedToDependent,
    isSupportUser,
    selfRoleId,
    Dependents,
    memberLOB,
    MemberStateName,
    programId,
    isNYAffinityMember,
  } = useContext(AppContext);

  const { communicationData, isViewCommunicationLoaded } =
    useViewCommunicationPreferences();
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();
  const ismedicareMember = memberLOB?.toLowerCase() === MEDICARE.toLowerCase();
  const isNYstate = MemberStateName !== NY;
  const tabsToHide = [];
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  const program_inscop = [
    NY_MD_HARP_PROGRAMID,
    NYMCC_PROGRAMID,
    NY_MD_NMC_PROGRAMID,
    NY_MD_SWHFIDE_PROGRAMID,
  ];
  const isSubscription = program_inscop.includes(programId);
  console.log(isSubscription);

  if (
    isDependentLoggedIn ||
    (selfRoleId === PRIMARY_SUBSCRIBER_ROLEID && Dependents?.length < 1) ||
    (isDependentLoggedIn &&
      isSwitchedView &&
      switchedUserType === SUBSCRIBER) ||
    Dependents?.length === 0 ||
    !isMarketplaceMember
  ) {
    tabsToHide.push(ACCESS_MGNT_TAB);
  }
  tabsToHide.push(MANAGE_COMUNICATION_TAB);

  // HIDE ACCOUNT SETTINGS - MFA
  if (IsDSNPMember || isMarketplaceMember) {
    if (IsDSNPMember) {
      for (let i = 0; i < DSNPdetails.length; i++) {
        if (DSNPdetails[i].IsCurrentLogin === 1) {
          const LOB = DSNPdetails[i].LOB.toUpperCase();
          if (LOB !== memberLOB.toUpperCase()) {
            tabsToHide.push(MFA_TAB);
          }
          break;
        }
      }
    } else if (isMarketplaceMember && isSwitchedView) {
      tabsToHide.push(MFA_TAB);
    } else {
      // DO NOTHING
    }
  }

  if (isNYstate || ismedicareMember || isMarketplaceMember || !isSubscription) {
    tabsToHide.push("AccountSettings-MySubscription");
  }

  return (
    <>
      <div className="account-settings">
        <div className="row">
          <div className="col-12">
            <div className="page-heading">
              <Text field={fields?.heading ?? ""} />
            </div>
          </div>
        </div>
        <Placeholder
          name="jss-account-settings-layout"
          rendering={rendering}
          hiddenTabs={tabsToHide}
        />
      </div>
    </>
  );
};

export default AccountSettingsLayout;
