import React, { useContext } from "react";
import {
  Text,
  Link,
  Image,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "./../../contexts/app.context";

function SubscriptionContent(props) {
  //const { innerSpinnerHTML } = useContext(SpinnerContext);
  let { prefPhone, homePhone, cellPhone, profileList } = useContext(AppContext);
  const { setActiveTab, tabOptions } = props;
  const fields = props?.fields;

  return (
    <div className="list-group-item accbox mb-4">
      <div className="d-flex field1 common-input">
        <div className="p-2">
          <Image
            className="img-molina-phone footer-img-size"
            field={fields.image}
            style={{ height: "55px", width: "55px", margin: "0 6px 0 0" }}
          />
        </div>
        <div className="p-2 flex-grow-1">
          <div className="row-details preferences-para">
            <Text field={props?.fields?.lettertabname} /> &nbsp;
            <Link
              className="link-design p-0 text-zoom-lg m-l-1"
              field={props.fields.link}
            />
            &nbsp;
            <Text field={props?.fields?.letterafterlink} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default SubscriptionContent;
