import React, { useContext, useEffect } from "react";
import {
  Placeholder,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {
  CARE_PLAN_TAB,
  DEPENDANT_18AndOVER,
  DEPENDANT_UNDER18,
  PRIMARY_SUBSCRIBER_ROLEID,
  HomePageUrl,
  SUBSCRIBER,
  LAB_RESULTS_TAB,
  NYMCC_PROGRAMID,
  ALLERGIES_TAB,
  NY,
  MARKETPLACE,
  GLOBAL_VARIABLE,
  MEDICATIONS_TAB,
  NY_MD_HARP_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  OH,
  MEDICAID,
  TX,
  TX_MD_PLUS_PROGRAMID,
  SERVICE_PLAN_TAB,
  TX_MEDICAID_STAR_PLUS,
  VACCINATIONS_TAB,
  CTCONTACTINFOACCESS_TAB,
} from "../../define.constants";

import { AppContext } from "../../contexts/app.context";
import { useGetCarePlanAccessAPI } from "../../hooks/useGetCarePlanAccessAPI";
import { redirectTo } from "./../../utils/helpers";
import { useGetServicePlanAPI } from "../../hooks/useGetServicePlanAPI";

const MyHealthRecordLayout = (props) => {
  const { rendering, fields } = props;
  const { carePlanFlag, isLoaded } = useGetCarePlanAccessAPI();
  const { MemberStateName, programId, memberLOB, memberStateCode } =
    useContext(AppContext);
  const { servicePlan } = useGetServicePlanAPI();
  const {
    isSwitchedToDependent,
    isDependentLoggedIn,
    roleId,
    isSwitchedView,
    DependentCategory,
    switchedUserDependentCat,
    loggedInUserDependentCat,
    switchedUserType,
  } = useContext(AppContext);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  const isTXMedicaidStarPlus =
    memberStateCode === TX && programId === TX_MEDICAID_STAR_PLUS;
  const ltsserviceplanFlag = servicePlan?.H17001Plan
    ? servicePlan.H17001Plan
    : false;
  const ltsaddendumFlag = servicePlan?.H17002Plan
    ? servicePlan.H17002Plan
    : false;
  const ltssignatureFlag = servicePlan?.H17003Plan
    ? servicePlan.H17003Plan
    : false;
  const ltstxserviceplanFlag = servicePlan?.TxServicePlan
    ? servicePlan.TxServicePlan
    : false;
  const isflag =
    ltsserviceplanFlag ||
    ltsaddendumFlag ||
    ltssignatureFlag ||
    ltstxserviceplanFlag;
  const isMedicaid = memberLOB?.trim()?.toLowerCase() === MEDICAID;
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();
  const isServiceplanStarPlus =
    memberStateCode === TX && programId === TX_MD_PLUS_PROGRAMID && isflag;

  useEffect(() => {
    if (
      (isDependentLoggedIn &&
        isSwitchedView &&
        switchedUserType === SUBSCRIBER) || //Conditions for 1486 & 1490
      (!isDependentLoggedIn &&
        isSwitchedView &&
        switchedUserDependentCat === DEPENDANT_18AndOVER) //Conditions for 1489
    ) {
      return redirectTo(HomePageUrl, false);
    }
  }, [
    isDependentLoggedIn,
    isSwitchedView,
    switchedUserDependentCat,
    switchedUserType,
  ]);

  const hiddenTabs = () => {
    let tabsToHide = [];

    if (!carePlanFlag || (MemberStateName === OH && isMedicaid)) {
      tabsToHide.push(CARE_PLAN_TAB);
    }
    if (!isServiceplanStarPlus) {
      tabsToHide.push(SERVICE_PLAN_TAB);
    }

    if (
      !isDependentLoggedIn && //Conditions for MMPI-1488
      isSwitchedView &&
      switchedUserDependentCat === DEPENDANT_UNDER18
    ) {
      tabsToHide.push(
        "MyHealthRecord-ServiceAuthorizations",
        "MyHealthRecord-Referrals",
        "MyHealthRecord-LabResults",
        "MyHealthRecord-Allergies",
        "MyHealthRecord-Medications",
        "MyHealthRecord-InpatientAdmissionsERVisits",
        "MyHealthRecord-CarePlan",
        "MyHealthRecord-CTContactInfoAccess",
        "MyHealthRecord-CTContactAccessManagement"
      );
    }

    // Display LabResults Tab only for TX Medicaid Star Plus & OH Medicaid members
    if (MemberStateName == TX && programId == TX_MD_PLUS_PROGRAMID) {
      // Do nothing, The tab should be visible
      tabsToHide.push(CARE_PLAN_TAB);
    }
    // //Hiding LabResults Tab FOR NON_MP EXCEPT TX MEDICAID STARPLUS
    else {
      if (!isMarketplaceMember) {
        tabsToHide.push(LAB_RESULTS_TAB);
      }
    }
    //Hiding LabResults Tab FOR NON_MP

    if (MemberStateName == NY && programId == NYMCC_PROGRAMID) {
      tabsToHide.push(ALLERGIES_TAB);
    }
    if (MemberStateName != OH || (MemberStateName == OH && !isMedicaid)) {
      tabsToHide.push("MyHealthRecord-CTContactInfoAccess");
    }
    // Display CTContactAccessManagement Tab only for TX Medicaid Star Plus & OH Medicaid members
    if (MemberStateName == TX && programId == TX_MD_PLUS_PROGRAMID) {
      // Do nothing, The tab should be visible
      tabsToHide.pop(CTCONTACTINFOACCESS_TAB);
    } else if (
      MemberStateName != OH ||
      (MemberStateName == OH && !isMedicaid)
    ) {
      tabsToHide.push("MyHealthRecord-CTContactAccessManagement");
    }

    // Hiding Vaccination Tab - other than TX Medicaid Star Plus members
    if (!(MemberStateName == TX && programId == TX_MD_PLUS_PROGRAMID)) {
      tabsToHide.push(VACCINATIONS_TAB);
    }

    return tabsToHide;
  };

  return (
    <>
      {isLoaded && (
        <div className="my-health-record">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12 col-12">
              <div className="page-heading">
                <Text field={fields?.heading ?? ""} />
              </div>
            </div>
          </div>

          <Placeholder
            name="jss-my-health-record-layout"
            rendering={rendering}
            hiddenTabs={hiddenTabs()}
          />
        </div>
      )}
    </>
  );
};

export default MyHealthRecordLayout;
