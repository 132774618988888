import React, { useState, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Form, Field, FieldArray, ErrorMessage, Formik } from "formik";
import { highlightBorderOnError } from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import { InlineFormError } from "../../common/InlineFormError";
import { AppContext } from "./../../contexts/app.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  ICON_PATH,
  PHONE_DIGIT_ONLY_REGEX,
  IUD_FLAG_D,
  SUPPORT_USER,
  TERMED_MEMBER,
} from "../../define.constants";
const PrimaryLanguage = ({
  fields,
  errors,
  values,
  touched,
  initialTouched,
  setFieldValue,
  setFieldTouched,
  setValues,
  pageEditable,
  onButtonClick,
  contactPreferencesList,
  handleBlur,
  handleChange,
  getPrimaryLanguageSelected,
  handlePrimaryLanguageValue,
  contactData,
}) => {
  let primaryLanguageList = [];
  let [primaryLanguageFlag, setPrimaryLaunguageFlag] = useState(true);
  let { isTermedMember } = useContext(AppContext);
  const handlePrimaryLanguageSelect = (e) => {
    setPrimaryLaunguageFlag(true);
    getPrimaryLanguageSelected(e.value);
  };
  contactPreferencesList?.HealthCloudGA__PrimaryLanguage__pc?.map(
    (data, index) => {
      primaryLanguageList.push({
        value: data,
        id: index,
        label: data,
      });
    }
  );
  const initialValues = {
    primaryLanguageList: contactData?.IsContactPreferences?.PrimaryLanguage,
  };
  return (
    <>
      {initialValues.primaryLanguageList !== undefined && (
        <Formik initialValues={initialValues}>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h5 className="tab-sub-title column-margin">
                  <Text field={fields?.primaryLanguage ?? ""} />
                </h5>
                <div
                  className={
                    pageEditable
                      ? "disabled-div dropdown-list-width"
                      : "dropdown-list-width"
                  }
                >
                  <Field
                    as="select"
                    id="primaryLanguageList"
                    name="primaryLanguageList"
                    options={primaryLanguageList}
                    value={
                      primaryLanguageList
                        ? primaryLanguageList.find(
                            (primaryLanguageList) =>
                              primaryLanguageList.value ===
                              values.primaryLanguageList
                          )
                        : ""
                    }
                    onChange={(e) => handlePrimaryLanguageSelect(e)}
                    component={CustomSelect}
                    isFormik={true}
                  />
                  <textarea
                    maxLength={20}
                    placeholder={getFieldValue(fields, "lblPrimaryLanguagePH")}
                    className={
                      primaryLanguageFlag
                        ? "textarea-display"
                        : "form-control textarea-control"
                    }
                    onChange={(e) => handlePrimaryLanguageValue(e)}
                  ></textarea>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default PrimaryLanguage;
