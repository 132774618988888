import { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../contexts/error.context";
import { SpinnerContext } from "../contexts/spinner.context";
import { HOME_MY_MESSAGE_CARD } from "../define.widgets";
import * as MessagesAPI from "../services/MessagesAPI";
const qs = require("qs");

const useMessageAPI = (
  recordsPerPage,
  userAction = null,
  page = 1,
  sortBy = null,
  searchTerm = null,
  dateSelected = null,
  fetchData = true
) => {
  const { setError } = useContext(ErrorContext);
  const { setInnerSpinner } = useContext(SpinnerContext);
  const [inboxMessageList, setInboxMessageList] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const setData = ({
    // MemMessageCenterResponse,
    MessageHeaders,
    //AttachmentListAllMessages,
  }) => {
    //let messages = MemMessageCenterResponse?.MessagesMyMolina || [];
    let messages = MessageHeaders || [];
    // let totalRecords = messages[0]?.TotalRecords || 0;
    // let sfMessages = MessageHeaders;
    setInboxMessageList(messages.MessagesList);
    // setTotalRecords(totalRecords);
    // setStatusMessage(MemMessageCenterResponse?.StatusMessage);
    // setAttachments(AttachmentListAllMessages[0]?.attachmentDetails || []);
  };

  useEffect(() => {
    if (fetchData) {
      let query = {
        UserAction: userAction,
        // Page: page,
        //RecordsPerPage: recordsPerPage,
        SortBy: sortBy,
        SearchTerm: searchTerm,
        DateSelected: dateSelected,
        fetchData: fetchData,
      };
      setInnerSpinner(true, HOME_MY_MESSAGE_CARD);
      MessagesAPI.getMsgHeaders(
        {
          UserAction: userAction,
          Page: 1,
          RecordsPerPage: 100,
          SortBy: sortBy ? sortBy + " Desc" : null,
          SearchTerm: searchTerm,
          DateSelected: dateSelected,
        },
        qs.stringify(query, { addQueryPrefix: true })
      )
        .then((response) => {
          if (response?.ResultData != undefined) {
            setInboxMessageList(
              response?.ResultData?.MemMessageCenterResponse?.MessagesMyMolina
            );
            //setData(response.ResultData);
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, HOME_MY_MESSAGE_CARD);
        });
    }
  }, [
    page,
    recordsPerPage,
    sortBy,
    searchTerm,
    fetchData,
    userAction,
    dateSelected,
  ]);

  return {
    inboxMessageList,
    // statusMessage,
    // attachments,
    // totalRecords,
  };
};

export { useMessageAPI };
