import React, { useState, useEffect, useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH, MARKETPLACE } from "../../define.constants";
import { useGetMedicationsHistoryAPI } from "../../hooks/useGetMedicationsHistoryAPI";
import MedicationDetailsRecords from "./MedicationDetailsRecords";
import MedicationDetailsMobileRecords from "./MedicationDetailsMobileRecords";
import { printReportDate } from "../Common-Health-Records/Helper";
import MedicationHistoryRecords from "./MedicationHistoryRecords";
import MedicationHistoryMobileRecords from "./MedicationHistoryMobileRecords";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_HEALTH_RECORD_GET_MEDICATIONS_HISTORY } from "../../define.widgets";
import { AppContext } from "./../../contexts/app.context";
function MedicationsDetails(props) {
  const {
    fields,
    BacktoMedDetals,
    medicationName,
    prescribingDoctor,
    prescriberNPI,
    dateFilled,
    dateWritten,
    quantity,
    daysSupply,
    refillsAuthorized,
    refillsLeft,
    pharmacyNPI,
  } = props;

  const { medicationsHistoryList, isMedHistoryLoaded } =
    useGetMedicationsHistoryAPI(medicationName);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  // Pagination
  // const postsPerPage = props.fields.lblPostPerPage.value;

  // /**Pagination useState Variables */
  // const [next, setNext] = useState(postsPerPage);
  // const [page, setPage] = useState(1);

  /**Filtering useState Variables */
  const [filteredRecords, setFilteredRecords] = useState([]);

  useEffect(() => {
    setFilteredRecords(medicationsHistoryList);
  }, [medicationsHistoryList]);

  // let totalRecords =
  //   filteredRecords !== null ? filteredRecords?.length ?? "" : 0;
  // let totalPages = Math.ceil(totalRecords / postsPerPage);
  let { memberLOB } = useContext(AppContext);
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();

  return (
    <div className="row no-tab-title" id="medicationDetails">
      <div className="col-md-12">
        <div className="back-to-list">
          <div className="media">
            <img
              className="d-flex align-self-start back-to-list-icon"
              src={`${ICON_PATH}/icon-navigation-chevron_leftblack_24px.svg`}
              alt={fields.lnkBackToList.value.text}
            />
            <div className="media-body">
              <a href="#" onClick={(e) => BacktoMedDetals(e)}>
                {fields.lnkBackToList.value.text}
              </a>{" "}
            </div>
          </div>
        </div>
        <div className="row print-heading mb-4 d-none">
          <Text field={props.fields.lblHistoryprintHeading} />
        </div>
        <MedicationDetailsRecords
          fields={fields}
          medicationName={medicationName}
          prescribingDoctor={prescribingDoctor}
          prescriberNPI={prescriberNPI}
          dateFilled={dateFilled}
          dateWritten={dateWritten}
          quantity={quantity}
          daysSupply={daysSupply}
          refillsAuthorized={refillsAuthorized}
          refillsLeft={refillsLeft}
          pharmacyNPI={pharmacyNPI}
        />
        <MedicationDetailsMobileRecords
          fields={fields}
          medicationName={medicationName}
          prescribingDoctor={prescribingDoctor}
          prescriberNPI={prescriberNPI}
          dateFilled={dateFilled}
          dateWritten={dateWritten}
          quantity={quantity}
          daysSupply={daysSupply}
          refillsAuthorized={refillsAuthorized}
          refillsLeft={refillsLeft}
          pharmacyNPI={pharmacyNPI}
        />
        {isMedHistoryLoaded ? (
          <>
            {medicationsHistoryList === null ||
            medicationsHistoryList?.length === 0 ? (
              <div className="no-records">
                <Text field={props.fields.lblZeroDesc} />
              </div>
            ) : (
              <>
                <hr />

                <MedicationHistoryRecords
                  fields={props.fields}
                  data={filteredRecords}
                  isMarketplaceMember={isMarketplaceMember}
                />
                <MedicationHistoryMobileRecords
                  fields={props.fields}
                  data={filteredRecords}
                  isMarketplaceMember={isMarketplaceMember}
                />

                {printReportDate(props.fields.lblPrintGenerated)}
              </>
            )}
          </>
        ) : (
          filteredRecords?.slice(0).length === 0 && (
            <li className="list-group-item">
              {innerSpinnerHTML?.[MY_HEALTH_RECORD_GET_MEDICATIONS_HISTORY]}
              <div className="row field1">
                <div className="col-sm-12"></div>
              </div>
            </li>
          )
        )}
      </div>
    </div>
  );
}

export default MedicationsDetails;
