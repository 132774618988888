import React, { createContext } from "react";
import { Error } from "../common/Error";
import { useError } from "../hooks/useError";

const ErrorContext = createContext({
  setError: () => {},
  message: "",
  resetError: () => {},
});

function ErrorProvider({ children }) {
  const { setError, message, resetError } = useError();
  const context = { setError, message, resetError };

  return (
    <ErrorContext.Provider value={context}>
      <Error />
      {children}
    </ErrorContext.Provider>
  );
}

export { ErrorContext, ErrorProvider };
