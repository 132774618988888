import React, { useEffect, useContext, useState, useCallback } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import { Formik, Form, Field } from "formik";
import { useCPPlanAPI } from "../../hooks/useCPPlanAPI";
import * as CPPlanNotificationAPI from "../../services/ManageCommunicationAPI";
import { manageCommunicationValues } from "../../utils/constants";
import { SUCCESS_RESPONSE_CODE } from "../../define.constants";
import { SpinnerContext } from "../../contexts/spinner.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import { ModalContext } from "../../contexts/modal.context";
import { ErrorContext } from "../../contexts/error.context";
import { Radio } from "@material-ui/core";

function SubscriptionNotification(props) {
  let { prefPhone, homePhone, cellPhone, profileList, isTermedMember } =
    useContext(AppContext);
  const { setActiveTab, tabOptions } = props;
  const fields = props?.fields;
  const { cPPlanList, isResultsloaded, refreshAPIDataAll } = useCPPlanAPI();
  const { setSpinner } = useContext(SpinnerContext);
  const [isEditable, setIsEditable] = useState(false);
  const { setError } = useContext(ErrorContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const [saveAction, setSaveAction] = useState(false);
  const [checkedvalue, setCheckedvalue] = useState(false);
  const [selectedDate, setselecteddate] = useState(null);
  const [isselected, setIsselected] = useState(null);
  const [erroreditable, seterroreditable] = useState(true);
  const [subscriptionData, SetSubscriptionData] = useState({});

  let engagementChannelList = [];
  let programList = [];
  let communicationPreferencesList = [];
  let channelList = [];
  let dateval = null;
  let descdate = null;
  let isedit = true;
  let isselect = false;
  let fixedvalue = "Opt-out";
  let errdisable = true;

  const onOptionChange = (e) => {
    channelList.UserOptedFlag = e.target.value;
    channelList.ProgramDefault = e.target.value;
  };

  const Cancel = () => {
    if (channelList.UserOptedFlag != fixedvalue) {
      channelList.UserOptedFlag = fixedvalue;
    }
  };

  const addDays = (seldate) => {
    let selcurrent = new Date();
    let selstartdate = new Date(seldate); //new Date("08/29/2023");
    let selenddate = new Date(selstartdate);
    selenddate.setDate(selstartdate.getDate() + 5);
    if (selcurrent <= selenddate && selcurrent >= selstartdate) {
      return false;
    }
    return true;
  };
  //For dummy checkin
  //useEffect(() => {
  if (cPPlanList?.IsPlanNotification?.communicationPreferencesList === null) {
    errdisable = false;
    // seterroreditable(false);
  } else {
    errdisable = true;
  }
  //}, []);

  cPPlanList?.IsPlanNotification?.communicationPreferencesList?.map(
    (data, index) => {
      programList = [];

      data.programList.map((pglist, key = index1) => {
        engagementChannelList = [];
        pglist.engagementChannelList?.map((channels, index2) => {
          const CheckCondition = () => {
            if (channelList.length == 0) {
              if (channels.ChannelType == "Online Delivery") {
                channelList = channels;
                if (
                  channels.Description != "" &&
                  channels.Description != null
                ) {
                  if (channels.Description.split(" ")[2]) {
                    var seldate = new Date(channels.Description.split(" ")[2]);
                    dateval = `${
                      seldate.getMonth() + 1
                    }/${seldate.getDate()}/${seldate.getFullYear()}`;

                    //seldate = new Date("08/09/23");
                    descdate = seldate;
                  }
                  if (dateval != "" && dateval != null) {
                    isselect = true;
                  }
                  return true;
                } else {
                  var seldate = new Date();
                  dateval = `${
                    seldate.getMonth() + 1
                  }/${seldate.getDate()}/${seldate.getFullYear()}`;
                  return false;
                }
              } else return false;
            } else return false;
          };

          let checkboxValue = CheckCondition();
          engagementChannelList.push({
            checkboxField: checkboxValue,
            checkboxExistingValue: checkboxValue,
            UserOptedFlag: channels.UserOptedFlag,
            ProgramDefault: channels.ProgramDefault,
            IsLocked: channels.IsLocked,
            ExistingConsentId: channels.ExistingConsentId,
            Description: channels.Description,
            ChannelTypeId: channels.ChannelTypeId,
            ChannelType: channels.ChannelType,
          });
        });

        programList.push({
          ProgramName: pglist.ProgramName,
          engagementChannelList: engagementChannelList,
        });
      });
      communicationPreferencesList.push({
        programList: programList,
        chk_opt_out_all: false,
        CommunicationSubscriptionName: data.CommunicationSubscriptionName,
        CommunicationSubscriptionHelpText:
          data.CommunicationSubscriptionHelpText,
        CommSubscriptionDisplayOrder: data.CommSubscriptionDisplayOrder,
      });

      if (descdate != "" && descdate != null) {
        isedit = addDays(descdate);
      }
      fixedvalue = channelList.UserOptedFlag;
      // const current = new Date();
      // current.setDate(descdate.getDate() + 5);
      //current);
      // const d1 = new Date();
      // if (d1 <= current) {
      //   setIsEditable(false);
      // } else {
      //   setIsEditable(true);
      // }
    }
  );
  //setselecteddate(dateval);
  let initialValues = {
    communicationPreferencesList: communicationPreferencesList,
  };

  // function checkvalue(objlist) {
  //   if ((objlist.length = 0)) {
  //     openModal({
  //       content: successManageComm,
  //       version: 1,
  //     });
  //   } else {
  //     return objlist;
  //   }
  // }
  const makeDisabled =
    communicationPreferencesList && communicationPreferencesList.length > 0
      ? false
      : true;
  const EnableControls = (setValues, values) => {
    let seldate = new Date();
    // if (channels.Description != "" && channels.Description != null) {
    seldate = new Date(channelList?.Description?.split(" ")[2]);
    // }
    if (isEditable) {
      setIsEditable(false);
      setValues({ ...values, communicationPreferencesList });
    } else {
      setIsEditable(true);
    }
  };

  const successManageComm = (
    <div className="managecomm-modal">
      <div className="update-header">
        <p className="comm-para">
          <Text field={fields.lblUpdated} /> {getstringDate()}
          <Text field={fields.lblUpdatedMSg} />
        </p>
      </div>
      <div className="managecomm-btns">
        <button
          type="button"
          className="btn btn-primary"
          data-mdb-dismiss="modal"
          onClick={closeModal}
        >
          <Text field={fields.lblClose} />
        </button>
      </div>
    </div>
  );
  const errorModal = (
    <div className="managecomm-modal">
      <div className="update-header">
        <p className="comm-para">
          <Text field={fields.lblerrormsg} />
        </p>
      </div>
      <div className="managecomm-btns">
        <button
          type="button"
          className="btn btn-primary"
          data-mdb-dismiss="modal"
          onClick={closeModal}
        >
          <Text field={fields.lblClose} />
        </button>
      </div>
    </div>
  );

  const successManageCommModal = () => {
    openModal({
      content: successManageComm,
      version: 1,
    });
  };
  const showErrorModal = () => {
    openModal({
      content: errorModal,
      version: 1,
    });
  };

  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const saveModal = () => {
    openModal({
      content: agreeContent(),
      version: 1,
    });
  };

  const agreeContent = () => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>
            <Text field={fields.lblconformmsg} />
          </p>
        </div>
        <div className="account-btns">
          <button
            type="button"
            className="btn btn-default"
            data-mdb-dismiss="modal"
            onClick={exitdialog}
          >
            <Text field={props?.fields?.lblDisagree ?? ""} />
          </button>

          <>
            <button
              type="button"
              className={`btn btn-primary`}
              onClick={saleforceApicall}
            >
              <Text field={props?.fields?.lblAgree ?? ""} />
            </button>
          </>
        </div>
      </div>
    </>
  );

  const exitdialog = () => {
    closeModal();
    refreshAPIDataAll();
    Cancel();
    //setSpinner(false);
  };
  function getcurrDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}-${date}-${year}`;
  }
  function getstringDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year}`;
  }

  const saleforceApicall = () => {
    let isDataChanged = false;

    communicationPreferencesList?.forEach((data) => {
      programList = [];
      if (data.CommunicationSubscriptionName == "Letters") {
        data.programList.forEach((pglist) => {
          engagementChannelList = [];
          pglist.engagementChannelList?.forEach((channels) => {
            var optflag = "Opt-in";
            var setoptflag = "Opt-out";
            if (channelList.UserOptedFlag == "Opt-in") {
              setoptflag = "Opt-in";
              optflag = "Opt-out";
            }

            if (channels.ChannelType == "Online Delivery") {
              isDataChanged = true;

              var seldate = new Date();
              seldate = new Date(getcurrDate());
              dateval = `${
                seldate.getMonth() + 1
              }/${seldate.getDate()}/${seldate.getFullYear()}`;
              var isTrueSet = false;
              if (channelList.IsLocked === "true") isTrueSet = true;

              engagementChannelList.push({
                UserOptedFlag: setoptflag,
                ChannelTypeId: channels.ChannelTypeId,
                ChannelType: channels.ChannelType,
                Description: channels.Description,
                ExistingConsentId: channels.ExistingConsentId,
                IsLocked: isTrueSet,
                ProgramDefault: channelList.ProgramDefault,
              });
            }
            if (channels.ChannelType == "Mail") {
              engagementChannelList.push({
                UserOptedFlag: optflag,
                ChannelTypeId: channels.ChannelTypeId,
                ChannelType: channels.ChannelType,
                Description: channels.Description,
                ExistingConsentId: channels.ExistingConsentId,
                IsLocked: channels.IsLocked == "true" ? true : false,
                ProgramDefault: channels.ProgramDefault,
              });
            }
          });
          console.log("Listofeng", engagementChannelList);
          programList.push({
            ProgramName: pglist.ProgramName,
            engagementChannelList: engagementChannelList,
          });
        });

        communicationPreferencesList = [];
        communicationPreferencesList.push({
          CommunicationSubscriptionName: data.CommunicationSubscriptionName,
          CommunicationSubscriptionHelpText:
            data.CommunicationSubscriptionHelpText,
          CommSubscriptionDisplayOrder: data.CommSubscriptionDisplayOrder,
          programList: programList,
        });
      }
    });
    let requestData = {
      QNXTMemberId: cPPlanList?.IsPlanNotification?.QNXTMemberId,
      Subscription: communicationPreferencesList,
    };
    if (isDataChanged) {
      setSpinner(true);
      CPPlanNotificationAPI.UpdateCPPlanNotification(requestData)
        .then((response) => {
          // if (response?.ResultCode == "2001") {
          if (response?.ResultData?.StatusCode === SUCCESS_RESPONSE_CODE) {
            closeModal();
            successManageCommModal();
          } else {
            closeModal();
            showErrorModal();
          }
          refreshAPIDataAll();
          setSpinner(false);
        })
        .catch((err) => {
          setSpinner(false);
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    } else {
    }
  };

  useEffect(() => {
    setIsEditable(isedit);
    setIsselected(isselect);
    seterroreditable(errdisable);
    if (erroreditable === false) {
      setError("err");
    }
  }, [isedit, isselect]);

  function errormsg() {
    openModal({
      content: ErrorManageComm(),
      version: 1,
    });
  }

  const ErrorManageComm = () => {
    <div className="managecomm-modal">
      <div className="update-header">
        <p className="comm-para">
          <Text field={fields.lblUpdated} />
          <Text field={fields.lblUpdatedMSg} />
        </p>
      </div>
      <div className="managecomm-btns">
        <button
          type="button"
          className="btn btn-primary"
          data-mdb-dismiss="modal"
          onClick={closeModal}
        >
          <Text field={fields.lblClose} />
        </button>
      </div>
    </div>;
  };

  // const enableEditButton = useCallback(() => {
  //   if (descdate != "" && descdate != null) {
  //     addDays(descdate);
  //   } else {
  //     setIsEditable(true);
  //   }
  // }, [descdate]);
  const onFormikSubmit = () => {
    saveModal();
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onFormikSubmit}>
        {({ values, setValues }) => {
          return (
            <Form>
              <div className="list-group-item accNotification">
                <h5 className="acc-title">
                  <Text field={props?.fields?.plannotification} />
                </h5>
                <hr className="hrline" />
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-md-6 col-sm-12">
                    <div className="sub-title">
                      {communicationPreferencesList &&
                      communicationPreferencesList.length > 0
                        ? communicationPreferencesList[0]
                            .CommunicationSubscriptionName
                        : props?.fields?.planheader?.value}
                    </div>
                    <div className="para-details">
                      {communicationPreferencesList &&
                      communicationPreferencesList.length > 0
                        ? communicationPreferencesList[0]
                            .CommunicationSubscriptionHelpText
                        : props?.fields?.plancontent?.value}
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-md-6 col-sm-12 clearfix">
                    <div className="form-check form-check-inline float-end myprofile-radio">
                      <Field
                        type="radio"
                        name="picked"
                        value="Opt-out"
                        id="englishChecked"
                        className="form-check-input"
                        checked={channelList.UserOptedFlag !== "Opt-in"}
                        onClick={onOptionChange}
                        disabled={!saveAction}
                      />
                      <label className="label-radio" htmlFor="Opt-out">
                        <Text field={props?.fields?.mail ?? ""} />
                      </label>
                    </div>
                    <div className="form-check form-check-inline float-end myprofile-radio">
                      <Field
                        type="radio"
                        name="picked"
                        value="Opt-in"
                        id="englishChecked"
                        className="form-check-input"
                        checked={channelList.UserOptedFlag === "Opt-in"}
                        onClick={onOptionChange}
                        disabled={!saveAction}
                      />
                      <label className="label-radio" htmlFor="Opt-in">
                        <Text field={props?.fields?.online ?? ""} />
                      </label>
                    </div>

                    <div className="clearfix"></div>
                    {isselected == true && (
                      <div
                        className="mt-2 txtpt12"
                        style={{
                          textAlign: "right",
                          marginBottom: "1.5%",
                          marginRight: "1rem",
                        }}
                      >
                        <Text field={fields?.lblSelect} /> {dateval}
                      </div>
                    )}
                  </div>
                </div>
                {erroreditable ? (
                  <div className="d-flex justify-content-end">
                    {!isEditable ? (
                      <div className="p-2 mt-2">
                        <div
                          className="txtpt12"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <Text
                            field={fields?.lblNoteMSg}
                            // style={{ fontSize: "12pt !important" }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="p-2">
                      <div className="preferences-btn">
                        {saveAction ? (
                          <div>
                            <button
                              type="button"
                              className="btn cancel-button cancel-button-mysubs mr15"
                              onClick={(e) => {
                                exitdialog();
                                setSaveAction(false);
                                EnableControls(setValues, values);
                              }}
                            >
                              <Text field={fields?.lblCancel} />
                            </button>
                            <button
                              type="submit"
                              className={`btn btn-primary
                                  `}
                              // onClick={(e) => postApiData(value)}
                              onClick={(e) => {
                                saveModal();
                                setSaveAction(false);
                                EnableControls(setValues, values);
                              }}
                            >
                              <Text field={fields?.btnsaveButton ?? ""} />
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            className={
                              isEditable === false
                                ? "btn btn-save-new"
                                : "btn btn-primary"
                            }
                            disabled={!isEditable || makeDisabled}
                            onClick={() => {
                              setSaveAction(true);
                              EnableControls(setValues, values);
                            }}
                          >
                            <Text field={fields?.btneditButton ?? ""} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="col-sm-12"
                    style={{ color: "Red", textAlign: "right" }}
                  >
                    {/* <Text field={fields?.lblerrormsg} /> */}
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
export default SubscriptionNotification;
