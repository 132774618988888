import React from "react";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";

function BusinessHoursModal(props) {
  const { fields } = props;
  const CLOSED = "CLOSED";
  return (
    <div className="doctor-home">
      <div className="businesspopupfont">
        <Text field={fields.title} />
      </div>
      <BusinessHours
        label={fields.monday}
        value={
          props.list !== "" &&
          (props.list?.monFrom?.trim() === CLOSED ? (
            getFieldValue(fields, "closed")
          ) : (
            <div>
              {`${props.list?.monFrom} - 
              ${props.list?.monTo}`}
            </div>
          ))
        }
      />
      <BusinessHours
        label={fields.tuesday}
        value={
          props.list !== "" &&
          (props.list?.tueFrom?.trim() === CLOSED ? (
            getFieldValue(fields, "closed")
          ) : (
            <div>
              {`${props.list?.tueFrom} - 
              ${props.list?.tueTo}`}
            </div>
          ))
        }
      />
      <BusinessHours
        label={fields.wednesday}
        value={
          props.list !== "" &&
          (props.list?.wedFrom?.trim() === CLOSED ? (
            getFieldValue(fields, "closed")
          ) : (
            <div>
              {`${props.list?.wedFrom} - 
              ${props.list?.wedTo}`}
            </div>
          ))
        }
      />
      <BusinessHours
        label={fields.thursday}
        value={
          props.list !== "" &&
          (props.list?.thuFrom?.trim() === CLOSED ? (
            getFieldValue(fields, "closed")
          ) : (
            <div>
              {`${props.list?.thuFrom} - 
              ${props.list?.thuTo}`}
            </div>
          ))
        }
      />
      <BusinessHours
        label={fields.friday}
        value={
          props.list !== "" &&
          (props.list?.friFrom?.trim() === CLOSED ? (
            getFieldValue(fields, "closed")
          ) : (
            <div>
              {`${props.list?.friFrom} - 
              ${props.list?.friTo}`}
            </div>
          ))
        }
      />
      <BusinessHours
        label={fields.saturday}
        value={
          props.list !== "" &&
          (props.list?.satFrom?.trim() === CLOSED ? (
            getFieldValue(fields, "closed")
          ) : (
            <div>
              {`${props.list?.satFrom} - 
              ${props.list?.satTo}`}
            </div>
          ))
        }
      />
      <BusinessHours
        label={fields.sunday}
        value={
          props.list !== "" &&
          (props.list?.sunFrom?.trim() === CLOSED ? (
            getFieldValue(fields, "closed")
          ) : (
            <div>
              {`${props.list?.sunFrom} - 
              ${props.list?.sunTo}`}
            </div>
          ))
        }
        hr={true}
      />
    </div>
  );
}

const BusinessHours = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-xl-2 col-md-4 col-sm-4">
          <div className="businesshoursweekfont">
            <Text field={props.label} />
          </div>
        </div>
        <div className="col-xl-2 col-md-2 col-sm-1"></div>
        <div className="col-xl-8 col-md-6 col-sm-7">
          <div className="businesshoursfont">{props.value}</div>
        </div>
      </div>
      {!props.hr && <hr />}
    </>
  );
};

export default BusinessHoursModal;
