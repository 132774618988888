import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH } from "../../define.constants";

const ProgressBar = (props) => {
  const { title, description, total, actual, id = "" } = props;
  let accChartValue = null;
  if (actual > total) accChartValue = total;
  else accChartValue = actual;
  const progress =
    accChartValue && total && accChartValue != 0 && total != 0
      ? (accChartValue / total) * 100
      : 0;
  const [progressStyleObj, setProgressStyleObj] = useState({
    //left: `${progress < 5 ? 0 : progress <= 95 ? progress - 5 : "auto"}%`,
    right: `${100 - progress}%`,
  });
  let textAlign = ""; //progress < 5 ? "txt-left" : progress > 95 ? "txt-right" : "";
  // const progress = (actual / total) * 100;

  useEffect(() => {
    if (id !== "") {
      setTimeout(() => {
        if (
          document.querySelector("#" + id + " .molina-progress-bar-score")
            .offsetLeft == 0
        ) {
          setProgressStyleObj({ left: `0px` });
        } else {
          setProgressStyleObj({ right: `${100 - progress}%` });
        }
      }, 200);
    }
  }, [progress, total, actual]);

  return (
    <div {...(id === "" ? {} : { id })} className="molina-progress-bar">
      <div className="molina-progress-bar-title">
        <div className="font-weight-500 custom-tooltip-spending">
          <span>
            <Text field={title} />
          </span>{" "}
          <p className="custom-tooltip-spending-text">{description}</p>
          <img
            className="img-molina-info-teal"
            src={`${ICON_PATH}/info-24px.svg`}
            alt={title?.value}
          />
          {/* </a> */}
          <span className="molina-progress-bar-total-score">{`$${total}`}</span>
        </div>
      </div>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      {/* <!-- For below, left margin should be -5% of above progress bar percentage --> */}
      <div
        className={"font-weight-bold molina-progress-bar-score " + textAlign}
        style={progressStyleObj}
      >
        {`$${actual}`}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  title: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  actual: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

export default ProgressBar;
