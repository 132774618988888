import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardSpecialPlanFL = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardNY"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/FL/Medicaid/FL_Front_SP_Member_Id_Card.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblmemberno} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblEffectiveDate} />
                  </b>
                  &nbsp; {idCard?.MemberEffectiveDate}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields?.lblBin} />
                  </b>
                  &nbsp; {result?.RxBin}
                </td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblSpecialtyPlan} />
                  </b>
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblPCN} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxPCN}
                  {/* &nbsp; {idCard?.MemberEffectiveDate} */}
                </td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblGroup} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxGRP}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPcp} />
                  </b>
                  &nbsp; {details?.PCPName_Current}
                </td>
              </tr>
              <tr>
                <td className="member-services-id">
                  <b>
                    <Text field={fields.lblPcpTel} />
                  </b>
                  &nbsp; {details?.PCPPhone_Current}
                </td>
              </tr>
              <tr>
                <td className="member-services-id">
                  <b>
                    <Text field={fields.lblPCPAdress} />
                  </b>
                  &nbsp; {idCard?.PCPAddress_Current}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblIDBackServices} />
                  &nbsp; <Text field={fields.lblOneDash} />
                  {details?.Medicaid_MemServices}{" "}
                  <Text field={fields.lblttyNum} />
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblMessageServiceHours} />
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td className="text-center">
                  <Text field={fields.lblIDBackNAL} />
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblContactEnglishSpanish} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicaid/WI_back_view.png)`,
          }}
          className="member-id-card-back"
          id="FLback"
        >
          <div className="member-id-card-back-text backcard">
            <div className="backcardmember">
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblEmergencyServices} />{" "}
                </u>
                <Text field={fields.lblNearestEmergencyNote} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblPractitionersProviders} />
                </u>
                <Text field={fields.lblPriorAuthorizationStabilization} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblProviderPharmacists} />
                </u>
                &nbsp;
                <Text field={fields.lblProviderPharmacistsDetails} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblLabTestingServices} />
                </u>
                &nbsp;
                <Text field={fields.lblQuestDiagnostics} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblClaims} />
                </u>
                &nbsp;
                <Text field={fields.lblClaimsBillingInformation} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblSCBackEDIClaims} />
                </u>
                &nbsp;
                <Text field={fields.lblEmdeonPayer} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblTransportation} />
                </u>
                &nbsp;
                <Text field={fields.lblToScheduleTrip} />
              </div>
              <div className="FLbackmb">
                <u>
                  <Text field={fields.lblIDBackComplaintContact} />
                </u>
                &nbsp;
                <Text field={fields.lblIDBackComplaintContactData} />
              </div>
            </div>
            <div className="text-center">
              <Text field={fields.lblFlAddress} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardSpecialPlanFL;
