import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Field, FieldArray } from "formik";
import { ICON_PATH } from "../../define.constants";
const SubscriptionComponent = ({
  fields,
  values,
  setFieldValue,
  setValues,
  isEditable,
}) => {
  const selectAllCheckbox = (ind, val) => {
    const communicationPreferencesList = values?.communicationPreferencesList;

    communicationPreferencesList[ind]?.programList.map((pglist, index1) => {
      pglist.engagementChannelList?.map((eng, index2) => {
        if (eng.IsLocked !== "true") eng.checkboxField = val;
      });
    });

    setValues({ ...values, communicationPreferencesList });
  };

  return (
    <>
      <div>
        {values?.communicationPreferencesList?.map((data, index) => {
          return (
            <li key={index} className="list-group-item">
              <div className="row">
                <div className="col-lg-10 custom-tooltip-spending">
                  <span className="tab-sub-title">
                    {data?.CommunicationSubscriptionName}
                  </span>
                  <img
                    src={`${ICON_PATH}/info-24px.svg`}
                    alt={data?.CommunicationSubscriptionName}
                    className="tooltip-img"
                  />
                  <p className="custom-tooltip-spending-text">
                    {data?.CommunicationSubscriptionHelpText}
                  </p>
                  <span className="manage-content">
                    <Text field={fields.lblData} />
                  </span>
                </div>
                <div className="col-lg-2 check-first">
                  <div className="form-check">
                    <Field
                      id={`communicationPreferencesList.${index}.chk_opt_out_all`}
                      name={`communicationPreferencesList.${index}.chk_opt_out_all`}
                      type="checkbox"
                      disabled={isEditable}
                      className={"form-check-input"}
                      onChange={(e) => {
                        const { checked } = e.target;
                        selectAllCheckbox(index, checked);
                        if (checked) {
                          setFieldValue(
                            `communicationPreferencesList.${index}.chk_opt_out_all`,
                            true
                          );
                        } else {
                          setFieldValue(
                            `communicationPreferencesList.${index}.chk_opt_out_all`,
                            false
                          );
                        }
                      }}
                    />
                    <label
                      htmlFor={`communicationPreferencesList.${index}.chk_opt_out_all`}
                      className="label-radio"
                    >
                      <Text field={fields.lblOptAll} />
                    </label>
                  </div>
                </div>
              </div>
              <hr className="hr-gap" />
              <div>
                {data?.programList?.map((pglist, index1) => (
                  <>
                    <div className="row" key={index1}>
                      <div className="col-lg-6 col-md-8">
                        <h5 className="tab-sub-title grid-space">
                          {pglist?.ProgramName}
                        </h5>
                      </div>
                    </div>
                    <div className="grid-2col-container">
                      {pglist.engagementChannelList?.length > 0 &&
                        pglist?.engagementChannelList?.map(
                          (channels, index2) => (
                            <div key={index2} className="grid-item">
                              <FieldArray name="engagementChannelList">
                                {(fieldArrayProps) => (
                                  <>
                                    <div className="form-check " role="group">
                                      <Field
                                        id={`communicationPreferencesList.${index}.programList.${index1}.engagementChannelList.${index2}.checkboxField`}
                                        name={`communicationPreferencesList.${index}.programList.${index1}.engagementChannelList.${index2}.checkboxField`}
                                        type="checkbox"
                                        disabled={
                                          channels.IsLocked === "true"
                                            ? true
                                            : isEditable
                                        }
                                        className={"form-check-input"}
                                        onChange={(e) => {
                                          const { checked } = e.target;
                                          if (checked) {
                                            setFieldValue(
                                              `communicationPreferencesList.${index}.programList.${index1}.engagementChannelList.${index2}.checkboxField`,
                                              true
                                            );
                                          } else {
                                            setFieldValue(
                                              `communicationPreferencesList.${index}.programList.${index1}.engagementChannelList.${index2}.checkboxField`,
                                              false
                                            );
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor={`communicationPreferencesList.${index}.programList.${index1}.engagementChannelList.${index2}.checkboxField`}
                                        className="label-radio"
                                      >
                                        {channels.ChannelType}
                                      </label>
                                    </div>
                                    <div className="check-content">
                                      <p>{channels.Description}</p>
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </div>
                          )
                        )}
                    </div>
                  </>
                ))}
              </div>
            </li>
          );
        })}
      </div>
    </>
  );
};

export default SubscriptionComponent;
