import React, { useContext } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import NonMarketplaceLanguageInfoLink from "./NonMarketplaceLanguageInfoLink";
import LanguageInfoLink from "./LanguageInfoLink";
import {
  MEDICARE,
  MEDICAID,
  DUALS,
  NY,
  CA,
  ID,
  MA,
} from "../../define.constants";
const NonMarketplaceLanguage = ({ distItem, fields, languageInfoList }) => {
  const { MemberStateName, memberLOB, planId, programId, isNYAffinityMember } =
    useContext(AppContext);
  const keys = distItem !== null ? Object.keys(distItem?.phrases) : null;
  let selectedYear = new Date().getFullYear();
  let param = "";
  if (memberLOB.toLocaleLowerCase() == MEDICARE.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      getFieldValue(languageInfoList?.fields, "docName") +
      `~` +
      programId +
      `~` +
      planId +
      `~` +
      selectedYear;
  } else if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      getFieldValue(languageInfoList?.fields, "docName") +
      `~` +
      programId +
      `~` +
      selectedYear;
  } else if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == NY) {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        getFieldValue(languageInfoList?.fields, "docNameMLT") +
        `~` +
        programId +
        `~` +
        isNYAffinityMember +
        `~` +
        selectedYear;
    } else {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        getFieldValue(languageInfoList?.fields, "docName") +
        `~` +
        programId +
        `~` +
        selectedYear;
    }
  }
  let planurl = "#";

  if (keys !== null && keys !== undefined) {
    keys.forEach((item) => {
      if (item == param) {
        planurl = distItem?.phrases[item];

        return planurl;
      }
    });
  }

  //Lang Information - Multi-Language Interpreter Service
  const lob = memberLOB.toLowerCase();
  switch (lob) {
    case MEDICARE:
      switch (MemberStateName) {
        case NY:
          return (
            <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <NonMarketplaceLanguageInfoLink
                text={
                  getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
                }
                planurl={planurl}
                link={languageInfoList?.fields?.multiLangLink}
                image={languageInfoList?.fields?.multiLangImage}
              />
            </p>
          );
        case MA:
          return (
            <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <NonMarketplaceLanguageInfoLink
                text={
                  getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
                }
                planurl={planurl}
                link={languageInfoList?.fields?.multiLangLink}
                image={languageInfoList?.fields?.multiLangImage}
              />
            </p>
          );
        case ID:
          return (
            <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <NonMarketplaceLanguageInfoLink
                text={
                  getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
                }
                planurl={planurl}
                link={languageInfoList?.fields?.multiLangLink}
                image={languageInfoList?.fields?.multiLangImage}
              />
            </p>
          );
        case CA:
          return (
            <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <NonMarketplaceLanguageInfoLink
                text={
                  getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
                }
                planurl={planurl}
                link={languageInfoList?.fields?.multiLangLink}
                image={languageInfoList?.fields?.multiLangImage}
              />
            </p>
          );
        default:
          return (
            <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <LanguageInfoLink
                text={
                  getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
                }
                link={languageInfoList?.fields?.multiLangLink}
                image={languageInfoList?.fields?.multiLangImage}
              />
            </p>
          );
      }
    case DUALS:
      switch (MemberStateName) {
        case ID:
          return (
            <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <NonMarketplaceLanguageInfoLink
                text={
                  getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
                }
                planurl={planurl}
                link={languageInfoList?.fields?.multiLangLink}
                image={languageInfoList?.fields?.multiLangImage}
              />
            </p>
          );
        default:
          return (
            <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <LanguageInfoLink
                text={
                  getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
                }
                link={languageInfoList?.fields?.multiLangLink}
                image={languageInfoList?.fields?.multiLangImage}
              />
            </p>
          );
      }
    case MEDICAID:
      switch (MemberStateName) {
        case NY:
          return (
            <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <NonMarketplaceLanguageInfoLink
                text={
                  getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
                }
                planurl={planurl}
                link={languageInfoList?.fields?.multiLangLink}
                image={languageInfoList?.fields?.multiLangImage}
              />
            </p>
          );
        default:
          return (
            <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <LanguageInfoLink
                text={
                  getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
                }
                link={languageInfoList?.fields?.multiLangLink}
                image={languageInfoList?.fields?.multiLangImage}
              />
            </p>
          );
      }
    default:
      return (
        <p className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
          <LanguageInfoLink
            text={
              getFieldValue(languageInfoList?.fields, "multiLangLink")?.text
            }
            link={languageInfoList?.fields?.multiLangLink}
            image={languageInfoList?.fields?.multiLangImage}
          />
        </p>
      );
  }
};

export default NonMarketplaceLanguage;
/*NonMPChecking*/
