import React, { useContext } from "react";
import {
  Text,
  Link,
  Image,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import {
  MARKETPLACE,
  MEDICAID,
  SWH_PROGRAMS,
  LA_PLAN_ID,
  TTY,
} from "./../../define.constants";
import { AppContext } from "./../../contexts/app.context";
import {
  getStateConfiguration,
  getStateLobConfigurationItems,
} from "./../../utils/helpers";

const Footer = ({ fields }) => {
  const { memberLOB, MemberStateName, programId, isNYAffinityMember, planId } =
    useContext(AppContext);

  let { isTermedMember } = useContext(AppContext);
  const year = new Date().getFullYear();

  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();
  const isSWHMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID && SWH_PROGRAMS.includes(programId);

  let list = null;
  if (!isMarketplaceMember) {
    list = getStateLobConfigurationItems(
      fields,
      MemberStateName,
      memberLOB,
      programId
    );
  } else {
    list = getStateConfiguration(fields, MemberStateName, memberLOB);
  }

  const FooterContent = ({ listItem, index, headingClass }) => {
    const { fields } = listItem;
    return (
      <>
        <div className={headingClass}>
          {fields.image !== null && (
            <Image
              className="img-molina-phone footer-img-size"
              key={index}
              field={fields.image}
            />
          )}
          <div>
            <b>
              {(isNYAffinityMember &&
                getFieldValue(fields, "isMMAffinityCheck")) ||
              isSWHMedicaidMember ? (
                <Text field={fields.heading2} />
              ) : (
                <Text field={fields.heading} />
              )}
            </b>
          </div>
        </div>
        {getFieldValue(fields, "hipaaLink") && (
          <Link
            className="text-decorate text-zoom"
            field={fields.hipaaLink}
            target="_blank"
          />
        )}
        {getFieldValue(fields, "termsLink") && (
          <Link
            className="text-decorate link-width text-zoom"
            field={fields.termsLink}
            target="_blank"
          />
        )}
        <div className="d-md-block d-none">
          {getFieldValue(fields, "copyright") && (
            <div className="font-logo">
              {getFieldValue(fields, "healthNet") &&
                memberLOB?.toLowerCase() === MEDICAID &&
                LA_PLAN_ID.includes(planId) && (
                  <div className="font-paragraph-footer mb-3">
                    <Text field={fields.healthNet} />
                  </div>
                )}
              {`©${year} `}
              <Text field={fields.copyright} />
            </div>
          )}
        </div>
        {getFieldValue(fields, "description1") && (
          <div className="font-phno">
            <Text field={fields.description1} />
          </div>
        )}
        {getFieldValue(fields, "description2") && (
          <div className="font-phno">
            <Text field={fields.description2} />
          </div>
        )}
        {getFieldValue(fields, "description3") && (
          <div
            className={` ${
              getFieldValue(fields, "description3").includes(TTY)
                ? "font-phno"
                : "font-weekday"
            }`}
          >
            <Text field={fields.description3} />
          </div>
        )}
        {getFieldValue(fields, "description5") && (
          <div className="font-phno">
            <Text field={fields.description5} />
          </div>
        )}
        {getFieldValue(fields, "description4") && (
          <div className="font-weekday-sun">
            <RichText field={fields.description4} />
          </div>
        )}
        {getFieldValue(fields, "description") && (
          <div className="font-paragraph">
            <Text field={fields.description} />
            <Link
              className="link-design p-0 text-zoom-lg m-l-1"
              field={fields.link}
            />
          </div>
        )}
      </>
    );
  };
  return (
    <div className="footer-wapper field-container container text-md-left d-lg-block ">
      <div className="flex-parent">
        {list?.map((listItem, index) => {
          let headingClass = "footer-linespace";
          // const headingClass =
          //   index === 0 ? "footer-title" : "footer-linespace";
          if (index === 2 && list?.length > 4) return null;

          return (
            <div key={index} className="flex-child">
              <FooterContent
                listItem={listItem}
                index={index}
                headingClass={headingClass}
              />
              {index === 1 && list?.length > 4 && (
                <FooterContent
                  listItem={list[index + 1]}
                  index={index + 1}
                  headingClass={headingClass + " mt-4"}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="row">
        {list?.map((listItem, index) => (
          <div key={index} className="d-sm-block d-md-none">
            {getFieldValue(listItem.fields, "copyright") && (
              <span className="font-logo">
                {getFieldValue(listItem?.fields, "healthNet") &&
                  memberLOB?.toLowerCase() === MEDICAID &&
                  LA_PLAN_ID.includes(planId) && (
                    <div className="font-paragraph-footer mb-3">
                      <Text field={listItem?.fields.healthNet} />
                    </div>
                  )}
                {`©${year} `}
                <Text field={listItem.fields.copyright} />
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Footer;
