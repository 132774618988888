import React, { useContext, useEffect } from "react";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";

const SapphireRedirect = () => {
  const { redirectToSSO } = useContext(SSORedirectContext);

  useEffect(() => {
    redirectToSSO({
      e: null,
      url: constants.apigeeURLs.SapphireChangePCPSSO,
      data: { LoginSource: "channel=sd" },
    });
  }, []);

  return <></>;
};

export default SapphireRedirect;
