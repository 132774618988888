import React, { useState, useContext } from "react";
import Eligibility from "./Eligibility";
import EligibilityNonMP from "./EligibilityNonMP";
import TrackMySpending from "./TrackMySpending";
import ProcedureCostEstimator from "./ProcedureCostEstimator";
import { AppContext } from "../../contexts/app.context";
import {
  MARKETPLACE,
  AIAN,
  MEDICAID,
  NYMCC_PROGRAMID,
  MA_MD_SCO_PROGRAMID,
  MA_MD_FIDE_PROGRAMID,
  MA_MD_SWH_PROGRAMID,
  NY,
  MA,
  GLOBAL_VARIABLE,
  NE,
  NY_MC_SWH_PROGRAMID,
  MEDICARE,
} from "../../define.constants.js";
import { getStateLobConfigurationItems } from "../../utils/helpers";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

const MyCoverage = ({ fields }) => {
  const { MemberStateName, memberLOB, programId } = useContext(AppContext);
  const [showTrackMySpending, setShowTrackMySpending] = useState(null);
  const [plan, setPlan] = useState(null);
  const list = getStateLobConfigurationItems(
    fields,
    MemberStateName,
    memberLOB,
    ""
  );
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  const onYearSelectHandler = (plan) => {
    setPlan(plan);
    setShowTrackMySpending(
      memberLOB.toLocaleLowerCase() === MARKETPLACE &&
        !plan?.PlanDescription?.includes(AIAN)
    );
  };
  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  const isMedicareMember = memberLOB?.toLowerCase() === MEDICARE;
  const showCostEstimator = () => {
    if (
      (MemberStateName == NY &&
        (programId?.toUpperCase() === NYMCC_PROGRAMID ||
          (isMedicareMember &&
            programId?.toUpperCase() === NY_MC_SWH_PROGRAMID))) ||
      (MemberStateName == MA &&
        (programId?.toUpperCase() === MA_MD_SCO_PROGRAMID ||
          (isMedicareMember &&
            (programId?.toUpperCase() === MA_MD_FIDE_PROGRAMID ||
              programId?.toUpperCase() === MA_MD_SWH_PROGRAMID)))) ||
      (MemberStateName == NE && isMedicaidMember) //NE implementation
    )
      return false;
    else if (isMedicaidMember || isMarketplaceMember) return true;
    else return false;
  };

  return (
    <div className="coverage-page">
      {!isMarketplaceMember && (
        <>
          <EligibilityNonMP fields={fields} />
          {showCostEstimator() && (
            <ProcedureCostEstimator fields={fields} list={list} />
          )}
        </>
      )}

      {isMarketplaceMember && (
        <>
          <Eligibility fields={fields} onYearSelect={onYearSelectHandler} />
          {showTrackMySpending ? (
            <TrackMySpending fields={fields} plan={plan} list={list} />
          ) : (
            <ProcedureCostEstimator fields={fields} list={list} />
          )}
        </>
      )}
    </div>
  );
};

export default MyCoverage;
