import React, { useContext, useEffect, useState } from "react";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../contexts/app.context";
import { ModalContext } from "../../../contexts/modal.context";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as MyCoveragesAPI from "../../../services/MyCoveragesAPI";
import { SpinnerContext } from "../../../contexts/spinner.context";
import SearchResults from "./SearchResults";
import { ErrorContext } from "../../../contexts/error.context";
const qs = require("qs");

const SearchForm = ({ fields }) => {
  const { MemberStateName, memberRKSID, zip } = useContext(AppContext);
  const initialValues = {
    ZipCode: zip,
    ProcedureDescription: "",
    ProcedureCode: "",
  };
  const { setError } = useContext(ErrorContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [requestData, setRequestData] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  var validationSchema = Yup.object(
    {
      ZipCode: Yup.number()
        .required(getFieldValue(fields, "lblRequired", false))
        .test(
          "len",
          getFieldValue(fields, "lblInvalid", false),
          (val) => val?.toString().length === 5 || val?.toString().length === 9
        ),
      ProcedureCode: Yup.string(),
      ProcedureDescription: Yup.string().when("ProcedureCode", {
        is: (ProcedureCode) => !ProcedureCode || ProcedureCode.length === 0,
        then: Yup.string().required(
          getFieldValue(fields, "lblRequired", false)
        ),
        otherwise: Yup.string(),
      }),
    },
    [["ProcedureCode"]]
  );

  useEffect(() => {
    setRequestData({
      ProcedureCode: "",
      ProcedureDescription: "",
      ZipCode: "",
      RKSMemberId: memberRKSID,
      ProgramId: "",
      PlanId: "",
      StateCode: MemberStateName,
    });
  }, [memberRKSID, MemberStateName]);

  useEffect(() => {
    let isMounted = true;
    requestData &&
      (requestData.ZipCode.length === 5 || requestData.ZipCode.length === 9) &&
      (requestData.ProcedureCode || requestData.ProcedureDescription) &&
      MyCoveragesAPI.ProcedureCostEstimate(
        requestData,
        qs.stringify(requestData, { addQueryPrefix: true })
      )
        .then((response) => {
          if (isMounted && response && response.ResultData) {
            let results = Array.isArray(response?.ResultData)
              ? response?.ResultData
              : [response?.ResultData?.ProcedureCostEstimateResponse];
            setSearchResults(results);
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    return () => {
      isMounted = false;
    };
  }, [requestData, setSpinner]);

  useEffect(() => {
    showSearchResults &&
      searchResults &&
      openModal({
        content: (
          <SearchResults
            results={searchResults}
            request={requestData}
            fields={fields}
          />
        ),
        version: 4,
      });
  }, [requestData, fields, searchResults, showSearchResults, openModal]);

  const handleSuggestions = (values) => {
    if (
      (values.ZipCode.length === 5 || values.ZipCode.length === 9) &&
      values.ProcedureDescription.length % 2 === 0
    ) {
      setShowSuggestions(true);
      setRequestData({
        ...requestData,
        ProcedureCode: values.ProcedureCode,
        ProcedureDescription: values.ProcedureDescription,
        ZipCode: values.ZipCode,
      });
    }
  };

  const onSubmit = (values, submitProps) => {
    setSpinner(true);
    setSearchResults(null);
    setRequestData({
      ...requestData,
      ProcedureCode: values.ProcedureCode,
      ProcedureDescription: values.ProcedureDescription,
      ZipCode: values.ZipCode,
    });
    setShowSearchResults(true);
  };

  const selectSuggestedResult = (item, setFieldValue) => {
    setShowSuggestions(false);
    setFieldValue("ProcedureDescription", item);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <div className="mycoverage-modal">
              <div className="update-header">
                <h5>
                  <Text field={fields.lblEstmtrFrmTitle} />
                </h5>
                <div className="form-group">
                  <label htmlFor="ZipCode" className="d-none d-md-block">
                    <Text field={fields.lblEstmtrFrmLocation} />
                  </label>
                  <label className="form-label-ZIP d-md-none d-block">
                    {getFieldValue(fields, "lblEstmtrFrmLocation")}
                  </label>
                  <Field
                    type="number"
                    className="form-control"
                    id="ZipCode"
                    name="ZipCode"
                    autoComplete="off"
                    value={formik.values.ZipCode}
                    onChange={formik.handleChange}
                    placeholder={getFieldValue(
                      fields,
                      "lblEstmtrFrmLocationPlchldr"
                    )}
                  />
                  <div className="text-danger form-error-msg">
                    <ErrorMessage name="ZipCode" />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="ProcedureDescription">
                    <Text field={fields.lblEstmtrFrmPrcdreDescription} />
                    <div className="d-md-none ">
                      {getFieldValue(
                        fields,
                        "lblEstmtrFrmPrcdreDescriptionPlchldr"
                      )}
                    </div>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    id="ProcedureDescription"
                    name="ProcedureDescription"
                    autoComplete="off"
                    value={formik.values.ProcedureDescription}
                    onChange={formik.handleChange}
                    onKeyUp={() => handleSuggestions(formik.values)}
                    placeholder={getFieldValue(
                      fields,
                      "lblEstmtrFrmPrcdreDescriptionPlchldr"
                    )}
                  />
                  {showSuggestions &&
                    searchResults &&
                    formik.values.ProcedureDescription && (
                      <div className="autocomplete-control">
                        <ul id="procSuggestions" className="autocomplete-list">
                          {searchResults?.map(
                            (item, index) =>
                              item?.Description?.toLowerCase().includes(
                                formik.values.ProcedureDescription.toLowerCase()
                              ) && (
                                <li
                                  onClick={() =>
                                    selectSuggestedResult(
                                      item.Description,
                                      formik.setFieldValue
                                    )
                                  }
                                  key={index}
                                >
                                  {item.Description}
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    )}
                  <div className="text-danger form-error-msg">
                    <ErrorMessage name="ProcedureDescription" />
                  </div>
                </div>
                <div className="form-label-txt d-md-none d-block">
                  <Text field={fields.lblOr} />
                </div>
                <div className="form-group">
                  <label htmlFor="ProcedureCode">
                    <Text field={fields.lblEstmtrFrmPrcdreCode} />
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    id="ProcedureCode"
                    name="ProcedureCode"
                    autoComplete="off"
                    value={formik.values.ProcedureCode}
                    onChange={formik.handleChange}
                    placeholder={getFieldValue(
                      fields,
                      "lblEstmtrFrmPrcdreCodePlchldr"
                    )}
                  />
                  <div className="text-danger form-error-msg">
                    <ErrorMessage name="ProcedureCode" />
                  </div>
                </div>
              </div>
              <div className="modal-btns text-right">
                <button
                  type="button"
                  className="btn btn-default ripple-surface"
                  data-mdb-dismiss="modal"
                  onClick={closeModal}
                >
                  <Text field={fields.lblEstmtrFrmCancelBtn} />
                </button>
                <button
                  type="submit"
                  className="btn btn-primary search-button ripple-surface"
                >
                  <Text field={fields.lblEstmtrFrmSearchBtn} />
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SearchForm;
