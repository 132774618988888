import React, { useState, useContext, useCallback } from "react";
import { Formik, Form } from "formik";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import Subscriptioncomponent from "./SubscriptionComponent";
import { useCPPlanAPI } from "../../hooks/useCPPlanAPI";
import * as CPPlanNotificationAPI from "../../services/ManageCommunicationAPI";
import { manageCommunicationValues } from "../../utils/constants";
import { SUCCESS_RESPONSE_CODE } from "../../define.constants";
import { SpinnerContext } from "../../contexts/spinner.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import { ModalContext } from "../../contexts/modal.context";
import { ErrorContext } from "../../contexts/error.context";
import { GLOBAL_VARIABLE } from "../../define.constants";

const AccountSettingsManageCommunications = (props) => {
  const { cPPlanList, isResultsloaded, refreshAPIDataAll } = useCPPlanAPI();
  const { setSpinner } = useContext(SpinnerContext);
  const [isEditable, setIsEditable] = useState(false);
  const { setError } = useContext(ErrorContext);
  const { openModal, closeModal } = useContext(ModalContext);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  let engagementChannelList = [];
  let programList = [];
  let communicationPreferencesList = [];
  let channelList = [];
  const fields = props?.fields;
  cPPlanList?.IsPlanNotification?.communicationPreferencesList?.map(
    (data, index) => {
      programList = [];
      data.programList.map((pglist, key = index1) => {
        engagementChannelList = [];
        pglist.engagementChannelList?.map((channels, index2) => {
          channelList = [];
          const CheckCondition = () => {
            if (
              channels.ProgramDefault == null ||
              channels.ProgramDefault == manageCommunicationValues.none
            ) {
              if (channels.UserOptedFlag == manageCommunicationValues.optIn) {
                return true;
              }
            } else {
              if (
                channels.ProgramDefault == manageCommunicationValues.optIn &&
                channels.UserOptedFlag == null
              ) {
                return true;
              } else if (
                channels.ProgramDefault == manageCommunicationValues.optIn &&
                channels.UserOptedFlag == manageCommunicationValues.optIn
              ) {
                return true;
              } else if (
                channels.ProgramDefault == manageCommunicationValues.optOut &&
                channels.UserOptedFlag == manageCommunicationValues.optIn
              ) {
                return true;
              }
            }
            return false;
          };
          let checkboxValue = CheckCondition();
          engagementChannelList.push({
            checkboxField: checkboxValue,
            checkboxExistingValue: checkboxValue,
            UserOptedFlag: channels.UserOptedFlag,
            ProgramDefault: channels.ProgramDefault,
            IsLocked: channels.IsLocked,
            ExistingConsentId: channels.ExistingConsentId,
            Description: channels.Description,
            ChannelTypeId: channels.ChannelTypeId,
            ChannelType: channels.ChannelType,
          });
        });
        programList.push({
          ProgramName: pglist.ProgramName,
          engagementChannelList: engagementChannelList,
        });
      });
      communicationPreferencesList.push({
        programList: programList,
        chk_opt_out_all: false,
        CommunicationSubscriptionName: data.CommunicationSubscriptionName,
        CommunicationSubscriptionHelpText:
          data.CommunicationSubscriptionHelpText,
        CommSubscriptionDisplayOrder: data.CommSubscriptionDisplayOrder,
      });
    }
  );

  let initialValues = {
    communicationPreferencesList: communicationPreferencesList,
  };
  const EnableControls = (setValues, values) => {
    if (isEditable) {
      setIsEditable(false);
      setValues({ ...values, communicationPreferencesList });
    } else {
      setIsEditable(true);
    }
  };

  const postApiData = useCallback((value) => {
    let isDataChanged = false;
    communicationPreferencesList = [];
    value?.communicationPreferencesList?.map((data, index) => {
      programList = [];
      data.programList.map((pglist, key = index1) => {
        engagementChannelList = [];
        pglist.engagementChannelList?.map((channels, index2) => {
          if (
            channels.IsLocked !== "true" &&
            channels.checkboxExistingValue !== channels.checkboxField
          ) {
            isDataChanged = true;
            engagementChannelList.push({
              UserOptedFlag: channels.checkboxField
                ? manageCommunicationValues.optIn
                : manageCommunicationValues.optOut,
              ExistingConsentId: channels.ExistingConsentId,
              ChannelTypeId: channels.ChannelTypeId,
              ChannelType: channels.ChannelType,
            });
          }
        });
        programList.push({
          ProgramName: pglist.ProgramName,
          engagementChannelList: engagementChannelList,
        });
      });
      communicationPreferencesList.push({
        CommunicationSubscriptionName: data.CommunicationSubscriptionName,
        CommunicationSubscriptionHelpText:
          data.CommunicationSubscriptionHelpText,
        CommSubscriptionDisplayOrder: data.CommSubscriptionDisplayOrder,
        programList: programList,
      });
    });
    let requestData = {
      QNXTMemberId: cPPlanList?.IsPlanNotification?.QNXTMemberId,
      Subscription: communicationPreferencesList,
    };
    if (isDataChanged) {
      setSpinner(true);
      CPPlanNotificationAPI.UpdateCPPlanNotification(requestData)
        .then((response) => {
          if (response?.ResultData?.StatusCode === SUCCESS_RESPONSE_CODE) {
            refreshAPIDataAll();
            successManageCommModal();
          }
          setSpinner(false);
        })
        .catch((err) => {
          setSpinner(false);
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    } else {
    }
  });

  const updateCommunication = (value) => (
    <>
      <div className="managecomm-modal">
        <div className="update-header">
          <p className="comm-para">
            <Text field={fields.lblUpdate} />
          </p>
        </div>
        <div className="managecomm-btns">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            <Text field={fields.lblCancel} />
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => postApiData(value)}
          >
            <Text field={fields.lblSave} />
          </button>
        </div>
      </div>
    </>
  );

  const successManageComm = (
    <div className="managecomm-modal">
      <div className="update-header">
        <p className="comm-para">
          <Text field={fields.lblUpdated} />
        </p>
      </div>
      <div className="managecomm-btns">
        <button type="button" className="btn btn-primary" onClick={closeModal}>
          <Text field={fields.lblClose} />
        </button>
      </div>
    </div>
  );

  const updateCommunicationModal = (value) => {
    openModal({
      content: updateCommunication(value),
      version: 1,
    });
  };

  const successManageCommModal = () => {
    openModal({
      content: successManageComm,
      version: 1,
    });
  };
  const onFormikSubmit = (value) => {
    updateCommunicationModal(value);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormikSubmit}
        enableReinitialize
      >
        {({ errors, values, touched, setFieldValue, setValues }) => (
          <Form>
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="formsDocs"
                      role="tabpanel"
                      aria-labelledby="tab-4"
                    >
                      <div className="row no-tab-title manage-communication">
                        <div className="col-md-12">
                          <ul className="list-group">
                            <li className="list-group-item">
                              <div className="row">
                                <div className="col-xl-4 col-md-12">
                                  <div className="manage-decription">
                                    <Text field={fields.lblContent} />
                                  </div>
                                </div>
                                <div className="col-xl-2 col-md-6 col-sm-12">
                                  <div className="row-title">
                                    <Text field={fields.lblPhone} />
                                  </div>
                                  <div className="row-details">
                                    {
                                      cPPlanList?.IsPlanNotification
                                        ?.PreferredPhone
                                    }
                                  </div>
                                </div>
                                <div className="col-xl-2 col-md-6 col-sm-12">
                                  <div className="row-title">
                                    <Text field={fields.lblText} />
                                  </div>
                                  <div className="row-details">
                                    {
                                      cPPlanList?.IsPlanNotification
                                        ?.PreferredText
                                    }
                                  </div>
                                </div>
                                <div className="col-xl-2 col-md-6 col-sm-12">
                                  <div className="row-title">
                                    <Text field={fields.lblMail} />
                                  </div>
                                  <div className="row-details">
                                    {
                                      cPPlanList?.IsPlanNotification
                                        ?.PreferredEmail
                                    }
                                  </div>
                                </div>
                                <div className="col-xl-2 col-md-6 col-sm-12">
                                  <div className="row-title">
                                    <button
                                      type="button"
                                      className="btn see-def"
                                    >
                                      <Text field={fields.lblDef} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row no-tab-title manage-communication">
                        <div className="col-md-12">
                          {cPPlanList !== null && (
                            <Subscriptioncomponent
                              fields={fields}
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              setValues={setValues}
                              isEditable={!isEditable}
                            />
                          )}
                        </div>
                        <div className="col-sm-12">
                          <div className="preferences-btn">
                            {!isEditable && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-edit"
                                  onClick={(e) => {
                                    EnableControls(setValues, values);
                                  }}
                                >
                                  <Text field={fields.lblEdit} />
                                </button>
                              </>
                            )}
                            {isEditable === true && (
                              <>
                                <button
                                  type="reset"
                                  className="btn btn-cancel"
                                  onClick={(e) => {
                                    EnableControls(setValues, values);
                                  }}
                                >
                                  <Text field={fields.lblCancel} />
                                </button>
                              </>
                            )}
                            <button
                              type="submit"
                              className={
                                isEditable === false
                                  ? "btn btn-save-new"
                                  : "btn btn-primary"
                              }
                              disabled={!isEditable}
                            >
                              <Text field={fields?.lblSave ?? ""} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withModalProvider(AccountSettingsManageCommunications);
