import React, { useCallback, useEffect, useState } from "react";
import { InnerSpinner } from "../common/Spinner";

const useSpinner = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [innerSpinnerHTML, setInnerSpinnerHTML] = useState({});
  let counter = 0;
  const setSpinner = useCallback(
    (loading) => {
      if (loading) {
        counter++;
      } else if (counter > 0) {
        counter--;
      }
      if (loading === false && counter !== 0) {
        return;
      }
      setIsLoading(loading);
    },
    [counter]
  );

  const setInnerSpinner = useCallback((loading, widget) => {
    setInnerSpinnerHTML((prevState) => ({
      ...prevState,
      [widget]: loading ? <InnerSpinner /> : undefined,
    }));
  }, []);

  return { isLoading, setSpinner, innerSpinnerHTML, setInnerSpinner };
};

export { useSpinner };
