import React, { useContext } from "react";
import { Text, Link, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import {
  ICON_PATH,
  DELIMITER_HYPHEN,
  NM,
  NY,
  MEDICAID,
  NY_MD_CHP_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  MARKETPLACE,
} from "../../define.constants";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { ModalContext } from "./../../contexts/modal.context";
import { redirectTo, showMyBillPopup } from "../../utils/helpers";

function SubscriberView(props) {
  const {
    fields,
    isAutoPay,
    MemberStateName,
    modalContent,
    memberLOB,
    programId,
    isNYAffinityMember,
    isTermedMember,
  } = props;
  const { redirectToSSO } = useContext(SSORedirectContext);
  const { openModal } = useContext(ModalContext);
  const managePaymentButton = getFieldValue(fields, "paymentLink", false);
  //const isNYMedicaid = MemberStateName === NY &&
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE?.toLowerCase();
  const isNYChip =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === NY &&
    !isMarketplaceMember &&
    programId === NY_MD_CHP_PROGRAMID;

  const onManagePaymentClick = (e) => {
    console.log("NY MY bill section");
    if (showMyBillPopup({ stateCode: MemberStateName })) {
      e.preventDefault();
      openModal({ content: modalContent });
    } else {
      if (MemberStateName === NM) {
        redirectTo(
          managePaymentButton?.href,
          false,
          managePaymentButton?.target
        );
      } else if (isNYChip === true) {
        redirectToSSO({
          e,
          url: constants.apigeeURLs.InstaMedSSORedirect,
          data: {
            ReqPage: "Home",
            StrFrom: "MAKEPAYMENT",
          },
        });
      } else {
        redirectToSSO({
          e,
          url: constants.apigeeURLs.GetHPSSSORedirect,
          data: {
            ReqPage: "Home",
          },
        });
      }
    }
  };
  const onManagePaymentNYClick = (e) => {
    redirectToSSO({
      e,
      url: constants.apigeeURLs.InstaMedSSORedirect,
      data: {
        ReqPage: "Home",
      },
    });
  };

  return (
    <>
      {/* {isAutoPay && (
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="btn-pay-on">
              <span className="card-bill-label2">
                <Text field={fields.autoPay} />
              </span>
              <span className="card-bill-label3">
                <Link
                  field={fields.manage}
                  className="card-link  card-bill-link mb-0"
                />
                <img
                  className="link-icon"
                  alt={getFieldValue(fields, "rightArrowAlt")}
                  src={`${ICON_PATH}/arrow-right-small.svg`}
                />
              </span>
            </div>
            <div>
              <span className="card-bill-value2">
                <Text field={fields.dateOfWithdrawal} />
                {`${DELIMITER_COLON} `}
              </span>
              <span className="card-bill-value2">mm/dd</span>
            </div>
          </div>
        </div>
      )}
      {isAutoPay && (
        <div className="row ">
          <div className="col-md-12 col-sm-12 paperless-billing">
            <a
              href={getFieldValue(fields, "billingEnabled").href}
              className="card-link card-bill-label2"
            >
              <div className="icon-data">
                <img
                  className="paperless-billing-icon"
                  alt={getFieldValue(fields, "paperLessIconAlt")}
                  src={` ${ICON_PATH}/paperlessbilling.svg`}
                />
                <Text field={fields.billingText} />
              </div>
              <span className="card-bill-label3">
                {getFieldValue(fields, "billingEnabled").text}
                <img
                  className="link-icon"
                  alt={getFieldValue(fields, "rightArrowAlt")}
                  src={`${ICON_PATH}/arrow-right-small.svg`}
                />
              </span>
            </a>
          </div>
        </div>
      )}
      {!isAutoPay && (
        <div className="row mb-5">
          <div className="col-md-12 col-sm-12">
            <button type="button" className="btn btn-secondary btn-autopay">
              {getFieldValue(fields, "buttonText").text}
            </button>
          </div>
          <div className="col-md-12 col-sm-12 pt-4">
            <img
              className="paperless-billing-icon"
              alt={getFieldValue(fields, "paperLessIconAlt")}
              src={`${ICON_PATH}/paperlessbilling.svg`}
            />
            <Link className="card-link card-bill-link" field={fields.billing} />
            <img
              alt={getFieldValue(fields, "rightArrowAlt")}
              src={` ${ICON_PATH}/arrow-right-small.svg`}
            />
          </div>
        </div>
      )} */}
      {isNYChip === true ? (
        <>
          <div className="col-md-12 col-sm-12 card-wrapper">
            <div className="card-bill-label card-bill-txt">
              {/* <span className="custom-tooltip"> */}
              <a
                onClick={onManagePaymentClick}
                className={
                  isTermedMember
                    ? "card-link test disabled-link"
                    : "cursor-pointer card-link test"
                }
              >
                {`${DELIMITER_HYPHEN} `}
                <Text field={fields.registerOrManageAutoPay} />
              </a>
              {/* </span> */}
            </div>
          </div>
          <div className="row card-link-cta-block my-bill-cta-block">
            <div className="col-md-12 col-sm-12 text-center">
              <span className="custom-tooltip">
                <a
                  onClick={onManagePaymentClick}
                  className={
                    isTermedMember
                      ? "card-link test disabled-link"
                      : "cursor-pointer card-link test"
                  }
                >
                  {getFieldValue(fields, "paymentLinkForNY").text}
                  <img
                    className="link-icon2"
                    alt={getFieldValue(fields, "rightArrowAlt")}
                    src={`${ICON_PATH}/arrow-right.svg`}
                  />
                </a>
                {!isTermedMember && (
                  <span className="custom-tooltip-text">
                    <Text field={fields.paymentLinkInfo} />
                  </span>
                )}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-md-12 col-sm-12 card-wrapper">
            <div className="card-bill-label">
              <Text field={fields?.subscriberText} />{" "}
              <b>
                <a onClick={onManagePaymentClick} className="card-link test">
                  {getFieldValue(fields, "paymentLink").text}
                </a>
              </b>{" "}
              <Text field={fields?.subscriberTextAfterLink} />
            </div>
            <div className="card-bill-box">
              <div className="card-bill-label">
                {`${DELIMITER_HYPHEN} `}
                <Text field={fields.viewBillText} />
              </div>
              {MemberStateName !== NM ? (
                <>
                  <div className="card-bill-label card-bill-txt">
                    {`${DELIMITER_HYPHEN} `}
                    <Text field={fields.scheduleText} />
                  </div>
                  <div className="card-bill-label card-bill-txt">
                    {`${DELIMITER_HYPHEN} `}
                    <Text field={fields.manageAutoPayText} />
                  </div>

                  <div className="card-bill-label card-bill-txt">
                    {`${DELIMITER_HYPHEN} `}
                    <Text field={fields.manageWalletText} />
                  </div>
                </>
              ) : (
                <>
                  <div className="card-bill-label card-bill-txt">
                    {`${DELIMITER_HYPHEN} `}
                    <Text field={fields.makePaymentText}></Text>
                  </div>
                  <div className="card-bill-label card-bill-txt">
                    {`${DELIMITER_HYPHEN} `}
                    <Text field={fields.setUpText}></Text>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row card-link-cta-block my-bill-cta-block">
            <div className="col-md-12 col-sm-12 text-center">
              <span className="custom-tooltip">
                <a onClick={onManagePaymentClick} className="card-link test">
                  {getFieldValue(fields, "paymentLink").text}
                  <img
                    className="link-icon2"
                    alt={getFieldValue(fields, "rightArrowAlt")}
                    src={`${ICON_PATH}/arrow-right.svg`}
                  />
                </a>
                <span className="custom-tooltip-text">
                  <Text field={fields.paymentLinkInfo} />
                </span>
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SubscriberView;
