import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayDateItem from "./DisplayDateItem";
import DisplayProvider from "./DisplayProvider";

const DisplaySearch = (fields) => {
  const {
    providers = null,
    handleDropdownChange = null,
    dropdownOptions = null,
    props,
    startDateDisclaimer,
    formikProps,
    showProvider,
  } = fields;
  return (
    <div className="row">
      <div className="col-lg-9 col-md-12 col-xxl-8">
        <div className="row medications myplanresources">
          <DisplayDateItem
            dateClass={"start-date"}
            dateLabel={
              props.fields?.lblStartDate
                ? props.fields.lblStartDate
                : props.fields.lblAdmitDate
            }
            dateName={"startDate"}
            formikProps={formikProps}
            invalidDateMessage={props.fields?.lblInvalidDateError?.value}
            minDateMessage={props.fields?.lblMinDateError?.value}
            maxDateMessage={props.fields?.lblMaxDateError?.value}
          />
          <DisplayDateItem
            dateClass={"end-date"}
            dateLabel={
              props.fields?.lblEndDate
                ? props.fields.lblEndDate
                : props.fields.lblDischargeDate
            }
            dateName={"endDate"}
            formikProps={formikProps}
            invalidDateMessage={props.fields?.lblInvalidDateError?.value}
            minDateMessage={props.fields?.lblMinDateError?.value}
            maxDateMessage={props.fields?.lblMaxDateError?.value}
          />
          {showProvider ? (
            <DisplayProvider
              providers={providers}
              handleDropdownChange={handleDropdownChange}
              dropdownOptions={dropdownOptions}
              props={props}
            />
          ) : null}

          <div className="col-xl-3 col-md-6 col-12 search-btn">
            <button type="submit" className="btn btn-search btn-primary ">
              <Text field={props.fields.btnSearch} />
            </button>
          </div>
        </div>
      </div>
      {startDateDisclaimer && (
        <div className="col-md-12 col-lg-3 col-12 col-xxl-4 desc">
          {startDateDisclaimer}
        </div>
      )}
    </div>
  );
};

export default DisplaySearch;
