import React, { useContext, useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DependentList from "../common/DependentList";
import Confirmation from "./Confirmation";
import { ModalContext } from "../../../contexts/modal.context";

const Dependents = (props) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const [selectedDependents, setSelectedDependents] = useState(null);
  const { fields, dependents } = props;
  return (
    <>
      <div className="request-new-id-body">
        <p className="request-new-id-body-title">
          <Text field={fields.PopupSelectMemberandDependentName} />
        </p>
        <DependentList
          fields={fields}
          dependents={dependents}
          onDependentSelected={(data) => setSelectedDependents(data)}
        />
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            <Text field={fields.btnCancel} />
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={selectedDependents?.length === 0}
            onClick={() =>
              openModal({
                header: (
                  <div className="request-new-id-header">
                    <Text field={fields.PopupRequestNewIDCard} />{" "}
                  </div>
                ),
                content: (
                  <Confirmation
                    {...props}
                    selectedDependents={selectedDependents}
                  />
                ),
                version: 1,
              })
            }
          >
            <Text field={fields.btnNext} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Dependents;
