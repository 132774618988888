import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import { ErrorContext } from "../contexts/error.context";
import * as AllergiesListAPI from "../services/HeathRecordAPI";
import { MY_HEALTH_RECORD_GET_ALLERGIES } from "../define.widgets";

const useAllergiesListAPI = () => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [allergiesList, setAllergiesList] = useState([]);
  const { MemberStateName, memberRKSID } = useContext(AppContext);
  const [isResultsloaded, setisResultsloaded] = useState(false);

  const setData = (data) => {
    setAllergiesList(data.ResultData.MemberAllergies?.AllergiesList);
    setisResultsloaded(true);
  };

  useEffect(() => {
    if (memberRKSID != "") {
      setInnerSpinner(true, MY_HEALTH_RECORD_GET_ALLERGIES);
      AllergiesListAPI.getAllergiesList({
        RKSMemberId: memberRKSID,
        StateCode: MemberStateName,
      })
        .then((response) => {
          setData(response);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MY_HEALTH_RECORD_GET_ALLERGIES);
        });
    }
  }, [memberRKSID, MemberStateName]);

  return { allergiesList, isResultsloaded };
};

export { useAllergiesListAPI };
