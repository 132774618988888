import Axios from "./axios/provider";
// import axios from "axios";
import { SpinnerContext } from "../contexts/spinner.context";

// let instance = axios.create({
//   baseURL: "https://apidev.molinahealthcare.com/",
//   withCredentials: true,
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
//   },
// });

export const PayerRequestToSF = async (data) => {
  try {
    // alert("its coming");
    // alert(JSON.stringify(data));
    // alert(constants.apigeeURLs.PayerRequestToSF);
    const response = await Axios.post(
      constants.apigeeURLs.PayerRequestToSF,
      data
    );
    return response;
  } catch (error) {
    // console.log(error);
    // alert(JSON.stringify(error));
    throw error;
  }
};
