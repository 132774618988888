import { PHONE_DIGIT_ONLY_REGEX } from "../../define.constants";

export const PhoneFormat = (props) => {
  let curated = props.values.phone?.replace(PHONE_DIGIT_ONLY_REGEX, "");
  if (curated.length > 10) {
    curated = curated.slice(0, 10);
  }
  if (curated.length > 6) {
    curated = curated.slice(0, 6) + "-" + curated.slice(6);
  }
  if (curated.length > 3) {
    curated = "(" + curated.slice(0, 3) + ")" + curated.slice(3);
  }
  if (props.values.phone != curated) {
    props.setFieldValue("phone", curated);
  }
};

export const ZipFormat = (props) => {
  let curated = props.values.zip?.replace(PHONE_DIGIT_ONLY_REGEX, "");
  if (curated.length > 9) {
    curated = curated.slice(0, 9);
  }
  if (curated.length > 5) {
    curated = curated.slice(0, 5) + "-" + curated.slice(5);
  }
  if (props.values.zip != curated) {
    props.setFieldValue("zip", curated);
  }
};
