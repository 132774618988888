import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import React from "react";

import { ICON_PATH } from "../../define.constants";

const HomeNavigationLink = (props) => {
  const { fields, sitecoreContext } = props;
  return (
    <div className="container">
      <div className="home-link">
        <div className="media">
          <img
            src={`${ICON_PATH}/arrow-right-small.svg`}
            className="d-flex align-self-start left-arrow-custom"
            alt={getFieldValue(fields, "lnkHome")?.text}
          />
          <div className="media-body">
            <a href={getFieldValue(fields, "lnkHome")?.href}>
              <span>{getFieldValue(fields, "lnkHome")?.text}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNavigationLink;
