import React, { useEffect, useState, useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import DisplayTitleItem from "../MyHealthRecord-Layout/Common/DisplayTitleItem";
import RequestCareCoordination from "../RequestCareCoordination";
import { TX, TX_MD_PLUS_PROGRAMID } from "../../define.constants";
import { AppContext } from "../../contexts/app.context";

const CareCoordinationTeam = (props) => {
  // TO HIDE REQUEST CARE COORDINATION BTN FOR TX STAR PLUS PROGRAM - START
  const { programId, MemberStateName } = useContext(AppContext);
  //TX Star Plus Program condition check
  const isTXStarPlus =
    MemberStateName == TX && programId == TX_MD_PLUS_PROGRAMID;

  const isHideReqCareCoordinationBtn = isTXStarPlus;
  // TO HIDE REQUEST CARE COORDINATION BTN FOR TX STAR PLUS PROGRAM - END

  const { fields, data, data1 } = props;
  let { dataToPassOnBtnClick } = "";
  if (
    data1 != null &&
    data1 != undefined &&
    data1.ResultData != null &&
    data1.ResultData != undefined
  ) {
    dataToPassOnBtnClick = data1?.ResultData?.RequestingEntity;
  }
  let isButtonClicked = false;
  const [isRequestBtnClicked, setData] = useState(false);
  const [requestingEntityToPass, setrequestingEntityToPass] = useState([]);
  const [cancelBtnClicked, setCancelBtnData] = useState(false);
  let [isCanceledUpdated, setCanceledUpdated] = useState(false);

  useEffect(() => {
    setData(isButtonClicked);
  }, [isButtonClicked]);

  useEffect(() => {
    setrequestingEntityToPass(dataToPassOnBtnClick);
  }, [dataToPassOnBtnClick]);

  const CareTeamToRequestPage = () => {
    isButtonClicked = true;
    setData(isButtonClicked);
    props.parentCallback(isButtonClicked);
  };

  //cancelCallBack={handleCancelCallBack}. isCanceled property UPDATED value comes as TRUE from
  //RequestCareCordination Page when cancel is clicked
  const handleCancelCallBack = (isCanceled) => {
    isCanceledUpdated = isCanceled;
    setCanceledUpdated(isCanceledUpdated);
    props.cancelCallBackInRCC(isCanceledUpdated); //when cancel is clicked in request coordinatin page, call this listener which is available in index.js page
    setData(false);
  };

  useEffect(() => {
    setCanceledUpdated(isCanceledUpdated);
  }, [isCanceledUpdated]);

  return (
    <>
      {isRequestBtnClicked && !isHideReqCareCoordinationBtn ? (
        <RequestCareCoordination
          requestingEntities={requestingEntityToPass}
          fields={props.fields}
          cancelCallBack={handleCancelCallBack}
        />
      ) : (
        <>
          <div className="col-sm-12">
            {MemberStateName != TX && (
              <div className="me-auto p-2">
                <h5 className="ohio-subheading">
                  <Text field={props.fields.MainTitle} />
                </h5>
              </div>
            )}
            {MemberStateName != TX && (
              <DisplayItem
                nameField={fields.lblContactInfoHeading}
                outClass={"ohioText p-2"}
                titleClass={"paraText"}
                detailsClass={"paraText"}
              />
            )}
            <DisplayItem
              nameField={"Care Coordination Team - Molina HealthCare"}
              outClass={"col-md-6 col-xl-2"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
            <DisplayItem
              nameField={"Sub Heading"}
              outClass={"col-md-6 col-xl-2 d-xl-block d-none"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
          </div>
          <div className="d-flex mb-3">
            <div className="me-auto p-2">
              <h5 className="ohio-subheading">
                <Text field={props.fields.Title} />
              </h5>
            </div>
            {!isHideReqCareCoordinationBtn && (
              <div className="p-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => CareTeamToRequestPage()}
                >
                  <Text field={fields.lblRequestBtn} />
                </button>
              </div>
            )}
          </div>
          {data != null && data != undefined && data.length == 0 ? (
            <p className="no-records">
              <Text field={props.fields.lblNoResults} />
            </p>
          ) : (
            <>
              {data?.map((item, index) => (
                <div className="careTeam" key={index}>
                  <div className="blockSec2 mb20" id="recordsToPrint">
                    <div className="row CareR1">
                      <DisplayItem
                        nameField={fields.lblCTProvFullName}
                        valueField={item.CareTeamproviderfullname}
                        outClass={"col-xxl-4 col-xl-4 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblContactType}
                        valueField={item.ContactType}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblPhoneNumber}
                        valueField={item.PhoneNumber}
                        outClass={"col-xxl-2 col-xl-2 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblAssigneDate}
                        valueField={item.AssignedDatestr}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                    </div>
                    <div className="row mt15">
                      <DisplayItem
                        nameField={fields.lblCareManagerName}
                        valueField={item.CareManagerFullName}
                        outClass={"col-xxl-4 col-xl-4 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblCareManagerPhone}
                        valueField={item.Caremanagersphonenumber}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblEntity}
                        valueField={item.EntityType}
                        outClass={"col-xxl-2 col-xl-2 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblCTPrimary}
                        valueField={item.Primary}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                    </div>
                    <div className="row mt15">
                      <DisplayItem
                        nameField={fields.lblCTCategory}
                        valueField={item.Category}
                        outClass={"col-xxl-4 col-xl-4 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblDelegateCareManager}
                        valueField={item.DelegatedCareManager}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblCaseMangerStatus}
                        valueField={item.CaseManagerStatus}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CareCoordinationTeam;
