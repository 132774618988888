import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardIDMedicaid = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/ID/Medicaid/ID-Medicaid-update.png)`,
          }}
          className="background-idcard"
        >
          <div className="row">
            <div className="col-sm-9 col-md-9 col-lg-9">
              <div className="p-4">
                <div className="mgBackgroundIDMedicaid">
                  <div style={{ color: "#C0D52F" }}>
                    <label>{idCard?.PlanName}</label>
                  </div>
                  <div>
                    <Text field={fields?.lblMember} /> &nbsp;
                    {idCard?.Member_Name}
                  </div>
                  <div>
                    <Text field={fields?.lblIdentification} /> &nbsp;
                    {idCard?.OriginalMemberProfile?.Number}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8 col-md-8 col-lg-8">
                <div className="p-4">
                  <div className="memberIdServicesIDMedicaid">
                    <div>
                      <b>
                        <Text field={fields?.lblIDReferText} />
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 col-md-4 col-lg-4">
                {/* <div className="p-4"> */}
                <div className="IDRXValues">
                  <div>
                    <Text field={fields?.lblBin} />
                    &nbsp; {result?.IdCardValues?.RxBIN}
                  </div>
                  <div>
                    <Text field={fields?.lblPCN} />
                    &nbsp; {result?.IdCardValues?.RxPCN}
                  </div>
                  <div>
                    <Text field={fields.lblGroup} />
                    &nbsp;{result?.IdCardValues?.RxGRP}
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 col-md-10 col-lg-10">
              <div className="p-4">
                <div className="memberIdServicesIDMedicaid">
                  <div>
                    <Text field={fields.lblIDMemberServices} />
                  </div>
                  <div>
                    <Text field={fields.lblIDMemberServicesHours} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2 col-md-2 col-lg-2">
              <div className="p-4"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/WA/Medicaid/WA_Medicaid_Back.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="medicaidIDBackView">
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>
              <span style={{ textDecoration: "underline" }}>
                <Text field={fields.lblIDEmergencyText} />
              </span>
              &nbsp;
              <Text field={fields.lblIDEmergencyValue} />
            </div>
            <div>&nbsp;</div>
            <div>
              <span style={{ textDecoration: "underline" }}>
                <Text field={fields.lblMemberServices} />
              </span>
              &nbsp;
              <Text field={fields.lblIDMemberBackTextValue} />
            </div>
            <div>&nbsp;</div>
            <div>
              <span style={{ textDecoration: "underline" }}>
                <Text field={fields.lblIDNurseAdviceLine} />
              </span>
              &nbsp;
              <Text field={fields.lblIDNurseAdviceLineValue} />
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>
              <span style={{ textDecoration: "underline" }}>
                <Text field={fields.lblIDProvider} />{" "}
              </span>
              &nbsp;
              <Text field={fields.lblIDProviderValue} />
            </div>
            <div>&nbsp;</div>
            <div></div>
            <div>
              <Text field={fields.lblSubmitClaim} /> &nbsp;
              <Text field={fields.lblMedicalValue} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardIDMedicaid;
