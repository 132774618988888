import React, { useContext, useState, useEffect } from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import { ModalContext } from "./../../contexts/modal.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import { AppContext } from "./../../contexts/app.context";
import { getStateLobConfigurationItems } from "./../../utils/helpers";

const CommonSurvey = (props) => {
  const { openModal } = useContext(ModalContext);
  const [showSurveyBtn, setShowSurveyBtn] = useState("d-none");
  let {
    memberLOB,
    MemberStateName,
    programId,
    homePhone,
    prefEmailId,
    cellPhone,
    prefPhone,
    profileList,
  } = useContext(AppContext);
  const survey = getStateLobConfigurationItems(
    props.fields,
    MemberStateName,
    memberLOB,
    ""
  );

  useEffect(() => {
    setTimeout(() => {
      setShowSurveyBtn("");
    }, 30000);
  }, []);

  // const modalContent = (
  //   <div className="contact-modal">
  //     <div className="update-header">
  //       <h5>
  //         <Text field={props.fields.heading}></Text>
  //       </h5>
  //       <p>
  //         <Text field={survey[0]?.fields?.feedback1}></Text>
  //       </p>
  //     </div>
  //     <p className="text-zoom-lg">
  //       <Link target="blank" field={survey[0]?.fields?.memberlink}></Link>{" "}
  //       <i className="fas fa-chevron-right fa-sm"></i>
  //     </p>
  //   </div>
  // );
  return (
    <div>
      <div className="popup-btn f-16">
        <a
          href={survey[0]?.fields?.memberlink?.value.href}
          target="_blank"
          rel="noopener noreferrer"
          className={showSurveyBtn}
          //onClick={() => openModal({ content: modalContent })}
        >
          <Text field={props.fields.heading1}></Text>
        </a>
      </div>
    </div>
  );
};
export default withModalProvider(CommonSurvey);
