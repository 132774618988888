import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import { ErrorContext } from "../contexts/error.context";
import * as ViewLabResultsAPI from "../services/HeathRecordAPI";
import { MY_HEALTH_RECORD_GET_LAB_RESULTS } from "../define.widgets";
const qs = require("qs");

const useViewVaccinationsAPI = (searchTerm = null) => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [viewVaccinationsList, setViewVaccinationsList] = useState([]);
  const { memberRKSID } = useContext(AppContext);
  const [isVaccinationDataloaded, setIsVaccinationDataloaded] = useState(false);

  // SETTING API FETCHED DATA TO STATE VARIABLES
  const setData = (data) => {
    setViewVaccinationsList(data?.ResultData?.FormattedVaccinationRecords);
    setIsVaccinationDataloaded(true);
  };

  useEffect(() => {
    // GETTING DATE RANGE
    let startDate = "",
      endDate = "";
    if (searchTerm != null) {
      startDate = searchTerm["startDate"];
      endDate = searchTerm["endDate"];
    }

    // SETTING QUERY
    let query = {
      VaccineDateFrom: startDate,
      VaccineDateTo: endDate,
    };

    setInnerSpinner(true, MY_HEALTH_RECORD_GET_LAB_RESULTS); // Check
    ViewLabResultsAPI.getViewVaccinationsList(
      {
        DropDownSelectedMemId: memberRKSID,
        VaccineDateFrom: startDate,
        VaccineDateTo: endDate,
      },
      qs.stringify(query, { addQueryPrefix: true })
    )
      .then((response) => {
        setData(response);
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(function () {
        setInnerSpinner(false, MY_HEALTH_RECORD_GET_LAB_RESULTS); // Check
      });
  }, [searchTerm]);

  return {
    viewVaccinationsList,
    isVaccinationDataloaded,
  };
};

export { useViewVaccinationsAPI };
