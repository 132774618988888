import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ACCOUNT_SETTINGS_MY_PROFILE } from "../../define.widgets";

function Communication(props) {
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  let { prefPhone, homePhone, cellPhone, profileList } = useContext(AppContext);
  const { setActiveTab, tabOptions } = props;
  const accountDetailMyProfile = props?.fields?.MyProfileList[0] ?? null;
  const initialValues = {
    homePhone: homePhone,
  };
  let updatedHomePhone = UpdatedPhoneNumber(homePhone);
  let { isTermedMember } = useContext(AppContext);
  function UpdatedPhoneNumber(phoneNumber) {
    const strPhone = phoneNumber?.replace(/[()]/g, "");
    const formattedPhone = strPhone?.replace(/\s/g, "-");
    return formattedPhone;
  }
  const onSubmit = (values, submitProps) => {
    submitProps.setSubmitting(false);
  };
  const validationSchema = Yup.object({
    prefEmailId: Yup.string().required("Required"),
    prefPhone: Yup.string().required("Required"),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <li className="list-group-item">
                {innerSpinnerHTML?.[ACCOUNT_SETTINGS_MY_PROFILE]}
                <div className="row">
                  <div className="col-xl-9 col-md-8">
                    <h5 className="tab-sub-title">
                      <Text
                        field={
                          accountDetailMyProfile?.fields?.communicationTitle ??
                          ""
                        }
                      />
                    </h5>
                  </div>
                  <div className="col-xl-3 col-md-4">
                    <button
                      type="button"
                      className={
                        isTermedMember
                          ? "btn btn-primary update-button disabled"
                          : "btn btn-primary update-button"
                      }
                      onClick={(e) => setActiveTab(e, 2, tabOptions[2]?.value)}
                    >
                      <label htmlFor="updateButtonText">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.updateButtonText
                          }
                        />
                      </label>
                    </button>
                  </div>
                </div>
                <div className="row common-input">
                  <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="row-title">
                      <Text
                        field={accountDetailMyProfile?.fields?.preferredPhone}
                      />
                    </div>
                    <div className="row-details">
                      {!homePhone == "" && <div>{updatedHomePhone}</div>}
                    </div>
                  </div>
                  <div className="col-xxl-4 col-lg-8 col-md-8 col-sm-12 button-space">
                    <div className="row-title">
                      <Text
                        field={
                          accountDetailMyProfile?.fields?.preferredEmail ?? ""
                        }
                      />
                    </div>
                    <div className="row-details">
                      <div>{profileList.Email}</div>
                    </div>
                  </div>
                </div>
              </li>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default Communication;
