import React, { useState, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Form, Field, FieldArray, ErrorMessage, Formik } from "formik";
import { highlightBorderOnError } from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import { InlineFormError } from "../../common/InlineFormError";
import { AppContext } from "./../../contexts/app.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  ICON_PATH,
  PHONE_DIGIT_ONLY_REGEX,
  IUD_FLAG_D,
  SUPPORT_USER,
  TERMED_MEMBER,
} from "../../define.constants";
const PreferredPronoun = ({
  fields,
  errors,
  values,
  touched,
  initialTouched,
  setFieldValue,
  setFieldTouched,
  setValues,
  pageEditable,
  onButtonClick,
  contactPreferencesList,
  handleBlur,
  handleChange,
  getPreferredPronounSelected,
  handlePreferredPronounValue,
  contactData,
}) => {
  let preferredPronounList = [];
  let [preferredPronounFlag, setPreferredPronounFlag] = useState(true);
  let { isTermedMember } = useContext(AppContext);
  let pronounId = 3; /*Added index value for comparing and displaying the texbox*/
  const handlePreferredPronounSelect = (e) => {
    if (e.value === "Other (please specify)") {
      setPreferredPronounFlag(false);
    } else if (e.id === pronounId) {
      setPreferredPronounFlag(false);
    } else {
      setPreferredPronounFlag(true);
    }
    getPreferredPronounSelected(e.value);
  };

  contactPreferencesList?.MH_Pronoun__pc?.map((data, index) => {
    preferredPronounList.push({
      value: data,
      id: index,
      label: data,
    });
  });
  const initialValues = {
    preferredPronounList: contactData?.IsContactPreferences?.Pronoun,
  };
  return (
    <>
      {initialValues.preferredPronounList !== undefined && (
        <Formik initialValues={initialValues}>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h5 className="tab-sub-title column-margin">
                  <Text field={fields?.preferredPronoun ?? ""} />
                </h5>
                <div
                  className={
                    pageEditable
                      ? "disabled-div dropdown-list-width"
                      : "dropdown-list-width"
                  }
                >
                  <Field
                    as="select"
                    id="preferredPronounList"
                    name="preferredPronounList"
                    options={preferredPronounList}
                    value={
                      preferredPronounList
                        ? preferredPronounList.find(
                            (preferredPronounList) =>
                              preferredPronounList.value ===
                              values.preferredPronounList
                          )
                        : ""
                    }
                    onChange={(e) => handlePreferredPronounSelect(e)}
                    component={CustomSelect}
                    isFormik={true}
                  />
                  <textarea
                    maxLength={20}
                    placeholder={getFieldValue(fields, "lblPreferredPronounPH")}
                    className={
                      preferredPronounFlag
                        ? "textarea-display"
                        : "form-control textarea-control"
                    }
                    onChange={(e) => handlePreferredPronounValue(e)}
                  ></textarea>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};
export default PreferredPronoun;
