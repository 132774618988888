import React, { useContext, useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Dependents from "./Dependents";
import Confirmation from "./Confirmation";
import { useGetIdcardReasonListAPI } from "../../../hooks/useGetIdCardReasonListAPI";
import { ModalContext } from "../../../contexts/modal.context";
import { AppContext } from "../../../contexts/app.context";
import { MARKETPLACE } from "../../../define.constants";

const Reasons = (props) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const { fields, showUserSwitch, dependents } = props;
  const { listReasons } = useGetIdcardReasonListAPI();
  const [selectedReason, setSelectedReason] = useState(null);
  const { memberLOB } = useContext(AppContext);
  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;

  const handleChange = (e) => {
    let reasonObj = listReasons?.filter(
      (lr) => lr.ReasonId === Number(e.target.id)
    )[0];
    setSelectedReason(reasonObj);
  };

  return (
    <>
      <div className="request-new-id-body">
        <p className="request-new-id-body-title">
          <Text field={fields.PopupRequestNewIDCardP4} />
        </p>
        {listReasons?.map((reason, index) => (
          <div key={index}>
            <input
              className="form-check-input radio-btns-access"
              type="radio"
              id={reason.ReasonId}
              name="reasons"
              value={reason.ChangeReason}
              onChange={(e) => handleChange(e)}
            ></input>
            &nbsp;
            <label className="form-check-label" htmlFor={reason.ReasonId}>
              {reason.ChangeReason}
            </label>
            <br />
            <br />
          </div>
        ))}
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            <Text field={fields.btnCancel} />
          </button>

          <button
            type="button"
            disabled={selectedReason === null}
            className="btn btn-primary"
            onClick={() =>
              openModal({
                header: (
                  <div className="request-new-id-header">
                    <Text field={fields.PopupRequestNewIDCard} />
                  </div>
                ),

                content:
                  isMarketplaceMember &&
                  showUserSwitch &&
                  dependents?.length > 0 ? (
                    <Dependents {...props} selectedReason={selectedReason} />
                  ) : (
                    <Confirmation {...props} selectedReason={selectedReason} />
                  ),
                version: 1,
              })
            }
          >
            <Text field={fields.btnNext} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Reasons;
