import React, { useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import {
  STEP_USER_ID,
  STEP_VERIFY_CODE,
  STEP_PWD,
  FUNCTIONALITY_USERID,
  INITIAL_NUMBER,
  SUBMIT,
  USERID,
  PWD,
  FUNCTIONALITY_PWD,
} from "./constants";
import { SUCCESS_RESPONSE, SUCCESS_RESULT_CODE } from "../../define.constants";
import * as RegistrationAPI from "../../services/RegistrationAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { highlightBorderOnError } from "../../utils/helpers";
import * as Yup from "yup";
import { InlineFormError } from "../../common/InlineFormError";
import { ErrorContext } from "../../contexts/error.context";

const ForgotUserIdAndPwd = (props) => {
  const { fields, selectedOption, NavigateToSteps } = props;
  const { setSpinner } = useContext(SpinnerContext);

  const { setError } = useContext(ErrorContext);
  const title = () => {
    if (selectedOption === STEP_USER_ID) {
      return fields.lblIdRecovery.value;
    } else {
      return fields.lblResetPwd.value;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          emailAddress: "",
        }}
        validationSchema={Yup.object().shape({
          emailAddress: Yup.string()
            .email(<Text field={fields.emailError} />)
            .required(<Text field={fields.lblRequired} />),
        })}
        onSubmit={(values) => {
          if (selectedOption === STEP_USER_ID) {
            setSpinner(true);
            RegistrationAPI.getForgotUserIDPassword({
              EmailId: values.emailAddress,
              CalledFrom: USERID,
              ButtonSend: SUBMIT,
              InCorrectCount: INITIAL_NUMBER,
              Functionality: FUNCTIONALITY_USERID,
            })
              .then((data) => {
                if (data.ResultCode === SUCCESS_RESULT_CODE) {
                  if (data.ResultData.Message === SUCCESS_RESPONSE) {
                    NavigateToSteps(STEP_VERIFY_CODE, values.emailAddress);
                  } else {
                    //alert(data.ResultData.Message);
                    setError({ customMessage: data.ResultData.Message });
                  }
                } else {
                  setError({
                    customMessage: getFieldValue(
                      fields,
                      "emailVerificationError"
                    ),
                  });
                  //alert(getFieldValue(fields, "emailVerificationError"));
                }
              })
              .catch((err) => {
                // setError({
                //   customMessage: getFieldValue(fields, "secCodeSendError"),
                // });
                // console.log(getFieldValue(fields, "secCodeSendError"), error);
                setError({ err });
              })
              .finally(() => {
                setSpinner(false);
              });
          } else if (selectedOption === STEP_PWD) {
            setSpinner(true);
            RegistrationAPI.getForgotUserIDPassword({
              EmailId: values.emailAddress,
              CalledFrom: PWD,
              ButtonSend: SUBMIT,
              InCorrectCount: INITIAL_NUMBER,
              Functionality: FUNCTIONALITY_PWD,
            })
              .then((data) => {
                if (data.ResultCode === SUCCESS_RESULT_CODE) {
                  if (data.ResultData.Message === SUCCESS_RESPONSE) {
                    NavigateToSteps(STEP_VERIFY_CODE, values.emailAddress);
                  } else {
                    setError({ customMessage: data.ResultData.Message });
                    // alert(data.ResultData.Message);
                  }
                } else {
                  setError({
                    customMessage: getFieldValue(
                      fields,
                      "emailVerificationError"
                    ),
                  });
                  // alert(getFieldValue(fields, "emailVerificationError"));
                }
              })
              .catch((err) => {
                //console.log(getFieldValue(fields, "secCodeSendError"), error);
                setError({ err });
              })
              .finally(() => {
                setSpinner(false);
              });
          }
        }}
      >
        {({ touched, errors }) => (
          <Form>
            <h5 className="card-title text-center">{title()}</h5>
            <div className="row-title">
              <Text field={fields.lblVerifyEmail} />
            </div>

            <Field
              type="text"
              name="emailAddress"
              className={`form-control ${highlightBorderOnError(
                errors.emailAddress,
                touched.emailAddress
              )}`}
              placeholder={getFieldValue(fields, "emailFieldPlaceholder")}
            />
            <ErrorMessage name="emailAddress" component={InlineFormError} />

            <div className="recovery-btn col-md-12">
              <button
                type="button"
                className="btn text-info ripple-surface1"
                onClick={() => {
                  NavigateToSteps(INITIAL_NUMBER);
                }}
              >
                <Text field={fields.btnCancel} />
              </button>
              <button type="submit" className="btn btn-primary">
                <Text field={fields.btnNext} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotUserIdAndPwd;
