import { useEffect, useState, useContext } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import * as HealthRecordAPI from "../services/HeathRecordAPI";
import { ErrorContext } from "../contexts/error.context";
import { MY_HEALTH_RECORD_GET_CARE_PLAN } from "../define.widgets";
import {
  Comprehensive_Care_Plan,
  NM,
  NM_MEDICAID_ProgramID,
} from "../define.constants";

const useGetCarePlanComprehensiveAPI = () => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { memberRKSID, MemberStateName, programId } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const [comprehensiveCarePlan, setComprehensiveCarePlan] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const setData = (response) => {
    setComprehensiveCarePlan(response.ResultData.Output);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (
      memberRKSID != "" &&
      MemberStateName === NM &&
      programId === NM_MEDICAID_ProgramID
    ) {
      setInnerSpinner(true, MY_HEALTH_RECORD_GET_CARE_PLAN);
      HealthRecordAPI.getCarePlan({
        CareplanName: Comprehensive_Care_Plan,
      })
        .then((response) => {
          setData(response);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MY_HEALTH_RECORD_GET_CARE_PLAN);
        });
    }
  }, [memberRKSID, setInnerSpinner]);
  return { comprehensiveCarePlan, isLoaded };
};

export { useGetCarePlanComprehensiveAPI };
