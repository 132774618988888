import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { withYearDropdownProvider } from "../../hoc/withYearDropdownProvider";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

const MyCoverageLayout = (props) => {
  const { rendering, fields } = props;

  return (
    <div>
      <Placeholder name="jss-mycoverage-layout" rendering={rendering} />
    </div>
  );
};
export default withYearDropdownProvider(MyCoverageLayout);
