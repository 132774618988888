import React, { useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { AppProvider } from "../../contexts/app.context";

function SSORedirect() {
  const { html, setHtml } = useContext(SSORedirectContext);

  useEffect(() => {
    if (html) {
      document.forms[0] !== undefined && document.forms[0].submit();
      setHtml("");
    }
  }, [html]);

  if (html) {
    return ReactDOM.createPortal(
      <AppProvider>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </AppProvider>,
      document.querySelector("#sso-root")
    );
  } else {
    return null;
  }
}

export default SSORedirect;
