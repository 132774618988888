import React from "react";
import { ModalProvider } from "../contexts/modal.context";

function withModalProvider(WrappedComponent) {
  return class HOC extends React.Component {
    render() {
      return (
        <ModalProvider>
          <WrappedComponent {...this.props} />
        </ModalProvider>
      );
    }
  };
}

export { withModalProvider };
