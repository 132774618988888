import { useContext, useEffect, useState } from "react";
import Communication from "../components/AccountSettings-MyProfileWrapper/Communication";
import { ErrorContext } from "../contexts/error.context";
import { SpinnerContext } from "../contexts/spinner.context";
import { HOME_MY_MESSAGE_CARD } from "../define.widgets";
import * as CommunicationPreferencesAPI from "../services/CommunicationPreferencesAPI";

const qs = require("qs");

const useViewContactPreferencesList = () => {
  const { setError } = useContext(ErrorContext);
  const { setInnerSpinner } = useContext(SpinnerContext);
  const [contactPreferencesList, setContactPreferencesList] = useState([]);

  useEffect(() => {
    setInnerSpinner(true);
    CommunicationPreferencesAPI.ViewContactPreferencesList({})
      .then((response) => {
        if (response?.ResultData != undefined) {
          setContactPreferencesList(
            response.ResultData?.IsContactPreferencesList
          );
        }
      })
      .catch(function (err) {
        setError({ err });
      })

      .finally(function () {
        setInnerSpinner(false);
      });
  }, []);

  return { contactPreferencesList };
};

export { useViewContactPreferencesList };
