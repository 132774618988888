import { useEffect, useState, useContext } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import * as HealthRecordAPI from "../services/HeathRecordAPI";
import { ErrorContext } from "../contexts/error.context";
import { MY_HEALTH_RECORD_GET_CARE_PLAN } from "../define.widgets";
import { CarePlan_Name } from "../define.constants";

const useGetCarePlanAPI = () => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { memberRKSID } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const [carePlan, setCarePlan] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const setData = (response) => {
    setCarePlan(response.ResultData.Output);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (memberRKSID != "") {
      setInnerSpinner(true, MY_HEALTH_RECORD_GET_CARE_PLAN);
      HealthRecordAPI.getCarePlan({
        RKSMemberId: memberRKSID,
        CareplanName: CarePlan_Name,
      })
        .then((response) => {
          setData(response);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MY_HEALTH_RECORD_GET_CARE_PLAN);
        });
    }
  }, [memberRKSID, setInnerSpinner]);
  return { carePlan, isLoaded };
};

export { useGetCarePlanAPI };
