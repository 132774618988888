import React from "react";
import Title from "./Title";
import Description from "./Description";
import CostEstimatorButton from "./CostEstimatorButton";
import { withModalProvider } from "../../../hoc/withModalProvider";

const ProcedureCostEstimator = ({
  fields,
  columns = "12",
  showFamilySpending,
  list,
}) => {
  return columns === "12" ? (
    <div className="row cost-estimator-block">
      <div className="col-md-12 col-sm-12">
        <section className=" d-flex justify-content-center">
          <div className="card lg-card">
            <div className="card-body">
              <div className="row">
                <div className={`col-lg-${columns} col-sm-12`}>
                  <Title fields={fields} />
                  <div className="row">
                    <div className="col-xl-9 col-lg-8">
                      <Description fields={fields} list={list} />
                    </div>
                    <div className="col-xl-3 col-lg-4 position-relative">
                      <CostEstimatorButton fields={fields} list={list} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : (
    <div
      className={`col-xxl-${columns} col-xl-${
        showFamilySpending ? "12" : "6"
      } col-lg-12 col-sm-12 cost-estimator-centered`}
    >
      <div className="cost-estimator">
        <Title fields={fields} />
        <Description fields={fields} list={list} />
        <CostEstimatorButton fields={fields} list={list} />
      </div>
    </div>
  );
};

export default withModalProvider(ProcedureCostEstimator);
