import React from "react";
import { Image, Link } from "@sitecore-jss/sitecore-jss-react";

const NonMarketplaceLanguageInfoLink = (props) => {
  const { planurl } = props;

  return (
    <Link
      href={planurl}
      aria-label={props.link?.href}
      field={props.link}
      className="card-link list-icon-download text-zoom"
    >
      <Image field={props.image} className="img-link" />
      {props.text}
    </Link>
  );
};

export default NonMarketplaceLanguageInfoLink;
