import React from "react";

function Arrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={style} onClick={onClick} />;
}

export const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  rows: 1,
  nextArrow: <Arrow />,
  prevArrow: <Arrow />,
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 3,
        slidesPerRow: 2,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        rows: 1,
        slidesPerRow: 1,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 1919,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        rows: 1,
        slidesPerRow: 1,
        dots: true,
        infinite: true,
      },
    },
  ],
};

export const __accordantSSO = "accordantSSO";
export const __document = "document";
export const __link = "link";
export const __planSpecificDocument = "planSpecificDocument";
export const __sso = "sso";
export const __dictionaryDocument = "dictionaryDocument";
