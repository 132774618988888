import Axios from "./axios/provider";

/**
 * Get inbox messages & alerts
 *
 * @param {*} data
 * @returns
 */
export const getMessages = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.MemMessageAndViewArchive + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get Message Headers
 *
 * @param {*} data
 * @returns
 */
export const getMsgHeaders = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetMessageAndArchive + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get Message/alert details!
 *
 * @param {object} data
 * @returns
 */
export const getMessageDetail = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetConversationDetails + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get Attchments
 *
 * @param {object} data
 * @returns
 */
export const getAttachment = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetAttachment + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const setAttachment = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.SetAttachment, data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getPortalMessageDetail = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.MemMessageDetail + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Archive messages
 * @param {string} data
 * @returns
 */
export const archiveMemMessages = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ArchiveMemMessages,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Downloadmessage attachment
 * @param {*} data
 * @returns
 */
export const downloadMessageAttachmentFile = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.DownloadMessageAttachmentFile,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// This method is used to send the message -- Send Message Create Message
export const CreateNewConversation = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.CreateNewConversation,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// This method is used to send the message -- Send Message Create Message
export const CreateNewConversationEntry = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.CreateNewConversationEntry,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// This method is to submit a request for a provider directory
export const RequestProviderDirectoryHardCopy = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.RequestProviderDirectory,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get inbox messages & alerts
 *
 * @param {*} data
 * @returns
 */
export const ListServicesAPI = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetCategoriesList,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// This method is to submit care coordination request to CCA
export const SubmitCareCoordinationRequestToCCA = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.SubmitCareCoordinationRequestToCCA,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// This method is submit the Member Rewards Form -- in My Wellness
export const SubmitMemberRewards = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.SubmitMemberRewards,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
