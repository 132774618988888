import React, { useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Form, Field, FieldArray, ErrorMessage } from "formik";
import { highlightBorderOnError } from "../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import { InlineFormError } from "../../common/InlineFormError";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  ICON_PATH,
  IUD_FLAG_D,
  SUPPORT_USER,
  TERMED_MEMBER,
} from "../../define.constants";
import { AppContext } from "./../../contexts/app.context";
const EmailPreference = ({
  fields,
  errors,
  values,
  touched,
  setFieldValue,
  setValues,
  pageEditable,
  onButtonClick,
  communicationData,
  setFieldTouched,
  isShowEmail,
}) => {
  let dropoptions = [];
  let { isTermedMember } = useContext(AppContext);
  communicationData?.LsMemberAPIInfo?.EmailTypes?.map((data) => {
    dropoptions.push({
      value: data.TypeName,
      id: data.TypeName,
      label: data.LocalizedFields[0]?.Name,
    });
  });
  const handleChange1 = (selectedOption) => {
    {
    }
  };
  const AddRow = () => {
    const preferences = values?.EmailPreference?.preferences;
    preferences.push({
      EmailAddress: "",
      EmailAddressType: "",
      preferredEmail: preferences?.length === 0 ? "0" : "",

      IUDFlag: null,
      isNewRow: true,
      isRowDeleted: false,
      OrderId: preferences?.filter((o) => o.IUDFlag !== IUD_FLAG_D).length + 1,
    });
    setValues({ ...values, preferences });
  };
  const ArrangeOrderofRow = (ind) => {
    let counter = 1;
    const preferences = values?.EmailPreference?.preferences;
    preferences.map((data, index) => {
      if (ind === index || data.IUDFlag === IUD_FLAG_D) {
        preferences[index].OrderId = null;
      } else {
        preferences[index].OrderId = counter;
        counter++;
      }
    });
    setValues({ ...values, preferences });
  };

  const BindLabel = ({ children, htmlFor, cssClass, textDetails }) => {
    return (
      <>
        <label
          htmlFor={`EmailPreference.preferences.${htmlFor}`}
          className={`row-title ${cssClass} radio-btn`}
        >
          <Text field={textDetails ?? ""} />
          {children}
        </label>
      </>
    );
  };

  let count = 0;
  return (
    <>
      <Form>
        <div className="row">
          <div className="col-lg-12 col-md-8">
            <h5 className="tab-sub-title">
              <Text field={fields?.emailCardHeading ?? ""} />
              <WhoIsAuthorized
                authorize={{
                  [SUPPORT_USER]: true,
                  [TERMED_MEMBER]: false,
                }}
                handleClick={onButtonClick}
              >
                {({ handleClick, disabledClass }) => {
                  return (
                    <>
                      {pageEditable === true && isShowEmail ? (
                        <a
                          className={`edit ${disabledClass}`}
                          onClick={handleClick}
                        >
                          <Text field={fields?.emailCardEditButton ?? ""} />
                        </a>
                      ) : null}
                    </>
                  );
                }}
              </WhoIsAuthorized>
            </h5>
          </div>
        </div>
        <div className="col-sm-12">
          <p className="preferences-para">
            {!isShowEmail ? (
              <Text field={fields?.emailCardWarnMessage ?? ""} />
            ) : (
              <Text field={fields?.emailCardDescription ?? ""} />
            )}
          </p>
        </div>
        <hr className="d-none d-sm-block d-xl-none" />
        <div>
          <FieldArray name="preferences">
            {(fieldArrayProps) => {
              return (
                <div>
                  {values?.EmailPreference?.preferences?.map(
                    (component, index) => (
                      component.IUDFlag !== IUD_FLAG_D ? count++ : "",
                      (
                        <div
                          id={`div_email_` + index}
                          key={index}
                          className={
                            component.IUDFlag === IUD_FLAG_D ? "d-none" : ""
                          }
                        >
                          <div className="row label-list">
                            <div className="col-xl-2 d-xl-block d-none">
                              <BindLabel
                                htmlFor={`${index}.EmailAddress`}
                                textDetails={fields?.emailHeading}
                              >
                                {component.OrderId}
                              </BindLabel>
                            </div>
                            <div className="col-xl-2 d-xl-block d-none">
                              <BindLabel
                                htmlFor={`${index}.EmailAddressType`}
                                textDetails={fields?.emailTypeHeading}
                              ></BindLabel>
                            </div>
                            <div className="col-xl-2 d-xl-block d-none radio-check-btn">
                              <BindLabel
                                htmlFor={`${index}.preferredEmail`}
                                textDetails={fields?.preferredEmailHeading}
                              ></BindLabel>
                            </div>
                          </div>
                          <div className="row field1">
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                              <BindLabel
                                htmlFor={`${index}.EmailAddress`}
                                cssClass={`d-lg-block d-xl-none`}
                                textDetails={fields?.emailHeading}
                              >
                                {component.OrderId}
                              </BindLabel>

                              <div className="row-details">
                                <Field
                                  name={`EmailPreference.preferences.${index}.EmailAddress`}
                                  placeholder={getFieldValue(
                                    fields,
                                    "placeholderEmailAddress",
                                    false
                                  )}
                                  type="text"
                                  disabled={pageEditable}
                                  onChange={(event) => {
                                    setFieldValue(
                                      `EmailPreference.preferences.${index}.EmailAddress`,
                                      event?.target?.value
                                    );
                                  }}
                                  className={
                                    pageEditable === true
                                      ? "form-control"
                                      : `form-control gray ${highlightBorderOnError(
                                          errors.EmailPreference
                                            ?.preferences !== undefined
                                            ? errors.EmailPreference
                                                ?.preferences[index]
                                                ?.EmailAddress
                                            : null,
                                          touched.EmailPreference
                                            ?.preferences !== undefined
                                            ? touched.EmailPreference
                                                ?.preferences[index]
                                                ?.EmailAddress
                                            : null
                                        )}`
                                  }
                                />
                                <ErrorMessage
                                  name={`EmailPreference.preferences.${index}.EmailAddress`}
                                  component={InlineFormError}
                                />
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                              <BindLabel
                                htmlFor={`${index}.EmailAddressType`}
                                cssClass={`d-lg-block d-xl-none`}
                                textDetails={fields?.emailTypeHeading}
                              ></BindLabel>
                              <div className="row-details">
                                <Field
                                  as="select"
                                  name={`EmailPreference.preferences.${index}.EmailAddressType`}
                                  id={`EmailPreference.preferences.${index}.EmailAddressType`}
                                  placeholder={getFieldValue(
                                    fields,
                                    "placeholderEmailType",
                                    false
                                  )}
                                  options={dropoptions}
                                  isDisabled={pageEditable}
                                  component={CustomSelect}
                                  onChange={(e) => {
                                    handleChange1(e);
                                    setFieldTouched(
                                      `EmailPreference.preferences.${index}.EmailAddressType`,
                                      true
                                    );
                                  }}
                                  aria-label="Default select example"
                                  customclass={
                                    (errors?.EmailPreference?.preferences !==
                                    undefined
                                      ? errors?.EmailPreference?.preferences[
                                          index
                                        ]?.EmailAddressType
                                      : null) &&
                                    (component.isNewRow
                                      ? touched?.EmailPreference
                                          ?.preferences !== undefined
                                        ? touched?.EmailPreference?.preferences[
                                            index
                                          ]?.EmailAddressType
                                          ? touched?.EmailPreference
                                              ?.preferences[index]
                                              ?.EmailAddressType
                                          : false
                                        : false
                                      : true)
                                      ? " molina-select__control_err"
                                      : ""
                                  }
                                />
                                <p className="text-danger">
                                  {(errors?.EmailPreference?.preferences !==
                                  undefined
                                    ? errors?.EmailPreference?.preferences[
                                        index
                                      ]?.EmailAddressType
                                    : false) &&
                                  (component.isNewRow
                                    ? touched?.EmailPreference?.preferences !==
                                      undefined
                                      ? touched?.EmailPreference?.preferences[
                                          index
                                        ]?.EmailAddressType
                                        ? touched?.EmailPreference?.preferences[
                                            index
                                          ]?.EmailAddressType
                                        : false
                                      : false
                                    : true)
                                    ? errors?.EmailPreference.preferences[index]
                                        ?.EmailAddressType
                                    : ""}
                                </p>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn ">
                              <BindLabel
                                htmlFor={`${index}.preferredEmail`}
                                cssClass={`d-lg-block d-xl-none`}
                                textDetails={fields?.preferredEmailHeading}
                              ></BindLabel>

                              <div className="row-details">
                                <div className="form-check">
                                  <Field
                                    name="EmailPreference.preferredEmail"
                                    id={`EmailPreference.preferences.${index}.preferredEmail`}
                                    type="radio"
                                    disabled={pageEditable}
                                    value={`${index}`}
                                    onChange={(event) => {
                                      setFieldValue(
                                        `EmailPreference.preferredEmail`,
                                        event?.target?.value
                                      );
                                      setFieldValue(
                                        `EmailPreference.preferences.${index}.preferredEmail`,
                                        event?.target?.value
                                      );
                                    }}
                                    className={"form-check-input"}
                                  />
                                </div>
                              </div>
                              {values?.EmailPreference?.preferences?.filter(
                                (o) => o.IUDFlag !== IUD_FLAG_D
                              )?.length === count && (
                                <div className="error-msg">
                                  <p className="text-danger">
                                    {errors?.EmailPreference?.preferences[0]
                                      ?.preferredEmail ?? ""}
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="col-xl-2 col-lg-0 d-none d-xl-block"></div>
                            <div className="col-xl-2 col-lg-0 d-none d-xl-block"></div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 radio-check-btn email">
                              {pageEditable === false && (
                                <>
                                  {values?.EmailPreference?.preferences
                                    ?.length > 0 &&
                                    (values?.EmailPreference?.preferredEmail ===
                                    index + "" ? (
                                      <>
                                        <img
                                          src={`${ICON_PATH}/remove_circle-light-24px.svg`}
                                          alt={getFieldValue(
                                            fields,
                                            "lblAltDeleteGrayIcon",
                                            false
                                          )}
                                          className={
                                            values?.EmailPreference
                                              ?.preferredEmail ===
                                            index + ""
                                              ? "d-none d-sm-none d-md-block disabled-link"
                                              : ""
                                          }
                                          onClick={() => {
                                            setFieldValue(
                                              `EmailPreference.preferences.${index}.IUDFlag`,
                                              IUD_FLAG_D
                                            );
                                          }}
                                        />
                                        <button
                                          type="button"
                                          className={
                                            values?.EmailPreference
                                              ?.preferredEmail ===
                                            index + ""
                                              ? "btn btn-delete d-none d-sm-block d-md-none disabled-link"
                                              : ""
                                          }
                                          onClick={() => {
                                            setFieldValue(
                                              `EmailPreference.preferences.${index}.IUDFlag`,
                                              IUD_FLAG_D
                                            );
                                          }}
                                        >
                                          <Text
                                            field={fields?.deleteButton ?? ""}
                                          />
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={`${ICON_PATH}/remove_circle-24px.svg`}
                                          alt={getFieldValue(
                                            fields,
                                            "lblAltDeleteBlackIcon",
                                            false
                                          )}
                                          className={
                                            values?.EmailPreference
                                              ?.preferredEmail ===
                                            index + ""
                                              ? "disabled-link"
                                              : "d-none d-sm-none d-md-block"
                                          }
                                          onClick={() => {
                                            ArrangeOrderofRow(index);
                                            setFieldValue(
                                              `EmailPreference.preferences.${index}.IUDFlag`,
                                              IUD_FLAG_D
                                            );
                                          }}
                                        />
                                        <button
                                          type="button"
                                          className="btn btn-delete d-none d-sm-block d-md-none"
                                          onClick={() => {
                                            ArrangeOrderofRow(index);
                                            setFieldValue(
                                              `EmailPreference.preferences.${index}.IUDFlag`,
                                              IUD_FLAG_D
                                            );
                                          }}
                                        >
                                          <Text
                                            field={fields?.deleteButton ?? ""}
                                          />
                                        </button>
                                      </>
                                    ))}
                                </>
                              )}
                            </div>
                          </div>
                          {values?.EmailPreference?.preferences?.length - 1 >
                            index && (
                            <hr className="d-none d-sm-block d-xl-none" />
                          )}
                        </div>
                      )
                    )
                  )}
                  {values?.EmailPreference?.preferences?.filter(
                    (o) => o.IUDFlag !== IUD_FLAG_D
                  ).length < 3 && (
                    <div className="row">
                      <div className="col-lg-6 col-md-8">
                        <button
                          type="button"
                          className={
                            pageEditable === true
                              ? "btn-add btn-disabled"
                              : "btn-add state-color-txt"
                          }
                          disabled={pageEditable}
                          onClick={AddRow}
                        >
                          <Text field={fields?.emailAddButton ?? ""} />
                        </button>
                        <span className="add-content">
                          <Text
                            field={fields?.emailAddButtonDescription ?? ""}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </FieldArray>
        </div>
      </Form>
    </>
  );
};
export default EmailPreference;
