import Axios from "./axios/provider";
import { SpinnerContext } from "../contexts/spinner.context";

/**
 * Get Allergies List
 *
 * @param {object} data
 * @returns
 */
// This method is used to get the communication preferences
export const ThirdPartyAppsList = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ThirdPartyApps,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
};
