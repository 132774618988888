import { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../contexts/error.context";
import * as getGraceDetails from "../services/MemberAPI";
import { MARKETPLACE } from "../define.constants";
import { AppContext } from "../contexts/app.context";

const useGraceDetails = () => {
  const { setError } = useContext(ErrorContext);
  const [graceStatus, setGraceStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let { memberLOB } = useContext(AppContext);
  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;
  const setData = (data) => {
    setGraceStatus(data.GraceDetails.GracePeriodStatus);
  };

  useEffect(() => {
    if (isMarketplaceMember) {
      setIsLoading(true);
      getGraceDetails
        .getGraceDetails()
        .then((response) => {
          setData(response?.ResultData);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setIsLoading(false);
        });
    }
  }, [isMarketplaceMember]);

  return { isLoading, graceStatus };
};

export { useGraceDetails };
