import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const STYLE = {
  modal: {
    fontSize: 12,
  },
  modalHeader: {
    width: "100%",
    borderBottom: "1px solid gray",
    fontSize: "18px",
    textAlign: "center",
    padding: "5px",
  },
  modalContent: {
    width: "100%",
    padding: "10px 5px",
  },
  modalActions: {
    width: "100%",
    padding: "10px 5px",
    margin: "auto",
    textAlign: "center",
  },
  modalClose: {
    cursor: "pointer",
    position: "absolute",
    display: "block",
    padding: "2px 5px",
    lineHeight: "20px",
    right: "-10px",
    top: "-10px",
    fontSize: "24px",
    background: "#ffffff",
    borderRadius: "18px",
    border: "1px solid #cfcece",
  },
};

const Modal = (props) => (
  <div>
    <Popup
      trigger={<button className="buttton"> Account Past Due</button>}
      modal
      nested
    >
      {(close) => (
        <div>
          <button style={STYLE.modalClose} onClick={close}>
            &times;
          </button>
          <div style={{ textAlign: "center" }}>
            <b>Account Past Due</b>
            <p>
              <Text field={props.fields.line1}></Text>
              <br />
              <Text field={props.fields.line2}></Text>
              <br />
              <Text field={props.fields.line3}></Text>
            </p>
            <center>
              <button className="btn btn-dark">Pay My Bill</button>
            </center>
          </div>
        </div>
      )}
    </Popup>
  </div>
);

export default Modal;
