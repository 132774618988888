import React from "react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";

const MedicationHistoryRecords = (props) => {
  const { fields, data, isMarketplaceMember } = props;
  return (
    <ul className="list-group d-none d-md-block" id="recordsToPrint">
      {data?.map((item, index) => (
        <li className="list-group-item " key={index}>
          <div className="row label-list">
            {isMarketplaceMember && (
              <>
                <DisplayItem
                  nameField={fields.lblMedicationName1}
                  outClass={"col-md-4 col-xl-4 details d-xl-block d-none"}
                  titleClass={"list-desc-title"}
                />
                <DisplayItem
                  nameField={fields.lblGeneric1}
                  outClass={"col-md-4 col-xl-4 details d-xl-block d-none"}
                  titleClass={"list-desc-title"}
                />
                <DisplayItem
                  nameField={fields.lblRefillsLeft1}
                  outClass={"col-md-4 col-xl-4 details d-xl-block d-none"}
                  titleClass={"list-desc-title "}
                />
              </>
            )}

            {!isMarketplaceMember && (
              <>
                <DisplayItem
                  nameField={fields.lblMedicationName1}
                  outClass={"col-md-4 col-xl-3 details d-xl-block d-none"}
                  titleClass={"list-desc-title"}
                />
                <DisplayItem
                  nameField={fields.lblGeneric1}
                  outClass={"col-md-4 col-xl-3 details d-xl-block d-none"}
                  titleClass={"list-desc-title"}
                />
                <DisplayItem
                  nameField={fields.lblRefillsLeft1}
                  outClass={"col-md-4 col-xl-3 details d-xl-block d-none"}
                  titleClass={"list-desc-title "}
                />

                <DisplayItem
                  nameField={fields.lblDateFilled}
                  outClass={"col-md-4 col-xl-3 details d-xl-block d-none"}
                  titleClass={"list-desc-title "}
                />
              </>
            )}
          </div>
          <div className="row">
            {isMarketplaceMember && (
              <>
                <DisplayItem
                  nameField={fields.lblMedicationName1}
                  valueField={item.MedicationName}
                  outClass={"col-md-12 col-xl-4 details d-md-flex d-xl-block"}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                />
                <DisplayItem
                  nameField={fields.lblGeneric1}
                  valueField={item.Generic}
                  outClass={"col-md-12 col-xl-4 details d-md-flex d-xl-block"}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                />
                <DisplayItem
                  nameField={fields.lblRefillsLeft1}
                  valueField={item.NumberofRefills}
                  outClass={"col-md-12 col-xl-4 details d-md-flex d-xl-block"}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                />
              </>
            )}
            {!isMarketplaceMember && (
              <>
                {" "}
                <DisplayItem
                  nameField={fields.lblMedicationName1}
                  valueField={item.MedicationName}
                  outClass={"col-md-12 col-xl-3 details d-md-flex d-xl-block"}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                />
                <DisplayItem
                  nameField={fields.lblGeneric1}
                  valueField={item.Generic}
                  outClass={"col-md-12 col-xl-3 details d-md-flex d-xl-block"}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                />
                <DisplayItem
                  nameField={fields.lblRefillsLeft1}
                  valueField={item.NumberofRefills}
                  outClass={"col-md-12 col-xl-3 details d-md-flex d-xl-block"}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                />
                <DisplayItem
                  nameField={fields.lblDateFilled}
                  valueField={item.DatePrecriptionFilled}
                  outClass={"col-md-12 col-xl-3 details d-md-flex d-xl-block"}
                  titleClass={"list-desc-title d-lg-block d-xl-none"}
                  detailsClass={"list-desc-details"}
                />
              </>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default MedicationHistoryRecords;
