import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH, MARKETPLACE } from "../../../define.constants";
import { withModalProvider } from "../../../hoc/withModalProvider";
import { ModalContext } from "../../../contexts/modal.context";
import Dependents from "./Dependents";
import { AppContext } from "../../../contexts/app.context";
import { SpinnerContext } from "../../../contexts/spinner.context";
import { ErrorContext } from "../../../contexts/error.context";
import { getOneildigitalid } from "../../../services/OneilIdCardAPI";
import { getIDCardType } from "../../../utils/helpers.js";
import * as IdCardAPI from "../../../services/IdCardAPI";
const qs = require("qs");

const PrintIdModal = (props) => {
  const { fields, showUserSwitch, dependents, yearSel } = props;
  const { openModal, closeModal } = useContext(ModalContext);
  const { memberRKSID, memberId, memberLOB, MemberStateName } =
    useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const { setSpinner } = useContext(SpinnerContext);
  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;
  const handlePrintIDCard = (sd) => {
    setSpinner(true);
    if (isMarketplaceMember) {
      getOneildigitalid(
        MemberStateName,
        memberLOB.toLowerCase(),
        sd,
        "Print",
        yearSel.toString()
      )
        .then((response) => {
          closeModal();
          if (response?.content) {
            let byteChars = window.atob(response.content);
            let byteNum = new Array(byteChars.length);
            for (let i = 0; i < byteChars.length; i++) {
              byteNum[i] = byteChars.charCodeAt(i);
            }
            let byteAr = new Uint8Array(byteNum);
            let file = new Blob([byteAr], {
              type: "application/pdf;base64",
            });
            let filePath = URL.createObjectURL(file);
            window.open(filePath);
          } else {
            setError({ err: { statusCode: response.ResultCode } });
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    } else {
      let request = {
        BbtnId: getIDCardType(memberLOB, MemberStateName),
        MemIDList: sd,
        ReasonId: null,
        Reason: "",
        Year: yearSel,
      };
      IdCardAPI.getMemberIdCardPdf(
        request,
        qs.stringify(request, { addQueryPrefix: true })
      )
        .then((response) => {
          closeModal();
          if (response?.ResultData?.Result_PDF) {
            let byteChars = window.atob(response.ResultData.Result_PDF);
            let byteNum = new Array(byteChars.length);
            for (let i = 0; i < byteChars.length; i++) {
              byteNum[i] = byteChars.charCodeAt(i);
            }
            let byteAr = new Uint8Array(byteNum);
            let file = new Blob([byteAr], {
              type: "application/pdf;base64",
            });
            let filePath = URL.createObjectURL(file);
            window.open(filePath);
          } else {
            setError({ err: { statusCode: response.ResultCode } });
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    }
  };
  return (
    <button
      type="button"
      className="btn btn-default"
      disabled={
        Number(yearSel) > 2021 &&
        props.details?.PCPName_Current === null &&
        props.details?.PCPName_New === null
      }
      onClick={() =>
        isMarketplaceMember && showUserSwitch && dependents?.length > 0
          ? openModal({
              header: (
                <div className="request-new-id-header">
                  <Text field={fields.PopupPrintNewIDCard} />
                </div>
              ),
              content: (
                <Dependents
                  {...props}
                  onPrint={handlePrintIDCard}
                  memberRKSID={memberRKSID}
                  isMarketplaceMember={isMarketplaceMember}
                />
              ),
              version: 1,
            })
          : isMarketplaceMember
          ? handlePrintIDCard([memberId])
          : handlePrintIDCard(memberRKSID)
      }
    >
      <img
        className="state-color"
        src={`${ICON_PATH}/Print.svg`}
        aria-hidden="true"
        alt={fields.btnPrint?.value}
      />
      <span className="space-btw">
        <Text field={fields.btnPrint} />
      </span>
    </button>
  );
};

export default withModalProvider(PrintIdModal);
