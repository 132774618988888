import React from "react";
import { renderStatusCss, renderStatus, renderHelperText } from "./Helper";
import { ICON_PATH } from "../../define.constants";

const Status = (props) => {
  const { status, fields, outerClass } = props;
  const helperText = renderHelperText(status, fields);

  return (
    <div className={`col-xl-2 col-md-4 col-6 history-details ${outerClass}`}>
      <div
        className={`history-status ${renderStatusCss(status)}`}
        style={{ height: "auto" }}
      >
        {renderStatus(status, fields)}
        {helperText != "" ? (
          <div className="font-weight-500 custom-tooltip-spending">
            <p className="custom-tooltip-spending-text">{helperText}</p>
            <img
              className="img-molina-info-teal"
              src={`${ICON_PATH}/info-24px.svg`}
              alt={status}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Status;
