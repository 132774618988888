import React, { useContext } from "react";
import {
  IMAGE_PATH,
  MEDICAID,
  MEDICARE,
} from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../../../contexts/app.context";

const ViewIdCardMedicaidMA = ({
  MemberStateName,
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const { memberLOB } = useContext(AppContext);
  const isMAMedicare = memberLOB.toLowerCase() === MEDICARE;
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/MA/Medicaid/MA-Medicaid-IDCard.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td>
                  {result?.IdCardValues?.LOB.toLowerCase() === MEDICAID
                    ? result?.IdCardValues?.LOB
                    : idCard?.PlanName}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields?.lblMemberNameMAMedicaid} />
                  </b>
                  {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td height="3" colSpan="2"></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields?.lblMemberIdMAMedicaid} />
                  </b>
                  {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td height="15" colSpan="2"></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields?.lblCareCoordinatorPhone} />
                  </b>
                  {isMAMedicare
                    ? result?.PortalDeductiblesMappingDetails[0]?.MS_No
                    : result?.IdCardValues?.CareCoordinatorPhone}
                </td>
              </tr>
              <tr>
                <td height="3" colSpan="2"></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields?.lblPCPGrpOrName} />
                  </b>
                  {idCard?.PCPName_Current}
                </td>
              </tr>
              <tr>
                <td height="3" colSpan="2"></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields?.lblPCPPhone} />
                  </b>
                  {idCard?.PCPPhone_Current}
                </td>
              </tr>
              <tr>
                <td colSpan="2" />
                <td colSpan="2" style={{ paddingLeft: "10px" }}>
                  <b>
                    <Text field={fields?.lblMARxBin} />
                  </b>
                  {isMAMedicare
                    ? result?.PortalDeductiblesMappingDetails[0]?.RxBIN
                    : result?.IdCardValues?.RxBIN}
                </td>
              </tr>
              <tr>
                <td height="5" colSpan="2"></td>
              </tr>
              <tr>
                <td colSpan="2" />
                <td colSpan="2" style={{ paddingLeft: "10px" }}>
                  <b>
                    <Text field={fields?.lblMAPCN} />
                  </b>
                  {isMAMedicare
                    ? result?.PortalDeductiblesMappingDetails[0]?.RxPCN
                    : result?.IdCardValues?.RxPCN}
                </td>
              </tr>
              <tr>
                <td height="5" colSpan="2"></td>
              </tr>
              <tr>
                <td colSpan="2" />
                <td colSpan="2" style={{ paddingLeft: "10px" }}>
                  <b>
                    <Text field={fields?.lblMAGroup} />
                  </b>
                  {isMAMedicare
                    ? result?.PortalDeductiblesMappingDetails[0]?.RxGroup
                    : result?.IdCardValues?.RxGRP}
                </td>
              </tr>
              <tr>
                <td height="5" colSpan="2"></td>
              </tr>
              {/* <tr>
                <td colSpan="2" />
                <td colSpan="2" style={{ paddingLeft: "10px" }}>
                  <b>
                    <Text field={fields?.lblMARxID} />
                  </b>
                  {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/FL/Medicaid/Member-ID-Card-Duals-back-FL.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
            >
              <tbody>
                <tr>
                  <td height="25" colSpan="2"></td>
                </tr>
                <tr>
                  <td>
                    <Text field={fields?.lblMANAL} />
                  </td>
                </tr>
                <tr>
                  <td height="30" colSpan="2"></td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblMemberServicesMAMcaid} />
                    </b>
                    <Text field={fields?.lblMemberServicesValueMA} />
                  </td>
                </tr>
                <tr>
                  <td height="3" colSpan="2"></td>
                </tr>
                <tr>
                  <td>
                    <Text field={fields?.lbl24hoursNurseAdviceMA} />
                  </td>
                </tr>
                <tr>
                  <td height="30" colSpan="2"></td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblMaMedicaidWebsite} />
                    </b>
                    <Text field={fields?.lblMaMedicaidWebsiteVal} />
                  </td>
                </tr>
                <tr>
                  <td height="30" colSpan="2"></td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblSendClaimsTo} />
                    </b>
                    <Text field={fields?.lblSendClaimsToValue} />
                  </td>
                </tr>
                <tr>
                  <td height="3" colSpan="2"></td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields.lblClaimInquiry} />
                    </b>
                    <Text field={fields?.lblClaimInquiryValue} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="cust-padding">
              <b>
                <Text field={fields.lblIDBackComplaintContactData} />
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicaidMA;
