import React, { useEffect, useRef } from "react";
import PersonalInformation from "./PersonalInformation";
import { useLocation } from "react-router-dom";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { GLOBAL_VARIABLE } from "../../define.constants";

const AccountSettingsPayerWrapper = (props) => {
  const { fields, setActiveTab, tabOptions } = props;
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  let currentpath = useLocation();
  useEffect(() => {
    if (currentpath?.hash === "#LangNavSection") {
      document.getElementById("LangNavSection").scrollIntoView();
    }
  }, [currentpath]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="formsDocs"
            role="tabpanel"
            aria-labelledby="tab-1"
          >
            <div className="row no-tab-title">
              <div className="col-md-12">
                <ul className="list-group">
                  <PersonalInformation fields={fields} />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsPayerWrapper;
