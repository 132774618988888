import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIDCardMedicaidTXChipRSA = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/TX/Medicaid/Molina-CHIP-Perinate-ID-Card-new.png)`,
          }}
          className="background-idcard"
          id="containerTX"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="table-font-size tx-member-idcard"
          >
            <tbody>
              <tr>
                <td>
                  <div className="clearfix">
                    <div className="float-start chipRSAleft">
                      <ul className="list-unstyled TXmrgn">
                        <li>
                          <b>
                            <Text field={fields?.lblMember} />
                          </b>
                          &nbsp; {idCard?.Member_Name}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblID} />
                          </b>
                          &nbsp; {idCard?.OriginalMemberProfile?.Number}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCDOB} />
                          </b>
                          &nbsp;{idCard?.Member_DOB}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCPCPName} />
                          </b>
                          &nbsp;{idCard?.PCPName_Current}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCPCPPhone} />
                          </b>{" "}
                          <br></br>
                          {idCard?.PCPPhone_Current}
                        </li>
                        <li>
                          <Text field={fields?.lblPCPEffectiveDateExpanded} />
                          &nbsp;{idCard?.PCPEffectiveDate}
                        </li>
                      </ul>
                      <div className="TXblock">
                        <ul className="list-unstyled TXmrgn">
                          <li>
                            <Text field={fields?.lblMMIS} />
                            &nbsp; {idCard?.OriginalMemberProfile?.Number}
                          </li>
                          <li>
                            <Text field={fields?.lblEffectiveDate} />
                            &nbsp;{idCard?.MemberEffectiveDate}
                          </li>
                          <li>
                            <Text field={fields?.lblIssueDate} />
                            &nbsp;{formatToday()}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="float-end chipRSAright">
                      <div className="chipTopHeight"></div>
                      <div className="vborder">
                        <ul className="list-unstyled TXmrgn">
                          <li>
                            <b>
                              <Text field={fields?.lblBin} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxBIN}
                          </li>
                          <li>
                            <b>
                              <Text field={fields?.lblGroup} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxGRP}
                          </li>
                          <li>
                            <b>
                              <Text field={fields?.lblPCN} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxPCN}
                          </li>
                          <li>
                            <Text field={fields?.lblCVSCaremark} />
                          </li>
                          <li>
                            <Text field={fields?.lblPrevHealth} />
                            &nbsp; {result?.IdCardValues?.Prev_Health}
                          </li>
                          <li>
                            <Text field={fields?.lblOfficeVisit} />
                            &nbsp; {result?.IdCardValues?.Office_Visit}
                          </li>
                          <li>
                            <Text field={fields?.lblNonEmergentER} />
                            &nbsp; {result?.IdCardValues?.Non_Emergent_ER}
                          </li>
                          <li>
                            <Text field={fields?.lblInpatient} />
                            &nbsp; {result?.IdCardValues?.Inpatient}
                          </li>
                          <li>
                            <Text field={fields?.lblGenericDrug} />
                            &nbsp; {result?.IdCardValues?.Generic_Drug}
                          </li>
                          <li>
                            <Text field={fields?.lblBrandDrug} />
                            &nbsp; {result?.IdCardValues?.BrandDrug}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/TX/Medicaid/Molina-CHIP-Perinate-ID-Card-back.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
              className="table-font-size-back"
            >
              <tr>
                <td>
                  <label>
                    <u>
                      <b>
                        <Text field={fields?.lblSCBackMembers} />
                      </b>
                    </u>
                    <Text field={fields?.lblBackMemberRSAValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <u>
                      <Text field={fields?.lblBackEmergency} />
                    </u>
                    &nbsp;
                    <Text field={fields?.lblBackEmergencyChipValue} />
                  </label>
                </td>
              </tr>
              <label>
                <u>
                  <Text field={fields?.lblBackBehavioralChip} />
                </u>
                &nbsp; <Text field={fields?.lblBackBehavioralChipRSAValue} />
              </label>
              <tr>
                <td>
                  <label>
                    <u>
                      <b>
                        <Text field={fields?.lblSCBackMembersSpanish} />
                      </b>
                    </u>
                    &nbsp;{" "}
                    <Text field={fields?.lblBackMemberChipRSASpanishValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <Text field={fields?.lblBackEmergencySpanish} />
                    &nbsp;
                    <Text field={fields?.lblBackEmergencySpanishValues} />
                  </label>
                </td>
              </tr>
              <label>
                <u>
                  <Text field={fields?.lblBackBehavioralChipSpanish} />
                </u>
                &nbsp;{" "}
                <Text field={fields?.lblBackBehavioralChipRSASpanishValue} />
              </label>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblBackPRACTITIONERS} />
                    </b>
                    <RichText field={fields?.lblBackPractitionerChipRSAValue} />
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardMedicaidTXChipRSA;
