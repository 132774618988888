import axios from "axios";
import { ENGLISH_LANG_PATH, SPANISH_LANG_PATH } from "../../define.constants";
import { serverConstants } from "../../utils/constants";

// Set the defaults for headers and other things
// axios.defaults.baseURL = "http://localhost:3000/";
// axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";

// Create an instance using the config defaults provided by the library
// At this point the timeout config value is `0` as is the default for the library
axios.create({
  withCredentials: true,
});

/**
 *
 * @param {string} resource
 * @returns
 */
const get = (resource, config = {}) => {
  return axios.get(resource, config).then(handleResponse).catch(handleError);
};

/**
 *
 * @param {string} resource
 * @param {object} data
 * @returns
 */
const post = (resource, data, config = {}) => {
  return axios
    .post(resource, data, config)
    .then(handleResponse)
    .catch(handleError);
};

const handleResponse = (response) => {
  if (response.results) {
    return response.results;
  }
  if (response.data) {
    return response.data;
  }
  return response;
};

const handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      window.location.pathname !== serverConstants.loginPage &&
      window.location.pathname !== SPANISH_LANG_PATH &&
      window.location.pathname !== ENGLISH_LANG_PATH
    ) {
      window.location.href = serverConstants.loginPage;
    }
    throw {
      statusCode: error.response?.status,
      data: error.response?.data,
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
};

export default {
  get,
  post,
};
