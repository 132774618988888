import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

const RegistrationAccounthelpLayout = (props) => {
  console.log(props, "LayoutProps");
  const { rendering, fields } = props;

  return (
    <div className="Registration row">
      <div className="password-recovery col-12">
        <section className="d-flex justify-content-center">
          <div className="card">
            <div className="card-body">
              <Placeholder
                name="jss-reg-accounthelp-layout"
                rendering={rendering}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RegistrationAccounthelpLayout;
