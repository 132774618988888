import React, { useContext, useState } from "react";
import {
  Text,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { getFormattedDate } from "../../utils/helpers";
import {
  IMAGE_PATH,
  SUPPORT_USER,
  TERMED_MEMBER,
  TX_MEDICAID_STAR_PLUS,
  TX,
} from "../../define.constants";
import { AppContext } from "./../../contexts/app.context";

const HippaPopUpContentTX = (props) => {
  const {
    fields,
    formValues,
    value,
    setShowConsentFormState,
    //entityNameList,
    consentCheckBoxRef,
    highlightAuthCheckBox,
    handleAuthorizationTXSubmit,
  } = props;
  const [allowSubmit, setAllowSubmit] = useState(false);

  const { memberName, profileList, memberStateCode, programId } =
    useContext(AppContext);
  const currentDate = new Date();
  let checkboxBehaviourMental = "";
  let checkboxAssessment = "";
  let checkboxServicePlan = "";
  let checkboxSubstanceUseandAbuse = "";
  if (formValues && formValues?.MemberConsentInf) {
    checkboxBehaviourMental =
      formValues?.MemberConsentInf[0].BehavioralandMentalHealth;
    checkboxAssessment = formValues?.MemberConsentInf[0].Assessment;

    checkboxServicePlan = formValues?.MemberConsentInf[0].ServicePlan;
    checkboxSubstanceUseandAbuse =
      formValues?.MemberConsentInf[0].SubstanceUseandAbuse;
  }

  let authExpiryDate = new Date(currentDate);
  authExpiryDate.setFullYear(authExpiryDate.getFullYear() + 1);

  const authExpiryDateString = getFormattedDate(
    authExpiryDate.toLocaleString(),
    "mm/dd/yyyy hh:mm:ss t"
  );
  const signatureDateString = getFormattedDate(
    currentDate.toLocaleString(),
    "mm/dd/yyyy hh:mm:ss t"
  );
  return (
    <>
      <div className="mycoverage-modal list-group-item">
        <div className="update-header" style={{ padding: "8px" }}>
          <div>
            <picture>
              <img
                src={`${IMAGE_PATH}/CT_Hippa_Logo.png`}
                style={{ filter: "none" }}
              />
            </picture>
            <div className="align-authorization-content">
              <b>
                <h5>
                  <Text field={fields?.MC_ICTContactInfoHIPAALink ?? ""} />
                </h5>
              </b>
            </div>
          </div>

          <div
            className="service-disc"
            style={{
              color: "#454545",
              font: "normal normal normal .8rem/1.04rem Arial",
              letterSpacing: ".24px",
              marginBottom: "8px",
              //marginTop: "2rem",
              wordBreak: "keep-all",
            }}
          >
            <br />
            <div>
              <div className="row">
                <div
                  className="col"
                  //   style={{ border: "1px solid #999", padding: "5px" }}
                >
                  <b>
                    <Text field={fields?.MC_MemberName_TX ?? ""} />
                  </b>{" "}
                  {memberName ?? ""}
                </div>
                <div
                  className="col"
                  //   style={{ border: "1px solid #999", padding: "5px" }}
                >
                  <b>
                    <Text field={fields?.MC_MemberID ?? ""} />
                  </b>{" "}
                  {profileList?.MemberId ?? ""}
                </div>
              </div>
              <div className="row">
                <div
                  className="col"
                  //   style={{ border: "1px solid #999", padding: "5px" }}
                >
                  <b>
                    <Text field={fields?.MC_MemberAddress ?? ""} />
                  </b>{" "}
                  {profileList?.MailingAddress ?? ""}
                </div>

                <div
                  className="col"
                  //   style={{ border: "1px solid #999", padding: "5px" }}
                >
                  <b>
                    <Text field={fields?.MC_DateofBirth ?? ""} />
                  </b>{" "}
                  {profileList?.DateOfBirth ?? ""}
                </div>
              </div>
              <div className="row">
                <div
                  className="col"
                  //   style={{ border: "1px solid #999", padding: "5px" }}
                >
                  <b>
                    <Text field={fields?.MC_City_State_Zip ?? ""} />
                  </b>{" "}
                  {profileList?.City ?? ""}/{profileList?.State ?? ""}/
                  {profileList?.Zip ?? ""}
                </div>

                <div className="col">
                  <b>
                    <Text field={fields?.MC_Telephone ?? ""} />
                  </b>{" "}
                  {profileList?.HomePhone ?? ""}
                </div>
              </div>
            </div>
            <br />

            <div>
              <b>
                <Text field={fields?.MC_Hippa_Auth_TX ?? ""} />
              </b>
            </div>
            <br />

            <div>
              <RichText field={fields?.MC_Hippa_Claus_1_TX ?? ""} />
              <div className="margin-left-points">
                <Text field={fields?.MC_Hippa_Clauus_1_TX_Value ?? ""} />
              </div>
            </div>
            <br />

            <div>
              <RichText field={fields?.MC_Hippa_Claus_2_TX ?? ""} />
              <span className="margin-left-points">
                <Text field={fields?.Hippa_PCPHeading ?? ""} />
              </span>
            </div>
            <br />
            <div ref={consentCheckBoxRef}>
              <RichText field={fields?.MC_Hippa_Claus_3_TX ?? ""} />

              {checkboxBehaviourMental && (
                <span className="margin-left-points">
                  <Text
                    field={
                      fields?.MC_ColumnHeader_BehavioralandMentalHealth ?? ""
                    }
                  />
                  , &nbsp;
                </span>
              )}
              {checkboxSubstanceUseandAbuse && (
                <span>
                  <Text
                    field={fields?.MC_ColumnHeader_SubstanceUseandAbuse ?? ""}
                  />
                  , &nbsp;
                </span>
              )}
              {checkboxAssessment && (
                <span>
                  <Text field={fields?.MC_ColumnHeader_Assessment ?? ""} />,
                  &nbsp;
                </span>
              )}
              {checkboxServicePlan && (
                <span>
                  <Text field={fields?.MC_ColumnHeader_ServicePlan ?? ""} />,
                  &nbsp;
                </span>
              )}
            </div>
            <br />
            <div>
              <RichText field={fields?.MC_Hippa_Claus_3_1_TX ?? ""} />
            </div>
            <ul style={{ marginLeft: "4%", listStyleType: "disc" }}>
              <li>
                <div>
                  <Text field={fields?.MC_Hippa_Claus_3_2 ?? ""} />
                </div>
              </li>
              <li>
                <div>
                  <Text field={fields?.MC_Hippa_Claus_3_3 ?? ""} />
                </div>
              </li>
              <li>
                <div>
                  <Text field={fields?.MC_Hippa_Claus_3_TX_3 ?? ""} />
                </div>
              </li>
              <li>
                <div>
                  <Text field={fields?.MC_Hippa_Claus_3_TX_4 ?? ""} />
                </div>
              </li>
              <li>
                <div>
                  <Text field={fields?.MC_Hippa_Claus_3_TX_5 ?? ""} />
                </div>
              </li>
            </ul>
            <div>
              <RichText field={fields?.MC_Hippa_Claus_4_TX ?? ""} />
              <div className="margin-left-points">
                <Text field={fields?.MC_Hippa_Claus_4_TX_Value ?? ""} />
              </div>
            </div>
            <br />
            <div>
              <RichText field={fields?.MC_Hippa_Claus_5_TX ?? ""} />
            </div>
            <br />

            <div style={{ paddingLeft: "40px" }}>
              <RichText field={fields?.MC_Hippa_Claus_6_a_TX ?? ""} />
            </div>

            <div style={{ paddingLeft: "40px" }}>
              <RichText field={fields?.MC_Hippa_Claus_6_b_TX ?? ""} />
            </div>

            <div style={{ paddingLeft: "40px" }}>
              <RichText field={fields?.MC_Hippa_Claus_6_c_TX ?? ""} />
            </div>

            <ul style={{ marginLeft: "4%", listStyleType: "disc" }}>
              <li>
                <div>
                  {" "}
                  <Text field={fields?.MC_Hippa_Claus_6_c_1_TX ?? ""} />
                </div>
              </li>
              <li>
                <div>
                  {" "}
                  <Text field={fields?.MC_Hippa_Claus_6_c_2_TX ?? ""} />
                </div>
              </li>
              <li>
                <div>
                  <Text field={fields?.MC_Hippa_Claus_6_c_3_TX ?? ""} />
                </div>
              </li>
            </ul>

            {/* <div style={{ paddingLeft: "60px" }}>
              <Text field={fields?.MC_Hippa_Claus_6_c_4 ?? ""} />
            </div>
            <br /> */}

            <div style={{ paddingLeft: "40px" }}>
              <RichText field={fields?.MC_Hippa_Claus_6_d_TX ?? ""} />
            </div>

            <div style={{ paddingLeft: "40px" }}>
              <RichText field={fields?.MC_Hippa_Claus_6_e_TX ?? ""} />
            </div>

            <div style={{ paddingLeft: "40px" }}>
              <RichText field={fields?.MC_Hippa_Claus_6_f_TX ?? ""} />
            </div>

            <br />
            <div>
              <div className="row">
                <div className="col-md-5">
                  <RichText field={fields?.MC_Hippa_Claus_7_TX ?? ""} />
                </div>
                <div className="col-md-4">
                  <u>{authExpiryDateString}</u>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div style={{ paddingLeft: "20px" }}>
                    <i>
                      <Text field={fields?.MC_Hippa_Claus_7_1_TX ?? ""} />
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <div>
                <div className="row">
                  <div className="col-md-5" style={{ padding: "5px" }}>
                    {formValues.ParentName != ""
                      ? formValues?.ParentName
                      : memberName}
                    &nbsp;
                    <hr />
                  </div>
                  <div className="col-md-5 offset-1" style={{ padding: "5px" }}>
                    {" "}
                    {signatureDateString}
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ padding: "5px" }}>
                    <Text field={fields?.MC_Hippa_Claus_7_Signature ?? ""} />
                  </div>
                  <div className="col" style={{ padding: "5px" }}>
                    <Text field={fields?.MC_Hippa_Claus_7_Date ?? ""} />
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-md-5" style={{ padding: "5px" }}>
                    {memberName ?? ""}
                    <hr />
                  </div>
                  <div className="col-md-5 offset-1" style={{ padding: "5px" }}>
                    &nbsp;
                  </div>
                </div>

                <div className="row">
                  <div className="col" style={{ padding: "5px" }}>
                    <Text
                      field={fields?.MC_Hippa_Claus_7_Signature_1_TX ?? ""}
                    />
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br />

            <div>
              <div className="row">
                <div className="col" style={{ padding: "5px" }}>
                  <b>
                    <Text field={fields?.MC_Hippa_Claus_7_2_TX ?? ""} />
                  </b>
                </div>
                <div className="col">
                  <p id="txtMCParentName_MC">{formValues?.ParentName ?? ""}</p>
                  <hr style={{ width: "90%" }} />
                  <u></u>
                </div>
              </div>
            </div>

            <br />
            <br />

            <div>
              <div>
                <div className="row">
                  <div className="col" style={{ padding: "5px" }}>
                    <Text field={fields?.MC_Hippa_Claus_8 ?? ""} />
                  </div>
                  <div className="col" style={{ padding: "5px" }}>
                    <input
                      type="checkbox"
                      id="ChkMCParent_H"
                      name="chkMIHIRelation"
                      //disabled="disabled"
                      checked={formValues?.Parent}
                    />
                    <span>
                      {" "}
                      <Text field={fields?.MC_Hippa_Claus_8_1 ?? ""} />
                    </span>
                  </div>
                  <div className="col" style={{ padding: "5px" }}>
                    <input
                      type="checkbox"
                      id="ChkMCGuardian_H"
                      name="chkMIHIRelation"
                      //disabled="disabled"
                      checked={formValues?.Guardian}
                    />
                    <span>
                      {" "}
                      <Text field={fields?.MC_Hippa_Claus_8_2 ?? ""} />
                    </span>
                  </div>
                  <div className="col" style={{ padding: "5px" }}>
                    <input
                      type="checkbox"
                      id="ChkMCAuthorRepre_H"
                      name="chkMIHIRelation"
                      //disabled="disabled"
                      checked={formValues?.AuthorizedRepresentative}
                    />
                    <span>
                      {" "}
                      <Text field={fields?.MC_Hippa_Claus_8_3 ?? ""} />
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ padding: "5px" }}>
                    <input
                      type="checkbox"
                      id="ChkMCOther_H"
                      name="chkMIOther"
                      //disabled="disabled"
                      checked={formValues?.Other}
                    />
                    <span>
                      {" "}
                      <Text field={fields?.MC_Hippa_Claus_8_4_TX ?? ""} />
                    </span>
                  </div>
                  <div className="col" style={{ padding: "5px" }}>
                    <p id="txtMCOtherRelatioinship_MC">
                      {formValues?.Relationship ?? ""}
                    </p>
                    <hr style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br />
            <div>
              <b>
                <Text field={fields?.MC_Hippa_Claus_7_4 ?? ""} />
              </b>
            </div>
            <br />
            <br />

            <div>
              <b>
                <Text field={fields?.MC_Hippa_Claus_7_5_TX ?? ""} />
              </b>
            </div>
            <br />
            <br />
          </div>
          <div>
            <button
              className="btn btn-primary m-3 w-md-35 w-sm-100"
              onClick={() => {
                handleAuthorizationTXSubmit(value);
              }}
              //disabled={}
            >
              <Text field={fields?.Submit ?? ""} />
            </button>

            <button
              className="btn btn-default m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100 "
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setShowConsentFormState(false);
              }}
            >
              <Text field={fields?.Cancel ?? ""} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HippaPopUpContentTX;
