import React, { useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { DUALS, MEDICARE } from "../../define.constants";

import { AppContext } from "../../contexts/app.context";
import { GLOBAL_VARIABLE } from "../../define.constants";

const MyWellnessMyHealthyRewards = (props) => {
  let { memberLOB } = useContext(AppContext);
  const isMedicare = memberLOB.toLowerCase() === MEDICARE;
  const isDuals = memberLOB.toLowerCase() === DUALS;
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="tab-content" id="myTabContent">
            <div id="healthyrewards" role="tabpanel" aria-labelledby="tab-1">
              <div className="row no-tab-title">
                {(isMedicare || isDuals) && (
                  <>
                    <div className="col-md-12 no-assessment-records">
                      <Text
                        field={
                          props?.fields?.disclaimerForHealthyRewardsMedicare
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyWellnessMyHealthyRewards;
