import React, { useState, isValidElement, useContext } from "react";
import { ErrorContext } from "../contexts/error.context";

const useModal = () => {
  const { resetError } = useContext(ErrorContext);
  const [isOpen, setIsOpen] = useState(false);
  const [header, setHeader] = useState("");
  const [content, setContent] = useState("");
  const [footer, setFooter] = useState("");
  const [version, setVersion] = useState(0);

  const openModal = (
    { header = "", content = "", footer = "", version = 0 } = ""
  ) => {
    setIsOpen(true);
    if (isValidElement(header)) {
      setHeader(header);
    }
    if (isValidElement(content)) {
      setContent(content);
    }
    if (isValidElement(footer)) {
      setFooter(footer);
    }
    setVersion(version);
  };
  const closeModal = () => {
    resetError(); //reset error message whenever popup is closed.
    setIsOpen(false);
  };

  return {
    isOpen,
    header,
    content,
    footer,
    openModal,
    closeModal,
    version,
  };
};

export { useModal };
