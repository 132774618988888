import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "./../../contexts/app.context";
import {
  getStateConfiguration,
  isStateUnderFFMCategory,
  isStateUnderSBMCategory,
} from "./../../utils/helpers";
import { Link, Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { ModalContext } from "./../../contexts/modal.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  SUPPORT_USER,
  TERMED_MEMBER,
  GLOBAL_VARIABLE,
} from "../../define.constants";

function AccountSettingsPersonalConfirmPopUp(props) {
  const { openModal, closeModal } = useContext(ModalContext);
  let { memberLOB, MemberStateName, isDependentLoggedIn, isTermedMember } =
    useContext(AppContext);
  const { redirectToSSO } = useContext(SSORedirectContext);
  const fields = props?.fields;
  const PersonalInfoPopUp = getStateConfiguration(
    fields,
    MemberStateName,
    memberLOB
  );
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  const [hiddenButton, setHiddenButton] = useState(false);

  useEffect(() => {
    if (isDependentLoggedIn === true) {
      setHiddenButton(true);
    }
  }, [isDependentLoggedIn]);

  const RedirectionLink = () => {
    if (isStateUnderSBMCategory(MemberStateName)) {
      return (
        <WhoIsAuthorized
          authorize={{
            [SUPPORT_USER]: true,
            [TERMED_MEMBER]: false,
          }}
          handleClick={closeModal}
        >
          {({ handleClick, disabledClass }) => {
            return (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleClick}
              >
                <Link
                  className="redirect"
                  target="blank"
                  field={PersonalInfoPopUp?.fields.Continue}
                />
              </button>
            );
          }}
        </WhoIsAuthorized>
      );
    } else if (isStateUnderFFMCategory(MemberStateName)) {
      return (
        <WhoIsAuthorized
          authorize={{
            [SUPPORT_USER]: false,
            [TERMED_MEMBER]: false,
          }}
          handleClick={(e) => {
            redirectToSSO({
              e,
              url: constants.apigeeURLs.GetHealthSherpaSSORedirect,
              data: {
                RequestSource: "MobileWeb",
              },
            });
            setTimeout(() => {
              closeModal(e);
            }, 200);
          }}
        >
          {({ handleClick, disabledClass }) => {
            return (
              <button
                type="button"
                className={`btn btn-primary ${disabledClass}`.trim()}
                onClick={handleClick}
              >
                {getFieldValue(PersonalInfoPopUp?.fields, "Continue").text}
              </button>
            );
          }}
        </WhoIsAuthorized>
      );
    } else {
      return (
        <button type="submit" className="btn btn-primary" onClick={closeModal}>
          {PersonalInfoPopUp?.fields?.Continue?.value.text}
        </button>
      );
    }
  };
  const PersonalInfoPopUpcontent = (
    <div className="account-modal">
      <div className="update-header">
        <h5>
          <Text field={PersonalInfoPopUp?.fields?.Title} />
        </h5>
        <>
          <p>
            <Text field={PersonalInfoPopUp?.fields?.Description} />
          </p>
        </>
      </div>
      <div className="account-btns">
        <WhoIsAuthorized
          authorize={{
            [SUPPORT_USER]: false,
            [TERMED_MEMBER]: false,
          }}
          handleClick={closeModal}
        >
          {({ handleClick, disabledClass }) => {
            return (
              <button
                type="button"
                className="btn btn-default"
                data-mdb-dismiss="modal"
                onClick={handleClick}
              >
                {PersonalInfoPopUp?.fields?.Cancel?.value.text}
              </button>
            );
          }}
        </WhoIsAuthorized>
        <RedirectionLink />
      </div>
    </div>
  );

  return (
    <div>
      {hiddenButton === false && (
        <button
          type="button"
          className={
            isTermedMember
              ? " btn btn-primary update-button disabled"
              : "btn btn-primary update-button"
          }
          onClick={() =>
            openModal({
              content: PersonalInfoPopUpcontent,
              version: 1,
            })
          }
        >
          <span>
            <Text field={PersonalInfoPopUp?.fields?.UpdateButtonText ?? ""} />
          </span>
        </button>
      )}
    </div>
  );
}

export default withModalProvider(AccountSettingsPersonalConfirmPopUp);
