import React from "react";
import PropTypes from "prop-types";

const InlineFormError = ({ children }) => {
  return <>{<p className="text-danger">{children}</p>}</>;
};

InlineFormError.propTypes = {
  children: PropTypes.string.isRequired,
};

export default InlineFormError;
