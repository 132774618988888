import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { MultiStepContext } from "../../contexts/Steps.context";
import { withStepContext } from "../../hoc/withStepContext";
import MemberInformation from "./MemberInformation";
import UserIdnPwd from "./UserIdnPwd";
import Verify from "./Verify";
import ActivateAccount from "./ActivateAccount";
import { useCalendar } from "../../hooks/useCalendar";
import FooterInformation from "./FooterInformation";
import { GLOBAL_VARIABLE } from "../../define.constants";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import * as RegistrationAPI from "../../services/RegistrationAPI";
const RegistrationMemberRegistration = (props) => {
  const { currentStep, finalData } = useContext(MultiStepContext);
  const { selectedDate, setSelectedDate } = useCalendar();
  GLOBAL_VARIABLE.lblSelectPlaceholder = getFieldValue(
    props?.fields,
    "selectPlaceholder",
    false
  );
  GLOBAL_VARIABLE.lblSelectDate = getFieldValue(
    props.fields,
    "selectDate",
    false
  );

  const showStep = (step) => {
    switch (step) {
      case 1:
        return (
          <MemberInformation
            fields={props.fields}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        );
      case 2:
        return <UserIdnPwd fields={props.fields} />;
      case 3:
        return <Verify fields={props.fields} />;
      case 4:
        return <ActivateAccount fields={props.fields} />;
    }
  };
  return (
    <>
      {showStep(currentStep)}
      <div>
        <FooterInformation fields={props.fields} />
      </div>
    </>
  );
};

export default withStepContext(RegistrationMemberRegistration);
