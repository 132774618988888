import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as HealthRecordAPI from "../services/HeathRecordAPI";
import { AppContext } from "../contexts/app.context";
import { MY_WELLNESS_GET_ASSESSMENTS } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";

const useMemberConsentGetEntityNameListAPI = () => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const { MemberStateName, memberLOB, memberRKSID } = useContext(AppContext);
  const [entityNameList, setEntityNameList] = useState([]);
  const [isApiResponded1, setIsApiResponded] = useState(false);
  const setData = (response) => {
    setEntityNameList(response);
  };
  useEffect(() => {
    setInnerSpinner(true, MY_WELLNESS_GET_ASSESSMENTS);
    HealthRecordAPI.MemberConsentGetEntityNameList()
      .then((response) => {
        setIsApiResponded(true);
        setData(response?.ResultData);
      })
      .catch(function (err) {
        setIsApiResponded(true);
        setError({ err });
      })
      .finally(() => {
        setInnerSpinner(false, MY_WELLNESS_GET_ASSESSMENTS);
      });
  }, []);

  return {
    entityNameList,
    isApiResponded1,
  };
};

export { useMemberConsentGetEntityNameListAPI };
