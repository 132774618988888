import React, { useEffect, useContext, useState } from "react";
import {
  Text,
  RichText,
  Link,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import MessageAttachment from "./MessageAttachment";
//const { setError } = useContext(ErrorContext);
import { useMessageDetailAPI } from "../../hooks/useMessageDetailAPI";
import * as MessageAPI from "../../services/MessagesAPI";
import { getFormattedDate, getSCRIPTremovedHTML } from "../../utils/helpers";
import { InlineFormError } from "../../common/InlineFormError";
import { AppContext } from "./../../contexts/app.context";
import {
  ICON_PATH,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  ARCHIVE,
  HEALTH_PLAN_RENEWALS,
} from "../../define.constants";
import { Formik, Form, Field, ErrorMessage, replace } from "formik";
import { highlightBorderOnError } from "../../utils/helpers";
import * as Yup from "yup";
import { SpinnerContext } from "../../contexts/spinner.context";
import FileUploadErrorDialog from "../CreateNewMessage/FileUploadErrorDialog";
import CancelConfirmationDialog from "../CreateNewMessage/CancelConfirmationDialog";
import ChildVaccinationMessage from "./ChildVaccinationMessage";
const MessageDetails = ({
  fields,
  message,
  attachments,
  userAction,
  refMsgDetails,
}) => {
  const { refreshMemberInfoAll, prefLang } = useContext(AppContext);
  const [salesForceFormat, setSalesForceFormat] = useState(false);
  const [onewaymessageFormat, setonewaymessageFormat] = useState(false);
  const [messID, setMessID] = useState("");
  const { setSpinner } = useContext(SpinnerContext);
  const [SFMessID, setSFMessID] = useState("");
  const [replyMessTxt, setReplyMessTxt] = useState(false);
  const [successMess, setSuccessMess] = useState(false);
  const [resetFileUpload, setFileUploadReset] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(1);
  const { MemberStateName } = useContext(AppContext);

  //var base64String;
  const langcode = `${
    SPANISH_LANG_TEXT === prefLang.toLowerCase() ||
    SPANISH_LANG_CODE === prefLang.toLowerCase()
      ? SPANISH_LANG_CODE
      : ENGLISH_LANG_CODE
  }-us`;
  //useMessageDetailAPI(message);
  const { messageDetails, statusMessage } = useMessageDetailAPI(
    message,
    pageRefresh
  );
  const onCloseButtonClick = () => {
    setReplyMessTxt(true);
  };
  const onSendButtonClick = () => {
    //window.location.reload();
    setPageRefresh(pageRefresh + 1);
  };
  const [fileUploadErrorDialog, setFileUploadErrorDialog] = useState({
    message: "",
    isLoading: false,
  });

  const countDisplay = (description) => {
    return description.trim() ? 32000 - description.trim().length : 32000;
  };
  let isChildVaccinationMessage = false;
  isChildVaccinationMessage =
    (message?.Subject?.toLowerCase().includes("vaccination") &&
      message?.Subject?.toLowerCase().includes("child")) ||
    (message?.Subject?.toLowerCase().includes("vacunas") &&
      message?.Subject?.toLowerCase().includes("infant"))
      ? true
      : false;
  const validationSchema = Yup.object().shape({
    description: Yup.string().trim().required("*"),
  });

  let uploadIcon = (
    <img
      src={`${ICON_PATH}/upload.svg`}
      width="18px"
      height="18px"
      alt={getFieldValue(fields, "lblPrint")}
    />
  );
  let closeIcon = (
    <img
      src={`${ICON_PATH}/cross1.jpg`}
      width="18px"
      height="18px"
      alt={getFieldValue(fields, "lblPrint")}
      onClick={() => deleteAttachments()}
    />
  );

  const [selectedFile, setSelectedFile] = useState();
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [fileUploadFlag, setFileUploadFlag] = useState();
  let { isTermedMember } = useContext(AppContext);
  const { isSupportUser } = useContext(AppContext);
  let strval = [];
  let listsOfBase64Strings = [];

  const confirmToClose = (choose) => {
    handleFileUploadErrorDialog("", false);
  };
  const handleFileUploadErrorDialog = (message, isLoading) => {
    setFileUploadErrorDialog({
      message,
      isLoading,
    });
  };

  const supportedFileTypes: string[] = [
    "application/pdf",
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/doc",
    "application/ms-doc",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/excel",
    "application/vnd.ms-excel",
    "application/x-excel",
    "application/x-msexcel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const sizeLimit: numer = 5 * 1048576;

  const [dialog, setCancelConfirmationDialog] = useState({
    message: "",
    isLoading: false,
  });

  const handleDialog = (message, isLoading) => {
    setCancelConfirmationDialog({
      message,
      isLoading,
    });
  };

  const handleOnCancel = () => {
    handleDialog(<Text field={fields.Cancelpopup} />, true);
  };

  const confirmToCancel = (choose) => {
    if (choose) {
      //Reset the file uploading pop up
      document.getElementById("create-message-form").reset();
      setSelectedDetails([]);
      handleDialog("", false);
      setReplyMessTxt(false);
    } else {
      handleDialog("", false);
    }
  };

  //Condition checks for Portal Message, Two_Way Message and One_Way Message
  useEffect(() => {
    setMessID(message?.MessageID);
    if (
      message.SFMessageID != null &&
      message.SFMessageID.length > 0 &&
      message?.Type?.trim()?.toUpperCase() != HEALTH_PLAN_RENEWALS
    ) {
      if (message.Type != "Message") {
        setSFMessID(message.SFMessageID);
        setSalesForceFormat(true);
        setReplyMessTxt(false);
      } else {
        setSalesForceFormat(false);
        setonewaymessageFormat(true);
      }
    } else {
      setSalesForceFormat(false);
      setonewaymessageFormat(false);
    }
    if (messageDetails) {
      message.Status === "N" && refreshMemberInfoAll(); //Toget the updated unread count
    }
  }, [messageDetails]);

  const changeHandler = async (event) => {
    let intval = 0;
    if (selectedDetails.length > 0) {
      intval = 3 - selectedDetails.length;
    } else if (event.target.files.length >= 3) {
      intval = 3;
    } else {
      intval = event.target.files.length;
    }

    for (let i = 0; i < intval; i++) {
      const fileTypeError = hasFileTypeError(
        event.target.files[i],
        supportedFileTypes
      );
      const fileSizeError = hasFileSizeError(event.target.files[i], sizeLimit);
      /*const fileUploadFlag =!(
        hasFileTypeError(event.target.files[i], supportedFileTypes) ||
        hasFileSizeError(event.target.files[i], sizeLimit))*/

      const fileUploadFlag = hasFileTypeError(
        event.target.files[i],
        supportedFileTypes
      );

      // setFileUploadFlag(fileTypeError);

      if (fileTypeError) {
        handleFileUploadErrorDialog(
          <Text field={fields.FileErrorpopup} />,
          true
        );
      } else if (fileSizeError) {
        handleFileUploadErrorDialog(
          <Text field={fields.FileErrorSizePopup} />,
          true
        );
      } else {
        setSelectedFile(event.target.files[i]);

        if (selectedDetails.length > 0 && i == 0) {
          strval.push(selectedDetails[0]);
          if (selectedDetails.length > 1) {
            strval.push(selectedDetails[1]);
          }
        }
        if (event.target.files[i]) {
          strval.push(event.target.files[i]);
        }
        if (strval.length > 0) {
          setSelectedDetails(strval);
        }
      }
    }
  };

  const hasFileTypeError = (file: File, supportedFileTypes): boolean =>
    file && !supportedFileTypes.includes(file.type);

  const hasFileSizeError = (file: File, sizeLimit): boolean =>
    file && file.size > sizeLimit;

  const deleteAttachments = (val) => {
    setSelectedDetails(selectedDetails.filter((str) => str.name != val));
  };
  const qs = require("qs");
  const downloadAtt = (val, name) => {
    let query1 = {
      AttachmentID: val,
    };

    MessageAPI.getAttachment(
      query1,
      qs.stringify(query1, { addQueryPrefix: true })
    )
      .then((response) => {
        if (response.ResultData != undefined) {
          var base64body = response.ResultData.DownloadAttachments.body;

          const fileTypes = {
            pdf: "application/pdf",
            png: "application/png",
            jpg: "image/jpg",
            jpeg: "image/jpeg",
            doc: "application/doc",
            docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            xls: "application/excel",
            xla: "application/x-msexcel",
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          };
          //checkingfilestypes
          var fileExtension = name.split(".").at(-1);
          var fileType = fileTypes[fileExtension.toLowerCase()];

          var header = `data:${fileType};base64,`;
          var a = document.createElement("a");
          a.href = header + base64body;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(function () {});
  };

  const resetFormUpload = () => {
    setSelectedDetails([]);
  };

  const getBase64String1 = (selectedFile) => {
    var fileName = selectedFile.name;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
        //resolve(reader.result);
      };
      reader.onerror = (error) => {};
    });
  };

  const onLoad = (fileString) => {
    listsOfBase64Strings.push(fileString);
  };

  return (
    <>
      {messageDetails ? (
        <div className="row">
          {salesForceFormat == false && (
            <div className="col-lg-12">
              <p className="message-date">
                {getFormattedDate(
                  message?.SentDate,
                  "Day, Mon dd, hh:mm t",
                  langcode
                )}
              </p>
              <h2 className="message-heading">{message?.Subject}</h2>
              <div className="message-from">
                <Text field={fields.lblFrom} /> {message?.From}
              </div>
              <div className="message-to">
                <Text field={fields.lblTo} /> {message?.ToID}
              </div>
              <hr />
              <div className="col-lg-6">
                <div className="msg-subject">{message?.Subject}</div>
              </div>

              <div className="message-body">
                <RichText
                  className="message-body"
                  field={{
                    value: messageDetails?.Body?.replaceAll("\n", "<br>"),
                  }}
                />
              </div>
            </div>
          )}
          {salesForceFormat == true && (
            <div className="col-lg-12">
              <div className="msg-id">
                <Text field={fields.lblMessageId} /> {messID}
              </div>
              <div className="msg-details">
                <div className="msg-details-head">
                  <Text field={fields.messageDetails} />
                </div>
                <hr />
                <div className="msg-tbl row">
                  <div className="col-lg-6">
                    <span className="msg-bold">
                      <Text field={fields.lblCategory} />
                    </span>
                    <div className="msg-category">
                      {messageDetails.SelectedCategory?.Category}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <span className="msg-bold">
                      <Text field={fields.lblSubject} />
                    </span>
                    <div className="msg-subject">{messageDetails.Subject}</div>
                  </div>
                  <div className="col-lg-6">
                    <span className="msg-bold">
                      <Text field={fields.lblStatus} />
                    </span>
                    <div className="msg-status">
                      {messageDetails.SelectedCategory?.Category.includes(
                        "NAL"
                      ) ? (
                        " "
                      ) : messageDetails?.Status?.includes(
                          "Pending Response"
                        ) ? (
                        <Text field={fields.MSG_STATUS1} />
                      ) : (
                        <Text field={fields.MSG_STATUS2} />
                      )}
                    </div>
                  </div>
                  {MemberStateName == "OH" && (
                    <div className="col-lg-6">
                      <span className="msg-bold">
                        <Text field={fields.preferredContactMethods} />
                      </span>
                      <div className="msg-status">
                        {messageDetails?.PreferredCommunication}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {replyMessTxt == true && (
                <Formik
                  initialValues={{
                    description: "",
                    ConversationID: "",
                    Attachments: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    setSpinner(true);

                    for (let i = 0; i < selectedDetails.length; i++) {
                      const base64String = await getBase64String1(
                        selectedDetails[i]
                      );
                      listsOfBase64Strings.push(base64String);
                    }
                    var res1 = await MessageAPI.CreateNewConversationEntry({
                      ConversationID: SFMessID,
                      Description: values?.description.trim(),
                      Attachments: "",
                    });

                    var responseConversationId =
                      res1?.ResultData?.NewConversationEntry
                        ?.ConversationEntryId;

                    for (let i = 0; i < listsOfBase64Strings.length; i++) {
                      var res2 = await MessageAPI.setAttachment({
                        ConversationId: responseConversationId,
                        Body: listsOfBase64Strings[i],
                        Name: selectedDetails[i].name,
                      });
                      var resfinal = res2;
                    }
                    var flag1 = res1?.Message;
                    var flag2 = res2?.Message;

                    if (
                      listsOfBase64Strings.length == 0 &&
                      res1?.Message === "Success"
                    ) {
                      setSuccessMess(true);
                      setSpinner(false);
                      setReplyMessTxt(false);
                      setPageRefresh(pageRefresh + 1);
                      window.location.reload();
                    } else if (
                      listsOfBase64Strings.length > 0 &&
                      res1?.Message === "Success" &&
                      res2?.Message === "Success"
                    ) {
                      setSuccessMess(true);
                      setSpinner(false);
                      setReplyMessTxt(false);
                      setPageRefresh(pageRefresh + 1);
                      window.location.reload();
                    } else {
                      setSuccessMess(false);
                      setSpinner(true);
                      setReplyMessTxt(false);
                    }
                  }}
                >
                  {({ touched, errors, values, setFieldValue }) => (
                    <Form id="create-message-form">
                      <div className="msg-details">
                        <div className="msg-details-head">
                          <Text field={fields.lblReply} />{" "}
                          <ErrorMessage
                            name="description"
                            component={InlineFormError}
                          />
                        </div>
                        <hr />
                        <div className="msg-tbl row">
                          <div className="col-lg-12 ">
                            <Field
                              name="description"
                              id="description"
                              as={`textarea`}
                              maxLength={32000}
                              rows={3}
                              placeholder={getFieldValue(
                                fields,
                                "lblDescriptionPH"
                              )}
                              className={`form-control ${highlightBorderOnError(
                                errors.description,
                                touched.description
                              )}`}
                            />
                            {values.description.trim().length > 0 && (
                              <div className="row-sub-text">
                                <Text field={fields?.lblMaxchars} />
                                {countDisplay(values.description)}{" "}
                                <Text field={fields?.lblremaining} />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="msg-tbl row">
                          <div className="row-title">
                            <Text field={fields?.lblAttachments} />
                          </div>

                          <div>
                            <label
                              className="custom-file-upload"
                              title={getFieldValue(fields, "lblchoosefilestt")}
                            >
                              <input
                                type="file"
                                name="file"
                                multiple
                                disabled={
                                  selectedDetails.length == 3 ? true : false
                                }
                                className="btn btn-upload"
                                onChange={changeHandler}
                              />
                              <i className="fa fa-cloud-upload" />
                              {uploadIcon}{" "}
                              <Text field={fields?.lblChoosefiles} />
                            </label>
                          </div>

                          <div className="label-attachments">
                            <Text field={fields?.InfoAttachment} />
                          </div>
                          <div className="row filedetails">
                            {selectedDetails?.map((file, index) => {
                              return (
                                <React.Fragment key={file.name}>
                                  <div className="label-file-upload">
                                    <table
                                      style={{
                                        border: "1px solid lightblue",
                                        margintop: "3px",
                                      }}
                                    >
                                      <tr>
                                        <td> {file.name}</td>
                                        <td style={{ marginleft: "178px" }}>
                                          <img
                                            src={`${ICON_PATH}/cross1.jpg`}
                                            width="10px"
                                            height="13px"
                                            margin-left="178px"
                                            margin-top="4px"
                                            margin-bottom="3px"
                                            color="red"
                                            className="img-close"
                                            alt={getFieldValue(
                                              fields,
                                              "lblPrint"
                                            )}
                                            onClick={() =>
                                              deleteAttachments(file.name)
                                            }
                                          />
                                        </td>
                                      </tr>
                                    </table>{" "}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                        <div className="msg-tbl row">
                          <div>
                            <button
                              className="btn btn-send"
                              type="submit"
                              onClick={onSendButtonClick}
                              title={getFieldValue(fields, "lblSendtt")}
                            >
                              <Text field={fields.btnSend} />
                            </button>
                            <button
                              type="button"
                              title={getFieldValue(fields, "lblCanceltt")}
                              onClick={handleOnCancel}
                              className="btn btn-primary btn-margin btn-cancel"
                            >
                              <Text field={fields?.btnCancel} />
                            </button>
                            {dialog.isLoading && (
                              <CancelConfirmationDialog
                                onDialog={confirmToCancel}
                                message={dialog.message}
                                fields={fields}
                              ></CancelConfirmationDialog>
                            )}
                            {fileUploadErrorDialog.isLoading && (
                              <FileUploadErrorDialog
                                message={fileUploadErrorDialog.message}
                                onDialog={confirmToClose}
                              ></FileUploadErrorDialog>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}

              <div className="msg-details">
                <div>
                  <button
                    className={
                      isSupportUser ? "btn btn-reply-termed" : "btn btn-reply"
                    }
                    type="button"
                    disabled={
                      replyMessTxt ||
                      userAction === ARCHIVE ||
                      messageDetails.SelectedCategory?.Category.includes(
                        "NAL"
                      ) ||
                      (messageDetails?.Status === "Closed" &&
                        messageDetails?.charStatus === "K") ||
                      isSupportUser
                        ? true
                        : false
                    }
                    onClick={onCloseButtonClick}
                    title={getFieldValue(fields, "lblReplytt")}
                  >
                    <Text field={fields.lblReply} />
                  </button>
                  <div className="msg-dtl-correspondence">
                    <Text field={fields.lblMessageThread} />
                  </div>
                </div>
                <hr />

                {messageDetails.ConversationEntries?.map((message, index) => {
                  return (
                    <React.Fragment key={message.ConversationEntryId}>
                      <div className="msg-tbl row">
                        <div className="col-lg-4 msg-cores">
                          <span className="msg-bold">{message.OwnerName}</span>
                          <br />
                          {getFormattedDate(
                            message.CreatedDate,
                            "Day, Mon dd, hh:mm t",
                            langcode
                          )}
                        </div>
                        <div className="col-lg-8">
                          <RichText
                            field={{
                              value: message?.Description?.replaceAll(
                                "\n",
                                "<br>"
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="msg-tbl row">
                        {message?.Attachments?.length > 0 && (
                          <a
                            href="#"
                            className="col-lg-2"
                            onClick={() =>
                              downloadAtt(
                                message?.Attachments[0]?.Id,
                                message?.Attachments[0]?.Name
                              )
                            }
                          >
                            {message?.Attachments[0]?.Name}
                          </a>
                        )}
                        {message?.Attachments?.length > 1 && (
                          <a
                            href="#"
                            className="col-lg-2"
                            onClick={() =>
                              downloadAtt(
                                message?.Attachments[1]?.Id,
                                message?.Attachments[1]?.Name
                              )
                            }
                          >
                            {message?.Attachments[1]?.Name}
                          </a>
                        )}
                        {message?.Attachments?.length > 2 && (
                          <a
                            href="#"
                            className="col-lg-2"
                            onClick={() =>
                              downloadAtt(
                                message?.Attachments[2]?.Id,
                                message?.Attachments[2]?.Name
                              )
                            }
                          >
                            {message?.Attachments[2]?.Name}
                          </a>
                        )}
                      </div>
                      {messageDetails.ConversationEntries.length > 0 &&
                        index >= 0 &&
                        messageDetails.ConversationEntries.length - 1 !=
                          index && <hr />}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="col-xl-7 col-lg-6 col-md-12 message-details">
          <p className="text-muted">{statusMessage}</p>
        </div>
      )}

      {message ? (
        <div className="row">
          {onewaymessageFormat == true && salesForceFormat == false && (
            <div className="col-lg-12">
              <p className="message-date">
                {getFormattedDate(
                  message?.SentDate,
                  "Day, Mon dd, hh:mm t",
                  langcode
                )}
              </p>
              <h2 className="message-heading">{message?.Subject}</h2>
              <hr />
              {replyMessTxt == true && isChildVaccinationMessage ? (
                <ChildVaccinationMessage fields={fields} />
              ) : (
                <div className="message-body">
                  <RichText
                    className="message-body"
                    field={{
                      value: message?.Body?.replaceAll("\n", "<br>"),
                    }}
                  />
                  <button
                    className={"btn btn-reply-now"}
                    type="button"
                    onClick={onCloseButtonClick}
                  >
                    <Text field={fields.btnReplyNow} />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="col-xl-7 col-lg-6 col-md-12 message-details">
          <p className="text-muted">{statusMessage}</p>
        </div>
      )}
    </>
  );
};
//MessageLayoutChanges for One_Way Message
export default MessageDetails;
