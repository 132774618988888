import React, { useState, useContext, useCallback, useEffect } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import PhonePreference from "./PhonePreference";
import EmailPreference from "./EmailPreference";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { useMemberAPI } from "../../hooks/useMemberAPI";
import * as CommunicationPreferrence from "../../services/CommunicationPreferencesAPI";
import { ModalContext } from "./../../contexts/modal.context";
import * as Yup from "yup";
import { SpinnerContext } from "../../contexts/spinner.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import { Formik, Form, Field } from "formik";
import { AppContext } from "./../../contexts/app.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  SUPPORT_USER,
  TERMED_MEMBER,
  SOURCE_PORTAL,
  MEDICAID,
  MEDICARE,
  MARKETPLACE,
} from "../../define.constants";
import {
  PHONE_CARD_PATH,
  PHONENUMBER_FIELD,
  PHONETYPE_FIELD,
  EMAIL_CARD_PATH,
  EMAILADDRESS_FIELD,
  EMAILTYPE_FIELD,
  CHANNEL_PREFERENCE_SMS,
  CHANNEL_PREFERENCE_PHONE,
  CHANNEL_PREFERENCE_EMAIL,
  CHANNEL_ID_SMS,
  CHANNEL_ID_PHONE,
  CHANNEL_ID_EMAIL,
  SUCCESS_RESPONSE_CODE,
  PHONE_VALID_REGEX,
  EMAIL_REGEX,
  PREFERREDPHONE_FIELD,
  PREFERREDEMAIL_FIELD,
  IUD_FLAG_D,
  GLOBAL_VARIABLE,
} from "../../define.constants";

import { useViewCommunicationPreferences } from "../../hooks/useViewCommunicationPreferences";
import { useViewContactPreferencesList } from "../../hooks/useViewContactPreferencesList";
import { useViewContactPreferences } from "../../hooks/useViewContactPreferences";
import { ACCSETTINGS_COMMUNICATION_PREFERENCE } from "../../define.widgets";
import { ErrorContext } from "../../contexts/error.context";
import { CustomSelect } from "../../common/Select";
import GenderIdentity from "./GenderIdentity";
import PreferredPronoun from "./PreferredPronoun";
import SexualOrientation from "./SexualOrientation";
import RaceOREthnicity from "./RaceOREthnicity";
import PrimaryLanguage from "./PrimaryLanguage";
const AccountSettingsCommunicationPreferences = (props) => {
  const { setError } = useContext(ErrorContext);
  const { communicationData, refreshMessageInfoAll } =
    useViewCommunicationPreferences();
  const { MemberStateName } = useMemberAPI();
  const { contactPreferencesList } = useViewContactPreferencesList();
  //  const { contactData, refreshMSGInfoAll } = useViewContactPreferences();
  const { contactData, refreshMSGInfoAll, isViewContactLoaded } =
    useViewContactPreferences();
  let { isTermedMember, memberLOB } = useContext(AppContext);
  //CA AB 114 NDD flag changes--start
  const isNDDflagEnabled = communicationData.LsMemberAPIInfo?.NDD;
  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;
  const isMedicareMember = memberLOB?.toLowerCase() === MEDICARE;
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  const isShowEmail =
    !(
      isNDDflagEnabled &&
      (MemberStateName === "CA" || MemberStateName === "U2")
    ) &&
    (isMarketplaceMember || isMedicaidMember || isMedicareMember);
  const isShowPhone =
    !(
      isNDDflagEnabled &&
      (MemberStateName === "CA" || MemberStateName === "U2")
    ) &&
    (isMarketplaceMember || isMedicaidMember || isMedicareMember);
  // CA AB 1184 NDD flag end
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  Yup.addMethod(
    Yup.array,
    "uniqueValidation",
    function (error, mapper = (a) => a, errorCardPath, errorField) {
      return this.test("uniqueValidation", error, function (list) {
        let list2 = [];
        list2 = list?.filter((o) => o.IUDFlag !== IUD_FLAG_D);
        const set = [...new Set(list2.map(mapper))];
        const isUnique = list2?.length === set?.length;
        if (isUnique) return true;
        var deleteCount = list.length - list2.length;
        var errorElement = "";
        const idx =
          list2.findIndex((l, i) => mapper(l) !== set[i]) + deleteCount;
        let dPath = errorCardPath + "." + `${idx}` + "." + errorField;
        errorElement = this.createError({
          path: dPath,
          error,
        });
        return errorElement;
      });
    }
  );
  Yup.addMethod(
    Yup.array,
    "minOneValidation",
    function (error, mapper = (a) => a, errorCardPath, errorField) {
      return this.test("minOneValidation", error, function (list) {
        let list2 = [];
        list2 = list?.filter(
          errorField === PREFERREDEMAIL_FIELD
            ? (o) =>
                o.IUDFlag !== IUD_FLAG_D &&
                o.preferredEmail !== undefined &&
                o.preferredEmail !== ""
            : (o) =>
                o.IUDFlag !== IUD_FLAG_D &&
                o.preferredPhone !== undefined &&
                o.preferredPhone !== ""
        );
        if (list2.length > 0) return true;
        var errorElement = "";
        let dPath = errorCardPath + ".0." + errorField;
        errorElement = this.createError({
          path: dPath,
          error,
        });
        return errorElement;
      });
    }
  );
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { setSpinner, innerSpinnerHTML } = useContext(SpinnerContext);
  const { fields } = props;

  const { openModal, closeModal } = useContext(ModalContext);
  let privacyContent = communicationData?.LsMemberAPIInfo?.PrivacyContent;
  const [pageEditable, setPageEditable] = useState(true);
  const [genderIdentityEditable, setGenderIdentityEditable] = useState(true);
  const [preferredPronounEditable, setPreferredPronounEditable] =
    useState(true);
  const [sexualOrientationEditable, setSexualOrientationEditable] =
    useState(true);
  const [phoneEditable, setPhoneEditable] = useState(true);
  const [emailEditable, setEmailEditable] = useState(true);
  const [saveButton, setSaveButton] = useState(true);
  const [initialize, setInitialize] = useState(true);
  const [raceOrEthinicityEditable, setRaceOrEthinicityEditable] =
    useState(true);
  const [primaryLanguageEditable, setPrimaryLanguageEditable] = useState(true);
  let genderIdentitySelected = "";
  let sexualOrientationSelected = "";
  let preferredPronounSelected = "";
  let genderIdentityTextValue = "";
  let sexualOrientationTextValue = "";
  let preferredPronounTextValue = "";
  let raceOrEthnicityTextValue = "";
  let raceOrEthicitySelected = "";
  let raceOrEthicityOtherSelected = "";
  let primaryLanguageSelected = "";
  let primaryLanguageTextValue = "";

  const handleGenderIdentityValue = (event) => {
    genderIdentityTextValue = "Other: " + event.target.value;
    genderIdentitySelected = genderIdentityTextValue;
  };
  const handleSexualOrientationValue = (event) => {
    sexualOrientationTextValue = "Other: " + event.target.value;
    sexualOrientationSelected = sexualOrientationTextValue;
  };
  const handlePreferredPronounValue = (event) => {
    preferredPronounTextValue = "Other: " + event.target.value;
    preferredPronounSelected = preferredPronounTextValue;
  };
  const handlePrimaryLanguageValue = (event) => {
    primaryLanguageTextValue = "Other: " + event.target.value;
    primaryLanguageSelected = primaryLanguageTextValue;
  };
  const handleRaceOrEthicityValue = (event) => {
    raceOrEthnicityTextValue = "Other: " + event.target.value;
    raceOrEthicityOtherSelected = raceOrEthnicityTextValue;
  };
  const getGenderIdentitySelected = (value) => {
    genderIdentitySelected = value;
  };
  const getSexualOrientationSelected = (value) => {
    sexualOrientationSelected = value;
  };
  const getPreferredPronounSelected = (value) => {
    preferredPronounSelected = value;
  };
  const getPrimaryLanguageSelected = (value) => {
    primaryLanguageSelected = value;
  };
  const getRaceOrEthicitySelected = (value) => {
    raceOrEthicitySelected = value;
  };
  const onGenderIdentityEditButtonClick = () => {
    setGenderIdentityEditable(false);
    setSaveButton(false);
    setInitialize(false);
  };
  const onPreferredPronounEditButtonClick = () => {
    setPreferredPronounEditable(false);
    setSaveButton(false);
    setInitialize(false);
  };
  const onPrimaryLanguageEditButtonClick = () => {
    setPrimaryLanguageEditable(false);
    setSaveButton(false);
    setInitialize(false);
  };
  const onRaceOrEthicitynEditButtonClick = () => {
    setRaceOrEthinicityEditable(false);
    setSaveButton(false);
    setInitialize(false);
  };

  const onSexualOrientationEditButtonClick = () => {
    setSexualOrientationEditable(false);
    setSaveButton(false);
    setInitialize(false);
  };
  const onPhoneEditButtonClick = () => {
    setPhoneEditable(false);
    setSaveButton(false);
    setInitialize(false);
  };
  const onEmailEditButtonClick = () => {
    setEmailEditable(false);
    setSaveButton(false);
    setInitialize(false);
  };
  const handleSaveClick = () => {
    postApiDataContactPrererences();
  };
  let phoneTypeSelected = "";
  let phoneNoList = [];
  communicationData?.LsMemberAPIInfo?.phoneNumbers?.map((data, index) => {
    if (data.IsDefault === true) {
      phoneTypeSelected = index + "";
    }
    let voiceConcent = data.phonePreferences?.filter(
      (o) =>
        o.FilterId === CHANNEL_PREFERENCE_PHONE &&
        o.PreferenceType === 1 &&
        o.ChannelId === CHANNEL_ID_PHONE
    );
    let textConcent = data.phonePreferences?.filter(
      (o) =>
        o.FilterId === CHANNEL_PREFERENCE_SMS &&
        o.PreferenceType === 1 &&
        o.ChannelId === CHANNEL_ID_SMS
    );
    phoneNoList.push({
      preferredPhone: data.IsDefault ? index + "" : "",
      isNewRow: false,
      isRowDeleted: false,
      phoneType: data.phoneType,
      phoneNumber: data.phoneNumber,
      IsDefault: data.IsDefault ? index + "" : "",
      IsMobile: data.IsMobile,
      IsActive: data.IsActive,
      Edited: data.Edited,
      CallConsent: data.CallConsent,
      VoiceConcent: voiceConcent?.length > 0 ? true : false,
      TextConsent: textConcent?.length > 0 ? true : false,
      Id: data.Id,
      IUDFlag: data.IUDFlag,
      AlertMember: data.AlertMember,
      phonePreferences: data.phonePreferences,
      OrderId: index + 1,
    });
  });
  let EmailAddressTypeSelected = "";
  let emailList = [];
  communicationData?.LsMemberAPIInfo?.Emails?.map((data, index) => {
    if (data.IsDefault === true) {
      EmailAddressTypeSelected = index + "";
    }

    emailList.push({
      preferredEmail: data.IsDefault ? index + "" : "",
      IUDFlag: data.IUDFlag,
      isNewRow: false,
      isRowDeleted: false,
      EmailAddressType: data.EmailAddressType,
      EmailAddress: data.EmailAddress,
      IsDefault: data.IsDefault,

      Preferred: data.IsDefault ? index + "" : "",
      IsActive: data.IsActive,
      Edited: data.Edited,
      IUDFlag: data.IUDFlag,
      Id: data.id,
      emailPreferences: data.emailPreferences,
      OrderId: index + 1,
    });
  });
  const initialValues = {
    PhonePreference: {
      preferences: phoneNoList,
      preferredPhone: phoneTypeSelected,
    },
    EmailPreference: {
      preferences: emailList,
      preferredEmail:
        communicationData?.LsMemberAPIInfo?.Emails?.length === 0 ||
        communicationData?.LsMemberAPIInfo?.Emails === null
          ? "0"
          : EmailAddressTypeSelected,
    },
  };
  const phoneValidRegex = PHONE_VALID_REGEX;
  const emailRegex = EMAIL_REGEX;
  const validationSchema = Yup.object({
    PhonePreference: Yup.object().shape({
      preferences: Yup.array()
        .of(
          Yup.object().shape({
            phoneNumber: Yup.string().when("IUDFlag", {
              is: (IUDFlag) => IUDFlag == IUD_FLAG_D,
              then: Yup.string(),
              otherwise: Yup.string()
                .required(getFieldValue(fields, "phoneNoRequired", false))
                .test(
                  "len",
                  getFieldValue(fields, "phoneNoFormat", false),
                  (val) => val?.length === 14 || val?.length === 10
                )
                .matches(
                  phoneValidRegex,
                  getFieldValue(fields, "phoneNoValid", false)
                ),
            }),
            phoneType: Yup.string().when("IUDFlag", {
              is: (IUDFlagVal) => IUDFlagVal == IUD_FLAG_D,
              then: Yup.string(),
              otherwise: Yup.string().required(
                getFieldValue(fields, "phoneTypeRequired", false)
              ),
            }),
            preferredPhone: Yup.string(),
            VoiceConcent: Yup.boolean(),
            TextConsent: Yup.boolean(),
          })
        )
        .uniqueValidation(
          getFieldValue(fields, "phoneNoUnique", false),
          (a) => a.phoneNumber,
          PHONE_CARD_PATH,
          PHONENUMBER_FIELD
        )
        .uniqueValidation(
          getFieldValue(fields, "phoneTypeUnique", false),
          (a) => a.phoneType,
          PHONE_CARD_PATH,
          PHONETYPE_FIELD
        )
        .minOneValidation(
          getFieldValue(fields, "preferredPhone", false),
          (a) => a.preferredPhone,
          PHONE_CARD_PATH,
          PREFERREDPHONE_FIELD
        ),
    }),
    EmailPreference: Yup.object().shape({
      preferences: Yup.array()
        .of(
          Yup.object().shape({
            EmailAddress: Yup.string().when("IUDFlag", {
              is: (IUDFlagVal) => IUDFlagVal == IUD_FLAG_D,
              then: Yup.string(),
              otherwise: Yup.string()
                .required(getFieldValue(fields, "emailAddressRequired", false))
                .matches(
                  emailRegex,
                  getFieldValue(fields, "emailAddressValid", false)
                ),
            }),
            EmailAddressType: Yup.string().when("IUDFlag", {
              is: (IUDFlagVal) => IUDFlagVal == IUD_FLAG_D,
              then: Yup.string(),
              otherwise: Yup.string().required(
                getFieldValue(fields, "emailTypeRequired", false)
              ),
            }),
            preferredEmail: Yup.string(),
          })
        )
        .uniqueValidation(
          getFieldValue(fields, "emailAddressUnique", false),
          (a) => a.EmailAddress,
          EMAIL_CARD_PATH,
          EMAILADDRESS_FIELD
        )
        .uniqueValidation(
          getFieldValue(fields, "emailTypeUnique", false),
          (a) => a.EmailAddressType,
          EMAIL_CARD_PATH,
          EMAILTYPE_FIELD
        ),
    }),
  });
  const postApiData = useCallback(
    (value) => {
      let phoneNumberData = [];
      value.PhonePreference.preferences.map((data, index) => {
        let phPreferences = [];
        if (data.VoiceConcent) {
          phPreferences.push({
            FilterId: CHANNEL_PREFERENCE_PHONE,
            PreferenceType: 1,
            ChannelId: null,
            TypeName: data.phoneType,
            PreferenceAttributes: null,
          });
        }
        if (data.TextConsent) {
          phPreferences.push({
            FilterId: CHANNEL_PREFERENCE_SMS,
            PreferenceType: 1,
            ChannelId: null,
            TypeName: data.phoneType,
            PreferenceAttributes: null,
          });
        }
        phoneNumberData.push({
          phoneType: data.phoneType,
          phoneNumber: data.phoneNumber,
          IsDefault:
            value.PhonePreference.preferredPhone == index ? true : false,
          IsMobile: false,
          IsActive: false,
          Edited: data.IsActive,
          CallConsent: data.VoiceConcent,
          TextConsent: data.TextConsent,
          Id: null,
          IUDFlag: data.IUDFlag,
          AlertMember: null,
          phonePreferences: phPreferences,
        });
      });
      let emailData = [];
      value.EmailPreference.preferences.map((data, index) => {
        let emPreferences = [];

        emailData.push({
          EmailAddressType: data.EmailAddressType,
          EmailAddress: data.EmailAddress,
          IsDefault:
            value.EmailPreference.preferredEmail == index ? true : false,

          Preferred: data.IsDefault,
          IsActive: data.IsActive,
          Edited: data.Edited,
          IUDFlag: data.IUDFlag,
          Id: data.id,
        });
      });
      let updatedCommunicationData = {
        PhoneNumbers: phoneNumberData,
        Emails: emailData,
      };
      if (updatedCommunicationData) {
        setSpinner(true);
        CommunicationPreferrence.UpdateCommunicationPreferences(
          updatedCommunicationData
        )
          .then((response) => {
            setSpinner(false);
            if (
              response?.ResultData?.LsMemberAPIInfo?.StatusCode ===
              SUCCESS_RESPONSE_CODE
            ) {
              refreshMessageInfoAll();
              setSpinner(true);
              successUpdate();
            }
          })
          .catch((err) => {
            setSpinner(false);
            setError({ err });
          })
          .finally(function () {
            setSpinner(false);
          });
      }
    },
    [refreshMessageInfoAll]
  );

  // Race or Ethnicity Multi-select: "Other" - value update for API
  const concatinateOtherRaceEthValue = () => {
    let replacedText = "";
    let otherText = props?.fields?.OtherText?.value;
    let otherTextWithComma = otherText ? "," + otherText : "";
    if (raceOrEthicitySelected) {
      const otherTextValue = raceOrEthicityOtherSelected;

      // IF "Other" checkbox is selected
      if (raceOrEthicitySelected?.includes(otherText)) {
        if (otherTextValue) {
          replacedText = raceOrEthicitySelected?.replace(
            otherText,
            otherTextValue
          );
        } else {
          replacedText = raceOrEthicitySelected?.replace(
            otherTextWithComma,
            ""
          );
        }
        raceOrEthicitySelected = replacedText;
      }
    }
  };

  const postApiDataContactPrererences = useCallback(
    (value) => {
      let contactPreferencesData = {
        Source: SOURCE_PORTAL,
        GenderIdentity:
          genderIdentitySelected ||
          contactData?.IsContactPreferences?.GenderIdentity,
        Pronoun:
          preferredPronounSelected ||
          contactData?.IsContactPreferences?.Pronoun,
        SexualOrientation:
          sexualOrientationSelected ||
          contactData?.IsContactPreferences?.SexualOrientation,
        RaceEthnicity: raceOrEthicitySelected || "",
        PrimaryLanguage:
          primaryLanguageSelected ||
          contactData?.IsContactPreferences?.PrimaryLanguage,
      };
      let updatedContactPreferenceData = {
        ContactPreferences: contactPreferencesData,
      };
      if (updatedContactPreferenceData) {
        setSpinner(true);
        CommunicationPreferrence.UpdateContactPreferences(
          updatedContactPreferenceData
        )
          .then((response) => {
            setSpinner(false);
            if (response?.ResultData?.StatusCode === SUCCESS_RESPONSE_CODE) {
              refreshMessageInfoAll();
              setSpinner(true);
              successUpdate();
            }
          })
          .catch((err) => {
            setSpinner(false);
            setError({ err });
          })
          .finally(function () {
            setSpinner(false);
          });
      }
    },
    [refreshMessageInfoAll]
  );
  const agreeContent = (value) => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>{privacyContent}</p>
        </div>
        <div className="account-btns">
          <button
            type="button"
            className="btn btn-default"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            <Text field={props?.fields?.lblDisagree ?? ""} />
          </button>
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: false,
            }}
            handleClick={() => {
              postApiData(value);
            }}
            isFormik
          >
            {({ handleClick, disabledClass }) => {
              return (
                <>
                  <button
                    type="button"
                    className={`btn btn-primary ${disabledClass}`.trim()}
                    onClick={handleClick}
                  >
                    <Text field={props?.fields?.lblAgree ?? ""} />
                  </button>
                </>
              );
            }}
          </WhoIsAuthorized>
        </div>
      </div>
    </>
  );
  const agreeContent1 = (value) => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>{privacyContent}</p>
        </div>
        <div className="account-btns">
          <button
            type="button"
            className="btn btn-default"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            <Text field={props?.fields?.lblDisagree ?? ""} />
          </button>
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: false,
            }}
            handleClick={() => {
              postApiDataContactPrererences(value);
            }}
            isFormik
          >
            {({ handleClick, disabledClass }) => {
              return (
                <>
                  <button
                    type="button"
                    className={`btn btn-primary ${disabledClass}`.trim()}
                    onClick={handleClick}
                  >
                    <Text field={props?.fields?.lblAgree ?? ""} />
                  </button>
                </>
              );
            }}
          </WhoIsAuthorized>
        </div>
      </div>
    </>
  );
  const agreeContent2 = (value) => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>{privacyContent}</p>
        </div>
        <div className="account-btns">
          <button
            type="button"
            className="btn btn-default"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            <Text field={props?.fields?.lblDisagree ?? ""} />
          </button>
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: false,
            }}
            handleClick={() => {
              postApiDataContactPrererences(value);
              postApiData(value);
            }}
            isFormik
          >
            {({ handleClick, disabledClass }) => {
              return (
                <>
                  <button
                    type="button"
                    className={`btn btn-primary ${disabledClass}`.trim()}
                    onClick={handleClick}
                  >
                    <Text field={props?.fields?.lblAgree ?? ""} />
                  </button>
                </>
              );
            }}
          </WhoIsAuthorized>
        </div>
      </div>
    </>
  );
  const onCloseButtonClick = () => {
    closeModal();
    setPageEditable(true);
    setPhoneEditable(true);
    setEmailEditable(true);
    setSaveButton(true);
  };
  const successModal = (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>
            <Text field={props?.fields?.lblSuccessMessage ?? ""} />
          </p>
        </div>
        <div className="comm-btns">
          <button
            type="button"
            className="btn continue-button"
            data-mdb-dismiss="modal"
            onClick={onCloseButtonClick}
          >
            <Text field={props?.fields?.lblClose ?? ""} />
          </button>
        </div>
      </div>
    </>
  );
  const successUpdate = () => {
    openModal({
      content: successModal,
      version: 1,
    });
  };
  const generalUpdate = (value) => {
    concatinateOtherRaceEthValue();
    if (
      (genderIdentityEditable === false ||
        sexualOrientationEditable === false ||
        preferredPronounEditable === false ||
        primaryLanguageEditable === false ||
        raceOrEthinicityEditable === false) &&
      phoneEditable === true &&
      emailEditable === true
    ) {
      openModal({
        content: agreeContent1(value),
        version: 1,
      });
    } else if (
      (genderIdentityEditable === true ||
        sexualOrientationEditable === true ||
        preferredPronounEditable === true ||
        primaryLanguageEditable === true ||
        raceOrEthinicityEditable === true) &&
      (phoneEditable === false || emailEditable === false)
    ) {
      openModal({
        content: agreeContent(value),
        version: 1,
      });
    } else {
      openModal({
        content: agreeContent2(value),
        version: 1,
      });
    }
  };
  const onFormikSubmit = (value) => {
    generalUpdate(value);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormikSubmit}
        enableReinitialize={initialize === true ? true : false}
      >
        {({
          errors,
          values,
          touched,
          initialTouched,
          setFieldValue,
          setFieldTouched,
          setValues,
          handleBlur,
          handleChange,
          disabledClass,
          onButtonClick,
          isDisabled,
        }) => (
          <Form>
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="formsDocs"
                      role="tabpanel"
                      aria-labelledby="tab-1"
                    >
                      <div className="row no-tab-title communication-preferences">
                        <div className="col-md-12">
                          <ul className="list-group">
                            <li className="list-group-item">
                              {
                                innerSpinnerHTML?.[
                                  ACCSETTINGS_COMMUNICATION_PREFERENCE
                                ]
                              }
                              <WhoIsAuthorized
                                authorize={{
                                  [SUPPORT_USER]: true,
                                  [TERMED_MEMBER]: false,
                                }}
                                handleClick={onButtonClick}
                              >
                                {({ handleClick, disabledClass }) => {
                                  return (
                                    <>
                                      {pageEditable === true ? (
                                        <a
                                          className={`edit custom-float ${disabledClass}`}
                                          onClick={() => {
                                            handleClick;
                                            setPageEditable(false);
                                            setGenderIdentityEditable(false);
                                            setPreferredPronounEditable(false);
                                            setRaceOrEthinicityEditable(false);
                                            setPrimaryLanguageEditable(false);
                                            setSexualOrientationEditable(false);
                                            setSaveButton(false);
                                          }}
                                        >
                                          <Text field={fields?.editCF ?? ""} />
                                        </a>
                                      ) : null}
                                    </>
                                  );
                                }}
                              </WhoIsAuthorized>
                              <div className="row field1 common-input">
                                <div
                                  className="col-xxl-4 col-xl-4 col-md-6 col-sm-12 column-margin"
                                  style={{ marginTop: "6%" }}
                                >
                                  <GenderIdentity
                                    fields={fields}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    contactData={contactData}
                                    initialTouched={initialTouched}
                                    setFieldValue={setFieldValue}
                                    setValues={setValues}
                                    isDisabled={pageEditable}
                                    handleGenderIdentityValue={
                                      handleGenderIdentityValue
                                    }
                                    pageEditable={genderIdentityEditable}
                                    onButtonClick={
                                      onGenderIdentityEditButtonClick
                                    }
                                    contactPreferencesList={
                                      contactPreferencesList
                                    }
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldTouched={setFieldTouched}
                                    getGenderIdentitySelected={
                                      getGenderIdentitySelected
                                    }
                                  />
                                </div>
                                <div
                                  className="col-xxl-4 col-xl-4 col-md-6 col-sm-12 column-margin"
                                  style={{ marginTop: "6%" }}
                                >
                                  <PreferredPronoun
                                    fields={fields}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    contactData={contactData}
                                    initialTouched={initialTouched}
                                    setFieldValue={setFieldValue}
                                    setValues={setValues}
                                    pageEditable={preferredPronounEditable}
                                    onButtonClick={
                                      onPreferredPronounEditButtonClick
                                    }
                                    contactPreferencesList={
                                      contactPreferencesList
                                    }
                                    handleBlur={handleBlur}
                                    handlePreferredPronounValue={
                                      handlePreferredPronounValue
                                    }
                                    handleChange={handleChange}
                                    setFieldTouched={setFieldTouched}
                                    getPreferredPronounSelected={
                                      getPreferredPronounSelected
                                    }
                                  />
                                </div>
                                <div
                                  className="col-xxl-4 col-xl-4 col-md-6 col-sm-12 column-margin"
                                  style={{ marginTop: "6%" }}
                                >
                                  <PrimaryLanguage
                                    fields={fields}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    contactData={contactData}
                                    initialTouched={initialTouched}
                                    setFieldValue={setFieldValue}
                                    setValues={setValues}
                                    pageEditable={primaryLanguageEditable}
                                    onButtonClick={
                                      onPrimaryLanguageEditButtonClick
                                    }
                                    contactPreferencesList={
                                      contactPreferencesList
                                    }
                                    handleBlur={handleBlur}
                                    handlePrimaryLanguageValue={
                                      handlePrimaryLanguageValue
                                    }
                                    handleChange={handleChange}
                                    setFieldTouched={setFieldTouched}
                                    getPrimaryLanguageSelected={
                                      getPrimaryLanguageSelected
                                    }
                                  />
                                </div>

                                <div
                                  className="col-xxl-4 col-xl-4 col-md-6 col-sm-12 column-margin"
                                  style={{ marginTop: "6%" }}
                                >
                                  <SexualOrientation
                                    fields={fields}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    contactData={contactData}
                                    initialTouched={initialTouched}
                                    setFieldValue={setFieldValue}
                                    setValues={setValues}
                                    pageEditable={sexualOrientationEditable}
                                    handleSexualOrientationValue={
                                      handleSexualOrientationValue
                                    }
                                    onButtonClick={
                                      onSexualOrientationEditButtonClick
                                    }
                                    contactPreferencesList={
                                      contactPreferencesList
                                    }
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldTouched={setFieldTouched}
                                    getSexualOrientationSelected={
                                      getSexualOrientationSelected
                                    }
                                  />
                                </div>

                                <div
                                  className="col-xxl-4 col-xl-4 col-md-6 col-sm-12 column-margin"
                                  style={{ marginTop: "6%" }}
                                >
                                  <RaceOREthnicity
                                    fields={fields}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    contactData={contactData}
                                    initialTouched={initialTouched}
                                    setFieldValue={setFieldValue}
                                    setValues={setValues}
                                    pageEditable={raceOrEthinicityEditable}
                                    onButtonClick={
                                      onRaceOrEthicitynEditButtonClick
                                    }
                                    contactPreferencesList={
                                      contactPreferencesList
                                    }
                                    handleBlur={handleBlur}
                                    handleRaceOrEthicityValue={
                                      handleRaceOrEthicityValue
                                    }
                                    handleChange={handleChange}
                                    setFieldTouched={setFieldTouched}
                                    getRaceOrEthinicitySelected={
                                      getRaceOrEthicitySelected
                                    }
                                  />
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item">
                              {
                                innerSpinnerHTML?.[
                                  ACCSETTINGS_COMMUNICATION_PREFERENCE
                                ]
                              }
                              <PhonePreference
                                fields={fields}
                                values={values}
                                errors={errors}
                                touched={touched}
                                initialTouched={initialTouched}
                                setFieldValue={setFieldValue}
                                setValues={setValues}
                                pageEditable={phoneEditable}
                                onButtonClick={onPhoneEditButtonClick}
                                communicationData={communicationData}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                setFieldTouched={setFieldTouched}
                                isNDDflagEnabled={isNDDflagEnabled}
                                isShowPhone={isShowPhone}
                              />
                            </li>
                            <li className="list-group-item">
                              {
                                innerSpinnerHTML?.[
                                  ACCSETTINGS_COMMUNICATION_PREFERENCE
                                ]
                              }
                              <EmailPreference
                                fields={fields}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                setValues={setValues}
                                pageEditable={emailEditable}
                                onButtonClick={onEmailEditButtonClick}
                                communicationData={communicationData}
                                isNDDflagEnabled={isNDDflagEnabled}
                                isShowEmail={isShowEmail}
                              />
                            </li>
                            {!isMarketplaceMember && (
                              <div className="col-sm-12">
                                <div className="disclaimer-text">
                                  <Text
                                    field={fields?.disclaimerMessageNonMP ?? ""}
                                  />
                                </div>
                              </div>
                            )}
                            <div className="col-sm-12">
                              <div className="preferences-btn">
                                {(pageEditable === true &&
                                  phoneEditable === false &&
                                  emailEditable === true) ||
                                  (pageEditable === true &&
                                    phoneEditable === true &&
                                    emailEditable === false) ||
                                  (pageEditable === true &&
                                    phoneEditable === false &&
                                    emailEditable === false) ||
                                  (pageEditable === true &&
                                    phoneEditable === false &&
                                    emailEditable === false &&
                                    genderIdentityEditable === true &&
                                    preferredPronounEditable === true &&
                                    primaryLanguageEditable === true &&
                                    raceOrEthinicityEditable === true &&
                                    sexualOrientationEditable === true) ||
                                  (pageEditable === true && (
                                    <>
                                      <button
                                        type="button"
                                        className={
                                          isTermedMember
                                            ? "btn btn-primary disabled"
                                            : "btn btn-default"
                                        }
                                        onClick={() => {
                                          setPageEditable(false);
                                          setPhoneEditable(false);
                                          setEmailEditable(false);
                                          setGenderIdentityEditable(false);
                                          setPreferredPronounEditable(false);
                                          setRaceOrEthinicityEditable(false);
                                          setSexualOrientationEditable(false);
                                          setPrimaryLanguageEditable(false);
                                          setSaveButton(false);
                                        }}
                                      >
                                        <Text
                                          field={fields?.editButton ?? ""}
                                        />
                                      </button>
                                    </>
                                  ))}
                                {(pageEditable === false ||
                                  phoneEditable === false ||
                                  emailEditable === false ||
                                  genderIdentityEditable === false ||
                                  preferredPronounEditable === false ||
                                  raceOrEthinicityEditable === false ||
                                  sexualOrientationEditable === false ||
                                  primaryLanguageEditable === false) && (
                                  <>
                                    <button
                                      type="reset"
                                      className="btn btn-default"
                                      onClick={() => {
                                        setInitialize(true);
                                        setTimeout(() => {
                                          setPageEditable(true);
                                          setGenderIdentityEditable(true);
                                          setPreferredPronounEditable(true);
                                          setRaceOrEthinicityEditable(true);
                                          setSexualOrientationEditable(true);
                                          setPrimaryLanguageEditable(true);
                                          setPhoneEditable(true);
                                          setEmailEditable(true);
                                          setSaveButton(true);
                                        }, 500);
                                      }}
                                    >
                                      <Text
                                        field={fields?.cancelButton ?? ""}
                                      />
                                    </button>
                                  </>
                                )}
                                <button
                                  type="submit"
                                  className={`btn btn-primary
                                    ${saveButton === true ? " btn-save" : ""}`}
                                  disabled={saveButton}
                                >
                                  <Text field={fields?.saveButton ?? ""} />
                                </button>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default withModalProvider(AccountSettingsCommunicationPreferences);
