import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import {
  withPlaceholder,
  withSitecoreContext,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { CustomSelect } from "../../common/Select";
import { ICON_PATH, SC, MEDICAID, MARKETPLACE } from "../../define.constants";

/**
 * Each tab is itself a child component added to a placeholder defined on the tabs component.
 * The tab component introspects its child components to render the tab headings (i.e. the tab children render partial content in two places).
 * When this component is edited in Sitecore Experience Editor, the tabbing behavior is turned off and each tab stacks on top of each other
 * for easy inline editing.
 */

const CommonLayoutTabs = (props) => {
  let { memberLOB, MemberStateName, isSwitchedToDependent } =
    useContext(AppContext);
  const isSCMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === SC;
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE?.toLowerCase();
  const { tabsPlaceholder, sitecoreContext, fields } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [options, setOptions] = useState([]);
  const [scrollTo, setScrollTo] = useState("");
  const [selectedValue, setSelectedValue] = useState(
    tabsPlaceholder[0]?.props?.fields?.tabName?.value
  );
  const [hiddenTabsList, setHiddenTabsList] = useState(
    tabsPlaceholder[0]?.props?.hiddenTabs
  );
  const currentpath = useLocation();
  let validTabIndex = 0;
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  const setActiveTab = (e, currentIndex, val) => {
    e.preventDefault();
    setActiveTabIndex(currentIndex);
    {
      !isEditing &&
        (tabsPlaceholder || [])
          .filter((tab) => tab.props && tab.props.fields)
          .map((tab, index) => {
            if (
              !tab.props.hiddenTabs?.includes(tab.props.rendering.componentName)
            ) {
              if (!tab.props.fields?.Applicablestates) {
                if (index === currentIndex) {
                  setSelectedValue(tab?.props?.fields?.tabName?.value);
                }
              } else {
                setSelectedValue(val);
              }
            }
          });
    }
  };

  const setActiveTabForDrp = (selectedVal) => {
    setSelectedValue(selectedVal.value);
    setActiveTabIndex(selectedVal.index);
  };

  useEffect(() => {
    const tabOptions = [];
    (tabsPlaceholder || [])
      .filter((tab) => tab.props && tab.props.fields)
      .map((tab, index) => {
        if (tab.props.hiddenTabs?.includes(tab.props.rendering.componentName)) {
          return null;
        }
        if (
          `#${getFieldValue(tab?.props?.fields, "tabHash", false)}` ===
          currentpath.hash
        ) {
          setActiveTabForDrp({
            value: getFieldValue(tab?.props?.fields, "tabName", false),
            index,
          });
          // setScrollTo("tabs-container");
        }
        tabOptions.push({
          value: tab?.props?.fields?.tabName?.value,
          id: "tab_drp" + index,
          label: <Text field={tab?.props?.fields?.tabName} />,
          index: index,
        });
      });
    setOptions(tabOptions);
    setHiddenTabsList(tabsPlaceholder[0]?.props?.hiddenTabs);
  }, [tabsPlaceholder[0]?.props?.hiddenTabs]);

  useEffect(() => {
    if (scrollTo) {
      window.scrollTo(
        0,
        document.getElementById(scrollTo).offsetTop -
          document.getElementById("header").offsetHeight
      );
    }
  }, [scrollTo]);
  const [prevBtnClass, setPrevBtnClass] = useState("disabled");
  const [nextBtnClass, setNextBtnClass] = useState("disabled");
  useEffect(() => {
    document.querySelector(".nav-tabs").onscroll = function () {
      scrollTabFunction();
    };
    scrollTabFunction();
  }, [tabsPlaceholder[0]?.props?.hiddenTabs]);

  const next = () => {
    var container = document.querySelector(".nav-tabs");
    sideScroll(container, "right", 25, 300, 30);
  };

  const prev = () => {
    var container = document.querySelector(".nav-tabs");
    sideScroll(container, "left", 25, 300, 30);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    for (let i = 0; i < 10; i++) {
      setTimeout(function timer() {
        if (direction == "left") {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
      }, i * 100);
    }
  };

  const widthOfList = () => {
    var itemsWidth = 0;
    var arr = document.querySelectorAll(".nav-tabs .nav-link");
    arr.forEach((element) => {
      var itemWidth = element.offsetWidth;
      itemsWidth += itemWidth;
    });
    return itemsWidth;
  };

  const scrollTabFunction = () => {
    if (document.querySelector(".nav-tabs").scrollLeft == 0)
      setPrevBtnClass("disabled");
    else setPrevBtnClass("");
    var listWidth = widthOfList();
    if (
      document.querySelector(".nav-tabs").offsetLeft > listWidth ||
      listWidth - document.querySelector(".scrollable-tabs").offsetWidth <=
        document.querySelector(".nav-tabs").scrollLeft
    )
      setNextBtnClass("disabled");
    else setNextBtnClass("");

    if (listWidth <= document.querySelector(".scrollable-tabs").offsetWidth) {
      setPrevBtnClass("d-none");
      setNextBtnClass("d-none");
    }
  };

  return (
    <>
      {/* Tabs title configured */}
      <div id="tabs-container" className="row">
        <div className="col-12 d-none d-xl-block">
          <div className="scrollable-tabs">
            <div className="carousel-btns">
              <a
                className={`carousel-btn-prev ${prevBtnClass}`}
                onClick={() => prev()}
              >
                <img src={`${ICON_PATH}/Group_2349_2.svg`} alt="Previous" />
              </a>
              <a
                className={`carousel-btn-next ${nextBtnClass}`}
                onClick={() => next()}
              >
                <img src={`${ICON_PATH}/Group_2344.svg`} alt="Next" />
              </a>
            </div>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {/*
              When the page is editing, we hide the tab headings, because they are not updated when xEditor adds or removes a tab rendering.
              Instead, we show the tab header inline with the tab contents (see Styleguide-Layout-Tabs-Tab).
            */}

              {!isEditing &&
                (tabsPlaceholder || [])
                  .filter((tab) => tab.props && tab.props.fields)
                  .map((tab, index) =>
                    !tab.props.hiddenTabs?.includes(
                      tab.props.rendering.componentName
                    ) ? (
                      tab.props.fields?.Applicablestates ? (
                        tab.props.fields.Applicablestates.map((state, ind) =>
                          state.fields.stateName.value == MemberStateName ? (
                            state.fields?.lobLevelList ? (
                              state.fields.lobLevelList?.map((lob, ind1) =>
                                lob.fields.lobName.value == memberLOB ? (
                                  <li
                                    className="nav-item"
                                    key={`tab${index}`}
                                    role="tab"
                                    id={`tab${index}`}
                                  >
                                    <a
                                      className={`nav-link ${
                                        index === activeTabIndex
                                          ? "active"
                                          : null
                                      }`}
                                      onClick={(e) =>
                                        setActiveTab(
                                          e,
                                          index,
                                          tab?.props?.fields?.tabName?.value
                                        )
                                      }
                                      href="#tab"
                                    >
                                      <Text field={tab.props.fields.tabName} />
                                    </a>
                                  </li>
                                ) : null
                              )
                            ) : (
                              <li
                                className="nav-item"
                                key={`tab${index}`}
                                id={`tab${index}`}
                                role="tab"
                              >
                                <a
                                  className={`nav-link ${
                                    index === activeTabIndex ? "active" : null
                                  }`}
                                  onClick={(e) =>
                                    setActiveTab(
                                      e,
                                      index,
                                      tab?.props?.fields?.tabName?.value
                                    )
                                  }
                                  href="#tab"
                                >
                                  <Text field={tab.props.fields.tabName} />
                                </a>
                              </li>
                            )
                          ) : null
                        )
                      ) : (
                        <li
                          className="nav-item"
                          key={`tab${index}`}
                          id={`tab${index}`}
                          role="tab"
                        >
                          <a
                            className={`nav-link ${
                              index === activeTabIndex ? "active" : null
                            }`}
                            onClick={(e) =>
                              setActiveTab(
                                e,
                                index,
                                tab?.props?.fields?.tabName?.value
                              )
                            }
                            href="#tab"
                          >
                            {isMarketplaceMember &&
                            tab?.props?.fields?.tabName?.value ==
                              "My Healthy Rewards" ? (
                              <Text
                                field={tab.props.fields.tabNameMarketPlace}
                              />
                            ) : (
                              <Text field={tab.props.fields.tabName} />
                            )}
                          </a>
                        </li>
                      )
                    ) : null
                  )}
            </ul>
          </div>
        </div>
      </div>
      <div className="row d-xl-none">
        <div className="col-sm-12">
          <div className="form-tab p-2">
            <CustomSelect
              field={{
                name: "select_tabs",
                value: selectedValue,
              }}
              options={options}
              onChange={setActiveTabForDrp}
              isFormik={false}
            />
          </div>
          <hr className="dropdown-divider d-md-none" />
        </div>
      </div>
      {/* Tabs content container */}
      <div className="row">
        <div className="col-12">
          <div className="tab-content" id="myTabContent">
            {(tabsPlaceholder || []).map((tab, index) => {
              const isValid = tab.props && tab.props.fields;

              // allow experience editor markup components to render
              if (!isValid && isEditing) return tab;

              validTabIndex += 1;
              if (
                tab.props.hiddenTabs?.includes(
                  tab.props.rendering.componentName
                )
              ) {
                return null;
              }

              // we render the tab either if it's active - or we're editing,
              // in which case we stack all tabs for visual editing
              if (activeTabIndex === validTabIndex - 1 || isEditing) {
                const tabWithMoreProps = React.cloneElement(tab, {
                  setActiveTab,
                  tabOptions: options,
                });
                return tabWithMoreProps;
              }

              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const tabsComponentWithPlaceholderInjected = withPlaceholder({
  placeholder: "jss-tabs",
  prop: "tabsPlaceholder",
})(CommonLayoutTabs);

const tabsWithPlaceholderAndSitecoreContext = withSitecoreContext()(
  tabsComponentWithPlaceholderInjected
);

export default tabsWithPlaceholderAndSitecoreContext;
