import React, { useContext, useEffect } from "react";
import { SitecoreContextReactContext } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import { ModalContext } from "../../contexts/modal.context";
import {
  UNAUTHORIZED,
  SUPPORT_USER,
  TERMED_MEMBER,
  SWITCHED_TO_DEPENDENT,
  WHO_CAN_VIEW,
  ACTION_UNAVAILABLE,
  UNDER_MAINTENANCE,
} from "../../define.constants";
import { getConfigByKey } from "../../utils/helpers";

/**
 * Below are the details for each param!
 *
 * children: React component!
 * handleClick: click handler!
 * authorize: Object with access control (currently available for support,
 * termed and switched user)!
 * type = "button": default type as button!
 * href = "#": default prop as #!
 * isFormik: to disable preventDefault for actions being taken on Formik forms!
 * byPassAccessControl: if need to bypass everything
 * and call default actions as recieved!
 * actionUnavailable: if no URL/ action is available on link or button!
 * underMaintenance: if action is under maintenance!
 * customMessage: only applicable with actionUnavailable and underMaintenance prop!
 *
 * @param {*} param0
 * @returns
 */
const WhoIsAuthorized = ({
  children,
  handleClick,
  authorize = {},
  type = "button",
  href = "#",
  isFormik = false,
  byPassAccessControl = false,
  actionUnavailable = false,
  underMaintenance = false,
  customMessage = "",
}) => {
  const { isSupportUser, isTermedMember, isSwitchedToDependent } =
    useContext(AppContext);
  const { openModal } = useContext(ModalContext);
  const {
    context: {
      route: { placeholders },
    },
  } = useContext(SitecoreContextReactContext);

  const showMessage = (e, key) => {
    triggerPreventDefault(e);
    if ((actionUnavailable || underMaintenance) && customMessage) {
      triggerOpenModal(customMessage);
    } else {
      triggerOpenModal(getConfigByKey(key, placeholders?.["jss-config"]));
    }
  };

  const triggerOpenModal = (message) => {
    openModal({
      header: <></>,
      content: <>{message}</>,
      footer: <></>,
      version: 1,
    });
  };

  const triggerPreventDefault = (e) => {
    if (!isFormik) {
      e.preventDefault();
    }
  };

  if (underMaintenance) {
    return children({
      handleClick: (e) => showMessage(e, UNDER_MAINTENANCE),
      href: "#",
    });
  }

  if (
    (href === "#" && typeof handleClick !== "function") ||
    actionUnavailable
  ) {
    return children({
      handleClick: (e) => showMessage(e, ACTION_UNAVAILABLE),
      href: "#",
    });
  }

  if (
    (!isSupportUser && !isTermedMember && !isSwitchedToDependent) ||
    byPassAccessControl
  ) {
    return children({ handleClick, href });
  }

  if (Object.keys(authorize).length) {
    /**
     * Termed member will supercede support user's access level!
     */
    if (isTermedMember && !authorize[TERMED_MEMBER]) {
      return children({
        //TODO: uncomment `handleClick` and comment `disabledClass` to show popup for termed member as well!
        // handleClick: (e) => showMessage(e, UNAUTHORIZED),
        disabledClass: type === "link" ? "disabled-link" : "disabled",
        // href,
      });
    }

    if (isSupportUser && !authorize[SUPPORT_USER]) {
      return children({
        handleClick: (e) => showMessage(e, UNAUTHORIZED),
        href: "#",
      });
    }

    if (
      isSwitchedToDependent &&
      authorize[SWITCHED_TO_DEPENDENT] !== undefined &&
      !authorize[SWITCHED_TO_DEPENDENT]
    ) {
      return children({
        handleClick: (e) => showMessage(e, WHO_CAN_VIEW),
        href: "#",
      });
    }
  }

  return children({ handleClick, href });
};

export default WhoIsAuthorized;
