import Axios from "./axios/provider";

export const setDefaultMFADevice = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.SetDefaultMFADevice,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteUseMFADevice = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.DeleteUseMFADevice,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const activateUserMFADevice = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ActivateUserMFADevice,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createUserMFADevice = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.CreateUserMFADevice,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllUserDevices = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetAllUserDevices,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const changeEmailInitiate = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ChangeEmailInitiate,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const changeEmailValidate = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ChangeEmailValidate,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.UpdatePassword,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const auditPingMFA = async (data) => {
  try {
    const response = Axios.post(constants.apigeeURLs.AuditPingMFA, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const requestDeleteMember = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.DeleteMemberAccount,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
