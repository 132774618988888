import React, { useState, useContext } from "react";
import { Formik, Field, Form } from "formik";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import * as Yup from "yup";
import {
  ICON_PATH,
  PWD_MAX_LENGTH,
  PWD_MINI_LENGTH,
  PWD_VALIDATION_REGEX1,
  PWD_VALIDATION_REGEX2,
  SUCCESS_RESPONSE,
  SUCCESS_RESULT_CODE,
} from "../../define.constants";
import { highlightBorderOnError } from "../../utils/helpers";
import { ENGLISH_LANG, STEP_PASS_CONFIRMATION } from "./constants";
import * as RegistrationAPI from "../../services/RegistrationAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ErrorContext } from "../../contexts/error.context";

const ResetPwrd = (props) => {
  const { fields, NavigateToSteps, email } = props;
  const [pwdValidate, setPwdValidate] = useState(false);
  const eye = `${ICON_PATH}/visibility-24px.svg`;
  const eyeSlash = `${ICON_PATH}/visibility_off_black_24dp.svg`;
  const [visible, setVisible] = useState(false); //for Password Field
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const pwdVisibilityIcon = (
    <img
      src={visible ? eye : eyeSlash}
      alt={fields.lblPwd.value}
      onClick={() => setVisible(!visible)}
      className="pwd-icon"
    />
  );
  const pwdInputType = visible ? "text" : "password";

  const [visible2, setVisible2] = useState(false); //for Confirm Password Field
  const pwdVisibilityIcon2 = (
    <img
      src={visible2 ? eye : eyeSlash}
      alt={fields.lblPwd.value}
      onClick={() => setVisible2(!visible2)}
      className="pwd-icon"
    />
  );
  const pwdInputType2 = visible2 ? "text" : "password";
  function validatepassword(value) {
    setPwdValidate(false);
    let error;
    if (!value) {
      error = " ";
    } else {
      if (PWD_VALIDATION_REGEX1.test(value)) {
        if (value.length < PWD_MINI_LENGTH) {
          error = getFieldValue(fields, "pwdMinLengthError");
        } else {
          if (value.length > PWD_MAX_LENGTH) {
            error = getFieldValue(fields, "pwdMaxLengthError");
          } else {
            if (PWD_VALIDATION_REGEX2.test(value)) {
              setPwdValidate(true);
            } else {
              error = getFieldValue(fields, "pwdCasingError");
            }
          }
        }
      } else {
        error = getFieldValue(fields, "pwdValidationMsg");
        setPwdValidate(false);
      }
    }
    return error;
  }

  const DisplayValidationerror = (validationerror) => {
    return <div className="validation-msg">{validationerror}</div>;
  };
  return (
    <>
      <Formik
        initialValues={{
          pwd: "",
          confirmPwd: "",
        }}
        validationSchema={Yup.object({
          confirmPwd: Yup.string()
            .matches(
              PWD_VALIDATION_REGEX1,
              getFieldValue(fields, "pwdValidationMsg")
            )
            .oneOf([Yup.ref("pwd"), null], getFieldValue(fields, "pwdNoMatch")),
        })}
        onSubmit={(values) => {
          setSpinner(true);
          RegistrationAPI.getResetPassword({
            Language: ENGLISH_LANG,
            NewPassword: values.pwd,
            ConfirmPassword: values.confirmPwd,
            EmailId: email,
          })
            .then((data) => {
              if (data.ResultCode === SUCCESS_RESULT_CODE) {
                if (data.Message === SUCCESS_RESPONSE) {
                  NavigateToSteps(STEP_PASS_CONFIRMATION);
                } else {
                  setError({ customMessage: data.Message });
                  //alert(data.Message);
                }
              } else {
                setError({
                  customMessage: getFieldValue(
                    fields,
                    "emailVerificationError"
                  ),
                });
                //alert(getFieldValue(fields, "emailVerificationError"));
              }
            })
            .catch((err) => {
              // setError({
              //   customMessage: getFieldValue(fields, "secCodeSendError"),
              // });
              setError({ err });
            })
            .finally(() => {
              setSpinner(false);
            });
        }}
      >
        {({ touched, errors, values }) => (
          <Form>
            <h5 className="card-title text-center">
              <Text field={fields.lblResetYourPwd} />
            </h5>
            <div className="pass-wrapper">
              <div className="row-title">
                <img
                  src={`${ICON_PATH}/check-green.svg`}
                  className="check-green"
                  style={{
                    display: pwdValidate ? "inline" : "none",
                  }}
                  alt={fields.lblPwd.value}
                />
                <Text field={fields.lblPwd} />
              </div>

              <Field
                name="pwd"
                type={pwdInputType}
                className={`form-control ${highlightBorderOnError(
                  errors.pwd,
                  touched.pwd
                )}`}
                validate={validatepassword}
              />
              <i>{pwdVisibilityIcon}</i>
            </div>

            {DisplayValidationerror(errors.pwd)}

            <div className="pass-wrapper">
              <div className="row-title">
                <img
                  src={`${ICON_PATH}/check-green.svg`}
                  className="check-green"
                  style={{
                    display:
                      !errors.confirmPwd && values.confirmPwd
                        ? "inline"
                        : "none",
                  }}
                  alt={fields.lblConfirmPwd.value}
                />
                <Text field={fields.lblConfirmPwd} />
              </div>
              <Field
                name="confirmPwd"
                type={pwdInputType2}
                className={`form-control ${highlightBorderOnError(
                  errors.confirmPwd,
                  touched.confirmPwd
                )}`}
              />
              <i>{pwdVisibilityIcon2}</i>
            </div>
            {DisplayValidationerror(errors.confirmPwd)}
            <div className="security-code">
              <Text field={fields.lblPassGuidelines} />
            </div>
            <div className="recovery-btn col-md-12">
              <button type="submit" className="btn btn-primary">
                <Text field={fields.lblResetPwd} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ResetPwrd;
