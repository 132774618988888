import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardNMMedicaid = ({ fields, idCard, result }) => {
  const Ratecode = idCard?.RateCode?.trim();

  let NMRate = "";

  if (Ratecode?.slice(-3) === "100") {
    NMRate = "ABP";
  } else if (Ratecode?.slice(-2) === "EI") {
    NMRate = "ABP Exempt";
  } else {
    NMRate = "State Plan";
  }
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardWI"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/NM/Medicaid/NM_Front_IDCard.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td>
                  <div className="clearfix">
                    <div className="float-start widththree nm-margin-top">
                      <ul className="list-unstyled TXmrgn">
                        <li>
                          <br></br>
                          <br></br>
                          <b>
                            <Text field={fields.lblMember} />
                          </b>
                          &nbsp; {idCard?.Member_Name}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblMisc} />
                          </b>
                          &nbsp; {idCard?.OriginalMemberProfile?.Number}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblIdentification} />
                          </b>
                          &nbsp; {idCard?.OriginalMemberProfile?.Number}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblDOB} />
                          </b>
                          &nbsp; {idCard?.Member_DOB}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblEffectiveDate} />
                          </b>
                          &nbsp; {idCard?.MemberEffectiveDate}
                        </li>
                        <li>
                          <br></br>
                          <b>
                            <Text field={fields?.lblPrimaryCare} />
                          </b>
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblPcpName} />
                          </b>
                          {idCard?.PCPName_Current}
                          <br></br>
                          <br></br>
                          <br></br>
                          <b>
                            <li>{NMRate}</li>
                          </b>
                        </li>
                        <li className="member-id-card-txt-NM">
                          <br></br>
                          <br></br>
                          <Text field={fields?.lblRemitClaimsAddress} />
                        </li>
                      </ul>
                    </div>
                    <div className="float-start widthtwo">
                      <div>
                        <ul className="list-unstyled TXmrgn ">
                          <li>
                            <br></br>
                            <b>
                              <Text field={fields?.lblBin} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxBIN}
                          </li>
                          <li>
                            <b>
                              <Text field={fields?.lblPCN} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxPCN}
                          </li>
                          <li>
                            <b>
                              <Text field={fields?.lblGroup} />
                            </b>
                            &nbsp; {result?.IdCardValues?.RxGRP}
                          </li>
                          <li></li>
                          <br></br>
                          <br></br>
                          <Text field={fields?.lblPayments} />
                          &nbsp;&nbsp;
                          <b>
                            <Text field={fields?.lblNote} />
                          </b>
                          <li></li>
                          <Text field={fields?.lblTeladocVirtualService} />
                          &nbsp;
                          <b>
                            <Text field={fields?.lblCardValidNote} />
                          </b>
                          <li></li>
                          <Text field={fields?.lblCardGuaranteeNoteNY} />
                          &nbsp;&nbsp;
                          <b>
                            <Text field={fields?.lblTo} />
                          </b>
                          <li></li>
                          <Text field={fields?.lblProviderKY} />
                          &nbsp;&nbsp;&nbsp;
                          <b>
                            <Text field={fields?.lblOneGoldenShore} />
                          </b>
                          <li></li>
                          <br></br>
                          <Text field={fields?.lblPaymentsNote} />
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/NM/Medicaid/NM_Back_IDCard.png)`,
          }}
          className="member-id-card-back member-id-card-nm"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
            >
              <tbody>
                <br></br>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblMembers} />
                    </b>
                    <tr></tr>
                    <td nowrap="nowrap"></td>
                    &nbsp;
                    <Text field={fields?.lblMolinaHealthcareSpeakNurseNY} />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblProviders} />
                    </b>
                    &nbsp;
                    <Text field={fields?.lblMolinaHealthcareContactNY} />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblPrescriptionDrug} />
                    </b>
                    <Text field={fields?.lblMolinaHealthcareContactNY} />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblNonPreferredDrugs} />
                    </b>
                    <Text
                      field={fields?.lblMolinaHealthcareSpeakNurseNYContact}
                    />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblPreferredDrugs} />
                    </b>
                    <Text field={fields?.lblForNonLifeCondition} />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblGenericDrugs} />
                    </b>
                    <Text field={fields?.lblPharmacistsAddress} />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblPharmacists} />
                    </b>
                    <Text field={fields?.lblPhpPhone} />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblOverTheCounterDrugs} />
                    </b>
                    <Text field={fields?.lblIdentificationNumber} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardNMMedicaid;
