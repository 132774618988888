import React, { useRef, useContext, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ICON_PATH, KY } from "../../define.constants";
import {
  GLOBAL_VARIABLE,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  INVALID_FORMAT_MESSAGE,
  INVALID_MIN_MESSAGE,
  INVALID_MAX_MESSAGE,
} from "../../define.constants";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import { AppContext } from "../../contexts/app.context";
//import Popper from "@material-ui/core/Popper";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CustomToolbar from "./Toolbar";

/**
 * If Input box and calendar icon is not required pass showInput & isOpen attributes
 * @param {*} param0
 * @returns
 */
const Calendar = (props) => {
  let { prefLang, MemberStateName } = useContext(AppContext);
  const {
    handleChange = null,
    showInput = true,
    isOpen = false,
    invalidDateMessage = INVALID_FORMAT_MESSAGE,
    minDateMessage = INVALID_MIN_MESSAGE,
    maxDateMessage = INVALID_MAX_MESSAGE,
    value,
    anchor,
    ...rest
  } = props;
  const localeMap = {
    en: enLocale,
    es: esLocale,
  };

  const langcode =
    SPANISH_LANG_TEXT === prefLang.toLowerCase() ||
    SPANISH_LANG_CODE === prefLang.toLowerCase()
      ? SPANISH_LANG_CODE
      : ENGLISH_LANG_CODE;

  const anchorElRef = useRef();

  const onChangeHandler = (date) => {
    handleChange && handleChange(date);
  };

  useEffect(() => {
    if (isOpen) {
      changeTheme();
    }
  }, [isOpen]);

  const changeTheme = () => {
    if (MemberStateName === KY) {
      setTimeout(
        () =>
          document
            .getElementsByClassName("MuiPopover-root")[0]
            .classList.add("state-KY"),
        0
      );
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[langcode]}>
      {showInput ? (
        <KeyboardDatePicker
          ToolbarComponent={(props) => (
            <CustomToolbar {...props} locale={localeMap[langcode]} />
          )}
          margin="normal"
          format="MM/dd/yyyy"
          value={value}
          onChange={onChangeHandler}
          placeholder={GLOBAL_VARIABLE.lblCalenderPlaceholder}
          orientation="portrait"
          autoOk="true"
          variant="inline"
          invalidDateMessage={invalidDateMessage}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          PopoverProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          onOpen={changeTheme}
          disableFuture
          // onOpen={handleHeaderChange}
          // onClick={handleSelectedChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={
            <img
              className="calendar-img"
              src={`${ICON_PATH}/calendar_today-24px.svg`}
              alt={GLOBAL_VARIABLE.lblAltCalender}
            />
          }
          {...rest}
        />
      ) : (
        <KeyboardDatePicker
          ToolbarComponent={(props) => (
            <CustomToolbar {...props} locale={localeMap[langcode]} />
          )}
          open={isOpen}
          margin="normal"
          TextFieldComponent={(props) => (
            <div
              ref={anchorElRef}
              onClick={props.onClick}
              onKeyDown={props.onKeyDown}
            ></div>
          )}
          format="MM/dd/yyyy"
          value={value}
          onChange={onChangeHandler}
          PopoverProps={{
            anchorEl: anchor,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          orientation="portrait"
          autoOk="false"
          variant="inline"
          invalidDateMessage={invalidDateMessage}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          disableFuture
          {...rest}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};
export default Calendar;
