import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { STEP_USER_ID } from "./constants";

const Confirmation = (props) => {
  const { fields, selectedOption, UserId } = props;

  return (
    <>
      <h5 className="card-title text-center">
        <Text field={fields.lblAllSet} />
      </h5>
      {selectedOption === STEP_USER_ID ? (
        <>
          <div className="security-code user-id">
            <Text field={fields.lblUserIdConfirmation} />
            <span>{UserId}</span>
          </div>
        </>
      ) : (
        <div className="security-code">
          <Text field={fields.lblPassConfirmation} />
        </div>
      )}

      <div className="recovery-btn col-md-12">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            location = "/";
          }}
        >
          <Text field={fields.btnLogin} />
        </button>
      </div>
    </>
  );
};

export default Confirmation;
