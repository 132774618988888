import React from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Currency_Symbol } from "../../define.constants";

export const currencyValue = (value) => {
  return `${Currency_Symbol}` + `${value}`;
};

export const renderStatusCss = (status) => {
  if (status != null && status != undefined && status != "") {
    const statusString = status?.toLowerCase();
    switch (statusString) {
      case "approved":
        return "green-bg";
      case "pend":
        return "yellow-bg";
      case "contact your doctor":
        return "red-bg";
      case "inprocess":
        return "yellow-bg";
      case "medreview":
        return "orange-bg";
      case "closed":
        return "lightgrey-bg";
      default:
        return "orange-bg";
    }
  }
};

export const renderStatus = (status, fields) => {
  if (status != null && status != undefined && status != "") {
    const statusString = status?.toLowerCase();
    switch (statusString) {
      case "pend":
        return getFieldValue(fields, "lblServiceAuthPending", false);
      case "contact your doctor":
        return getFieldValue(fields, "lblContactYourDoc", false);
      case "closed":
        return getFieldValue(fields, "lblclosed", false);
      case "inprocess":
        return getFieldValue(fields, "lblInProcess", false);
      case "medreview":
        return getFieldValue(fields, "lblMedReview", false);
      case "approved":
        return getFieldValue(fields, "lblApproved", false);
      default:
        return statusString;
    }
  }
};

export const renderHelperText = (status, fields) => {
  if (status != null && status != undefined && status != "") {
    const statusString = status?.toLowerCase();
    switch (statusString) {
      case "pend":
        return getFieldValue(fields, "lblPendingHelperText", false);
      case "contact your doctor":
        return getFieldValue(fields, "lblContactYourDocHelperText", false);
      case "closed":
        return getFieldValue(fields, "lblClosedHelperText", false);
      case "inprocess":
        return getFieldValue(fields, "lblInProcessHelperText", false);
      case "medreview":
        return getFieldValue(fields, "lblMedReviewHelperText", false);
      case "approved":
        return getFieldValue(fields, "lblApprovedHelperText", false);
      default:
        return "";
    }
  }
};
