import React, { useContext, useEffect, useState } from "react";
import { withModalProvider } from "../../hoc/withModalProvider";
import { auditPingMFA } from "../../services/AccountSettingsMfaAPI";
import {
  FAILURE_RESPONSE,
  UPDATE_PASSWORD_AUTH_PING_WIDGET,
  SUCCESS_RESPONSE,
} from "../../define.constants";
import * as commonAPI from "../../services/Common";

const MFAAuthWidget = (props) => {
  const userId = props.userId;
  const lang = props.lang;
  console.log("userId: ", userId);
  console.log("lang: ", lang);

  commonAPI
    .getWidgetEnv()
    .then((responseData) => {
      var props = {
        config: {
          method: "runFlow",
          apiRoot: responseData.apiRoot,
          accessToken: responseData.accessToken,
          companyId: responseData.companyId,
          policyId: responseData.policyId.MFAAuth,
          parameters: {
            username: userId,
            lang: lang,
          },
        },
        useModal: false,
        successCallback,
        errorCallback,
        onCloseModal,
      };

      /*** Invoke the Widget ****/
      davinci.skRenderScreen(document.querySelector(".skWidgetModal"), props);
    })
    .catch((error) => console.log("error", error));

  const auditMFA = (data) => {
    auditPingMFA({
      DeviceID: data?.additionalProperties?.p1DeviceId ?? "",
      DeviceType: data?.additionalProperties?.p1DeviceType ?? "",
      PingAPIStatus: data.success
        ? SUCCESS_RESPONSE.toUpperCase()
        : FAILURE_RESPONSE.toUpperCase(),
      Functionality: UPDATE_PASSWORD_AUTH_PING_WIDGET,
    });
  };

  function successCallback(response) {
    console.log("successCallback");
    console.log(response);

    // On authentication success, invoke referenced method
    props.onAuthSuccess();

    // Audit PING widget's success response - used for authentication
    auditMFA(response);
  }

  function errorCallback(error) {
    console.log("errorCallback");
    console.log(error);

    // Audit PING widget's failure response - used for authentication
    auditMFA(error);
  }

  function onCloseModal() {
    console.log("onCloseModal");
  }
  //Widget End

  return (
    <>
      <div id="widget" className="skWidgetModal">
        Loading...
      </div>
    </>
  );
};

export default withModalProvider(MFAAuthWidget);
