import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import { ErrorContext } from "../contexts/error.context";
import * as ViewLabResultsAPI from "../services/HeathRecordAPI";
import { MY_HEALTH_RECORD_GET_LAB_RESULTS } from "../define.widgets";
const qs = require("qs");

const useGetMemberLabResultsAPI = (searchTerm = null) => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [viewLabResultsList, setViewLabResultsList] = useState([]);
  const { memberRKSID, MemberStateName } = useContext(AppContext);
  const [islabResultsloaded, setislabResultsloaded] = useState(false);
  const setData = (data) => {
    setViewLabResultsList(data.ResultData.LabResultRecords);

    setislabResultsloaded(true);
  };

  useEffect(() => {
    let startDate = "",
      endDate = "";
    if (searchTerm != null) {
      startDate = searchTerm["startDate"];
      endDate = searchTerm["endDate"];
    }
    let query = {
      LabDateFrom: startDate,
      LabDateTo: endDate,
    };

    setInnerSpinner(true, MY_HEALTH_RECORD_GET_LAB_RESULTS);
    ViewLabResultsAPI.getMemberLabResultsList(
      {
        MemberID: memberRKSID,
        StateCode: MemberStateName,
        LabDateFrom: startDate,
        LabDateTo: endDate,
      },
      qs.stringify(query, { addQueryPrefix: true })
    )
      .then((response) => {
        setData(response);
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(function () {
        setInnerSpinner(false, MY_HEALTH_RECORD_GET_LAB_RESULTS);
      });
  }, [searchTerm]);

  return {
    viewLabResultsList,
    islabResultsloaded,
  };
};

export { useGetMemberLabResultsAPI };
