import { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../contexts/error.context";
import { SpinnerContext } from "../contexts/spinner.context";
import { HOME_MY_MESSAGE_CARD } from "../define.widgets";
import * as MessagesAPI from "../services/MessagesAPI";
const qs = require("qs");

const useListServicesAPI = () => {
  const { setError } = useContext(ErrorContext);
  const { setInnerSpinner } = useContext(SpinnerContext);
  const [MsgServices, setMsgServices] = useState([]);

  useEffect(() => {
    setInnerSpinner(true);
    MessagesAPI.ListServicesAPI({})
      .then((response) => {
        if (response?.ResultData != undefined) {
          setMsgServices(response.ResultData.MsgServices);
        }
      })
      .catch(function (err) {
        setError({ err });
      })

      .finally(function () {
        setInnerSpinner(false);
      });
  }, []);

  return { MsgServices };
};

export { useListServicesAPI };
