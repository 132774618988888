import React, { useContext, useState, useEffect } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { healthReminderRecords, printPage, printReportDate } from "./helper";
import { useGetAlertsAPI } from "../../hooks/useGetAlertsAPI";
import { ALERT_CATEGORY_HEDIS } from "../../define.constants.js";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_WELLNESS_GET_ALERTS } from "../../define.widgets";
import { GLOBAL_VARIABLE } from "../../define.constants";
const MyWellnessHealthReminder = (props) => {
  const { alertsData } = useGetAlertsAPI();
  const [filteredRecords, setFilteredRecords] = useState([]);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  //console.log(innerSpinnerHTML);
  useEffect(() => {
    if (
      alertsData !== null &&
      alertsData !== undefined &&
      alertsData.length > 0
    ) {
      let results = alertsData?.filter(
        (x) => x.AlertCategory.toLowerCase() === ALERT_CATEGORY_HEDIS
      );
      setFilteredRecords(results);
    }
  }, [alertsData]);

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="healthReminder"
        role="tabpanel"
        aria-labelledby="tab-1"
      >
        <div className="row health-reminder no-tab-title">
          <div className="col-md-12">
            {filteredRecords === null || filteredRecords.length === 0 ? (
              <div className="col-md-12 no-assessment-records">
                <Text field={props.fields.lblNoAlertMessage} />
              </div>
            ) : (
              <>
                <ul className="list-group">
                  {filteredRecords?.slice(0).length === 0 && (
                    <li className="list-group-item">
                      {innerSpinnerHTML?.[MY_WELLNESS_GET_ALERTS]}
                      <div className="row field1">
                        <div className="col-sm-12"></div>
                      </div>
                    </li>
                  )}
                  {filteredRecords?.map((item, index) => (
                    <li className="list-group-item " key={index}>
                      {healthReminderRecords(item.AlertType, item.Description)}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default MyWellnessHealthReminder;
