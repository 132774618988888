import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const DisplayTitleItem = (props) => {
  const { nameField, valueField, outClass } = props;
  return (
    <div className={`${outClass ?? outClass}`}>
      <Text field={nameField} />
      {valueField && <span>{valueField}</span>}
    </div>
  );
};

export default DisplayTitleItem;
