import React from "react";
import {
  MI,
  WA,
  NY,
  WAFCPRGMID,
  NYMCC_PROGRAMID,
  DUAL,
  MEDICAID,
  NM,
  NM_MEDICAID_ProgramID,
} from "../../../define.constants";

export const getClaimHistoryDisclaimerNonMP = (
  state,
  descriptionListFields,
  programId,
  isNYAffinityMember,
  isFIMCMember,
  isDualEligible,
  memberLOB
) => {
  let footerDescription = null;
  const isWASateMember = state?.toLowerCase() === WA.toLowerCase();
  const isMISateMember = state?.toLowerCase() === MI.toLowerCase();
  const isNYSateMember = state?.toLowerCase() === NY.toLowerCase();
  //NM  implementation
  const isNMSateMember = state?.toLowerCase() === NM.toLowerCase();

  if (
    (isWASateMember && programId === WAFCPRGMID) ||
    (isWASateMember && isFIMCMember != null)
  ) {
    footerDescription = descriptionListFields?.lblDescriptionMIWANonMP.value;
  } else if (isMISateMember && isDualEligible && isDualEligible === DUAL) {
    footerDescription = descriptionListFields?.lblDescriptionMIWANonMP.value;
  } else if (
    isWASateMember &&
    memberLOB.toLowerCase() === MEDICAID.toLowerCase()
  ) {
    footerDescription = descriptionListFields?.lblDescriptionMIWANonMP.value;
  } else if (
    (isNYSateMember && programId === NYMCC_PROGRAMID) ||
    (isNYSateMember && isNYAffinityMember && isNYAffinityMember === true)
  ) {
    footerDescription = descriptionListFields?.lblDescriptionNYNonMP?.value;
  } //NM implementation  start
  else if (
    isNMSateMember &&
    programId === NM_MEDICAID_ProgramID &&
    memberLOB.toLowerCase() === MEDICAID.toLowerCase()
  ) {
    footerDescription = descriptionListFields?.lblDescriptionNMNonMP?.value;
  }
  //NM implementation  endelse {
  else {
    footerDescription =
      descriptionListFields?.lblDescriptionClaimHistNonMP?.value;
  }

  return footerDescription;
};
