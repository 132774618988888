import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardMedicaidWI = ({ fields, idCard }) => {
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardWI"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicaid/WI_Medicaid_front_view.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIdentification} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblDateOfBirth} />
                  </b>
                  &nbsp; {idCard?.Member_DOB}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblEffectiveDate} />
                  </b>
                  &nbsp; {idCard?.MemberEffectiveDate}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblNurseHelpLine} />
                </td>
              </tr>

              <tr>
                <td>
                  <Text field={fields.lblTtyTtd} />
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblTtyTtdSpanish} />
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblMemberServicesWI} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicaid/WI_back_view.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
            >
              <tbody>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblMembers} />
                    </b>
                    &nbsp;
                    <Text field={fields?.lblMembersQuestions} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblID24HoursWI} />
                    </b>
                    &nbsp;
                    <Text field={fields?.lblNurseAdviceLine} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblEmergencyServices} />
                    </b>
                    <Text field={fields?.lblEmergencyServiceDetails} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblPractitionersProviders} />
                    </b>
                    <Text field={fields?.lblPractitionersProvidersDetails} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblMemberPharmacyQuestions} />
                    </b>
                    <Text field={fields?.lblMemberPharmacysDetails} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblPharmacists} />
                    </b>
                    <Text field={fields?.lblPharmacistsDetails} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblClaimlblSCBackCLaim} />
                    </b>
                    <Text field={fields?.lblSCBackClaimSubmissionValue} />
                  </td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblMolinaAddress} />
                    </b>
                    <Text field={fields?.lblMolinaAddressValue} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicaidWI;
