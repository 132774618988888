import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ModalContext } from "../../../contexts/modal.context";

const Result = (props) => {
  const { closeModal } = useContext(ModalContext);
  const { fields, selectedDependents } = props;
  return (
    <>
      <div className="request-new-id-body">
        <p className="request-new-id-body-title">
          <Text field={fields.PopupRequestNewIDCardP3} />
        </p>
        {selectedDependents?.length > 0 && (
          <div className="id-details-request-id">
            <Text field={fields.PopupRequestedIDCards} />
            {selectedDependents.map((d, index) => (
              <p key={index}>{`${d.LastName}, ${d.FirstName}`}</p>
            ))}
          </div>
        )}

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={closeModal}
          >
            <Text field={fields.btnPopupOkay} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Result;
