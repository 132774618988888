import React, { useState, useEffect, useCallback, Fragment } from "react";

/* Pagination Helper class & needed functions - start */
/* Usage
<Pagination
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={onPageChanged}
          />

 const onPageChanged = (data) => {
    ...
    const offset = (data.currentPage - 1) * data.pageLimit;
    const currentList = actualList?.slice(
      offset,
      offset + data.pageLimit
    );
    ...
  };
*/

const Pagination = ({
  totalRecords = null,
  pageLimit = 30,
  pageNeighbours = 1,
  onPageChanged,
}) => {
  const LEFT_PAGE = "LEFT";
  const RIGHT_PAGE = "RIGHT";

  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }
    return range;
  };

  pageNeighbours = Math.max(0, Math.min(pageNeighbours, 2));

  const totalPages = Math.ceil(totalRecords / pageLimit);
  const [currentPage, setCurrentPage] = useState(1);

  const whenPageChanged = useCallback(() => {
    onPageChanged(
      currentPage ? Math.max(0, Math.min(currentPage, totalPages)) : 0
    );
  }, [currentPage, onPageChanged, totalPages]);

  useEffect(() => {
    whenPageChanged();
  }, [whenPageChanged]);

  const handleClick = (page, evt) => {
    evt.preventDefault();
    setCurrentPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    setCurrentPage(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    setCurrentPage(currentPage + pageNeighbours * 2 + 1);
  };

  const handleArrowLeft = (evt) => {
    evt.preventDefault();
    setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const handleArrowRight = (evt) => {
    evt.preventDefault();
    setCurrentPage(totalPages > currentPage ? currentPage + 1 : currentPage);
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  if (!totalRecords) return null;

  if (totalPages === 1) return null;

  const pages = fetchPageNumbers();
  return (
    <Fragment>
      <nav aria-label="Pagination">
        <ul className="pagination mb-pagination">
          {pages.length > 1 && (
            <li key="previous" className="page-item">
              <a
                className="page-link"
                href="#"
                aria-label="Previous"
                onClick={handleArrowLeft}
              >
                <span aria-hidden="true">
                  <span
                    className={`fa fa-angle-left px-sm-2 ${
                      currentPage <= 1 ? `disabled` : `pg-active`
                    } p-r-10`}
                  >
                    {" "}
                  </span>
                </span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
          )}
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    aria-label="Previous"
                    onClick={handleMoveLeft}
                  >
                    <span aria-hidden="true">&#183;&#183;&#183;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    aria-label="Next"
                    onClick={handleMoveRight}
                  >
                    <span aria-hidden="true">&#183;&#183;&#183;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              );

            return (
              <li
                key={index}
                className={`page-item${currentPage === page ? " active" : ""}`}
              >
                <a
                  className="page-link"
                  href="#"
                  onClick={(e) => handleClick(page, e)}
                >
                  {page}
                </a>
              </li>
            );
          })}
          {pages.length > 1 && (
            <li key={pages.length + 1} className="page-item">
              <a
                className="page-link"
                href="#"
                aria-label="Next"
                onClick={handleArrowRight}
              >
                <span aria-hidden="true">
                  <span
                    className={`fa fa-angle-right px-sm-2 ${
                      currentPage === pages.length ? `disabled` : `pg-active`
                    }`}
                  >
                    {" "}
                  </span>
                </span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          )}
        </ul>
      </nav>
    </Fragment>
  );
};

export default Pagination;
/* Pagination Helper class & needed functions - end */
