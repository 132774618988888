import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import * as ViewCPPlanNotification from "../services/ManageCommunicationAPI";
import { ErrorContext } from "../contexts/error.context";

const useCPPlanAPI = () => {
  const { setError } = useContext(ErrorContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [cPPlanList, setCPPlanList] = useState(null);
  const { memberRKSID } = useContext(AppContext);
  const [refreshSession, setRefreshSession] = useState(false);
  const [isResultsloaded, setisResultsloaded] = useState(false);

  const setData = (data) => {
    setCPPlanList(data?.ResultData);

    setisResultsloaded(true);
  };

  useEffect(() => {
    if (refreshSession) {
      callApi();
    } else {
      callApi();
    }
  }, [refreshSession]);

  const callApi = () => {
    if (memberRKSID != "") {
      setSpinner(true);
      ViewCPPlanNotification.getCommunicationPreferences({})
        .then((response) => {
          setData(response);
        })
        .catch((err) => setError({ err }))
        .finally(function () {
          setSpinner(false);
        });
    }
  };
  const refreshAPIDataAll = () => {
    setRefreshSession(true);
  };
  return { cPPlanList, isResultsloaded, refreshAPIDataAll };
};

export { useCPPlanAPI };
