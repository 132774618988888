import React, { useContext, useState } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Box } from "../../common/Box";
import { ProgressBar } from "../../common/ProgressBar";
import { BoxContainer } from "../../common/BoxContainer";
import {
  IMAGE_PATH,
  MARKETPLACE,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import { useGetAccumulatorAPI } from "../../hooks/useGetAccumulatorAPI";
import { AppContext } from "../../contexts/app.context";
import {
  getFormattedDate,
  getStateLobConfigurationItems,
} from "../../utils/helpers";
import { SpinnerContext } from "../../contexts/spinner.context";
import { HOME_TRACK_MY_SPENDING } from "../../define.widgets";

const HomeTrackMySpending = (props) => {
  const { isAIANMember, MemberStateName, memberLOB } = useContext(AppContext);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const { accum, date } = useGetAccumulatorAPI();
  const { fields } = props;
  const disclaimerDate = getFormattedDate(date, "mm/dd/yyyy");
  const [hideOverlayOfVideo, setHideOverlayOfVideo] = useState("");
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");
  const playVideo = () => {
    document.getElementById("div_video").classList.add("molina-video-block");
    setHideOverlayOfVideo("d-none");
    document.querySelector("#div_video iframe").src =
      getFieldValue(fields, "videoLink", null).href + "?autoplay=1";
  };
  const list = getStateLobConfigurationItems(
    props.fields,
    MemberStateName,
    memberLOB,
    ""
  );
  const isMarketplaceMember = memberLOB.toLowerCase() === MARKETPLACE;
  const accumValue =
    accum?.DeductibleIndividualMedicalLimit > 0 ||
    accum?.DeductibleIndividualPharmacyLimit > 0 ||
    accum?.IndividualLimit > 0
      ? true
      : false;

  return !isAIANMember && isMarketplaceMember ? (
    <div className="row trackmyspending vb">
      <BoxContainer>
        <Box title={fields.heading} subTitle={fields.link}>
          {innerSpinnerHTML?.[HOME_TRACK_MY_SPENDING]}
          <div className="row">
            <div className="col-lg-6 col-sm-12 mb-4">
              <div className="molina-progress-bar-list">
                {accumValue ? (
                  <>
                    {accum?.DeductibleIndividualMedicalLimit > 0 && (
                      <ProgressBar
                        title={fields.medicalLabel}
                        description={
                          list[0]?.fields?.lblMedicalInfoStateSpecific.value
                        }
                        total={accum.DeductibleIndividualMedicalLimit}
                        actual={accum.DeductibleIndividualMedicalAccumValue}
                        id="Home_TrackMySpending_Medical"
                      />
                    )}
                    {accum?.DeductibleIndividualPharmacyLimit > 0 && (
                      <ProgressBar
                        title={fields.pharmacyLabel}
                        description={
                          list[0]?.fields?.lblPharmacyInfoStateSpecific.value
                        }
                        total={accum.DeductibleIndividualPharmacyLimit}
                        actual={accum.DeductibleIndividualPharmacyAccumValue}
                        id="Home_TrackMySpending_Pharmacy"
                      />
                    )}
                    {accum?.IndividualLimit > 0 && (
                      <ProgressBar
                        title={fields.annualLabel}
                        description={
                          list[0]?.fields?.lblAnnualInfoStateSpecific.value
                        }
                        total={accum.IndividualLimit}
                        actual={accum.IndividualAccumValue}
                        id="Home_TrackMySpending_Annual"
                      />
                    )}
                  </>
                ) : (
                  <>
                    <ProgressBar
                      title={fields.medicalLabel}
                      description={
                        list[0]?.fields?.lblMedicalInfoStateSpecific.value
                      }
                      total={0}
                      actual={0}
                      id="Home_TrackMySpending_Medical0"
                    />
                    <ProgressBar
                      title={fields.pharmacyLabel}
                      description={
                        list[0]?.fields?.lblPharmacyInfoStateSpecific.value
                      }
                      total={0}
                      actual={0}
                      id="Home_TrackMySpending_Pharmacy0"
                    />
                    <ProgressBar
                      title={fields.annualLabel}
                      description={
                        list[0]?.fields?.lblAnnualInfoStateSpecific.value
                      }
                      total={0}
                      actual={0}
                      id="Home_TrackMySpending_Annual0"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 mb-4">
              <div id="div_video" className="position-relative">
                <picture>
                  <source
                    srcSet={`${IMAGE_PATH}/PlaceholderVideoGraphic.svg`}
                    type="image/svg"
                  />
                  <img
                    src={`${IMAGE_PATH}/PlaceholderVideoGraphic.svg`}
                    className="img-fluid"
                    alt={getFieldValue(fields, "videoContentAlt")}
                  />
                  <div className="video-placeholder-text">
                    <div>{getFieldValue(fields, "videoPlaceholderText")}</div>
                  </div>
                </picture>
                <div className="embed-responsive embed-responsive-16by9 d-none">
                  <iframe
                    className="embed-responsive-item"
                    allow="autoplay; encrypted-media"
                    src={getFieldValue(fields, "videoLink")?.href}
                    allowFullScreen=""
                  ></iframe>
                </div>
                <a
                  rel="nofollow"
                  onClick={playVideo}
                  className={hideOverlayOfVideo}
                >
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12 col-sm-12 text-center">
              <div className="track-msg">
                <Text field={fields.description1} />
                {` ${disclaimerDate}. `}
                <Text field={fields.description2} />
              </div>
            </div>
          </div>
        </Box>
      </BoxContainer>
    </div>
  ) : null;
};

export default HomeTrackMySpending;
