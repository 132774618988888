import React, { useContext } from "react";
import {
  IMAGE_PATH,
  IL_PartB_PROGRAMID,
  IL_PartB_PLANID,
  COST_ZERO,
  IL,
} from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../../../contexts/app.context";

const ViewIdCardMedicareIL = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  let { programId, planId, MemberStateName } = useContext(AppContext);

  let portalDeductiblesDetail =
    result?.PortalDeductiblesMappingDetails &&
    result?.PortalDeductiblesMappingDetails.length > 0
      ? result?.PortalDeductiblesMappingDetails[0]
      : null;

  const isILMCare_ProgramID96 =
    MemberStateName === IL && programId === IL_PartB_PROGRAMID;

  // Flag to Show Co-Pay values
  const showCopay =
    isILMCare_ProgramID96 ||
    (programId === IL_PartB_PROGRAMID && planId === IL_PartB_PLANID);

  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardUT"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/UT/Medicare/MedicareUTFrontview.png)`,
          }}
          className="background-idcard member-id-medicare-ut"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td colSpan="2" className="plan-care-name">
                  {idCard?.PlanName}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblmemberno} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPcp} />
                  </b>
                  &nbsp; {idCard?.PCPName_Current}
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblBin} />
                  </b>
                  &nbsp;
                  {/* <Text field={fields.lblRxBinValue} /> */}
                  {result.PortalDeductiblesMappingDetails[0]?.RxBIN}
                </td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPcpTel} />
                  </b>
                  &nbsp; {idCard?.PCPPhone_Current}
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblPCN} />
                  </b>
                  {/* &nbsp; <Text field={fields.lblRxpcnValue} /> */}
                  &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxPCN}
                </td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblGroup} />
                  </b>
                  &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxGroup}
                </td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblMedicalCopays} />
                    </b>
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}

                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblRxid} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblOfficeVisits} />
                    </b>
                    &nbsp;
                    {result?.PortalDeductiblesMappingDetails[0]
                      ?.OfficeVisitCoPay ?? COST_ZERO}
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}
                <td>&nbsp;</td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblSpecialistVisits} />
                    </b>
                    &nbsp;
                    {result?.PortalDeductiblesMappingDetails[0]
                      ?.SpecialistVisitCoPay ?? COST_ZERO}
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}
                <td>&nbsp;</td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblUrgentCare} />
                    </b>
                    &nbsp;
                    {result?.PortalDeductiblesMappingDetails[0]
                      ?.UrgentCareCoPay ?? COST_ZERO}
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}
                <td>&nbsp;</td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblErVisits} />
                    </b>
                    &nbsp;
                    {result?.PortalDeductiblesMappingDetails[0]?.ERVisitCoPay ??
                      COST_ZERO}
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIssuedDate} />
                  </b>
                  &nbsp; {formatToday()}
                </td>
                <td>
                  &nbsp;&nbsp;
                  {result?.PortalDeductiblesMappingDetails[0]?.ContractPlanId}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicare/Medicare_ID_Card_Molina_BLANK_220914_r1_FNL2.png)`,
          }}
          className="member-id-card-back"
          id="backMedicare"
        >
          <table
            width="96%"
            border="0"
            cellPadding="2"
            cellSpacing="0"
            id="memberCurrentID"
            //style={{ fontSize: "12px" }}
          >
            <tbody>
              <tr>
                <td>
                  <div className="TXbackwidth">
                    <ul className="list-unstyled TXmrgn">
                      <li>
                        <b>
                          <Text field={fields.lblIDBackServices} />
                        </b>
                        &nbsp;
                        {portalDeductiblesDetail?.MS_No}&nbsp;
                        <Text field={fields.lblor} />
                        &nbsp;
                        <Text field={fields.lblTTyGlobalData} />
                      </li>
                      <li>
                        <Text field={fields.lblIDBackNAL} />

                        <Text field={fields.lblbackNurseEnglishData} />
                      </li>
                      <li>
                        <Text field={fields.lblIDBackNALSpanish} />

                        <Text field={fields.lblIDBackNALData} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblIDBackProvider} />
                        </b>
                        <Text field={fields.lblIDBackProviderData} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackSendClaims} />
                        </b>
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackMedicalHospital} />
                        </b>
                      </li>
                      <li>
                        <Text field={fields.lblbackmedicalData} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackPharmacy} />
                        </b>
                      </li>
                      <li>
                        <Text field={fields.lblbackPharmacyData} />
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicareIL;
