import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import { ErrorContext } from "../contexts/error.context";
import * as ViewMemberReferralsAPI from "../services/HeathRecordAPI";
import { MY_HEALTH_RECORD_VIEW_MEMBER_REFERRALS } from "../define.widgets";

const useMemberReferralsListAPI = () => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [memberReferralsList, setMemberReferralsList] = useState([]);
  const { memberRKSID, MemberStateName } = useContext(AppContext);
  const [isRefLoaded, setisRefLoaded] = useState(false);

  const setData = (response) => {
    setMemberReferralsList(
      response.ResultData.MemberReferrals.ReferralSearchDetails
    );
    setisRefLoaded(true);
  };

  useEffect(() => {
    setInnerSpinner(true, MY_HEALTH_RECORD_VIEW_MEMBER_REFERRALS);
    ViewMemberReferralsAPI.getViewMemberReferrals({
      MemberNo: memberRKSID,
      StateCode: MemberStateName,
    })
      .then((response) => {
        if (response != undefined && response != null) {
          setData(response);
        }
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(function () {
        setInnerSpinner(false, MY_HEALTH_RECORD_VIEW_MEMBER_REFERRALS);
      });
  }, [setInnerSpinner, MemberStateName, memberRKSID]);

  return {
    memberReferralsList,
    isRefLoaded,
  };
};

export { useMemberReferralsListAPI };
