import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "./../../contexts/app.context";
import {
  getStateConfiguration,
  isStateUnderFFMCategory,
  isStateUnderSBMCategory,
} from "./../../utils/helpers";
import { Link, Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { ModalContext } from "./../../contexts/modal.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  SUPPORT_USER,
  TERMED_MEMBER,
  MARKETPLACE,
  GLOBAL_VARIABLE,
  MEDICAID,
} from "../../define.constants";

function AccountSettingsPlanConfirmPopup(props) {
  const { openModal, closeModal } = useContext(ModalContext);
  let { memberLOB, MemberStateName, isDependentLoggedIn, isTermedMember } =
    useContext(AppContext);
  const { redirectToSSO } = useContext(SSORedirectContext);
  const fields = props?.fields;
  const PlanInfoPopUp = getStateConfiguration(
    fields,
    MemberStateName,
    memberLOB
  );
  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  const [hiddenButton, setHiddenButton] = useState(false);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  useEffect(() => {
    if (isDependentLoggedIn === true) {
      setHiddenButton(true);
    }
  }, [isDependentLoggedIn]);

  useEffect(() => {
    if (isMedicaidMember) {
      setHiddenButton(true);
    }
  }, [isMedicaidMember]);
  const RedirectionLink = () => {
    if (isMarketplaceMember) {
      if (isStateUnderSBMCategory(MemberStateName)) {
        return (
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: true,
              [TERMED_MEMBER]: false,
            }}
            handleClick={closeModal}
          >
            {({ handleClick, disabledClass }) => {
              return (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleClick}
                >
                  <Link
                    className="redirect"
                    target="blank"
                    field={PlanInfoPopUp?.fields.Continue}
                  />
                </button>
              );
            }}
          </WhoIsAuthorized>
        );
      } else if (isStateUnderFFMCategory(MemberStateName)) {
        return (
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: false,
            }}
            handleClick={(e) => {
              redirectToSSO({
                e,
                url: constants.apigeeURLs.GetHealthSherpaSSORedirect,
                data: {
                  RequestSource: "MobileWeb",
                },
              });
              setTimeout(() => {
                closeModal(e);
              }, 200);
            }}
          >
            {({ handleClick, disabledClass }) => {
              return (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleClick}
                >
                  {getFieldValue(PlanInfoPopUp?.fields, "Continue").text}
                </button>
              );
            }}
          </WhoIsAuthorized>
        );
      } else {
        return (
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: false,
            }}
            handleClick={closeModal}
          >
            {({ handleClick, disabledClass }) => {
              return (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleClick}
                >
                  {PlanInfoPopUp?.fields?.Continue?.value.text}
                </button>
              );
            }}
          </WhoIsAuthorized>
        );
      }
    } else {
      return (
        <WhoIsAuthorized
          authorize={{
            [SUPPORT_USER]: true,
            [TERMED_MEMBER]: false,
          }}
          handleClick={closeModal}
        >
          {({ handleClick, disabledClass }) => {
            return (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleClick}
              >
                <Link
                  className="redirect"
                  target="blank"
                  field={PlanInfoPopUp?.fields.Continue}
                />
              </button>
            );
          }}
        </WhoIsAuthorized>
      );
    }
  };
  const PlanInfoPopUpcontent = (
    <div className="account-modal">
      <div className="update-header">
        <h5>
          <Text field={PlanInfoPopUp?.fields?.Title} />
        </h5>
        <>
          <p>
            <Text field={PlanInfoPopUp?.fields?.Description} />
          </p>
        </>
      </div>
      {isMarketplaceMember && (
        <div className="account-btns">
          <button
            type="button"
            className="btn btn-default"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            {PlanInfoPopUp?.fields?.Cancel?.value.text}
          </button>
          <RedirectionLink />
        </div>
      )}
    </div>
  );

  return (
    <div>
      {hiddenButton === false && (
        <button
          type="button"
          className={
            isTermedMember
              ? " btn btn-primary update-button disabled"
              : "btn btn-primary update-button"
          }
          onClick={() =>
            openModal({
              content: PlanInfoPopUpcontent,
              version: 1,
            })
          }
        >
          <span>
            <Text field={PlanInfoPopUp?.fields?.UpdateButtonText ?? ""} />
          </span>
        </button>
      )}
    </div>
  );
}

export default withModalProvider(AccountSettingsPlanConfirmPopup);
