import React from "react";
import ReadMore from "../Common-Health-Records/ReadMore";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";

const MedicationHistoryMobileRecords = (props) => {
  const { fields, data, isMarketplaceMember } = props;
  return (
    <ul
      className="list-group no-list-title d-sm-block d-md-none"
      id="recordsToPrint"
    >
      {data?.map((item, index) => (
        <li className="list-group-item " key={index}>
          <div className="row">
            <DisplayItem
              nameField={fields.lblMedicationName1}
              valueField={item.MedicationName}
              outClass={"col-md-4 details"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
            <DisplayItem
              nameField={fields.lblGeneric1}
              valueField={item.Generic}
              outClass={"col-md-4 details"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
          </div>
          <div id={"readMoreDetails" + index} className="">
            <ReadMore props={fields}>
              <DisplayItem
                nameField={fields.lblRefillsLeft1}
                valueField={item.NumberofRefills}
                outClass={"col-md-4 details"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />
              {!isMarketplaceMember && (
                <DisplayItem
                  nameField={fields.lblDateFilled}
                  valueField={item.DatePrecriptionFilled}
                  outClass={"col-md-4 details"}
                  titleClass={"list-desc-title"}
                  detailsClass={"list-desc-details"}
                />
              )}

              <div className="close-btn" id={"closeBtnDetails" + index}></div>
            </ReadMore>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default MedicationHistoryMobileRecords;
