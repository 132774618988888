import React, { useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import {
  CreateNewMessageUrl,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  ENGLISH_LANG_CODE,
} from "../../define.constants";
import { AppContext } from "../../contexts/app.context";

const NewMessageTab = ({ fields, mobile = false }) => {
  let { isTermedMember } = useContext(AppContext);
  const { unreadInboxMsgCount, prefLang } = useContext(AppContext);
  const handleOnNewMess = (e) => {
    location =
      "/" +
      (prefLang !== "" &&
      (SPANISH_LANG_TEXT.toLowerCase() === prefLang.toLowerCase() ||
        SPANISH_LANG_CODE.toLocaleLowerCase() === prefLang.toLocaleLowerCase())
        ? SPANISH_LANG_CODE
        : ENGLISH_LANG_CODE) +
      CreateNewMessageUrl;
  };
  return (
    <>
      <div
        // className={isTermedMember ? "new-mess-link-termed" : "new-mess-link"}
        className={`${"new-mess-link"} ${
          mobile && "d-block d-sm-block d-md-none w-100 text-center my-5"
        }`}
        onClick={handleOnNewMess}
        title={getFieldValue(fields, "lblCreateMessage")}
      >
        <span>+ </span>
        <Text field={fields?.lblNewMessage} />
      </div>
    </>
  );
};

export default NewMessageTab;
