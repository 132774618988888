import React, { useContext, useState, useEffect } from "react";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import { ModalContext } from "../../contexts/modal.context";
import {
  HIDE_EMAIL_CHARACTERS,
  SUCCESS_RESPONSE,
} from "../../define.constants";
import { SpinnerContext } from "../../contexts/spinner.context";
import * as RegistrationAPI from "../../services/RegistrationAPI";
import { ErrorContext } from "../../contexts/error.context";
import { apiResponseTemplate } from "../AccountSettings-PayerWrapper/formatAPISubmitData";

const Authentication = (props) => {
  const { fields, setStep, phoneNo, email, emailVerifiedCheck } = props;
  const { closeModal } = useContext(ModalContext);
  const [securityCodebyUser, setSecurityCodebyUser] = useState("");
  const [showVerify, setShowVerify] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgIncorrectAttempt, setMsgIncorrectAttempt] = useState("");
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [attemptCount, setAttemptCountValue] = useState(0);
  const [resendButtonCount, setResendButtonCount] = useState(0);
  const [isResend, setIsResend] = useState(false);
  let emailNameLength = email.substring(0, email.lastIndexOf("@")).length;
  let emailDomain = email.substring(email.lastIndexOf("@"));
  let str = HIDE_EMAIL_CHARACTERS.toLowerCase();
  useEffect(() => {
    const header = document.getElementsByClassName("modal-header")[0];
    header.style.display = "none";
    let el = document.getElementById("mdBootstrapModal");
    el.removeAttribute("id");
    return () => {
      header.style.display = "flex";
      el.setAttribute("id", "mdBootstrapModal");
    };
  }, []);
  const handleSubmit = () => {
    if (securityCodebyUser !== null && securityCodebyUser !== "") {
      RegistrationAPI.getValidateSecurityCode({
        EmailId: email,
        SecurityCode: securityCodebyUser,
        isphonenumber: false,
        Functionality: "MemberRegistration",
        AttemptCountValue: attemptCount,
        PhoneNum: phoneNo,
        IsReSendSecurityCode: isResend,
        communicationConsent: props.textConsent ? props.textConsent : false,
      })
        .then((data) => {
          const newAttemptCount = data?.ResultData;
          setIsResend(false);
          setErrorMsg("");
          if (data.Message === SUCCESS_RESPONSE) {
            setStep(3);
            emailVerifiedCheck(true);
            closeModal();
          } else if (
            data.Message !== SUCCESS_RESPONSE &&
            newAttemptCount <= 2 // one less because attemptCount hasn't been incremented yet
          ) {
            setAttemptCountValue(data?.ResultData);
            // first and second incorrect attempt
            setMsgIncorrectAttempt(getFieldValue(fields, "emailPhoneOtp"));
            setSecurityCodebyUser("");
          } else if (
            data.Message !== SUCCESS_RESPONSE &&
            newAttemptCount > 2 &&
            resendButtonCount < 2
          ) {
            // third incorrect attempt
            setMsgIncorrectAttempt(
              getFieldValue(fields, "emailPhoneOtpThirdAttempt")
            );
            setAttemptCountValue(data?.ResultData);
            setSecurityCodebyUser("");
          } else if (
            data.Message !== SUCCESS_RESPONSE &&
            newAttemptCount > 2 &&
            resendButtonCount >= 2
          ) {
            // fourth incorrect attmpt
            setMsgIncorrectAttempt(
              getFieldValue(fields, "emailPhoneOtpAfterThirdAttempt")
            );
            setAttemptCountValue(data?.ResultData);
            setSecurityCodebyUser("");
          } else {
            setShowVerify(false);
            setAttemptCountValue(data?.ResultData);
            const content = apiResponseTemplate(
              getFieldValue(fields, "errorApiTitle"),
              getFieldValue(fields, "errorApiMsg")
            );
            openModal({
              content,
              version: 1,
            });
          }
        })
        .catch((err) => {
          setError({ err });
        })
        .finally(() => {
          setSpinner(false);
        });
    } else {
      setErrorMsg(getFieldValue(fields, "pleaseEnterSecurityCode"));
    }
  };

  const resendSecurityCode = () => {
    setSpinner(true);
    RegistrationAPI.getSendOrResendSecurityCode({
      EmailId: email,
      isphonenumber: false,
      isResendClicked: true,
      Functionality: "MemberRegistration",
      isResendButtonCount: resendButtonCount,
    })
      .then((data) => {
        setResendButtonCount(data?.ResultData?.ResendButtonCount);
        setIsResend(true);
        setAttemptCountValue(0);
        setErrorMsg(getFieldValue(fields, "codeResent"));
      })
      .catch((e) => {
        console.error("Error Occurred in resending the security code", e);
      })
      .finally(() => {
        setSpinner(false);
      });
  };
  return (
    <>
      <div className="Registration_new">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <section className="d-flex justify-content-center">
              <div className="">
                <div className="card-body">
                  <>
                    {/* <!-- step3 --> */}
                    <div className="font-family-regis-popup">
                      <h5 className="text-center">
                        <b>
                          <Text field={fields.enterSecurityCode} />
                        </b>
                      </h5>
                      <div className="security-code step-3">
                        <Text field={fields.sentSecurityCode} />
                        {emailNameLength > 3 && (
                          <>
                            &nbsp; {email.substring(0, 3)}
                            {str.repeat(emailNameLength - 3)}
                            {emailDomain}.&nbsp;
                          </>
                        )}
                        {emailNameLength <= 3 && <span>{email}.</span>}
                      </div>
                      <div className="security-code">
                        <Text field={fields.spamFolder} />
                      </div>
                      <div className="row-title">
                        <b>
                          <Text field={fields.oneTimeSecurityCode} />
                        </b>
                      </div>

                      <input
                        type="text"
                        className="form-control"
                        value={securityCodebyUser}
                        onChange={(e) => setSecurityCodebyUser(e.target.value)}
                      />
                      <div className="validation-msg pb-5">
                        {" "}
                        {errorMsg || errorMsgIncorrectAttempt}
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div className="registration-btn security">
                            <button
                              type="button"
                              className="btn btn-primary ripple-surface1 btn-1"
                              onClick={resendSecurityCode}
                              disabled={resendButtonCount >= 2}
                            >
                              <Text field={fields.resendButton} />
                            </button>
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="btn btn-primary ripple-surface"
                              disabled={attemptCount > 2}
                            >
                              <Text field={fields.submitButton} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Authentication;
