import React, { useState, useEffect, useContext } from "react";
import {
  ICON_PATH,
  PAGE_ID,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
} from "../../define.constants";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useGetDynamicAssessmentQuestionsAndAnswersAPI } from "../../hooks/useGetDynamicAssessmentQuestionsAndAnswersAPI";
import AssessmentForm from "./AssessmentForm";
import { AppContext } from "./../../contexts/app.context";
import { MY_WELLNESS_TAKE_ASSESSMENT } from "../../define.widgets";
import { SpinnerContext } from "../../contexts/spinner.context";

const UpdateContent = (
  assessmentid,
  contentList,
  assessmentIDSpecificList,
  prefLang,
  versionid
) => {
  let list = contentList;

  contentList?.map((content, index) => {
    let contentData = assessmentIDSpecificList?.filter(
      (rec) =>
        rec?.fields?.assessmentID?.value + "" === assessmentid + "" &&
        rec?.fields?.contentID?.value + "" === content.contentId
    );

    if (contentData.length > 0) {
      if (
        SPANISH_LANG_TEXT.toLowerCase() === prefLang?.toLowerCase() ||
        SPANISH_LANG_CODE.toLocaleLowerCase() === prefLang?.toLocaleLowerCase()
      ) {
        list[index].spanishContentText =
          contentData[0]?.fields?.contentText?.value;
      } else {
        list[index].contentText = contentData[0]?.fields?.contentText?.value;
      }
    }
  });

  return list;
};

const TakeAssessment = (props) => {
  const { assessment, disabledPage, assessmentIDSpecificList } = props;
  const { prefLang } = useContext(AppContext);

  const {
    assessmentDetails,
    responderName,
    relationshipToMember,
    memberHealthcareID,
    statusMessage,
    isResultsloaded,
    refreshAPIDataAll,
  } = useGetDynamicAssessmentQuestionsAndAnswersAPI({
    assessmentId: assessment.id,
    versionId: assessment.version,
  });

  useEffect(() => {
    if (memberHealthcareID !== "")
      props.updateResponderData(
        responderName,
        relationshipToMember,
        memberHealthcareID
      );
  }, [memberHealthcareID]);

  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepChange, setCurrentStepChange] = useState(false);
  const [pageDetails, setPageDetails] = useState(null);
  const [questionList, setQuestionList] = useState(null);
  const [assessmentOptions, setAssessmentOptions] = useState(null);
  const [assessmentBranchCondition, setAssessmentBranchCondition] =
    useState(null);
  const [assessmentContent, setAssessmentContent] = useState(null);
  const [assessmentAnswer, setAssessmentAnswer] = useState(null);
  const [pageDetailsList, setPageDetailsList] = useState(null);
  const [pageId, setPageId] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const takeAssessmentProps = props?.fields ?? "";
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  useEffect(() => {
    let getAssessmentDetails = JSON.parse(
      localStorage.getItem("AssessmentData")
    );
    let currentAssessmentPage = 0;
    if (getAssessmentDetails != null) {
      currentAssessmentPage = parseInt(
        getAssessmentDetails?.currentAssessmentPage
      );
      if (
        getAssessmentDetails.newAssessmentId ==
        assessmentDetails?.AssessmentQuestionDetails?.AssessmentDetail
          ?.AssessmentId
      ) {
        getAssessmentDetails.assessmentPages =
          assessmentDetails?.AssessmentQuestionDetails?.AssessmentPages?.length;
        localStorage.setItem(
          "AssessmentData",
          JSON.stringify(getAssessmentDetails)
        );
      }
    }

    if (
      (assessmentDetails != null && currentStep === 0) ||
      currentAssessmentPage == 1
    ) {
      let assessmentPages = [];
      assessmentPages =
        assessmentDetails?.AssessmentQuestionDetails?.AssessmentPages;
      if (
        assessmentPages?.length > 0 &&
        assessmentPages[0]?.NextPageId == null
      ) {
        assessmentPages.map((page, index) => {
          page.NextPageId =
            index < assessmentPages.length - 1
              ? assessmentPages[index + 1]?.PageId
              : null;
        });
      }
      setPageDetailsList(assessmentPages);
      setPageDetails(pageDetailsList?.length > 0 ? pageDetailsList[0] : null);
      setPageId(pageDetails?.PageId);
      setSelectedValue(
        pageDetailsList?.length === 1 ? PAGE_ID : pageDetails?.NextPageId
      );
      setAssessmentAnswer(assessmentDetails?.AssessmentAnswerDetails);
      if (pageId !== null)
        setQuestionList(
          assessmentDetails.AssessmentQuestionDetails?.AssessmentQuestions?.filter(
            (obj) => obj.PageId === pageId
          ).sort((obj) => obj.OrderId)
        );
      setAssessmentOptions(
        assessmentDetails.AssessmentQuestionDetails?.AssessmentOptions
      );
      setAssessmentBranchCondition(
        assessmentDetails.AssessmentQuestionDetails?.AssessmentBranchCondition
      );

      if (pageId !== null) {
        let contentData = UpdateContent(
          assessment.id,
          assessmentDetails.AssessmentQuestionDetails?.AssessmentContent?.filter(
            (obj) => obj.pageId === pageId
          ),
          props?.assessmentIDSpecificList,
          prefLang,
          assessment.version
        );

        setAssessmentContent(contentData);
      }
    }
  }, [assessmentDetails, pageDetails, pageDetailsList, pageId, currentStep]);
  useEffect(() => {
    if (isResultsloaded)
      setAssessmentAnswer(assessmentDetails?.AssessmentAnswerDetails);
  }, [isResultsloaded]);
  const onButtonClick = () => {
    let value = selectedValue;
    let checkCurrentStep = null;
    let getAssessmentDetails = JSON.parse(
      localStorage.getItem("AssessmentData")
    );
    if (getAssessmentDetails != null) {
      if (
        assessmentDetails?.AssessmentQuestionDetails?.AssessmentDetail
          ?.AssessmentId == getAssessmentDetails.existAssessmentId
      ) {
        let assessmentPages = parseInt(
          getAssessmentDetails.assessmentPages.length
        );
        if (assessmentPages == selectedValue || value == PAGE_ID) {
          value = getAssessmentDetails.existsNextPageId;
          checkCurrentStep = parseInt(getAssessmentDetails.currentStep) + 1;
          localStorage.removeItem("AssessmentData");
        }
      }
    }

    if (value !== null) {
      const records =
        assessmentDetails?.AssessmentQuestionDetails?.AssessmentPages.filter(
          (rec) => rec.PageId === parseInt(value)
        )[0];
      setQuestionList(
        assessmentDetails.AssessmentQuestionDetails.AssessmentQuestions.filter(
          (obj) => obj.PageId === parseInt(value)
        ).sort((obj) => obj.OrderId)
      );
      setAssessmentOptions(
        assessmentDetails.AssessmentQuestionDetails?.AssessmentOptions
      );
      setPageDetailsList(
        assessmentDetails?.AssessmentQuestionDetails?.AssessmentPages
      );
      let contentData = UpdateContent(
        assessment.id,
        assessmentDetails?.AssessmentQuestionDetails?.AssessmentContent?.filter(
          (obj) => obj.pageId === parseInt(value)
        ),
        props?.assessmentIDSpecificList,
        prefLang,
        assessment.version
      );
      setAssessmentBranchCondition(
        assessmentDetails.AssessmentQuestionDetails?.AssessmentBranchCondition
      );
      setAssessmentContent(contentData);
      setPageDetails(records);
      setPageId(records?.PageId);
      let NextPageObj =
        assessmentDetails?.AssessmentQuestionDetails?.AssessmentPages.filter(
          (rec) => rec.PageId === parseInt(records?.NextPageId)
        );
      setSelectedValue(NextPageObj?.length > 0 ? records?.NextPageId : PAGE_ID);

      if (
        getAssessmentDetails?.newAssessmentId != null &&
        getAssessmentDetails?.newAssessmentId ==
          assessmentDetails?.AssessmentQuestionDetails?.AssessmentDetail
            ?.AssessmentId
      ) {
        let currenrPageId = parseInt(
          getAssessmentDetails.currentAssessmentPage
        );
        setCurrentStep(currenrPageId);
        getAssessmentDetails.currentAssessmentPage = currenrPageId + 1;
        localStorage.setItem(
          "AssessmentData",
          JSON.stringify(getAssessmentDetails)
        );
      } else {
        if (checkCurrentStep != null) {
          setCurrentStep(checkCurrentStep);
        } else {
          setCurrentStep(currentStep + 1);
        }
      }
    }
  };
  const onImageClick = (selectedValue, index) => {
    let getAssessmentDetails = JSON.parse(
      localStorage.getItem("AssessmentData")
    );
    if (selectedValue !== null && getAssessmentDetails == null) {
      const records = pageDetailsList.filter(
        (rec) => rec.PageId === parseInt(selectedValue)
      )[0];
      setQuestionList(
        assessmentDetails.AssessmentQuestionDetails.AssessmentQuestions.filter(
          (obj) => obj.PageId === parseInt(selectedValue)
        ).sort((obj) => obj.OrderId)
      );
      let contentData = UpdateContent(
        assessment.id,
        assessmentDetails.AssessmentQuestionDetails.AssessmentContent.filter(
          (obj) => obj.pageId === parseInt(selectedValue)
        ),
        props?.assessmentIDSpecificList,
        prefLang,
        assessment.version
      );
      setAssessmentContent(contentData);
      setPageDetails(records);
      setPageId(selectedValue);
      setSelectedValue(records?.NextPageId);
      setCurrentStep(index);
    }
    setCurrentStepChange(false);
  };
  const stepDescription = getFieldValue(props.fields, "stepDescription", false);
  return (
    <>
      <div id="steps">
        {innerSpinnerHTML?.[MY_WELLNESS_TAKE_ASSESSMENT]}
        {pageDetailsList?.map((data, index) => {
          return (
            <>
              <div
                className={`step ${
                  currentStep >= index ? "state-color-txt active" : ""
                } ${currentStep > index ? "tick" : ""}`}
                data-desc={`${stepDescription} ${index + 1}`}
                key={index}
                id={`step-${index + 1}`}
              >
                {currentStep > index ? (
                  <img
                    onClick={() => {
                      setCurrentStepChange(true);
                      setTimeout(() => {
                        onImageClick(data.PageId, index);
                      }, 200);
                    }}
                    src={`${ICON_PATH}/tick-mark.svg`}
                    alt={getFieldValue(
                      takeAssessmentProps,
                      "completedTickAlt",
                      false
                    )}
                    className="cursor-pointer"
                  />
                ) : (
                  <div>{index + 1}</div>
                )}
              </div>
              {index < pageDetailsList.length - 1 ? (
                <div className="progress">
                  <div
                    className="progress-bar"
                    id={`progressbar${index + 1}`}
                    role="progressbar"
                    style={{
                      width: `${
                        currentStep === index
                          ? "50%"
                          : currentStep > index
                          ? "100%"
                          : "0%"
                      }`,
                    }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              ) : null}
            </>
          );
        })}
      </div>
      <AssessmentForm
        fields={props.fields}
        questionList={questionList}
        assessmentOptions={assessmentOptions}
        assessmentContent={assessmentContent}
        {...pageDetails}
        onButtonClick={onButtonClick}
        isResultsloaded={isResultsloaded}
        refreshAPIDataAll={refreshAPIDataAll}
        prefLang={prefLang}
        assessment={assessment}
        assessmentAnswer={assessmentAnswer}
        pageId={pageId}
        nextPageId={selectedValue}
        disabledPage={disabledPage}
        backToMyWellness={props.backToMyWellness}
        currentStepChange={currentStepChange}
        assessmentIDSpecificList={assessmentIDSpecificList}
        assessmentBranchCondition={assessmentBranchCondition}
        assessmentPages={pageDetailsList}
        currentStep={currentStep}
        assessments={props.assessments}
      />
    </>
  );
};
export default TakeAssessment;
