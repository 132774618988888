import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { getFormattedDate } from "../../utils/helpers";
import { ICON_PATH } from "../../define.constants";
import { Calendar } from "../../common/Calendar";
import { Field, ErrorMessage } from "formik";
import { InlineFormError } from "../../common/InlineFormError";
import { CustomSelect } from "../../common/Select";
import * as yup from "yup";

//print - healthrecord specific
export var printReportDate = (lblPrintGenerated) => {
  return (
    <div className="row">
      <div className="col-md-12 report-date pt-4 d-none">
        <span className="report-generate">
          <Text field={lblPrintGenerated}></Text>
        </span>
        <span>
          {new Date().toLocaleTimeString()}{" "}
          {getFormattedDate(new Date(), "Day, Mon dd, yyyy")}
        </span>
      </div>
    </div>
  );
};

export var paginationPrint = (totalRecords, next, props) => {
  return (
    <div className="row pagination float-start w-50">
      {paginationLabel(totalRecords, next, props)}
    </div>
  );
};
//print end

//pagination label DOM items - common pagination version2

var paginationLabel = (totalRecords, next, props) => {
  //console.log(props);
  return (
    <div className="col-12 col-lg-3">
      {totalRecords < props.lblPostPerPage.value ? (
        <label>
          {totalRecords} <Text field={props.lblResults} />
        </label>
      ) : (
        <label>
          1-{next} of {totalRecords} <Text field={props.lblResults} />
        </label>
      )}
    </div>
  );
};

export var pagination = (
  totalRecords,
  next,
  page,
  postsPerPage,
  setNext,
  setPage,
  currentPage,
  setCurrentPage,
  NUMBER_OF_RECORDS_PER_API_CALL,
  setFetchData
) => {
  if (totalRecords - next < postsPerPage) {
    setNext((prevVal) => prevVal + (totalRecords - next));
  } else {
    setNext((prevVal) => prevVal + postsPerPage);
  }

  if (totalRecords > currentPage * postsPerPage) {
    setCurrentPage(currentPage + 1);
    if (
      (currentPage + 1) * postsPerPage >
      page * NUMBER_OF_RECORDS_PER_API_CALL
    ) {
      setFetchData(true);
      setPage(page + 1);
    } else {
      setFetchData(false);
    }
  }
};

export var paginationWithoutApiCall = (
  totalRecords,
  next,
  page,
  postsPerPage,
  setNext,
  setPage
) => {
  if (totalRecords - next < postsPerPage) {
    setNext((prevVal) => prevVal + (totalRecords - next));
  } else {
    setNext((prevVal) => prevVal + postsPerPage);
  }
  setPage(page + 1);
};

//End of pagination DOM items

//Health Records Dom elements - Helthrecord - common

//End - Helth Records Dom elements

// move to - helper.js
export const formSchema = (...dateError) => {
  return yup.object().shape(
    {
      startDate: yup.date().when("endDate", {
        is: (endDate) => endDate != null,
        then: yup
          .date()
          .typeError(dateError[0])
          .required(dateError[0])
          .max(yup.ref("endDate"), dateError[1]),
        otherwise: yup.date().typeError(dateError[0]).nullable(),
      }),
      endDate: yup.date().when("startDate", {
        is: (startDate) => startDate != null,
        then: yup.date().typeError(dateError[0]).required(dateError[0]),
        otherwise: yup.date().typeError(dateError[0]).nullable(),
      }),
    },
    [["startDate", "endDate"]]
  );
};
