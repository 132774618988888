import React, { createContext } from "react";
import { SSORedirect } from "../common/SSORedirect";
import { useSSORedirect } from "../hooks/useSSORedirect";

const SSORedirectContext = createContext({
  html: "",
  redirectToSSO: () => {},
});

function SSORedirectProvider({ children }) {
  const { html, redirectToSSO, setHtml } = useSSORedirect();
  const context = { html, redirectToSSO, setHtml };

  return (
    <SSORedirectContext.Provider value={context}>
      <SSORedirect />
      {children}
    </SSORedirectContext.Provider>
  );
}

export { SSORedirectContext, SSORedirectProvider };
