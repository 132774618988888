import React, { useContext } from "react";
import {
  Text,
  Link,
  RichText,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { EFFECTIVE } from "../Home-Layout/Constants";
import { getFormattedDate } from "../../utils/helpers";
import { AppContext } from "./../../contexts/app.context";
import { SUBSCRIBER } from "./../../define.constants";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";

function MyBillModal(props) {
  let date = null;
  const { userType } = useContext(AppContext);
  const { redirectToSSO } = useContext(SSORedirectContext);

  return (
    <>
      <div className="account-font">
        <RichText field={props.myBillMessageTop} />
        <p>
          <RichText
            field={props.myBillMessageMid}
            tag="span"
            className="text-alignment"
          />
          <RichText
            field={props.myBillMessageBottom}
            tag="span"
            className="text-padding"
          />
        </p>
      </div>
    </>
  );
}

export default MyBillModal;
