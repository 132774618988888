import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as GetAlertsAPI from "../services/GetAlertsAPI";
import { MY_WELLNESS_GET_ALERTS } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";

const useGetAlertsAPI = () => {
  const [alertsData, setAlertsData] = useState([]);
  const [showOerBanner, setShowOerBanner] = useState(false);
  const [showPDHIBanner, setShowPDHIBanner] = useState(false);
  const [showPRERENEWALBanner, setShowPRERENEWALBanner] = useState(false);
  const [oerBannerIds, setOerBannerIds] = useState({});
  const [pdhiBannerIds, setPdhiBannerIds] = useState({});
  const [prerenewalBannerIds, setPrerenewalBannerIds] = useState({});
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const [alertMemberOnNoConsents, SetAlertMemNoConsent] = useState({});
  const [bnrProgramId, SetProgramId] = useState(false);

  const setData = (data) => {
    setAlertsData(data.NotificationAlerts.PPHRAlerts);
    SetAlertMemNoConsent(data.AlertMemberOnNoConsents);
  };

  useEffect(() => {
    setInnerSpinner(true, MY_WELLNESS_GET_ALERTS);
    GetAlertsAPI.getAlertsInformation()
      .then((response) => {
        if (response?.ResultData) {
          setData(response.ResultData);
        }
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(() => {
        setInnerSpinner(false, MY_WELLNESS_GET_ALERTS);
      });
  }, []);

  useEffect(() => {
    GetAlertsAPI.getOerBanners()
      .then((response) => {
        if (response?.ResultData) {
          const {
            IsOERBanner,
            IsPDHIBanner,
            IsPRERENEWALBanner,
            OERBannerIDs,
            PDHIBannerIDs,
            PRERENEWALBannerIDs,
            ProgramId,
          } = response?.ResultData;
          setShowOerBanner(IsOERBanner);
          setShowPDHIBanner(IsPDHIBanner);
          setShowPRERENEWALBanner(IsPRERENEWALBanner);
          setOerBannerIds(OERBannerIDs);
          setPdhiBannerIds(PDHIBannerIDs);
          setPrerenewalBannerIds(PRERENEWALBannerIDs);
          SetProgramId(ProgramId);
        }
      })
      .catch(function (err) {
        setError({ err });
      });
  }, []);

  return {
    alertsData,
    showOerBanner,
    showPDHIBanner,
    showPRERENEWALBanner,
    oerBannerIds,
    pdhiBannerIds,
    prerenewalBannerIds,
    alertMemberOnNoConsents,
    bnrProgramId,
  };
};
export { useGetAlertsAPI };
