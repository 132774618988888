import React from "react";
import ReadMore from "../Common-Health-Records/ReadMore";
import { formatName } from "../../utils/helpers";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";

const InPatientAdmissionMobileRecords = (props) => {
  const { fields, data, next } = props;
  return (
    <ul className="list-group no-list-title d-sm-block d-md-none">
      {data?.map((item, index) => (
        <li className="list-group-item " key={index}>
          <div className="row">
            <DisplayItem
              nameField={fields.lblType}
              valueField={item.ServiceType}
              outClass={"col-md-3 details"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
            <DisplayItem
              nameField={fields.lblFacility1}
              valueField={formatName(item.FacilityName)}
              outClass={"col-md-2 details"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
          </div>

          <div id={"readMoreDetails" + index} className="">
            <ReadMore props={fields}>
              <DisplayItem
                nameField={fields.lblDiagnosis}
                valueField={formatName(item.PrimaryDiag)}
                outClass={"col-md-3 details"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblAdmit1}
                valueField={item.AdmitDate}
                outClass={"col-md-2 details"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />
              <DisplayItem
                nameField={fields.lblDischarge}
                valueField={item.DischargeDate}
                outClass={"col-md-2 details"}
                titleClass={"list-desc-title"}
                detailsClass={"list-desc-details"}
              />
              <div className="close-btn" id={"closeBtnDetails" + index}></div>
            </ReadMore>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default InPatientAdmissionMobileRecords;
