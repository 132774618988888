import React, { useContext, useState, useEffect } from "react";
import {
  Text,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { BoxContainer } from "../../common/BoxContainer";
import { Box } from "../../common/Box";
import { GLOBAL_VARIABLE } from "../../define.constants";
import { getStateItems } from "./../../utils/helpers";
import { AppContext } from "./../../contexts/app.context";
const MyWellnessMentalHealth = (props) => {
  let fields = props?.fields;
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  let { MemberStateName } = useContext(AppContext);
  const items = getStateItems(MemberStateName, fields);
  return items?.title.value == "" ? (
    <>
      <br />
      <div className="font-win-comingSoon">
        <RichText field={items?.description1} />
      </div>
    </>
  ) : (
    <>
      <div className="row">
        <BoxContainer sectionClass={"mac-section"}>
          <Box title={items?.title}>
            <br />
            <div className="font-mac-items">
              <RichText field={items?.description1} />
            </div>
            <br />
          </Box>
        </BoxContainer>
      </div>
    </>
  );
};

export default MyWellnessMentalHealth;
