import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as IdCardAPI from "../services/IdCardAPI";
import { AppContext } from "../contexts/app.context";
import { ErrorContext } from "../contexts/error.context";
import {
  ENGLISH_LANG_CODE,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
} from "../define.constants";
const qs = require("qs");

const useGetIdcardReasonListAPI = () => {
  const { prefLang } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [listReasons, setListReasons] = useState([]);

  const setData = (data) => {
    setListReasons(data.ResultData.MemAPIChangeIDCardReason);
  };

  useEffect(() => {
    setSpinner(true);
    let langcode =
      SPANISH_LANG_TEXT === prefLang.toLowerCase() ||
      SPANISH_LANG_CODE === prefLang.toLowerCase()
        ? SPANISH_LANG_CODE
        : ENGLISH_LANG_CODE;
    let request = { lang: langcode };
    IdCardAPI.getIdCardReasonList(
      request,
      qs.stringify(request, { addQueryPrefix: true })
    )
      .then((response) => {
        setData(response);
      })
      .catch(function (err) {
        setError({ err });
      })
      .finally(function () {
        setSpinner(false);
      });
  }, [prefLang]);

  return {
    listReasons,
  };
};

export { useGetIdcardReasonListAPI };
