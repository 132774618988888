import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as MessagesAPI from "../services/MessagesAPI";
import { ErrorContext } from "../contexts/error.context";
import { MESSAGES_DETAILS } from "../define.widgets";
import { HEALTH_PLAN_RENEWALS } from "../define.constants";
const qs = require("qs");

const useMessageDetailAPI = (
  { SFMessageID, Type, MessageID, InteractionId, AttachmentID },
  page = 1
) => {
  const { setError } = useContext(ErrorContext);
  const { setInnerSpinner } = useContext(SpinnerContext);
  const [messageDetails, setMessageDetails] = useState(null);
  const [conversationDetails, setConversationDetails] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);

  //Redetermination message body
  const redeterminationMessage = (response) => {
    let MessageDetails = {
      Body: response?.MessageDetails?.MessageDescription,
    };
    return MessageDetails;
  };

  const setData = (response) => {
    if (
      response?.MessageDetails?.MessageType?.trim()?.toUpperCase() ==
      HEALTH_PLAN_RENEWALS
    ) {
      setMessageDetails(redeterminationMessage(response));
    } else {
      setMessageDetails(response.ConversationDetails);
    }
    //setConversationDetails(response.ConversationDetails?.ConversationEntries);
    setStatusMessage(response.StatusMessage);
  };
  //Querying Type parameter to Identify MessageType
  useEffect(() => {
    setInnerSpinner(true, MESSAGES_DETAILS);
    if (SFMessageID != null && SFMessageID.length > 0) {
      let query = {
        ConversationID: SFMessageID,
        //InteractionId: InteractionId,
        //Subject: Subject,
        //Status: Status,
        // From: From,
        //ToID: ToID,
        Type: Type,
      };
      MessagesAPI.getMessageDetail(
        query,
        qs.stringify(query, { addQueryPrefix: true })
      )
        .then((response) => {
          if (response.ResultData != undefined) {
            setData(response.ResultData);
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MESSAGES_DETAILS);
        });
    } else {
      let query = {
        MessageID: MessageID,
        InteractionId: InteractionId,
        AttachmentID: AttachmentID,
        //MessageID: SFMessageID ? SFMessageID : MessageID,
        // Subject: Subject,
        //Status: Status,
        // From: From,
        //ToID: ToID,
        //Type: Type ? Type : "Message",
      };
      MessagesAPI.getPortalMessageDetail(
        query,
        qs.stringify(query, { addQueryPrefix: true })
      )
        .then((response) => {
          if (response.ResultData != undefined) {
            setMessageDetails(response.ResultData.InboxMessageList[0]);
            setStatusMessage(response.ResultData.StatusMessage);
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MESSAGES_DETAILS);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MessageID, page]);

  return {
    messageDetails,
    statusMessage,
    MessageID,
  };
};

export { useMessageDetailAPI };
