import React from "react";

const CarePlan = (props) => {
  const { carePlan } = props;

  return (
    <div className="d-none d-md-block">
      <iframe
        src={`data:application/pdf;base64,${carePlan}`}
        width="100%"
        height="1140px"
      />
    </div>
  );
};
export default CarePlan;
