import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useMemberReferralsListAPI } from "../../hooks/useMembersReferralsListAPI";
import ReferralsRecords from "./ReferralsRecords";
import {
  printReportDate,
  //paginationPrint,
  //paginationWithoutApiCall,
} from "../Common-Health-Records/Helper";
import ReferralsRecordsMobile from "./ReferralsRecordsMobile";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_HEALTH_RECORD_VIEW_MEMBER_REFERRALS } from "../../define.widgets";
import { GLOBAL_VARIABLE } from "../../define.constants";

const MyHealthRecordReferrals = (props) => {
  const { memberReferralsList, isRefLoaded } = useMemberReferralsListAPI();
  const { innerSpinnerHTML } = useContext(SpinnerContext);

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  const [filteredRecords, setFilteredRecords] = useState([]);
  useEffect(() => {
    setFilteredRecords(memberReferralsList);
  }, [memberReferralsList]);

  // let totalRecords =
  //   filteredRecords !== null ? filteredRecords?.length ?? "" : 0;
  // let totalPages = Math.ceil(totalRecords / postsPerPage);

  return (
    <>
      <div
        className="tab-pane fade show active referrals-tab"
        id="referrals"
        role="tabpanel"
        aria-label="tab-1"
      >
        <div className="row no-tab-title" id="medicationDate">
          <div className="col-12 no-date-filter">
            {isRefLoaded ? (
              memberReferralsList === null ||
              memberReferralsList?.length === 0 ? (
                <div className="no-records">
                  <Text field={props.fields.lblZeroReferralMessage} />
                </div>
              ) : (
                <>
                  <ReferralsRecords
                    fields={props.fields}
                    data={filteredRecords}
                    // next={next}
                  />
                  <ReferralsRecordsMobile
                    fields={props.fields}
                    data={filteredRecords}
                    // next={next}
                  />

                  {/* {totalPages !== page && (
                    <a
                      className="show-more"
                      onClick={(e) =>
                        paginationWithoutApiCall(
                          totalRecords,
                          next,
                          page,
                          postsPerPage,
                          setNext,
                          setPage
                        )
                      }
                    >
                      <Text field={props.fields.lblShowMore}></Text>
                    </a>
                  )} */}
                  {printReportDate(props.fields.lblPrintGenerated)}
                </>
              )
            ) : (
              filteredRecords?.slice(0).length === 0 && (
                <li className="list-group-item">
                  {innerSpinnerHTML?.[MY_HEALTH_RECORD_VIEW_MEMBER_REFERRALS]}
                  <div className="row field1">
                    <div className="col-sm-12"></div>
                  </div>
                </li>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyHealthRecordReferrals;
