import React, { useEffect } from "react";
import ViewSDKClient from "./ViewSDKClient";
import { v4 as uuidv4 } from "uuid";
const getFilePath = (content) => {
  let byteChars = window.atob(content);
  let byteNum = new Array(byteChars.length);
  for (let i = 0; i < byteChars.length; i++) {
    byteNum[i] = byteChars.charCodeAt(i);
  }
  let byteAr = new Uint8Array(byteNum);
  let file = new Blob([byteAr], {
    type: "application/pdf;base64",
  });
  let filePath = URL.createObjectURL(file);
  return filePath;
};
const ViewIdCardOneil = ({ oneildigitalid }) => {
  useEffect(() => {
    if (!oneildigitalid.length) {
      return;
    }
    let filePath1 = getFilePath(oneildigitalid[0].content1);
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      viewSDKClient.previewFile(
        "pdf-front-div",
        {
          /* Pass the embed mode option here */
          embedMode: "IN_LINE",
          showDownloadPDF: false,
          showPrintPDF: false,
        },
        filePath1,
        "FRONT.pdf",
        uuidv4()
      );
    });
    let filePath2 = getFilePath(oneildigitalid[0].content2);
    const viewSDKClient2 = new ViewSDKClient();
    viewSDKClient2.ready().then(() => {
      /* Invoke file preview */
      viewSDKClient2.previewFile(
        "pdf-back-div",
        {
          /* Pass the embed mode option here */
          embedMode: "IN_LINE",
          showDownloadPDF: false,
          showPrintPDF: false,
        },
        filePath2,
        "BACK.pdf",
        uuidv4()
      );
    });
  }, []);
  return (
    <div className="row ">
      <div
        id="id-card-container"
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 container-fluid"
      >
        <p className="text-align-center">FRONT</p>
        <div id="pdf-front-div"></div>
      </div>
      <div
        id="id-card-container"
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 container-fluid"
      >
        <p className="text-align-center">BACK</p>
        <div id="pdf-back-div"></div>
      </div>
    </div>
  );
};
export default ViewIdCardOneil;
