import React, { useState } from "react";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { makeStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import { GLOBAL_VARIABLE } from "../../define.constants";

class LocalizedUtils extends DateFnsUtils {
  setLocale(locale) {
    this.locale = locale;
  }
  getDatePickerHeaderText(date) {
    return format(date, "MMM dd", { locale: this.locale });
  }
}
const dateFns = new LocalizedUtils();

export const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

function arrayIncludes(array, itemOrItems) {
  if (Array.isArray(itemOrItems)) {
    return itemOrItems.every(function (item) {
      return array.indexOf(item) !== -1;
    });
  }
  return array.indexOf(itemOrItems) !== -1;
}

const CustomToolbar = function (props) {
  dateFns.setLocale(props.locale);
  const { date, isLandscape, openView, setOpenView, title } = props;
  const classes = useStyles();
  console.log(openView);

  return (
    <PickerToolbar
      className={classes.toolbar}
      title={title}
      isLandscape={isLandscape}
    >
      <p className="toolbar-Text">{GLOBAL_VARIABLE.lblSelectDate}</p>
      <ToolbarButton
        variant="subtitle1"
        onClick={function () {
          return setOpenView("year");
        }}
        selected={openView === "year"}
        label={dateFns.getYearText(date)}
      />
      <ToolbarButton
        variant="h4"
        onClick={function () {
          return setOpenView("date");
        }}
        selected={openView === "date"}
        label={dateFns.getDatePickerHeaderText(date)}
        align={isLandscape ? "left" : "center"}
      />
    </PickerToolbar>
  );
};

export default CustomToolbar;
