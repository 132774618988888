import Axios from "./axios/provider";

/**
 * Get member Id Card Pdf!
 *
 * @param {object} data
 * @returns
 */
export const getMemberInfoAll = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.MemberInfoAll, data);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Validate and Get member details!
 *
//  * @param {object} data
//  * @returns
//  */
// export const getuserSession = async (data) => {
//   try {
//     const response = await Axios.post(constants.apigeeURLs.userSession, data);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };

/**
 * Validate and Get member details!
 *
 * @param {object} data
 * @returns
 */
export const getuserLogin = async (data, queryString) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.userLogin + (queryString ? queryString : ""),
      data
    );
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Validate Membership!
 *
 * @param {object} data
 * @returns
 */
export const getValidateMembership = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ValidateMembership,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Create Account!
 *
 * @param {object} data
 * @returns
 */
export const getCreateAccount = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.CreateAccount, data);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Checks if email exists in system or not!
 *
 * @param {object} data
 * @returns
 */
export const getEmailExistsInSystem = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.EmailExistsInSystem,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Checks if userid exists in system or not!
 *
 * @param {object} data
 * @returns
 */
export const getUserIdExistsInSystem = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.UserIdExistsInSystem,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Sends security code for Step-3 Authentication!
 *
 * @param {object} data
 * @returns
 */
export const getSendOrResendSecurityCode = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.SendOrResendSecurityCode,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - Validates security code for Step-3 Authentication!
 *
 * @param {object} data
 * @returns
 */
export const getValidateSecurityCode = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ValidateSecurityCode,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Registration - ForgotUserIDPassword
 *
 * @param {object} data
 * @returns
 */
export const getForgotUserIDPassword = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.ForgotUIDPd, data);
    return response;
  } catch (error) {
    throw error;
  }
};

/* Registration - ForgotUserIDPasswordPost
 *
 * @param {object} data
 * @returns
 */
export const getForgotUserIDPasswordPost = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ForgotUIDPdPost,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGraceDetails = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.GraceDetails, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProfiles = async () => {
  try {
    const response = await Axios.post(constants.apigeeURLs.GetProfiles);
    return response;
  } catch (error) {
    throw error;
  }
};

// /**
//  * Get member details!
//  *
//  * @param {object} data
//  * @returns
//  */
// export const getMemberDetails = async (data) => {
//   try {
//     const response = await Axios.post(constants.apigeeURLs.MemAPIDetails, data);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };

// /**
//  * Validate user.
//  *
//  * @param {object} data
//  * @returns
//  */
// export const validateUser = async (data) => {
//   try {
//     const response = await Axios.post(constants.apigeeURLs.validateUser, data);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };

/**
 * Save memeber in the DB.
 */
export const saveMember = () => {};

/**
 * Get all the members!
 */
export const getMembers = () => {};
