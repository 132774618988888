/**
 * Send CDP Events and Get CDP Alerts
 */
import { CDP_CHANNEL } from "../define.constants";
import * as commonAPI from "../services/Common";

export const getCDPAlerts = async (lang = "en") => {
  try {
    var cdpConfig = {
      CDP_CLIENTKEY: process.env.CDP_CLIENTKEY,
      CDP_POINTOFSALE: process.env.CDP_POINTOFSALE,
      CDP_URL: process.env.CDP_URL,
    };

    const resp = await commonAPI.getCDPConfig();
    if (resp && resp?.CDP_CLIENTKEY && resp?.CDP_POINTOFSALE && resp?.CDP_URL) {
      cdpConfig = resp;
    }
    const apiUrl = cdpConfig.CDP_URL + "/v2/callFlows";
    const bid = window.Engage?.getBrowserId();

    const requestData = {
      channel: CDP_CHANNEL,
      clientKey: cdpConfig.CDP_CLIENTKEY,
      pointOfSale: cdpConfig.CDP_POINTOFSALE,
      friendlyId: "desktopexperience",
      browserId: bid,
      params: {
        language: lang.toLowerCase(),
      },
    };
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });
    if (response?.ok) {
      const cdpAlert = await response.json();
      return cdpAlert;
    } else {
      console.error("CDP API Error:", response?.status, response?.statusText);
    }
  } catch (error) {
    console.error("CDP Fetch Error:", error.message);
  }
};
export const sendCDPAlert = async (
  eventType = "",
  alertType = "",
  sfCId = ""
) => {
  try {
    var cdpConfig = {
      CDP_CLIENTKEY: process.env.CDP_CLIENTKEY,
      CDP_POINTOFSALE: process.env.CDP_POINTOFSALE,
      CDP_URL: process.env.CDP_URL,
    };
    const bid = window.Engage?.getBrowserId();
    const resp = await commonAPI.getCDPConfig().then((resp) => {
      if (
        resp &&
        resp?.CDP_CLIENTKEY &&
        resp?.CDP_POINTOFSALE &&
        resp?.CDP_URL
      ) {
        cdpConfig = resp;
      }
      const apiUrl = cdpConfig.CDP_URL + "/v1.2/events";
      var eventData = {
        channel: CDP_CHANNEL,
        pos: cdpConfig.CDP_POINTOFSALE,
        client_key: cdpConfig.CDP_CLIENTKEY,
        browser_id: bid,
        type: eventType,
      };
      if (eventType == "IDENTITY") {
        eventData.identifiers = [
          {
            provider: "SalesforceContactID",
            id: sfCId,
          },
        ];
      }
      if (eventType === "AlertClosed" || eventType === "AlertDismiss") {
        eventData.ext = { alertType: alertType };
      }
      const response = fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventData),
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendIDENTITYEvent = async (sfCId) => {
  try {
    if (!sessionStorage.getItem("IDENTITYEventSent")) {
      var cdpConfig = {
        CDP_CLIENTKEY: process.env.CDP_CLIENTKEY,
        CDP_POINTOFSALE: process.env.CDP_POINTOFSALE,
        CDP_URL: process.env.CDP_URL,
      };
      const bid = window.Engage?.getBrowserId();
      const resp = await commonAPI.getCDPConfig();
      if (
        resp &&
        resp?.CDP_CLIENTKEY &&
        resp?.CDP_POINTOFSALE &&
        resp?.CDP_URL
      ) {
        cdpConfig = resp;
      }
      const apiUrl = cdpConfig.CDP_URL + "/v1.2/events";
      var eventData = {
        channel: CDP_CHANNEL,
        pos: cdpConfig.CDP_POINTOFSALE,
        client_key: cdpConfig.CDP_CLIENTKEY,
        browser_id: bid,
        type: "IDENTITY",
      };
      eventData.identifiers = [
        {
          provider: "SalesforceContactID",
          id: sfCId,
        },
      ];

      await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventData),
      }).then(() => {
        sessionStorage.setItem("IDENTITYEventSent", "true");
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendCDPUTMEvent = async () => {
  try {
    const utmCampaign = localStorage.getItem("utm_campaign");
    const utmSource = localStorage.getItem("utm_source");
    const utmMedium = localStorage.getItem("utm_medium");
    if (utmCampaign && utmSource && utmMedium) {
      var cdpConfig = {
        CDP_CLIENTKEY: process.env.CDP_CLIENTKEY,
        CDP_POINTOFSALE: process.env.CDP_POINTOFSALE,
        CDP_URL: process.env.CDP_URL,
      };
      const bid = window.Engage?.getBrowserId();
      const resp = await commonAPI.getCDPConfig().then((resp) => {
        if (
          resp &&
          resp?.CDP_CLIENTKEY &&
          resp?.CDP_POINTOFSALE &&
          resp?.CDP_URL
        ) {
          cdpConfig = resp;
        }
        const apiUrl = cdpConfig.CDP_URL + "/v1.2/events";
        var eventData = {
          channel: CDP_CHANNEL,
          pos: cdpConfig.CDP_POINTOFSALE,
          client_key: cdpConfig.CDP_CLIENTKEY,
          browser_id: bid,
          type: "UTM",
          utm_campaign: utmCampaign,
          utm_source: utmSource,
          utm_medium: utmMedium,
        };
        const response = fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventData),
        });
      });
    }
  } catch (error) {
    console.log(error);
  }
};
