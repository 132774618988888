import React, { useContext } from "react";
import { IMAGE_PATH, NY } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../../../contexts/app.context";

const ViewIdCardMA = ({ fields, result, details, idCard, drugInfo }) => {
  const { MemberStateName } = useContext(AppContext);

  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardMA"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/MA/Medicare/MA_Medicare_IdCard.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td colSpan="2" className="plan-care-name">
                  {idCard?.PlanName}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMemberNum} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                {MemberStateName !== NY && (
                  <td>
                    <b>
                      <Text field={fields.lblPcp} />
                    </b>
                    &nbsp; {details?.PCPName_Current}
                  </td>
                )}
                {MemberStateName === NY && <td>&nbsp;</td>}
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblBin} />
                  </b>
                  &nbsp;{result?.PortalDeductiblesMappingDetails[0]?.RxBIN}
                  {/* &nbsp; {idCard?.MemberEffectiveDate} */}
                </td>
              </tr>

              <tr>
                {MemberStateName !== NY && (
                  <td>
                    <b>
                      <Text field={fields.lblPcpTel} />
                    </b>
                    &nbsp; {details?.PCPPhone_Current}
                  </td>
                )}
                {MemberStateName === NY && <td>&nbsp;</td>}
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblPCN} />
                  </b>
                  &nbsp;{result?.PortalDeductiblesMappingDetails[0]?.RxPCN}
                  {/* &nbsp; {idCard?.MemberEffectiveDate} */}
                </td>
              </tr>

              <tr>
                <td> </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblGroup} />
                  </b>
                  &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxGroup}
                  {/* &nbsp; {idCard?.MemberEffectiveDate} */}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblRxid} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td style={{ fontSize: "12px" }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                  {result?.PortalDeductiblesMappingDetails[0]?.ContractPlanId}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIssuedDate} />
                  </b>
                  &nbsp; {formatToday()}{" "}
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/FL/Medicaid/Member-ID-Card-Duals-back-FL.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
            >
              <tbody>
                <tr>
                  <td style={{ height: "30px" }}></td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblMemberServicesKY} />
                    </b>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {MemberStateName === NY ? (
                      <Text field={fields?.lblMemberServicesValueNY} />
                    ) : (
                      <Text field={fields?.lblMemberServicesValueKY} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    {MemberStateName === NY ? (
                      <Text field={fields?.lbl24hoursNurseAdviceNY} />
                    ) : (
                      <Text field={fields?.lbl24hoursNurseAdviceKY} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblProviderKY} />
                    </b>
                    &nbsp; <Text field={fields?.lblProviderValueKY} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblSubmitClaimKY} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblMedicalKY} />
                    </b>
                    &nbsp;
                    {MemberStateName === NY ? (
                      <Text field={fields?.lblMedicalNYValue} />
                    ) : (
                      <Text field={fields?.lblMedicalKYValue} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblPharmacyKY} />
                    </b>
                    &nbsp; <Text field={fields?.lblbackPharmacyData} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="cust-padding">
              <b>
                <Text field={fields.lblIDBackComplaintContactData} />
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMA;
