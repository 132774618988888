import Axios from "./axios/provider";
import { SpinnerContext } from "../contexts/spinner.context";

/**
 * Get Allergies List
 *
 * @param {object} data
 * @returns
 */
// This method is used to get the communication preferences
export const ViewCommunicationPreferences = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ViewCommunicationPreferences,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// This method is used to get the contact preferences
export const ViewContactPreferences = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ViewContactPreferences,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// This method is used to get the contact preferences
export const ViewContactPreferencesList = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ViewContactPreferencesList,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const UpdateContactPreferences = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.UpdateContactPreferences,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const UpdateCommunicationPreferences = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.UpdateCommunicationPreferences,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
