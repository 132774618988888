import React, { useState, useContext } from "react";
import NavigationLinks from "./NavigationLinks";
import Program from "./Program";
import { AppContext } from "../../contexts/app.context";
import { withModalProvider } from "../../hoc/withModalProvider";

function TopNavigation(props) {
  const { fields } = props;
  const [tabshow, settabshow] = useState(true);
  const { MemberStateName } = useContext(AppContext);
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white molina-header2 d-none d-xl-block">
        <div className="header-link">
          <NavigationLinks
            fields={fields}
            tabshow={tabshow}
            MemberStateName={MemberStateName}
            onMyBillClick={props.onMyBillClick}
          />
          <Program fields={fields} settabshow={settabshow} />
        </div>
      </nav>
    </>
  );
}

export default withModalProvider(TopNavigation);
