import React from "react";
import PropTypes from "prop-types";

const BoxContainer = ({ classForWidth, sectionClass, children }) => {
  const sectionClassName = `d-flex justify-content-center ${sectionClass}`;

  return (
    <div className={classForWidth}>
      <section className={sectionClassName}>{children}</section>
    </div>
  );
};

BoxContainer.defaultProps = {
  classForWidth: "col-md-12 col-sm-12",
  sectionClass: "",
};

BoxContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BoxContainer;
