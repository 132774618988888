import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import {
  MEDICAID,
  IMAGE_PATH,
  ICON_PATH,
  ENGLISH_LANG_CODE,
  WI,
  UT,
  AZ,
  NV,
  KY,
  IA,
  NY,
  GLOBAL_VARIABLE,
  FL,
  MS,
  TX,
  CA,
  ID,
  MI,
  VA,
  WA,
  IL,
  MA,
  SC,
  OH,
  NM,
  NE,
} from "../../define.constants";
import React, { useContext } from "react";
import { AppContext } from "../../contexts/app.context";
import { getStateLobConfigurationItems } from "../../utils/helpers";
import ViewWIRewards from "./ViewWIRewards";
import ViewUTRewards from "./ViewUTRewards";
import ViewAZRewards from "./ViewAZRewards";
import ViewNVRewards from "./ViewNVRewards";
import ViewKYRewards from "./ViewKYRewards";
import ViewIARewards from "./ViewIARewards";
import ViewNYRewards from "./ViewNYRewards";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import ViewFLRewards from "./ViewFLRewards";
import ViewMSRewards from "./ViewMSRewards";
import ViewTXRewards from "./ViewTXRewards";
import ViewCARewards from "./ViewCARewards";
import ViewMIRewards from "./ViewMIRewards";
import ViewMARewards from "./ViewMARewards";
import ViewSCRewards from "./ViewSCRewards";
import ViewOHRewards from "./ViewOHRewards";
import ViewNERewards from "./ViewNERewards";

const Rewards = (props) => {
  const { memberLOB, MemberStateName } = useContext(AppContext);
  const list = getStateLobConfigurationItems(
    props.fields,
    MemberStateName,
    memberLOB,
    ""
  );
  const viewRewardsCollection = {
    wi_medicaid: ViewWIRewards,
    ut_medicaid: ViewUTRewards,
    az_medicaid: ViewAZRewards,
    nv_medicaid: ViewNVRewards,
    ky_medicaid: ViewKYRewards,
    ia_medicaid: ViewIARewards,
    ny_medicaid: ViewNYRewards,
    fl_medicaid: ViewFLRewards,
    ms_medicaid: ViewMSRewards,
    tx_medicaid: ViewTXRewards,
    ca_medicaid: ViewCARewards,
    mi_medicaid: ViewMIRewards,
    ma_medicaid: ViewMARewards,
    sc_medicaid: ViewSCRewards,
    oh_medicaid: ViewOHRewards,
    ne_medicaid: ViewNERewards,
  };
  const common_State = [CA, VA, WA, IL, ID, NM];
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  const getappropriateRewards = (stateName, item) => {
    if (memberLOB.toLowerCase() != MEDICAID) {
      return null;
    }
    let CurrentViewRewards = "";
    const lob = memberLOB.toLowerCase();
    if (lob === MEDICAID) {
      switch (stateName) {
        case WI:
          CurrentViewRewards = viewRewardsCollection["wi_medicaid"];
          break;
        case UT:
          CurrentViewRewards = viewRewardsCollection["ut_medicaid"];
          break;
        case AZ:
          CurrentViewRewards = viewRewardsCollection["az_medicaid"];
          break;
        case NV:
          CurrentViewRewards = viewRewardsCollection["nv_medicaid"];
          break;
        case KY:
          CurrentViewRewards = viewRewardsCollection["ky_medicaid"];
          break;
        case IA:
          CurrentViewRewards = viewRewardsCollection["ia_medicaid"];
          break;
        case NY:
          CurrentViewRewards = viewRewardsCollection["ny_medicaid"];
          break;
        case FL:
          CurrentViewRewards = viewRewardsCollection["fl_medicaid"];
          break;
        case MS:
          CurrentViewRewards = viewRewardsCollection["ms_medicaid"];
          break;
        case TX:
          CurrentViewRewards = viewRewardsCollection["tx_medicaid"];
          break;
        case common_State.includes(MemberStateName) ? MemberStateName : null:
          CurrentViewRewards = viewRewardsCollection["ca_medicaid"];
          break;
        case MI:
          CurrentViewRewards = viewRewardsCollection["mi_medicaid"];
          break;
        case MA:
          CurrentViewRewards = viewRewardsCollection["ma_medicaid"];
          break;
        case SC:
          CurrentViewRewards = viewRewardsCollection["sc_medicaid"];
          break;
        case OH:
          CurrentViewRewards = viewRewardsCollection["oh_medicaid"];
          break;
        case NE:
          CurrentViewRewards = viewRewardsCollection["ne_medicaid"];
          break;
        default:
          return null;
      }
    }
    if (lob === MEDICAID) {
      return <CurrentViewRewards props={item} stateName={MemberStateName} />;
    }
  };
  return getappropriateRewards(MemberStateName, list);
};

export default Rewards;
