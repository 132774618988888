import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardMedicaidNYNMC = ({
  MemberStateName,
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardNY"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/NY/Medicaid/NY_Medicaid-Affinity_MMC-ID-Card.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <Text field={fields.lblMember} />
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblCinNum} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblDateOfBirth} />
                  </b>
                  &nbsp; {idCard?.Member_DOB}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblEffectiveDate} />
                  </b>
                  &nbsp; {idCard?.PCPEffectiveDate}
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblBin} />
                  </b>
                  &nbsp;{result?.IdCardValues?.RxBIN}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPcpName} />
                  </b>
                  &nbsp; {details?.PCPName_Current}
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblPCN} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxPCN}
                </td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPhpPhone} />
                  </b>
                  &nbsp; {details?.PCPPhone_Current}
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblGroup} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxGRP}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPrescriptionDrug} />
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblNonPreferredDrugs} />
                  </b>{" "}
                  TBD
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPreferredDrugs} />
                  </b>{" "}
                  TBD
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblGenericDrugs} />
                  </b>{" "}
                  TBD
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblOverTheCounterDrugs} />
                  </b>{" "}
                  TBD
                </td>
              </tr>
              {/* <tr>
                <td>
                  <b>
                    <Text field={fields.lblIssuedDate} />
                  </b>
                  &nbsp; {formatToday()}{" "}
                </td>
                <td>&nbsp;</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/NY/Medicaid/NYAffinity_back_view.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
            >
              <tbody>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblMembers} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblEmergencyCare} />
                    </b>
                    &nbsp;
                    <Text field={fields?.lblEmergencyCareText} />
                  </td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblBehavioralHealthBenefit} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblDentalBenefitDentaQuest} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblPharmacyBenefit} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblTeladocVirtualService} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblVisionBenefit} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                {/* <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblForNonLifeCondition} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <Text field={fields?.lblForNonLifeConditionPoints} />
                  </td>
                </tr> */}
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblProviders} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblRemitClaims} />
                    </b>
                    <Text field={fields?.lblRemitClaimsAddress} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblPharmacists} />
                    </b>
                    <Text field={fields?.lblPharmacistsAddress} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <Text field={fields?.lblCardGuaranteeNoteNY} />
                    <b>
                      <Text field={fields?.lblMolinaHealthcareContactNY} />
                    </b>
                    &nbsp;
                    <Text field={fields?.lblMolinaHealthcareSpeakNurseNY} />
                    <b>
                      <Text
                        field={fields?.lblMolinaHealthcareSpeakNurseNYContact}
                      />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <div className="cust-padding">
              <b>
                <Text field={fields.lblIDBackComplaintContactData} />
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicaidNYNMC;
