import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as HealthRecordAPI from "../services/HeathRecordAPI";
import { AppContext } from "../contexts/app.context";
import { MY_WELLNESS_GET_ASSESSMENTS } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";

const useGetCareTeamContactAccessManagementAPI = () => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const { MemberStateName, memberLOB, memberRKSID } = useContext(AppContext);
  const [memberConsent, setMemberConsent] = useState([]);
  const [refreshSession, setRefreshSession] = useState(false);
  const [isApiResponded, setIsApiResponded] = useState(false);
  const setData = (response) => {
    setMemberConsent(response);
  };

  useEffect(() => {
    if (refreshSession) {
      callApi();
    } else {
      callApi();
    }
  }, [refreshSession]);

  const callApi = () => {
    setInnerSpinner(true, MY_WELLNESS_GET_ASSESSMENTS);
    HealthRecordAPI.getCareTeamContactAccessManagement()
      .then((response) => {
        setIsApiResponded(true);
        setData(response?.ResultData);
        if (refreshSession === true) {
          setRefreshSession(false);
        }
      })
      .catch(function (err) {
        setIsApiResponded(true);
        setError({ err });
        setRefreshSession(false);
      })
      .finally(() => {
        setInnerSpinner(false, MY_WELLNESS_GET_ASSESSMENTS);
      });
  };

  const refreshContactAccessManagementAPI = () => {
    setRefreshSession(true);
  };

  return {
    memberConsent,
    refreshContactAccessManagementAPI,
    isApiResponded,
  };
};

export { useGetCareTeamContactAccessManagementAPI };
