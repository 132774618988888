import React, { useEffect, useContext, useState } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import MessageAttachment from "./MessageAttachment";
import { getFormattedDate, getSanitisedHTML } from "../../utils/helpers";
import { INBOX, ARCHIVE } from "../../define.constants";
import { useMessageDetailAPI } from "../../hooks/useMessageDetailAPI";
import { AppContext } from "./../../contexts/app.context";
import {
  ENGLISH_LANG_CODE,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  CaseManagertten,
  CaseManagerttes,
  Membertten,
  Memberttes,
} from "../../define.constants";

const MessagesIndexItem = ({
  fields,
  message,
  openedMessage,
  onMessageSelect,
  messageTabType,
  attachments,
  index,
}) => {
  const [status, setStatus] = useState(null);
  const [messID, setMessID] = useState("");
  const [salesForceFormat, setSalesForceFormat] = useState(false);
  const [replyMessTxt, setReplyMessTxt] = useState(false);
  const [SFMessID, setSFMessID] = useState("");
  const [pageRefresh, setPageRefresh] = useState(1);
  const { refreshMemberInfoAll, prefLang } = useContext(AppContext);
  const { unreadInboxMsgCount } = useContext(AppContext);
  const { messageDetails, statusMessage } = useMessageDetailAPI(
    message,
    pageRefresh
  );
  const langcode = `${
    SPANISH_LANG_TEXT === prefLang.toLowerCase() ||
    SPANISH_LANG_CODE === prefLang.toLowerCase()
      ? SPANISH_LANG_CODE
      : ENGLISH_LANG_CODE
  }-us`;
  const SetClassName = () => {
    if (message.Status === "N" && index > 0) {
      return `inbox-message-unread`;
    } else if (messageDetails?.charStatus === "C") {
      return `icon-case-manager`;
    } else if (
      (messageDetails?.Status === "Pending with Member " &&
        messageDetails?.charStatus === "M") ||
      (messageDetails?.Status === "Pending with Member " &&
        messageDetails?.charStatus === "R")
    ) {
      return `icon-user`;
    } else {
      return `inbox-message-read`;
    }
  };

  const SetToolTip = () => {
    if (messageDetails?.charStatus === "C") {
      if (langcode === "en-us") {
        return CaseManagertten;
      } else {
        return CaseManagerttes;
      }
    } else if (
      (messageDetails?.Status === "Pending with Member " &&
        messageDetails?.charStatus === "M") ||
      (messageDetails?.Status === "Pending with Member " &&
        messageDetails?.charStatus === "R")
    ) {
      if (langcode === "en-us") {
        return Membertten;
      } else {
        return Memberttes;
      }
    }
  };

  useEffect(() => {
    setStatus(message.Status);
    setMessID(message?.MessageID);
    if (message.SFMessageID != null && message.SFMessageID.length > 0) {
      setSFMessID(message.SFMessageID);
      setSalesForceFormat(true);
      setReplyMessTxt(false);
    } else {
      setSalesForceFormat(false);
    }
    if (messageDetails) {
      refreshMemberInfoAll(); //To get the updated unread count
    }
  }, [message, messageDetails]);

  return (
    <li
      className={`list-group-item
      ${
        openedMessage?.MessageID?.toString() === message.MessageID.toString() &&
        "active-item"
      } ${SetClassName()} `}
      onClick={() => {
        setStatus(null);
        if (messageDetails?.charStatus === "M") {
          window.location.reload();
        }
        // setPageRefresh(pageRefresh + 1);
        onMessageSelect(message.MessageID);
      }}
      title={SetToolTip()}
    >
      <div
        className={
          messageDetails?.Status === "Pending with Member " &&
          messageDetails?.charStatus === "M"
            ? "msg-status-highlighted"
            : ""
        }
      >
        <div className="message-subject">
          {message?.From?.trim() ? message?.From : <span>&nbsp;&nbsp;</span>}
          {/* {console.log(message)} */}
        </div>
        <span className="message-date">
          {getFormattedDate(message?.SentDate, "mm/dd")}
        </span>
        {/* {prefLang === "en-US"
            ? moment(message?.SentDate).format("mm/dd")
            : moment(message?.SentDate).format("dd/mm")} */}

        <div className="message-body">
          {message?.Subject?.trim()}
          {/* <div>
          {getSanitisedHTML(
            message.Body && message.Body !== "" ? message.Body : message.Subject
          )}
        </div> */}
          {messageTabType != ARCHIVE && (
            <span className="checkbox-img">
              <input
                type="checkbox"
                id={`chk_${
                  message.SFMessageID ? message.SFMessageID : message.MessageID
                }`}
                name="message_chk"
                value={
                  message.SFMessageID ? message.SFMessageID : message.MessageID
                }
              />
              <label
                htmlFor={`chk_${
                  message.SFMessageID ? message.SFMessageID : message.MessageID
                }`}
              ></label>
            </span>
          )}
        </div>
      </div>
      {/* {attachments?.length > 0 && (
        <MessageAttachment fields={fields} attachments={attachments} />
      )} */}
    </li>
  );
};

export default MessagesIndexItem;
