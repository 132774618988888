import Axios from "./axios/provider";

/**
 * Get member Id Card Pdf!
 *
 * @param {object} data
 * @returns
 */
export const getMemberIdCardPdf = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetMemberIDCardPDF + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get IDCard Reasons List
 */

export const getIdCardReasonList = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.GetIdCardReasonList + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get IDCard Details
 */

export const getMemAPIIdCardDetails = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.MemAPIIdCardDetails + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 *Request ID Card
 */

export const submitRequestIDCard = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.MyMolinaMemReqIDCard,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
