import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import { IMAGE_PATH, ICON_PATH } from "../../define.constants";
import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewAZRewards = ({ props }) => {
  const list = props;
  return (
    <>
      <div className="row trackmyspending vb">
        <BoxContainer>
          <Box title={list[0]?.fields?.rewardsHeading}>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 col-sm-12 mb-4">
                  <div className="molina-progress-bar-list">
                    <table>
                      <tr>
                        <td style={{ width: "18%" }}>
                          <picture>
                            <img
                              src={`${IMAGE_PATH}/MolinaHelpFinder_rewards.png`}
                              //className="img-fluid"
                              style={{ width: "100%" }}
                            />
                          </picture>{" "}
                        </td>
                        <td
                          style={{
                            paddingLeft: "30px",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text
                            field={list[0]?.fields?.MolinaHelpFinderPrefix}
                          />
                          <a href="https://www.auntbertha.com/" target="blank">
                            <Text field={list[0]?.fields?.MolinaHelpFinder} />
                          </a>
                          <Text
                            field={list[0]?.fields?.MolinaHelpFinderSuffix}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: "15px" }}></td>
                      </tr>
                      <tr>
                        <td>
                          <picture>
                            <img
                              src={`${IMAGE_PATH}/UT_PhyshHub.png`}
                              className="img-fluid"
                            />
                          </picture>{" "}
                        </td>
                        <td
                          style={{
                            paddingLeft: "20px",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text field={list[0]?.fields?.physhHub} />
                          <a href="https://psychhub.com/" target="blank">
                            <Text field={list[0]?.fields?.learrningMore} />
                            <img
                              className="arr-icon"
                              src={`${ICON_PATH}/arrow-right-small.svg`}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: "15px" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "18%" }}>
                          <picture>
                            <img
                              src={`${IMAGE_PATH}/AZ-211.png`}
                              className="img-fluid"
                              //style={{ width: "100%" }}
                            />
                          </picture>{" "}
                        </td>
                        <td
                          style={{
                            paddingLeft: "30px",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text field={list[0]?.fields?.onlineResourcePrefix} />
                          <a href="https://211arizona.org/" target="blank">
                            <Text
                              field={list[0]?.fields?.onlineResourceWebsite}
                            />
                          </a>
                          <Text field={list[0]?.fields?.onlineResourceSuffix} />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: "15px" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "18%" }}>
                          <picture>
                            <img
                              src={`${IMAGE_PATH}/nowpow_logo.png`}
                              className="img-fluid"
                            />
                          </picture>{" "}
                        </td>
                        <td
                          style={{
                            paddingLeft: "30px",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text field={list[0]?.fields?.nowPow} />
                          <a href="https://www.nowpow.com/" target="blank">
                            <Text field={list[0]?.fields?.learrningMore} />
                            <img
                              className="arr-icon"
                              src={`${ICON_PATH}/arrow-right-small.svg`}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: "15px" }}></td>
                      </tr>
                      <tr style={{ fontSize: "15px" }}>
                        <td className="h-100" style={{ width: "18%" }}>
                          <ul className="list-inline memberSocialIcon my-auto">
                            <li className="list-inline-item">
                              <a
                                href="https://www.facebook.com/MolinaHealthAZ"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture1.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="https://www.youtube.com/user/MolinaHealthcare"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture5.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="https://twitter.com/molinahealth"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture2.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="https://www.instagram.com/molinahealth/"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture3.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                rel="noreferrer"
                                href={
                                  list[0]?.fields?.linkedInLink?.value?.href
                                }
                                target="_blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture4.png`}
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                          </ul>
                        </td>
                        <td
                          style={{
                            paddingLeft: "30px",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text field={list[0]?.fields?.SocialMedia} />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 mb-4">
                  <div className="molina-progress-bar-list">
                    <div>
                      <picture className="img-mobile-app">
                        <img
                          src={`${IMAGE_PATH}/Mobile-app-image.png`}
                          className="img-fluid spotlight img-mobile-app"
                        />
                        <a href="https://apps.apple.com/us/app/my-molina/id1087995311">
                          <img
                            src={`${IMAGE_PATH}/Appstore.png`}
                            className="img-fluid spotlight img-appstore"
                          />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.molina.mobile.myhealthinhand&hl=en_US&gl=US&pli=1">
                          <img
                            src={`${IMAGE_PATH}/Google-PlayStore.png`}
                            className="img-fluid spotlight img-playstore"
                          />
                        </a>
                      </picture>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </BoxContainer>
      </div>
    </>
  );
};

export default ViewAZRewards;
