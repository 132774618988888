import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import DisplayTitleItem from "../MyHealthRecord-Layout/Common/DisplayTitleItem";

const CareCoordinationRequestHistory = (props) => {
  const { fields, data } = props;
  return (
    <>
      <div className="row mb20">
        <div className="col-md-12 ohio-subheading">
          <Text field={props.fields.TitleHistory} />
        </div>
      </div>

      {data != null && data != undefined && data.length == 0 ? (
        <p className="no-records">
          <Text field={props.fields.lblNoResults} />
        </p>
      ) : (
        <>
          {data?.map((item, index) => (
            <div className="rquestHistory" key={index}>
              <div className="blockSec2 mb20" id="recordsToPrint">
                {/* <li className="list-group-item "> */}
                <div className="row">
                  <DisplayItem
                    nameField={fields.lblAssigneDate}
                    valueField={item.Datestr}
                    outClass={"col-xxl-2 col-xl-2 col-md-6 col-sm-12"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  <DisplayItem
                    nameField={fields.lblRequestingEntity}
                    valueField={item.RequestingEntity}
                    outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  <DisplayItem
                    nameField={fields.lblReasonForRequesting}
                    valueField={item.ReasonForRequesting}
                    outClass={"col-xxl-4 col-xl-4 col-md-6 col-sm-12"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  <DisplayItem
                    nameField={fields.lblRequestStatus}
                    valueField={item.RequestStatus}
                    outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default CareCoordinationRequestHistory;
