/*
This componentreturns the Service Description Disclaimer at the end of the result. 
*/

import React from "react";
import { Link, getFieldValue } from "@sitecore-jss/sitecore-jss-react";

const ClaimLinks = (props) => {
  const { fields } = props;
  return (
    <div className="row eligibility-details">
      <div className="col-sm-12 col-md-6 col-xl-6">
        <p className="page-subheading mb-4 row-title ohio-disc">
          {fields.lblOhioRise.value}
        </p>
        <p className="disc-bld row-details ohio-disc">
          {fields.lblOhRiseSen.value} <br></br>
          {getFieldValue(fields, "lblinkOhioRiseClaims") && (
            <Link
              className="text-decorate"
              field={fields.lblinkOhioRiseClaims}
              target="_blank"
            />
          )}
        </p>
        <p className="page-subheading mb-4 ohio-disc">
          {fields.lblOHPmacyClaim.value}
        </p>
        <p className="disc-bld row-details ohio-disc">
          {fields.lblOHPmacyClaimSen.value}
          <br></br>
          {getFieldValue(fields, "lbOHlinkPmacyClaim") && (
            <Link
              className="text-decorate"
              field={fields.lbOHlinkPmacyClaim}
              target="_blank"
            />
          )}
        </p>
      </div>
      <div className="col-sm-12 col-md-6 col-xl-6">
        <p className="page-subheading mb-4 row-title ohio-disc">
          {fields.iblOHDisclaimerHeader.value}
        </p>
        <p className="disc-bld row-details ohio-disc">
          {fields.iblOHDisclaimerDesc.value}
        </p>
      </div>
    </div>
  );
};

export default ClaimLinks;
