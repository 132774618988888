import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as AccumulatorAPI from "../services/Common";
import { HOME_TRACK_MY_SPENDING } from "../define.widgets";
import { ErrorContext } from "../contexts/error.context";
import { MARKETPLACE } from "../define.constants";
import { AppContext } from "../contexts/app.context";

const qs = require("qs");

const useGetAccumulatorAPI = (planYear = null) => {
  const { setError } = useContext(ErrorContext);
  const { setInnerSpinner } = useContext(SpinnerContext);
  const [accum, setAccum] = useState("");
  const [date, setDate] = useState("");
  let { memberLOB } = useContext(AppContext);
  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;

  const setData = (data) => {
    setAccum(data.Accumulator);
    setDate(data.dtTime);
  };

  useEffect(() => {
    if (isMarketplaceMember) {
      let request = planYear ? { Year: planYear } : null;
      setInnerSpinner(true, HOME_TRACK_MY_SPENDING);
      AccumulatorAPI.getAccumulator(
        request,
        qs.stringify(request, { addQueryPrefix: true })
      )
        .then(
          (response) => response?.ResultData && setData(response?.ResultData)
        )
        .catch((err) => setError({ err }))
        .finally(() => setInnerSpinner(false, HOME_TRACK_MY_SPENDING));
    }
  }, [planYear, isMarketplaceMember]);

  return {
    accum,
    date,
  };
};

export { useGetAccumulatorAPI };
