import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIDCardMedicaidTXChipPerinateRSA = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/TX/Medicaid/Molina-CHIP-Perinate-ID-Card-new.png)`,
          }}
          className="background-idcard"
          id="containerTX"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="table-font-size tx-member-idcard"
          >
            <tbody>
              <tr>
                <td>
                  <div className="clearfix">
                    <div className="float-start perinateleft">
                      <ul className="list-unstyled TXmrgn">
                        <li>
                          <b>
                            <Text field={fields?.lblMember} />
                          </b>
                          &nbsp; {idCard?.Member_Name}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblID} />
                          </b>
                          &nbsp; {idCard?.OriginalMemberProfile?.Number}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblSCDOB} />
                          </b>
                          &nbsp;
                          {idCard?.Member_DOB}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblPlanType} />
                          </b>
                          &nbsp; {result?.IdCardValues?.Plan_Type}
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblDeliveryFacilityCharges} />{" "}
                          </b>
                          <br></br>
                          <Text field={fields?.lblSendClaimsToTMHP} />
                        </li>
                        <li>
                          <b>
                            <Text field={fields?.lblDeliveryProfCharges} />
                          </b>
                          &nbsp; <Text field={fields?.lblSendClaimsToMolina} />
                        </li>
                      </ul>
                      <div className="TXblock">
                        <ul className="list-unstyled TXmrgn">
                          <li>
                            <Text field={fields?.lblEffectiveDate} />
                            &nbsp; {idCard?.MemberEffectiveDate}
                          </li>
                          <li>
                            <Text field={fields?.lblIssueDate} />
                            &nbsp; {formatToday()}
                          </li>
                          <li>
                            <Text field={fields?.lblCoPaysTX} />
                            &nbsp; <Text field={fields?.lblCoPaysValue} />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="float-end perinateright">
                      <div className="chipTop2Height"></div>
                      <div className="vborder">
                        <ul className="list-unstyled TXmrgn">
                          <li>
                            <Text field={fields?.lblBin} />
                            &nbsp; {result?.IdCardValues?.RxBIN}
                          </li>
                          <li>
                            <Text field={fields?.lblGroup} />
                            &nbsp; {result?.IdCardValues?.RxGRP}
                          </li>
                          <li>
                            <Text field={fields?.lblPCN} />
                            &nbsp; {result?.IdCardValues?.RxPCN}
                          </li>
                          <li>
                            <Text field={fields?.lblCVSCaremark} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/TX/Medicaid/Molina-CHIP-Perinate-ID-Card-back.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
              className="table-font-size-back"
            >
              <tr>
                <td>
                  <label>
                    <u>
                      <b>
                        <Text field={fields?.lblSCBackMembers} />
                      </b>
                    </u>
                    <Text field={fields?.lblBackMemberRSAValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <u>
                      <b>
                        <Text field={fields?.lblBackEmergency} />
                      </b>
                    </u>
                    &nbsp;
                    <Text field={fields?.lblBackEmergencyPerinateRSAValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <u>
                      <b>
                        <Text field={fields?.lblSCBackMembersSpanish} />
                      </b>
                    </u>
                    &nbsp;{" "}
                    <Text field={fields?.lblBackMemberChipRSASpanishValue} />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <u>
                      <b>
                        <Text field={fields?.lblBackEmergencySpanish} />
                      </b>
                    </u>
                    &nbsp;
                    <Text
                      field={fields?.lblBackEmergencyPerinateRSASpanishValue}
                    />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b>
                      <Text field={fields?.lblBackPRACTITIONERS} />
                    </b>
                    <RichText
                      field={fields?.lblBackPractitionerPerinateRSAValue}
                    />
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardMedicaidTXChipPerinateRSA;
