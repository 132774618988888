import React, { useContext } from "react";
import { AppContext } from "./../../contexts/app.context";
import MarketPlaceRecords from "./MarketPlaceRecords";
import NonMarketPlaceRecords from "./NonMarketPlaceRecords";

const ClaimHistoryRecords = (props) => {
  const { fields, data, next } = props;
  let { memberLOB } = useContext(AppContext);
  return (
    <>
      <div className="row print-heading mb-4 d-none">
        {fields.lblprintHeading.value}
      </div>
      <MarketPlaceRecords fields={fields} data={data} />
    </>
  );
};

export default ClaimHistoryRecords;
