import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const Description = ({ fields, list }) => {
  return (
    <>
      {list?.length > 0 && (
        <div className="cost-estimator-desc">
          {list[0]?.fields?.lblEstmtrDescription?.value}
        </div>
      )}
    </>
  );
};

export default Description;
