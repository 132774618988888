import React, { useContext } from "react";
import {
  IMAGE_PATH,
  MCCare_PROGRAMID,
  PLAN_MEDICARE_CHOICECARE,
  COST_ZERO,
  ID,
} from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../../../contexts/app.context";

const ViewIdCardMedicareUT = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  let { programId, planId, MemberStateName } = useContext(AppContext);

  const isIdahoMCare_ProgramID82 =
    MemberStateName === ID && programId === MCCare_PROGRAMID;

  // Flag to Show Co-Pay values
  const showCopay =
    isIdahoMCare_ProgramID82 ||
    (programId === MCCare_PROGRAMID && planId === PLAN_MEDICARE_CHOICECARE);

  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardUT"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/UT/Medicare/MedicareUTFrontview.png)`,
          }}
          className="background-idcard member-id-medicare-ut"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td colSpan="2" className="plan-care-name">
                  {idCard?.PlanName}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMemberNum} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPcp} />
                  </b>
                  &nbsp; {idCard?.PCPName_Current}
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblBin} />
                  </b>
                  &nbsp;
                  {/* <Text field={fields.lblRxBinValue} /> */}
                  {result.PortalDeductiblesMappingDetails[0]?.RxBIN}
                </td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPcpTel} />
                  </b>
                  &nbsp; {idCard?.PCPPhone_Current}
                </td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblPCN} />
                  </b>
                  {/* &nbsp; <Text field={fields.lblRxpcnValue} /> */}
                  &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxPCN}
                </td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblGroup} />
                  </b>
                  &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxGroup}
                </td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblMedicalCopays} />
                    </b>
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}

                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblRxid} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblOfficeVisits} />
                    </b>
                    &nbsp;
                    {result?.PortalDeductiblesMappingDetails[0]
                      ?.OfficeVisitCoPay ?? COST_ZERO}
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}
                <td>&nbsp;</td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblSpecialistVisits} />
                    </b>
                    &nbsp;
                    {result?.PortalDeductiblesMappingDetails[0]
                      ?.SpecialistVisitCoPay ?? COST_ZERO}
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}
                <td>&nbsp;</td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblUrgentCare} />
                    </b>
                    &nbsp;
                    {result?.PortalDeductiblesMappingDetails[0]
                      ?.UrgentCareCoPay ?? COST_ZERO}
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}
                <td>&nbsp;</td>
              </tr>
              <tr>
                {showCopay ? (
                  <td>
                    <b>
                      <Text field={fields.lblErVisits} />
                    </b>
                    &nbsp;
                    {result?.PortalDeductiblesMappingDetails[0]?.ERVisitCoPay ??
                      COST_ZERO}
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td> </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIssuedDate} />
                  </b>
                  &nbsp; {formatToday()}
                </td>
                <td>
                  &nbsp;&nbsp;
                  {result?.PortalDeductiblesMappingDetails[0]?.ContractPlanId}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicaid/WI_back_view.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
            >
              <tbody>
                <tr>
                  <td style={{ height: "30px" }}></td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <Text field={fields?.lblMemberServices} />
                    </b>
                    &nbsp;
                    {isIdahoMCare_ProgramID82 ? (
                      <Text field={fields?.lbl24hoursNurseAdviceID} />
                    ) : (
                      <Text field={fields?.lbl24hoursNurseAdvice} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblProvider} />
                    </b>
                    &nbsp; <Text field={fields?.lblProviderValue} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblSubmitClaim} />
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblMedical} />
                    </b>
                    &nbsp;
                    <Text field={fields?.lblMedicalValue} />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td nowrap="nowrap">
                    <b>
                      <Text field={fields?.lblPharmacy} />
                    </b>
                    <Text field={fields?.lblbackAddressPharmacyData} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="cust-padding">
              <b>
                <Text field={fields.lblIDBackComplaintContactData} />
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardMedicareUT;
