import React, { createContext } from "react";
import { Spinner } from "../common/Spinner";
import { useSpinner } from "../hooks/useSpinner";

const SpinnerContext = createContext({
  isLoading: false,
  setSpinner: () => {},
  innerSpinnerHTML: "",
});

function SpinnerProvider({ children }) {
  const { isLoading, setSpinner, innerSpinnerHTML, setInnerSpinner } =
    useSpinner();
  const context = { isLoading, setSpinner, innerSpinnerHTML, setInnerSpinner };

  return (
    <SpinnerContext.Provider value={context}>
      <Spinner />
      {children}
    </SpinnerContext.Provider>
  );
}

export { SpinnerContext, SpinnerProvider };
