import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardAZMedicaid = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/AZ/Medicaid/Member-ID-Card_AZ.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
            className="azIdCardFont"
          >
            <tbody>
              <tr className="table-tr-height-az">
                <td nowrap="nowrap" style={{ fontSize: "20px" }}>
                  <b>
                    <Text field={fields.lblHeaderText} />
                  </b>
                </td>
              </tr>
              <tr className="table-tr-height-az">
                <td nowrap="nowrap" style={{ fontSize: "20px" }}>
                  <b>
                    <Text field={fields.lblhedaerText2} />
                  </b>
                </td>
              </tr>
              <tr className="table-tr-height-az">
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblMemberName} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                  <br />
                </td>
              </tr>
              <tr className="table-tr-height-az">
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblAZMemberId} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr className="table-tr-height-az">
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblAZPlanName} />
                  </b>
                  &nbsp; {result?.IdCardValues?.PlanName}
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    width="100%"
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                  >
                    <tr className="table-tr-height-az">
                      <td style={{ width: "60%" }}>
                        <b>
                          <Text field={fields.lblAZRxBIN} />
                        </b>
                        &nbsp; {result?.IdCardValues?.RxBIN}
                      </td>
                      <td nowrap="nowrap" style={{ width: "40%" }}>
                        <b>
                          <Text field={fields.lblRxPCN} />
                        </b>
                        &nbsp; {result?.IdCardValues?.RxPCN}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr className="table-tr-height-az">
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblAZRxGRP} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxGRP}
                  &nbsp;
                  <b>
                    <Text field={fields.lblAZCHP} />
                  </b>
                  &nbsp; {result?.IdCardValues?.CHP}
                </td>
              </tr>
              <tr className="table-tr-height-az">
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblAZMemberServices} />
                  </b>
                  &nbsp; {result?.IdCardValues?.MemberServices}
                </td>
              </tr>
              <tr className="table-tr-height-az">
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblAZBehavirolHelathServices} />
                  </b>
                  &nbsp; {result?.IdCardValues?.BehavioralHealthServices}
                </td>
              </tr>
              <tr className="table-tr-height-az">
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblStateWIdeAZ} />
                  </b>
                  &nbsp; {result?.IdCardValues?.Arizona_Statewide_Crisis_Line}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/AZ/Medicaid/AZ_back_view.png)`,
          }}
          className="member-id-card-back"
        >
          <div className="member-id-card-back-text">
            <div className="table-font-size-back">
              <Text field={fields.lblAZBackText} />
            </div>
            <div>&nbsp;</div>
            <table
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
              className="azIdCardFont"
            >
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblAZNurseAdviseLine} />
                  </b>
                  &nbsp;&nbsp;
                  <Text field={fields?.lblAZNurseAdviseLineValue} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblAZTransportation} />
                  </b>
                  &nbsp;&nbsp;
                  <Text field={fields?.lblAZTransportationValue} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td nowrap="nowrap">
                  <b>
                    <Text field={fields.lblAZCLaim} />
                  </b>
                  &nbsp;&nbsp;
                  <Text field={fields?.lblAZCLaimValue} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblAZPharamacy} />
                  </b>
                  &nbsp;&nbsp;
                  <Text field={fields?.lblAZPharamacyValue} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblWebsiteAZ} />
                  </b>
                  <span style={{ color: "#00a4b3" }}>
                    <Text field={fields.lblWebsiteAZValue} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblAZMailTo} />
                  </b>
                  <span style={{ paddingLeft: "40%" }}>
                    <Text field={fields.lblAZPayerId} />
                    <b>
                      <Text field={fields.lblAZPayerIDValue} />
                    </b>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblAZAdrressPrefix} />
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblAZAdressSuffix} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </table>
            <div className="azIdCardFont">
              <i>
                <Text field={fields.lblAZFotterText} />
              </i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardAZMedicaid;
