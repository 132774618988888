import React, { createContext } from "react";
import { useYearDropdown } from "../hooks/useYearDropdown";

const YearDropdownContext = createContext({
  yearFromMyCoverageDropdown: {},
  setYearFromMyCoverageDropdown: () => {},
});

function YearDropdownProvider({ children }) {
  const { yearFromMyCoverageDropdown, setYearFromMyCoverageDropdown } =
    useYearDropdown();
  const context = { yearFromMyCoverageDropdown, setYearFromMyCoverageDropdown };
  return (
    <YearDropdownContext.Provider value={context}>
      {children}
    </YearDropdownContext.Provider>
  );
}

export { YearDropdownContext, YearDropdownProvider };
