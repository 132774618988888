import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

const HomeCardsSection = (props) => (
  <div className="row cards-list">
    <Placeholder name="jss-home-cards-section" rendering={props.rendering} />
  </div>
);

export default HomeCardsSection;
