import { useEffect, useState, useContext } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import * as HealthRecordAPI from "../services/HeathRecordAPI";
import { ErrorContext } from "../contexts/error.context";

const useGetCarePlanAccessAPI = () => {
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const { memberRKSID } = useContext(AppContext);
  const [carePlanFlag, setCarePlanFlag] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ltssMemberFlag, setLtssMemberFlag] = useState(false);

  const setData = (response) => {
    setCarePlanFlag(response.ResultData.CarePlanFlag);
    setLtssMemberFlag(response.ResultData.LtssMemberFlag);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (memberRKSID != "") {
      setSpinner(true);
      HealthRecordAPI.getCarePlanAccess({ RKSMemberId: memberRKSID })
        .then((response) => {
          setData(response);
          //console.log(response.ResultData.CarePlanFlag);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    }
  }, [memberRKSID, setSpinner]);
  return { carePlanFlag, ltssMemberFlag, isLoaded };
};

export { useGetCarePlanAccessAPI };
