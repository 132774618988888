import Axios from "./axios/provider";

/**
 * Get DDL Years
 *
 * @param {object} data
 * @returns
 */
export const getDDLYears = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.GetDDLYears, data);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Get procedure cost estimator Data
 *
 * @param {object} data
 * @returns
 */
export const ProcedureCostEstimate = async (data, querystring) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ProcedureCostEstimate + querystring,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
