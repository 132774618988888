import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../../contexts/app.context";
import {
  IMAGE_PATH,
  TX,
  WI,
  WA,
  FL,
  CA,
  IL,
  NV,
  SC,
  OH,
  MI,
  NM,
  VA,
  NE,
  MS,
  DUALS,
  MEDICARE,
} from "../../../../define.constants";

const ViewIDCardMedicare = ({ fields, result, details, idCard, drugInfo }) => {
  let { MemberStateName, planName, memberLOB } = useContext(AppContext);
  const lob = memberLOB.toLowerCase();
  let portalDeductiblesDetail =
    result?.PortalDeductiblesMappingDetails &&
    result?.PortalDeductiblesMappingDetails.length > 0
      ? result?.PortalDeductiblesMappingDetails[0]
      : null;
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  // Pulling NAL English number
  const contactinfo = () => {
    switch (MemberStateName) {
      case CA:
      case FL:
      case IL:
      case TX:
      case OH:
      case WA:
      case WI:
      case MI:
      case MS:
      case NE:
      case NM:
        return fields.lblbackNurseEnglishData;
      case NV:
        return fields.lblbackNurseEnglishDataNV;
      case WI:
        return fields.lblbackNurseEnglishDataWI;
      case SC:
        return fields.lblbackNurseEnglishDataSC;
      default:
        return fields.lblbackNurseEnglishData;
    }
  };

  // Pulling NAL Spanish number
  const contactinfoSpanish = () => {
    switch (MemberStateName) {
      case CA:
      case FL:
      case IL:
      case TX:
        return fields.lblIDBackNALData;
      case OH:
        return fields.lblIDBackNALData;
      case WI:
      case MI:
      case NM:
        return fields.lblIDBackNALData;
      case WA:
        return fields.lblbackNurseSpanishDataWA;
      case NV:
        return fields.lblbackNurseEnglishDataNV;
      case WI:
        return fields.lblbackNurseEnglishDataWI;
      case SC:
        return fields.lblIDBackEnfermerasData;
      case NE:
        return fields.lblIDBackNALData;
      case MS:
        return fields.lblIDBackNALData;
      default:
        return fields.lblbackNurseEnglishData;
    }
  };

  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/Medicare/Molina_IDCard_CompleteCare2023.png)`,
          }}
          className="background-idcard background-id-row"
        >
          <div>
            <span className="plan-name-id-card">{planName}</span>
          </div>
          <br></br>
          <table
            className="medicare-table"
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td>
                  <Text field={fields.lblMember} />
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblmemberno} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
              </tr>
              <tr>
                <td className="height-adjustment-pcp"></td>
              </tr>
              <tr>
                {MemberStateName != VA ? (
                  <td>
                    <b>
                      <Text field={fields.lblPcp} />
                    </b>
                    &nbsp;
                    {idCard?.PCPName_Current}
                  </td>
                ) : (
                  <td></td>
                )}
                <td>
                  &nbsp;&nbsp;
                  <Text field={fields.lblBin} />
                  &nbsp;
                  {result?.PortalDeductiblesMappingDetails[0]?.RxBIN}
                  {/*<Text field={fields.lblRxBinValue} /> */}
                </td>
              </tr>
              <tr>
                <td style={{ height: "3px" }}></td>
              </tr>
              <tr>
                {MemberStateName != VA ? (
                  <td>
                    <b>
                      <Text field={fields.lblPrimaryCareNumber} />
                    </b>
                    &nbsp; {idCard?.PCPPhone_Current}
                  </td>
                ) : (
                  <td></td>
                )}
                <td>
                  &nbsp;&nbsp;
                  <Text field={fields.lblPCN} />
                  &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxPCN}
                  {/* <Text field={fields.lblPCNValue} /> */}
                </td>
              </tr>
              <tr>
                <td style={{ height: "3px" }}></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  &nbsp;&nbsp;
                  <Text field={fields.lblGroup} />
                  &nbsp;{result?.PortalDeductiblesMappingDetails[0]?.RxGroup}
                </td>
              </tr>
              <tr>
                <td style={{ height: "3px" }}></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  &nbsp;&nbsp;
                  <Text field={fields.lblRxid} />
                  &nbsp;
                  {idCard?.OriginalMemberProfile?.MedicareMemberId ||
                    idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr className="medicare-id-card-spacer">
                <td style={{ height: "49px" }}></td>
              </tr>
              <tr>
                <td></td>
                <td style={{ fontSize: "14px" }}>{}</td>
              </tr>
              <tr>
                <td style={{ height: "12px" }}></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  &nbsp;&nbsp;
                  {result?.PortalDeductiblesMappingDetails[0]?.ContractPlanId}
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblIssueDate} />
                  &nbsp;{formatToday()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicare/Medicare_ID_Card_Molina_BLANK_220914_r1_FNL2.png)`,
          }}
          className="member-id-card-back"
          id="backMedicare"
        >
          <table
            width="96%"
            border="0"
            cellPadding="2"
            cellSpacing="0"
            id="memberCurrentID"
            //style={{ fontSize: "12px" }}
          >
            <tbody>
              <tr>
                <td>
                  <div className="TXbackwidth">
                    <ul className="list-unstyled TXmrgn">
                      <li>
                        <b>
                          <Text field={fields.lblIDBackServices} />
                        </b>
                        &nbsp;
                        {portalDeductiblesDetail?.MS_No}&nbsp;
                        <Text field={fields.lblor} />
                        &nbsp;
                        <Text field={fields.lblTTyGlobalData} />
                      </li>
                      <li>
                        <Text field={fields.lblIDBackNAL} />
                        {contactinfo()?.value}
                        {/* <Text field={fields.lblbackNurseEnglishData} /> */}
                      </li>
                      <li>
                        <Text field={fields.lblIDBackNALSpanish} />
                        {contactinfoSpanish()?.value}
                        {/* <Text field={fields.lblbackNurseEnglishData} /> */}
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblIDBackProvider} />
                        </b>
                        <Text field={fields.lblIDBackProviderData} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackSendClaims} />
                        </b>
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackMedicalHospital} />
                        </b>
                      </li>
                      <li>
                        <Text field={fields.lblbackmedicalData} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackPharmacy} />
                        </b>
                      </li>
                      <li>
                        <Text field={fields.lblbackPharmacyData} />
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardMedicare;
