import React, { useState, useEffect } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.screen.availWidth,
        height: window.screen.availHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  let maxChar = 15;

  if (windowSize.width >= 375 && windowSize.width <= 768) {
    maxChar = 0;
  } else if (windowSize.width >= 769 && windowSize.width < 959) {
    maxChar = 60;
  } else if (windowSize.width >= 960 && windowSize.width < 1280) {
    maxChar = 70;
  } else if (windowSize.width >= 1280 && windowSize.width < 1360) {
    maxChar = 90;
  } else if (windowSize.width >= 1360 && windowSize.width < 1920) {
    maxChar = 105;
  } else if (windowSize.width >= 1920) {
    maxChar = 130;
  }
  return maxChar;
};
