import React, { useContext } from "react";
import ReactDOM from "react-dom";

import { ModalContext } from "./../../contexts/modal.context";
import { ThemeProvider } from "../../contexts/theme.context";

/**
 * Default, i.e. max-width: 500px
 * Version = 1, i.e. max-width: 600px
 *
 * If different version needs to be added then add case under
 * switch section below, add it's description above and write specific CSS!
 */

const Modal = () => {
  const { isOpen, header, content, footer, closeModal, version } =
    useContext(ModalContext);
  let overrideModalCSS = "";
  switch (version) {
    case 1:
      overrideModalCSS = "custom-modal";
      break;
    case 2:
      overrideModalCSS = "custom-modal-md";
      break;
    case 3:
      overrideModalCSS = "custom-popup-md";
      break;
    case 4:
      overrideModalCSS = "custom-modal-lg";
      break;
    case 5:
      overrideModalCSS = "custom-modal-xl";
      break;
    default:
      overrideModalCSS = "";
      break;
  }

  if (isOpen) {
    return ReactDOM.createPortal(
      <ThemeProvider>
        <div className="modal-backdrop fade show"></div>
        <div className={`modal-open`}>
          <div
            className="modal fade show"
            id="mdBootstrapModal"
            tabIndex="-1"
            aria-labelledby="mdBootstrapModalLabel"
            aria-hidden="true"
            style={{ display: "block" }}
            onClick={(e) => {
              if (e?.target?.id === "mdBootstrapModal") closeModal();
            }}
          >
            <div
              className={`modal-dialog modal-dialog-centered ${overrideModalCSS}`.trim()}
            >
              <div className="modal-content animate learnmorepopup">
                <div className="modal-header">
                  {header && (
                    <h5 className="modal-title" id="mdBootstrapModalLabel">
                      {header}
                    </h5>
                  )}
                  <button
                    type="button"
                    className="btn-close"
                    data-mdb-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">{content}</div>
                {footer && <div className="modal-footer">{footer}</div>}
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>,
      document.querySelector("#modal-root")
    );
  } else return null;
};

export default Modal;
