import Axios from "./axios/provider";
import { SpinnerContext } from "../contexts/spinner.context";

/**
 * Get Allergies List
 *
 * @param {object} data
 * @returns
 */
// This method is used to get the communication preferences
export const SelectedOptin = async (data) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.SelectedOptin, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const SelectedOptOut = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.SelectedOptOut,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const RevokeMemberConsentApp = async (data) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.RevokeMemberConsentApp,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
